export const glosarioDeVariables = {
    "aislamiento": {
        "key": "aislamiento",
        "nombre": "Aislamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "ajustePorAccionesCorrectivas": {
        "key": "ajustePorAccionesCorrectivas",
        "nombre": "Ajuste por acciones previas",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Modificación basada en un análisis completo",
            "Modificación basada en experiencia",
            "Sin modificaciones"
        ]
    },
    "ajustePorComplejidadDeTuberia": {
        "key": "ajustePorComplejidadDeTuberia",
        "nombre": "Complejidad de la tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "0 - 5 accesorios",
            "6 - 10 accesorios",
            "Más de 10 accesorios"
        ]
    },
    "ajustePorCondicionDeTuberia": {
        "key": "ajustePorCondicionDeTuberia",
        "nombre": "Condición de tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Sin soporte, inadecuado o dañado",
            "Refuerzo roto o soldado directamente a la tubería",
            "Buena condición"
        ]
    },
    "ajustePorDiametroDeRamificaciones": {
        "key": "ajustePorDiametroDeRamificaciones",
        "nombre": "Diámetro de junta",
        "unidades": "in",
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Todas las ramificaciones iguales o menores a 2 NPS",
            "Cualquier ramificación mayor de 2 NPS"
        ]
    },
    "ajustePorDiseño": {
        "key": "ajustePorDiseño",
        "nombre": "¿El diseño permite acumulación de agua?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "ajustePorTipoDeJuntaODiseñoDeRamales": {
        "key": "ajustePorTipoDeJuntaODiseñoDeRamales",
        "nombre": "Diseño de tipo de tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Threaded, socketweld, saddle on",
            "Saddle in fittings",
            "Piping tee, weldolets",
            "Sweepolets"
        ]
    },
    "alturaDeLiquido": {
        "key": "alturaDeLiquido",
        "nombre": "Altura del líquido",
        "unidades": "ft",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "ca": {
        "key": "ca",
        "nombre": "Corrosion allowance",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "calidadDeCondicionLining": {
        "key": "calidadDeCondicionLining",
        "nombre": "Calidad de condición de lining",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Malo",
            "Medio",
            "Bueno"
        ]
    },
    "calidadDelRecubrimiento": {
        "key": "calidadDelRecubrimiento",
        "nombre": "Calidad del recubrimiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Sin recubrimiento o calidad pobre del recubrimiento",
            "Calidad media de recubrimiento",
            "Calidad alta de recubrimiento"
        ]
    },
    "cantidadDeVibracion": {
        "key": "cantidadDeVibracion",
        "nombre": "Cantidad de vibración audible/visible",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Menor",
            "Moderada",
            "Severa"
        ]
    },
    "cladding": {
        "key": "cladding",
        "nombre": "¿Tiene recubrimiendo?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "complegidadDelAislamiento": {
        "key": "complegidadDelAislamiento",
        "nombre": "Complejidad del aislamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Por encima del promedio",
            "Promedio",
            "Por debajo del promedio"
        ]
    },
    "condicionDelAislamiento": {
        "key": "condicionDelAislamiento",
        "nombre": "Condición del aislamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Por encima del promedio",
            "Promedio",
            "Por debajo del promedio"
        ]
    },
    "condicionDeSegmento": {
        "key": "condicionDeSegmento",
        "nombre": "Condición de la línea",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Aérea",
            "Enterrada",
        ]
    },
    "condicionDeDucto": {
        "key": "condicionDeSegmento",
        "nombre": "Condición de la línea",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Aérea",
            "Aéreo Acuífero",
            "Enterrada",
            "Enterrado Acuífero",
            "Semienterrado",
            "Aéreo Población",
            "Enterrado Población",
            "Semienterrado Población",
        ]
    },
    "condicionEspecifica": {
        "key": "condicionEspecifica",
        "nombre": "Condición Especifica",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Aérea - Sin acceso",
            "Aérea - Con acceso",
            "Enterrada - Tierra",
            "Enterrada - Concreto"
        ]
    },
    "confianzaDeLosDatosCE": {
        "key": "confianzaDeLosDatosCE",
        "nombre": "Confianza de los datos (CE)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Baja",
            "Media",
            "Alta"
        ]
    },
    "confianzaDeLosDatosCI": {
        "key": "confianzaDeLosDatosCI",
        "nombre": "Confianza de los datos (CI)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Baja",
            "Media",
            "Alta"
        ]
    },
    "confianzaDeLosDatosCUI": {
        "key": "confianzaDeLosDatosCUI",
        "nombre": "Confianza de los datos (CUI)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Baja",
            "Media",
            "Alta"
        ]
    },
    "densidadDePoblacion": {
        "key": "densidadDePoblacion",
        "nombre": "Densidad de la poblacion",
        "unidades": "personas / ft^2",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "descripcionDelActivo": {
        "key": "descripcionDelActivo",
        "nombre": "Descripción del activo",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "dique": {
        "key": "dique",
        "nombre": "Dique",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "distanciaAguasSubterraneas": {
        "key": "distanciaAguasSubterraneas",
        "nombre": "Distancia a aguas subterraneas",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "driver": {
        "key": "driver",
        "nombre": "Driver",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Marino/Área de torre de enfriamiento",
            "Templado",
            "Arido/Seco",
            "Severo"
        ]
    },
    "eficienciaDeJunta": {
        "key": "eficienciaDeJunta",
        "nombre": "Eficiencia de junta",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficientiaTratamientoQuimicoSCC": {
        "key": "eficientiaTratamientoQuimicoSCC",
        "nombre": "eficiencia de tratamiento quimico SCC",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "equipCost": {
        "key": "equipCost",
        "nombre": "Costo de reemplazo de equipos adyacentes",
        "unidades": "$ / ft^2",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "esAcidoOCaustico": {
        "key": "esAcidoOCaustico",
        "nombre": "¿Es ácido o cáustico?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "espesorCladdingYMb": {
        "key": "espesorCladdingYMb",
        "nombre": "Espesor del recubrimiento",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "espesorEstructural": {
        "key": "espesorEstructural",
        "nombre": "Espesor estructural",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "espesorMinimoMedidoActualCE": {
        "key": "espesorMinimoMedidoActualCE",
        "nombre": "Espesor mínimo medido actual CE",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "tNom": {
        "key": "tNom",
        "nombre": "Espesor nominal",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "espesorMinimoRequerido": {
        "key": "espesorMinimoRequerido",
        "nombre": "Espesor mínimo requerido",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "evaluarCUI": {
        "key": "evaluarCUI",
        "nombre": "¿Se evalua CUI?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarFatiga": {
        "key": "evaluarFatiga",
        "nombre": "¿Se evalua Fatiga?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarLining": {
        "key": "evaluarLining",
        "nombre": "¿Se evalua Lining?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "factorDeAjustePorAsentamiento": {
        "key": "factorDeAjustePorAsentamiento",
        "nombre": "Factor de ajuste por asentamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Asentamiento nunca evaluado",
            "Asentamiento registrado excede el criterio API653",
            "Asentamiento registrado acorde con API653",
            "Fundación de concreto sin asentamiento"
        ]
    },
    "factorDeAjustePorComponenteSoldado": {
        "key": "factorDeAjustePorComponenteSoldado",
        "nombre": "Factor de ajuste por componentes soldados",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "factorDeForma": {
        "key": "factorDeForma",
        "nombre": "Factor de forma",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "númerico",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "2",
            "4",
            "1.13"
        ]
    },
    "factorDeGestion": {
        "key": "factorDeGestion",
        "nombre": "Factor de gestión",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 1
            }
        ]
    },
    "factorDeMatenimiento653": {
        "key": "factorDeMatenimiento653",
        "nombre": "Factor de mantenimiento API 653",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "faseDelFluido": {
        "key": "faseDelFluido",
        "nombre": "Fase del fluido almacenado",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Líquido",
            "Gas"
        ]
    },
    "fechaDeActivacion": {
        "key": "fechaDeActivacion",
        "nombre": "Fecha de activación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": []
    },
    "fechaDeInspeccionActualCE": {
        "key": "fechaDeInspeccionActualCE",
        "nombre": "Fecha de inspecciónactual CE",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInspeccionActualCI": {
        "key": "fechaDeInspeccionActualCI",
        "nombre": "Fecha de inspección actual",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": []
    },
    "fechaDeInspeccionEfectivaLining": {
        "key": "fechaDeInspeccionEfectivaLining",
        "nombre": "Fecha de última inspección efectiva de lining",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInspeccionUltimaCE": {
        "key": "fechaDeInspeccionUltimaCE",
        "nombre": "Periodo de Evaluación (CE)",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInspeccionUltimaCI": {
        "key": "fechaDeInspeccionUltimaCI",
        "nombre": "Fecha de inspección última",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInstalacionDelRecubrimiento": {
        "key": "fechaDeInstalacionDelRecubrimiento",
        "nombre": "Fecha de instalación del recubrimiento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]

    },
    "fechaDeInstalacionLining": {
        "key": "fechaDeInstalacionLining",
        "nombre": "Fecha de instalación de lining",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fin": {
        "key": "fin",
        "nombre": "Fin",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "fluidoRepresentativo": {
        "key": "fluidoRepresentativo",
        "nombre": "Fluido representativo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            { filter: 'línea', value: 'C1-C2' },
            { filter: 'línea', value: 'C3-C4' },
            { filter: 'línea', value: 'C5' },
            { filter: 'línea', value: 'C6-C8' },
            { filter: 'línea', value: 'C9-C12' },
            { filter: 'línea', value: 'C13-C16' },
            { filter: 'línea', value: 'C17-C25' },
            { filter: 'línea', value: 'C25+' },
            { filter: 'línea', value: 'Water' },
            { filter: 'línea', value: 'Steam' },
            { filter: 'línea', value: 'Acid' },
            { filter: 'línea', value: 'H2' },
            { filter: 'línea', value: 'H 2 S' },
            { filter: 'línea', value: 'HF' },
            { filter: 'línea', value: 'CO' },
            { filter: 'línea', value: 'DEE' },
            { filter: 'línea', value: 'HCL' },
            { filter: 'línea', value: 'Nitric Acid' },
            { filter: 'línea', value: 'ALCL3' },
            { filter: 'línea', value: 'NO 2' },
            { filter: 'línea', value: 'Phosgene' },
            { filter: 'línea', value: 'TDI' },
            { filter: 'línea', value: 'Methanol' },
            { filter: 'línea', value: 'PO' },
            { filter: 'línea', value: 'Styrene (Aromatic)' },
            { filter: 'línea', value: 'EEA' },
            { filter: 'línea', value: 'EE' },
            { filter: 'línea', value: 'EG' },
            { filter: 'línea', value: 'EO' },
            { filter: 'línea', value: 'Pyrophoric' },
            { filter: 'línea', value: 'Ammonia' },
            { filter: 'línea', value: 'Chlorine' },
            { filter: 'tanque', value: 'Gasoline' },
            { filter: 'tanque', value: 'Light Diesel Oil' },
            { filter: 'tanque', value: 'Heavy Diesel Oil' },
            { filter: 'tanque', value: 'Fuel Oil' },
            { filter: 'tanque', value: 'Crude Oil' },
            { filter: 'tanque', value: 'Heavy Fuel Oil' },
            { filter: 'tanque', value: 'Heavy Crude Oil' },
            { filter: 'tanque', value: 'Water' },
            { filter: 'vasija', value: 'C1-C2' },
            { filter: 'vasija', value: 'C3-C4' },
            { filter: 'vasija', value: 'C5' },
            { filter: 'vasija', value: 'C6-C8' },
            { filter: 'vasija', value: 'C9-C12' },
            { filter: 'vasija', value: 'C13-C16' },
            { filter: 'vasija', value: 'C17-C25' },
            { filter: 'vasija', value: 'C25+' },
            { filter: 'vasija', value: 'Water' },
            { filter: 'vasija', value: 'Steam' },
            { filter: 'vasija', value: 'Acid' },
            { filter: 'vasija', value: 'H2' },
            { filter: 'vasija', value: 'H 2 S' },
            { filter: 'vasija', value: 'HF' },
            { filter: 'vasija', value: 'CO' },
            { filter: 'vasija', value: 'DEE' },
            { filter: 'vasija', value: 'HCL' },
            { filter: 'vasija', value: 'Nitric Acid' },
            { filter: 'vasija', value: 'ALCL3' },
            { filter: 'vasija', value: 'NO 2' },
            { filter: 'vasija', value: 'Phosgene' },
            { filter: 'vasija', value: 'TDI' },
            { filter: 'vasija', value: 'Methanol' },
            { filter: 'vasija', value: 'PO' },
            { filter: 'vasija', value: 'Styrene (Aromatic)' },
            { filter: 'vasija', value: 'EEA' },
            { filter: 'vasija', value: 'EE' },
            { filter: 'vasija', value: 'EG' },
            { filter: 'vasija', value: 'EO' },
            { filter: 'vasija', value: 'Pyrophoric' },
            { filter: 'vasija', value: 'Ammonia' },
            { filter: 'vasija', value: 'Chlorine' },
        ]
    },
    "fuenteDeEsfuerzoCiclico": {
        "key": "fuenteDeEsfuerzoCiclico",
        "nombre": "Fuentes de esfuerzo crítico (50 ft)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Máquina de acción reciproca",
            "PRV intermitente",
            "Válvula con alta caida de presión",
            "Ninguna"
        ]
    },
    "grupoDeInventario": {
        "key": "grupoDeInventario",
        "nombre": "Grupo de inventario",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "hallazgos": {
        "key": "hallazgos",
        "nombre": "Hallazgos",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "inicio": {
        "key": "inicio",
        "nombre": "Inicio",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "injCost": {
        "key": "injCost",
        "nombre": "Costo por lesiones graves o muerte",
        "unidades": "$",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "inspeccionEfectivaDl": {
        "key": "inspeccionEfectivaDl",
        "nombre": "Inspección efectiva DI",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "inspeccionEfectivaIp": {
        "key": "inspeccionEfectivaIp",
        "nombre": "Inspección efectiva IP",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "interfaseSueloAire": {
        "key": "interfaseSueloAire",
        "nombre": "Interfase suelo-aire",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "longitudDeSegmento": {
        "key": "longitudDeSegmento",
        "nombre": "Longitud",
        "unidades": "m",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ]
    },
    "materialDeConstruccion": {
        "key": "materialDeConstruccion",
        "nombre": "Material de construcción",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "A134",
            "A672 A45",
            "API 5L A25",
            "A53 A",
            "A139 A",
            "A106 A",
            "A135 A",
            "A369 FPA",
            "API 5L A",
            "A672 A50",
            "A671 ca55",
            "A672 A55",
            "A672 C55",
            "A671 CC60",
            "A671 CB60",
            "A672 B60",
            "A672 C60",
            "A139 B",
            "A135 B",
            "A53 B",
            "A106 B",
            "A381 Y35",
            "API 5L B"
        ]
    },
    "mecanismoDeDaño": {
        "key": "mecanismoDeDaño",
        "nombre": "Mecanismo de daño",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Corrosión ácida a alta temperatura sulfidico/naftenico",
            "Corrosión a alta temperatura por H2S/H2",
            "Corrosión por ácido sulfúrico(H2S/H2) baja velocidad",
            "Corrosión por ácido sulfúrico(H2S/H2) alta velocidad",
            "Corrosión por ácido hidrofluórico",
            "Corrrosión por aguas amargas baja velocidad",
            "Corrrosión por aguas amargas alta velocidad",
            "Aminas baja velocidad",
            "Aminas alta velocidad",
            "Otros mecánismos de daño"
        ]
    },
    "monitoreoOnlineLining": {
        "key": "monitoreoOnlineLining",
        "nombre": "Monitoreo online de lining",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "numeroDeFallasPrevias": {
        "key": "numeroDeFallasPrevias",
        "nombre": "Número de fallas previas",
        "unidades": "#",
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Ninguna",
            "Una",
            "Más de una"
        ]
    },
    "numeroDeInspeccionesTipoACE": {
        "key": "numeroDeInspeccionesTipoACE",
        "nombre": "Inspecciones tipo A (CE)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoACI": {
        "key": "numeroDeInspeccionesTipoACI",
        "nombre": "Inspecciones tipo A (CI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoACUI": {
        "key": "numeroDeInspeccionesTipoACUI",
        "nombre": "Inspecciones tipo A (CUI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoBCE": {
        "key": "numeroDeInspeccionesTipoBCE",
        "nombre": "Inspecciones tipo B (CE)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoBCI": {
        "key": "numeroDeInspeccionesTipoBCI",
        "nombre": "Inspecciones tipo B (CI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoBCUI": {
        "key": "numeroDeInspeccionesTipoBCUI",
        "nombre": "Inspecciones tipo B (CUI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoCCE": {
        "key": "numeroDeInspeccionesTipoCCE",
        "nombre": "Inspecciones tipo C (CE)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoCCI": {
        "key": "numeroDeInspeccionesTipoCCI",
        "nombre": "Inspecciones tipo C (CI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoCCUI": {
        "key": "numeroDeInspeccionesTipoCCUI",
        "nombre": "Inspecciones tipo C (CUI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoDCE": {
        "key": "numeroDeInspeccionesTipoDCE",
        "nombre": "Inspecciones tipo D (CE)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoDCI": {
        "key": "numeroDeInspeccionesTipoDCI",
        "nombre": "Inspecciones tipo D (CI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeInspeccionesTipoDCUI": {
        "key": "numeroDeInspeccionesTipoDCUI",
        "nombre": "Inspecciones tipo D (CUI)",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeSemanasExpuesta": {
        "key": "numeroDeSemanasExpuesta",
        "nombre": "Número de semanas expuesta a vibración",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Vibrando menos de 2 semanas",
            "Vibrando entre 2 y 13 semanas",
            "Vibrando entre 13 y 52 semanas"
        ]
    },
    "numeroTotalDeAnillos": {
        "key": "numeroTotalDeAnillos",
        "nombre": "Número total de anillos",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "outageMult": {
        "key": "outageMult",
        "nombre": "Factor multiplicador de detenciones",
        "unidades": "#",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "periodoDeEvaluacionCE": {
        "key": "periodoDeEvaluacionCE",
        "nombre": "Periodo de Evaluación (CE)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Instalación - Actual inspección",
            "Inspección anterior - Actual inspección"
        ]
    },
    "periodoDeEvaluacionCI": {
        "key": "periodoDeEvaluacionCI",
        "nombre": "Periodo de evaluación",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Instalación - Actual inspección",
            "Inspección anterior - Actual inspección"
        ]
    },
    "piernasMuertas": {
        "key": "piernasMuertas",
        "nombre": "Piernas muertas",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "plvDique": {
        "key": "plvDique",
        "nombre": "P. lv dique",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 100
            }
        ]
    },
    "pOffSite": {
        "key": "pOffSite",
        "nombre": "P. offsite",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 100
            }
        ]
    },
    "pOnSite": {
        "key": "pOnSite",
        "nombre": "P. onsite",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 100
            }
        ]
    },
    "posicionDeActivo": {
        "key": "posicionDeActivo",
        "nombre": "Posición del activo",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "presionAtmosferica": {
        "key": "presionAtmosferica",
        "nombre": "Presión atmosférica",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presionDeOperacion": {
        "key": "presionDeOperacion",
        "nombre": "Presión de operación",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "productCost": {
        "key": "productCost",
        "nombre": "Costo por interrupción",
        "unidades": "$",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "puntosDeInyeccion": {
        "key": "puntosDeInyeccion",
        "nombre": "Puntos de inyección",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "scanAespesorMinimoMedidoActual": {
        "key": "scanAespesorMinimoMedidoActual",
        "nombre": "Espesor mínimo medido actual Scan A",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "scanAespesorMinimoMedidoUltima": {
        "key": "scanAespesorMinimoMedidoUltima",
        "nombre": "Espesor mínimo medido último Scan A",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "scanAinformacionAdicional": {
        "key": "scanAinformacionAdicional",
        "nombre": "Ubicación máxima perdida Scan A",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "scanAvelocidadDeCorrosionMbase": {
        "key": "scanAvelocidadDeCorrosionMbase",
        "nombre": "Velocidad de corrosión Scan A",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "scanCespesorMinimoMedidoActual": {
        "key": "scanCespesorMinimoMedidoActual",
        "nombre": "Espesor mínimo medido actual Scan B, C",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "scanCespesorMinimoMedidoUltima": {
        "key": "scanCespesorMinimoMedidoUltima",
        "nombre": "Espesor mínimo medido último Scan B, C",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "scanCinformacionAdicional": {
        "key": "scanCinformacionAdicional",
        "nombre": "Ubicación máxima perdida Scan B, C",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "trazaDelSegmento": {
        "key": "trazaDelSegmento",
        "nombre": "Traza del segmento",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "temperaturaDeOperacion": {
        "key": "temperaturaDeOperacion",
        "nombre": "temperatura de operacion",
        "unidades": "°F",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "tieneInspeccion": {
        "key": "tieneInspeccion",
        "nombre": "Tiene Inspeccion",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "tipoDeLinning": {
        "key": "tipoDeLinning",
        "nombre": "Tipo de lining",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Recubrimiento de grado de inmersión de baja calidad aplicado por pulverización, a 40 millas",
            "Recubrimiento de grado de inmersión de calidad media relleno, aplicado con llana, a 80 millas",
            "Recubrimiento de grado de inmersión de alta calidad reforzado, aplicado con llana, a >= 80 millas",
            "aleación de banda forrada",
            "Refractario moldeable",
            "Refractario moldeable con condiciones severas",
            "Forrado en vidrio",
            "Bloque ácido",
            "fibra de vidrio",
        ]
    },
    "tipoDeAislamiento": {
        "key": "tipoDeAislamiento",
        "nombre": "Tipo de aislamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Desconocido",
            "Espum de vidrio",
            "Perlita",
            "Fibra de vidrio",
            "Lana mineral",
            "Silicato de calcio",
            "Asbesto",
        ]
    },
    "tipoDeMaterial": {
        "key": "tipoDeMaterial",
        "nombre": "Tipo de material",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Acero al carbono",
            "1.25Cr-0.5Mo",
            "2.25Cr-1Mo",
            "5Cr-0.5Mo",
            "Clad 304 SS",
            "Polypropylene Lined (pp)",
            "9Cr-1Mo",
            "405SS",
            "410SS",
            "304SS",
            "Clad316 SS",
            "CS“Saran” Lined",
            "CSRubber Lined",
            "316SS",
            "CSGlass Lined",
            "CladAlloy 400",
            "90/10Cu/Ni",
            "CladAlloy",
            "CS“Teflon” Lined",
            "CladNickel",
            "Alloy800",
            "70/30Cu/Ni",
            "904L",
            "Alloy20",
            "Alloy400",
            "Alloy600",
            "Nickel",
            "Alloy 625",
            "Titanium",
            "Alloy“C”",
            "Alloy“B”",
            "Tantalum",
        ]
    },
    "tipoDeMonitoreo": {
        "key": "tipoDeMonitoreo",
        "nombre": "Tipo de monitoreo de corrosión",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Variable de proceso principal",
            "Probetas de resistencia eléctrica",
            "Cupones de corrosión",
            "Variable de proceso principal & Cupones de corrosión"
        ]
    },
    "velocidadDeCorrosionBase": {
        "key": "velocidadDeCorrosionBase",
        "nombre": "Velocidad de corrosión base",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionDelCladding": {
        "key": "velocidadDeCorrosionDelCladding",
        "nombre": "Velocidad de corrosión del recubrimiento",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "scanCvelocidadDeCorrosionMbase": {
        "key": "scanCvelocidadDeCorrosionMbase",
        "nombre": "Velocidad de corrosión Scan B,C",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "sulfatos": {
        "key": "sulfatos",
        "nombre": "Sulfatos \n(ppm SO₄²⁻)",
        "unidades": "ppm SO₄²⁻",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeFluido": {
        "key": "velocidadDeFluido",
        "nombre": "Velocidad Fludio\n(m/s)",
        "unidades": "m/s",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "actividadSismica": {
        "key": "actividadSismica",
        "nombre": "Zona con actividad sísmica",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "aislamientoTuberia": {
        "key": "aislamientoTuberia",
        "nombre": "Aislamiento de la Tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "alcanceMonitoreoPorVideocamaras": {
        "key": "alcanceMonitoreoPorVideocamaras",
        "nombre": "Alcande del monitoreo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "100% del segmento",
            "75% del segmento",
            "50% del segmento",
            "25% del segmento",
            "Puntos críticos (válvulas de seccionamiento)",
            "No se tiene"
        ]
    },
    "alivioTension": {
        "key": "alivioTension",
        "nombre": "Alivio Tensión",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No",
            "No requerido"
        ]
    },
    "amenazaPorDeslizamiento": {
        "key": "amenazaPorDeslizamiento",
        "nombre": "Existe posible amenaza por Deslizamiento de tierra?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "analisisECDA": {
        "key": "analisisECDA",
        "nombre": "¿Existe análisis ECDA para este segmento?",
        "unidades": "null ",
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "analisisICDA": {
        "key": "analisisICDA",
        "nombre": "Existe Análisis ICDA para éste segmento?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "anguloDeDeslizamiento": {
        "key": "anguloDeDeslizamiento",
        "nombre": "Ángulo de Deslizamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "0° < Angulo de deslizamiento  ≤ 10°",
            "10° < Angulo de deslizamiento  ≤ 50°",
            "Angulo de deslizamiento  > 50°",
            "Sin Información"
        ]
    },
    "anomaliasDañosPorTerceros": {
        "key": "anomaliasDañosPorTerceros",
        "nombre": "Anomalías por daños por terceros\n (Anomalías/km)",
        "unidades": "Anomalías/km",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "anomaliasDañosPorTercerosSegmento": {
        "key": "anomaliasDañosPorTercerosSegmento",
        "nombre": "Anomalías por daños por terceros\nen este segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "anomaliasDañosPorTercerosTotal": {
        "key": "anomaliasDañosPorTercerosTotal",
        "nombre": "Total Anomalías por daños por terceros en long. Inspeccionada",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "anuncionMediosComumicacion": {
        "key": "anuncionMediosComumicacion",
        "nombre": "Anuncios en medios de comunicación una vez por año",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "añoManufacturaDeTuberia": {
        "key": "añoManufacturaDeTuberia",
        "nombre": "Año de Manufactura de la tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "2000 > (Año MAN)",
            "1980 < (Año MAN) ≤ 2000",
            "1970 < (Año MAN) ≤ 1980",
            "(Año MAN) ≤ 1970"
        ]
    },
    "areaDeAltaConsecuencia": {
        "key": "areaDeAltaConsecuencia",
        "nombre": "Es un HCA (Área de alta consecuencia)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "barrerasArtificiales": {
        "key": "barrerasArtificiales",
        "nombre": "Barreras Artificiales",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "barrerasNaturales": {
        "key": "barrerasNaturales",
        "nombre": "Barreras Naturales",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "biocida": {
        "key": "biocida",
        "nombre": "Biocida",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "cantidadEmpaquesYSellosSegmento": {
        "key": "cantidadEmpaquesYSellosSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cantidadOringSegmento": {
        "key": "cantidadOringSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cantidadUnionesBridadasSegmento": {
        "key": "cantidadUnionesBridadasSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cantidadUnionesRoscadasSegmento": {
        "key": "cantidadUnionesRoscadasSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cantidadValvulasAlivioSegmento": {
        "key": "cantidadValvulasAlivioSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cantidadValvulasControlSegmento": {
        "key": "cantidadValvulasControlSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cantidadValvulasSeccionamientoSegmento": {
        "key": "cantidadValvulasSeccionamientoSegmento",
        "nombre": "Cantidad por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "categoriaDeExposicionOI": {
        "key": "categoriaDeExposicionOI",
        "nombre": "Categoría",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Contínua-(exposición por minuto)",
            "Ocasional-(exposición unas pocas veces por año)",
            "Poco probable-( exposición una vez o menos por año)"
        ]
    },
    "certificadoDeCalidadManufactura": {
        "key": "certificadoDeCalidadManufactura",
        "nombre": "Documentación certificada de controles de calidad durante manufactura y cumplimiento de especificaciones técnicas",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "contactoPuertaAPuerta": {
        "key": "contactoPuertaAPuerta",
        "nombre": "Contacto puerta a puerta con residentes vecinos",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "contenidoCO2": {
        "key": "contenidoCO2",
        "nombre": "Contenido CO2\n(ppm)",
        "unidades": "ppm",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "contenidoDeAgua": {
        "key": "contenidoDeAgua",
        "nombre": "Contenido de agua",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 1
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "contenidoDeBacterias": {
        "key": "contenidoDeBacterias",
        "nombre": "Contenido de Bacterias  (UFC/mL)",
        "unidades": "UFC/ml",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "contenidoDeHumedad": {
        "key": "contenidoDeHumedad",
        "nombre": "Contenido de Humedad",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 1
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "contenidoDeIonesCloruroCS": {
        "key": "contenidoDeIonesCloruroCS",
        "nombre": "contenido de lones cloruro",
        "unidades": "mg/kg",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "contenidoH2S": {
        "key": "contenidoH2S",
        "nombre": "Contenido H2S",
        "unidades": "ppm",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "contenidoIonesCloruro": {
        "key": "contenidoIonesCloruro",
        "nombre": "Contenido de Iones Cloruro",
        "unidades": "ppm",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "contenidoO2": {
        "key": "contenidoO2",
        "nombre": "Contenido O2\n(ppm)",
        "unidades": "ppm",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "controlDeCalidadRecubrimientoExterno": {
        "key": "controlDeCalidadRecubrimientoExterno",
        "nombre": "Control de calidad durante aplicación",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Bueno,Regular,Pobre,Ausente"
        ]
    },
    "cordonDeSoldadura": {
        "key": "cordonDeSoldadura",
        "nombre": "Cordón de soldadura",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Sin costura",
            "ERW-HF",
            "DSAW",
            "SAW",
            "Soldadura en espiral",
            "Soldadura Flash",
            "Soldadura a tope",
            "ERW – Baja Frecuencia",
            "Soldadura Vuelta",
            "Soldadura a Martillo",
            "Sin información"
        ]
    },
    "corrosividadDelFluido": {
        "key": "corrosividadDelFluido",
        "nombre": "Corrosividad del Fluido (índice langelier)",
        "unidades": "índice langelier",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "crucesDeAgua": {
        "key": "crucesDeAgua",
        "nombre": "Cruces de agua",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Cruces de Agua = 0",
            "Cruces de Agua > 0"
        ]
    },
    "desviacionEstandarCorrosionMedidaCE": {
        "key": "desviacionEstandarCorrosionMedidaCE",
        "nombre": "% Desviación Estándar",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "desviacionEstandarCorrosionMedidaCI": {
        "key": "desviacionEstandarCorrosionMedidaCI",
        "nombre": "% Desviación Estándar",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "detonacionesConstrucciones": {
        "key": "detonacionesConstrucciones",
        "nombre": "Para construcciones",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "detonacionesSismograficas": {
        "key": "detonacionesSismograficas",
        "nombre": "Sismográficas",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "diametro": {
        "key": "diametro",
        "nombre": "Diámetro",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "distanciaCompresorDeCarga": {
        "key": "distanciaCompresorDeCarga",
        "nombre": "Distancia a estación de compresor de descarga",
        "unidades": "km",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "educacionRegular": {
        "key": "educacionRegular",
        "nombre": "Programas de educación regular para grupos de la comunidad",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "efectividadDeInspeccionAE": {
        "key": "efectividadDeInspeccionAE",
        "nombre": "Efectividad de la Inspección",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "efectividadDeInspeccionAI": {
        "key": "efectividadDeInspeccionAI",
        "nombre": "Efectividad de la Inspección",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "efectividadDeInspeccionCE": {
        "key": "efectividadDeInspeccionCE",
        "nombre": "Efectividad de la Inspección",
        "unidades": null,
        "categoria": null,
        "tipo": null,
        "condicionesDeLaVariable": []
    },
    "efectividadDeInspeccionCI": {
        "key": "efectividadDeInspeccionCI",
        "nombre": "Efectividad de la Inspección",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "efectividadDeInspeccionFT": {
        "key": "efectividadDeInspeccionFT",
        "nombre": "Efectividad de la Inspección",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficienciaAmortiguadoresDeVibracionFT": {
        "key": "eficienciaAmortiguadoresDeVibracionFT",
        "nombre": "% Eficiencia de Amortiguadores de Vibración",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
        
    },
    "eficienciaBiocida": {
        "key": "eficienciaBiocida",
        "nombre": "Eficiencia Biocida",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficienciaInhibidorDeCorrosion": {
        "key": "eficienciaInhibidorDeCorrosion",
        "nombre": "Eficiencia Inhibidor de Corrosion",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficienciaInhibidorDeIncrustacion": {
        "key": "eficienciaInhibidorDeIncrustacion",
        "nombre": "Eficiencia Inhibidor de Incrustación",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficienciaLimpiezaInterna": {
        "key": "eficienciaLimpiezaInterna",
        "nombre": "Eficiencia",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficienciaOtrosFT": {
        "key": "eficienciaOtrosFT",
        "nombre": "%Eficiencia de Otros modos de mitigación de vibración",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "eficienciaTratamientoQuimicoSCC": {
        "key": "eficienciaTratamientoQuimicoSCC",
        "nombre": "% Eficiencia tramiento químico específico para agrietamiento",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "elementosDePrevencionErroresMecanicosOI": {
        "key": "elementosDePrevencionErroresMecanicosOI",
        "nombre": "Elementos de prevención de errores mecánicos",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "email": {
        "key": "email",
        "nombre": "Email",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "emailreunionesContratistas": {
        "key": "emailreunionesContratistas",
        "nombre": "Reuniones con contratistas/excavadores locales una vez al año",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "entrenamientoPersonalOI": {
        "key": "entrenamientoPersonalOI",
        "nombre": "Entrenamiento del personal (Adecuado y documentado)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "envCost": {
        "key": "envCost",
        "nombre": "Costo de remediacion en $/barril.",
        "unidades": "$/barril",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "estabilidacionTerreno": {
        "key": "estabilidacionTerreno",
        "nombre": "Estabilización del Terreno",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "estabilidadDelSuelo": {
        "key": "estabilidadDelSuelo",
        "nombre": "Estabilidad del suelo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Estable",
            "Inestable",
            "Posibles Hundimientos (incluir minería)",
            "Posibles Deslizamientos",
            "Sin Información"
        ]
    },
    "estadoDelRecubrimientoExternoPorInspeccionVisual": {
        "key": "estadoDelRecubrimientoExternoPorInspeccionVisual",
        "nombre": "Estado del recubrimiento por inspección visual",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Buenas condiciones-Sin defectos,Regular-Daños localizados,Malo-Daños generalizados"
        ]
    },
    "estadoDeLRecubrimientoExternoPorPCM": {
        "key": "estadoDeLRecubrimientoExternoPorPCM",
        "nombre": "Estado del recubrimiento por PCM/ACVG(ó DCVG)",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Buenas condiciones-Sin defectos,Regular-Daños localizados,Malo-Daños generalizados"
        ]
    },
    "evaluarCL": {
        "key": "evaluarCL",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarCONS": {
        "key": "evaluarCONS",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarEQ": {
        "key": "evaluarEQ",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarFT": {
        "key": "evaluarFT",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarMAN": {
        "key": "evaluarMAN",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarOI": {
        "key": "evaluarOI",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "evaluarSCC": {
        "key": "evaluarSCC",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "evaluarTER": {
        "key": "evaluarTER",
        "nombre": "Realizar",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "excavacionesPorAgricultura": {
        "key": "excavacionesPorAgricultura",
        "nombre": "Agricultura",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "excavacionesPorConstrucciones": {
        "key": "excavacionesPorConstrucciones",
        "nombre": "Por nuevas construcciónes",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "excavacionesPorOtros": {
        "key": "excavacionesPorOtros",
        "nombre": "Otros",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "excavacionesPorTrabajo": {
        "key": "excavacionesPorTrabajo",
        "nombre": "Trabajos en áreas aledañas",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "fallasDeLaPruebaHidrostaticaSegmentoCE": {
        "key": "fallasDeLaPruebaHidrostaticaSegmentoCE",
        "nombre": "Fallas de la prueba hidrostática por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "fallasDeLaPruebaHidrostaticaSegmentoCI": {
        "key": "fallasDeLaPruebaHidrostaticaSegmentoCI",
        "nombre": "Fallas de la prueba hidrostática por segmento",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "fallasDeLaPruebaHidrostaticaSegmentoFT": {
        "key": "fallasDeLaPruebaHidrostaticaSegmentoFT",
        "nombre": "Fallas de la prueba hidrostática por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "fallasDeLaPruebaHidrostaticaSegmentoSCC": {
        "key": "fallasDeLaPruebaHidrostaticaSegmentoSCC",
        "nombre": "Fallas de la prueba hidrostática por segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "fechaDeInstalacionDelRecubrimientoExterno": {
        "key": "fechaDeInstalacionDelRecubrimientoExterno",
        "nombre": "Año de instalación del recubrimiento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInstalacionDelRecubrimientoInterno": {
        "key": "fechaDeInstalacionDelRecubrimientoInterno",
        "nombre": "Año de instalación del recubrimiento interno",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInstalacionDeProteccionCatodica": {
        "key": "fechaDeInstalacionDeProteccionCatodica",
        "nombre": "Año de instalación del protección catódica",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeInstalacionTuberia": {
        "key": "fechaDeInstalacionTuberia",
        "nombre": "Fecha instalación de tubería",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeMonitoreoPorCupon": {
        "key": "fechaDeMonitoreoPorCupon",
        "nombre": "Fecha de monitoreo por Cupón",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeMonitoreoProbeta": {
        "key": "fechaDeMonitoreoProbeta",
        "nombre": "Fecha de monitoreo por Probeta",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimaInspeccionAE": {
        "key": "fechaDeUltimaInspeccionAE",
        "nombre": "Fecha de última Inspección",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimaInspeccionAI": {
        "key": "fechaDeUltimaInspeccionAI",
        "nombre": "Fecha de última Inspección",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimaInspeccionCE": {
        "key": "fechaDeUltimaInspeccionCE",
        "nombre": "Fecha de última Inspección",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimaInspeccionCI": {
        "key": "fechaDeUltimaInspeccionCI",
        "nombre": "Fecha de última Inspección",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimaInspeccionFT": {
        "key": "fechaDeUltimaInspeccionFT",
        "nombre": "Fecha de última Inspección",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimaInspeccionILI": {
        "key": "fechaDeUltimaInspeccionILI",
        "nombre": "Fecha última inspección ILI",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimoAnalisisECDA": {
        "key": "fechaDeUltimoAnalisisECDA",
        "nombre": "Fecha último análisis ECDA",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaDeUltimoAnalisisICDA": {
        "key": "fechaDeUltimoAnalisisICDA",
        "nombre": "Fecha último Análisis ICDA",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "fecha",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "frecuenciaDeExposicionOI": {
        "key": "frecuenciaDeExposicionOI",
        "nombre": "Frecuencia de exposición\nEventos/año",
        "unidades": "Eventos/año",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "frecuenciaDeMonitoreoPorCupon": {
        "key": "frecuenciaDeMonitoreoPorCupon",
        "nombre": "Frecuencia de monitoreo",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "frecuenciaDeMonitoreoPorProbeta": {
        "key": "frecuenciaDeMonitoreoPorProbeta",
        "nombre": "Frecuencia de monitoreo",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "frecuenciaDePatrullaje": {
        "key": "frecuenciaDePatrullaje",
        "nombre": "Frecuencia",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Diaria",
            "4 días por semana",
            "3 días por semana",
            "2 días por semana",
            "Una vez por semana",
            "1-3 veces por mes",
            "Menos de 1 vez por mes"
        ]
    },
    "frecuenciaLimpiezaInterna": {
        "key": "frecuenciaLimpiezaInterna",
        "nombre": "Frecuencia",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Mas de 2 veces por año,2 veces al año,1 vez por año,Ninguna"
        ]
    },
    "impactosPorObjeto": {
        "key": "impactosPorObjeto",
        "nombre": "Caída de objetos",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "impactosPorOtros": {
        "key": "impactosPorOtros",
        "nombre": "Otros",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "impactosPorVehiculo": {
        "key": "impactosPorVehiculo",
        "nombre": "Vehículos",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "incendiosPorAgricultura": {
        "key": "incendiosPorAgricultura",
        "nombre": "Agricultura",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "inhibidorDeCorrosion": {
        "key": "inhibidorDeCorrosion",
        "nombre": "Inhibidor de Corrosión",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "inhibidorDeIncrustacion": {
        "key": "inhibidorDeIncrustacion",
        "nombre": "Inhibidor de Incrustación",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "inspeccionILI": {
        "key": "inspeccionILI",
        "nombre": "¿Posee inspección por ILI?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "SI,No"
        ]
    },
    "limpiezaInterna": {
        "key": "limpiezaInterna",
        "nombre": "Limpieza Interna",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "longitudGrietaAE": {
        "key": "longitudGrietaAE",
        "nombre": "Longitud de la grieta",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "locationClass": {
        "key": "locationClass",
        "nombre": "Location class",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "longitudGrietaAI": {
        "key": "longitudGrietaAI",
        "nombre": "Longitud de la grieta",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "longitudInspeccionadaILI": {
        "key": "longitudInspeccionadaILI",
        "nombre": "Total longitud inspección",
        "unidades": "km",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "materialDeTuberia": {
        "key": "materialDeTuberia",
        "nombre": "Material de la tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Sobre la Tenacidad Mínima Requerida",
            "Bajo la Tenacidad Mínima Requerida"
        ]
    },
    "mejoresDelSuelo": {
        "key": "mejoresDelSuelo",
        "nombre": "Mejoras en el Suelo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "minimoEspesorMedidoCorrosionPorPicaduraCE": {
        "key": "minimoEspesorMedidoCorrosionPorPicaduraCE",
        "nombre": "Mínimo Espesor Medido por Picadura ",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "minimoEspesorMedidoCorrosionPorPicaduraCI": {
        "key": "minimoEspesorMedidoCorrosionPorPicaduraCI",
        "nombre": "Mínimo Espesor Medido por Picadura ",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "minimoEspesorMedidoGeneralCE": {
        "key": "minimoEspesorMedidoGeneralCE",
        "nombre": "Mínimo Espesor Medido",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "minimoEspesorMedidoGeneralCI": {
        "key": "minimoEspesorMedidoGeneralCI",
        "nombre": "Mínimo Espesor Medido ",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "minimoEspesorRequerido": {
        "key": "minimoEspesorRequerido",
        "nombre": "Mínimo espsor requerido",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "monitoreoFibraOptica": {
        "key": "monitoreoFibraOptica",
        "nombre": "Sistema se encuentra operativo y en buenas condiciones",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "nivelDeHumedadCS": {
        "key": "nivelDeHumedadCS",
        "nombre": "Nivel de humedad",
        "unidades": null,
        "categoria": null,
        "tipo": null,
        "condicionesDeLaVariable": []
    },
    "nivelDeVoltajeDeProteccionCatodica": {
        "key": "nivelDeVoltajeDeProteccionCatodica",
        "nombre": "Nivel de Voltaje",
        "unidades": "V",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroArranquesCompresorDeDescarga": {
        "key": "numeroArranquesCompresorDeDescarga",
        "nombre": "Número arranques (start) compresor de descarga  (Número arranques/mes)",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeFallasPorCE": {
        "key": "numeroDeFallasPorCE",
        "nombre": "No. Fallas por C.E",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeFallasPorCI": {
        "key": "numeroDeFallasPorCI",
        "nombre": "No. Fallas por C.I",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeFallasPorFT": {
        "key": "numeroDeFallasPorFT",
        "nombre": "No. Fallas",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDeFallasPorSCC": {
        "key": "numeroDeFallasPorSCC",
        "nombre": "No. Fallas",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDefectosDetectadosConFugaDelSegmentoCONS": {
        "key": "numeroDefectosDetectadosConFugaDelSegmentoCONS",
        "nombre": "Número Total de defectos detectados en el segmento que han generado fugas o interrupción de la operación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDefectosDetectadosConFugaDelSegmentoMAN": {
        "key": "numeroDefectosDetectadosConFugaDelSegmentoMAN",
        "nombre": "Número Total de defectos detectados en el segmento que han generado fugas o interrupción de la operación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDefectosDetectadosDelSegmentoCONS": {
        "key": "numeroDefectosDetectadosDelSegmentoCONS",
        "nombre": "Número Total de defectos detectados en el segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDefectosDetectadosDelSegmentoMAN": {
        "key": "numeroDefectosDetectadosDelSegmentoMAN",
        "nombre": "Número Total de defectos detectados en el segmento",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDePiernasMuertas": {
        "key": "numeroDePiernasMuertas",
        "nombre": "No.Piernas Muertas",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroDePuntosDeInyeccion": {
        "key": "numeroDePuntosDeInyeccion",
        "nombre": "No. Puntos de Inyección",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosConFugaSegmentoCL": {
        "key": "numeroEventosConFugaSegmentoCL",
        "nombre": "Número de eventos en el segmento por año que han generado fugas o interrupción de la operación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosDeslizamientoSegmento": {
        "key": "numeroEventosDeslizamientoSegmento",
        "nombre": "Número eventos por deslizamiento que podrían ocurrir en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosDetectadosConFugaDelSegmentoOI": {
        "key": "numeroEventosDetectadosConFugaDelSegmentoOI",
        "nombre": "Número de eventos en el segmento por año que han generado fugas o interrupción de la operación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosDetectadosConFugaDelSegmentoTER": {
        "key": "numeroEventosDetectadosConFugaDelSegmentoTER",
        "nombre": "Número de eventos en el segmento por año que han generado fugas o interrupción de la operación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosDetectadosDelSegmentoOI": {
        "key": "numeroEventosDetectadosDelSegmentoOI",
        "nombre": "Número Total de eventos que han ocurrido en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosDetectadosDelSegmentoTER": {
        "key": "numeroEventosDetectadosDelSegmentoTER",
        "nombre": "Número Total de otros eventos detectados en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroEventosSegmentoCL": {
        "key": "numeroEventosSegmentoCL",
        "nombre": "Número Total de eventos que han ocurrido en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasEmpaquesYSellosSegmento": {
        "key": "numeroFallasEmpaquesYSellosSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasOringSegmento": {
        "key": "numeroFallasOringSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasUnionesBridadasSegmento": {
        "key": "numeroFallasUnionesBridadasSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasUnionesRoscadasSegmento": {
        "key": "numeroFallasUnionesRoscadasSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasValulasControlSegmento": {
        "key": "numeroFallasValulasControlSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasValvulasAlivioSegmento": {
        "key": "numeroFallasValvulasAlivioSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroFallasValvulasSeccionamientoSegmento": {
        "key": "numeroFallasValvulasSeccionamientoSegmento",
        "nombre": "Número de fallas en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numerosInundacionesSegmento": {
        "key": "numerosInundacionesSegmento",
        "nombre": "Número inundaciones que podrían ocurrir en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroSismosSegmento": {
        "key": "numeroSismosSegmento",
        "nombre": "Número sismos que podrían ocurrir en el segmento por año",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "numeroTotalFallasPorEquipoConFugaSegmento": {
        "key": "numeroTotalFallasPorEquipoConFugaSegmento",
        "nombre": "Número de fallas en el segmento por año que han generado fugas o interrupción de la operación",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "perdidaDeEspesorCE": {
        "key": "perdidaDeEspesorCE",
        "nombre": "% Pérdida de Espesor",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "perdidaDeEspesorCI": {
        "key": "perdidaDeEspesorCI",
        "nombre": "% Pérdida de Espesor",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "phFluido": {
        "key": "phFluido",
        "nombre": "pH fluido",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "phSuelo": {
        "key": "phSuelo",
        "nombre": "PH suelo",
        "unidades": null,
        "categoria": null,
        "tipo": null,
        "condicionesDeLaVariable": []
    },
    "porcentajeC02": {
        "key": "porcentajeC02",
        "nombre": "%CO2",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 1
            }
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "porcentajeH2S": {
        "key": "porcentajeH2S",
        "nombre": "%H2S",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "potencialRedox": {
        "key": "potencialRedox",
        "nombre": "Potencial redox",
        "unidades": "mv",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "precipitaciones": {
        "key": "precipitaciones",
        "nombre": "Precipitaciones",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "0 ≤ Precipitaciones (mm/yr) < 305",
            "305 ≤ Precipitaciones (mm/yr) < 1270",
            "Precipitaciones (mm/yr) ≥ 1270",
            "Sin Información"
        ]
    },
    "presenciaBSRoBPAExterna": {
        "key": "presenciaBSRoBPAExterna",
        "nombre": "Presencia de BSR o BPA Externa ",
        "unidades": "UFC/mL",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presenciaDePuntosDeInyección": {
        "key": "presenciaDePuntosDeInyección",
        "nombre": "Presencia Puntos de Inyección",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "presionDeFallaPredecidaMAOPAE": {
        "key": "presionDeFallaPredecidaMAOPAE",
        "nombre": "Presión de falla predecida para la grieta",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "presionDeFallaPredecidaMAOPAI": {
        "key": "presionDeFallaPredecidaMAOPAI",
        "nombre": "Presión de falla predecida para la grieta (%MAOP)",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
        ],
        "funcionDeTransformacion": "paraPorcentajes"
    },
    "presionDeFallaPredecidaMYSAE": {
        "key": "presionDeFallaPredecidaMYSAE",
        "nombre": "Presión de falla predecida para la grieta (%SMYS)",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 1
            }
        ]
    },
    "presionDeFallaPredecidaMYSAI": {
        "key": "presionDeFallaPredecidaMYSAI",
        "nombre": "Presión de falla predecida para la grieta (%SMYS)",
        "unidades": "%",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            },
            {
                "operador": "<=",
                "valorTarget": 1
            }
        ]
    },
    "presionDeFallaPredecidaPSIAE": {
        "key": "presionDeFallaPredecidaPSIAE",
        "nombre": "Presión de falla predecida para la grieta",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presionDeFallaPredecidaPSIAI": {
        "key": "presionDeFallaPredecidaPSIAI",
        "nombre": "Presión de falla predecida para la grieta",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presionDeOperacionMaxima": {
        "key": "presionDeOperacionMaxima",
        "nombre": "Presion de operación máxima",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presionDeOperacionMinima": {
        "key": "presionDeOperacionMinima",
        "nombre": "Presion de operación mínima",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presionParcialCO2": {
        "key": "presionParcialCO2",
        "nombre": "Presión Parcial CO2",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "presionParcialH2S": {
        "key": "presionParcialH2S",
        "nombre": "Presión Parcial H2S",
        "unidades": "psi",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "procedimientosActualizadosOI": {
        "key": "procedimientosActualizadosOI",
        "nombre": "Existen Procedimientos Escritos Adecuados, Aprobados y Actualizados",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "produccionTotal": {
        "key": "produccionTotal",
        "nombre": "Producción total",
        "unidades": 'BFPD',
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "profundidadDeTuberia": {
        "key": "profundidadDeTuberia",
        "nombre": "Profundidad de la tubería",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Profundidad ≥ 1,5m",
            "1m ≤ Profundidad  < 1,5m",
            "0m<Profundidad < 1m",
            "Aérea"
        ]
    },
    "profundidadPicaduraCE": {
        "key": "profundidadPicaduraCE",
        "nombre": "Profundidad Picadura",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "profundidadPicaduraCI": {
        "key": "profundidadPicaduraCI",
        "nombre": "Profundidad Picadura",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "profunidadGrietaAE": {
        "key": "profunidadGrietaAE",
        "nombre": "Profundidad de la grieta",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "profunidadGrietaAI": {
        "key": "profunidadGrietaAI",
        "nombre": "Profundidad de la grieta",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "programaAbusoDeSustanciasOI": {
        "key": "programaAbusoDeSustanciasOI",
        "nombre": "Programa de Abuso de Sustancias",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "programaDeSeguridadOI": {
        "key": "programaDeSeguridadOI",
        "nombre": "Programa de Seguridad",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "radiografia": {
        "key": "radiografia",
        "nombre": "Radiografía",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "100% Radiografiado",
            "< 80% Radiografiado",
            "< 50% Radiografiado",
            "Sin Información"
        ]
    },
    "recubrimientoInterno": {
        "key": "recubrimientoInterno",
        "nombre": "Posee Recubrimiento Interno?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "regimenDeFlujo": {
        "key": "regimenDeFlujo",
        "nombre": "Régimen de Flujo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Flujo Turbulento",
            "Flujo Laminar",
            "Sin información"
        ]
    },
    "resistividadDelSuelo": {
        "key": "resistividadDelSuelo",
        "nombre": "Resistividad del suelo",
        "unidades": "ohm*cm",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "resultadoECDACE": {
        "key": "resultadoECDACE",
        "nombre": "Resultados del ECDA para Corrosión Externa",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "No indicaciones,Monitoreada pero sin indicaciones programadas,Monitoreada con indicaciones programadas,Indicaciones inmediatas a reclasificación de programas,Indicaciones inmediatas ,No testeado,Desconocido,>1 Falla/unidad de longitud"
        ]
    },
    "resultadoECDASCC": {
        "key": "resultadoECDASCC",
        "nombre": "Resultados del ECDA para Agrietamiento Externo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "No indicaciones,Monitoreada pero sin indicaciones programadas,Monitoreada con indicaciones programadas,Indicaciones inmediatas a reclasificación de programas,Indicaciones inmediatas,No testeado,Desconocido,>1 Falla/unidad de longitud"
        ]
    },
    "resultadosCorrosionInternaICDA": {
        "key": "resultadosCorrosionInternaICDA",
        "nombre": "Resultados del ICDA para Corrosión Interna",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "No indicaciones,Monitoreada pero sin indicaciones programadas,Monitoreada con indicaciones programadas,Indicaciones inmediatas a reclasificación de programas,Indicaciones inmediatas,No testeado,Desconocido,>1 Falla/unidad de longitud"
        ]
    },
    "resultadosICDASCC": {
        "key": "resultadosICDASCC",
        "nombre": "Resultados del ICDA para Agrietamiento Interno",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "No indicaciones,Monitoreada pero sin indicaciones programadas,Monitoreada con indicaciones programadas,Indicaciones inmediatas a reclasificación de programas,Indicaciones inmediatas,No testeado,Desconocido,>1 Falla/unidad de longitud"
        ]
    },
    "reunionesFuncionariosPublicos": {
        "key": "reunionesFuncionariosPublicos",
        "nombre": "Reuniones con funcionarios públicos una vez al año",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "SCADAOI": {
        "key": "SCADAOI",
        "nombre": "SCADA",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "señalizacionVia": {
        "key": "señalizacionVia",
        "nombre": "Condición",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Excelente",
            "Buena",
            "Promedio",
            "Mala",
            "Ninguna"
        ]
    },
    "sensibilidadAmbiental": {
        "key": "sensibilidadAmbiental",
        "nombre": "Sensibilidad Ambiental",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
    },
    "severidadDeLaGrietaExternaAE": {
        "key": "severidadDeLaGrietaExternaAE",
        "nombre": "Severidad de la grieta",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Grieta con cualquier longitud y profundidad menor a 10% del espesor de pared,Grieta con longitud máxima de 2 in y profundidad menor a 30% del espesor de pared,Presión de falla predecida mayor a 110%SMYS,Presión de falla predecida menor a 110%SMYS Y mayor a 125% MAOP,Presión de falla predecida menor a 125% MAOP Y mayor a 110%MAOP,Presión de falla predecida menor a 110%MAOP"
        ]
    },
    "severidadDeLaGrietaInternaAI": {
        "key": "severidadDeLaGrietaInternaAI",
        "nombre": "Severidad de la grieta",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Grieta con cualquier longitud y profundidad menor a 10% del espesor de pared,Grieta con longitud máxima de 2 in y profundidad menor a 30% del espesor de pared,Presión de falla predecida mayor a 110%SMYS,Presión de falla predecida menor a 110%SMYS Y mayor a 125% MAOP,Presión de falla predecida menor a 125% MAOP Y mayor a 110%MAOP,Presión de falla predecida menor a 110%MAOP"
        ]
    },
    "sistemaAutomaticoApagadoBombasOI": {
        "key": "sistemaAutomaticoApagadoBombasOI",
        "nombre": "Sistema autómatico de apagado de bombas en caso de detectar una sobrepresión",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    },
    "sistemaDeAislamiento": {
        "key": "sistemaDeAislamiento",
        "nombre": "Tipo de sistema de aislamiento",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "A",
            "B",
            "C",
            "D"
        ]
    },
    "sistemaDeControlDeCalidadDeConstruccion": {
        "key": "sistemaDeControlDeCalidadDeConstruccion",
        "nombre": "Sistema de Control de calidad Durante la construcción",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Bueno",
            "Regular",
            "Pobre",
            "Ausente"
        ]
    },
    "sistemaDeDeteccion": {
        "key": "sistemaDeDeteccion",
        "nombre": "Tipo de sistema de deteccion",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "A",
            "B",
            "C",
            "D"
        ]
    },
    "sistemaDeMitigacion": {
        "key": "sistemaDeMitigacion",
        "nombre": "Sistema de mitigacion",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Purga de inventario acoplada con sistema de aislamiento clase B o superior",
            "Sistema de innudación de agua contra incedios y monitores",
            "Solo monitores de agua contra incendios",
            "Sistema de espuma",
            ""
        ]
    },
    "sistemaDeProteccionCatodica": {
        "key": "sistemaDeProteccionCatodica",
        "nombre": "Posee Sistema de protección catódica?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si,No"
        ]
    },
    "SistemaDeProteccionCatodicaProtegido": {
        "key": "SistemaDeProteccionCatodicaProtegido",
        "nombre": "De acuerdo a inspección, el sistema se encuentra protegido?",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Buenas condiciones-Sin defectos,Regular-Daños localizados,Malo-Daños generalizados"
        ]
    },
    "solidosSuspendidosTotales": {
        "key": "solidosSuspendidosTotales",
        "nombre": "Sólidos suspendidos totales\n(ppm)",
        "unidades": "ppm",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "tamañoPromedioAnomaliasILICE": {
        "key": "tamañoPromedioAnomaliasILICE",
        "nombre": "Tamaño promedio de anomalías",
        "unidades": "mm^2",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "tamañoPromedioAnomaliasILICI": {
        "key": "tamañoPromedioAnomaliasILICI",
        "nombre": "Tamaño promedio de anomalías",
        "unidades": "mm^2",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "tecnicaDeInspeccionAE": {
        "key": "tecnicaDeInspeccionAE",
        "nombre": "Técnica de Inspección",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "tecnicaDeInspeccionAI": {
        "key": "tecnicaDeInspeccionAI",
        "nombre": "Técnica de Inspección",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "tecnicaDeInspeccionCE": {
        "key": "tecnicaDeInspeccionCE",
        "nombre": "Técnica de Inspección",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "UT-ESPESORES"
        ]
    },
    "tecnicaDeInspeccionCI": {
        "key": "tecnicaDeInspeccionCI",
        "nombre": "Técnica de Inspección",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "tecnicaDeInspeccionFT": {
        "key": "tecnicaDeInspeccionFT",
        "nombre": "Técnica de Inspección",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "tecnicaDeInspeccionILI": {
        "key": "tecnicaDeInspeccionILI",
        "nombre": "Técnica de inspección ILI",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "USWM,MFL,TFI,Sin inspección"
        ]
    },
    "temperaturaDeDiseño": {
        "key": "temperaturaDeDiseño",
        "nombre": "Temperatura de diseño",
        "unidades": "°f",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "tipoDeAtmosfera": {
        "key": "tipoDeAtmosfera",
        "nombre": "Tipo de atmósfera ",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Árido/Seco,Templado (humedad y precipitación moderada),Alta Humedad,Marino,Aledaño a torres de agua de enfriamiento,Ambientes industriales"
        ]
    },
    "tipoDeFluido": {
        "key": "tipoDeFluido",
        "nombre": "Tipo de fluido",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Gas",
            "liquido"
        ]
    },
    "tipoDeJunta": {
        "key": "tipoDeJunta",
        "nombre": "Tipo de Junta",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "SMAW",
            "Dresser Coupling",
            "Soldadura de acetileno",
            "Mech Coupling",
            "Campana y espiga",
            "Con bridas",
            "Sin Información"
        ]
    },
    "tipoDeRecubrimientoExterno": {
        "key": "tipoDeRecubrimientoExterno",
        "nombre": "Tipo de recubrimiento externo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "FBE,Tricapa Polietileno,Tricapa Polipropileno,Polietileno extruido,Aplicado X-Tru,Epoxi Aplicado en Campo,Pintura Bituminosa,Asfáltica aplicada en fabrica,Alquitrán de Ulla ,Cera,Lana de Vidrio y Betun,Desconocido,Polyken ,Cinta epóxica y masilla,Asfáltica aplicada en campo,Sin recubrimiento"
        ]
    },
    "tipoDeRecubrimientoInterno": {
        "key": "tipoDeRecubrimientoInterno",
        "nombre": "Tipo de recubrimiento interno",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "tipoDeServidumbre": {
        "key": "tipoDeServidumbre",
        "nombre": "Tipo de Servidumbre",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Servidumbre Exclusiva",
            "Servidumbre No Exclusiva o compartida",
            "Servidumbre propiedad de tercero o restringida",
            "Sin Información"
        ]
    },
    "tipoDeSuelo": {
        "key": "tipoDeSuelo",
        "nombre": "Tipo de suelo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Arena Seca,Piedra Caliza,Arena Magra,Grava,Limos,Arcilla,Sin Información"
        ]
    },
    "totalAnomaliasILICE": {
        "key": "totalAnomaliasILICE",
        "nombre": "Total Anomalías por corrosión externa en long. Inspeccionada",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "totalAnomaliasILICI": {
        "key": "totalAnomaliasILICI",
        "nombre": "Total Anomalías por corrosión interna en long. Inspeccionada",
        "unidades": "in",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "ubicacionGrietaAE": {
        "key": "ubicacionGrietaAE",
        "nombre": "Ubicación grieta",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "ubicacionGrietaAI": {
        "key": "ubicacionGrietaAI",
        "nombre": "Ubicación grieta",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "ubicacionGrietaFT": {
        "key": "ubicacionGrietaFT",
        "nombre": "Ubicación grieta",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "ubicacionMinimoEspesorMedidoCE": {
        "key": "ubicacionMinimoEspesorMedidoCE",
        "nombre": "Ubicación Mínimo Espesor Medido",
        "unidades": null,
        "categoria": null,
        "tipo": null,
        "condicionesDeLaVariable": []
    },
    "ubicacionMinimoEspesorMedidoCI": {
        "key": "ubicacionMinimoEspesorMedidoCI",
        "nombre": "Ubicación Mínimo Espesor Medido",
        "unidades": null,
        "categoria": "valorAbierto",
        "tipo": "texto",
        "condicionesDeLaVariable": []
    },
    "usoDelSuelo": {
        "key": "usoDelSuelo",
        "nombre": "Uso del suelo",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Sin desarrollo",
            "Actividad Agropecuaria (Agricultura, ganadería, piscicultura, etc)",
            "Residencial",
            "Comercial",
            "Industrial"
        ]
    },
    "vandalismoCiberAtaque": {
        "key": "vandalismoCiberAtaque",
        "nombre": "Ciber-Ataques",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "vandalismoDetonaciones": {
        "key": "vandalismoDetonaciones",
        "nombre": "Detonaciones (Sabotaje)",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "vandalismoGraffiti": {
        "key": "vandalismoGraffiti",
        "nombre": "Graffiti",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "vandalismoSabotajeEquipos": {
        "key": "vandalismoSabotajeEquipos",
        "nombre": "Sabotaje de equipos",
        "unidades": null,
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadCrecimientoGrietaEstimadaAE": {
        "key": "velocidadCrecimientoGrietaEstimadaAE",
        "nombre": "Velocidad  crecimiento de grieta ESTIMADA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadCrecimientoGrietaEstimadaAI": {
        "key": "velocidadCrecimientoGrietaEstimadaAI",
        "nombre": "Velocidad  crecimiento de grieta ESTIMADA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadCrecimientoGrietaEstimadaFT": {
        "key": "velocidadCrecimientoGrietaEstimadaFT",
        "nombre": "Velocidad  crecimiento de grieta ESTIMADA (mpy)",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadCrecimientoGrietaMedidaAE": {
        "key": "velocidadCrecimientoGrietaMedidaAE",
        "nombre": "Velocidad de  crecimiento de grieta MEDIDA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "abscisaInicial": {
        "key": "abscisaInicial",
        "nombre": "Abscisa Inicial",
        "unidades": "km",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "abscisaFinal": {
        "key": "abscisaFinal",
        "nombre": "Abscisa Final",
        "unidades": "km",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cmlInicial": {
        "key": "cmlInicial",
        "nombre": "CML Inicial",
        "unidades": "null",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "cmlFinal": {
        "key": "cmlFinal",
        "nombre": "CML Final",
        "unidades": "null",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadCrecimientoGrietaMedidaAI": {
        "key": "velocidadCrecimientoGrietaMedidaAI",
        "nombre": "Velocidad de  crecimiento de grieta MEDIDA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadCrecimientoGrietaMedidaFT": {
        "key": "velocidadCrecimientoGrietaMedidaFT",
        "nombre": "Velocidad de  crecimiento de grieta MEDIDA (mpy)",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionEstimadaCE": {
        "key": "velocidadDeCorrosionEstimadaCE",
        "nombre": "Velocidad de corrosión externa ESTIMADA ",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionEstimadaCI": {
        "key": "velocidadDeCorrosionEstimadaCI",
        "nombre": "Velocidad de corrosión Interna ESTIMADA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionInternaCupones": {
        "key": "velocidadDeCorrosionInternaCupones",
        "nombre": "Velocidad de Corrosión Interna  Cupones (General)",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionInternaProbeta": {
        "key": "velocidadDeCorrosionInternaProbeta",
        "nombre": "Velocidad de Corrosión Interna  Probeta (General)",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionMedidaGeneralCE": {
        "key": "velocidadDeCorrosionMedidaGeneralCE",
        "nombre": "Velocidad de Corrosión Externa  MEDIDA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionMedidaGeneralCI": {
        "key": "velocidadDeCorrosionMedidaGeneralCI",
        "nombre": "Velocidad de Corrosión Interna  MEDIDA",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionPicaduraCE": {
        "key": "velocidadDeCorrosionPicaduraCE",
        "nombre": "Velocidad de Corrosión En Picadura",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "velocidadDeCorrosionPicaduraCI": {
        "key": "velocidadDeCorrosionPicaduraCI",
        "nombre": "Velocidad de Corrosión Interna En Picadura ",
        "unidades": "mpy",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "volumenEnInventario": {
        "key": "volumenEnInventario",
        "nombre": "Volumen de inventario (BBL)",
        "unidades": "BBL",
        "categoria": "valorÚnico",
        "tipo": "númerico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ]
    },
    "zonaInundable": {
        "key": "zonaInundable",
        "nombre": "Zona Inundables",
        "unidades": null,
        "categoria": "valorDeSeleccionMultiple",
        "tipo": "texto",
        "condicionesDeLaVariable": [],
        "listaDeOpciones": [
            "Si",
            "No"
        ]
    }
}