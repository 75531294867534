export const reemplazarTildes = (palabra) => {
    console.log(palabra)
    let nuevaPalabra = [];

    for (let i = 0; i < palabra.length; i++) {
        switch (palabra[i]) {
            case "á":
                nuevaPalabra += "a";
                break;
            case "é":
                nuevaPalabra += "e";
                break;
            case "í":
                nuevaPalabra += "i";
                break;
            case "ó":
                nuevaPalabra += "o";
                break;
            case "ú":
                nuevaPalabra += "u";
                break;
            default:
                nuevaPalabra += palabra[i];
        }
    }

    return nuevaPalabra;
}