import React from "react";
import { FaAngleDown} from 'react-icons/fa'
import { IoIosFunnel } from "react-icons/io";
import { XAxis, YAxis, Tooltip, CartesianGrid, Legend, Brush, ResponsiveContainer, ComposedChart, Area, Line, } from "recharts";
import {  CustomInput, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";

const objectPath = require("object-path");

class GraficoCartesianoCompuesto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numeroDeAbscisas: 0,
            checks: undefined,
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.crearGraficas()}
            </React.Fragment>
        )

    }

    componentWillMount = () => {
        this.arrayDeChecks = new Set();
        this.setState({
            check: this.arrayDeChecks
        })
    }

    //Funciones auxiliares para ajuste de datos:
    ajustarDataDeNodosParaGrafico = (props) => {
        let nodosAjustados = this.obtenerNodosConAbscisaEnRaiz(props.nodos, props.xAxisKey).sort((a, b) => (a.valorEnAbscisa > b.valorEnAbscisa) ? 1 : -1);
        let configEnY = props.componentesGraficos.map((componenteDeGrafica, i) => { return { key: componenteDeGrafica.dataKey, funcionDeFormato: componenteDeGrafica.funcionDeFormato }; });

        let prevKeys = {
          "resultadosDeLaValoracion.probabilidad.agrietamientoFatiga.pof": undefined,
          "resultadosDeLaValoracion.probabilidad.agrietamientoSCC.pof": undefined,
          "resultadosDeLaValoracion.probabilidad.corrosionInterna.pof": undefined,
          "resultadosDeLaValoracion.probabilidad.corrosionExterna.pof": undefined,
      };
        nodosAjustados.forEach((nodo, i) => {
        
            configEnY.forEach((itemDeConfiguracion, i) => {
                const valorCrudo = objectPath.get(nodo, itemDeConfiguracion.key);
                const valorTarget = (itemDeConfiguracion.funcionDeFormato) ? itemDeConfiguracion.funcionDeFormato(valorCrudo) : valorCrudo;
                if(valorTarget){
                    nodo[itemDeConfiguracion.key] = valorTarget;
                    prevKeys[itemDeConfiguracion.key] = valorTarget
                }else{
                    nodo[itemDeConfiguracion.key] = prevKeys[itemDeConfiguracion.key]
                    prevKeys[itemDeConfiguracion.key] = undefined;
                }
            });
        });
            
        return nodosAjustados;
    }

    crearGraficas = () => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'space-between' ,width: "100%" }}>
                    <UncontrolledDropdown inNavbar >
                        <DropdownToggle nav className='p-0'>
                            <IoIosFunnel/>
                            Filtrar
                            <FaAngleDown />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg rm-pointers">
                            <div  className="dropdown-menu-header">
                                <div style={{backgroundImage: 'linear-gradient(to right, #141e30, #243b55)'}} className="dropdown-menu-header-inner pt-2 pb-2">
                                    <div className="menu-header-content text-left">
                                        <p style={{color: '#ffffff', fontSize: 14}} className="menu-header-title ">Selecciona los items a filtrar</p>
                                    </div>
                                </div>
                            </div>
                            {this.crearInputsSegunAbscisas()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <p style={{ fontSize: 12, textTransform: "uppercase" }}>
                        <b>{this.props.titulo}</b>
                    </p>
                </div>
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart data={this.ajustarDataDeNodosParaGrafico(this.props)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend />
                        <XAxis dataKey={this.props.xAxisKey} />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Brush dataKey={this.props.xAxisKey} height={20} stroke="#DADADA" />

                        {this.obtenerComponentesInternos(this.props)}
                    </ComposedChart>
                </ResponsiveContainer>
            </React.Fragment>
        )
    }

    crearInputsSegunAbscisas = () => {
        let inputs = [];
        this.props.componentesGraficos.forEach((itemGrafico, i) => {
            if(itemGrafico.dataKey !== 'variablesDeLaInspeccion.areaDeAltaConsecuencia.valor'){
                inputs.push(
                    <React.Fragment key={itemGrafico.dataKey}>
                        <DropdownItem>
                            <CustomInput
                                type='checkbox'
                                id={`${itemGrafico.dataKey}`}
                                label="&nbsp;"
                                disabled={false}
                                onChange={(e) => {
                                    this.cambiarArrayDeChecks(itemGrafico);
                                }}
                                defaultChecked={itemGrafico.check}
                            />
                            {itemGrafico.name}
                        </DropdownItem>
                    </React.Fragment>
                )
            }
        });
        return inputs
    }

    cambiarArrayDeChecks = (itemGrafico) => {
        if(!this.arrayDeChecks.has(itemGrafico.dataKey)){
            this.arrayDeChecks.add(itemGrafico.dataKey);
            this.setState({
                check: this.arrayDeChecks
            })
        } else if(this.arrayDeChecks.has(itemGrafico.dataKey)){
            this.arrayDeChecks.delete(itemGrafico.dataKey);
            this.setState({
                check: this.arrayDeChecks
            })
        }
    }

    obtenerNodosConAbscisaEnRaiz = (nodos, xAxisKey) => {
        return nodos.map((nodo, i) => {
            return { ...nodo, valorEnAbscisa: objectPath.get(nodo, xAxisKey) };
        });
    }

    //Funciones auxiliares para renderizado:
    obtenerComponentesInternos = (props) => {  
        return props.componentesGraficos.map((itemGrafico, i) => {
            let renderDelGrafico = undefined;
            if (!this.arrayDeChecks.has(itemGrafico.dataKey)) {
                switch (itemGrafico.tipo) {
                    case "area":
                        renderDelGrafico = (<Area {...itemGrafico} key={"itemGrafico." + i} type="stepAfter" />);
                        break;
                    case "line":
                        renderDelGrafico = (<Line {...itemGrafico} key={"itemGrafico." + i} type="stepAfter" />);
                        break;
                    default:
                        renderDelGrafico = (<React.Fragment key={"itemGrafico." + i} />);
                        break;
                }
            }
            return renderDelGrafico;
        })
    }

    
}


export default GraficoCartesianoCompuesto;