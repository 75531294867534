import agruparPorcentajes from './agruparPorcentajes.js';

const organizarDataGraficaDeTorta = (data) => {
    const datosParaGrafica = [];

    const aerea = data.filter((item) => item.etiqueta === 'Aérea');
    const porcentajeTotalAerea = parseFloat(agruparPorcentajes(aerea));

    const enterrada = data.filter((item) => item.etiqueta === 'Enterrada');
    const porcentajeEnterrada =  parseFloat(agruparPorcentajes(enterrada));

    const otra = data.filter((item) => item.etiqueta === 'Otra');
    const porcentajeOtra = parseFloat(agruparPorcentajes(otra));


    datosParaGrafica.push(
        {color: "#1E96FC", etiqueta: "Aérea", valor: porcentajeTotalAerea || 0},
        {color: "#f5cc00", etiqueta: "Enterrada", valor: porcentajeEnterrada || 0},
        {color: "#6D15D0", etiqueta: "Otra", valor: porcentajeOtra || 0},
        )
    return datosParaGrafica;
}

export default organizarDataGraficaDeTorta
