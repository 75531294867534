import { firestoreDB } from "../../../../../firebase";

let anomalias = [];
let anomaliasAbolladuras = [];
let distanciaCaALosHombros = [];
let localizacionDeHombros = [];

const obtenerDatosDeAnomalias = (datosDeAnomalias, idDeODT) => {
    return new Promise((resolve, rejects) => {
        let arregloAnomalias = [];
        let arregloCamisas = [];
        Object.keys(datosDeAnomalias).forEach(async (keys) => {
            arregloAnomalias.push(obtenerInformacionAnomalia(keys, idDeODT))
        })
        Promise.all(arregloAnomalias)
            .then(resultados => {
                let anomaliasGrietas = []
                let ensayosGrietas = []
                let generalGrietas = []
                resultados.forEach((anomalia) => {
                    anomalia[0].forEach(element => {
                        switch (element.tipoDeAnomalia) {
                            case 'PERDIDA_DE_METAL':
                                datosParaPerdidaDeMetal(element)
                                break;
                            case 'ABOLLADURA':
                                datosParaAbolladuras(element)
                                break;
                            case 'GRIETA':
                                anomaliasGrietas.push(element)
                                break;
                            case 'ENSAYO_GRIETA':
                                ensayosGrietas.push(element)
                                break;
                            case 'BENDING_STRAIN':
                                arregloCamisas.push(datosParaBending(element, idDeODT))
                                break;
                            case 'CAMISA':
                                arregloCamisas.push(datosParaAnomaliasConSubAnomalias(element, idDeODT, anomalia[1]))
                                break;
                            case 'CAMISA_SOBRE_ANILLOS':
                                arregloCamisas.push(datosParaAnomaliasConSubAnomalias(element, idDeODT, anomalia[1]))
                                break;
                            case 'PARCHE':
                                arregloCamisas.push(datosParaAnomaliasConSubAnomalias(element, idDeODT, anomalia[1]))
                                break;
                            default:
                                generalGrietas.push(element)
                                break;
                        }
                    });
                });
                Promise.all(arregloCamisas)
                    .then(results => {
                        let anomaliasCamisas = []
                        let anomaliasBending = []
                        results.forEach(element2 => {
                            switch (element2.tipoDeAnomalia) {
                                case 'BENDING_STRAIN':
                                    anomaliasBending.push(element2)
                                    break;
                                case 'CAMISA':
                                    anomaliasCamisas.push(element2)
                                    break;
                                case 'CAMISA_SOBRE_ANILLOS':
                                    anomaliasCamisas.push(element2)
                                    break;
                                case 'PARCHE':
                                    anomaliasCamisas.push(element2)
                                    break;
                                default:
                                    break;
                            }
                        });
                        let nuevoEstado = {
                            anomalias: anomalias,
                            distanciaCaALosHombros: distanciaCaALosHombros,
                            localizacionDeHombros: localizacionDeHombros,
                            anomaliasAbolladuras: anomaliasAbolladuras,
                            generalGrietas: generalGrietas,
                            anomaliasGrietas: anomaliasGrietas,
                            ensayosGrietas: ensayosGrietas,
                            anomaliasCamisas: anomaliasCamisas,
                            anomaliasBending: anomaliasBending,
                        }
                        anomalias = [];
                        anomaliasAbolladuras = [];
                        distanciaCaALosHombros = [];
                        localizacionDeHombros = [];
                        resolve(nuevoEstado);
                    })
            
        })
        .catch(e => {

        })

    })

}

const obtenerInformacionAnomalia = async (tipoDeAnomalia, idDeODT) => {
    let anomalias = []
    let dataEnsayos
    let dataGeneral
    let dataAnomalia = await firestoreDB.collection('ordenesDeTrabajo').doc(idDeODT)
        .collection('anomalias').doc(tipoDeAnomalia).collection('anomalias')
        .get()
    if (tipoDeAnomalia === 'grietas') {
        dataGeneral = await firestoreDB.collection('ordenesDeTrabajo').doc(idDeODT)
            .collection('anomalias').doc(tipoDeAnomalia)
            .get()
            anomalias.push(dataGeneral.data())
        dataEnsayos = await firestoreDB.collection('ordenesDeTrabajo').doc(idDeODT)
            .collection('anomalias').doc(tipoDeAnomalia).collection('ensayos')
            .get()
        dataEnsayos.forEach(async (element) => {
            let data = element.data()
            anomalias.push(data)
        });
    }
    dataAnomalia.forEach(async (element) => {
        let data = element.data()
        anomalias.push(data)
    });
    return [anomalias, tipoDeAnomalia]
}
const datosParaAbolladuras = (anomalia) => {
    if (Object.keys(anomalia.detalle).length > 1) {
        anomaliasAbolladuras.push({ ...anomalia.detalle, 'idDeAnomalia':anomalia.idDeAnomalia });
        distanciaCaALosHombros.push(
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                H: "H1",
                distanciaCAALosHombros: anomalia.detalle.distanciaCAALosHombrosH1,
                mm: "mm"
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                H: "H2",
                distanciaCAALosHombros: anomalia.detalle.distanciaCAALosHombrosH2,
                mm: "mm"
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                H: "H3",
                distanciaCAALosHombros: anomalia.detalle.distanciaCAALosHombrosH3,
                mm: "mm"
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                H: "H4",
                distanciaCAALosHombros: anomalia.detalle.distanciaCAALosHombrosH4,
                mm: "mm"
            },
        )
        localizacionDeHombros.push(
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                hombro: "H1",
                distanciaCAALosHombros: anomalia.detalle.localizaciónDeHombrosH1Distancia,
                posicionHoraria: anomalia.detalle.localizaciónDeHombrosH1Poscicion,
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                hombro: "H2",
                distanciaCAALosHombros: anomalia.detalle.localizaciónDeHombrosH2Distancia,
                posicionHoraria: anomalia.detalle.localizaciónDeHombrosH2Poscicion,
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                hombro: "H3",
                distanciaCAALosHombros: anomalia.detalle.localizaciónDeHombrosH3Distancia,
                posicionHoraria: anomalia.detalle.localizaciónDeHombrosH3Poscicion,
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                hombro: "H4",
                distanciaCAALosHombros: anomalia.detalle.localizaciónDeHombrosH4Distancia,
                posicionHoraria: anomalia.detalle.localizaciónDeHombrosH4Poscicion,
            },
            {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                hombro: "CA",
                distanciaCAALosHombros: anomalia.detalle.localizaciónDeHombrosCADistancia,
                posicionHoraria: anomalia.detalle.localizaciónDeHombrosCAPoscicion,
            },
        )
    }

}

const datosParaPerdidaDeMetal = (anomalia) => {
    if (Object.keys(anomalia.detalle).length > 1) {
        anomalias.push({
            "anomalia": {
                id: anomalia.idDeAnomalia,
                evaluacionOComparacion: anomalia.detalle.evaluacionOComparacion,
                programadaOAdiccional: anomalia.detalle.programadaOAdiccional,
                tipoDeAnomalia: anomalia.tipoDeAnomalia,
            },
            "datosSuministradosCorridaILI": {
                idAnomaliaILI: anomalia.detalle.idAnomaliaILI,
                evento: anomalia.detalle.evento,
                distanciaAlRegistro: anomalia.detalle.distanciaAlRegistro,
                posicionDelDuctoILI: anomalia.detalle.posicionDelDuctoILI,
                longitudAnomialia: anomalia.detalle.longitudAnomialia,
                anchoAnomialia: anomalia.detalle.anchoAnomialia,
                porcentajeReduccionEspesor: anomalia.detalle.porcentajeReduccionEspesor,
                paredInterna: anomalia.detalle.paredInterna,
            },
            "datosSuministradosPorMedicion": {
                distanciaAlRegistroMedicion: anomalia.detalle.distanciaAlRegistroMedicion,
                distanciaJuntaAnterior: anomalia.detalle.distanciaJuntaAnterior,
                distanciaJuntaPosterior: anomalia.detalle.distanciaJuntaPosterior,
                posicionDelDuctoILIDistanciaMedicion : anomalia.detalle.posicionDelDuctoILIDistanciaMedicion,
                posicionDelDuctoILIHoraMedicion: anomalia.detalle.posicionDelDuctoILIHoraMedicion,
                longitudAnomaliaMedicion: anomalia.detalle.longitudAnomaliaMedicion,
                anchoAnomaliaMedicion: anomalia.detalle.anchoAnomaliaMedicion,
                profundidad: anomalia.detalle.profundidad,
                porcentajeReduccionEspesorMedicion: anomalia.detalle.porcentajeReduccionEspesorMedicion,
                remanente: anomalia.detalle.remanente,
                interactuaConJunta: anomalia.detalle.interactuaConJunta,
                tipoAnomalia: anomalia.detalle.tipoAnomalia,
                clasificacionAnomalia: anomalia.detalle.clasificacionAnomalia,
                paredInternaMedicion: anomalia.detalle.paredInternaMedicion,
            },
            "evaluacionASME": {
                presionSegura: anomalia.detalle.presionSegura,
                ERFs: anomalia.detalle.ERFs,
                evaluacionDeLaAnomalia: anomalia.detalle.evaluacionDeLaAnomalia,
            },
            "detalleGrilla":{
                columns:anomalia.detalleGrilla.columns,
                rows:anomalia.detalleGrilla.rows,
                export:anomalia.detalleGrilla.export,
                dataGrilla:anomalia.detalleGrilla.dataGrilla,
            }
        });
    }
}

const datosParaAnomaliasConSubAnomalias = (anomalia, idDeODT, tipoDeAnomalia) => {
    return new Promise(async (resolve, rejects) => {
        let subAnomalias;

        if (anomalia.idDeAnomalia) {
            subAnomalias = await obtenerSubAnomalias(anomalia.idDeAnomalia, idDeODT, tipoDeAnomalia);
        }
        const context = { ...anomalia, "listadoDeSubAnomalias": subAnomalias }
        resolve(context)
    })

}

const obtenerSubAnomalias = (idDeAnomalia, idDeODT, tipoDeAnomalia) => {
    return new Promise((resolve, rejects) => {
        let guardarDatos = [];
        firestoreDB.collection('ordenesDeTrabajo').doc(idDeODT)
            .collection('anomalias').doc(tipoDeAnomalia).collection('anomalias').doc(idDeAnomalia)
            .collection('subAnomalias')
            .get()
            .then(result => {
                const data = result;
                if (!data.empty) {
                    data.forEach(element => {
                        guardarDatos.push(element.data());
                    })
                }
                resolve(guardarDatos);
                guardarDatos = [];
            })
            .catch(e => {
                console.log('error: ', e);
            })

    })

}

const datosParaBending = (anomalia, idDeODT) => {
    return new Promise(async (resolve, rejects) => {
        let subAnomalias;
        if (anomalia.idDeAnomalia) {
            subAnomalias = await obtenerJuntas(anomalia.idDeAnomalia, idDeODT);
        }
        const context = { ...anomalia, "listadoDeSubAnomalias": subAnomalias }
        resolve(context)
    })

}

const obtenerJuntas = (idDeAnomalia, idDeODT) => {
    return new Promise((resolve, rejects) => {
        let guardarDatos = [];
        firestoreDB.collection('ordenesDeTrabajo').doc(idDeODT)
            .collection('anomalias').doc(idDeAnomalia)
            .collection('subAnomalias')
            .get()
            .then(result => {
                const data = result;
                if (!data.empty) {
                    data.forEach(element => {
                        guardarDatos.push(element.data());
                    })
                }
                resolve(guardarDatos);
                guardarDatos = [];
            })
            .catch(e => {
                console.log('error: ', e);
            })

    })

}

export default obtenerDatosDeAnomalias;