import { firestoreAuth } from '../../firebase';

/**
 * @param link link de la cloud function que se genera cuando se corre en local.
 * @param dataQwery data que se va a recibir en el back
 */

const cloudFunctionLocal = async ({ link, dataQwery }) => {
  const { token } = await firestoreAuth.currentUser.getIdTokenResult();
  const result = await fetch(link, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    method: 'POST',
    body: JSON.stringify({
      data: dataQwery || {}
    })
  });
  const { status } = result;
  const data = await result.json();
  if (data.error) throw data.error;
  if (status !== 200) throw data.message;
  // la cloud function retorna la respuesta en un objeto data.
  return { data: data.result };
};

export default cloudFunctionLocal;
