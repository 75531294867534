import React from 'react';
import { connect } from 'react-redux';

import { Breadcrumb, Row, Col, Label, BreadcrumbItem, Input } from 'reactstrap';
import { Select, MenuItem } from '@material-ui/core';
import { firebaseFunctions } from '../../../firebase';

import { glosarioDeInputsODTHija } from './scripts/glosarioVariablesDeInput';

import Preloader from '../../../utilidades/componentes/plantillaBase/Preloader';
//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

import { obtenerValidacionDeVariable } from './scripts/paraValidadVariablesFormulario';

const cargarOrdenDeTrabajoHija = firebaseFunctions.httpsCallable('odt_crearOrdenDeTrabajoHija');

const estiloLabel = 'mb-0 mt-2'

class VisualizadorFormularioDeILI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            //Identificación Anomalia
            numeroDeFormato: this.props.ordenMadre,
            index: 1,
            ordenDeTrabajo: this.props.ordenMadre,
            tecnico: '',
            correo: '',
            estado: '',
            fechaEstimadaDeProgramacion: new Date(),
            fechaDeActualizacion: new Date(),
            descripcionODT: '', 


            //Mensajes Error
            mensajeNumeroDeFormato: '',
            mensajeIndex: '',
            mensajeOrdenDeTrabajo: '',
            mensajeTecnico: '',
            mensajeCorreo: '',
            mensajeEstado: '',
            mensajeFechaEstimadaDeProgramacion: '',
            mensajeDescripcionODT: '',


            cargando: false,

            error: "",
            estadoValidacion: true
        }
    }

    componentDidMount(){
        let newArr = this.props.listaDeFormatos
        newArr.sort(function(a, b) {
            return parseInt(a.id.split('-')[1]) - parseInt(b.id.split('-')[1]);
        });
        if (Object.keys(newArr).length>0) this.setState({index:parseInt(newArr[Object.keys(newArr).length-1 ].id.split('-')[1])+1})
    }

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.submitDelFormulario}>
                    <Row>
                        {this.generarVariosInputs(this.obtenerGlosario())}
                    </Row>
                    <br />
                    <Input type='submit' value="Guardar" style={{ textAlign: 'center', color: '#ffffff', width: '25%', float: 'right' }} className={'bg-royal pusheable-button mb-3'} />
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    generarVariosInputs = (glosarioDeInputs) => {
        let listaDeInputs = [];
        for (const input of Object.keys(glosarioDeInputs)) {
            listaDeInputs.push(this.generarInputSegunGlosario(input, glosarioDeInputs))
        }
        return listaDeInputs;
    }

    generarInputSegunGlosario = (input, glosarioDeInputs) => {
        for (let key in glosarioDeInputs) {
            if (key === input) {
                switch (glosarioDeInputs[key].type) {
                    case 'text':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label for={key} className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    type={glosarioDeInputs[key].type}
                                    name={key}
                                    // defaultValue={''}
                                    value={this.state[key]}
                                    onChange={(e) => { this.cambiosEnInput(e, glosarioDeInputs[key]) }}
                                    required
                                />
                                <small style={{ color: 'red' }} >{(this.state[glosarioDeInputs[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                            </Col>
                        );
                    case 'email':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label for={key} className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    type={glosarioDeInputs[key].type}
                                    name={key}
                                    // defaultValue={this.state[key]}
                                    value={this.state[key]}
                                    onChange={(e) => { this.cambiosEnInput(e, glosarioDeInputs[key]) }}
                                    required
                                />
                                <small style={{ color: 'red' }} >{(this.state[glosarioDeInputs[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                            </Col>
                        );
                    case 'fecha':
                        return (
                            <Col key={`date ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    key={key}
                                    type="date"
                                    name={key}
                                    // defaultValue={this.state[key].toISOString().split("T")[0]}
                                    value={this.state[key].toISOString().split("T")[0]}
                                    onChange={(e) => this.cambiosEnInput(e, glosarioDeInputs[key], 'date')}
                                    style={{ width: '100%', boxShadow: 'none' }}
                                    required
                                />
                            </Col>
                        );
                    case 'select':
                        if(this.state[glosarioDeInputs[key].padre] !== ''){
                            return (
                                <Col key={`select ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                    <Label className={estiloLabel} >{glosarioDeInputs[key].contenido}</Label>
                                    <Select
                                        name={key}
                                        defaultValue={this.state[key]}
                                        value={this.state[key]}
                                        onChange={this.cambiosEnInput}
                                        style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                                        variant="outlined"
                                    >
                                        {this.obtenerListadoDeOpciones(key, glosarioDeInputs[key])}
                                    </Select>
                                    <small>{this.state[glosarioDeInputs[key].mensaje]}</small>
                                </Col>
                            )
                        }else if(this.state[glosarioDeInputs[key].padre] === ''){
                            return (
                                <Col key={`select ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                    <Label className={estiloLabel} style={{color: '#c9c9c9'}}>{glosarioDeInputs[key].contenido}</Label>
                                    <Select
                                        name={key}
                                        defaultValue={this.state[key]}
                                        value={this.state[key]}
                                        onChange={this.cambiosEnInput}
                                        style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                                        variant="outlined"
                                        disabled
                                    >
                                        {this.obtenerListadoDeOpciones(key, glosarioDeInputs[key])}
                                    </Select>
                                    <small>{this.state[glosarioDeInputs[key].mensaje]}</small>
                                </Col>
                            )
                        }
                        break
                    case 'textarea':
                        return(
                        <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                            <Label for={key} className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                            <Input
                                type={glosarioDeInputs[key].type}
                                name={key}
                                // defaultValue={this.state[key]}
                                value={this.state[key]}
                                onChange={(e) => { this.cambiosEnInput(e, glosarioDeInputs[key]) }}
                                required
                            />
                            <small style={{ color: 'red' }} >{(this.state[glosarioDeInputs[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                        </Col>
                        )
                    case 'separador':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                            </Col>
                        )
                    default:
                        return (
                            <Col key={`titulo ${key}`} lg='12' className='pt-4'>
                                <Breadcrumb>
                                    <BreadcrumbItem className='BreadcrumbItem-color'>{glosarioDeInputs[key].name}</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        )
                }
            }
        }
    }

    obtenerGlosario = () => { 
        return glosarioDeInputsODTHija
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        if(nodo.obtenerOpciones !== undefined){
            let opciones = nodo.obtenerOpciones(this.state)
            opciones.forEach((opcion, i)=> {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }else{
            nodo.opciones.forEach((opcion, i) =>{
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }
    }

    cargarOrdenDeTrabajo = (registroActualizado) => {
        this.setState({ cargando: true })
        cargarOrdenDeTrabajoHija(registroActualizado)
        .then(result =>{
            switch(result.data.status){
                case "ORDEN_HIJA_CREADA":
                    this.setState({ cargando: false },()=>{
                        this.avisoDeGuardadoSatisfactorio('¡La orden de trabajo se creó satisfactoriamente!');
                        setTimeout(()=>{ this.props.cerrarModal() }, 500);
                    })
                break;
                case "ORDEN_NO_CREADA":
                    this.setState({ cargando: false }, ()=>{
                        this.avisoDeGuardadoFallido('No se pudo crear la orden de trabajo');
                    })
                    
                break;
                case "ORDEN_EXISTENTE":
                    this.setState({ cargando: false }, ()=>{
                        this.avisoDeGuardadoFallido('La orden de trabajo ya existe');
                    })
                break;
                default:
                    this.setState({ cargando: false }, ()=>{
                        this.avisoDeGuardadoFallido('Ocurrió un error al crear la orden de trabajo');
                    })
                    console.log('ocurrio un error al crear la orden de trabajo');
                break;
            }
        })
        .catch(e =>{
            console.log('errores al realizar guardado: ', e);
        })
    }

    estructuraParaGuardado = () =>{
        return {
            "ordenDeTrabajoHija": {
                "numeroDeFormato": this.state.numeroDeFormato+'-'+this.state.index,
                "ordenDeTrabajoMadre": this.props.ordenMadre,
                "dominioCorporativo": this.props.dominioCorporativo,
                "descripcionODT": this.state.descripcionODT,
                "actaFinalizacion": 0,
                "valorFacturado": 0,
                "fechaEstimadaDeProgramacion": this.state.fechaEstimadaDeProgramacion.toString(),
                "fechaDeActualizacion": this.state.fechaDeActualizacion.toString(),
                "estado": this.state.estado,
                "tecnico": {
                    "correo": this.state.tecnico,
                    "nombre": this.state.correo
                }
            }
        };
    }

    submitDelFormulario = (evento) => {
        evento.preventDefault();
        this.setState({
            cargando: true
        })
        this.cargarOrdenDeTrabajo(this.estructuraParaGuardado())
    }

    cambiosEnInput = (event, key, type) => {
        let value =  undefined
        if (type === 'date') {
            let myDate = new Date(((new Date(event.target.value).getTime()/1000)+18000)*1000)
            value = myDate
        }
        else {
            value = event.target.value
        }
        const name = event.target.name;
        let funcionValidacion = obtenerValidacionDeVariable(name, this.props.seccion)
        let validacion = funcionValidacion['validation']
        if (validacion != null && name !== 'estado') {
            const respuesta = validacion(key, value);
            if (!respuesta) {
                this.setState({
                    error: "El valor no es válido!",
                    estadoValidacion: false,
                    [key.mensaje]: true
                })
            } else {
                this.setState({
                    [name]: value,
                    error: "",
                    estadoValidacion: true,
                    [key.mensaje]: false
                })
            }
        } else if (validacion !== null && name === 'estado') {
            this.setState({
                [name]: value,
            })
        }
    }

    transformarVariable = (palabra) => {
        switch(palabra){
            case 'En sitio':
                return 'EN_SITIO';
            default:
                return palabra.toUpperCase()
        }
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorFormularioDeILI);
