import { createMuiTheme } from "@material-ui/core";

export default  createMuiTheme({
    overrides: {
        MUIDataTableBodyRow: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#f5f5f5'
                }
            }
        },
        MUIDataTable: {
            root: {
                backgroundColor: '#AAF',
            },
            paper: {
                boxShadow: 'none',
                borderWidth: "1px", borderColor: "#E9ECEF", borderStyle: "solid"
            },
        },
        MUIDataTableHeadCell: {
            toolButton: {
                /* backgroundColor:"yellow", */
                fontWeight: "bold",
                lineHeight: "normal",
                fontFamily: "system-ui",
                color: "#545cd8",
                fontStyle: "normal",
                marginLeft: "-8px",
                marginRight: "-8px"
                //marginRight:"-8px"// ANTES
            },
            sortActive: {
                fontWeight: "bold",
                lineHeight: "normal",
                fontFamily: "system-ui",
                color: "#545cd8",
                fontStyle: "normal"
            },
            contentWrapper: {
                justifyContent: "center"
            }

        }
        ,
        MUIDataTableResize: {
            resizer: {
                borderRight: "1px",
                borderColor: "#E9ECEF",
            }
        },
        MUIDataTableFilterList: {
            chip: {
                margin: "0px 3px 3px 0px"
            }
        },
        MuiTableRow: {
            hover: { '&$root': { '&:hover': { backgroundColor: "#e0f4fc" }, } },
        },
        MuiTableCell: {
            root: {
                fontFamily: "unset",
                borderBottom: "1px",
                borderBottomColor: "rgba(224, 224, 224, 1)",
                borderBottomStyle: "solid",
                letterSpacing: 0,
                textAlign:"center",
                /* backgroundColor:"red" */
            },
            head: {
                /*  backgroundColor: 'purple', */
                borderTop: "1px",
                borderTopColor: "rgba(224, 224, 224, 1)",
                borderTopStyle: "solid",
                lineHeight: "unset"
            },
            sizeSmall: {
                padding: "6px 16px 6px 16px"
                //padding:"6px 24px 6px 16px" //ANTES
            }
        },

    },
});
