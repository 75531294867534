import React from 'react'

/** Componentes creados */
import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection';


/** Posibles íconos que lleva el botón */
import { FaCloudUploadAlt, FaListOl, FaCloudDownloadAlt } from "react-icons/fa";

/** 
 * Retorna el botón de la sección que se desee
 * @param {any} key  key del botón
 * @param {string} titulo string el tooltip que llevará al hacer hover
 * @param {string} icono string nombre del ícono reactstrap que llevará
 * @param {function} metodo function 
 * FaCloudUploadAlt = flecha arriba || FaListOl = lista en hoja
*/
export const crearBotonEncabezado = ( key, titulo, icono, callback ) => {

    // const seleccionarIcono = () => {
    //     switch(icono){
    //         case 'FaCloudUploadAlt':
    //             return <FaCloudUploadAlt />;
    //         case 'FaListOl':
    //             return <FaListOl />;
    //         default:
    //             return
    //     }
    // }

    return (
        <BotonParaSection 
            key={ key }
            title={ titulo }
            icono={ <FaCloudDownloadAlt /> } 
            // icono={ seleccionarIcono() } 
            onClick={ callback }
        />
    )
}
