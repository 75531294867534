import { Grid } from '@material-ui/core';
import React from 'react'
import { Label } from 'reactstrap';

/**
 * Recibe un child que se ubica a la derecha del componente
 */
function BarraTitulo({ titulo ,children, ...props}) {
    return (
        <>
            <Grid  className={"mb-0 p-3 d-flex border rounded"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none',position:"relative" }}>
                <Label style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black' }}>{titulo}</Label>
                {!!children && <Grid style={{ justifyContent: "flex-end", position:"absolute" , right:"1rem" }} >
                    {children}
                </Grid>}
            </Grid>
        </>
    )
}
/* ubica a la derecha
<div className="btn-actions-pane-right text-capitalize">
    <span className="d-inline-block ml-2" style={{ marginRight: "1rem" }}>
        {info}
    </span>
</div> 
*/


export default BarraTitulo
