import React from 'react';
import { Card } from 'reactstrap';

let CardDeResumen = (props) => {
    return (
        <Card className="widget-chart border mb-3">
            {obtenerIconoDelProceso(props)}

            <div className="widget-heading"><p><b>{props.titulo}</b></p></div>

            <div className="widget-subheading" style={{ opacity: 1 }}
                dangerouslySetInnerHTML={{ __html: props.subtitulo }} />

            <hr />
            {props.children}
        </Card>
    );
}

let obtenerIconoDelProceso = (props) => {
    if (props.icono) {
        return (
            <div className="icon-wrapper" style={{ width: '7rem', height: '6rem' }}>
                <img style={{ width: '100%' }} src={props.icono} alt={"imagenDePlantilla." + props.titulo} />
            </div>
        );
    }
}

export default CardDeResumen;