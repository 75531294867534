// import { firebaseFunctions } from '../../../../../../firebase.js';
// const obtenerListaDeOpciones = firebaseFunctions.httpsCallable('obtenerListaDeOpciones');

//Funciones auxiliares:
// const obtenerFiltrosComoLista = (objeto) => {
//     let listaTarget = [];
//     let keys = Object.keys(objeto);

//     keys.forEach((key, i) => { listaTarget.push({ ...objeto[key], key: key }); });
//     return listaTarget;
// }

export const categorias = 
{
    "Semicuantitativo": [
        {
            tipo: "CONSECUENCIA_FUGA",
            keyDelNodo: "consecuencia.fuga.financiera",
            value: "consecuenciaFuga",
            label: "Consecuencia fuga",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia</b> igual a <b>" + valorTarget + " $</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia</b> mayor o igual a <b>" + valorTarget + " $</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia</b> mayor a <b>" + valorTarget + " $</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia</b> menor o igual a <b>" + valorTarget + " $</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia</b> menor a <b>" + valorTarget + " $</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "CONSECUENCIA_APERTURA",
            keyDelNodo: "consecuencia.apertura.financiera",
            value: "consecuenciaApertura",
            label: "Consecuencia apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia</b> igual a <b>" + valorTarget + " $</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia</b> mayor o igual a <b>" + valorTarget + " $</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia</b> mayor a <b>" + valorTarget + " $</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia</b> menor o igual a <b>" + valorTarget + " $</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia</b> menor a <b>" + valorTarget + " $</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "CONSECUENCIA_CUALITATIVA_FUGA",
            // keyDelNodo: "resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa",
            keyDelNodo: "consecuencia.fuga.nivelDeConsecuenciaFinanciera",
            value: "consecuenciaCualitativaFuga",
            label: "Nivel de consecuencia fuga",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "CONSECUENCIA_CUALITATIVA_APERTURA",
            // keyDelNodo: "resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa",
            keyDelNodo: "consecuencia.apertura.nivelDeConsecuenciaFinanciera",
            value: "consecuenciaCualitativaApertura",
            label: "Nivel de consecuencia apertura",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "RIESGO", // representa el riesgo para fuga
            keyDelNodo: "riesgo.fuga.riesgoFinanciero",
            value: "riesgoFuga",
            label: "Riesgo fuga",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "!=", label: "Diferente" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "RIESGO_APERTURA",
            keyDelNodo: "riesgo.apertura.riesgoFinanciero",
            value: "riesgoApertura",
            label: "Riesgo apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "!=", label: "Diferente" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "NIVEL_DE_RIESGO_FUGA",
            keyDelNodo: "riesgo.fuga.valorCualitativo",
            value: "nivelDeRiesgoFuga",
            label: "Nivel de riesgo fuga",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "!=", label: "Diferente" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "NIVEL_DE_RIESGO_APERTURA",
            keyDelNodo: "riesgo.apertura.valorCualitativo",
            value: "nivelDeRiesgoApertura",
            label: "Nivel de riesgo apertura",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "!=", label: "Diferente" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_FUGA",
            keyDelNodo: "probabilidad.fuga.total",
            value: "probabilidadFuga",
            label: "Probabilidad fuga",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad</b> igual a " + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad</b> mayor o igual a " + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad</b> mayor a " + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad</b> menor o igual a " + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad</b> menor a " + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_APERTURA",
            keyDelNodo: "probabilidad.apertura.total",
            value: "probabilidadApertura",
            label: "Probabilidad apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad</b> igual a " + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad</b> mayor o igual a " + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad</b> mayor a " + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad</b> menor o igual a " + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad</b> menor a " + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_CUALITATIVA_FUGA",
            keyDelNodo: "probabilidad.fuga.nivelDeProbabilidad",
            value: "probabilidadCualitativaFuga",
            label: "Nivel de probabilidad fuga",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_CUALITATIVA_APERTURA",
            keyDelNodo: "probabilidad.apertura.nivelDeProbabilidad",
            value: "probabilidadCualitativaApertura",
            label: "Nivel de probabilidad apertura",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "TIPO_DE_EQUIPO",
            keyDelNodo: "informacionEquipoProtegido.informacionGeneral.tipoDeEquipo",
            value: "tipoDeEquipo",
            label: "Tipo de equipo API 581",
            valorDefault: { value: "Compressor" },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "Compressor",
                    label: "Compressor"
                },
                {
                    value: "Heat exchanger",
                    label: "Heat exchanger"
                },
                {
                    value: "Pipe",
                    label: "Pipe"
                },
                {
                    value: "Pump",
                    label: "Pump"
                },
                {
                    value: "Tank",
                    label: "Tank"
                },
                {
                    value: "Vessel/FinFan",
                    label: "Vessel/FinFan"
                }
            ],
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },               
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>el equipo protegido</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "TIPO_DE_COMPONENTE",
            keyDelNodo: "informacionEquipoProtegido.informacionGeneral.tipoCompAPI581",
            value: "tipoCompAPI581",
            label: "Tipo de componente API 581",
            valorDefault: { value: "COMPC" },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "COMPC",
                    label: "COMPC"
                },
                {
                    value: "COMPR",
                    label: "COMPR"
                },
                {
                    value: "HEXSS",
                    label: "HEXSS"
                },
                {
                    value: "HEXTS",
                    label: "HEXTS"
                },
                {
                    value: "HEXTUBE",
                    label: "HEXTUBE"
                },
                {
                    value: "PIPE-1",
                    label: "PIPE-1"
                },
                {
                    value: "PIPE-2",
                    label: "PIPE-2"
                },
                {
                    value: "PIPE-4",
                    label: "PIPE-4"
                },
                {
                    value: "PIPE-6",
                    label: "PIPE-6"
                },
                {
                    value: "PIPE-8",
                    label: "PIPE-8"
                },
                {
                    value: "PIPE-10",
                    label: "PIPE-10"
                },
                {
                    value: "PIPE-12",
                    label: "PIPE-12"
                },
                {
                    value: "PIPE-16",
                    label: "PIPE-16"
                },
                {
                    value: "PIPEGT16",
                    label: "PIPEGT16"
                },
                {
                    value: "PUMP2S",
                    label: "PUMP2S"
                },
                {
                    value: "PUMP1S",
                    label: "PUMP1S"
                },
                {
                    value: "PUMPR",
                    label: "PUMPR"
                },
                {
                    value: "TANKBOTTOM",
                    label: "TANKBOTTOM"
                },
                {
                    value: "COURSES-10",
                    label: "COURSES-10"
                },
                {
                    value: "KODRUM",
                    label: "KODRUM"
                },
                {
                    value: "DRUM",
                    label: "DRUM"
                },
                {
                    value: "FINFAN",
                    label: "FINFAN"
                },
                {
                    value: "FILTER",
                    label: "FILTER"
                },
                {
                    value: "REACTOR",
                    label: "REACTOR"
                },
                {
                    value: "COLTOP",
                    label: "COLTOP"
                },
                {
                    value: "COLMID",
                    label: "COLMID"
                },
                {
                    value: "COLBTM",
                    label: "COLBTM"
                },


            ],
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },               
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>tipo de componente</b> igual a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "TIPO_DE_VALVULA",
            keyDelNodo: "informacionDePRD.informacionDeDiseñoyConstruccion.tipoDeValvula",
            value: "tipoDeValvula",
            label: "Tipo de válvula",
            valorDefault: { value: "Convencional balanceada" },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "Convencional balanceada",
                    label: "Convencional balanceada"
                },
                {
                    value: "Convencional resortada",
                    label: "Convencional resortada"
                },
                {
                    value: "Pilotada",
                    label: "Pilotada"
                },
                {
                    value: "Disco de rotura",
                    label: "Disco de rotura"
                },
                {
                    value: "Presión y vacío",
                    label: "Presión y vacío"
                },
            ],
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },               
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>tipo de valvula</b> igual a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
    ],
    "Cualitativo":[
        {
            tipo: "CONSECUENCIA_FUGA",
            keyDelNodo: "consecuencia.fuga.maximaCOF",
            value: "consecuenciaFuga",
            label: "Consecuencia fuga",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia</b> igual a <b>" + valorTarget + " $</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia</b> mayor o igual a <b>" + valorTarget + " $</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia</b> mayor a <b>" + valorTarget + " $</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia</b> menor o igual a <b>" + valorTarget + " $</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia</b> menor a <b>" + valorTarget + " $</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "CONSECUENCIA_APERTURA",
            keyDelNodo: "consecuencia.apertura.maximaCOF",
            value: "consecuenciaApertura",
            label: "Consecuencia apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia</b> igual a <b>" + valorTarget + " $</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia</b> mayor o igual a <b>" + valorTarget + " $</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia</b> mayor a <b>" + valorTarget + " $</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia</b> menor o igual a <b>" + valorTarget + " $</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia</b> menor a <b>" + valorTarget + " $</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "CONSECUENCIA_CUALITATIVA_FUGA",
            // keyDelNodo: "resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa",
            keyDelNodo: "consecuencia.fuga.maximaCOF",
            value: "consecuenciaCualitativaFuga",
            label: "Nivel de consecuencia fuga",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "CONSECUENCIA_CUALITATIVA_APERTURA",
            // keyDelNodo: "resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa",
            keyDelNodo: "consecuencia.apertura.maximaCOF",
            value: "consecuenciaCualitativaApertura",
            label: "Nivel de consecuencia apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>consencuencia cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "RIESGO",
            keyDelNodo: "riesgo.fuga.nivelDeRiesgo",
            value: "riesgoFuga",
            label: "Riesgo fuga",
            valorDefault: { value: 'L' },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "L",
                    label: "L"
                },
                {
                    value: "M",
                    label: "M"
                },
                {
                    value: "H",
                    label: "H"
                },
                {
                    value: "E",
                    label: "E"
                }
            ],
            esTexto:true,
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "RIESGO_APERTURA",
            keyDelNodo: "riesgo.apertura.nivelDeRiesgo",
            value: "riesgoApertura",
            label: "Riesgo apertura",
            valorDefault: { value: 'L' },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "L",
                    label: "L"
                },
                {
                    value: "M",
                    label: "M"
                },
                {
                    value: "H",
                    label: "H"
                },
                {
                    value: "E",
                    label: "E"
                }
            ],
            esTexto:true,
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "NIVEL_DE_RIESGO_FUGA",
            keyDelNodo: "riesgo.fuga.valorCualitativo",
            value: "nivelDeRiesgoFuga",
            label: "Nivel de riesgo fuga",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            esTexto: false,
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "NIVEL_DE_RIESGO_APERTURA",
            keyDelNodo: "riesgo.apertura.valorCualitativo",
            value: "nivelDeRiesgoApertura",
            label: "Nivel de riesgo apertura",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                },
            ],
            esTexto: false,
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>riesgo cualitativo</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_FUGA",
            keyDelNodo: "probabilidad.fuga.total",
            value: "probabilidadFuga",
            label: "Probabilidad fuga",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad</b> igual a " + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad</b> mayor o igual a " + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad</b> mayor a " + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad</b> menor o igual a " + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad</b> menor a " + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_APERTURA",
            keyDelNodo: "probabilidad.apertura.total",
            value: "probabilidadApertura",
            label: "Probabilidad apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad</b> igual a " + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad</b> mayor o igual a " + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad</b> mayor a " + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad</b> menor o igual a " + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad</b> menor a " + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_CUALITATIVA_FUGA",
            keyDelNodo: "probabilidad.fuga.nivelDeProbabilidad",
            value: "probabilidadCualitativaFuga",
            label: "Nivel de probabilidad fuga",
            valorDefault: { value: 1 },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "PROBABILIDAD_CUALITATIVA_APERTURA",
            keyDelNodo: "probabilidad.apertura.nivelDeProbabilidad",
            value: "probabilidadCualitativaApertura",
            label: "Nivel de probabilidad apertura",
            valorDefault: { value: 0 },
            tipoDeCategoria: "valoresContinuos",
            comparadoresPermitidos: [
                { value: ">", label: "Mayor" },
                { value: ">=", label: "Mayor o igual" },
                { value: "==", label: "Igual" },
                { value: "<=", label: "Menor o igual" },
                { value: "<", label: "Menor" }
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> igual a <b>" + valorTarget + "</b>";
                    case "Mayor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                    case "Mayor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                    case "Menor o igual":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                    case "Menor":
                        return "Todos los elementos con <b>probabilidad cualitativa</b> menor a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "TIPO_DE_EQUIPO",
            keyDelNodo: "informacionEquipoProtegido.informacionGeneral.tipoDeEquipo",
            value: "tipoDeEquipo",
            label: "Tipo de equipo API 581",
            valorDefault: { value: "Compressor" },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "Compressor",
                    label: "Compressor"
                },
                {
                    value: "Heat exchanger",
                    label: "Heat exchanger"
                },
                {
                    value: "Pipe",
                    label: "Pipe"
                },
                {
                    value: "Pump",
                    label: "Pump"
                },
                {
                    value: "Tank",
                    label: "Tank"
                },
                {
                    value: "Vessel/FinFan",
                    label: "Vessel/FinFan"
                }
            ],
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },               
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>el equipo protegido</b> igual a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "TIPO_DE_COMPONENTE",
            keyDelNodo: "informacionEquipoProtegido.informacionGeneral.tipoCompAPI581",
            value: "tipoCompAPI581",
            label: "Tipo de componente API 581",
            valorDefault: { value: "COMPC" },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "COMPC",
                    label: "COMPC"
                },
                {
                    value: "COMPR",
                    label: "COMPR"
                },
                {
                    value: "HEXSS",
                    label: "HEXSS"
                },
                {
                    value: "HEXTS",
                    label: "HEXTS"
                },
                {
                    value: "HEXTUBE",
                    label: "HEXTUBE"
                },
                {
                    value: "PIPE-1",
                    label: "PIPE-1"
                },
                {
                    value: "PIPE-2",
                    label: "PIPE-2"
                },
                {
                    value: "PIPE-4",
                    label: "PIPE-4"
                },
                {
                    value: "PIPE-6",
                    label: "PIPE-6"
                },
                {
                    value: "PIPE-8",
                    label: "PIPE-8"
                },
                {
                    value: "PIPE-10",
                    label: "PIPE-10"
                },
                {
                    value: "PIPE-12",
                    label: "PIPE-12"
                },
                {
                    value: "PIPE-16",
                    label: "PIPE-16"
                },
                {
                    value: "PIPEGT16",
                    label: "PIPEGT16"
                },
                {
                    value: "PUMP2S",
                    label: "PUMP2S"
                },
                {
                    value: "PUMP1S",
                    label: "PUMP1S"
                },
                {
                    value: "PUMPR",
                    label: "PUMPR"
                },
                {
                    value: "TANKBOTTOM",
                    label: "TANKBOTTOM"
                },
                {
                    value: "COURSES-10",
                    label: "COURSES-10"
                },
                {
                    value: "KODRUM",
                    label: "KODRUM"
                },
                {
                    value: "DRUM",
                    label: "DRUM"
                },
                {
                    value: "FINFAN",
                    label: "FINFAN"
                },
                {
                    value: "FILTER",
                    label: "FILTER"
                },
                {
                    value: "REACTOR",
                    label: "REACTOR"
                },
                {
                    value: "COLTOP",
                    label: "COLTOP"
                },
                {
                    value: "COLMID",
                    label: "COLMID"
                },
                {
                    value: "COLBTM",
                    label: "COLBTM"
                },


            ],
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },               
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>tipo de componente</b> igual a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
        {
            tipo: "TIPO_DE_VALVULA",
            keyDelNodo: "informacionDePRD.informacionDeDiseñoyConstruccion.tipoDeValvula",
            value: "tipoDeValvula",
            label: "Tipo de válvula",
            valorDefault: { value: "Convencional balanceada" },
            tipoDeCategoria: "opcionesDiscretas",
            opcionesDeLaCategoria: [
                {
                    value: "Convencional balanceada",
                    label: "Convencional balanceada"
                },
                {
                    value: "Convencional resortada",
                    label: "Convencional resortada"
                },
                {
                    value: "Pilotada",
                    label: "Pilotada"
                },
                {
                    value: "Disco de rotura",
                    label: "Disco de rotura"
                },
                {
                    value: "Presión y vacío",
                    label: "Presión y vacío"
                },
            ],
            comparadoresPermitidos: [
                { value: "==", label: "Igual" },               
            ],
            descripcion: (comparadorActivo, valorTarget) => {
                switch (comparadorActivo) {
                    case "Igual":
                        return "Todos los elementos con <b>tipo de valvula</b> igual a <b>" + valorTarget + "</b>";
                    default:
                        return "Al parecer, estas condiciones no se pueden interpretar...";
                }
            }
        },
    ]
}

