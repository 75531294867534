//firebase:
import { firebaseStorage, firestoreDB } from '../../../../firebase';

//Services
import { servicioCrearUsuario } from '../services/paraGestionDeUsuarios'

/**
 * Description: Permite crear un nuevo usuario para el dominio corporativo especificado.
 * @param {String} nombre Nombre del usuario
 * @param {String} correo Correo del usuario
 * @param {String} dominioCorporativo Dominio corporativo al que pertenece
 * @param {String} rol Rol del usuario
 * @param {String} companyId Id del documento de la empresa
 * @param {File} fotoACargar Imagen de perfil del usuario
 */
export const crearUsuario = async (nombre, correo, dominioCorporativo, rol, companyId, fotoACargar, userRol, userDomain) => {
    let idUsuario

    try{

        const datosDeUsuario = {
            nombre: nombre,
            correo: correo,
            dominioCorporativo: dominioCorporativo,
            companyId: companyId,
            rol: rol  //admin, supervisor, tecnico, cliente
        }

        let validation = userDomain === 'insercor.com' && userRol === 'admin'
        //Se crea usuario
        idUsuario = await servicioCrearUsuario(datosDeUsuario, validation)
        //Se almacena imagen
        const resultadoCargaDeFoto = await almacenarFotoDeUsuario(idUsuario, fotoACargar)
        if(resultadoCargaDeFoto.status !== 'CARGA_EXITOSA'){
            throw new Error('No se pudo cargar la foto del usuario')
        }

        //Se almacena información en base de datos
        await firestoreDB.collection('usuarios').doc(idUsuario).update({'fotoDePerfil': resultadoCargaDeFoto.data});
        return idUsuario    
            
    }catch(error){
        if(idUsuario){
            await eliminarFotoDeUsuario(idUsuario);
        }

        throw new Error(
            error.message || 'El usuario no se ha podido crear correctamente'
        )
    }
}

const almacenarFotoDeUsuario = (idUsuario, imagen) => {
    return new Promise((resolve, reject) =>{
        if(imagen){
            firebaseStorage.ref(`usuarios/${idUsuario}/fotoDeperfil`).put(imagen)
                .then(() => {
                    return firebaseStorage.ref(`usuarios/${idUsuario}/fotoDeperfil`).getDownloadURL();
                })
                .then((urldResult)=>{
                    resolve({'status': 'CARGA_EXITOSA', 'mensaje': 'Foto cargada exitosamente', 'data': { 'path' : `usuarios/${idUsuario}/fotoDeperfil`, 'url': urldResult }})
                })
                .catch(e => {
                    console.log('error al guardar la imagen: ', e);
                    reject({'status': 'CARGA_FALLIDA', 'mensaje': 'No se pudo cargar la foto'})
                })
        }else{
            resolve({'status': 'SIN_INFORMACION', 'mensaje': 'No hay archivo para cargar'})
        }
    })
}

const eliminarFotoDeUsuario = (idUsuario) => {
    return new Promise((resolve, reject) =>{
        firebaseStorage.ref(`usuarios/${idUsuario}/fotoDeperfil`).delete()
            .then(result => {
                resolve({'status': 'FOTO_ELIMINADA', 'mensaje' : 'La foto del usuario fue eliminada correctamente'});
            })
            .catch(error =>{
                resolve({'status': 'FOTO_NO_ELIMINADA', 'mensaje': 'La foto del usuario no pudo ser eliminada'});
            })
    });
}