import React from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//Swal:
import Swal from 'sweetalert2'
import { 
    mensajeErrorDeDominio, 
    mensajeIngresoUsuarioExitoso, 
    mensajeLimiteDeUsuarios, 
    mensajeErrorDeUsuario 
} from '../../../utilidades/almacen/alertasParaSwal.js';

//Redux storage actions:
import { logearUsuario } from '../../../storage/reducerParaUsuario.js';
import { setearParametrizacion } from '../../../storage/reducerParaUsuario.js';

//Imágenes para el login:
import bg1 from '../../../assets/utils/images/login/login-bg-1.jpg';
import bg2 from '../../../assets/utils/images/login/login-bg-2.jpg';
import bg3 from '../../../assets/utils/images/login/login-bg-3.jpg';
import logoGiaCore from '../../../assets/utils/images/logos/giaCore-logo.png';
import logoInsercor from '../../../assets/utils/images/logos/Insercor-logo.png';

//Utilidades de firebase:
import { firebaseApp as firebase } from '../../../firebase';

//Cloud functions
import { firebaseFunctions } from '../../../firebase';
const validarUsuario = firebaseFunctions.httpsCallable('validarUsuario');
const obtenerParametrizacionSegunDominio = firebaseFunctions.httpsCallable('obtenerParametrizacionSegunDominio');

//Variables auxiliares:
let configuracionDelSlider = {
    dots: true, infinite: true, speed: 2000, arrows: true,
    slidesToShow: 1, slidesToScroll: 1, fade: true, initialSlide: 0, autoplay: true, adaptiveHeight: true
};

let estilosDeBotonesDeLogeo = {
    margin: 5
};

class Login extends React.Component {

    constructor() {
        super();

        this.suscriberDeListenerDeAutenticacion = null;
        this.state = { mostrarContenidoDeLogin: false };
    }

    componentDidMount() {
        this.activarListenerDeAutenticacion();
    }

    componentWillUnmount() {
        try {
            this.suscriberDeListenerDeAutenticacion();
        }
        catch (error) { console.log(error); }
    }


    render() {
        if (this.state.mostrarContenidoDeLogin && this.state.mostrarContenidoDeLogin !== undefined) {
            return (
                <div className="h-100">
                    <Row className="h-100 no-gutters">

                        <Col lg="4" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...configuracionDelSlider}>

                                    <div className="h-100 d-flex justify-content-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{ backgroundImage: 'url(' + bg1 + ')' }} />
                                        <div className="slider-content d-flex justify-content-center align-items-center">
                                            <img style={{ width: '70%' }} src={logoGiaCore} alt="GiaCore-logo" />
                                        </div>
                                    </div>

                                    <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg opacity-6" style={{ backgroundImage: 'url(' + bg3 + ')' }} />
                                        <div className="slider-content">
                                            <h3>Aplicación <b>Cloud</b></h3>
                                            <p>Risk Based Inspection con arquitectura en <b><span className="text-info">G</span><span className="text-danger">o</span><span className="text-warning">o</span><span className="text-info">g</span><span className="text-success">l</span><span className="text-danger">e</span></b></p>
                                        </div>
                                    </div>

                                    <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{ backgroundImage: 'url(' + bg2 + ')' }} />
                                        <div className="slider-content">
                                            <h3><b>Integridad</b> de activos</h3>
                                            <p>Velocidad. Jerarquización. Informes. Resultados.</p>
                                        </div>
                                    </div>

                                </Slider>
                            </div>

                        </Col>
                        <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">

                                <img style={{ width: '20%' }} src={logoInsercor} alt="Insercor-logo" />
                                <h4 className="mb-0">
                                    <div style={{ opacity: 1 }}>Bienvenido a <b>GIAcore</b></div>
                                    <span style={{ opacity: 0.75 }}>Para continuar, inicie sesión con su cuenta <b>corporativa</b></span>
                                </h4>

                                <Row className="divider" />
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="ml-auto">
                                            <Button color="danger" size="lg" style={estilosDeBotonesDeLogeo}
                                                onClick={this.iniciarProtocoloDeAutenticacionConGoogle}>
                                                <span> Google</span>
                                            </Button>
                                            <Button color="primary" size="lg" style={estilosDeBotonesDeLogeo}
                                                onClick={this.iniciarProtocoloDeAutenticacionConMicrosoft}>
                                                <span> Microsoft</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            return (<React.Fragment />);
        }
    }


    //Métodos operativos:
    activarListenerDeAutenticacion = () => {
        this.suscriberDeListenerDeAutenticacion = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.validarAutenticacionDeUsuario(user);
            }
            else {
                this.setState({ mostrarContenidoDeLogin: true });
            }
        })
    }

    cambiarUsuarioActivoEnAplicacion = (usuario) => {
        let usuarioActivo = { ...usuario };
        this.props.logearUsuario(usuarioActivo);
        let ultimaRutaVisitadaEnLS;
        if (localStorage.getItem('ultimaRutaVisitada')){
            ultimaRutaVisitadaEnLS = JSON.parse(localStorage.getItem('ultimaRutaVisitada'));
        }
        this.props.history.push((ultimaRutaVisitadaEnLS) ? ultimaRutaVisitadaEnLS : '/app/dashboard');
    }

    iniciarProtocoloDeAutenticacionConGoogle = () => {
        let googleProvider = new firebase.auth.GoogleAuthProvider();
        googleProvider.setCustomParameters({ prompt: 'select_account', });

        firebase.auth().signInWithPopup(googleProvider)
            .then(result => { this.validarAutenticacionDeUsuario(result.user); })
            .catch(error => { console.log(error); });
    }

    iniciarProtocoloDeAutenticacionConMicrosoft = () => {
        let microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
        microsoftProvider.setCustomParameters({ prompt: 'consent', });
        firebase.auth().signInWithPopup(microsoftProvider)
            .then(result => { this.validarAutenticacionDeUsuario(result.user); })
            .catch(error => { console.log(error); });
    }

    //Método de autenticación desde Functions:
    validarAutenticacionDeUsuario = (firebaseUser) => {
        let usuarioActivo = {};
        firebase.auth().currentUser.getIdToken(true)
            .then(authToken => {
                validarUsuario({
                    "email": firebaseUser.email,
                    "token": authToken,
                    "isLogin": true,
                })
                    .then(responseUsuarioActivo => {
                        usuarioActivo = {...responseUsuarioActivo.data.usuario};
                        this.generarMensajeSegunStatus(responseUsuarioActivo.data)

                        if (responseUsuarioActivo.data.status === "USUARIO_ACTIVO" || responseUsuarioActivo.data.status === 'USUARIO_YA_LOGEADO') {
                            obtenerParametrizacionSegunDominio({ 'dominioCorporativo': usuarioActivo.dominioCorporativo })

                                .then(responseParametrizacion => {
                                    this.props.setearParametrizacion(responseParametrizacion.data);
                                    usuarioActivo = {
                                        ...usuarioActivo,
                                        photoURL: firebaseUser.photoURL,
                                        email: firebaseUser.email,
                                        displayName: firebaseUser.displayName,
                                        token: authToken
                                    };
                                    this.cambiarUsuarioActivoEnAplicacion(usuarioActivo);
                                })
                                .catch(error => {
                                    console.log(error);
                                    firebase.auth().signOut();
                                    this.setState({ mostrarContenidoDeLogin: true });
                                });
                        }
                        else {
                            validarUsuario({
                                "email": firebaseUser.email,
                                "token": authToken,
                                "isLogin": false,
                            })
                                .then(response => {
                                    firebase.auth().signOut();
                                    this.setState({ mostrarContenidoDeLogin: true });
                                })
                        }
                    })
                    .catch(error => { console.log(error); });
            });
    }

    generarMensajeSegunStatus = (data) => {
        switch (data.status) {
            case 'ERROR_DOMINIO':
                return (
                    Swal.fire(mensajeErrorDeDominio)
                );
            case 'LIMITE_USUARIOS':
                return (
                    Swal.fire(mensajeLimiteDeUsuarios)
                )
            case 'USUARIO_ACTIVO':
                return (
                    Swal.fire(mensajeIngresoUsuarioExitoso)
                )
            case 'ERROR_USUARIO':
                return (
                    Swal.fire(mensajeErrorDeUsuario)
                )
            default:
                return (<React.Fragment></React.Fragment>)
        }
    }

    toggleDelModal = () => {
        this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto })
    }
}

const mapStateToProps = state => ({
    elUsuarioEstaLogeado: state.reducerParaUsuario.elUsuarioEstaLogeado,
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

const mapDispatchToProps = dispatch => ({
    logearUsuario: usuarioTarget => dispatch(logearUsuario(usuarioTarget)),
    setearParametrizacion: nuevaParametrizacion => dispatch(setearParametrizacion(nuevaParametrizacion))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));