import React from 'react';

import { Breadcrumb, BreadcrumbItem, Button, CardImg, Card, Col, Row, CardImgOverlay, Spinner } from 'reactstrap';
import { firebaseStorage, firestoreDB } from "../../../../../../firebase";
import CustomModal from '../../../../../../utilidades/componentes/plantillaBase/CustomModal'
import Preloader from '../../../../../../utilidades/componentes/plantillaBase/Preloader'

//iconos
import { BsXCircleFill } from "react-icons/bs";

class GaleriaDeBS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listaDeUrl: [],
            listaDeImagenes: [],
            modalAbierto: false,
            imagenAEliminar: undefined,
            cargando: false
        }
        this.cargarImagen = React.createRef();
        this.unsuscribeImagen = undefined;
    }

    render() {
        return (
            <React.Fragment>
                <br />
                <br />
                <Breadcrumb>
                    <BreadcrumbItem className='BreadcrumbItem-color'> GALERÍA DE FOTOS BENDING STRAIN</BreadcrumbItem>
                </Breadcrumb>
                {this.state.listaDeImagenes.length < 4 ?
                    <Button className="btn-wide pusheable-button"
                        color="primary" style={{ marginBottom: 20 }}
                        onClick={() => { this.cargarImagen.current.click(); }}>
                        Agregar imagen
                        </Button>
                    : <React.Fragment />
                }

                <input ref={this.cargarImagen} type="file" style={{ display: "none" }}
                    onChange={(e) => { this.gurdarImagen(e) }} />
                <Row>
                    {
                        this.state.listaDeImagenes ?
                            this.state.listaDeImagenes
                            : <React.Fragment>
                                <Spinner animation="border" />
                            </React.Fragment>
                    }
                </Row>
                <CustomModal
                    titulo={"Eliminar imagen"}
                    elModalEstaAbierto={this.state.modalAbierto}
                    toggleDelModal={this.toggleDelModalBS}
                    children={this.childrenModal()}
                    size={"sm"}
                    botonesParaElFooter={this.botonesDeAccionParaModal()}
                />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>

        )
    }

    componentDidMount() {
        this.mostrarImagenes();
    }

    componentWillUnmount() {
        this.unsuscribeImagen();
    }

    toggleDelModalBS = () => {
        this.setState({
            modalAbierto: !this.state.modalAbierto
        })
    }

    childrenModal = () => {
        return (
            <React.Fragment>
                <p>
                    ¿Está seguro que desea eliminar la imagen?
                </p>
            </React.Fragment>
        )
    }

    botonesDeAccionParaModal = () => {
        return (
            <Row>
                <Col>
                    <Button className="btn btn-danger"
                        onClick={() => {
                            this.setState({ cargando: true }, () => {
                                this.eliminarImagen();
                                this.toggleDelModalBS();
                            })
                        }}
                    >Eliminar</Button>
                </Col>
            </Row>
        )
    }

    gurdarImagen = (imagen) => {
        const newImagen = imagen.target.files[0];
        if (newImagen) {
            this.setState({ cargando: true });
            const refImagen = firebaseStorage.ref(`pictures/${this.props.idOrdenDeTrabajo}/${"img_" + newImagen.name}`).put(newImagen);
            refImagen.then(() => {
                firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo)
                    .collection('anomalias')
                    .get()
                    .then(anomalias => {
                        anomalias.forEach(list => {
                            if (list.data().tipoDeAnomalia === "BENDING_STRAIN") {
                                let detalleDeZonaBs = list.data().detalleDeZonaBs;
                                let imagenes = [];
                                if (detalleDeZonaBs.imagenes) {
                                    imagenes = detalleDeZonaBs.imagenes;
                                    imagenes.push(`pictures/${this.props.idOrdenDeTrabajo}/${"img_" + newImagen.name}`);
                                    detalleDeZonaBs.imagenes = imagenes;
                                }else{
                                    imagenes.push(`pictures/${this.props.idOrdenDeTrabajo}/${"img_" + newImagen.name}`);
                                    detalleDeZonaBs["imagenes"] = imagenes;
                                }
                                firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo)
                                    .collection('anomalias')
                                    .doc(list.id)
                                    .set({ "detalleDeZonaBs": detalleDeZonaBs }, { merge: true })
                            }
                        })
                    })



            })
                .catch(e => {
                    this.setState({ cargando: false });
                    console.log('error al guardar la imagen: ', e);
                })
        }
    }

    mostrarImagenes = () => {
        let imagenes = [];

        this.unsuscribeImagen = firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo)
            .collection('anomalias')
            .onSnapshot(snapshot => {

                if (snapshot) {
                    snapshot.forEach(snapshots => {
                        if (snapshots.data().tipoDeAnomalia === "BENDING_STRAIN") {
                            if (snapshots.data().detalleDeZonaBs && snapshots.data().detalleDeZonaBs.imagenes) {
                                imagenes = snapshots.data().detalleDeZonaBs.imagenes;
                            }
                        }
                    })
                    if (imagenes) {
                        const newList = []
                        imagenes.forEach(item => {
                            newList.push(this.obtenerImagenes(item));
                        })

                        Promise.all(newList)
                            .then(result => {
                                let listImg = Object.keys(result).map(index => {
                                    return (
                                        <Col xs='12' md='6' lg='4' key={result[index].identificador+"col"}>                                
                                            <Card className={"bg-royal"} style={{ marginTop: 15,height: 320 }}>                                    
                                                <CardImg key={result[index].identificador} top width="100%" style={{ height: 320 }} src={result[index].url} alt={result[index].url} />
                                                <CardImgOverlay style={{ padding:1}}>
                                                    <div style={{float: 'right', margin:3}}>
                                                        <BsXCircleFill 
                                                            size={20} color={"white"} 
                                                            style={{ cursor: 'pointer'}} 
                                                            onClick={()=>{
                                                                this.setState({
                                                                    modalAbierto:true,
                                                                    imagenAEliminar: result[index].identificador
                                                                })
                                                            }}
                                                        />
                                                    </div>                                       
                                                </CardImgOverlay>
                                            </Card>
                                        </Col>
                                    )
                                })
                                this.setState({
                                    listaDeImagenes: listImg,
                                    cargando: false
                                })
                            })

                    } else {
                        return (
                            <React.Fragment>
                                <Spinner animation="border">

                                </Spinner>
                            </React.Fragment>
                        )
                    }
                }
            })
    }

    obtenerImagenes = async (nombreImagen) => {
        // let dataImg = await firebaseStorage.ref(`pictures/${this.props.idOrdenDeTrabajo}`).child(nombreImagen).getDownloadURL();
        let dataImg = await firebaseStorage.ref().child(nombreImagen).getDownloadURL();
        return {"url":dataImg,"identificador":nombreImagen};
    }

    eliminarImagen = () => {
        const imagen = this.state.imagenAEliminar
        firebaseStorage.ref().child(imagen)
            .delete()
            .then(() => {
                firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo)
                    .collection('anomalias')
                    .get()
                    .then(anomalias => {
                        anomalias.forEach(list => {
                            if (list.data().tipoDeAnomalia === "BENDING_STRAIN") {
                                let dataDocument = list.data();
                                let detalleDeZonaBs = dataDocument.detalleDeZonaBs;
                                let datosNuevosImagen = [];
                                detalleDeZonaBs.imagenes.forEach(item => {
                                    if (item !== imagen) {
                                        datosNuevosImagen.push(item);
                                    }
                                })
                                detalleDeZonaBs.imagenes = datosNuevosImagen;
                                dataDocument.detalleDeZonaBs = detalleDeZonaBs

                                firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo)
                                    .collection('anomalias')
                                    .doc(list.id)
                                    .set(dataDocument)
                            }
                        })
                    })
            })
            .catch((e) => {
                console.log('Error al eliminar la imagen: ', e)
            })
    }
}



export default GaleriaDeBS;