import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataParaTablaResumenIntegridad = (dataResumen, anhosEntreInspeccion, fechaDeInspeccion) => {

    let resumenDeIntegridad = [];
    resumenDeIntegridad.push(
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Fecha de inspección', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: fechaDeInspeccion || 'aún sin calcular', 
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: '-', 
                minWidth:50,
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Proxima fecha de inspección', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor:  new Date((anhosEntreInspeccion[0]?.seconds * 1000) + (dataResumen?.anhosEntreInspecciones?.valor * 365 * 24 * 3600 * 1000)).toLocaleString().split(',')[0] ||  'aún sin calcular', 
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: '-', 
                minWidth:50,
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Cml vida remanente mínima', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.cmlRemMin?.valor, ''),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.cmlRemMin?.unidades || '-', 
                minWidth:50,
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Velocidad máxima de corrosión', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.velocidadMaximaDeCorrosion?.valor, 3),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.velocidadMaximaDeCorrosion?.unidades || 'mpy',
                minWidth: 50
                }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Porcentaje máximo de perdida de espesor', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.porcentajeMaxPerdidaMaterial?.valor, 3),
                minWidth: 50
                },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.porcentajeMaxPerdidaMaterial?.unidades || '%', 
                minWidth: 50
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Vida remanente', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: dataResumen?.vidCmlRemMin?.valor > 20 ? '> 20' : parseFloat(dataResumen?.vidCmlRemMin?.valor).toFixed(2)  || 'Aún sin calcular', 
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.vidCmlRemMin?.unidades || 'Años',
                minWidth: 50
                }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'MAWP',
                minWidth: 120
                },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.maximaPresionTrabajoAdmisible?.valor, 3),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.maximaPresionTrabajoAdmisible?.unidades || 'psi', 
                minWidth: 50
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Fugas en activo', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.fugasHumedecimientos?.valor, 0),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.fugasHumedecimientos?.unidades || 'Un', 
                minWidth: 50
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Interfaces suelo aire en activo', 
                minWidth: 120
            },
            valor: {
                label: 'Valor',
                valor: formatearvalorsegunestado(dataResumen?.interfazSueloAire?.valor, 0),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.interfazSueloAire?.unidades || 'Un',
                minWidth: 50
            }
        },
        
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Piernas muertas en activo', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.piernasMuertas?.valor, 0),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.piernasMuertas?.unidades || 'Un', 
                minWidth: 50
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Uniones bridadas en activo', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.unionesBridadas?.valor, 0),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.unionesBridadas?.unidades || 'Un',
                minWidth: 50
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Valvulas en activo', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.valvulas?.valor, 0),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: dataResumen?.valvulas?.unidades || 'Un',
                minWidth: 50
            }
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Mínimo espesor medido', 
                minWidth: 120
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataResumen?.tMin?.valor, 3),
                minWidth: 50
            },
            unidades: {
                label: 'Unidades', 
                valor: 'in',
                minWidth: 50
            }
        },
       
       
    );
    return resumenDeIntegridad
}

export default armarDataParaTablaResumenIntegridad;