import React from 'react'
import get from "lodash/get"
import { firebaseFunctions } from '../../firebase';
import ItemExpandibleConsola from '../componentes/resumenDeModulo/ItemExpandibleConsola';
 
// const  datosPrueba = require('../../vistas/aplicacion/analisis/riesgo/api581Valvulas/estaticos/pruebasPostman.json')
// const cargarOrdenDeTrabajo = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajo');
const cargarPRD = firebaseFunctions.httpsCallable('cargarPRD');
const cargarInventarioPRD = firebaseFunctions.httpsCallable('cargarInventarioPRD');


const obtenerCloudFunction = (proceso)=>{
switch (proceso) {
    case "Inventario":
        return cargarInventarioPRD;
    case "RBI":
    default:
        return cargarPRD;
}
}

export const cargarDatosDePRD = ({ matriz , proceso }, callbackParaCargue) => {
    return new Promise((resolve, reject) =>{
        let contador = [];
        const cloudFunction = obtenerCloudFunction(proceso)
        contador = matriz.length;
        if(matriz.length > 0){
            matriz.forEach(prd => {   
                cloudFunction(prd)            
                    .then((result) =>{
                        switch(result.data.status){
                            case "PRD_CREADA":
                                callbackParaCargue("El PRD <b>"+prd.informacionDePRD.informacionGeneral.TAG+"</b> fue creada con exito",'success');
                                // callbackParaCargue("El prd <b>"+datosPrueba.informacionDePRD.informacionGeneral.TAG+"</b> fue creada con exito",'success');
                            break;
                            case "PRD_NO_CREADA":
                                callbackParaCargue(<ItemExpandibleConsola
                                    message={<div>
                                        El PRD <b>{prd.informacionDePRD.informacionGeneral.TAG}</b>  no pudo ser creada
                                    </div>}
                                    expanded={`${get(result, "data.message","Error desconocido")} : ${get(result, "data.error.detalle","Detalle desconocido")}`}
                                />
                                    , 'danger', false);
                                // callbackParaCargue("El prd <b>"+datosPrueba.informacionDePRD.informacionGeneral.TAG+"</b> no pudo ser creada",'danger');
                            break;
                            case "PRD_EXISTENTE":
                                callbackParaCargue("El PRD <b>"+prd.informacionDePRD.informacionGeneral.TAG+"</b> fue actualizada con exito",'warning');
                                /* callbackParaCargue(<ItemExpandibleConsola
                                    message={<div>
                                        El prd <b>{prd.informacionDePRD.informacionGeneral.TAG}</b>  ya existe
                                    </div>}
                                    expanded={get(result, "data.message")}
                                    />
                                    , 'warning', false); */
                            break;
                            default:
                                console.log('status no encontrado');
                            break;
                        }
                        contador--;
                        if(contador===0) resolve();
                    })
                    .catch(e =>{
                        console.log("error: ", e);
                        callbackParaCargue(<ItemExpandibleConsola
                            message={<div>
                                El PRD <b>{prd.informacionDePRD.informacionGeneral.TAG}</b>  no pudo ser creada
                            </div>}
                            expanded={`Valide los datos en la plantilla de cargue. Puede deberse a que alguna casilla tenga un valor: #¡REF! o a un problema en el servidor. (${e.message||""})`}
                        />
                            , 'danger', false);
                        contador--;
                        if(contador===0) reject();
                    })
            });
           
        }else{
            callbackParaCargue("El archivo excel se encuentra vació",'danger');
            reject();
        }
        
    })
}