import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Componentes auxiliares:
import CustomSection from '../../../../../utilidades/componentes/plantillaBase/CustomSection';

//Utilidad de base de datos:
import { firebaseFunctionsWithLocalOption, firestoreDB } from '../../../../../firebase';
import { Row, Col } from 'reactstrap';
import { BsTable } from 'react-icons/bs';

import Error404 from "../../../../404";
import CardDeFicheros from './componentes/CardDeFicheros.jsx';
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado.jsx';
import TablaDeNodos from '../../../../../utilidades/componentes/resumenDeModulo/TablaDeNodos.jsx';
import MatrizDeRiesgo from '../../../../../utilidades/componentes/resumenDeModulo/MatrizDeRiesgo.jsx';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader.jsx';

//Scripts auxiliares:
import { obtenerNodosConSubcolecciones } from '../../../../../utilidades/scripts/paraConsultaDeNodos';
import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection';
import { FaTrashAlt } from 'react-icons/fa';
import { get } from 'lodash';
import { confirmacionDeBorradoDeSistema } from './estaticos/alertasParaSwal';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { actualizarDataApi1160, actualizarDataApi580, actualizarDataAsmeB318s } from '../../../../../storage/reducerParaData';

const eliminarActivoMultiple = firebaseFunctionsWithLocalOption('eliminarActivoMultiple',false);

//Estilos base:
const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaParrafosDeInfo = { ...estiloDeFuentesBase, textAlign: "right", marginBottom: 2 };
const estiloParaHipervinculo = { marginLeft: 5, marginRight: 5, cursor: "pointer", textDecoration: "underline" };

//Constantes para el desarrollo:
const permitirConsultas = true;

class VisualizadorDeSistemas extends React.Component {

    constructor() {
        super();

        this.state = {
            idTarget: "",
            nodo: undefined,
            coleccionActiva: "",
            cargaCompleta: false,

            cargando: false,
            todosLosActivos: (permitirConsultas) ? [] : require('.././api580/estaticos/ejemploDeActivos.json'),

            itemsColumnaIzquierda: {
                1: {
                    activo: true,
                    tipo: "TABLA_DE_NODOS",
                    props: {
                        criterioDeOrdenamiento: {
                            id: "riesgo", desc: true
                        }
                    }
                }
            },
            itemsColumnaDerecha: {
                1: {
                    activo: true,
                    tipo: "FICHEROS",
                },
                2: {
                    activo: true,
                    tipo: "MATRIZ_DE_RIESGO",
                    props: {
                        criterioDeOrdenamiento: {
                            id: "riesgo", desc: true
                        }
                    }
                },
            },
        }
    }
        
    render() {
        const nodo = this.state.nodo;
        if (!this.state.error) {
            if (nodo !== undefined && this.state.cargaCompleta) {
                return (
                    <CustomSection
                        titulo={nodo.informacionGeneral.nombre}
                        subtitulo={"Sistema del campo " + nodo.informacionDeLosPadres.campoAsociado.nombre}
                        URLIconoPrincipal="/assets/img/iconos/iconoSegmento.jpg"
                        botonesAdicionales={[ <BotonParaSection 
                            key="BotonEliminarSegmento" 
                            icono={<FaTrashAlt />} 
                            onClick={this.eliminarSistema} 
                            title={'Eliminar'} 
                        />]}>

                        {this.obtenerInfoDeLosPadres()}



                        <Row>
                            <Col xs="12" md="12" lg="7" xl="7">
                                {this.obtenerContenidoDeColumna("itemsColumnaIzquierda")}
                            </Col>
                            <Col xs="12" md="12" lg="5" xl="5">
                                {this.obtenerContenidoDeColumna("itemsColumnaDerecha")}
                            </Col>
                        </Row>
                        <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                    </CustomSection>
                );
            }
            else { return (<Preloader elPreloaderEstaAbierto={true} />); }
        }
        else { return (<Error404 error={this.props.location.pathname} />); }
    }

    componentWillMount() {
        this.actualizarNodo();
    }

    componentDidMount(){
        this.setState({ moduloTarget: this.props.match.params.modulo });
    }

    //Métodos operativos:
    actualizarNodo = () => {
        const id = this.props.match.params.id;
        const coleccionActiva = "sistemas";

        firestoreDB.collection(coleccionActiva).doc(id).get()
            .then(snapshot => {
                this.setState({
                    id: snapshot.id,
                    coleccionActiva: coleccionActiva,
                    nodo: { id: snapshot.id, ...snapshot.data() },
                });
                setTimeout(() => {  this.actualizarNodosDeTablas(snapshot.ref); }, 50)
            })
            .catch(error => { 
                this.setState({ error: true })
                console.log(error); });
    }

    actualizarNodosDeTablas(refDoc) {
        if (permitirConsultas) {
            this.setState({ cargando: true });

            const moduloTarget = this.props.match.params.modulo;

            let promiseDeConsultaDeActivos = firestoreDB.collection("activos");
            promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
            promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionDeLosPadres.sistemaAsociado.referencia", "==",refDoc);

            obtenerNodosConSubcolecciones(promiseDeConsultaDeActivos, moduloTarget, true, false)
                .then(nodos => {
                    const todosLosActivos = this.limpiarVariablesInnecesarias(nodos);

                    this.setState({ todosLosActivos: todosLosActivos, });
                    setTimeout(() => { this.setState({ cargando: false, cargaCompleta: true }); }, 1000);
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ cargando: false });
                    window.document.body.style.cursor = "default";
                });
        }
    }

    actualizarDataEnVisualizadorPrincipal = () => {
        if(!this.props.moduloActivo) return
        /* ------------- Obtiene informacion de acuerdo a modulo activo ------------- */

        let dataActual
        let nuevoListadoDeActivos = []
        let actualizarDataEnRedux

        switch(this.props.moduloActivo){
            case 'API581':
                dataActual = this.props.dataDe580EnRedux
                actualizarDataEnRedux = this.props.actualizarDataApi580
                break;
            case 'API1160':
                dataActual = this.props.dataDe1160EnRedux
                actualizarDataEnRedux = this.props.actualizarDataApi1160
                break;
            case 'ASMEB31_8S':
                dataActual = this.props.dataDeB318sEnRedux
                actualizarDataEnRedux = this.props.actualizarDataAsmeB318s
                break;
            default:
                break;
        }
        /* --------------------- Actualiza informacion en redux --------------------- */
        actualizarDataEnRedux(
            {
                ...dataActual, 
                activos: nuevoListadoDeActivos,
            }
        )
    }
    
    avisoDeGuardadoSatisfactorio = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }

    eliminarSistema = () => {
        const estructuraElimicacion=[ { key :"informacionDeLosPadres.sistemaAsociado.referencia",condicion:"==",value:`sistemas/${get(this.state,"nodo.id",)}`,isReferenceDoc: true}]
        let miConfirmacionDeBorradoDeSegmento = { ...confirmacionDeBorradoDeSistema };
         miConfirmacionDeBorradoDeSegmento.html = miConfirmacionDeBorradoDeSegmento.html.replace("@nombreDelFichero",get(this.state,"nodo.informacionGeneral.nombre"));
        Swal.fire(miConfirmacionDeBorradoDeSegmento)
            .then(result => {
               if (result.isConfirmed) {
                 this.actualizarDataEnVisualizadorPrincipal()
                this.setState({ cargando: true });
                 eliminarActivoMultiple({where:estructuraElimicacion})
                        .then(result2 => {
                            this.setState({ cargando: false });
                            const responseEliminate=result2.data||{}
                            const resultadosEliminacion = responseEliminate.data||[];
                            if(!resultadosEliminacion.length)throw new Error(responseEliminate.message||"Error al eliminar Sistema")
                            const erroresDetectados= resultadosEliminacion.filter((itemResult)=>itemResult.status==="rejected"|| itemResult.value.status !=="ACTIVO_ELIMINADO")
                            if(erroresDetectados.length){
                                this.avisoDeGuardadoSatisfactorio(`se detectaron: ${resultadosEliminacion.length} Activos`, 'warning');
                                this.avisoDeGuardadoSatisfactorio(`Error eliminando ${erroresDetectados.length} activos del sistema`, 'error');
                            }
                            else {

                                this.avisoDeGuardadoSatisfactorio(`${resultadosEliminacion.length} Activos eliminados`, 'success')
                            }
                            this.actualizarDataEnVisualizadorPrincipal()
                            this.navegarHaciaPath(localStorage.getItem('regresarA'))
                             
                        })
                        .catch(error => { 
                            this.setState({ cargando: false });
                            this.avisoDeGuardadoSatisfactorio(error.message || 'Error al eliminar Sistema', 'error')
                        });
                        
                }
            }); 
    }

    obtenerContenidoDeColumna = (keyEnState) => {
        let columnaTarget = this.state[keyEnState];
        let keys = Object.keys(columnaTarget);
        let items = keys.map((key, i) => { return columnaTarget[key]; });
        items = items.filter(paramsDeComponente => paramsDeComponente.activo);

        return items.map((item, i) => {
            return (
                <React.Fragment key={keyEnState + "." + i}>
                    {this.obtenerContenidoBasadoEnTipo(item)}
                </React.Fragment>
            );
        });
    }

    obtenerContenidoBasadoEnTipo = (parametrizacionDelContenido) => {
        const moduloTarget = this.props.match.params.modulo;

        switch (parametrizacionDelContenido.tipo) {
            case "TABLA_DE_NODOS":
                if (this.state.todosLosActivos["0"].resultadosDeLaValoracion.segmentoCritico.id) {
                    return (
                        <CardConEncabezado titulo={`Activos que pertenecen al sistema ${this.state.nodo.informacionGeneral.nombre}`} icono={BsTable}>
                            <TablaDeNodos
                                coleccionActiva={'activos'}
                                listaDeNodos={this.state.todosLosActivos}
                                moduloTarget={moduloTarget}
                                parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget]}
                                {...parametrizacionDelContenido.props} />
                        </CardConEncabezado>
                    );
                }
                else {
                    return (
                        <CardConEncabezado titulo={`Activos que pertenecen al sistema ${this.state.nodo.informacionGeneral.nombre}`} icono={BsTable}>
                            <p style={{ textAlign: "center" }}>No hay activos asociados a este sistema</p>
                        </CardConEncabezado>
                    );
                }
            case "FICHEROS":
                return (
                    <CardDeFicheros 
                        nodo={this.state.nodo} 
                        coleccionTarget={this.state.coleccionActiva} />
                );

            case "MATRIZ_DE_RIESGO":
                return (
                    <MatrizDeRiesgo
                        coleccionActiva={'activos'}
                        listaDeNodos={this.state.todosLosActivos}
                        moduloTarget={moduloTarget}
                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget]} />
                );

            default:
                return (<React.Fragment />);
        }
    }

    limpiarVariablesInnecesarias = (listaDeNodos) => {
        const listaDeNodosLimpiados=listaDeNodos.map((nodo, i) => {
            try {
                let objetoConProbabilidad = {};
                let objetoConConsecuencia = {};
                let objetoConSegmentoCritico = {};
                let probabilidadOriginal = nodo["resultadosDeLaValoracion"]["probabilidad"];
                let consecuenciaOriginal = nodo["resultadosDeLaValoracion"]["consecuencia"];

                Object.keys(probabilidadOriginal).forEach((key, i) => {
                    if (key !== "logDeCalculos") {
                        objetoConProbabilidad[key] = probabilidadOriginal[key];
                    }
                });

                Object.keys(consecuenciaOriginal).forEach((key, i) => {
                    if (key !== "logDeCalculos") {
                        objetoConConsecuencia[key] = consecuenciaOriginal[key];
                    }
                });

                if (nodo["resultadosDeLaValoracion"]["segmentoCritico"]) {
                    objetoConSegmentoCritico = nodo["resultadosDeLaValoracion"]["segmentoCritico"];
                }
                else {
                    objetoConSegmentoCritico = { nombre: "-", id: undefined };
                }

                return ({
                    "id": nodo["id"],
                    "dataCompleta": true,
                    "dominioCorporativo": nodo["dominioCorporativo"],
                    "informacionGeneral": nodo["informacionGeneral"],
                    "informacionDeLosPadres": nodo["informacionDeLosPadres"],
                    "resultadosDeLaValoracion": {
                        "consecuencia": objetoConConsecuencia,
                        "planesDeAccion": { "informacionAdicional": nodo["resultadosDeLaValoracion"]["planesDeAccion"]["informacionAdicional"] },
                        "probabilidad": objetoConProbabilidad,
                        "riesgo": nodo["resultadosDeLaValoracion"]["riesgo"],
                        "segmentoCritico": objetoConSegmentoCritico,
                    }
                });
            }
            catch (error) { return { "dataCompleta": false } }
        });

        return listaDeNodosLimpiados.filter(item => item.dataCompleta);
    }

    obtenerNombreDeColeccionEnSingular = (coleccion) => {
        switch (coleccion) {
            case "campos":
                return "campo";
            case "sistemas":
                return "sistema";
            case "activos":
                return "activo";
            case "segmentos":
                return "segmento";
            default:
                return coleccion;
        }
    }

    //Métodos para renderizado:
    obtenerInfoDeLosPadres = () => {
        if (this.state.cargaCompleta) {
            let listaDeContenido = [];
            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo }} key={"infoDePadres." + this.state.nodo.id + ".nombre"}
                    dangerouslySetInnerHTML={{
                        __html:
                            "Estoy viendo los detalles del " + this.obtenerNombreDeColeccionEnSingular(this.state.coleccionActiva)
                            + " <b>" + this.state.nodo.informacionGeneral.nombre + "</b>"
                    }} />
            );

            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo, marginBottom: 20 }}
                    key={"infoDePadres." + this.state.nodo.id + ".paraSistema"}>
                    Pertenece al campo
                    <b style={{ ...estiloParaHipervinculo, marginRight: 0 }} onClick={this.navergarHaciaCampoPadre}>
                        {this.state.nodo.informacionDeLosPadres.campoAsociado.nombre}
                    </b>
                </p>
            );


            return listaDeContenido;
        }
        else {
            return "Cargando...";
        }
    }

    //Métodos para redirección:
    navegarHaciaPath = (path) => {
        this.props.history.push(path);
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }

    navergarHaciaCampoPadre = () => {
        const moduloTarget = this.props.match.params.modulo;
        const pathTarget = "/app/detalleDeNodo/campos/" + this.state.nodo.informacionDeLosPadres.campoAsociado.referencia.id + "/" + moduloTarget;
        this.navegarHaciaPath(pathTarget);
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa,
    dataDe580EnRedux: state.reducerParaData["/app/analisis/riesgo/api580"],
    dataDe1160EnRedux: state.reducerParaData["/app/analisis/riesgo/api1160"],
    dataDeB318sEnRedux: state.reducerParaData["/app/analisis/riesgo/b318s"],
    moduloActivo: state.reducerParaData['moduloActivo'],
});

const mapDispatchToProps = dispatch => ({
    actualizarDataApi580: nuevaData => { dispatch(actualizarDataApi580(nuevaData)); },
    actualizarDataApi1160: nuevaData => { dispatch(actualizarDataApi1160(nuevaData)); },
    actualizarDataAsmeB318s: nuevaData => { dispatch(actualizarDataAsmeB318s(nuevaData)); },
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VisualizadorDeSistemas));