import React, { useState } from 'react';

import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';

//Componentes auxiliares:
import CustomModal from '../../../../../../utilidades/componentes/plantillaBase/CustomModal';

//Utilidades de firebase:
import { firebaseFunctions } from '../../../../../../firebase.js';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';


const editarTagDeActivo = firebaseFunctions.httpsCallable('editarTagDeActivo');


const ConsolaDeEdicionDeActivo = (props) => {
    const [ loading, setLoading ] = useState(false)
    const [ disabled, setDisabled ] = useState(true)
    const [ nuevoTag, setNuevoTag ] = useState('')

    const actualizarActivo = async (activo) => {
        try {
            setLoading(true)
            let response = await editarTagDeActivo(
                {
                    activo: activo.id,
                    nuevoTag: nuevoTag
                }
            )

            response = response.data; 

            generarToast(response.message, response.status === 'ACTIVO_ACTUALIZADO' ? 'success' : 'error' )

            if(response.status === 'ACTIVO_ACTUALIZADO'){
                props.onSuccess()
            }
                        
        } catch (error) {
            generarToast(error.message, 'warning' )
        } 
        finally {
            setLoading(false)
        }
    }

    return (
        <CustomModal 
            size="md" 
            elModalEstaAbierto={props.elModalEstaAbierto}
            toggleDelModal={props.toggleDelModal} 
            titulo={<span>Editar tag del Activo <b>{props.activo.informacionGeneral.nombre.valor}</b></span>}
            hideFooter={true}
        >
            <Row style={{ padding: 15 }}>
                <Col md={12}>
                    <FormGroup>
                        <Label className="h6 " for="inputTag">Nuevo tag:</Label>
                        <Input 
                            id="inputTag"
                            type="text" 
                            name="inputTag" 
                            onChange={(e) => {
                                setDisabled(  e.target.value.length === 0 )
                                setNuevoTag( e.target.value )
                            }} 
                            placeholder={ `Ej: "Activo-123"`}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="justify-content-center mb-4 mt-3">
                <LaddaButton 
                    className={"mb-2 mr-2 btn btn-success w-50"}
                    loading={loading}
                    onClick={() => {
                        actualizarActivo(props.activo)
                    }}
                    data-style={EXPAND_RIGHT}
                    
                    disabled={disabled}
                >
                    Editar Activo
                </LaddaButton>
            </Row>
            <ToastContainer />
        </CustomModal>
    )
}

const generarToast = (texto, tipo) => {
    toast(texto, {
        closeButton: true,
        hideProgressBar: false,
        autoClose: 4000,
        position: 'bottom-right',
        closeOnClick: true,
        pauseOnHover: true,
        type: tipo || 'success'
    });
}

export default ConsolaDeEdicionDeActivo;
