export const glosarioDeOpciones = [
    {
        "key": "opcion1",
        "type":"web",
        "nombre": "Tablero de Control",
    }, 
    {
        "key": "opcion2",
        "type":"web",
        "nombre": "Detalle ODT Madre",
    }, 
    {
        "key": "opcion3",
        "type":"web",
        "nombre": "Detalle ODT Hija",
    }, 
    {
        "key": "opcion4",
        "type":"app",
        "nombre": "Iniciando",
    }, 
    {
        "key": "opcion5",
        "type":"app",
        "nombre": "Inspección",
    },
    {
        "key": "opcion6",
        "type":"app",
        "nombre": "Perfil de Usuario",
    },
]

export const glosarioDeRespuestas = [
    {
        "key": "respuesta1",
        "padre":"opcion1",
        "nombre": "Mapa y leyendas",
        "content": [
            {
                "type":"text",
                "value": `En el mapa se puede ver la ubicación real de las ordenes filtradas. Al dar click en uno de los puntos se muestra una descripción general de la orden y la posibilidad de entrar a ver
                el datalle de la misma. Al costado derecho del mapa se muestra un resumen con la cantidad de ordenes por estado dentro del filtro aplicado`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2Fmapa.gif?alt=media&token=4c69c458-cc4a-4971-bd49-977012ea5ec9`,
            },
        ],
    },
    {
        "key": "respuesta2",
        "padre":"opcion2",
        "nombre": "Detalle Orden de Trabajo",
        "content": [
            {
                "type":"text",
                // "value": `Esta sección se conforma por el Detalle General y la Identificación del Ducto. Es información general que en su mayoria fue diligenciada al moemento de la creacion. \
                // La información puede ser modificada en cualquier momento y será modificada a su vez a las ordenes hijas relacionadas.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FdetalleODTMadre.gif?alt=media&token=ba7c3f2c-c691-4bf6-a560-a7409f264f7a`,
            },
        ],
    },
    {
        "key": "respuesta3",
        "padre":"opcion3",
        "nombre": "Acceder al Detalle de Orden Hija",
        "content": [
            {
                "type":"text",
                "value":`Desde la tabla de formatos asociados que se encuentra en el Detalle de Orde de Trabajo Madre, el usuario puede ir al detalle de cada uno de los formatos hijos asociados a la orden Madre.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FaccederODTHija.gif?alt=media&token=44167841-93b5-4d6a-bd63-5176fd34450f`,
            },
        ],
    },
    {
        "key": "respuesta4",
        "padre":"opcion4",
        "nombre": "Iniciar Sesión",
        "content": [
            {
                "type":"text",
                "value":`Para poder comenzar a usar el aplicativo es necesario que el usuario inicie sesión con su \
                correo y contraseña corporativo.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FsignIn.gif?alt=media&token=642fb124-8a6a-4f28-9782-c165e84ea7a0`,
            },
            {
                "type":"text",
                "value":`Una vez autenticado, el usuario podrá hacer uso de las funcionalidades del aplicativo.`,
            },
        ],
    },
    {
        "key": "respuesta5",
        "padre":"opcion5",
        "nombre": "Inspección de ODT",
        "content": [
            {
                "type":"text",
                "value":`Para proceder con el registro de la información de la inspección, el usuario debe ingresar a la orden correspondiente tocando la tarjeta identificada con el numero de OT asignado.\
                Esto lo llevará a una pantalla donde se puede diligenciar la informacion de la inspección ILI, anomalias, registro fotográfico, reparaciones y ensayos no destructivos de la inspección.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FcontenidoODT.gif?alt=media&token=b93f4938-2913-4d9e-8c3b-d49b5766fc98`,
            },
        ],
    }, 
    {
        "key": "respuesta6",
        "padre":"opcion1",
        "nombre": "Tabla de Ordenes",
        "content": [
            {
                "type":"text",
                "value":`En la tabla se puede ver el listado de ordenes dentro del filtro aplicado sin importar sus estado. Al dar click en el ID de la orden se puede entrar a ver el detalle de la orden.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2Ftabla.gif?alt=media&token=257b796f-d318-4e6c-bb0f-a97935bc0ff4`,
            },
        ],
    }, 
    {
        "key": "respuesta7",
        "padre":"opcion4",
        "nombre": "Ordenes De Trabajo Asigandas",
        "content": [
            {
                "type":"text",
                "value":`Cada usuario tiene un listado de ordenes asignadas. Para revisar cuales son las ordenes de cada usuario, en la pantalla de \
                inicio debe acceder al modulo 'ODTS' tocando la segunda opción en menú lateral.`,
            },
            // {
            //     "type":"img",
            //     "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FODTSCard.png?alt=media&token=d5849dd2-6669-412d-8290-aad55be35317",
            // },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FodtAsignadas.gif?alt=media&token=e2240470-1dd9-420d-8988-f79a4c01a775`,
            },
        ],
    },
    {
        "key": "respuesta8",
        "padre":"opcion5",
        "nombre": "Novedad ILI",
        "content": [
            {
                "type":"text",
                "value":`Para diligenciar la informacion de la corrida ILI correspondiente a la Identificación de la anomalía, Ubicación geográfica, datos de la excavación y ubicación y georeferenciación, \
                el usuario debe tocar la tarjeta con el título 'Novadad ILI'. Al hacer esto se le redireccionará a una pantalla donde se le solicitará el primer dato necesario.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FnovedadILI.gif?alt=media&token=01da72a3-dd40-4fb9-8d53-7d81c8c177ed`,
            },
            {
                "type":"text",
                "value":`Lo mas probable es que los primeros datos ya esten diligenciados ya que son solicitados al momento de la creación de la orden. Sin embargo se solicita la confirmacion de los mismos ya \
                que son datos indispensables y necesarios para los resultados de la inspección`,
            },
        ],
    },
    {
        "key": "respuesta9",
        "padre":"opcion5",
        "nombre": "Registro de Anomalías",
        "content": [
            {
                "type":"text",
                "value":`Para registrar una anomalía, el usuario debe tocar la tarjeta con el título 'Registro de Anomalías'. Esto lo llevará a una pantalla donde habrá un resumen de las anomalías registradas y \
                un botón para agregar mas anomalías.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FregistroAnomalia2.gif?alt=media&token=d20aa444-4537-4ac2-a7af-391acc5b3fa7`,
            },
            {
                "type":"card",
                "title":"Agregar una Anomalía",
                "value":`Para agregar una nueva anomalia, el usuario deberá tocar el boton con el título 'Agregar Anomalía',\
                 y aparecerá un listado de los posibles tipos de anomalías para registrar'. Actualmente se cuentan con 6 opciones: Perdida de Metal, Abolladuras, Camisa, Camisa sobre Anillos, Parches y Bending Strain.\
                 Cada opcion le solicitará los datos especificos dependiendo del tipo de anomalía. Es posible agregar cuantas anomalias sean deseadas, pero hay que tener en cuenta las limitaciones a la hora de la exportación de la inspección.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FnuevaAnomalia.gif?alt=media&token=8c5e123d-0339-4ba9-befc-c12cb17970c9`,
            },
            {
                "type":"text",
                "value":`Al agregar la primera anomalía el estado de la orden cambiará acutomaticamente a 'EN SITIO'.`,
            },
            {
                "type":"card",
                "title":"Eliminar una Anomalía",
                "value":`Para eliminar una nueva anomalia, el usuario deberá tocar el boton con el ícono de X que se encuentra en la parte derecha de la tarjeta de la Anomalía. \
                El aplicativo pedira validación sobre la accion que se desea realizar, en caso de ser afirmativa la anomalía será eliminada.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FeliminarAnomalia.gif?alt=media&token=d635acc6-0dec-4022-948a-1616426c326f`,
            },
        ],
    },
    {
        "key": "respuesta10",
        "padre":"opcion5",
        "nombre": "Registro Fotográfico",
        "content": [
            {
                "type":"text",
                "value":`El módulo de registro fotografico permite al usuario agregar cuantas imagenes desee al registro de la inspección. Para acceder, el usuario debe toca la tarjeta con el tíulo 'Registro Fotográfico'. Esto lo llevará al modulo de registro fotografico.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FregistroFotografico.gif?alt=media&token=4cc0c0c4-e406-4af1-9bf9-7fdb355a6cf3`,
            },
            {
                "type":"card",
                "title":"Agregar una Imagen",
                "value":`Para agregar una nueva imagen, el usuario deberá tocar el boton que aparece en la esquina superior derecha con el título 'Agregar Fotográfia', esto lo llevará al proceso de agregar imagen. \
                Aquí se le solicitará que agregue la imagen (puede tomarla usando la cámara o seleccionarla de la galería de imágenes) y que escriba o seleccione el título, lo que sea mas conveniente para el usuario.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FagregarImagen.gif?alt=media&token=f90775bb-5b53-404d-8bb2-513c6eabf9c9`,
            },
            {
                "type":"card",
                "title":"Eliminar una Imagen",
                "value":`Para eliminar una imagen el usuario debera tocar en la esquina superior derecha de la image, sobre el ícono de eliminar. \
                El aplicativo pedira validación sobre la accion que se desea realizar, en caso de ser afirmativa la imagen será eliminada.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FeliminarImagen.gif?alt=media&token=7c815077-0153-4e83-afb1-95d1d49c77c4`,
            },
        ],
    },
    {
        "key": "respuesta11",
        "padre":"opcion6",
        "nombre": "Perfil de Usuario",
        "content": [
            {
                "type":"text",
                "value":`El módulo de Perfil de Usuario muestra la información basica del usuario actual (rol, correo, empresa a la que pertenece). El la sección 'Mi Firma' se puede revisar la información de la firma del usuario y en caso de no tener le permitirá crear una.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FperfilUsuario.gif?alt=media&token=aefd6ec8-9f8a-458e-89db-0a04fbb3619a`,
            },
        ],
    },
    {
        "key": "respuesta12",
        "padre":"opcion6",
        "nombre": "Firma",
        "content": [
            {
                "type":"card",
                "title":"Agregar Firma",
                "value":`Para agregar la firma, el usuario deberá tocar la tarjeta con el titulo 'Mi Firma', esto abrirá una sección donde el usuario con su dedo podrá dibujar su firma y guardarla.`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FagregarFirma.gif?alt=media&token=2ca1fccd-d85b-4705-927a-9c218d1f4408`,
            },
            {
                "type":"card",
                "title":"Modificar firma",
                "value":`Para modificar la firma actual, el usuario debe tocar sobre la imagen de la firma, esto abrirá una previsualización de la firma actual con la opción de 'Modificar'. \
                Una vez el usuario guarde la imagen, esta será actualiada en la base de datos. `,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2FmodificarFirma.gif?alt=media&token=2df02b96-3513-427d-a4d7-6488a3f6decc`,
            },
        ],
    },
    {
        "key": "respuesta13",
        "padre":"opcion2",
        "nombre": "Edición Datos de Orden Madre",
        "content": [
            {
                "type":"text",
                "value":`Para modificar los datos básicos de la orden de trabajo madre es necesario dar click al botón de título 'Editar' que se encuentra bajo el mapa. \
                Aquí se solicitará la información que el usuario desee modificar y se actualizará en la orden Madre y en las ordenes hijas asociadas`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FedicionODTMadre.gif?alt=media&token=c1361772-547a-4eb7-a999-23768da520c4`,
            },
        ],
    },
    {
        "key": "respuesta14",
        "padre":"opcion2",
        "nombre": "Tabla de Formatos Asociados",
        "content": [
            {
                "type":"text",
                "value":`En esta tabla se ve el listado de ordenes hijas asociadas a la orden madre actual. El usuario puede añadir o eliminar ordenes segun sea la conveniencia. Para entrar a ver el detalle de la orden hija es becesario dar click en el botón con título 'Detalle'`,
            },
            {
                "type":"img",
                "value":`https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FtablaODTHijas.jpg?alt=media&token=f9e117b1-16d2-4f9f-89ac-d8612bacdb7c`,
            },
        ],
    },
    {
        "key": "respuesta15",
        "padre":"opcion2",
        "nombre": "Gestion de Formatos Asociados",
        "content": [
            {
                "type": "card",
                "title": "Agregar una Orden de Trabajo Hija",
                "value": `Para agregar una nueva imagen, el usuario deberá tocar el boton que aparece en la esquina superior derecha con el título 'Agregar Fotográfia', esto lo llevará al proceso de agregar imagen. \
                Aquí se le solicitará que agregue la imagen (puede tomarla usando la cámara o seleccionarla de la galería de imágenes) y que escriba o seleccione el título, lo que sea mas conveniente para el usuario.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FcrearODTHija.gif?alt=media&token=005c61e3-64fb-4d4c-816c-fdc01c015bb3`,
            },
            {
                "type": "card",
                "title": "Eliminar una Orden de Trabajo Hija",
                "value": `Para eliminar una orden de trabajo hija el usuario debe hacer click en el icono de eliminar ubicado en el extremo derecho de la tarjeta de la orden hija. Para poder eliminar la orden el usuario debe contar \
                con el rol adecuado para esta acción (admin, supervisor).`,
            },
            {
                "type": "img",
                "value": "https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeliminarODTHija.gif?alt=media&token=d4cb166b-5cca-4c82-929d-1e71bf6f8947",
            },
        ]
    },
    {
        "key": "respuesta16",
        "padre":"opcion1",
        "nombre": "Agregar una Orden de Trabajo Madre",
        "content": [
            {
                "type": "text",
                "value": `La primera opción para crear ordenes de Trabajo es crear una por una. Para esto vamos a hacer click en el icono '+' que se encuentra en la barra superior y a continuacion \
                aparecerá el formato para diligenciar con la información necesaria para la creación.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FcrearODTMadre.gif?alt=media&token=dd48fe2b-170f-4d26-80d8-7f8f43697a2f`,
            },
        ]
    },
    {
        "key": "respuesta15",
        "padre":"opcion1",
        "nombre": "Cargue masivo de Ordenes Madres e hijas",
        "content": [
            {
                "type": "card",
                "title": "Plantilla de Cargue",
                "value": `El otro metodo de creación de ordenes es de forma masiva. Para realizar el cargue masivo de ordenes es necesario usar una plantilla en Excel. En ella se pide la informacion necesaria para realizar el proceso de creacion de ordenes Madres e Hijas. \
                A continuación el proceso para el descargue de la plantilla.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FdescarguePlantilla.gif?alt=media&token=fbef966e-74f0-4699-8690-805c8f5d993a`,
            },
            {
                "type": "card",
                "title": "Proceso de cargue masivo",
                "value": `Una vez se tenga diligenciada la plantilla, se realiza el proceso de cargue masivo. A continuacion el proceso realizado.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FcargueMAsivo.gif?alt=media&token=654cd8e6-1201-4801-9f6c-3818e272d390`,
            },
        ]
    },
    {
        "key": "respuesta16",
        "padre":"opcion3",
        "nombre": "Agregar o Editar Información ILI",
        "content": [
            {
                "type":"text",
                "value":`De esta forma el usuario puede agregar o editar la informacion de la corrida ILI. Esto aplica para 'Identificación Anomalía ILI', 'Ubicación Geográfica', 'Datos de la Excavación' y \
                'Datos de Campo'. El progrmama solicita la totalidad de los datos de la sección.`,
            },
            {
                "type": "card",
                "title": "Agregar Datos",
                "value": `Para agregar datos es necesario dar click al boton con título 'Agregar Datos'. De esta forma aparece un formato con los datos necesarios para la sección que se va a agregar.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FagregarDatosODTHija.gif?alt=media&token=36ace488-2b02-420e-b74d-2aeb30699499`,
            },
            {
                "type": "card",
                "title": "Editar Datos",
                "value": `El proceso de edicion es similar al de 'Agregar Datos'. Para editar el usuario debe dar click al boton con título 'Editar Datos' El formato de datos solicitados es el mismo.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeditarDatosODTHija.gif?alt=media&token=082a3a36-7fa4-4288-bebc-1e3e367c1e9f`,
            },
        ],
    },
    {
        "key": "respuesta17",
        "padre":"opcion3",
        "nombre": "Editar Anomalías",
        "content": [
            {
                "type":"text",
                "value":`De esta forma el usuario puede editar la información de anomalías recogida en campo. El proceso puede variar un poco dependiendo del tipo de anomalía. \
                Cada vez que el usuario cambia un dato, se realizan las validaciones y cálculos a los que haya lugar para actualizar la información de forma correcta.`,
            },
            {
                "type": "card",
                "title": "Perdida de Metal",
                "value": `Al existir la posibilidad de que haya mas de una anomalia de perdida de metal, la edición se da a nivel individual. Al inicio de la fila de cada \
                anomalía hay un boton que nos lleva al formato de edición.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeditarPMODTHija.gif?alt=media&token=611f00a9-9a57-41c6-b060-d3e307f64f2b`,
            },
            {
                "type": "card",
                "title": "Abolladura",
                "value": `La edición de la abolladura es mas sencilla. En la esquina superior derecha del cuadro de abolladura hay un boton con título 'Editar Datos', \
                al hacer click nos lleva al formato con la información ya existente de la anomalia y allí se podra hacer la modificación de los datos.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeditarAbolladuraODTHija.gif?alt=media&token=5a7589d2-92fe-4f4e-957f-f27a1fe003df`,
            },
            {
                "type": "card",
                "title": "Camisas-General",
                "value": `La edición de camisas se divide en dos partes. La informacion general de la camisa (camisa, camisa sobre anillos o parche) se realiza de igual forma a una anomalia de abolladura, \
                click al boton 'Editar Datos 'en la esquina superior derecha del cuadro de la anomalia.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeditarCamisasGeneralODTHija.gif?alt=media&token=54660d24-39a1-403a-83cf-9c2e71b170d9`,
            },
            {
                "type": "card",
                "title": "Camisas-SubAnomalía",
                "value": `La información de las subanomalías, en caso de exisitr, se modifica de igual forma a una anomalía de perdida de metal. Al inicio de la fila de la subanomalía hay un boton para la edicion de la subanomalía.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeditarCamisasSubODTHija.gif?alt=media&token=31219b81-65a3-42e3-95f0-0370ed160354`,
            },
        ],
    },
    {
        "key": "respuesta1",
        "padre": "opcion3",
        "nombre": "Agregar o Editar Imagenes",
        "content": [
            {
                "type": "card",
                "title": "Agregar nueva Imagen",
                "value": `En el registro fotográfico, aparece una tarjeta con la opcion 'Agregar nueva imagen a la galeria'. Al hacer click, nos lleva a formato para agregar imagenes. Es importante resaltar que \
                se aceptan imagenes en cualquier formato que no supere 1.5MB de tamaño.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FagregarImagenODTHija.gif?alt=media&token=caf611ba-a857-4af5-89da-a9616636ff30`,
            },
            {
                "type": "card",
                "title": "Editar nueva Imagen",
                "value": `Para editar una imagen ya existente solo es necesario dar click sobre la imagen que se desea editar. Es posible cambiar la imagen y el título de la misma. El proceso es similar al de agregar imagen.`,
            },
            {
                "type": "img",
                "value": `https://firebasestorage.googleapis.com/v0/b/giacore-dev.appspot.com/o/Utilidades%2FgifsFAQ%2Fweb%2FeditarImagenODTHija.gif?alt=media&token=fbe4f44d-2045-4ed4-bc60-f867baf0610f`,
            },
        ],
    },
]