import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataUtScanC = (nodoCml) => {
    let datosTabla = [];

    const listaDeNodos = nodoCml;

    try{
        listaDeNodos.sort(( a, b ) => a?.variablesDeLaInspeccion?.utScanC?.ordenDeCargue?.valor > 
        b?.variablesDeLaInspeccion?.utScanC?.ordenDeCargue?.valor ? 1 : 
        b?.variablesDeLaInspeccion?.utScanC?.ordenDeCargue?.valor > 
        a?.variablesDeLaInspeccion?.utScanC?.ordenDeCargue?.valor ? -1 : 0)
        
        listaDeNodos.forEach((data, i) => {
            let resVarC = data?.variablesDeLaInspeccion?.utScanC;
            let resVarA = data?.variablesDeLaInspeccion?.utScanA;
            let resint = data?.resultadosIntegridad;
            datosTabla.push({
                item: {label:'Item', valor: i+1, minWidth: 80},
                tagLinea: {label:'TAG LÍnea', valor: data.informacionDeLosPadres?.activoAsociado?.nombre, minWidth: 150},
                'id/cml': {label:'id/cml', valor: resVarA?.locacion?.valor || 0, minWidth: 80},
                diametro: {label:'Diámetro (in)', valor: formatearvalorsegunestado(resVarA?.diametro?.valor, 0) || 0, minWidth: 100},
                velocidadCorrosion: {label:'Velocidad de corrosión (mpy)', valor: formatearvalorsegunestado(resint?.velocidadCorrosion?.valor, 1), minWidth: 180 },
                // areaTotalInspeccionX: {label:'Área total inspeccion X (in)', valor:resVarC?.areaTotalInspeccionX?.valor, minWidth: 180, condicion: 'dataEnriquecida'},
                // areaTotalInspeccionY: {label:'Área total inspeccion Y (in)', valor:resVarC?.areaTotalInspeccionY?.valor, minWidth: 180, condicion: 'dataEnriquecida'},
                espesorMinimoEncontrado: {label:'Espesor mínimo encontrado (in)', valor: formatearvalorsegunestado(resVarC?.espesorMinimoEncontrado?.valor, 3) , minWidth: 130},
                espesorPromedio: {label:'Espesor promedio (in)', valor: formatearvalorsegunestado(resVarC?.espesorPromedio?.valor, 3), minWidth: 170},
                porcentajeDePerdida: {label:'Porcentaje de pérdida (%)', valor: formatearvalorsegunestado(resint?.PM.valor, 1), minWidth: 120},
            })
        })
    }
    catch(error){console.log(error)}
    return datosTabla
}

export default armarDataUtScanC
