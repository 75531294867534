import React from 'react'
import { firestoreDB } from "../../../../../../firebase";
import { firebaseApp } from "../../../../../../firebase";
import { connect } from 'react-redux'
import { Label, Input, Col, Row, Breadcrumb, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, CardFooter } from 'reactstrap';

//Scripts
import { parametrosDeEstados } from '../../../scripts/parametrosDeEstados';

//Iconos
import { MdClose } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { MdSave } from "react-icons/md";

//Componentes Genéricos
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";
import MapaDeFormatoDeInspeccion from '../../../components/Mapa/MapaDeFormatoDeInspeccion';

//Images
let defaultAvatar = "/assets/img/iconos/avatar.webp";

class InformacionGeneralODTMadre extends React.Component {
    constructor(props) {
        super(props);
        this.isMonted = false;
        this.state = {
            dropdownEstadosOpen: false,
            dropdownTecnicosOpen: false,
            listaDeTecnicos: [],
            fechaRealDeProgramacion: undefined,
            idDeLaOrdenDeInspeccion: undefined,
            estadoDelRegistro: {},
            latitudes:[],
            longitudes:[],

            //Edicion
            edicionDeshabilitada: true,
            estadoDelRegistroEnEdicion: {},
            estadoModificado: false,

            //Render
            contadorKeyRender: 0
        }
    }

    componentDidMount() {
        this.obtenerCoordenadas()
        this.setState({
            idDeLaOrdenDeInspeccion: this.props.id,
            estadoDelRegistro: this.props.estadoDelRegistro,
            estadoDelRegistroEnEdicion: { ...this.props.estadoDelRegistro }
        })

        this.isMonted = true;
        let getTecnicos = this.traerTecnicos();

        getTecnicos.then(() => {
            if (this.props.estadoDelRegistro.fechaRealDeProgramacion !== undefined && this.props.estadoDelRegistro.fechaRealDeProgramacion !== null) {
                this.setState({ fechaRealDeProgramacion: new Date(this.props.estadoDelRegistro.fechaRealDeProgramacion.seconds * 1000) })
            }
        })
    }

    componentWillUnmount() {
        this.isMonted = false;
    }

    render() {
        if (this.props.estadoDelRegistro !== undefined) {
            return (
                <React.Fragment>
                    <div className={"mb-0 p-3 d-flex border rounded-top"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                        <BreadcrumbItem className='BreadcrumbItem-color align-items-center' >DETALLE GENERAL</BreadcrumbItem>
                    </div>
                    <div className="p-3 shadow-none border rounded-bottom">
                        <FormGroup>
                            <Row>
                                <Col className='ml-xs-0 ' xs='12' md='12' lg='4'>
                                    <Row>
                                        <Col xs='12' md='12' lg='12'>
                                            <Label for="supervisor" className='mb-0 mt-2'>Supervisor:</Label>
                                            <Input type="text" name="responsable" id="responsable" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.responsable.nombre} disabled />
                                        </Col>
                                        <Col xs='12' md='12' lg='12'>
                                            {this.obtenerRenderDeDropdownEstados()}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        {this.obtenerRenderFechaEstimadaDeProgramacion()}
                                        {this.obtenerRenderFechaRealDeInicio()}
                                        {this.obtenerRenderFechaRealDeFinalizacion()}
                                    </Row>
                                    <Row>
                                        {this.obtenerRenderFechaDeActualizacion()}
                                        {this.obtenerRenderFechaRealDeProgramacion()}
                                    </Row>
                                </Col>
                            </Row>
                        </FormGroup>
                        {/* <Col className="d-flex flex-row px-0" style={{backgroundColor:'green'}}> */}
                        <FormGroup>
                            <Row>
                                <Col xs='12' md='12' lg='12'>
                                    <Row>
                                        <Col xs='12' md='6' lg='3'>
                                            <Label for="descripcionLinea" className='mb-0 mt-2'>Línea de ducto:</Label>
                                            <Input type="text" className='text-center' name="descripcionLinea" id="descripcionLinea" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.lineaDeDucto ? this.props.estadoDelRegistro.lineaDeDucto : '-'} disabled />
                                        </Col>
                                        <Col xs='12' md='12' lg='3'>
                                            <Label for="distanciaDeInicio" className='mb-0 mt-2'>Distancia de inicio de la anomalía:</Label>
                                            <Input type="text" name="distanciaDeInicio" id="distanciaDeInicio" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.distanciaDeInicio} disabled />
                                        </Col>

                                        <Col xs='12' md='12' lg='3' className=''>
                                            <Label for="distanciaDeFin" className='mb-0 mt-2 '>Distancia de fin de la anomalía:</Label>
                                            <Input type="text" name="distanciaDeFin" id="distanciaDeFin" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.distanciaDeFin} disabled />
                                        </Col>
                                        <Col xs='12' md='12' lg='3' className=''>
                                            <Label for="longitudAnomalia" className='mb-0 mt-2 '>Longitud de la anomalía:</Label>
                                            <Input type="text" name="longitudAnomalia" id="longitudAnomaliaGeneral" placeholder="Sin asignar" defaultValue={(this.props.estadoDelRegistro.distanciaDeFin && this.props.estadoDelRegistro.distanciaDeInicio)?(this.props.estadoDelRegistro.distanciaDeFin - this.props.estadoDelRegistro.distanciaDeInicio).toFixed(3):''} disabled />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xs='12' md='6' lg='3'>
                                            <Label for="latitudInicial" className='mb-0 mt-2'>Latitud Inicial:</Label>
                                            <Input type="text" className='text-center' name="latitudInicial" id="latitudInicial" placeholder="Sin asignar" defaultValue={this.state.latitudes[0]} disabled />
                                        </Col>
                                        <Col xs='12' md='12' lg='3'>
                                            <Label for="latitudFinal" className='mb-0 mt-2'>Latitud Final:</Label>
                                            <Input type="text" className='text-center' name="latitudFinal" id="latitudFinal" placeholder="Sin asignar" defaultValue={this.state.latitudes[1]} disabled />
                                        </Col>

                                        <Col xs='12' md='12' lg='3' className=''>
                                            <Label for="longitudInicial" className='mb-0 mt-2 '>Longitud Inicial:</Label>
                                            <Input type="text" className='text-center' name="longitudInicial" id="longitudInicial" placeholder="Sin asignar" defaultValue={this.state.longitudes[0]} disabled />
                                        </Col>
                                        <Col xs='12' md='12' lg='3' className=''>
                                            <Label for="longitudFinal" className='mb-0 mt-2 '>Longitud Final:</Label>
                                            <Input type="text" className='text-center' name="longitudFinal" id="longitudFinal" placeholder="Sin asignar" defaultValue={this.state.longitudes[1]} disabled />
                                        </Col>
                                    </Row> */}
                                </Col>
                                <Col xs='12' md='12' lg='12'>
                                    <Label for="descripcionODT" className='mb-0 mt-2'>Descripción de la orden de trabajo:</Label>
                                    <Input type="textarea" name="descripcionODT" id="descripcionODT" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.descripcionODT} disabled />
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                    <br/>
                    <div className="p-3 shadow-none border rounded-bottom">
                        <Row>
                            <Col className='ml-xs-0 ' xs='12' md='12' lg='6'>
                                <div className={"mb-0 p-3 d-flex border rounded-top"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                                    <BreadcrumbItem className='BreadcrumbItem-color align-items-center' >IDENTIFICACIÓN DEL DUCTO</BreadcrumbItem>
                                </div>
                                <Row>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="zona" className='mb-0 mt-2'>Zona:</Label>
                                        <Input type="text" name="zona" id="zona" placeholder='Zona' defaultValue={this.props.estadoDelRegistro.zonaDeDucto} disabled />
                                    </Col>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="region" className='mb-0 mt-2'>Región:</Label>
                                        <Input type="text" name="region" id="region" placeholder="Region" defaultValue={this.props.estadoDelRegistro.regionDeDucto} disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="Depto" className='mb-0 mt-2'>Depto O&M:</Label>
                                        <Input type="text" name="Depto" id="Depto" placeholder="Depto O&M" defaultValue={this.props.estadoDelRegistro.departamentoOYMDeDucto} disabled />
                                    </Col>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="sistema" className='mb-0 mt-2'>Sistema:</Label>
                                        <Input type="text" name="sistema" id="sistema" placeholder="Sistema" defaultValue={this.props.estadoDelRegistro.sistemaDeDucto} disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="Depto" className='mb-0 mt-2'>Diámetro:</Label>
                                        <Input type="text" name="diametro" id="diametro" placeholder="Diámetro" defaultValue={this.props.estadoDelRegistro.diametroDeDucto} disabled />
                                    </Col>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="EspesorNominal" className='mb-0 mt-2'>Espesor nominal:</Label>
                                        <Input type="text" name="EspesorNominal" id="EspesorNominal" placeholder="Espesor nominal" defaultValue={this.props.estadoDelRegistro.espesorNominalDeDucto} disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="SMYS" className='mb-0 mt-2'>SMYS:</Label>
                                        <Input type="text" name="SMYS" id="SMYS" placeholder="SMYS" defaultValue={this.props.estadoDelRegistro.SMYSDeDucto} disabled />
                                    </Col>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="MAOP" className='mb-0 mt-2'>MAOP:</Label>
                                        <Input type="text" name="MAOP" id="MAOP" placeholder="MAOP" defaultValue={this.props.estadoDelRegistro.MAOPDeDucto} disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='12' md='4' lg='6'>
                                        <Label for="CEMOP" className='mb-0 mt-2'>CEMOP:</Label>
                                        <Input type="text" name="CEMOP" id="CEMOP" placeholder="CEMOP" defaultValue={this.props.estadoDelRegistro.CEMOPDeDucto} disabled />
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='ml-xs-0 ' xs='12' md='12' lg='6'>
                                <div className={"mb-0 p-3 d-flex border rounded-top"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                                    <BreadcrumbItem className='BreadcrumbItem-color align-items-center' >UBICACIÓN GEOGRÁFICA</BreadcrumbItem>
                                </div>
                                <br></br>
                                <MapaDeFormatoDeInspeccion
                                    ordenesDeTrabajo={[this.props.estadoDelRegistro]}
                                    tipoMapa={'satelite'}
                                    //ordenesDeTrabajo={this.state.listaDeFormatos}
                                    crearBadgeSegunEstado={this.crearBadgeSegunEstado}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <br></br>
                        </Row>
                        <CardFooter className="d-block text-right">
                            {this.obtenerRenderDeBottonesDeAccion()}
                        </CardFooter>
                    </div>
                    <br/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Breadcrumb>
                        <BreadcrumbItem className='BreadcrumbItem-color'>Este documento ya no existe</BreadcrumbItem>
                    </Breadcrumb>
                </React.Fragment>
            )
        }
    }

    //Funciones Render
    generarListaDeTecnicosParaDropdown = () => {
        if (this.state.listaDeTecnicos !== undefined) {
            return this.state.listaDeTecnicos.map(tecnico => {
                return (
                    <DropdownItem
                        key={`tecnico ${tecnico.correo}`}
                        onClick={() => this.setDatosModificados('tecnico', { nombre: tecnico.nombre, correo: tecnico.correo })}>
                        <div className="widget-content-left mr-4">
                            <img width={30} className="rounded" style={{ borderRadius: "50%" }} src={tecnico.fotoDePerfil ? tecnico.fotoDePerfil.url : defaultAvatar} alt="" />
                        </div>
                        {tecnico.nombre}
                    </DropdownItem>
                );
            });
        }
    }

    obtenerCoordenadas = () => {
        let coordenadas = this.props.estadoDelRegistro.coordenadas.split(';')
        let latitudes = []
        let longitudes = []
        coordenadas.forEach(coordenada => {
            latitudes.push(coordenada.split(':')[1])
            longitudes.push(coordenada.split(':')[0])
        });
        this.setState({
            latitudes: latitudes,
            longitudes: longitudes,
        })
    }

    obtenerRenderDeBottonesDeAccion = () => {
        const rolesPermitidos = ["admin", "supervisor", "control_calidad", "programador_contrato"]
        if (rolesPermitidos.includes(this.props.usuarioActivo.rol)) {
            return (
                <>
                    <BotonCallToAction
                        className="mx-2"
                        style={{ width: 150 }}
                        key={'btn_estado_edicion'}
                        usuarioActivo={this.props.usuarioActivo}
                        color={this.state.edicionDeshabilitada ? 'primary' : 'success'}
                        icono={this.state.edicionDeshabilitada ? MdEdit : MdSave}
                        titulo={'Editar'}
                        onClick={() => this.props.toggleModal()}
                        disabled={!this.state.edicionDeshabilitada && !this.state.estadoModificado}
                    />

                    <BotonCallToAction
                        className={this.state.edicionDeshabilitada ? "d-none" : ""}
                        style={{ width: 150 }}
                        key={'btn_estado_cancelar'}
                        usuarioActivo={this.props.usuarioActivo}
                        color={'light'}
                        icono={MdClose}
                        colorDeIcono={'dark'}
                        titulo={'Cancelar'}
                        onClick={() => this.accionCancelar()}
                    />
                </>
            );
        }
    }

    obtenerRenderDeDropdownEstados = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.estado;
        return (
            <>
                <Label for="estado" className='mb-0 mt-2'>Estado:</Label>
                <Dropdown key={'dropdown_estado_' + this.state.contadorKeyRender} isOpen={this.state.dropdownEstadosOpen} toggle={this.toggleEstados} disabled={this.state.edicionDeshabilitada} >
                    <DropdownToggle className='w-100' style={{ backgroundColor: this.state.edicionDeshabilitada ? '#e9ecef' : 'white', color: 'black', fontSize: 16, fontWeight: 'normal', borderColor: '#ced4da' }}>
                        {tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("estado") || "Sin asignar" : "Sin asignar"}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Estado</DropdownItem>
                        {this.obtenerRenderListaDeEstadosSegunRol()}
                    </DropdownMenu>
                </Dropdown>
            </>
        );
    }

    obtenerRenderDeDropdownTecnicos = () => {
        //if (this.state.estadoDelRegistro.estado !== 'PLANEADA' || mostrarEnEdicion) {
        const valorEnEdicion = this.state.edicionDeshabilitada ? this.state.estadoDelRegistroEnEdicion : this.state.estadoDelRegistro;
        const tieneValorAsignado = !!(valorEnEdicion.tecnico && valorEnEdicion.tecnico.nombre !== "" && valorEnEdicion.tecnico.correo !== "");
        return (
            <React.Fragment>
                <Label for="tecnico" className='mb-0 mt-2'>Inspector :</Label>
                <Dropdown
                    key={'dropdown_tecnico_' + this.state.contadorKeyRender}
                    isOpen={this.state.dropdownTecnicosOpen}
                    toggle={this.toggleTecnicos} disabled={this.state.edicionDeshabilitada}>
                    <DropdownToggle caret={false} className='w-100' style={{ backgroundColor: this.state.edicionDeshabilitada ? '#e9ecef' : 'white', color: 'black', fontSize: 16, fontWeight: 'normal', borderColor: '#ced4da' }}>
                        {tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("tecnico") || "Sin asignar" : "Sin asignar"}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Técnico</DropdownItem>
                        {this.generarListaDeTecnicosParaDropdown()}
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )
        //}
    }

    obtenerRenderListaDeEstadosSegunRol = () => {
        // const estadosPorRol = estadosSegunRolyEstadoActual[this.props.usuarioActivo.rol];
        // if(estadosPorRol){
        //     if(estadosPorRol[this.props.estadoDelRegistro.estado]){
        //         return estadosPorRol[this.props.estadoDelRegistro.estado].map(estado =>{
        //             return (
        //                 <DropdownItem key={'key_'  + estado} onClick={() => this.setDatosModificados("estado", estado)}>{parametrosDeEstados[estado]['tituloDelModal']}</DropdownItem>
        //             );
        //         })
        //     }
        // }
        // return (<></>);

        return Object.keys(parametrosDeEstados).map(key => {
            return (
                <DropdownItem key={'key_' + parametrosDeEstados[key].estado} onClick={() => this.setDatosModificados("estado", parametrosDeEstados[key].estado)}>{parametrosDeEstados[key]['tituloDelModal']}</DropdownItem>
            );
        });
    }

    obtenerRenderFechaDeActualizacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaDeActualizacion;
        return (
            <Col key={'fecha_actualizacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                <Label for="fechaDeActualizacion" className='mb-0 mt-2'>Fecha de Actualización:</Label>
                <Input
                    type={tieneValorAsignado ? "date" : "text"}
                    name="fechaDeActualizacion"
                    id="fechaDeActualizacion"
                    placeholder="Sin asignar"
                    value={tieneValorAsignado ? new Date(this.state.estadoDelRegistro.fechaDeActualizacion.toDate()).toISOString().split('T')[0] : "Fecha aún no definida"}
                    disabled
                />
            </Col>
        )
    }

    obtenerRenderFechaEstimadaDeProgramacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaEstimadaDeProgramacion;
        return (
            <Col key={'fecha_est_programacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                <Label for="fechaEstimadaDeProgramacion" className='mb-0 mt-2'>Fecha Estimada de Programación:</Label>
                <Input
                    type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                    name="fechaEstimadaDeProgramacion"
                    id="fechaEstimadaDeProgramacion"
                    placeholder="Sin asignar"
                    value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaEstimadaDeProgramacion") : "Fecha aún no definida"}
                    onChange={(e) => this.setDatosModificados("fechaEstimadaDeProgramacion", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                    disabled={this.state.edicionDeshabilitada}
                />
            </Col>
        )
    }

    obtenerRenderFechaRealDeFinalizacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaRealDeFinalizacion;

        //if (poseeEstadoPermitido || mostrarEnEdicion) {
            return (
                <Col key={'fecha_real_finalizacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                    <Label for="fechaRealDeFinalizacion" className='mb-0 mt-2'>Fecha Real de Finalización:</Label>
                    {/* <Input type='text' placeholder="Sin asignar"
                        defaultValue={tieneValorAsignado ? this.obtenerValorActualDeInput("fechaRealDeFinalizacion") : "Fecha aún no definida"}
                        disabled /> */}
                    <Input
                        type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                        name="fechaRealDeFinalizacion"
                        id="fechaRealDeFinalizacion"
                        placeholder="Sin asignar"
                        value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaRealDeFinalizacion") : "Fecha aún no definida"}
                        onChange={(e) => this.setDatosModificados("fechaRealDeFinalizacion", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                        disabled={this.state.edicionDeshabilitada}
                    />
                </Col>
            )
        // } else {
        //     return (<React.Fragment></React.Fragment>)
        // }
    }

    obtenerRenderFechaRealDeInicio = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaRealDeInicio;

        //if ((this.state.estadoDelRegistro.estado !== 'PLANEADA' && this.state.estadoDelRegistro.estado !== 'PROGRAMADA') || mostrarEnEdicion) {
            return (
                <Col key={'fecha_real_inicio_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                    <Label for="fechaRealDeInicio" className='mb-0 mt-2'>Fecha Real de Inicio:</Label>
                    <Input
                        type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                        name="fechaRealDeInicio"
                        id="fechaRealDeInicio"
                        placeholder="Sin asignar"
                        value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaRealDeInicio") : "Fecha aún no definida"}
                        onChange={(e) => this.setDatosModificados("fechaRealDeInicio", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                        disabled={this.state.edicionDeshabilitada}
                    />
                </Col>
            )
        // } else {
        //     return (<React.Fragment></React.Fragment>)
        // }
    }

    obtenerRenderFechaRealDeProgramacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaRealDeProgramacion;
        const mostrarEnEdicion = (this.state.estadoDelRegistroEnEdicion.estado !== 'PLANEADA' && !this.state.edicionDeshabilitada);

        if (this.state.estadoDelRegistro.estado !== 'PLANEADA' || mostrarEnEdicion) {
            return (
                <Col key={'fecha_real_programacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                    <Label for="fechaRealDeProgramacion" className='mb-0 mt-2'>Fecha Real de Programación:</Label>
                    <Input
                        type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                        name="fechaRealDeProgramacion"
                        id="fechaRealDeProgramacion"
                        placeholder="Sin asignar"
                        value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaRealDeProgramacion") : "Fecha aún no definida"}
                        onChange={(e) => this.setDatosModificados("fechaRealDeProgramacion", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                        disabled={this.state.edicionDeshabilitada}
                    />
                </Col>
            )
        } else {
            return (<></>)
        }
    }

    //Funciones Lógicas
    accionCancelar = () => {
        this.setState({
            estadoModificado: false,
            edicionDeshabilitada: true,
            estadoDelRegistroEnEdicion: { ...this.state.estadoDelRegistro },
            contadorKeyRender: this.state.contadorKeyRender + 1
        });
    }

    accionEditar = () => {
        if (this.state.edicionDeshabilitada) {
            this.setState({
                edicionDeshabilitada: !this.state.edicionDeshabilitada
            });
        } else {
            this.actualizarDocumentoEnBd();
        }
    }

    actualizarDocumentoEnBd = () => {
        const datosActualizados = this.state.estadoDelRegistroEnEdicion;
        firestoreDB.doc(`ordenesDeTrabajo/${this.state.idDeLaOrdenDeInspeccion}`).update(datosActualizados)
            .then(() => {
                this.setState({
                    edicionDeshabilitada: !this.state.edicionDeshabilitada,
                    estadoDelRegistro: datosActualizados,
                    estadoModificado: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    obtenerValorActualDeInput = (parametro) => {
        let valorParametro = this.state.edicionDeshabilitada
            ? this.state.estadoDelRegistro[parametro]
            : this.state.estadoDelRegistroEnEdicion[parametro] || this.state.estadoDelRegistro[parametro];

        switch (parametro) {
            case "tecnico":
                const tecnico = this.state.listaDeTecnicos.find(tecnico => valorParametro.correo === tecnico.correo);
                if (valorParametro.correo) {
                    return (
                        <Row className={"mx-2 my-0 justify-content-left"}>
                            {tecnico ? <img width={25} className="rounded mr-4" style={{ borderRadius: "50%" }} src={tecnico ? tecnico.fotoDePerfil ? tecnico.fotoDePerfil.url || defaultAvatar : defaultAvatar : defaultAvatar} alt="" /> : null}
                            {valorParametro.nombre || 'Sin asignar'}
                        </Row>
                    );
                } else {
                    return null
                }
            case "estado":
                return parametrosDeEstados[valorParametro].estado;
            case "fechaDeActualizacion":
                valorParametro = valorParametro ? valorParametro.toDate() : new Date();
                return new Date(valorParametro).toISOString().split('T')[0]
            case "fechaRealDeInicio":
                valorParametro = valorParametro ? new Date(valorParametro.seconds*1000) : new Date();
                //valorParametro = valorParametro ? valorParametro.toDate() : new Date();
                return new Date(valorParametro).toISOString().split('T')[0]
            case "fechaEstimadaDeProgramacion":
                valorParametro = valorParametro ? valorParametro.toDate() : new Date();
                return new Date(valorParametro).toISOString().split('T')[0]
            case "fechaRealDeFinalizacion":
                valorParametro = valorParametro ? valorParametro.toDate() : new Date();
                return new Date(valorParametro).toISOString().split('T')[0]
            case "fechaRealDeProgramacion":
                valorParametro = valorParametro ? valorParametro.toDate() : new Date();
                return new Date(valorParametro).toISOString().split('T')[0]
            default:
                return valorParametro;
        }
    }

    setDatosModificados = (parametro, valor) => {
        let estadoDelRegistroEnEdicion = this.state.estadoDelRegistroEnEdicion;

        estadoDelRegistroEnEdicion[parametro] = valor;

        if (parametro) {
            estadoDelRegistroEnEdicion["fechaDeActualizacion"] = firebaseApp.firestore.Timestamp.fromDate(new Date());
        }

        this.setState({
            estadoDelRegistroEnEdicion: estadoDelRegistroEnEdicion,
            estadoModificado: true
        })
    }

    toggleEstados = () => this.setState({ dropdownEstadosOpen: !this.state.dropdownEstadosOpen });

    toggleTecnicos = () => this.setState({ dropdownTecnicosOpen: !this.state.dropdownTecnicosOpen });

    traerTecnicos = () => {
        return new Promise((resolve) => {
            let tecnicos = []
            firestoreDB.collection('usuarios')
                .where('dominioAlQuePertenece', '==', this.props.usuarioActivo.dominioCorporativo)
                .where('rol', '==', 'tecnico')
                .get()
                .then(snapShots => {
                    snapShots.forEach(snapShot => {
                        tecnicos.push({
                            'nombre': snapShot.data().nombre,
                            'correo': snapShot.data().correo,
                            'fotoDePerfil': snapShot.data().fotoDePerfil
                        });
                        if (this.isMonted) this.setState({ listaDeTecnicos: tecnicos });
                    })
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    resolve()
                })
        })
    }
}


const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

export default connect(mapStateToProps)(InformacionGeneralODTMadre);

