import React from 'react';
import '../assets/LaunchView.scss';
import giaCore from '../assets/utils/images/logos/giaCore-logo2.png';
import insercor from '../assets/utils/images/logos/Insercor-logo-x.png';
import { Animated } from "react-animated-css";

class Home extends React.Component {

    constructor() {
        super();

        this.state = {
        };
    }

    render() {
            return (
                <div className="container">
                    <div className="bg"></div>
                    <div className="bg bg2"></div>
                    <div className="bg bg3"></div>

                    <div className="logos">
                        <Animated animationIn="fadeInDown" animationInDuration={3000}>
                            <div className="giacore-logo img-fluid ">
                                <img src={giaCore} alt="GIACORE LOGO" />
                            </div>
                        </Animated>
                        <div className="insercor-logo img-fluid ">
                            <img src={insercor} alt="" />
                        </div>

                    </div>
                </div>
            );
        }
       
}

export default Home;
