import React from 'react';
import { connect } from 'react-redux';

import { Breadcrumb, Row, Col, Label, BreadcrumbItem, Input } from 'reactstrap';
import { Select, MenuItem } from '@material-ui/core';

import {
    glosarioDeInputsIDAnomaliaILI,
    glosarioDeInputsUbGeograficaILI,
    glosarioDeInputsDatosExcavacionILI,
    glosarioDeInputsGeoILI,
    glosarioDeInputsObservaciones
} from './scripts/glosarioVariablesDeInput';

import Preloader from '../../../utilidades/componentes/plantillaBase/Preloader';

import { firestoreDB } from '../../../firebase';
//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

import { obtenerValidacionDeVariable } from './scripts/paraValidadVariablesFormulario';

const estiloLabel = 'mb-0 mt-2'

class VisualizadorFormularioDeILI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            //Identificación Anomalia
            distanciaRegistroDucto: '',
            longitudDeDuctoDecimal: '',
            latitudDeDuctoDecimal: '',
            lTubo: '',
            distanciaAlRegistroDelDucto: '',
            longitudAnomaliaDelDucto: '',
            anchoAnomaliaDelDucto: '',
            distanciaJuntaAnteriorDelDucto: '',
            distanciaJuntaPosteriorDelDucto: '',
            posicionHorariaDelDucto: '',
            anhoDeLaCorridaDelDucto: '',
            porcentajeDeMetalOReduccionDelIdDelDucto: '',
            tipoDeAnomaliaDelDucto: '',
            programadaOAdicionalDelDucto: '',
            clasificacionDelDucto: '',
            enParedDelDucto: '',

            //Ubicacion Geografica ILI
            departamentoUbicacionGeografica: '',
            municipioUbicacionGeografica: '',
            sectorOVeredaUbicacionGeografica: '',

            //Datos de la excavacion
            distanciaRegistroExcavacionInicial: '',
            distanciaRegistroExcavacionFinal: '',
            longitudDeExcavacion: '',
            anchoDeExcavacion: '',
            profundidadDeExcavacion: '',
            tipoDeTerreno: '',

            //Georeferenciacion de la Anomalia
            pkCampo: '',
            longitudDeAnomaliaDecimal: '',
            latitudDeAnomaliaDecimal: '',
            ubicacionTuberiaAnomalia: '',
            longitudTubo: '',
            precisionDelGPS: '',
            espesorZ1_12: '',
            espesorZ1_3: '',
            espesorZ1_6: '',
            espesorZ1_9: '',
            espesorZ2_12: '',
            espesorZ2_3: '',
            espesorZ2_6: '',
            espesorZ2_9: '',
            espesorZ3_12: '',
            espesorZ3_3: '',
            espesorZ3_6: '',
            espesorZ3_9: '',
            espesorZ4_12: '',
            espesorZ4_3: '',
            espesorZ4_6: '',
            espesorZ4_9: '',
            espesorZ5_12: '',
            espesorZ5_3: '',
            espesorZ5_6: '',
            espesorZ5_9: '',
            espesorMinimo: '',
            espesorMaximo: '',
            espesorPromedio: '',

            //
            observacionAdicional: this.props.seccion === 'observaciones' ? this.props.informacionOT.observaciones['observacionAdicional'] ? this.props.informacionOT.observaciones['observacionAdicional'] : '' : '',

            //Mensajes Error
            mensajeDistanciaRegistroDucto: false,
            mensajeLongitudDeDuctoDecimal: false,
            mensajeLatitudDeDuctoDecimal: false,
            mensajeLTubo: false,
            mensajeDistanciaAlRegistroDelDucto: false,
            mensajeLongitudAnomaliaDelDucto: false,
            mensajeAnchoAnomaliaDelDucto: false,
            mensajeDistanciaJuntaAnteriorDelDucto: false,
            mensajeDistanciaJuntaPosteriorDelDucto: false,
            mensajePosicionHorariaDelDucto: false,
            mensajeAnhoDeLaCorridaDelDucto: false,
            mensajeporcentajeDeMetalOReduccionDelIdDelDucto: false,
            mensajeTipoDeAnomaliaDelDucto: false,
            mensajeProgramadaOAdicionalDelDucto: false,
            mensajeClasificacionDelDucto: false,
            mensajeEnParedDelDucto: false,

            mensajeDepartamentoUbicacionGeografica: false,
            mensajeMunicipioUbicacionGeografica: false,
            mensajeSectorOVeredaUbicacionGeografica: false,

            mensajeDistanciaRegistroExcavacionInicial: false,
            mensajeDistanciaRegistroExcavacionFinal: false,
            mensajeLongitudDeExcavacion: false,
            mensajeAnchoDeExcavacion: false,
            mensajeProfundidadDeExcavacion: false,
            mensajeTipoDeTerreno: false,

            mensajePkCampo: false,
            mensajeLongitudDeAnomaliaDecimal: false,
            mensajeLatitudDeAnomaliaDecimal: false,
            mensajeUbicacionTuberiaAnomalia: false,
            mensajeLongitudTubo: false,
            mensajePrecisionDelGPS: false,
            mensajeEspesorZ1_12: false,
            mensajeEspesorZ1_3: false,
            mensajeEspesorZ1_6: false,
            mensajeEspesorZ1_9: false,
            mensajeEspesorZ2_12: false,
            mensajeEspesorZ2_3: false,
            mensajeEspesorZ2_6: false,
            mensajeEspesorZ2_9: false,
            mensajeEspesorZ3_12: false,
            mensajeEspesorZ3_3: false,
            mensajeEspesorZ3_6: false,
            mensajeEspesorZ3_9: false,
            mensajeEspesorZ4_12: false,
            mensajeEspesorZ4_3: false,
            mensajeEspesorZ4_6: false,
            mensajeEspesorZ4_9: false,
            mensajeEspesorZ5_12: false,
            mensajeEspesorZ5_3: false,
            mensajeEspesorZ5_6: false,
            mensajeEspesorZ5_9: false,
            mensajeEspesorMinimo: false,
            mensajeEspesorMaximo: false,
            mensajeEspesorPromedio: false,

            mensajeObservacionAdicional: false, 


            cargando: false,

            error: "",
            estadoValidacion: true
        }
    }

    componentDidMount(){
        const registro = this.props.informacionOT.registro;
        this.setState({...registro})
    }

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.submitDelFormulario}>
                    <Row>
                        {this.generarVariosInputs(this.obtenerGlosario())}
                    </Row>
                    <br />
                    <Input type='submit' value="Guardar" style={{ textAlign: 'center', color: '#ffffff', width: '25%', float: 'right' }} className={'bg-royal pusheable-button mb-3'} />
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    generarVariosInputs = (glosarioDeInputs) => {
        let listaDeInputs = [];
        for (const input of Object.keys(glosarioDeInputs)) {
            listaDeInputs.push(this.generarInputSegunGlosario(input, glosarioDeInputs))
        }
        return listaDeInputs;
    }

    generarInputSegunGlosario = (input, glosarioDeInputs) => {
        for (let key in glosarioDeInputs) {
            if (key === input) {
                switch (glosarioDeInputs[key].type) {
                    case 'text':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label for={key} className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    type={glosarioDeInputs[key].type}
                                    name={key}
                                    // defaultValue={''}
                                    value={this.state[key]}
                                    onChange={(e) => { this.cambiosEnInput(e, glosarioDeInputs[key]) }}
                                    required
                                />
                                <small style={{ color: 'red' }} >{(this.state[glosarioDeInputs[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                            </Col>
                        );
                    case 'email':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label for={key} className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    type={glosarioDeInputs[key].type}
                                    name={key}
                                    // defaultValue={this.state[key]}
                                    value={this.state[key]}
                                    onChange={(e) => { this.cambiosEnInput(e, glosarioDeInputs[key]) }}
                                    required
                                />
                                <small style={{ color: 'red' }} >{(this.state[glosarioDeInputs[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                            </Col>
                        );
                    case 'fecha':
                        return (
                            <Col key={`date ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    key={key}
                                    type="date"
                                    name={key}
                                    // defaultValue={this.state[key].toISOString().split("T")[0]}
                                    value={this.state[key].toISOString().split("T")[0]}
                                    onChange={(e) => this.cambiosEnInput(e, glosarioDeInputs[key], 'date')}
                                    style={{ width: '100%', boxShadow: 'none' }}
                                    required
                                />
                            </Col>
                        );
                    case 'select':
                        if(this.state[glosarioDeInputs[key].padre] !== ''){
                            return (
                                <Col key={`select ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                    <Label className={estiloLabel} >{glosarioDeInputs[key].contenido}</Label>
                                    <Select
                                        name={key}
                                        defaultValue={this.state[key]}
                                        value={this.state[key]}
                                        onChange={this.cambiosEnInput}
                                        style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                                        variant="outlined"
                                    >
                                        {this.obtenerListadoDeOpciones(key, glosarioDeInputs[key])}
                                    </Select>
                                    <small>{this.state[glosarioDeInputs[key].mensaje]}</small>
                                </Col>
                            )
                        }else if(this.state[glosarioDeInputs[key].padre] === ''){
                            return (
                                <Col key={`select ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                    <Label className={estiloLabel} style={{color: '#c9c9c9'}}>{glosarioDeInputs[key].contenido}</Label>
                                    <Select
                                        name={key}
                                        defaultValue={this.state[key]}
                                        value={this.state[key]}
                                        onChange={this.cambiosEnInput}
                                        style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                                        variant="outlined"
                                        disabled
                                    >
                                        {this.obtenerListadoDeOpciones(key, glosarioDeInputs[key])}
                                    </Select>
                                    <small>{this.state[glosarioDeInputs[key].mensaje]}</small>
                                </Col>
                            )
                        }
                        break
                    case 'textarea':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                                <Label for={key} className={estiloLabel}>{glosarioDeInputs[key].contenido}</Label>
                                <Input
                                    rows={5}
                                    type={glosarioDeInputs[key].type}
                                    name={key}
                                    defaultValue={this.state[key]}
                                    value={this.state[key]}
                                    onChange={(e) => {
                                        this.cambiosEnInput(e, glosarioDeInputs[key])
                                    }}
                                    required
                                />
                                <small style={{ color: 'red' }} >{(this.state[glosarioDeInputs[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                            </Col>
                        )
                    case 'separador':
                        return (
                            <Col key={`input ${key}`} md={glosarioDeInputs[key]['col-md']} lg={glosarioDeInputs[key]['col-lg']}>
                            </Col>
                        )
                    default:
                        return (
                            <Col key={`titulo ${key}`} lg='12' className='pt-4'>
                                <Breadcrumb>
                                    <BreadcrumbItem className='BreadcrumbItem-color'>{glosarioDeInputs[key].name}</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        )
                }
            }
        }
    }

    obtenerGlosario = () => { 
        switch (this.props.seccion) {
            case 'id_anomalia_ili':
                return glosarioDeInputsIDAnomaliaILI       
            case 'ub_geografica_ili':
                return glosarioDeInputsUbGeograficaILI   
            case 'datos_excavacion':
                return glosarioDeInputsDatosExcavacionILI
            case 'ub_anomalia_campo':
                return glosarioDeInputsGeoILI
            case 'observaciones':
                return glosarioDeInputsObservaciones
            default:
                return glosarioDeInputsIDAnomaliaILI
        }
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        if(nodo.obtenerOpciones !== undefined){
            let opciones = nodo.obtenerOpciones(this.state)
            opciones.forEach((opcion, i)=> {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }else{
            nodo.opciones.forEach((opcion, i) =>{
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }
    }

    cargarOrdenDeTrabajo = (registroActualizado) => {
        if (this.props.seccion === 'observaciones') {
            firestoreDB.doc(`ordenesDeTrabajo/${this.props.ordenDeTrabajo}`).update({ observaciones: registroActualizado })
                .then(result => {
                    this.setState({ cargando: false }, () => {
                        this.avisoDeGuardadoSatisfactorio('¡Información cargada satisfactoriamente!');
                        this.props.cerrarModal();
                    })
                })
                .catch(e => {
                    console.log('errores al realizar guardado: ', e);
                    this.avisoDeGuardadoFallido('La información no pudo ser almacenada!')
                })
        }
        else {
            firestoreDB.doc(`ordenesDeTrabajo/${this.props.ordenDeTrabajo}/registrosDeInspeccion/ili`).update({ registro: registroActualizado })
                .then(result => {
                    this.setState({ cargando: false }, () => {
                        this.avisoDeGuardadoSatisfactorio('¡Información cargada satisfactoriamente!');
                        this.props.cerrarModal();
                    })
                })
                .catch(e => {
                    console.log('errores al realizar guardado: ', e);
                    this.avisoDeGuardadoFallido('La información no pudo ser almacenada!')
                })
        }
    }

    estructuraParaGuardado = () => {
        let informacionRegistro = this.props.seccion === 'observaciones' ? this.props.informacionOT : this.props.informacionOT.registro
        let estructura = { }
        switch (this.props.seccion) {
            case 'id_anomalia_ili':
                estructura = {
                    'distanciaRegistroDucto': this.state.distanciaRegistroDucto,
                    'longitudDeDuctoDecimal': this.state.longitudDeDuctoDecimal,
                    'latitudDeDuctoDecimal': this.state.latitudDeDuctoDecimal,
                    'lTubo': this.state.lTubo,
                    'distanciaAlRegistroDelDucto': this.state.distanciaAlRegistroDelDucto,
                    'longitudAnomaliaDelDucto': this.state.longitudAnomaliaDelDucto,
                    'anchoAnomaliaDelDucto': this.state.anchoAnomaliaDelDucto,
                    'distanciaJuntaAnteriorDelDucto': this.state.distanciaJuntaAnteriorDelDucto,
                    'distanciaJuntaPosteriorDelDucto': this.state.distanciaJuntaPosteriorDelDucto,
                    'posicionHorariaDelDucto': this.state.posicionHorariaDelDucto,
                    'anhoDeLaCorridaDelDucto': this.state.anhoDeLaCorridaDelDucto,
                    'porcentajeDeMetalOReduccionDelIdDelDucto': this.state.porcentajeDeMetalOReduccionDelIdDelDucto,
                    'tipoDeAnomaliaDelDucto': this.state.tipoDeAnomaliaDelDucto,
                    'programadaOAdicionalDelDucto': this.state.programadaOAdicionalDelDucto,
                    'clasificacionDelDucto': this.state.clasificacionDelDucto,
                    'enParedDelDucto': this.state.enParedDelDucto,
                }
                break;
            case 'ub_geografica_ili':
                estructura = {
                    'departamentoUbicacionGeografica': this.state.departamentoUbicacionGeografica,
                    'municipioUbicacionGeografica': this.state.municipioUbicacionGeografica,
                    'sectorOVeredaUbicacionGeografica': this.state.sectorOVeredaUbicacionGeografica,
                }
                break;
            case 'datos_excavacion':
                estructura = {
                    'distanciaRegistroExcavacionInicial': this.state.distanciaRegistroExcavacionInicial,
                    'distanciaRegistroExcavacionFinal': this.state.distanciaRegistroExcavacionFinal,
                    'longitudDeExcavacion': this.state.longitudDeExcavacion,
                    'anchoDeExcavacion': this.state.anchoDeExcavacion,
                    'profundidadDeExcavacion': this.state.profundidadDeExcavacion,
                    'tipoDeTerreno': this.state.tipoDeTerreno,
                }
                break;
            case 'ub_anomalia_campo':
                estructura = {
                    'pkCampo': this.state.pkCampo,
                    'longitudDeAnomaliaDecimal': this.state.longitudDeAnomaliaDecimal,
                    'latitudDeAnomaliaDecimal': this.state.latitudDeAnomaliaDecimal,
                    'ubicacionTuberiaAnomalia': this.state.ubicacionTuberiaAnomalia,
                    'longitudTubo': this.state.longitudTubo,
                    'precisionDelGPS': this.state.precisionDelGPS,
                    'espesorZ1_12': this.state.espesorZ1_12,
                    'espesorZ1_3': this.state.espesorZ1_3,
                    'espesorZ1_6': this.state.espesorZ1_6,
                    'espesorZ1_9': this.state.espesorZ1_9,
                    'espesorZ2_12': this.state.espesorZ2_12,
                    'espesorZ2_3': this.state.espesorZ2_3,
                    'espesorZ2_6': this.state.espesorZ2_6,
                    'espesorZ2_9': this.state.espesorZ2_9,
                    'espesorZ3_12': this.state.espesorZ3_12,
                    'espesorZ3_3': this.state.espesorZ3_3,
                    'espesorZ3_6': this.state.espesorZ3_6,
                    'espesorZ3_9': this.state.espesorZ3_9,
                    'espesorZ4_12': this.state.espesorZ4_12,
                    'espesorZ4_3': this.state.espesorZ4_3,
                    'espesorZ4_6': this.state.espesorZ4_6,
                    'espesorZ4_9': this.state.espesorZ4_9,
                    'espesorZ5_12': this.state.espesorZ5_12,
                    'espesorZ5_3': this.state.espesorZ5_3,
                    'espesorZ5_6': this.state.espesorZ5_6,
                    'espesorZ5_9': this.state.espesorZ5_9,
                    'espesorMinimo': this.state.espesorMinimo,
                    'espesorMaximo': this.state.espesorMaximo,
                    'espesorPromedio': this.state.espesorPromedio,
                }
                break;
            case 'observaciones':
                estructura = {
                    'observacionAdicional': this.state.observacionAdicional,
                    'observacionEND': this.props.informacionOT.observaciones['observacionEND'] ? this.props.informacionOT.observaciones['observacionEND'] : null,
                    'observacionGeneral': this.props.informacionOT.observaciones['observacionGeneral'] ? this.props.informacionOT.observaciones['observacionGeneral'] : null,
                    'observacionReparacion': this.props.informacionOT.observaciones['observacionReparacion'] ? this.props.informacionOT.observaciones['observacionReparacion'] : null,
                }
                break;
            default:
                break;
        }
        if (this.props.seccion === 'observaciones') {
            return estructura
        }
        else {
            Object.keys(estructura).forEach(element => {
                if (estructura[element] !== ' ') {
                    informacionRegistro[element] = estructura[element]
                }
            });
            return informacionRegistro;
        }
    }

    submitDelFormulario = (evento) => {
        evento.preventDefault();
        this.setState({
            cargando: true
        })
        this.cargarOrdenDeTrabajo(this.estructuraParaGuardado())
    }

    cambiosEnInput = (event, key, type) => {
        console.log(event.target.value)
        let value =  undefined
        if (type === 'date') {
            let myDate = new Date(((new Date(event.target.value).getTime()/1000)+18000)*1000)
            value = myDate
        }
        else {
            value = event.target.value
        }
        const name = event.target.name;
        let funcionValidacion = obtenerValidacionDeVariable(name, this.props.seccion)
        let validacion = funcionValidacion['validation']
        if (validacion != null && name !== 'estado') {
            const respuesta = validacion(key, value);
            if (!respuesta) {
                this.setState({
                    error: "El valor no es válido!",
                    estadoValidacion: false,
                    [key.mensaje]: true
                })
            } else {
                this.setState({
                    [name]: value,
                    error: "",
                    estadoValidacion: true,
                    [key.mensaje]: false
                })
            }
        } else if (validacion !== null && name === 'estado') {
            this.setState({
                [name]: value,
            })
        }
    }

    transformarVariable = (palabra) => {
        switch(palabra){
            case 'En sitio':
                return 'EN_SITIO';
            default:
                return palabra.toUpperCase()
        }
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorFormularioDeILI);
