import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card } from 'reactstrap';

//Componentes auxiliares:
import ProgressBarDeCarga from './ProgressBarDeCarga';
import CustomModal from '../../../../../../utilidades/componentes/plantillaBase/CustomModal';

//Scripts auxiliares para Firebase Storage:
import { cargarArchivo } from '../../../../../../utilidades/scripts/managerDeFirebaseStorage';
import has from 'lodash/has';

class ConsolaDeCargueDeFicheros extends React.Component {

    constructor() {
        super();

        this.state = {
            ficheros: [],
            logDeProceso: [],
            estadoDelProceso: "inicio" //inicio; archivosListosParaCarga; cargandoArchivos; procesoFinalizado
        }
    }

    render() {
        return (
            <CustomModal size="lg" elModalEstaAbierto={this.props.elModalEstaAbierto}
                toggleDelModal={this.props.toggleDelModal} titulo={<span>Herramienta para <b>cargar y asociar ficheros</b></span>}>

                <Row style={{ padding: 15 }}>
                    <Col xs="12" md="12" lg="4" xl="4">
                        <Card className="widget-chart border mb-3">
                            <div className="widget-heading">
                                <p>
                                    <b>{has(this.props,"nodo.informacionGeneral.nombre.valor") ? this.props.nodo.informacionGeneral.nombre.valor :  this.props.nodo.nombre}</b>
                                </p>
                            </div>

                            <div className="widget-subheading" style={{ opacity: 1 }}
                                dangerouslySetInnerHTML={{ __html: this.obtenerSubtituloSegunEstado() }} />

                            <hr />
                            {this.obtenerBotonDeInicioDeCarga()}
                            {this.obtenerBotonDeSeleccionDeFicheros()}
                        </Card>
                    </Col>

                    <Col xs="12" md="12" lg="8" xl="8">
                        <Card className="widget-chart border mb-3">
                            {this.obtenerBarrasDeProgreso()}
                        </Card>
                    </Col>
                </Row>

                <input type="file" style={{ display: "none" }}
                    ref="miInputDeFicheros" multiple onChange={(e) => {
                        let filesAsList = Object.keys(e.target.files).map((key, i) => {
                            return e.target.files[key];
                        });

                        this.setState({
                            ficheros: filesAsList.map((fichero, i) => {
                                return {
                                    archivo: fichero, status: "READY_FOR_LOADING",
                                    porcentajeCompletado: 0, indice: i, deberiaCargar: true
                                };
                            }),
                            estadoDelProceso: "archivosListosParaCarga"
                        });
                    }}
                />

            </CustomModal>
        );
    }

    //Métodos para renderizado:
    obtenerBarrasDeProgreso = () => {
        if(this.state.ficheros.length>0){
            return this.state.ficheros.map((fichero, i) => {
                return (
                    <ProgressBarDeCarga fichero={fichero}
                        key={"progressDeCargaDeFichero." + i + "." + (new Date()).getTime()} />
                );
            });
        }
        else {
            return (
                <p style={{ fontSize: 12, opacity: 0.75, textAlign: "right", marginBottom:0 }}>
                    Aún no has seleccionado ningún archivo
                </p>
            );
        }
    }

    obtenerBotonDeInicioDeCarga = () => {
        if (this.state.estadoDelProceso === "archivosListosParaCarga") {
            return (
                <Button className="btn-wide pusheable-button"
                    style={{ marginBottom: 20 }} color="success"
                    onClick={() => {
                        this.cargarFicherosDelState();
                    }}>
                    Iniciar proceso
                </Button>
            );
        }
    }

    obtenerBotonDeSeleccionDeFicheros = () => {
        if (this.state.estadoDelProceso === "inicio" || this.state.estadoDelProceso === "archivosListosParaCarga" || this.state.estadoDelProceso === "procesoFinalizado") {
            return (
                <Button className="btn-wide pusheable-button"
                    style={{ marginBottom: 20 }}
                    onClick={() => { this.refs.miInputDeFicheros.click(); }}
                    outline={this.state.estadoDelProceso === "archivosListosParaCarga"}
                    color={(this.state.estadoDelProceso === "archivosListosParaCarga") ? "info" : "primary"}>
                    {(this.state.estadoDelProceso === "archivosListosParaCarga") ? "Seleccionar otros ficheros" : "Seleccionar ficheros"}
                </Button>
            );
        }
    }

    obtenerSubtituloSegunEstado = () => {
        switch (this.state.estadoDelProceso) {
            case "inicio":
                return "<br/>Selecciona los ficheros que quieres cargar.";
            case "archivosListosParaCarga":
                let nombresDeFicheros = "";
                this.state.ficheros.forEach((fichero, i) => {
                    if (fichero.deberiaCargar) {
                        nombresDeFicheros += "</br>" + fichero.archivo.name + "</br>";
                    }
                });
                return nombresDeFicheros;
            case "cargandoArchivos":
                return "<br/>Cargando ficheros...";
            case "procesoFinalizado":
                return "<br/>El proceso terminó.<br/><br/> Selecciona más ficheros o continúa navegando.";
            default:
                return "";
        }
    }

    //Métodos operativos:
    cargarFicherosDelState = () => {
        this.state.ficheros.forEach((fichero, i) => {
            if (fichero.deberiaCargar) {
                cargarArchivo(fichero.archivo, this.props.usuarioActivo.companyId, this.props.coleccionTarget, this.props.nodo.id)
                    .subscribe(
                        (value) => {
                            let nuevaListaDeFicheros = this.state.ficheros;
                            nuevaListaDeFicheros[fichero.indice].status = value.status;
                            nuevaListaDeFicheros[fichero.indice].porcentajeCompletado = value.progress;

                            if (value.progress === 100) { nuevaListaDeFicheros[fichero.indice].status = "DONE"; }

                            this.setState({ ficheros: nuevaListaDeFicheros, estadoDelProceso: "cargandoArchivos" });
                        },
                        (error) => {
                            let nuevaListaDeFicheros = this.state.ficheros;
                            nuevaListaDeFicheros[fichero.indice].status = "ERROR";

                            this.setState({ ficheros: nuevaListaDeFicheros });
                        },
                        () => {
                            let nuevaListaDeFicheros = this.state.ficheros;
                            nuevaListaDeFicheros[fichero.indice].deberiaCargar = false;

                            this.setState({ ficheros: nuevaListaDeFicheros });

                            setTimeout(() => {
                                let todosCompletados = true;
                                this.state.ficheros.forEach((fichero, i) => {
                                    if (fichero.porcentajeCompletado !== 100) {
                                        todosCompletados = false;
                                    }
                                });

                                if (todosCompletados) {
                                    this.setState({ estadoDelProceso: "procesoFinalizado" });
                                    this.props.actualizarLista();
                                }
                            }, 50);
                        });
            }
        });
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ConsolaDeCargueDeFicheros);