const crearTablaLeyendaGraficaBarrasUtScanA = (tipo) => {
    const items = [];
    switch(tipo){
        case 'Vida remanente':
            items.push(
                {
                'alias': { label: 'Alias', valor: 'A', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'VidaRemanente menor a 5 años', minWidth: 45},
                },
                {
                'alias': { label: 'Alias', valor: 'B', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'VidaRemanente entre 5 y 20 años', minWidth: 45},
                },
                {
                'alias': { label: 'Alias', valor: 'C', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'VidaRemanente mayor a 20 años', minWidth: 45},
                },
            );
            return items
        
        case 'Porcentaje de pérdida':
            items.push(
                {
                'alias': { label: 'Alias', valor: 'A', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'Porcentaje', minWidth: 45},
                },
                {
                'alias': { label: 'Alias', valor: 'B', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'Porcentaje', minWidth: 45},
                },
                {
                'alias': { label: 'Alias', valor: 'C', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'Porcentaje', minWidth: 45},
                }
            )
                
            
            return items
        case 'MAWP':
            items.push(
                {
                'alias': { label: 'Alias', valor: 'A', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'MAWP', minWidth: 45},
                },
                {
                'alias': { label: 'Alias', valor: 'B', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'MAWP', minWidth: 45},
                },
                {
                'alias': { label: 'Alias', valor: 'C', minWidth: 10,},
                'valor': { label: 'Valor', valor: 'MAWP', minWidth: 45},
                },

            )
                
            return items
        default:
            items.push(
                {
                    'alias': { label: 'Alias', valor: '-', minWidth: 10,},
                    'valor': { label: 'Valor', valor: 'No hay datos para mostrar', minWidth: 45},
                },
                )
            return items;
        
    }
}

export default crearTablaLeyendaGraficaBarrasUtScanA