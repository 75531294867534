import { useState } from 'react'

/** Base de datos */
import { firestoreDB } from '../../../../../firebase'

export const useFetchCmls = () => {

    const [datosCmls, setDatosCmls] = useState([])
    const [cargandoCmls, setCargandoCmls] = useState(false)

    const consultarCmlsAsociados = ( objetoCondiciones ) => {

        setCargandoCmls( true )

        try{
            let promiseConsulta = firestoreDB.collection(`cml`)
            for ( const prop in objetoCondiciones ) {
                promiseConsulta = promiseConsulta.where( prop, '==', objetoCondiciones[prop] );
            }
            promiseConsulta.get()
            .then( snapShots => {
                let dataDelCml = [];
                snapShots.forEach( snapshot => {
                    dataDelCml.push( {...snapshot.data()} )
                })
                setDatosCmls( dataDelCml );
                setCargandoCmls( false )
            })
            .catch( error => {console.log( error ); setCargandoCmls( false )})               
                
        }
        catch(error){ console.log( error ) }
    }

    return { datosCmls, cargandoCmls, consultarCmlsAsociados }
}
