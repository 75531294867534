const TIPODEVALVULA = {
    "Convencional balanceada":"#E3E32E",
    "Convencional resortada":"#B7E32E",
    "Pilotada":"#2EE38E",
    "Presión y vacío":"#B692E7",
    "Disco de rotura":"#FB8989",
}

const SISTEMA = {
    "sistema elevado":"#FB8989",
    "sistema de tierra":"#FED466",
    "sistema de agua":"#CDE749",
    "Recibo de crudo":"#49E7AB",
    "noDefinido":"#929590",
}

const DEFAULT = [
    "#FB8989",
    "#FED466",
    "#CDE749",
    "#49E7AB",
    "#929590",
    "#FA8072",
    "#4682B4",
    "#9ACD32",
    "#F5DEB3",
    "#BDB76B",
    "#DA70D6",
    "#F4A460",
    "#A52A2A",
    "#778899",
    "#00BFFF",
    "#9966CC",
    "#DB7093",
    "#F08080",
    "#FFD700",
    "#3CB371",
    "#DAA520",
]

module.exports = {
    TIPODEVALVULA,
    SISTEMA,
    DEFAULT,
}