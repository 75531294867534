import React from 'react';

export default ({ tituloDeLaBarra, valorCualitativo, valorCualitativoMaximo, colorAsociado, containerStyle,calculado,nivelDeRiesgo, ...props }) => (
    <div style={{ paddingLeft: 7.5, paddingRight: 7.5, ...containerStyle }}>
        <div className="progress-bar-sm progress" style={{ width: '100%', backgroundColor: '#dadada' }}>
            <div className="progress-bar" title={tituloDeLaBarra}
                style={{
                    borderRadius: '2px', transition: 'all 0.2s ease-out',
                    width: (valorCualitativo / valorCualitativoMaximo * 100) + "%",
                    backgroundColor: colorAsociado
                }}
            />
        </div>
    </div>
)