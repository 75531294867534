import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Row, Col, Label, BreadcrumbItem, Input, Card } from 'reactstrap';
import { Select, MenuItem } from '@material-ui/core';
import { firebaseFunctions } from '../../../firebase';

//Componentes Generales
import Preloader from '../../../utilidades/componentes/plantillaBase/Preloader';

//Scripts Generales
import { glosarioDeInputsOrdenDeTrabajo1, glosarioDeInputsOrdenDeTrabajo2 } from './scripts/glosarioVariablesDeInput';
import { obtenerValidacionDeVariable } from './scripts/paraValidadVariablesFormulario';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//const cargarOrdenDeTrabajo = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajo');
const crearOrdenDeTrabajoMadre = firebaseFunctions.httpsCallable('crearOrdenDeTrabajoMadre')

class VisualizadorFormularioDeInspeccion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando: false,

            //Validación formulario
            estadoDeValidacion: {},
            error: "",
        }
    }

    componentDidMount(){
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        this.setState({estadoDeValidacion: estadoDeValidacionInicial});
    }

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.submitDelFormulario}>
                    <Card className="col col-12 my-2 px-0">
                        <Col key={'titulo_seccion1'} lg='12' className='p-0'>
                            <Breadcrumb>
                                <BreadcrumbItem className='BreadcrumbItem-color'>ESTADO DE LA INSPECCIÓN</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                        <Row className="mb-3 px-3">
                            {this.obtenerPrimeraSeccionDeFormulario()}
                        </Row>
                    </Card>
                    <Card className="col col-12 mt-3 px-0">
                        <Col key={'titulo_seccion2'} lg='12' className='p-0'>
                            <Breadcrumb>
                                <BreadcrumbItem className='BreadcrumbItem-color'>IDENTIFICACIÓN DEL DUCTO</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                        <Row className="mb-3 px-3">
                            {this.obtenerSegundaSeccionDeFormulario()}
                        </Row>
                    </Card>
                    <br />
                    <Input type='submit' value="Crear" style={{ textAlign: 'center', color: '#ffffff', width: '25%', float: 'right', opacity: this.obtenerEstadoDeFormulario()? 1 : 0.8 }} className={'bg-royal pusheable-button mb-3'} disabled={!this.obtenerEstadoDeFormulario()}/>
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    //Funciones Render
    obtenerInputSegunTipo = (input, seccion) => {
        switch (input.type) {
            case 'text':
                return (
                    <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                        <Input
                            type={input.type}
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                            onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'email':
                return (
                    <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                        <Input
                            type={input.type}
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                            onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'fecha':
                return (
                    <Col key={`date ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label className={"mb-0 mt-2"}>{input.contenido}</Label>
                        <Input
                            key={input.name}
                            type="date"
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor? this.state.estadoDeValidacion[input.name].valor.toISOString().split("T")[0] : new Date().toISOString().split("T")[0] : new Date().toISOString().split("T")[0]}
                            onChange={(e) => this.cambiosEnInput(e, input, seccion, 'date')}
                            style={{ width: '100%', boxShadow: 'none' }}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'select':
                const estadoDelPadre = this.state.estadoDeValidacion[input.padre];
                return(
                    <Col key={`select ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label className={"mb-0 mt-2"} style={{color: estadoDelPadre? estadoDelPadre.valor === ''? '#c9c9c9' : 'black' : 'black'}}>{input.contenido}</Label>
                        <Select
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                            onChange={this.cambiosEnInput}
                            style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                            variant="outlined"
                        >
                            {this.obtenerListadoDeOpciones(input.name, input)}
                        </Select>
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'textarea':
                return(
                <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                    <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                    <Input
                        type={input.type}
                        name={input.name}
                        value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                        onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                        required
                    />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                </Col>
                )
            default:
                return (
                    <Col key={`titulo ${input.name}`} lg='12' className='pt-4'>
                        <Breadcrumb>
                            <BreadcrumbItem className='BreadcrumbItem-color'>{input.name}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                )
        }
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        const estadoDeValidacion =  this.state.estadoDeValidacion;

        const listadoDeValores = {};
        Object.keys(estadoDeValidacion).forEach(key => {
            listadoDeValores[key] = estadoDeValidacion[key].valor
        });

        if(nodo.obtenerOpciones !== undefined){
            let opciones = nodo.obtenerOpciones(listadoDeValores)
            opciones.forEach((opcion, i)=> {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }else{
            nodo.opciones.forEach((opcion, i) =>{
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }
    }

    obtenerPrimeraSeccionDeFormulario = () => {
        return Object.keys(glosarioDeInputsOrdenDeTrabajo1).map(key =>this.obtenerInputSegunTipo(glosarioDeInputsOrdenDeTrabajo1[key], 'orden_trabajo_1'))
    }

    obtenerSegundaSeccionDeFormulario = () => {
        return Object.keys(glosarioDeInputsOrdenDeTrabajo2).map(key =>this.obtenerInputSegunTipo(glosarioDeInputsOrdenDeTrabajo2[key], 'orden_trabajo_2'))
    }

    //Funciones Lógicas
    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    cambiosEnInput = (event, key, seccion, type) => {
        let value =  undefined
        if (type === 'date') {
            let myDate = new Date(((new Date(event.target.value).getTime()/1000)+18000)*1000)
            value = myDate
        }
        else {
            value = event.target.value
        }
        const name = event.target.name;
        const funcionValidacion = obtenerValidacionDeVariable(name, seccion)
        const validacion = funcionValidacion['validation'];

        const estadoDeValidacionActual = this.state.estadoDeValidacion;


        if (validacion && name !== 'estado') {
            const respuesta = validacion(key, value);
            if (!respuesta) {
                estadoDeValidacionActual[name].estado = false;
                estadoDeValidacionActual[name].valor = value;
                estadoDeValidacionActual[name].mensaje = true;
                this.setState({
                    error: "El valor no es válido!",
                    estadoDeValidacion: estadoDeValidacionActual,
                })
                return;
            } 
        } 
        estadoDeValidacionActual[name].estado = true;
        estadoDeValidacionActual[name].valor = value;
        estadoDeValidacionActual[name].mensaje = false;
        this.setState({
            error: "",
            estadoValidacion: estadoDeValidacionActual,
        })
    }

    estructuraParaGuardado = () =>{
        const estadoDeValidacion = this.state.estadoDeValidacion;

        return {
            "ordenDeTrabajo":{
                "descripcionODT": estadoDeValidacion.descripcionODT.valor,
                "numeroDeFormato": estadoDeValidacion.numeroDeFormato.valor,
                "lineaDeDucto": estadoDeValidacion.lDucto.valor,
                "distanciaDeInicio": estadoDeValidacion.distanciaDeInicio.valor,
                "distanciaDeFin": estadoDeValidacion.distanciaDeFin.valor,
                "dominioCorporativo": this.props.usuarioActivo.dominioCorporativo,
                "lngInicial": estadoDeValidacion.lngInicial.valor,
                "lngFinal": estadoDeValidacion.lngFinal.valor,
                "latInicial": estadoDeValidacion.latInicial.valor,
                "latFinal": estadoDeValidacion.latFinal.valor,
                "fechaEstimadaDeProgramacion": new Date(estadoDeValidacion.fechaEstimadaDeProgramacion.valor).toString(),
                "fechaDeActualizacion": new Date().toString(),
                "novedad":{
                    "zonaDeDucto": estadoDeValidacion.zona.valor,
                    "regionDeDucto": estadoDeValidacion.region.valor,
                    "departamentoOYMDeDucto": estadoDeValidacion.depto.valor,
                    "sistemaDeDucto": estadoDeValidacion.sistema.valor,
                    "lineaDeDucto": estadoDeValidacion.lDucto.valor,
                    "diametroDeDucto": estadoDeValidacion.diametro.valor,
                    "espesorNominalDeDucto": estadoDeValidacion.espesorNominal.valor,
                    "SMYSDeDucto": estadoDeValidacion.SMYS.valor,
                    "MAOPDeDucto": estadoDeValidacion.MAOP.valor,
                    "CEMOPDeDucto": estadoDeValidacion.CEMOP.valor
                },
                "responsable":{
                    "nombre": estadoDeValidacion.responsable.valor,
                    "correo": estadoDeValidacion.correo.valor
                }
                   
            }
        }
    }

    generarEstadoDeValidacioninicial = () =>{
        let estadoDeValidacionInicial = {};
        const listadoDeVariables = [...Object.keys(glosarioDeInputsOrdenDeTrabajo1), ...Object.keys(glosarioDeInputsOrdenDeTrabajo2)];
        
        listadoDeVariables.forEach(variable =>{
            estadoDeValidacionInicial[variable] = {}
            estadoDeValidacionInicial[variable]['estado'] = false;
            estadoDeValidacionInicial[variable]['mensaje'] = false;
            estadoDeValidacionInicial[variable]['valor'] = '';
            
            //Fecha ya posee un valor inicial válido
            if(variable === 'fechaEstimadaDeProgramacion'){
                estadoDeValidacionInicial[variable]['estado'] = true;
                estadoDeValidacionInicial[variable]['valor'] = new Date();
            }
        })
       
        
        return estadoDeValidacionInicial;
    }

    obtenerEstadoDeFormulario = () =>{
        const estadoDeValidacion = this.state.estadoDeValidacion;
        return Object.keys(estadoDeValidacion).every(key => estadoDeValidacion[key].estado);
    }

    submitDelFormulario = (evento) => {
        evento.preventDefault();
        this.setState({
            cargando: true
        })
        
        const nuevaOrdenDeTrabajo = this.estructuraParaGuardado();
        crearOrdenDeTrabajoMadre(nuevaOrdenDeTrabajo)
        .then(result =>{
            switch(result.data.status){
                case "ORDEN_MADRE_CREADA":
                    this.setState({ cargando: false },()=>{
                        this.avisoDeGuardadoSatisfactorio('¡La orden de trabajo se creó satisfactoriamente!');
                        setTimeout(()=>{ this.props.cerrarModal() }, 1000);
                    })
                break;
                case "ORDEN_MADRE_NO_CREADA":
                    this.setState({ cargando: false }, ()=>{
                        this.avisoDeGuardadoFallido('No se pudo crear la orden de trabajo');
                    })
                    
                break;
                case "ORDEN_EXISTENTE":
                    this.setState({ cargando: false }, ()=>{
                        this.avisoDeGuardadoFallido('La orden de trabajo ya existe');
                    })
                break;
                default:
                    console.log('ocurrio un error al crear la orden de trabajo');
                break;
            }
        })
        .catch(e =>{
            console.log('errores al realizar guardado: ', e);
        })
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorFormularioDeInspeccion);
