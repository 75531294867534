import React from 'react'

import { Label, Input, Col, Row } from 'reactstrap';
import { MdEdit } from "react-icons/md";
import { MdAdd } from "react-icons/md";

//Componentes Auxiliares
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

const UbicacionGeoGrafica = (props) => {
    return (
        <React.Fragment>
            <br />
            <br />
            <div className={"mb-0 p-3 d-flex " + (props.estado ? 'border rounded-top' : 'border rounded')} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                <Label style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black'}}>UBICACIÓN GEOGRÁFICA</Label>                    
                <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                    {props.authRol ? <BotonCallToAction key={'btn_ubicacion_ili'}
                        usuarioActivo={props.usuarioActivo}
                        color={props.estado ? 'primary' : 'success'}
                        icono={props.estado ? MdEdit : MdAdd}
                        titulo={props.estado ? 'Editar Datos' : 'Agregar Datos'}
                        onClick={props.toogleModal}
                    /> : null}
                </div>
            </div>
            {obtenerRenderDeDatos(props)}
        </React.Fragment>
    )
}

const obtenerRenderDeDatos = (props) => {
    if(props.estado){
        return(
            <div className="p-3 shadow-none border rounded-bottom">
                <Row>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="departamento" className='mb-0 mt-2'>Departamento:</Label>
                        <Input type="text" name="departamento" id="departamento" placeholder="Departamento" defaultValue={props.keysDelRegistro.departamentoUbicacionGeografica} disabled />
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="municipio" className='mb-0 mt-2'>Municipio:</Label>
                        <Input type="text" name="municipio" id="municipio" placeholder="Municipio" defaultValue={props.keysDelRegistro.municipioUbicacionGeografica} disabled />
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="sectorOVereda" className='mb-0 mt-2'>Sector o vereda:</Label>
                        <Input type="text" name="sectorOVereda" id="sectorOVereda" placeholder="Sector o vereda" defaultValue={props.keysDelRegistro.sectorOVeredaUbicacionGeografica} disabled />
                    </Col>
                </Row>
            </div>
        );
    }else{
        return(<></>);
    }
}

export default UbicacionGeoGrafica