import React from 'react';
import { Col, Input, FormGroup, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { firestoreDB } from '../../../../../../firebase';

//Componentes auxiliares:
import CustomRightModal from '../../../../../../utilidades/componentes/plantillaBase/CustomRightModal';
import MensajeDeChat from './MensajeDeChat';

class ModalDeChat extends React.Component {


    constructor() {
        super();

        this.isMonted = false;

        this.state = {
            scroll: false,
            mensajes: [],
            mensajeActual: null,
        }
    }

    componentDidMount() {
        this.isMonted = true;
        this.obtenerMensajes();
    }

    componentWillUnmount(){
        this.isMonted = false;
    }

    componentDidUpdate() {
        if (!this.state.scroll) {
            setTimeout(this.scrollHaciaAbajo, 300);
        }

        if (!this.props.elModalEstaAbierto && this.state.scroll) {
            this.setState({ scroll: false })
        }
    }

    render() {
        return (
            <CustomRightModal
                elModalEstaAbierto={this.props.elModalEstaAbierto}
                size="md"
                titulo={<span><b>Chat</b></span>}
                toggleDelModal={this.props.toggleDelModal}
                botonesParaElFooter={
                    <div className="w-100">
                        <FormGroup className="mb-0" row>
                            <Col sm={12}>
                                <Input
                                    bsSize="md"
                                    type="text"
                                    placeholder="Escribe un mensaje..."
                                    onChange={(event) => this.setState({ mensajeActual: event.target.value })}
                                    value={this.state.mensajeActual ? this.state.mensajeActual : ''}
                                    onKeyDown={(event) => { if (event.keyCode === 13) this.enviarMensaje() }}
                                />
                            </Col>
                        </FormGroup>
                    </div>
                }
                footerButton={
                    <Button color="success" onClick={() => this.enviarMensaje()}>Enviar</Button>
                }
            >
                <div className="chat-wrapper" >
                    {this.mostrarMensajes()}
                    <div ref={'contenedor'} />
                </div>
            </CustomRightModal>
        );
    }

    mostrarMensajes() {
        let mensajes = [];
        if (this.props.usuario !== null) {
            this.state.mensajes.forEach(mensaje => {
                const key = mensaje.timestamp.seconds + mensaje.timestamp.nanoseconds / 1000000;
                mensajes.push(
                    (
                        <MensajeDeChat
                            key={key}
                            mensaje={mensaje.mensaje}
                            timestamp={mensaje.timestamp.seconds * 1000}
                            esDelUsuarioLogeado={mensaje.correoDelRemitente === this.props.usuarioActivo.email}
                            nombre={mensaje.nombreDelRemitente}
                        />
                    )
                );
            });
        }
        return mensajes;
    }

    scrollHaciaAbajo = () => {
        if (this.refs.contenedor) {
            this.refs.contenedor.scrollIntoView({ behavior: 'smooth' })
            if (!this.state.scroll) {
                this.setState({
                    scroll: true
                })
            }
        }
    }

    obtenerMensajes() {
        firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo).collection('mensajes').orderBy('timestamp').limitToLast(30)
            // firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo).collection('mensajes').orderBy('timestamp')
            .onSnapshot(snapshots => {
                let mensajes = this.state.mensajes;
                snapshots.docChanges().forEach(change => {
                    if (change.type === 'added') {
                        mensajes.push(change.doc.data());
                    }
                });
                if(this.isMonted){
                    this.setState({
                        mensajes: mensajes
                    });
                }
                
            });
    }

    enviarMensaje = () => {
        const texto = this.state.mensajeActual;
        if (texto !== null && texto !== '') {
            const time = new Date(Date.now());
            const mensaje = {
                mensaje: texto,
                nombreDelRemitente: this.props.usuarioActivo.displayName,
                timestamp: time,
                correoDelRemitente: this.props.usuarioActivo.email
            }

            this.setState({
                mensajeActual: null
            })

            firestoreDB.collection('ordenesDeTrabajo').doc(this.props.idOrdenDeTrabajo).collection('mensajes').add(mensaje)
                .then(response => {
                    this.scrollHaciaAbajo();
                })
                .catch(error => {
                    console.log('Error al enviar mensaje', error);
                });
        }
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ModalDeChat);