import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { DropdownToggle, DropdownMenu, UncontrolledButtonDropdown, Label, DropdownItem } from 'reactstrap';

//Iconos
import { faAngleDown, faIdBadge, faUsers, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Imports auxiliares de estilo
import rolesDeUsuarios from '../../../vistas/aplicacion/administrador/scripts/parametrizacionRoles';
let defaultAvatar = '/assets/img/logos/giaCore-logo.png';

class PanelDeUsuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

    }

    render() {
        return (
            <Fragment>
                <div className="widget-content-left">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="link" className="p-0 d-flex align-items-center" style={{ textDecoration: 'none' }}>
                            <img width={42} height={42} className="rounded-circle" src={this.props.usuarioActivo.fotoDePerfil? this.props.usuarioActivo.fotoDePerfil.url : defaultAvatar} alt="" />
                            <div className="widget-content-left  ml-3 header-user-info"  >
                                <div className="widget-heading">
                                    {this.props.usuarioActivo.displayName}
                                </div>
                                <div className="widget-subheading">
                                    {this.props.usuarioActivo.email.split("@")[1]}
                                </div>
                            </div>
                            <FontAwesomeIcon className="ml-3 opacity-8" icon={faAngleDown} />
                        </DropdownToggle>
                        <DropdownMenu right className="rm-pointers dropdown-menu-lg py-0">
                            <div className="dropdown-menu-header mb-0">
                                <div className="dropdown-menu-header-inner bg-light py-2" style={{height: 80}}>
                                    <div className="menu-header-content text-left">
                                        <div className="widget-content py-1">
                                            <div className="widget-content-wrapper text-light">
                                                <div className="widget-content-left mr-4 rounded-circle">
                                                    <img width={54} height={54} className="rounded-circle" src={this.props.usuarioActivo.fotoDePerfil? this.props.usuarioActivo.fotoDePerfil.url : defaultAvatar}
                                                        alt="" />
                                                </div>
                                                <div className="widget-content-left">
                                                    <div className="widget-heading text-dark">
                                                        {this.props.usuarioActivo.displayName}
                                                    </div>
                                                    <div className="widget-subheading  text-dark opacity-10 mt-1" style={{ fontSize: 12 }}>
                                                        {rolesDeUsuarios[this.props.usuarioActivo.rol] ? rolesDeUsuarios[this.props.usuarioActivo.rol].titulo : ''}
                                                    </div>
                                                    <div className="widget-subheading  text-dark opacity-8 mt-0" style={{ fontSize: 11 }}>
                                                        {this.props.usuarioActivo.email.split("@")[1]}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to={''} style={{textDecoration: 'none'}}>
                                <DropdownItem>
                                    <Label className="mb-0 py-1" style={{cursor: 'pointer' }}>
                                        <FontAwesomeIcon className="mr-3" icon={faIdBadge} size='lg' style={{width: 25}} />
                                        Mi perfil
                                    </Label>
                                </DropdownItem>
                            </Link>
                            {/* {this.obtenerRenderGestionDeUsuarios()} */}
                            {this.obtenerRenderGestionDeEmpresas()}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </Fragment>
        )
    }

    obtenerRenderGestionDeUsuarios = () =>{
        if(this.props.usuarioActivo.rol === 'admin'){
            return (
                <>
                    <DropdownItem className="my-0" divider/>
                    <Link 
                        to={'/app/administrador/usuarios'}
                        onContextMenu={() => this.guardarEnlaceEnLocalStorage('/app/administrador/usuarios')}
                        onClick={() => this.guardarEnlaceEnLocalStorage('/app/administrador/usuarios')} 
                        style={{textDecoration: 'none'}}>
                        <DropdownItem>
                            <Label className="mb-0 py-1" style={{cursor: 'pointer' }}>
                                <FontAwesomeIcon className="mr-3" icon={faUsers} size='lg'  style={{width: 25}} />
                                Administrar usuarios
                            </Label>
                        </DropdownItem>
                    </Link>
                </>
            );
        }else{
            return(<></>);
        }
    }

    obtenerRenderGestionDeEmpresas = () =>{
        if(this.props.usuarioActivo.rol === 'admin'){
            return (
                <>
                    <DropdownItem className="my-0" divider/>
                    <Link 
                        to={'/app/administrador/empresas'}
                        onContextMenu={() => this.guardarEnlaceEnLocalStorage('/app/administrador/empresas')}
                        onClick={() => this.guardarEnlaceEnLocalStorage('/app/administrador/empresas')} 
                        style={{textDecoration: 'none'}}>
                        <DropdownItem>
                            <Label className="mb-0 py-1" style={{cursor: 'pointer' }}>
                                <FontAwesomeIcon className="mr-3" icon={faServer} size='lg'  style={{width: 25}} />
                                Administrar Empresas
                            </Label>
                        </DropdownItem>
                    </Link>
                </>
            );
        }
        else return(<> </>);
    }

    guardarEnlaceEnLocalStorage = (path) => {
        // props.history.push(path);
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

export default connect(mapStateToProps)(withRouter(PanelDeUsuario));