import React from 'react';
import { connect } from 'react-redux';
//iconos:
import { GrUserAdmin } from 'react-icons/gr';
import { FaPlus } from 'react-icons/fa';

//componentes:
import CustomSection from '../../../utilidades/componentes/plantillaBase/CustomSection';
import ListaEmpresas from './componentes/empresas/ListaEmpresas';
import ModalDetalleUsuario from './componentes/ModalDetalleUsuario';
import BotonParaSection from '../../../utilidades/componentes/plantillaBase/BotonParaSection';
import CustomModal from '../../../utilidades/componentes/plantillaBase/CustomModal';
import ContenidoModalAgregarEmpresa from './componentes/empresas/ContenidoModalAgregarEmpresa';


//reacstrap:
import { Col, Row } from 'reactstrap';
//llamada a base de datos:
import { firestoreDB } from '../../../firebase';
require('firebase/firestore');

class VisualizadorAdministradorDeEmpresas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listaDeEmpresas: [],
            parametrizacionEmpresa: {},
            usuarioSeleccionado: {},
            datosUsuariosPermitidos: undefined,
            elModalAgregarEstaAbierto: false,
            elModalEditarEstaAbierto: false,
            elModalDetalleEstaAbierto: false,
            nombre: '',
            correo: '',
            dominio: '',
            rol: '',
        };

        this.unsubscribe = undefined;
    }

    componentDidMount() {
        this.obtenerListaEmpresas()
    }

    componentWillUnmount(){
    }
    
    render() {
        if (this.state.listaDeEmpresas !== undefined) {
            return (
                <CustomSection
                    titulo='Administrador de Empresas'
                    icono={GrUserAdmin}
                    botonesAdicionales={[
                        <BotonParaSection key="BotonAgregarUsuario" icono={<FaPlus />} onClick={this.toggleDelModalAgregar} title={'Agregar'} />
                    ]}
                >
                    <CustomModal
                        elModalEstaAbierto={this.state.elModalAgregarEstaAbierto}
                        toggleDelModal={this.toggleDelModalAgregar}
                        titulo={
                            <span className='h5 font-weight-bold'>Agregar Empresa</span>
                        }
                        size="lg"
                        hideFooter={true}
                    >
                        <ContenidoModalAgregarEmpresa
                            agregarNuevaEmpresa={(idNuevo, empresa)=>{this.agregarNuevaEmpresa(idNuevo, empresa)}}
                            toggleDelModal={this.toggleDelModalAgregar}
                        />
                    </CustomModal>
                    <ModalDetalleUsuario
                          elModalEstaAbierto={this.state.elModalDetalleEstaAbierto}
                          toggleDelModal={this.toggleDelModalDetalle}
                          usuario={this.state.usuarioSeleccionado}
                    />
                    <Row className='mx-2'>
                        <Col xs='12' xl='12'>
                            <ListaEmpresas
                                usuarios={this.state.listaDeEmpresas}
                            />
                        </Col>
                    </Row> 
                </CustomSection>
            )
        } else {
            return (<React.Fragment></React.Fragment>)
        }
    }

    obtenerListaEmpresas = async () => {
        let dataEmpresas = []
        let snapShot
        if (this.props.usuarioActivo.rol==='admin' && this.props.usuarioActivo.dominioCorporativo==='insercor.com') snapShot = await firestoreDB.collection('empresas').get()
        else if (this.props.usuarioActivo.rol==='admin') snapShot = await firestoreDB.collection('empresas').where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo).get()
        snapShot.forEach(doc => {
            let tempData = doc.data()
            dataEmpresas.push({
                id:doc.id,
                dominioCorporativo: tempData.dominioCorporativo,
                nombreEmpresa: tempData.nombreEmpresa,
                usuariosActivos: tempData.usuariosActivos,
                usuariosPermitidos: tempData.usuariosPermitidos,
            })         
        });
        this.setState({
            listaDeEmpresas:dataEmpresas
        })
    }

    toggleDelModalAgregar = () => {
        this.setState({elModalAgregarEstaAbierto: !this.state.elModalAgregarEstaAbierto})
    }

    toggleDelModalEditar = () => {
        this.setState({elModalEditarEstaAbierto: !this.state.elModalEditarEstaAbierto})
    }

    toggleDelModalDetalle = () => {
        this.setState({elModalDetalleEstaAbierto: !this.state.elModalDetalleEstaAbierto})
    }
    
    agregarNuevaEmpresa = (idNuevo, empresa) => {
        let tempData = this.state.listaDeEmpresas
        tempData.push({
            id:idNuevo,
            dominioCorporativo: empresa.dominioCorporativo,
            nombreEmpresa: empresa.nombre,
            usuariosActivos: 0,
            usuariosPermitidos: empresa.usuariosPermitidos,
        })
        this.setState({ listaDeEmpresas: tempData })
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorAdministradorDeEmpresas);