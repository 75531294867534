import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { FaThList } from 'react-icons/fa';

/** Componentes creados */
import CardConEncabezado from '../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import CustomSection from '../../../../utilidades/componentes/plantillaBase/CustomSection.jsx';
import Preloader from '../../../../utilidades/componentes/plantillaBase/Preloader';
import TablaFetchPaginada from './componentes/tablas/TablaFetchPaginada';
import { SpanDeCampoApi510 } from './componentes/botones/SpanDeCampoApi510';
import ConsolaDeCargue from '../../../../utilidades/componentes/resumenDeModulo/ConsolaDeCargue';

/** Custom Hooks */
import { useFetchTablaActivos } from './hooks/useFetchTablaActivos';

/** Scripts */
import { crearBotonEncabezado } from './scripts/crearBotonEncabezado';
import { armarDataParaTablaActivos } from './scripts/armarDataParaTablaActivos';

/** Estilos */
import './visualizadorApi510.scss';


const VisualizadorApi510 = (props) => {

    /** Hooks */
    const [campoTarget, setCampoTarget] = useState(undefined)
    const [consolaDeCargueAbierta, setconsolaDeCargueAbierta] = useState(false)

    /** Custom Hooks */
    const { datos, cargando, primerCargando, last, consultarActivos } = useFetchTablaActivos();

    useEffect(() => {
        campoTarget && consultarActivos(props.usuarioActivo.dominioCorporativo, campoTarget, 1, undefined, 5, true)
    }, [campoTarget])

    //Toggles de los modales:
    const toggleDeLaConsolaDeCargue = () => {
        setconsolaDeCargueAbierta(!consolaDeCargueAbierta)
    }

    return (
        <React.Fragment>
            <CustomSection
                titulo={'Módulo API 510'}
                subtitulo={'Integridad'}
                URLIconoPrincipal="/assets/img/logos/giaCore-logo.png"
                botonesAdicionales={
                    [
                        crearBotonEncabezado(
                            'Cargar datos api510',
                            'Cargar datos',
                            'FaCloudUploadAlt',
                            toggleDeLaConsolaDeCargue
                        )
                    ]
                }
            >
                <SpanDeCampoApi510
                    coleccion={ 'campos' }
                    campoTarget={ campoTarget }
                    cargando = { cargando }
                    usuarioActivo={ props.usuarioActivo }
                    nodosDescargados={ props.dataDe510EnRedux.nodosDescargados }
                    actualizarCampoTarget={ setCampoTarget }
                    datos={ datos }
                />
                <ConsolaDeCargue
                    toggleDelModal={toggleDeLaConsolaDeCargue}
                    elModalEstaAbierto={consolaDeCargueAbierta}
                    callbackFunction={() => consultarActivos(props.usuarioActivo.dominioCorporativo, campoTarget, 1, undefined, 5, true)} 
                    isIntegridad={true}
                    modulo={'api510'}
                />
                {
                    (!primerCargando ? 
                        <CardConEncabezado
                            icono={ FaThList }
                            titulo={ 'Lista de activos' }
                        >
                            <TablaFetchPaginada
                                keyDeTabla={'new Date()'}
                                listaDeDatos={armarDataParaTablaActivos(datos)}
                                cantidadDeDatos={datos.length}
                                callback={consultarActivos}
                                cargando={cargando} 
                                mostrarPaginacionProp={true}
                                posicionUltimoDato={last}
                                limiteDeDescargas={5}
                            />
                        </CardConEncabezado>
                    : <Preloader elPreloaderEstaAbierto={primerCargando}/>)
                }
            </CustomSection>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    dataDe510EnRedux: state.reducerParaData["/app/analisis/integridad/api510"],
});


export default connect(mapStateToProps)(VisualizadorApi510)



