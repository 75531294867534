import React from 'react'

import { Label, Input, Col, Row, Button } from 'reactstrap';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import GaleriaCamisas from './GaleriaCamisas';

import { MdEdit } from "react-icons/md";

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = { display: "flex", width: "100%", justifyContent: 'center', cursor: 'pointer' }

const DetalleDeCamisas = (props) => { 
    return (
        <React.Fragment>
            <HeaderDetalleDeCamisas {...props.anomaliaCamisa} />
            <br/>
            <TablaComentariosCamisas listadoDeComentarios={formatoEnsayos(props.anomaliaCamisa)}></TablaComentariosCamisas>
            {props.existeSubanomalia ? <TablaDeAnomaliasCamisas
                authRol={props.authRol}
                idDeAnomalia={props.anomaliaCamisa.idDeAnomalia}
                tipoDeAnomalia={props.tipoDeAnomalia}
                listadoDeSubAnomalias={props.anomaliaCamisa.listadoDeSubAnomalias}
                toggleModalEdicionAnomalias={() => props.toggleModalEdicionAnomalias()}
                setTipoAnomaliaEdicion={(e) => props.setTipoAnomaliaEdicion(e)}
                setAnomaliaEdicion={(e) => props.setAnomaliaEdicion(e)}
                setSubAnomaliaEdicion={(e) => { props.setSubAnomaliaEdicion(e) }}
            ></TablaDeAnomaliasCamisas> : null}
            <GaleriaDeCamisas
                idDeAnomalia={props.anomaliaCamisa.idDeAnomalia}
                idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                {...props.anomaliaCamisa} />
        </React.Fragment>
    )
}

const formatoEnsayos = (anomaliaDeCamisas) => {
    let ensayos = []
    ensayos.push({
        "id":"VT",
        "comentario":anomaliaDeCamisas.detalle.condicionVT,
        "recomendacion":anomaliaDeCamisas.detalle.recomendacionVT,
    })
    ensayos.push({
        "id":"MT",
            "comentario":anomaliaDeCamisas.detalle.condicionMT,
            "recomendacion":anomaliaDeCamisas.detalle.recomendacionMT,
    })
    ensayos.push({
        "id":"PAUT",
            "comentario":anomaliaDeCamisas.detalle.condicionPAUT,
            "recomendacion":anomaliaDeCamisas.detalle.recomendacionPAUT,
    })
    ensayos.push({
        "id":"Espesores",
            "comentario":anomaliaDeCamisas.detalle.condicionEspesores,
            "recomendacion":anomaliaDeCamisas.detalle.recomendacionEspesores,
    })
    ensayos.push({
        "id":"Otros Ensayos",
            "comentario":anomaliaDeCamisas.detalle.condicionOtros,
            "recomendacion":anomaliaDeCamisas.detalle.recomendacionOtros,
    })
    return { "ensayos": ensayos }
 }

const TablaComentariosCamisas = (props) => {

    const { listadoDeComentarios } = props;
    return (
        <CustomTable style={{ flex: 1, marginTop:'20px'}} listaDeDatos={listadoDeComentarios.ensayos} columnas={[
            {
                Header: () => (<p style={{ ...estiloParaHeader, }}>Ensayos</p>),
                columns:
                    [
                        {
                            Header: () => (<p style={estiloParaHeader}>Ensayo </p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.id || "-"}</p>
                                </div>
                            ),
                            maxWidth: 300
                        },
                        {
                            //accesor: 'longitudAReparar',

                            Header: () => (<p style={{ ...estiloParaHeader }}>Comentario</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.comentario || "-"}</p>
                                </div>
                            ),
                        },
                        {
                            //accesor: 'distanciaRegistroInicialRecomendacion',

                            Header: () => (<p style={estiloParaHeader}>Recomendación</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.recomendacion || "-"}</p>
                                </div>
                            ),
                        },
                    ]
            },
        ]}>

        </CustomTable>
    )
}
const TablaDeAnomaliasCamisas = (props) => {
    const { listadoDeSubAnomalias } = props;
    return (
        <CustomTable style={{ flex: 1, marginTop:'20px'}} setSubAnomaliaEdicion={(e) => { props.setSubAnomaliaEdicion(e) }} subAnomalia={true} listaDeDatos={listadoDeSubAnomalias} columnas={[
            {
                Header: () => (<p style={{ ...estiloParaHeader, }}>Identificación de Anomalías</p>),
                columns:
                    [
                            {
                                Header: () => (<p style={estiloParaHeader}>Editar</p>),
                                Cell: ({ row }) => (
                                    <div style={{ ...estiloSegmentos }}>
                                        {props.authRol ? <Button
                                            color="link"
                                            onClick={() => {
                                                props.toggleModalEdicionAnomalias()
                                                props.setAnomaliaEdicion(props.idDeAnomalia)
                                                props.setTipoAnomaliaEdicion(`${props.tipoDeAnomalia}`)
                                            }}
                                        >
                                            <MdEdit
                                            />
                                        </Button> : <Button
                                            color="link"
                                            disabled
                                        >
                                            <MdEdit
                                            />
                                        </Button>}
                                    </div>
                                ),  
                                maxWidth: 60
                            },{
                            Header: () => (<p style={estiloParaHeader}>Item </p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.idDeSubAnomalia || "-"}</p>
                                </div>
                            ),
                        },
                        {
                            //accesor: 'longitudAReparar',

                            Header: () => (<p style={{ ...estiloParaHeader }}>Largo</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.detalle.largoAnomaliaCamisa || "-"}</p>
                                </div>
                            ),
                        },
                        {
                            //accesor: 'distanciaRegistroInicialRecomendacion',

                            Header: () => (<p style={estiloParaHeader}>Ancho</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.detalle.anchoAnomaliaCamisa || "-"}</p>
                                </div>
                            ),
                        },
                        {
                            //accesor: 'distanciaRegistroFinalRecomendacion',

                            Header: () => (<p style={estiloParaHeader}>Ubicación</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.detalle.ubicacionEnLaReparacion || "-"}</p>
                                </div>
                            ),
                        },
                        {
                            //accesor: 'distanciaJuntaAnteriorRecomendacion',

                            Header: () => (<p style={estiloParaHeader}>Concepto</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.detalle.conceptoAnomalia || "-"}</p>
                                </div>
                            ),
                        },
                        {
                            //accesor: 'distanciaJuntaAnteriorRecomendacion',

                            Header: () => (<p style={estiloParaHeader}>Ensayo</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos }}>
                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                    >{row._original.detalle.ensayoReparacion || "-"}</p>
                                </div>
                            ),
                        },

                    ]
            },
        ]}>

        </CustomTable>
    )

}
const HeaderDetalleDeCamisas = (props) => {
    const { tipoDeAnomalia, detalle} = props;
    const estructura =
    {
        "CAMISA":
            [
                {
                    id: "espesorCamisa",
                    label: "Espesor Camisa"
                },
                {
                    id: "espesorTuberia",
                    label: "Espesor de Tubería"
                },
                {
                    id: "longitudCamisa",
                    label: "Longitud de Camisa"
                }
            ],
        "CAMISA_SOBRE_ANILLOS":
            [
                {
                    id: "espesorTuberia",
                    label: "Espesor de Tubería"
                },
                {
                    id: "espesorAnillo1",
                    label: "Espesor Anillo 1"
                },
                {
                    id: "espesorAnillo2",
                    label: "Espesor Anillo 2"
                },
                {
                    id: "longitudEntreAnillos",
                    label: "Longitud Entre Anillos"
                }
            ],
        "PARCHE":
            [
                {
                    id: "espesorTuberia",
                    label: "Espesor de Tubería"
                },
                {
                    id: "espesorParche",
                    label: "Espesor de Parche"
                },
                {
                    id: "diametroParche",
                    label: "Diametro de Parche"
                }
            ]
    }
    const campos = 12 / (estructura[tipoDeAnomalia] ? estructura[tipoDeAnomalia].length : 1);
    return (
        <React.Fragment>
            <Row>
                {estructura[tipoDeAnomalia].map((item) => {
                    return (
                        <Col key={item.id + tipoDeAnomalia} md={campos < 3 ? "3" : campos.toString()} >
                            <Label for={item.id} className='mb-0 mt-2'>{item.label}</Label>
                            <Input type="text" name={item.id} disabled={true} placeholder="Null" 
                            defaultValue={
                                detalle ? 
                                    detalle[item.id] ?
                                        detalle[item.id]
                                    : ""
                                : ""
                            } 
                            />
                        </Col>
                    )
                })}
            </Row>
        </React.Fragment>
    )
}
const GaleriaDeCamisas = (props) => {
    const { tipoDeAnomalia, ...informacionGeneral } = props
    let datosAnomalias = informacionGeneral
    let fotosGaleria;
    if (datosAnomalias) {
        try {
            switch (tipoDeAnomalia) {
                case 'CAMISA':
                    fotosGaleria = {
                        primeraFotoAnomalia: datosAnomalias.detalle ? datosAnomalias.detalle.primeraImagenCamisas : undefined,
                        segundaFotoAnomalia: datosAnomalias.detalle ? datosAnomalias.detalle.segundaImagenCamisas : undefined,
                    }
                    break;
                case 'CAMISA_SOBRE_ANILLOS':
                    fotosGaleria = {
                        primeraFotoAnomalia: datosAnomalias.detalle ? datosAnomalias.detalle.primeraImagenCamisasSA : undefined,
                        segundaFotoAnomalia: datosAnomalias.detalle ? datosAnomalias.detalle.segundaImagenCamisasSA : undefined,
                    }
                    break;
                case 'PARCHE':
                    fotosGaleria = {
                        primeraFotoAnomalia: datosAnomalias.detalle ? datosAnomalias.detalle.primeraImagenParches : undefined,
                        segundaFotoAnomalia: datosAnomalias.detalle ? datosAnomalias.detalle.segundaImagenParches : undefined,
                    }
                    break;
            
                default:
                    break;
            }
            
        } catch (error) { console.log(error) }
    }
    return (
        <React.Fragment>
            <GaleriaCamisas
                idDeAnomalia={props.idDeAnomalia}
                idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                toggleModalEdicionAnomalias={() => props.toggleModalEdicionAnomalias()}
                setTipoAnomaliaEdicion={(e) => props.setTipoAnomaliaEdicion(e)}
                setAnomaliaEdicion={(e) => props.setAnomaliaEdicion(e)}
                keysDelRegistro={fotosGaleria}
                tipoDeAnomalia={tipoDeAnomalia}
                titulo={tipoDeAnomalia} />
        </React.Fragment>
    )
}
export default DetalleDeCamisas;