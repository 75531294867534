export const bgHorizontales = [
    "/assets/img/backgrounds/bgHorizontal-0.jpg",
    "/assets/img/backgrounds/bgHorizontal-1.jpg",
    "/assets/img/backgrounds/bgHorizontal-2.jpg",
    "/assets/img/backgrounds/bgHorizontal-3.jpg",
    "/assets/img/backgrounds/bgHorizontal-4.jpg",
    "/assets/img/backgrounds/bgHorizontal-5.jpg",
    "/assets/img/backgrounds/bgHorizontal-6.jpg",
    "/assets/img/backgrounds/bgHorizontal-7.jpg",
    "/assets/img/backgrounds/bgHorizontal-8.jpg",
    "/assets/img/backgrounds/bgHorizontal-9.jpg",
    "/assets/img/backgrounds/bgHorizontal-10.jpg",
]

export const bgVerticales = [

]

export const iconosDeActivos = {
    "línea": "/assets/img/iconos/iconoActivoPipe.jpg",
    "tanque": "/assets/img/iconos/iconoActivoTank.jpg",
    "vasija": "/assets/img/iconos/iconoActivoVessel.png"
}