import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado"

const armarDataParaTablaInformacionBase = ( nodo ) => {

    let informacionBase = []
    const dataInfoBase = nodo.informacionGeneral
    informacionBase.push(
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Contrato vigente'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.contratoVigente?.valor, '')
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'TAG'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.nombre?.valor, '')
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Diámetro'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.diametro?.valor)
            },
            unidades: {
                label: 'unidades', 
                valor: dataInfoBase?.diametro?.unidades ||'in'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Longitud total del activo'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.longitudTotalActivo?.valor, 3)
            },
            unidades: {
                label: 'unidades', 
                valor: 'm'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Fecha de construcción'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.fechaDeActivacion?.valor)
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Tiempo en servicio'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.edadTuberia?.valor, 3)
            },
            unidades: {
                label: 'unidades', 
                valor: 'Años'
            },
        },
        {
            nombre: {
                label: 'Nombre',
                valor: 'Material de construcción'
            },
            valor: {
                label: 'valor',
                valor: formatearvalorsegunestado(dataInfoBase?.materialDeConstruccion?.valor)
            },
            unidades: {
                label: 'unidades',
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: nodo.informacionGeneral.tipoDeActividad.nombre === 'Proceso'? 
                'Esfuerzo (S)' : 'Esfuerzo (Sy)'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.esfuerzo?.valor)
            },
            unidades: {
                label: 'unidades', 
                valor: 'psi'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor:  nodo?.variablesDeLaInspeccion?.mop?.nombre  + `(${nodo?.variablesDeLaInspeccion?.mop?.procesoCalculo})` || 'Presión de operación'
            },
            valor: {
                label: 'Valor',
                valor: formatearvalorsegunestado(nodo?.variablesDeLaInspeccion?.mop?.valor)
            },
            unidades: {
                label: 'unidades',
                valor: 'psi'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Presión de operación'
            },
            valor: {
                label: 'Valor',
                valor: formatearvalorsegunestado(dataInfoBase?.presionDeOperacion?.valor)
            },
            unidades: {
                label: 'unidades',
                valor: 'psi'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Temperatura de diseño'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.temperaturaDeDisenho?.valor)
            },
            unidades: {
                label: 'unidades', 
                valor: '°F'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Temperatura de operación'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.temperaturaDeOperacion?.valor)
            },
            unidades: {
                label: 'unidades', 
                valor: '°F'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Espesor requerido'
            },
            valor: {
                label: 'Valor', 
                valor: nodo?.variablesDeLaInspeccion?.tReq?.valor !== undefined ? parseFloat(nodo?.variablesDeLaInspeccion?.tReq?.valor).toFixed(3) : 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: 'in'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Espesor nominal'
            },
            valor: {
                label: 'Valor', 
                valor: nodo?.variablesDeLaInspeccion?.tNom?.valor !== undefined? parseFloat(nodo?.variablesDeLaInspeccion?.tNom?.valor).toFixed(3) : 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: 'in'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Rating'
            },
            valor: {
                label: 'Valor', 
                valor: formatearvalorsegunestado(dataInfoBase?.rating?.valor)
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
    )
    if (nodo.informacionGeneral.tipoDeActividad.nombre === 'Proceso') {
        informacionBase.push(
            {
                nombre: {
                    label: 'Nombre', 
                    valor: 'Coeficiente aceros ferríticos (Y)'
                },
                valor: {
                    label: 'Valor', 
                    valor: formatearvalorsegunestado(dataInfoBase?.coeficienteAcerosFerriticos?.valor, 3)
                },
                unidades: {
                    label: 'unidades', 
                    valor: '-'
                },
            },
            {
                nombre: {
                    label: 'Nombre', 
                    valor: 'Factor de calidad (E)'
                },
                valor: {
                    label: 'Valor', 
                    valor: formatearvalorsegunestado(dataInfoBase?.factorCalidad?.valor)
                },
                unidades: {
                    label: 'unidades', 
                    valor: '-'
                },
            },
            {
                nombre: {
                    label: 'Nombre', 
                    valor: 'Factor de la junta soldada (W)'
                },
                valor: {
                    label: 'Valor', 
                    valor: formatearvalorsegunestado(dataInfoBase?.factorJunta?.valor)
                },
                unidades: {
                    label: 'unidades', 
                    valor: '-'
                },
            },
        )
    } else {
        informacionBase.push(

            {
                nombre: {
                    label: 'Nombre', 
                    valor: 'Factor de diseño (F)'
                },
                valor: {
                    label: 'Valor', 
                    valor: '0.72'
                },
                unidades: {
                    label: 'unidades', 
                    valor: '-'
                },
            },
            {
                nombre: {
                    label: 'Nombre', 
                    valor: 'Factor de la junta soldada (E)'
                },
                valor: {
                    label: 'Valor', 
                    valor: '1'
                },
                unidades: {
                    label: 'unidades', 
                    valor: '-'
                },
            }
        )
    }
    return informacionBase
}

export default armarDataParaTablaInformacionBase;