export const confirmacionDeBorradoDeFichero = {
    title: 'Borrar archivo',
    html: '<span style="font-size:14px;">¿Estás seguro de que quieres eliminar el formato <b>@nombreDelFichero</b>?</span>',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm:false,
    focusCancel: true,
};

export const confirmacionDeBorradoDeActivo = {
    title: 'Borrar activo',
    html: `
        <span style="font-size:14px;">¿Estás seguro de que quieres eliminar el activo <b>@nombreDelFichero</b>?</span>
        <p style="font-size:14px;">Los segmentos y ficheros asociados también serán eliminados</p>
        `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm:false,
    focusCancel: true,}

export const confirmacionDeBorradoDeSistema = {
    title: 'Borrar sistema',
    html: `
        <span style="font-size:14px;">¿Estás seguro de que quieres eliminar <b>TODOS LOS ACTIVOS</b> del sistema <b>@nombreDelFichero</b>?</span>
        <p style="font-size:14px;">segmentos y ficheros asociados también serán eliminados</p>
        `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm:false,
    focusCancel: true,}

    export const confirmacionDeBorradoDeCampo = {
    title: 'Borrar campo',
    html: `
        <span style="font-size:14px;">¿Estás seguro de que quieres eliminar <b>TODOS LOS ACTIVOS</b> del campo <b>@nombreDelFichero</b>?</span>
        <p style="font-size:14px;">segmentos y ficheros asociados también serán eliminados</p>
        `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm:false,
    focusCancel: true,}
