import React from 'react'

import { Label, Input, Col, Row, BreadcrumbItem } from 'reactstrap';

let IdentificacionDelDucto = (props) => {

    return (
        <React.Fragment>
            <div className={"mb-0 p-3 d-flex border rounded-top"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                <BreadcrumbItem className='BreadcrumbItem-color align-items-center' >IDENTIFICACIÓN DEL DUCTO</BreadcrumbItem>
            </div>
            <div className="p-3 shadow-none border rounded-bottom">
                <Row>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="zona" className='mb-0 mt-2'>Zona:</Label>
                        <Input type="text" name="zona" id="zona" placeholder='Zona' defaultValue={props.keysDelRegistro.zonaDeDucto} disabled/>
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="region" className='mb-0 mt-2'>Región:</Label>
                        <Input type="text" name="region" id="region" placeholder="Region" defaultValue={props.keysDelRegistro.regionDeDucto} disabled/>
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="Depto" className='mb-0 mt-2'>Depto O&M:</Label>
                        <Input type="text" name="Depto" id="Depto" placeholder="Depto O&M" defaultValue={props.keysDelRegistro.departamentoOYMDeDucto} disabled/>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="sistema" className='mb-0 mt-2'>Sistema:</Label>
                        <Input type="text" name="sistema" id="sistema" placeholder="Sistema" defaultValue={props.keysDelRegistro.sistemaDeDucto} disabled/>
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="Depto" className='mb-0 mt-2'>Diámetro:</Label>
                        <Input type="text" name="diametro" id="diametro" placeholder="Diámetro" defaultValue={props.keysDelRegistro.diametroDeDucto} disabled/>
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="EspesorNominal" className='mb-0 mt-2'>Espesor nominal:</Label>
                        <Input type="text" name="EspesorNominal" id="EspesorNominal" placeholder="Espesor nominal" defaultValue={props.keysDelRegistro.espesorNominalDeDucto} disabled/>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="SMYS" className='mb-0 mt-2'>SMYS:</Label>
                        <Input type="text" name="SMYS" id="SMYS" placeholder="SMYS" defaultValue={props.keysDelRegistro.SMYSDeDucto} disabled/>
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="MAOP" className='mb-0 mt-2'>MAOP:</Label>
                        <Input type="text" name="MAOP" id="MAOP" placeholder="MAOP" defaultValue={props.keysDelRegistro.MAOPDeDucto} disabled/>
                    </Col>
                    <Col xs='12' md='4' lg='4'>
                        <Label for="CEMOP" className='mb-0 mt-2'>CEMOP:</Label>
                        <Input type="text" name="CEMOP" id="CEMOP" placeholder="CEMOP" defaultValue={props.keysDelRegistro.CEMOPDeDucto} disabled/>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}

export default IdentificacionDelDucto