import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

export const GraficaDeBarras = ({ datos }) => {
    
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
            <BarChart data={datos} barSize={30} barGap={4}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke="#66be33" />
                <YAxis yAxisId="right" orientation="right" stroke="#d22928"/>
                <Legend />
                <Tooltip cursor={{ stroke: '#333', strokeWidth: 1 }} />
                <Bar yAxisId="left" name="MAWP (psi)" dataKey="mawp" fill="#66be33" />
                <Bar yAxisId="right" name="Treq (in)" dataKey="tReq" fill="#d22928" />
                <br/>
            </BarChart>
        </ResponsiveContainer>
    )
}
