import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    contador: {
        width: "100%",
        // backgroundColor:"red"
    },
    contadorMedio: {
        width: "100%"
    },
    root: {
        width: '350px',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: '405px',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
        margin:0,
        width:'auto'
    },
    contadoresContainer :{
        justifyContent: "center", 
        position: "relative", 
        height:'500px',
        maxHeight: "500px",
        [theme.breakpoints.down("sm")]: {
            maxHeight: "unset",
            height: "auto",
        },
    },
    graficasContadores:{
        width: "100%", 
        maxWidth:480,
    },
    graficasBarrasContadores:{
        width: "100%", 
        maxWidth:920,
    },
    graficasContadoresDetalle:{
        width: "100%", 
        [theme.breakpoints.up("lg")]: {
            maxWidth:350,
            marginLeft:theme.spacing(2),
            marginRight:theme.spacing(2)
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth:450,
        },     
    },
}));