import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import SpanDeCampo from "../../../../../utilidades/componentes/resumenDeModulo/SpanDeCampo"

const useStyles = makeStyles((theme) => ({
    estiloParaResumen: {
        textAlign: "center"
    }
}))
function MensajeDeCampos({ campoTarget, campos, cargando, onChange, onChangePreAlert, conContador }) {
    const classes = useStyles()
    return (!campoTarget && cargando ?
        <Typography variant="subtitle2" className={classes.estiloParaResumen} children="..." /> :
        campos && !!campos.length ?
            <Typography variant="subtitle2" className={classes.estiloParaResumen}>
                {"Estoy viendo los "}<b>PRD</b>{" del campo "}
                <b>
                    <SpanDeCampo campoTarget={campoTarget} camposDescargadosLaPrimeraVez campos={campos.map((camp) => ({ value: camp, label: camp }))}
                        onChange={onChange}
                        onChangePreAlert={onChangePreAlert}
                    />
                </b>
                {conContador && ` (${cargando ? "..." : conContador})`}
            </Typography>
            : <Typography variant="subtitle2" className={classes.estiloParaResumen} children={<b children="¡¡SIN CAMPOS PARA MOSTRAR!!" />}></Typography>
    )
}

export default MensajeDeCampos
