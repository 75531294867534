export const armarDatosParaGraficaDeBarras = ( nodo ) => {

    const mawp = nodo?.variablesDeLaInspeccion?.maximaPresionTrabajoAdmisible
    const tReq = nodo?.variablesDeLaInspeccion?.tReq
    let datosGraficaDeBarras = [];

    datosGraficaDeBarras.push(
        {
            name: "cap1", 
            mawp: mawp?.mawpMinCap1 || 0,
            tReq: tReq?.tReqMinCap1 || 0 
        },
        {
            name: "cap2", 
            mawp: mawp?.mawpMinCap2 || 0,
            tReq: tReq?.tReqMinCap2 || 0
        },
        {
            name: "cuerpo", 
            mawp: mawp?.mawpMinBody || 0,
            tReq: tReq?.tReqMinBody || 0
        },
        {
            name: "boquilla", 
            mawp: mawp?.mawpMinNozzle || 0,
            tReq: tReq?.tReqMinNozzle || 0
        },
        {
            name: "Head", 
            mawp: mawp?.mawpMinHead || 0,
            tReq: tReq?.tReqMinHead || 0
        },
    )

    return datosGraficaDeBarras
}
