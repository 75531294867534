const geolib = require('geolib');

const armarCoordenadasParaMapaDeActivos = (todosLosSegmentos) => {
    try{
        if (todosLosSegmentos) {
            let coordenadas = [];
            todosLosSegmentos.forEach(item => {
            let coorInicio = item?.variablesDeLaInspeccion?.ubicacion?.inicio?.coordenada.toString().trim();
            let coorFinal = item?.variablesDeLaInspeccion?.ubicacion?.fin?.coordenada.toString().trim();
            coordenadas.push([
                {'lng': -geolib.toDecimal(coorInicio.split(',')[1]), 'lat': geolib.toDecimal(coorInicio.split(',')[0]) },
                {'lng': -geolib.toDecimal(coorFinal.split(',')[1]), 'lat': geolib.toDecimal(coorFinal.split(',')[0]) }
            ]);
            })
            return coordenadas;
        } else {
            return undefined
        }
    }
    catch(error){console.log(error)}
}

export default armarCoordenadasParaMapaDeActivos