import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

const DefaultLink = ({
  className,
  classNameActive,
  classNameHasActiveChild,
  active,
  hasActiveChild,
  to,
  externalLink,
  hasSubMenu,
  toggleSubMenu,
  activateMe,
  children,
}) => {
  if (hasSubMenu) {
    return (
      <a className={classnames(className, active && classNameActive, hasActiveChild && classNameHasActiveChild)} href={to}
        onClick={hasSubMenu ? toggleSubMenu : activateMe} target={externalLink ? '_blank' : undefined}>
        {children}
      </a>
    );
  }
  else {
    return (
      <NavLink className={className} activeClassName={classNameActive} to={to}
        onClick={hasSubMenu ? toggleSubMenu : () => {
          localStorage.setItem('ultimaRutaVisitada', JSON.stringify(to));
          activateMe();
        }}>
        {children}
      </NavLink>
    );
  }
};

DefaultLink.defaultProps = {
  externalLink: false,
  toggleSubMenu: null,
};

DefaultLink.propTypes = {
  className: PropTypes.string.isRequired,
  classNameActive: PropTypes.string.isRequired,
  classNameHasActiveChild: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  hasActiveChild: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  hasSubMenu: PropTypes.bool.isRequired,
  toggleSubMenu: PropTypes.func,
  activateMe: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default DefaultLink;