import listarValoresSegunRango from './listarValoresSegunRango';

const armarDataParaGraficoDeTortaUtScanC = (tipo, nodosCml) => {
    let datosParaGrafica = [];

    let porcentajeDePerdidaMenor = [];
    let porcentajeDePerdidaMedia = [];
    let porcentajeDePerdidaMayor = [];  

    nodosCml.forEach((data, i) => {
        try{
            let resVarC = data?.resultadosIntegridad?.PM?.valor;
            const dataResVarC = listarValoresSegunRango(resVarC, 1, 2, 2);

            porcentajeDePerdidaMenor.push(...dataResVarC['menor'])
            porcentajeDePerdidaMedia.push(...dataResVarC['medio'])
            porcentajeDePerdidaMayor.push(...dataResVarC['mayor'])
        }
        catch(e){console.log(e)} 
    })
    switch(tipo){
        case 'porcentaje de pérdida':
            datosParaGrafica.push(
                {color: "#66be33", etiqueta: "Porcentaje de pérdida menor a 10", valor: porcentajeDePerdidaMenor.length || 0 },
                {color: "#e7961e", etiqueta: "Porcentaje de pérdida entre 10 y 30 ", valor: porcentajeDePerdidaMedia.length || 0 },
                {color: "#d22928", etiqueta: "Porcentaje de pérdida mayor a 30 ", valor: porcentajeDePerdidaMayor.length || 0 },
                )
            return datosParaGrafica;
        default:
            datosParaGrafica.push(
                {color: "#333", etiqueta: "No hay datos para mostrar ", valor: 0 },
                )
            return datosParaGrafica;
    }

}

export default armarDataParaGraficoDeTortaUtScanC
