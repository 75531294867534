
import get from 'lodash/get';
import  xor from 'lodash/xor';
import  without from 'lodash/without';
import { SIN_VALORAR } from '../../../scripts/constantes';
import { KEYS } from './constantes';

export const resultados = (storePRD, campoActual) => {
    const infoCampoActual = get(storePRD, `data.analisis.${campoActual}`, {})
    const keysDeInfoFaltante = Object.keys(infoCampoActual)
    const keysDeInfoFaltanteWithout = without(keysDeInfoFaltante, SIN_VALORAR)
    return xor(keysDeInfoFaltanteWithout, KEYS.VALORACION).length === 0
}
export const inventario = (storePRD, campoActual) => {
    const infoCampoActual = get(storePRD, `data.analisis.${campoActual}`, {})
    const keysDeInfoFaltante = Object.keys(infoCampoActual)
    return xor(keysDeInfoFaltante, KEYS.INFOCAMPO).length === 0
}