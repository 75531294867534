import React from 'react';
import EditorDeVariables from '../../../../../../utilidades/componentes/visualizadores/EditorDeVariables';
import { obtenerVariableDeTablaFormateada } from '../scripts/managerDeVariablesDeTabla';

class CeldaActualizable extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            laCeldaSeEstaModificando: false,
            touchtime: Date,
        }
    }

    render() {
        if (!this.state.laCeldaSeEstaModificando) {
            return (
                <span
                    onDoubleClick={
                        () => {
                            this.setState({
                                laCeldaSeEstaModificando: true
                            })
                        }
                    }
                    onClick={() => {
                        this.dobleClickEnMovil()
                    }
                    }
                >
                    {(this.props.valor === null ) ? "-" : obtenerVariableDeTablaFormateada(this.props.variable, this.props.valor)}
                </span>
            )
        } else {
            return (
                <EditorDeVariables
                    tipoActivo={this.props.tipoActivo}
                    variable={this.props.variable}
                    validacion={this.props.funcionDeValidacion}
                    valor={this.props.valor}
                    onBlur={() => {
                        this.setState({
                            laCeldaSeEstaModificando: false
                        })
                    }}
                    actualizarCelda={this.props.actualizarCelda}
                    infoDeCelda={this.props.infoDeCelda}
                    valorMensaje={this.props.valorMensaje}
                    keyDelNodo={this.props.keyDelNodo}
                    vacioComoNulo={this.props.vacioComoNulo}
                />
            )
        }
    }

    dobleClickEnMovil = () => {
        if (this.state.touchtime === 0) {
            this.setState({
                touchtime: new Date().getTime()
            })
        } else {
            if (((new Date().getTime()) - this.state.touchtime) < 700) {
                this.setState({
                    touchtime: 0,
                    laCeldaSeEstaModificando: true
                })
            } else {
                this.setState({
                    touchtime: new Date().getTime()
                })
            }
        }
    }
}
export default CeldaActualizable;