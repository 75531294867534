import listarValoresSegunRango from './listarValoresSegunRango';

const armarDataParaGraficoDeBarrasUtScanC = (nodosCml) => {
    let datosParaGrafica = [];
    let porcentajeDePerdidaMenor = [];
    let porcentajeDePerdidaMedia = [];
    let porcentajeDePerdidaMayor = [];
      
    nodosCml.forEach((data) => {
        try{
            let resVarC = data?.resultadosIntegridad?.PM?.valor;
            const dataResVarC = listarValoresSegunRango(resVarC, 1, 2, 2);

            porcentajeDePerdidaMenor.push(...dataResVarC['menor']);
            porcentajeDePerdidaMedia.push(...dataResVarC['medio']);
            porcentajeDePerdidaMayor.push(...dataResVarC['mayor']);
        }
        catch(e){console.log(e)} 
    })
    datosParaGrafica.push(
        {color: "#1E96FC", name: "A", cantidad: porcentajeDePerdidaMenor.length || 0 },
        {color: "#f5cc00", name: "B", cantidad: porcentajeDePerdidaMedia.length || 0 },
        {color: "#6D15D0", name: "C", cantidad: porcentajeDePerdidaMayor.length || 0 },
        )
    return datosParaGrafica;

}

export default armarDataParaGraficoDeBarrasUtScanC