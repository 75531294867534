import React from 'react';
import { Card, ListGroup, ListGroupItem, CardHeader } from 'reactstrap';

import { faBuilding, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DetalleDeDominioCorporativo = (props) => {
    return (
        <Card className="main-card mb-3 shadow-sm border border-light">
            <CardHeader style={{fontSize: 18}}>{props.nombreEmpresa}</CardHeader>
            <ListGroup className="todo-list-wrapper" flush>
                <ListGroupItem className='py-1'>
                    {obtenerRenderDeDetalle('Dominio Corporativo', props.dominioCorporativo, faBuilding, '#8E44AD')}
                    {obtenerRenderDeDetalle('Usuarios activos', props.usuariosActivos+'/'+props.usuariosPermitidos, faUsers, '#D35400')}
                </ListGroupItem>
            </ListGroup>
        </Card>
    );
}

const obtenerRenderDeDetalle = (titulo, valor, icono, colorDeIcono) => {
    return (
        <div className="widget-content p-0 my-2">
            <div className="widget-content-wrapper">
                <div className="widget-content-left mr-3">
                    <div className="widget-content-left" style={{width: 40, textAlign: 'center'}}>
                        <FontAwesomeIcon icon={icono} size='2x' color= {colorDeIcono} opacity={0.7} />
                    </div>
                </div>
                <div className="widget-content-left">
                    <div className="widget-heading" style={{fontSize: 16, fontWeight: 'bold'}}>
                        {titulo}:
                    </div>
                    <div className="widget-subheading" style={{fontSize: 16}}>
                        {valor}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetalleDeDominioCorporativo;