import agruparPorcentajes from './agruparPorcentajes.js';

const organizarDataGraficaDeBarras = (data) => {
    const datosParaGrafica = [];

    const aerea = data.filter((item) => item.etiqueta === 'Aérea');
    const porcentajeTotalAerea = parseFloat(agruparPorcentajes(aerea));

    const enterrada = data.filter((item) => item.etiqueta === 'Enterrada');
    const porcentajeEnterrada =  parseFloat(agruparPorcentajes(enterrada));

    const otra = data.filter((item) => item.etiqueta === 'Otra');
    const porcentajeOtra = parseFloat(agruparPorcentajes(otra));

    // console.log('este es el porcentaje de otra', agruparPorcentajes(otra) )

    datosParaGrafica.push(
        {name: 'Aérea', alias: 'Aérea', cantidad: aerea.length || 0, porcentaje: porcentajeTotalAerea || 0},
        {name: 'Enterrada', alias: 'Enterrada', cantidad: enterrada.length || 0, porcentaje:porcentajeEnterrada || 0},
        {name: 'Otra', alias: 'Otra', cantidad: otra.length || 0, porcentaje:porcentajeOtra || 0},
    );
    return datosParaGrafica;
}

export default organizarDataGraficaDeBarras
