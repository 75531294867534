const armarDataPerfilDeEspesores = (nodoCml) => {
    let datosGrafica = [];
    try{
        nodoCml.forEach((data, i) => {
            let resInt = data?.resultadosIntegridad;
            let resVarA = data?.variablesDeLaInspeccion?.utScanA;
            let cmls = isNaN(parseInt(resVarA.locacion.valor)) ? resVarA.locacion.valor : parseInt(resVarA.locacion.valor);
            datosGrafica.push({
                cml: cmls,
                tNom: resVarA?.tNom?.valor || 0, 
                tMin: resInt?.tMin?.valor || 0 , 
                tRet: resInt?.tRet?.valor || 0 , 
                MAWP: resInt?.MAWP?.valor || 0,
                MOP: resInt?.MOP?.valor || 0
            })
        })
        datosGrafica.sort((a,b) => (a.cml > b.cml) ? 1 : ((b.cml > a.cml)? -1 : 0))
    }
    catch(error){console.log(error)}


    return datosGrafica
}

export default armarDataPerfilDeEspesores
