import React from 'react'

import { Label, Input, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import { MdEdit } from "react-icons/md";
import { MdAdd } from "react-icons/md";

//Componentes Auxiliares
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = { display: "flex", width: "100%", justifyContent: 'center', cursor: 'pointer', textDecoration: 'underline' }

const DatosDeLaExcavacion = (props) => {
    return (
        <React.Fragment>
            <br />
            <br />
            <div className={"mb-0 p-3 d-flex " + (props.estado ? 'border rounded-top' : 'border rounded')} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                <Label style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black'}}>DATOS DE LA EXCAVACIÓN</Label>                    
                <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                    {props.authRol ? <BotonCallToAction key={'btn_excavacion_ili'}
                        usuarioActivo={props.usuarioActivo}
                        color={props.estado ? 'primary' : 'success'}
                        icono={props.estado ? MdEdit : MdAdd}
                        titulo={props.estado ? 'Editar Datos' : 'Agregar Datos'}
                        onClick={props.toogleModal}
                    /> : null}
                </div>
            </div>
            {obtenerRenderDeDatos(props)}
        </React.Fragment>
    )
}

const obtenerRenderDeDatos = (props) => {
    if(props.estado){
        return (
            <div className="p-3 shadow-none border rounded-bottom">  
                <Row>
                    <Col className='mt-3' xs='12' md='6' lg='6' >
                        <CustomTable
                            listaDeDatos={[props.keysDelRegistro]}
                            columnas={
                                [
                                    {
                                        accesor: 'titulo',
                                        Header: () => (<p style={estiloParaHeader}>Distancia de registro de excavación</p>),
                                        columns: [
                                            {
                                                accesor: 'distanciaRegistroExcavacionInicial',
                                                Header: () => (<p style={estiloParaHeader}>Inicial</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.distanciaRegistroExcavacionInicial}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'distanciaRegistroExcavacionFinal',
                                                Header: () => (<p style={estiloParaHeader}>Final</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.distanciaRegistroExcavacionFinal}</p>
                                                    </div>
                                                ),
                                            }
                                        ]
                                    }
                                ]
                            }
                        />
                    </Col>
                    <Col className='ml-2 mr-2'>
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="longitudDeExcavacion" className='mb-0 mt-2'>Longitud de excavación:</Label>
                                <Input type="text" name="longitudDeExcavacion" id="longitudDeExcavacion" placeholder="Longitud de excavación" defaultValue={props.keysDelRegistro.longitudDeExcavacion} disabled/>
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="profundidadDeExcavacion" className='mb-0 mt-2'>Profundidad de excavación:</Label>
                                <Input type="text" name="profundidadDeExcavacion" id="profundidadDeExcavacion" placeholder="Profundidad de excavación" defaultValue={props.keysDelRegistro.profundidadDeExcavacion} disabled/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="anchoDeExcavacion" className='mb-0 mt-2'>Ancho de excavación:</Label>
                                <Input type="text" name="anchoDeExcavacion" id="anchoDeExcavacion" placeholder="Ancho de excavación" defaultValue={props.keysDelRegistro.anchoDeExcavacion} disabled/>
                            </Col>
                            <Col xs='12' md='6' lg='6' >
                                <Label for="tipoDeTerreno" className='mb-0 mt-2'>Tipo de terreno:</Label>
                                <Input type="text" name="tipoDeTerreno" id="tipoDeTerreno" placeholder="Tipo de terreno" defaultValue={props.keysDelRegistro.tipoDeTerreno} disabled/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }else{
        return(<></>);
    }
}

export default DatosDeLaExcavacion