export const obtenerColorSegunValorCualitativo = (valorTarget, parametrizacionDelRango) => {
    try {
        let intervalos = Object.keys(parametrizacionDelRango);

        for (let i = 0; i < intervalos.length; i++) {
            if (parametrizacionDelRango[intervalos[i]]['valorCualitativo'] === valorTarget) {
                return parametrizacionDelRango[intervalos[i]].color;
            };
        }

        return "#DADADA";
    }
    catch (error) { return "#DADADA"; };
}


export const obtenerColorSegunNivel = (valorTarget, parametrizacionDelRango) => {
    try {
        let intervalos = Object.keys(parametrizacionDelRango);

        for (let i = 0; i < intervalos.length; i++) {
            if (parametrizacionDelRango[intervalos[i]]['etiqueta'] === valorTarget) {
                return parametrizacionDelRango[intervalos[i]].color;
            };
        }

        return "#DADADA";
    }
    catch (error) { return "#DADADA"; };
}


const obtenerColorSegunEstado = (estado) => {
    try {
       switch(estado){
           case 'TOTAL':
               return '#342056';
           case 'PLANEADA':
               return '#1E96FC';
           case 'PROGRAMADA':
               return '#ED0101';
           case 'EN_SITIO':
               return '#f5cc00';
           case 'INSPECCIONADA':
               return '#6D15D0';
           case 'EJECUTADA':
               return '#001D3D';
           case 'REVISADA':
               return '#F56A00';
           case 'FINALIZADA':
               return '#0000';
           case 'CONTROL_CALIDAD':
               return '#922B21';
           case 'FACTURADA':
               return '#43B929';
           case 'CANCELADA':
               return '#ADACAC';
           default:
               return '#DADADA';
       }
    }
    catch (error) { return "#DADADA"; };
}
export default obtenerColorSegunEstado;



