import React from 'react';
import ReactTable from 'react-table';

import Pagination from './Pagination';

//Constantes auxiliares:
const largoDePaginacionDefault = 20;

let CustomTable = (props) => {
    let mostrarPaginacion = true;
    if (!!props.mostrarPaginacion) { mostrarPaginacion = props.mostrarPaginacion };
    let largoDePaginacion = props.isPRD ? 9 : largoDePaginacionDefault;
    if (mostrarPaginacion && props.listaDeDatos && props.listaDeDatos.length < largoDePaginacion) { mostrarPaginacion = false; };
    if (props.largoDePaginacion) { largoDePaginacion = props.largoDePaginacion; };
    if (props.listaDeDatos && props.listaDeDatos.length < largoDePaginacion) { largoDePaginacion = props.listaDeDatos.length; };
    if(props.isPRD){
        return (
            <ReactTable
                key={(new Date()).getTime()}
                PaginationComponent={Pagination}    
                defaultSorted={[props.criterioDeOrdenamiento || {}]}
                pageText={'Página'} ofText={'de'} showPagination={mostrarPaginacion}
                defaultPageSize={largoDePaginacion} previousText={'Anterior'} nextText={'Siguiente'}
                rowsText={'filas'} 
                data={props.listaDeDatos} 
                className="-striped -highlight"
                columns={props.columnas}
                style={{ width:"100%" }}
                {...props.tableProps}
            />
        );
    }else{
        return (
            <ReactTable
                key={(new Date()).getTime()}
                PaginationComponent={Pagination}    
                defaultSorted={[props.criterioDeOrdenamiento || {}]}
                pageText={'Página'} ofText={'de'} showPagination={mostrarPaginacion}
                defaultPageSize={largoDePaginacion} previousText={'Anterior'} nextText={'Siguiente'}
                rowsText={'filas'} 
                data={props.listaDeDatos} 
                className="-striped -highlight"
                columns={props.columnas}
                style={props.style}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row && (props.setSubAnomaliaEdicion !==undefined || props.setAnomaliaEdicion !==undefined)) {
                        return {
                            onClick: (e) => {
                                if (rowInfo.original.anomalia || rowInfo.original.idDeSubAnomalia) {
                                    if (props.subAnomalia) { 
                                        if (rowInfo.original.idDeSubAnomalia) { props.setSubAnomaliaEdicion(rowInfo.original.idDeSubAnomalia) }
                                        //if (rowInfo.original.idDAnomalia) { props.setAnomaliaEdicion(rowInfo.original.idDeAnomalia) }
                                     }
                                    else { props.setAnomaliaEdicion(rowInfo.original.anomalia.id) }
                                }
                                else {
                                    if (props.subAnomalia) {
                                        if (rowInfo.original.idDeSubAnomalia) { props.setSubAnomaliaEdicion(rowInfo.original.idDeSubAnomalia) }
                                        if (rowInfo.original.idDAnomalia) { props.setAnomaliaEdicion(rowInfo.original.idDAnomalia) }
                                    }
                                    else { props.setAnomaliaEdicion((rowInfo.original.idDeAnomalia ? rowInfo.original.idDeAnomalia : rowInfo.original.idDeEnsayo)) }
                                }
                            }
                        }
                    } else {
                        return {}
                    }
                }}
                {...props.tableProps}
            />
        );
    }
    
}

export default CustomTable;