import React from 'react';
import PropTypes from 'prop-types';
import { FaAlignCenter, } from 'react-icons/fa';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { BsArrowsExpand, BsArrowsCollapse } from 'react-icons/bs';

//Componentes auxiliares:
import BotonDeCardConEncabezado from './BotonDeCardConEncabezado';

//Propiedades base para el icono del card:
const baseParaIconoDelCard = { size: 15, style: { strokeWidth: 0.75 } };

class CardConEncabezado extends React.Component {

    constructor() {
        super();

        this.state = {
            elContenidoEstaDesplegado: true
        }
    }

    componentDidMount() {
        this.setState({ elContenidoEstaDesplegado: (this.props.elContenidoEstaDesplegado !== undefined) ? this.props.elContenidoEstaDesplegado : this.state.elContenidoEstaDesplegado });
    }

    render() {
        return (
            <Card className="main-card mb-3" style={this.props.estiloGeneralDelCard}>
                <CardHeader className={"bg-" + (this.props.bgColor || "royal") + " text-" + (this.props.textColor || "white") + ` ${this.props.clase}`}
                    style={{ paddingLeft: 15, paddingRight: 15  }}>
                    <div style={{ paddingLeft: 7.5, paddingRight: 7.5 }}>
                        {this.obtenerIconoPrincipal()}
                    </div>

                    <span style={{ marginTop: 3, marginLeft: 3, ...this.props.stylesDelTitulo }}
                        onClick={() => { if (this.props.onClickEnTitulo) { this.props.onClickEnTitulo(); } }}>
                        {this.props.titulo}
                    </span>

                    <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "center" }}>
                        {this.props.botonesAuxiliares}

                        <BotonDeCardConEncabezado  onClick={this.toggleDelCard}
                            icono={(this.state.elContenidoEstaDesplegado) ? BsArrowsCollapse : BsArrowsExpand} />
                    </div>
                </CardHeader>

                <CardBody style={{ ...this.props.style, display: (this.state.elContenidoEstaDesplegado) ? "unset" : "none" }} >
                    {this.props.children}
                </CardBody>

            </Card>
        );
    }

    //Métodos para renderizado:
    obtenerIconoPrincipal = () => {
        let Componente = this.props.icono;
        if (this.props.icono) {
            return (
                <Componente {...baseParaIconoDelCard} />
            );
        }
        else {
            return (
                <FaAlignCenter {...baseParaIconoDelCard} />
            );
        }
    }

    //Métodos operativos:
    toggleDelCard = () => {
        this.setState({ elContenidoEstaDesplegado: !this.state.elContenidoEstaDesplegado });
    }
}

CardConEncabezado.propTypes = {
    icono: PropTypes.func,
    titulo: PropTypes.string,
    elContenidoEstaDesplegado: PropTypes.bool,
    botonesAuxiliares: PropTypes.arrayOf(BotonDeCardConEncabezado)
};

export default CardConEncabezado;