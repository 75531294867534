import React from 'react';
import { connect } from 'react-redux';

//Componentes auxiliares:
import ResumenDeModulo from '../../../../../utilidades/componentes/resumenDeModulo/ResumenDeModulo';

//Scripts auxiliares:
import { categorias } from '../api1160/estaticos/opcionesDeFiltros';
import { opcionesParaGraficas } from '../api580/estaticos/opcionesParaGraficas';

//Funciones para manipular state redux:
import { actualizarDataApi1160, actualizarModuloActivo } from '../../../../../storage/reducerParaData';
import Error404 from "../../../../404";

const activosDePrueba = require('../api580/estaticos/ejemploDeActivos.json');
const segmentosDePrueba = require('../api580/estaticos/ejemploDeSegmentos.json');

const itemsColumnaIzquierda = {
    1: {
        activo: true,
        tipo: "MATRIZ_DE_RIESGO",
        props: {
            criterioDeOrdenamiento: {
                id: "riesgo", desc: true
            }
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[0]
        }
    },
    3: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[1]
        }
    },
    4: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[2]
        }
    }
};

const itemsColumnaDerecha = {
    1: {
        activo: true,
        tipo: "TABLA_DE_NODOS",
        props: {
            criterioDeOrdenamiento: {
                id: "riesgo", desc: true
            }
        }
    },
    2: {
        activo: true,
        tipo: "MAPA_DEL_ACTIVO",
        props: {}
    },
    3: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[1]
        }
    },
    4: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[6]
        }
    },
    5: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[3]
        }
    },
};

class VisualizadorApi1160 extends React.Component {

    constructor() {
        super();

        this.state = {
            error: false,
        };
    }

    render() {
        if(!this.state.error) {
            return (
                <React.Fragment>
                    <ResumenDeModulo
                        categoriasDeFiltrado={categorias}
                        itemsColumnaDerecha={itemsColumnaDerecha}
                        itemsColumnaIzquierda={itemsColumnaIzquierda}
                        metodoParaActualizarDataEnRedux={this.props.actualizarDataApi1160}
                        moduloTarget="API1160"
                        nodosEnRedux={this.props.dataDe1160EnRedux}
                        nodosParaDesarrollo={{ "todosLosSegmentos": segmentosDePrueba, "todosLosActivos": activosDePrueba }}
                        opcionesParaGraficas={opcionesParaGraficas}
                        permitirConsultas={true}
                        titulo="API 1160"
                        subtitulo="Análisis de riesgo para ductos"
                    />
                </React.Fragment>
                );}
                else {
                    return (
                        <Error404
                            error={this.props.location.pathname} />
                    );
                }
    }
    static getDerivedStateFromError(error) {
        this.setState({ error: true })
    }

    componentDidMount(){
        this.props.actualizarModuloActivo('API1160')
    }
}


const mapStateToProps = state => ({
    dataDe1160EnRedux: state.reducerParaData["/app/analisis/riesgo/api1160"],
});

const mapDispatchToProps = dispatch => ({
    actualizarDataApi1160: nuevaData => { dispatch(actualizarDataApi1160(nuevaData)); },
    actualizarModuloActivo: nuevoModulo => { dispatch(actualizarModuloActivo(nuevoModulo)); },

});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizadorApi1160);