import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/** Estilos */
import { Row, Col} from 'reactstrap';
import { FaFileAlt, FaInfoCircle, FaMapMarkedAlt, FaImage, FaFileImage, FaChartBar, FaTachometerAlt } from 'react-icons/fa';
import { Nav, NavItem, TabPane, TabContent } from 'reactstrap';
import imagenTambor from '../../../../../../assets/utils/images/api510/drum-horizontal.png';
import classnames from 'classnames';
import GaugeChart from 'react-gauge-chart'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
import '../../visualizadorApi510.scss';

/** Componentes creados */
import CustomTableDinamica from '../../../../../../utilidades/componentes/visualizadores/CustomTableDinamica';
import CustomSection from '../../../../../../utilidades/componentes/plantillaBase/CustomSection.jsx';
import CardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import Preloader from '../../../../../../utilidades/componentes/plantillaBase/Preloader';
import GaleriaFotograficaIntegridad510 from '../galeriaFotografica/GaleriaFotograficaIntegridad510'
import MapaActivosIntegridad from '../../../../../../utilidades/componentes/visualizadores/MapaActivosIntegridad';
import TablaConGraficoDeBarras from '../../../../../../utilidades/componentes/visualizadores/TablaConGraficoDeBarras';
import { BotonFiltrarFechaEncabezado } from '../botones/BotonFiltrarFechaEncabezado';
import VisualizadorLrecLh5ALh1002 from '../../../API570/lrecLh5ALh1002/VisualizadorLrecLh5ALh1002';
import { GraficaDeBarras } from '../graficas/GraficaDeBarras';
import  GraficaDeDona  from '../graficas/GraficaDeDona';

/** Custom hooks */
import { useFetchTablaInfo } from '../../hooks/useFetchTablaInfo';
import { useFetchTablaResumen } from '../../hooks/useFetchTablaResumen';
// import { useFetchNodos } from '../../hooks/useFetchNodos';
import { useFetchNodo } from '../../hooks/useFetchNodo';
import { useFetchHistorialDeResultados } from '../../hooks/useFetchHistorialDeResultados';
import { useFetchCmls } from '../../hooks/useFetchCmls';

/** Scripts */
import { crearBotonEncabezado } from '../../scripts/crearBotonEncabezado';
import { crearFormatoParaTabla } from '../../scripts/crearFormatoParaTabla';
import { calcularActivoPorFecha } from '../../scripts/calcularActivoPorFecha';
import { formatearFechaParaHistorialDeResultados } from '../../scripts/formatearFechaParaHistorialDeResultados';
import armarCoordenadasParaMapaDeActivos from '../../../scripts/armarCoordenadasParaMapaDeActivos';
import armarDataParaTablaCondicionSegmento from '../../scripts/armarDataParaTablaCondicionSegmento';
import armarDataUtScanA from '../../scripts/armarDataUtScanA';
import armarDataUtScanC from '../../scripts/armarDataUtScanC';
import armarDataPerfilDeEspesores from '../../../API570/scripts/armarDataPerfilDeEspesores';
import { armarDatosParaGraficaDeBarras } from '../../scripts/armarDatosParaGraficaDeBarras';
import { posicionarVelocidadCorrosionEnIntervalo } from '../../scripts/posicionarVelocidadCorrosionEnIntervalo';

/** Base de datos */
import { firebaseApp } from '../../../../../../firebase';

/** Datos de prueba */
import {boquillas} from '../../datosDePrueba/boquillas'
import {datosDeBoquillas} from '../../datosDePrueba/datosDeBoquillas'
import {datosDelEquipo} from '../../datosDePrueba/datosDelEquipo'
import {datosDeProceso} from '../../datosDePrueba/datosDeProceso'
import {tablaIdentificacion} from '../../datosDePrueba/tablaIdentificacion'
import { datosGraficoDeDona } from '../../datosDePrueba/datosGraficoDeDona';
import { datosGraficaDeBarras } from '../../datosDePrueba/datosGraficaDeBarras';
import { posicionarVidaRemanenteEnIntervalo } from '../../scripts/posicionarVidaRemanenteEnIntervalo';
import { DrumSeparador } from '../esquemas/DrumSeparador';



const VisualizadorDetalleActivoApi510 = ( props ) => {
    
    /** Custom hooks */
    const { datosNodo, cargandoNodo, consultarNodo } = useFetchNodo();  
    const { dataTablaInfo, cargandoInfo, armarDatosTablaInfo } = useFetchTablaInfo();
    const { dataTablaResumen, cargandoResumen, armarDatosTablaResumen } = useFetchTablaResumen();
    const { datosHistorial, cargandoHistorial, consultarHistorialDeResultados } = useFetchHistorialDeResultados();
    const { crearColumnasParaTabla, crearListaDatosParaTabla } = crearFormatoParaTabla();
    const { datosCmls, cargandoCmls, consultarCmlsAsociados } = useFetchCmls();
    
    const { filtrarFechaSeleccionada, darFormatoAFechaInicio, darFormatoAFechaFin, seleccionarLaFechaMasReciente } = formatearFechaParaHistorialDeResultados();
    
    /** Hooks */
    const [ cargando, setCargando ] = useState();
    const [datosResumen, setDatosResumen] = useState([])
    const [botonActivo, setBotonActivo] = useState('activo')
    const [fechaDeInspeccion, setFechaDeInspeccion] = useState(undefined)
    
    /** Primer método que se ejecuta para consultar los nodos necesarios para la vista */
    useEffect(() => {
        consultarNodo( 'activos', props.match.params.id );
    }, []);
    
    /** Métodos utilizados para llenar la data de las tablas acorde cambian los valores del nodo (del activo) */
    useEffect(() => {
        Object.keys(datosNodo).length > 0 && consultarCmlsAsociados(
            {
                'dominioCorporativo': props.usuarioActivo.dominioCorporativo,
                'informacionDeLosPadres.activoAsociado.nombre': datosNodo.informacionGeneral.nombre.valor
            }
        )
        Object.keys(datosNodo).length > 0 && armarDatosTablaInfo( datosNodo );

        if ( datosNodo.fechasDeInspeccion ) {
            Object.keys(datosNodo).length > 0 && setFechaDeInspeccion( new Date(seleccionarLaFechaMasReciente( datosNodo.fechasDeInspeccion ).seconds * 1000).toLocaleString().split(',')[0] )
        }
        
    }, [datosNodo])
    
    useEffect(() => {
        if ( fechaDeInspeccion ) {
            const fechaInicial = firebaseApp.firestore.Timestamp.fromDate( darFormatoAFechaInicio( datosNodo.fechasDeInspeccion, fechaDeInspeccion ) );
            const fechaFinal = firebaseApp.firestore.Timestamp.fromDate( darFormatoAFechaFin( datosNodo.fechasDeInspeccion, fechaDeInspeccion ) );
            consultarHistorialDeResultados( 'activos', datosNodo.id, 'API510', 'fechaDeInspeccion', fechaInicial, fechaFinal );
        }
    }, [fechaDeInspeccion])

    useEffect(() => {
        armarDatosTablaResumen( datosHistorial )
    }, [datosHistorial])

    return (
        <React.Fragment>
            {
                Object.keys(datosNodo).length > 0 && 
                <CustomSection
                    titulo={ 'Módulo API 510' }
                    subtitulo={ 'Detalle del activo' + fechaDeInspeccion }
                    URLIconoPrincipal="/assets/img/logos/giaCore-logo.png"
                    estiloPadreBotones={{display: 'flex'}}
                    botonesAdicionales={
                        [
                            <BotonFiltrarFechaEncabezado
                                key={ 'Boton Filtro fecha API510' }
                                nodo={ datosNodo }
                                modulo={ 'API510' }
                                callback={ calcularActivoPorFecha }
                                setFechaDeInspeccion={ setFechaDeInspeccion }
                                fechaDeInspeccion={ fechaDeInspeccion }
                                idActivo={props.match.params.id}
                            />,
                            crearBotonEncabezado(
                                'Descargar datos api510',
                                'Descargar datos',
                                'FaCloudDownloadAlt',
                                () => {console.log('Subir data')}
                            )
                        ]
                    }
                >
                    <Nav className="nav-buttons-content">
                        <NavItem>
                            <div 
                                className={classnames({ active: botonActivo === 'activo' })}
                            >
                                <span type='button' 
                                    className={ botonActivo === 'activo'? 'nav-button-activos' : 'nav-button-activos-desactivado' } 
                                    onClick={() => setBotonActivo('activo')}>
                                    Activo
                                </span>
                            </div>
                        </NavItem>
                        <NavItem>
                            <div 
                                className={classnames({ active: botonActivo === 'cmls' })}
                            >
                                <span 
                                    type='button' 
                                    className={botonActivo === 'cmls'? 'nav-button-activos' : 'nav-button-activos-desactivado'} 
                                    onClick={() => setBotonActivo('cmls')}>
                                    Cmls del activo
                                </span>
                            </div>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={botonActivo}>
                        <TabPane tabId="activo">
                            <Row>
                                {
                                    dataTablaInfo.length > 0 && 
                                    <Col xs='12' md='12' lg='5' xl='5'>
                                        <CardConEncabezado 
                                            icono={ FaInfoCircle }
                                            titulo={ 'Información general del activo' }
                                        >
                                            <CustomTableDinamica
                                                keyDeLaTabla={'Tabla informacion' + dataTablaInfo.length}
                                                listaDeDatos={ crearListaDatosParaTabla(dataTablaInfo)  }
                                                columnas={ crearColumnasParaTabla(dataTablaInfo) }
                                                criterioDeOrdenamiento={ {id: "numero", desc: false} }
                                            />
                                        </CardConEncabezado>
                                    </Col>
                                }
                                {
                                    dataTablaResumen.length > 0 &&
                                    <Col xs='12' md='12' lg='7' xl='7' >
                                        <Col xs='12' md='12' lg='12' xl='12' >
                                            <CardConEncabezado
                                                icono={ FaFileAlt }
                                                titulo={ 'Datos del equipo' }
                                            >
                                                <CustomTableDinamica
                                                    keyDeLaTabla={'Tabla informacion' + new Date()}
                                                    listaDeDatos={ crearListaDatosParaTabla(dataTablaResumen)  }
                                                    columnas={ crearColumnasParaTabla(dataTablaResumen) }
                                                    criterioDeOrdenamiento={ {id: "numero", desc: false} }
                                                />
                                            </CardConEncabezado>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12'>
                                            <CardConEncabezado
                                                icono={ FaChartBar }
                                                titulo={ 'MAWP' }
                                            >
                                              <GraficaDeBarras
                                                datos={ armarDatosParaGraficaDeBarras( datosHistorial ) }
                                                />
                                            </CardConEncabezado>

                                        </Col>
                                    </Col>
                                }
                            </Row>
                            {/*  Visualizador para resultados calculos mínimos */}
                            <Row>
                                {
                                    Object.keys(datosNodo).length > 0 &&
                                    <Col xs='12' md='12' lg='12' xl='12' >
                                        <Row>
                                            <Col xs='12' md='12' lg='12' xl='12'>
                                                <CardConEncabezado
                                                    icono={ FaImage }
                                                    titulo={ 'Tmin requerido Drum (in)' }
                                                    style={{margin: '0 auto', position: 'relative'}}
                                                >
                                                    <DrumSeparador  
                                                        posicion={datosNodo.informacionGeneral.drum.valor}
                                                        cap={datosNodo.informacionGeneral.tNom.tNomBody}
                                                        body={datosNodo.informacionGeneral.tNom.tNomHead}
                                                        nozzle={datosNodo.informacionGeneral.tNom.tNomNozzle}
                                                    />
                                                    {/* datosNodo.informacionGeneral.tNom.tNomBody */}
                                                    {/* datosNodo.informacionGeneral.tNom.tNomHead */}
                                                    {/* datosNodo.informacionGeneral.tNom.tNomNozzle */}
                                                    {/* <img 
                                                        src={imagenTambor}
                                                        alt="tambor"
                                                    />
                                                    <div className='campo1'>
                                                        <span className="title">CAP semielíptico</span>
                                                        <span className="content">0,33 (in)</span>    
                                                    </div>
                                                    <div className='campo2'>
                                                        <span className="title">Cuerpo</span>
                                                        <span className="content">0,32 (in)</span>  
                                                        
                                                    </div>
                                                    <div className='campo3'>
                                                        <span className="title">CAP semielíptico</span> 
                                                        <span className="content">0,33 (in)</span>  
                                                    </div> */}
                                                </CardConEncabezado>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col xs='12' md='12' lg='4' xl='4'>
                                    <CardConEncabezado 
                                        icono={ FaTachometerAlt }
                                        titulo={ 'Velocidad de corrosión máxima Cap (mpy)' }
                                    >
                                        {/* <GraficaDeDona
                                            parametrizacion={datosGraficoDeDona}
                                        /> */}
                                         <GaugeChart id="velocidad-corrosion-Cap" 
                                            nrOfLevels={4} 
                                            colors={["#66be33", "#f1ca1c", "#e7961e", "#d22928" ]}
                                            percent={posicionarVelocidadCorrosionEnIntervalo(datosHistorial?.variablesDeLaInspeccion?.velocidadDeCorrosion?.vCorrMaxHead)} 
                                            textColor={'#333'}
                                            formatTextValue={value => parseFloat(datosHistorial?.variablesDeLaInspeccion?.velocidadDeCorrosion?.vCorrMaxHead).toFixed(5) }
                                        />
                                        <div className='gauge-leyend'>
                                            <ul>
                                                <li><div className='circle-green'></div>Baja &lt; 1</li>
                                                <li><div className='circle-yelow'></div>Moderada 1.0 - 4.9</li>
                                                <li><div className='circle-orange'></div>Alta 5 - 10</li>
                                                <li><div className='circle-red'></div>Severa &gt; 10</li>
                                            </ul>
                                        </div>
                                    </CardConEncabezado>
                                </Col>
                                <Col xs='12' md='12' lg='4' xl='4'>
                                    <CardConEncabezado 
                                        icono={ FaTachometerAlt }
                                        titulo={ 'Velocidad de corrosión máxima Cuerpo (mpy)'}
                                        className={'content-gauge-chart'}
                                    >
                                        {/* <GraficaDeDona
                                            parametrizacion={datosGraficoDeDona}
                                        /> */}
                                        <GaugeChart id="velocidad-corrosion-Cuerpo" 
                                            nrOfLevels={4} 
                                            colors={["#66be33", "#f1ca1c", "#e7961e", "#d22928" ]} 
                                            percent={posicionarVelocidadCorrosionEnIntervalo(datosHistorial?.variablesDeLaInspeccion?.velocidadDeCorrosion?.vCorrMaxBody || 0)} 
                                            textColor={'#333'}
                                            formatTextValue={value => parseFloat(datosHistorial?.variablesDeLaInspeccion?.velocidadDeCorrosion?.vCorrMaxBody).toFixed(5) || 0}
                                        />
                                        <div className='gauge-leyend'>
                                            <ul>
                                                <li><div className='circle-green'></div>Baja &lt; 1</li>
                                                <li><div className='circle-yelow'></div>Moderada 1.0 - 4.9</li>
                                                <li><div className='circle-orange'></div>Alta 5 - 10</li>
                                                <li><div className='circle-red'></div>Severa &gt; 10</li>
                                            </ul>
                                        </div>
                                    </CardConEncabezado>
                                </Col>
                                <Col xs='12' md='12' lg='4' xl='4'>
                                    <CardConEncabezado 
                                        icono={ FaTachometerAlt }
                                        titulo={ 'Velocidad de corrosión máxima boquilla (mpy)'}
                                        className={'content-gauge-chart'}
                                    >
                                        {/* <GraficaDeDona
                                            parametrizacion={datosGraficoDeDona}
                                        /> */}
                                        <GaugeChart id="velocidad-corrosion-boquilla" 
                                            nrOfLevels={4} 
                                            colors={["#66be33", "#f1ca1c", "#e7961e", "#d22928" ]} 
                                            percent={posicionarVelocidadCorrosionEnIntervalo(datosHistorial?.variablesDeLaInspeccion?.velocidadDeCorrosion?.vCorrMaxNozzle || 0)} 
                                            textColor={'#333'}
                                            formatTextValue={value => parseFloat(datosHistorial?.variablesDeLaInspeccion?.velocidadDeCorrosion?.vCorrMaxNozzle).toFixed(5) || 0}
                                        />
                                        <div className='gauge-leyend'>
                                            <ul>
                                                <li><div className='circle-green'></div>Baja &lt; 1</li>
                                                <li><div className='circle-yelow'></div>Moderada 1.0 - 4.9</li>
                                                <li><div className='circle-orange'></div>Alta 5 - 10</li>
                                                <li><div className='circle-red'></div>Severa &gt; 10</li>
                                            </ul>
                                        </div>
                                    </CardConEncabezado>
                                </Col>
                                <Col xs='12' md='12' lg='4' xl='4'>
                                    <CardConEncabezado 
                                        icono={ FaTachometerAlt }
                                        titulo={ 'Vida remanente mínima Cap (años)' }
                                    >
                                        <GaugeChart id="vida-remanente-Cap" 
                                            nrOfLevels={3} 
                                            colors={["#66be33", "#f1ca1c", "#d22928" ]} 
                                            percent={posicionarVidaRemanenteEnIntervalo(datosHistorial?.variablesDeLaInspeccion?.vidaRemanente?.vRemMinNozzle || 0 )} 
                                            textColor={'#333'}
                                            formatTextValue={value => datosHistorial?.variablesDeLaInspeccion?.vidaRemanente?.vRemMinNozzle || 0}
                                        />
                                        <div className='gauge-leyend'>
                                            <ul>
                                                <li><div className='circle-green'></div>Baja &lt; 0 - 5</li>
                                                <li><div className='circle-yelow'></div>Moderada 5 - 20</li>
                                                <li><div className='circle-red'></div>Severa &gt; 20</li>
                                            </ul>
                                        </div>
                                    </CardConEncabezado>
                                </Col>
                                <Col xs='12' md='12' lg='4' xl='4'>
                                    <CardConEncabezado 
                                        icono={ FaTachometerAlt }
                                        titulo={ 'Vida remanente mínima Cuerpo (años)' }
                                    >
                                        <GaugeChart id="vida-remanente-Cuerpo"
                                            nrOfLevels={3}
                                            colors={["#66be33", "#f1ca1c", "#d22928" ]}
                                            percent={posicionarVidaRemanenteEnIntervalo(datosHistorial?.variablesDeLaInspeccion?.vidaRemanente?.vRemMinBody || 0)}
                                            textColor={'#333'}
                                            formatTextValue={value => datosHistorial?.variablesDeLaInspeccion?.vidaRemanente?.vRemMinBody || 0}
                                        />
                                        <div className='gauge-leyend'>
                                            <ul>
                                                <li><div className='circle-green'></div>Baja &lt; 0 - 5</li>
                                                <li><div className='circle-yelow'></div>Moderada &gt; 5 - 20</li>
                                                <li><div className='circle-red'></div>Severa &gt; 20</li>
                                            </ul>
                                        </div>
                                    </CardConEncabezado>
                                </Col>
                                <Col xs='12' md='12' lg='4' xl='4'>
                                    <CardConEncabezado 
                                        icono={ FaTachometerAlt }
                                        titulo={ 'Vida remanente mínima Boquilla (años)' }
                                    >
                                        <GaugeChart id="vida-remanente-Boquilla"
                                            nrOfLevels={3}
                                            colors={["#66be33", "#f1ca1c", "#d22928" ]}
                                            percent={posicionarVidaRemanenteEnIntervalo(datosHistorial?.variablesDeLaInspeccion?.vidaRemanente?.vRemMinHead || 0)}
                                            textColor={'#333'}
                                            formatTextValue={value => datosHistorial?.variablesDeLaInspeccion?.vidaRemanente?.vRemMinHead || 0}
                                        />
                                        <div className='gauge-leyend'>
                                            <ul>
                                                <li><div className='circle-green'></div>Baja &lt; 0 - 5</li>
                                                <li><div className='circle-yelow'></div>Moderada &gt; 5 - 20</li>
                                                <li><div className='circle-red'></div>Severa &gt; 20</li>
                                            </ul>
                                        </div>
                                    </CardConEncabezado>
                                </Col>
                            </Row>
                            <Row>
                                {   
                                    <Col>
                                        <CardConEncabezado
                                            icono={ FaMapMarkedAlt }
                                            titulo={ 'Geo referenciación mapa de activos' }
                                        >
                                            <MapaActivosIntegridad
                                                coordenadas= {
                                                    armarCoordenadasParaMapaDeActivos(undefined)
                                                }
                                            />
                                        </CardConEncabezado>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                {   
                                    <Col>
                                        <GaleriaFotograficaIntegridad510
                                            idActivoIntegridad={props.match.params.id}
                                            modulo={'API510'}
                                        />
                                    </Col>
                                }
                            </Row>
                        </TabPane>
                        <TabPane tabId="cmls">
                            <Row>
                                <Col>
                                    {
                                        datosCmls.length > 0 &&
                                        <VisualizadorLrecLh5ALh1002
                                            key={ 'Visualizador Lrhc' }
                                            dataUtScanA={ armarDataUtScanA(datosCmls) }
                                            dataUtScanC={ armarDataUtScanC(datosCmls) }
                                            dataPerfilEspesores={ armarDataPerfilDeEspesores(datosCmls) }
                                            nodosCml={ datosCmls }
                                        />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </CustomSection>
            }
            <ToastContainer />
            <Preloader elPreloaderEstaAbierto={cargandoInfo || cargandoResumen || cargandoNodo || cargandoHistorial}/>        
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});


export default connect(mapStateToProps)(VisualizadorDetalleActivoApi510)