import React from 'react'

/** Componentes propios */
import SpanDeCampo from '../../../../../../utilidades/componentes/resumenDeModulo/SpanDeCampo.jsx';

export const SpanDeCampoApi510 = ({ 
    coleccion, campoTarget, 
    cargando, usuarioActivo, 
    nodosDescargados, mostrarLabelDeDescargaFallida, 
    actualizarCampoTarget, datos
}) => {

    const obtenerMensajeDeDescargaFallida = () => {
        if (mostrarLabelDeDescargaFallida) {
            return (
                <p style={{ marginBottom: 2.5 }}
                    dangerouslySetInnerHTML={{ __html: "La descarga de exportación a excel <b>falló</b>" }} />
            );
        }
    }

    const obtenerMensajeDeLimiteDeDescargas = () => {
        if (!cargando && campoTarget) {
            return (
                <p style={{ marginBottom: 2.5 }}
                    dangerouslySetInnerHTML={{
                        __html: "Se descargaron <b>todos los activos" 
                            + "</b> del campo ("
                            + (datos.length) + ")"
                    }} />
            );
        }
        else {
            return (
                <p style={{ marginBottom: 2.5 }}
                    dangerouslySetInnerHTML={{ __html: "..." }} />
            );
        }
    }

    

    return (
        <div style={{ textAlign: "right", marginBottom: "1rem" }}>
            <p style={{ marginBottom: 2.5, display: ((campoTarget) ? "unset" : "none") }}>
                {cargando
                    ? "Descargando "
                    : "Estoy viendo los "
                }
                <b>Activos</b>
                {" del campo "}
                <b>
                    <SpanDeCampo
                        coleccion={ coleccion }
                        campoTarget={campoTarget}
                        usuarioActivo={usuarioActivo}
                        onChange={(nuevoCampoTarget) => { actualizarCampoTarget(nuevoCampoTarget); }}
                        permitirConsultaInicial={!nodosDescargados}
                    />
                </b>
            </p>
            <p style={{ marginBottom: 2.5, display: ((!campoTarget) ? "unset" : "none") }}>
                Obteniendo los <b>posibles campos</b>...
            </p>

            {obtenerMensajeDeDescargaFallida()}
            {obtenerMensajeDeLimiteDeDescargas()}
        </div>
    )
}
