import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Input, Label, Row, CardImg, Card, CardImgOverlay, Button } from 'reactstrap';

//iconos:
import { faPlus, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//componentes:
import { obtenerValidacionDeVariable } from '../scripts/paraValidarVariablesAgregarUsuarios';
import Preloader from '../../../../utilidades/componentes/plantillaBase/Preloader';

//glosario de inputs:
import { glosarioDeInputsAgregarUsuario } from '../scripts/glosarioDeInputsAgregarUsuario';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//Utilidades Gestión Usuarios
import { crearUsuario } from '../scripts/gestionDeUsuarios';

let defaultAvatar = "/assets/img/iconos/avatar.webp";

require('firebase/firestore');

const estiloLabel = 'mb-0 mt-2'

class ContenidoModalAgregarUsuarios extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            nombre: '',
            correo: '',
            dominioAlQuePertenece: '',
            rol: '',
            cargando: false,

            mensajeNombre: false,
            mensajeCorreo: false,
            mensajeDominioAlQuePertenece: false,
            mensajeRol: false,

            estadoValidacion: {},

            fotoACargar: undefined,
            imagenACargar: undefined

        }
        this.cargarImagen = React.createRef(); 
    }

    componentDidMount(){
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        this.setState({estadoValidacion: estadoDeValidacionInicial});
    }

    render(){
        return(
            <React.Fragment>
                <form className='my-3 offset-lg-1' onSubmit={this.submitDelFormulario}>
                    <Row className='mx-auto' xs='12' lg='10'>
                        <Col className="my-2 pl-0 d-flex justify-content-center" lg='4'>
                            {this.obtenerRenderDeImagenDePerfil()}
                        </Col>
                        <Col className="px-0" lg='7'>
                            {this.generarInputsDelModal(glosarioDeInputsAgregarUsuario)}
                        </Col>
                    </Row>
                    <Row xs='12' lg='10'>
                        {/* {this.generarInputsDelModal(glosarioDeInputsAgregarUsuario2)} */}
                    </Row>
                    <Row className='mt-4' xs='12' lg='10'>
                        <Col lg='11' xs='12'>
                            <Input 
                                type='submit' 
                                value="Crear" 
                                style={{ textAlign: 'center', color: '#ffffff' }} 
                                className={'bg-royal pusheable-button mb-3'} 
                            />
                        </Col>
                    </Row>
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    //Funciones de renderizado
    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    generarInputsDelModal = (parametrizacion) => {
        return Object.keys(parametrizacion).map(input => this.generarInputSegunGlosario(parametrizacion[input]));
    }

    generarInputSegunGlosario = (input) => {
        switch(input.type){
            case 'text':
                return (
                    <Col
                        key={`input:${input.key}:`} 
                        xs={input['col-sm']} 
                        md={input['col-md']}
                        lg={input['col-lg']}
                        xl={input['col-xl']}
                    >
                        <Label for={input.type} className={estiloLabel}>{input.label}</Label>
                        <Input
                            type={input.type}
                            name={input.key}
                            value={this.state[input.key]}
                            onChange={(e) => this.cambiosEnInput(e, input)}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state[input.mensaje] ? 'El valor del campo es invalido' : '')}</small>
                    </Col>
                )
            case 'select':
                return (
                    <Col key={`select ${input.key}`} 
                        xs={input['col-sm']} 
                        md={input['col-md']}
                        lg={input['col-lg']}
                        xl={input['col-xl']}
                    >
                        <Label className={estiloLabel} >{input.label}</Label>
                        <Select
                            name={input.key}
                            value={this.state[input.key]}
                            onChange={(e) => this.cambiosEnInput(e, input)}
                            style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                            variant="outlined"
                        >
                            {this.obtenerListadoDeOpciones(input.type, input)}
                        </Select>
                    </Col>
                )
            default: 
                return( <React.Fragment></React.Fragment>)
        }
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        if(nodo.filtrarOpciones !== undefined){
            let opciones = nodo.filtrarOpciones(this.props.usuarioActivo.dominioCorporativo)
            opciones.forEach((opcion, i)=> {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }else{
            nodo.opciones.forEach((opcion, i) =>{
                if (opcion.domain === undefined || opcion.domain === this.props.usuarioActivo.dominioCorporativo) {
                    menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
                }
            })
            return menu
        }
    }

    obtenerRenderDeImagenDePerfil = () =>{
        return (
            <Card className={"shadow-md"} style={{width: 200, height: 200, borderRadius: 8}}>
                <CardImg key={'default_avatar'} style={{width: 200, height: 200, objectFit: 'cover', borderRadius: 8}} src={this.state.imagenACargar || defaultAvatar} alt={'avatar'} />
                <CardImgOverlay className="p-0 d-flex flex-column justify-content-end align-items-end" style={{width: 200, height: 200, padding: 2 }} >
                    <div style={{ float: 'right', marginRight: 8, marginBottom: 8}}>
                        <Button 
                            className="btn-pill btn-light btn-shine btn-sm opacity-8" 
                            color="focus" 
                            style={{fontSize: 12}}
                            onClick={() => this.cargarImagen.current.click()}>

                            <FontAwesomeIcon icon={ this.state.fotoACargar ? faPen : faPlus} size='sm' color= {"white"} className="mr-2" />
                            {this.state.fotoACargar ? 'Cambiar foto' : 'Agregar foto'}
                        </Button>
                    </div>
                </CardImgOverlay>
                <input ref={this.cargarImagen} type="file" accept='image/*' style={{ display: "none" }}
                    onChange={(e) => { this.obtenerArchivoDeImagen(e) }} />
            </Card>
        );
    }




    //Funciones Operativas
    cambiosEnInput = (event, input, type) => {
        const value =  event.target.value
        const name = event.target.name;
        let funcionValidacion = obtenerValidacionDeVariable(input.key)
        let validacion = funcionValidacion['validation']
        
        const estadoValidacionActual = this.state.estadoValidacion;

        if (validacion) {
            const respuesta = validacion(input.key, value);
            if (!respuesta) {
                estadoValidacionActual[input.key] = false;
                this.setState({
                    [name]: value,
                    error: "El valor no es válido!",
                    estadoValidacion: estadoValidacionActual,
                    [input.mensaje]: true
                })
                return;
            }
        }
        estadoValidacionActual[input.key] = true;
        this.setState({
            [name]: value,
            error: "",
            estadoValidacion: estadoValidacionActual,
            [input.mensaje]: false
        })
    }

    generarEstadoDeValidacioninicial = () =>{
        let estadoDeValidacionInicial = {};
        Object.keys(glosarioDeInputsAgregarUsuario).forEach(variable =>{
            estadoDeValidacionInicial[variable] = false;
        })
        return estadoDeValidacionInicial;
    }

    obtenerArchivoDeImagen = (imagen) => {
        const imagenSeleccionada = imagen.target.files[0];
        if (imagenSeleccionada) {
            this.setState({
                fotoACargar : imagenSeleccionada,
                imagenACargar: URL.createObjectURL(imagenSeleccionada)
            })
        }
    }
    
    obtenerEstadoDeFormulario = () =>{
        const estadoDeValidacion = this.state.estadoValidacion
        let flag = true;
        Object.keys(this.state.estadoValidacion).forEach(variable =>{
            if(!estadoDeValidacion[variable]) flag = false;
        });

        if(!this.state.fotoACargar) flag = false;

        return flag;
    }

    resetearFormulario = () =>{
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        this.setState({
            nombre: '',
            correo: '',
            dominioAlQuePertenece: '',
            rol: '',
            cargando: false,

            mensajeNombre: false,
            mensajeCorreo: false,
            mensajeDominioAlQuePertenece: false,
            mensajeRol: false,

            estadoValidacion: estadoDeValidacionInicial,
            
            fotoACargar: undefined,
            imagenACargar: undefined
        })
    }

    submitDelFormulario = (evento) => {
        evento.preventDefault();
        this.setState({cargando: true})
        if (this.obtenerEstadoDeFormulario()) {
            crearUsuario(this.state.nombre, this.state.correo, this.props.dominio, this.state.rol, this.props.idEmpresa, this.state.fotoACargar, this.props.usuarioActivo.rol, this.props.usuarioActivo.dominioCorporativo )
                .then(resultado =>{
                    this.resetearFormulario();
                    this.avisoDeGuardadoSatisfactorio(resultado.message);
                })
                .catch(error =>{
                    this.setState({ cargando: false })
                    this.avisoDeGuardadoFallido(error.message);
                })
        }else{
            this.setState({ cargando: false })
            this.avisoDeGuardadoFallido('Los datos ingresados están incompletos no son válidos');
        }
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ContenidoModalAgregarUsuarios);