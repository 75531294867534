import { glosarioDeVariables } from "./glosarioDeVariables";

export const obtenerValidacionDeVariable = (key, objetoPRD) =>{
    const variable = objetoPRD ? objetoPRD[`${key}`] : glosarioDeVariables[`${key}`];
    
    if(variable != null){
        switch(variable["categoria"]){
            case "valorÚnico":
                return validacionValorUnico(variable);
            case "valorAbierto" : 
                return validacionValorAbierto();
            case "valorDeSeleccionMultiple":
                return validacionMultivalor(variable);
            default:
                return {
                    "status" : "VARIABLE_INVALIDA",
                    "error" : "La categoría no se encuentrá en el glosario"
                };
        }
    }else{
        return {
            "status" : "VARIABLE_INVALIDA",
            "error" : "La variable no se encuentrá en el glosario"
        };
    }
}


const validacionValorAbierto = () =>{
    return {
        "status" : "VALIDACION_REQUERIDA",
        "validation" : convertirVariableATexto
    };
}

const convertirVariableATexto = (valor) =>{
    try{
        valor.toString();
        return true;
    }catch(e){  
        return false;
    }
}

const validacionMultivalor = (variable) =>{
    return {
        "status" : "VALIDACION_REQUERIDA",
        "options" : variable['listaDeOpciones']
    };
}

const validacionValorUnico = (variable) =>{
    switch(variable["tipo"]){
        case "númerico":
            return {
                "status" : "VALIDACION_REQUERIDA",
                "validation" : validarValorNumerico
            };
        case "fecha":
            return {
                "status" : "VALIDACION_REQUERIDA",
                "validation" : validarValorFecha
            };        
        default:
            return {
                "status" : "TIPO_INVALIDO",
                "success" : false,
                "error" : "El tipo de variable no está registrado"
            }; 
    }
}

const validarValorFecha = (variable, valor) =>{
    try{
        let flag = true;
        const fecha = Date.parse(valor);
        for (var i = 0; i < variable["condicionesDeLaVariable"].length; i++) {
            const condicion = variable["condicionesDeLaVariable"][i];
            switch(condicion["operador"]){
                case "<=" : 
                    flag =  fecha <= (Date.now() - new Date().getTimezoneOffset()*60*1000);
                    break;
                default:
                    break;
            }
            if(!flag){
                return flag;
            }
        }
        return flag;
    }catch(e){
        return false;
    }
}

const validarValorNumerico = (variable, valor) =>{
    try{
        let isNumber = true
        let letterSpecialChar = "[a-zA-Z,]+"
        if (valor.match(letterSpecialChar))   {
            isNumber=false
        } 
        if (isNumber) {
            let flag = true;
            const numero = parseFloat(valor);
            for (var i = 0; i < variable["condicionesDeLaVariable"].length; i++) {
                const condicion = variable["condicionesDeLaVariable"][i];
                switch (condicion["operador"]) {
                    case ">":
                        flag = numero > condicion['valorTarget'];
                        break;
                    case ">=":
                        flag = numero >= condicion['valorTarget'];
                        break;
                    case "<":
                        flag = numero < condicion['valorTarget'];
                        break;
                    case "<=":
                        flag = numero <= condicion['valorTarget'];
                        break;
                    case "==":
                        flag = numero === condicion['valorTarget'];
                        break;
                    default:
                        break;
                }
                if (!flag) {
                    return flag;
                }
            }
            return flag;
        }
        else {
            return false;
        }
    }catch(e){
        return false;
    }
}