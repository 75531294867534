//Utilidades de firebase:
import { firebaseFunctions } from '../../../../firebase';

//Microservicios firebase:
const crearEmpresa = firebaseFunctions.httpsCallable('crearEmpresa');
const agregarMockupsDeParametros = firebaseFunctions.httpsCallable('agregarMockupsDeParametros');


/**
 * **Servicio**: Crear Parametrizacion
 * 
 * @param {String} dominioCorporativo - El dominio corporativo
 * 
 * @returns id del usuario registrado
 */

const servicioCrearParametrizacionEmpresa = async (data) => {
    try {
        /* ----------------- Obtener listado de imagenes en storage ----------------- */
        await agregarMockupsDeParametros(data)

    } catch (error) {
        throw error
    }
}

/**
 * **Servicio**: Crear Parametrizacion
 * 
 * @param {String} empresa.dominioCorporativo - El dominio corporativo
 * @param {String} empresa.nombre - El dominio corporativo
 * @param {String} empresa.usuariosPermitidos - El dominio corporativo
 * 
 * @returns id del usuario registrado
 */

 const servicioCrearEmpresa = async (empresa) => {
    try {
        /* ----------------- Obtener listado de imagenes en storage ----------------- */
        const response = await crearEmpresa(empresa)
        return response

    } catch (error) {
        throw error
    }
}

export { servicioCrearEmpresa, servicioCrearParametrizacionEmpresa };