import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Row, Col, Card, CardBody } from 'reactstrap';

//Componentes auxiliares:
import BotonParaSection from './BotonParaSection';

//Utilidades estáticas:
import { bgHorizontales } from '../../almacen/bancoDeImagenes.js';

//Constantes auxiliares de personalización:
const URLIconoPrincipal = '../../assets/img/iconos/iconoCampo.jpg';
const backgroundImageUrl = bgHorizontales[Math.floor(Math.random() * bgHorizontales.length)];

let EncabezadoDeSeccion = (props) => {
    return (
        <Row>
            <Col xs="12" md="12" lg="12" xl="12">
                <Card>
                    <div className="dropdown-menu-header">
                        <div className="dropdown-menu-header-inner" style={{
                            backgroundImage: "linear-gradient(to right, rgb(35, 33, 46) 0%, rgb(30, 47, 82) 40%, rgb(66, 11, 23) 100%)",
                            borderTopLeftRadius: 5, borderTopRightRadius: 5, margin: 0.25
                        }}>
                            <CardHeader className="menu-header-image opacity-4" style={{
                                backgroundImage: "url('" + backgroundImageUrl + "')",
                                backgroundPositionX: "center", backgroundPositionY: "top", backgroundSize: "cover"
                            }} />

                            <div className="menu-header-content btn-pane-right">
                                <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                                    <div className="avatar-icon rounded">
                                        <img src={props.URLIconoPrincipal || URLIconoPrincipal}
                                            alt="iconoDeSectionGIAcore" style={{ objectFit: "cover", backgroundColor: "white", }} />
                                    </div>
                                </div>

                                <div>
                                    <h4 className="menu-header-title"><b>{props.titulo}</b></h4>
                                    <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: props.subtitulo }}></p>
                                    <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: props.subtitulo2 }}></p>
                                </div>

                                <div className="btn-actions-pane-right actions-icon-btn" style={props.estiloPadreBotones}>
                                    {props.botonesAdicionales}
                                </div>
                            </div>
                        </div>
                    </div>

                    <CardBody>
                        {props.children}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

EncabezadoDeSeccion.propTypes = {
    titulo: PropTypes.string,
    subtitulo: PropTypes.string,
    URLIconoPrincipal: PropTypes.string,
    botonesAdicionales: PropTypes.arrayOf(BotonParaSection)
};

export default EncabezadoDeSeccion;