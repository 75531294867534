export const obtenerNombreDePropiedad = (key, scope) => {
    switch (key) {
        case "cArea":
        case "condicionesDeOperacionActivo":
            return "Operación del Activo";
        case "cFinanciera":
            return "Consecuencia Financiera";
        case "climaYFuerzasExternas":
            return "Clima y fuerzas externas";
        case "consecuenciaEnArea":
            return "Consecuencia en área";
        case "consecuenciaEnviron":
            return "Consecuencia ambiental";
        case "consecuenciaProd":
            return "Consecuencia por detenciones";
        case "consecuenciaInj":
            return "Consecuencia por lesiones a personas";
        case "consecuenciaCmd":
            return "Consecuencia por daño a componente";
        case "consecuenciaAFFA":
            return "Consecuencia AFFA";
        case "corrosionInterna":
            return "Corrosión interna";
        case "corrosionExterna":
            return "Corrosión externa";
        case "cui":
            return "Corrosion under insulation";
        case "danhosPorTerceros":
            return "Daños por terceros";
        case "dañosPorTerceros":
            return "Daños por terceros";
        case "defectosDeConstruccion":
            return "Defectos de construcción";
        case "defectosDeManufactura":
            return "Defectos de manufactura";
        case "fallaDeEquipos":
            return "Falla de equipos";
        case "fatiga":
            return "Fatiga mecánica";
        case "lining":
            return "Component Lining Damage Factor";
        case "operacionesIncorrectas":
            return "Operaciones incorrectas";
        case "propiedadesFisicoquimicas":
            return "Fisicoquímicas";
        case "propiedadesGenerales":
            return "Generales";
        case "propiedadesParaProbabilidad":
            return "Del Sistema";
        case "propiedadesParaElCalculoDeVelocidadDeCorrosion":
            return "Velocidad de Corrosión";
        case "nivelDeRiesgo":
            return "Nivel de Riesgo";
        case "nivelDeConsecuenciaDePersonas":
            return "Nivel de consecuencia personas";
        case "nivelDeConsecuenciaAmbiental":
            return "Nivel de consecuencia ambiental";
        case "nivelDeConsecuenciaReputacion":
            return "Nivel de consecuencia reputacion";
        case "nivelDeConsecuenciaFinanciera":
            return "Nivel de consecuencia financiera";
        case "financiera":
            return scope === "riesgoTotal" ? "Riesgo financiero" : "Consecuencia financiera";
        case "maximaCOF":
            return "Nivel de consecuencia máximo";
        case "riesgoFinanciero":
            return "Riesgo Financiero";
        case "CostInvMild":
            return "Consecuencia por perdida de Inventario (Fugas leves o moderadas)";
        case "CostInvSo":
            return "Consecuencia por perdida de Inventario (Atascamiento)";
        case "cEnvMild":
            return "Consecuencia ambiental (Fugas leves o moderadas)";
        case "cEnvSo":
            return "Consecuencia ambiental (Atascamiento)";
        case "clMild":
            return "Consecuencia Financiera (Fugas leves o moderadas)";
        case "clSo":
            return "Consecuencia Financiera (Atascamiento)";
        case "costProdMild":
            return "Consecuencia por detenciones (Fugas leves o moderadas)";
        case "costProdSo":
            return "Consecuencia por detenciones (Atascamiento)";
        case "CostSd":
            return "Costo de reparación";
        case "nivelDeConsecuenciaArea":
            return "Nivel de consecuencia en área";

        default:
            return key;
    }
}

export const obtenerDeNombreDeSetDePropiedades = (key) => {
    switch (key) {
        case "otrasPropiedades":
            return "Otras propiedades";
        case "propiedadesDeDfs":
            return "Para mecanismos de daño";
        case "propiedadesParaConsecuencias":
            return "Para consecuencia";
        default:
            return key;
    }
}