import React from 'react'
import { Link, useHistory } from 'react-router-dom';

const guardarEnlaceEnLocalStorage = (history, path) => {
    history && history.push(path);
    localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
}

function LinkConHistory({ to, children,...props }) {
    const history = useHistory()
    return (
        <Link to={to}
            onClick={() => {
                guardarEnlaceEnLocalStorage(history, to);
            }}
            onContextMenu={() => {
                guardarEnlaceEnLocalStorage(history, to);
            }}
            {...props}
            children={children}
        />
    )
}

export default LinkConHistory
