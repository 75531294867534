import { MODELO_CONSECUENCIA, VSP } from '../../vistas/aplicacion/gestion/api581Valvulas/scripts/constantes';

export const glosarioDeVariablesPRD = {
    "informacionDePRD": {
        "label": "Información de PRD",
        "informacionGeneral": {
            "TAG": {
                "key": "TAG",
                "nombre": "TAG",
                "valor": null,
                "unidades": null,
                "categoria": "valorConstante",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC
            },
            "factGeren": {
                "key": "factGeren",
                "nombre": "Factor de gerenciamiento FMS",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "estado": {
                "key": "estado",
                "nombre": "Estado",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Disponible", "Operativa", "Fuera de Servicio"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "dependencia": {
                "key": "dependencia",
                "nombre": "Dependencia",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "campo": {
                "key": "campo",
                "nombre": "Campo",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "id": {
                "key": "id",
                "nombre": "Id (placa)",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "codActivo": {
                "key": "codActivo",
                "nombre": "Código del activo",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "plantaOestacion": {
                "key": "plantaOestacion",
                "nombre": "Planta o estación",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "rsgTrgtCuant": {
                "key": "rsgTrgtCuant",
                "nombre": "Riesgo Target Cuantitativo",
                "valor": null,
                "unidades": "USD/AÑO",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "rsgTrgtCual": {
                "key": "rsgTrgtCual",
                "nombre": "Riesgo Target Cualitativo",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    'L',
                    'M',
                    'H',
                    'E',
                ],
                'modelo': MODELO_CONSECUENCIA.CUALIT,
            },
            "servOsistem": {
                "key": "servOsistem",
                "nombre": "Servicio / sistema",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "plano": {
                "key": "plano",
                "nombre": "Plano",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "descripcion": {
                "key": "descripcion",
                "nombre": "Descripción del PRD",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            }

        },
        "informacionDeDiseñoyConstruccion": {
            "tipoDeValvula": {
                "key": "tipoDeValvula",
                "nombre": "Tipo de válvula",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Convencional balanceada",
                    "Convencional resortada",
                    "Pilotada",
                    "Disco de rotura",
                    "Presión y vacío",
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "setDisenio": {
                "key": "setDisenio",
                "nombre": "Set de diseño",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "fabricante": {
                "key": "fabricante",
                "nombre": "Fabricante",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "modelo": {
                "key": "modelo",
                "nombre": "Modelo",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "serial": {
                "key": "serial",
                "nombre": "Serial",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "prdInletSize": {
                "key": "prdInletSize",
                "nombre": "Diámetro de entrada (in) (PRDomlisoze / Calculos consecuencia)",
                "valor": null,
                "unidades": "IN",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "ratingEntrada": {
                "key": "ratingEntrada",
                "nombre": "Rating de entrada",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "diametroSalida": {
                "key": "diametroSalida",
                "nombre": "Diámetro de salida",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "ratingSalida": {
                "key": "ratingSalida",
                "nombre": "Rating de Salida",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "wPrdCHora": {
                "key": "wPrdCHora",
                "nombre": "Caudal de alivio Wc",
                "valor": null,
                "unidades": "(barril/hora)",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "asientosBlandos": {
                "key": "asientosBlandos",
                "nombre": "Asientos blandos",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "tipoDeDescarga": {
                "key": "tipoDeDescarga",
                "nombre": "Tipo de descarga",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Atmosférica",
                    "Tea",
                    "Proceso cerrado(Drenaje,recirculación)",
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "tipoOperacion": {
                "key": "tipoOperacion",
                "nombre": "Tipo de operación",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Contínua", "Intermitente"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
        },
        "informacionDeFluidoSeveridadTipo": {
            "severidadFluidoApertura": {
                "key": "severidadFluidoApertura",
                "nombre": "Severidad del fluido-Apertura (Use Tabla 7.4 API 581-2016)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Leve", "Moderada", "Severa"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "severidadFluidoFuga": {
                "key": "severidadFluidoFuga",
                "nombre": "Severidad del fluido-Fuga (Use Tabla 7.11 API 581-2016)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Leve", "Moderada", "Severa"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "fluidoRepresentativo": {
                "key": "fluidoRepresentativo",
                "nombre": "Tipo de Fluido API 581 (Tabla 4.1-4.2)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "C1-C2",
                    "C3-C4",
                    "C5",
                    "C6-C8",
                    "C9-C12",
                    "C13-C16",
                    "C17-C25",
                    "C25+",
                    "Water",
                    "Steam",
                    "Acid",
                    "H2",
                    "H 2 S",
                    "HF",
                    "CO",
                    "DEE",
                    "HCL",
                    "Nitric Acid",
                    "ALCL3",
                    "NO 2",
                    "Phosgene",
                    "TDI",
                    "Methanol",
                    "PO",
                    "Styrene (Aromatic)",
                    "EEA",
                    "EE",
                    "EG",
                    "EO",
                    "Pyrophoric",
                    "Ammonia",
                    "Chlorine",
                ],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "faseDelFluidoAlmacenado": {
                "key": "faseDelFluidoAlmacenado",
                "nombre": "Fase del fluido almacenado",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Líquido", "Gas"],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "evaluarComo": {
                "key": "evaluarComo",
                "nombre": "Estado de fase estacionario del fluido después de fuga",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Evaluar como Liquido",
                    " Evaluar como Gas",
                ],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "esAcidoOCaustico": {
                "key": "esAcidoOCaustico",
                "nombre": "Es ácido / cáustico",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "servicio": {
                "key": "servicio",
                "nombre": "Servicio",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    'Servicios típicos, fluidos corrosivos',
                    'Servicios limpios (no presentan fouling), fluidos no corrosivos',
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "tipoDeFluido": {
                "key": "tipoDeFluido",
                "nombre": "Descripción del tipo de fluido",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
            },
        },
        "informacionDeLaInspeccion": {
            "fechaDeInstalacion": {
                "key": "fechaDeInstalacion",
                "nombre": "Fecha de la instalación",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "fecha",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "fechaDeValoracionRBI": {
                "key": "fechaDeValoracionRBI",
                "nombre": "Fecha de valoración RBI",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "fecha",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "setCalibracion": {
                "key": "setCalibracion",
                "nombre": "Set de calibración",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "fechaUltimaInspeccion": {
                "key": "fechaUltimaInspeccion",
                "nombre": "Fecha de la ultima inspección",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "fecha",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "tipoDePrueba": {
                "key": "tipoDePrueba",
                "nombre": "Tipo de prueba",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    'En Banco',
                    'In situ',
                    'Sin inspeccion / Sin prueba desde su instalación',
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "tInsp": {
                "key": "tInsp",
                "nombre": "Tiempo desde la última prueba / inspección (Calculado)",
                "valor": null,
                "unidades": "años",
                "categoria": "valorConstante",
                "tipo": "fecha",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "efectInsp": {
                "key": "efectInsp",
                "nombre": "Efectividad de la inspección",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Altamente efectiva",
                    "Usualmente efectiva",
                    "Pobremente efectiva",
                    "Inefectiva o sin inspección",
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "resultPruebPresion": {
                "key": "resultPruebPresion",
                "nombre": "Resultado de la prueba de presión",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Aprobó", "Falló"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "resultPruebHermet": {
                "key": "resultPruebHermet",
                "nombre": "Resultado de la prueba de hermeticidad",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["Aprobó", "Falló"],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "ntHallR": {
                "key": "ntHallR",
                "nombre": "Notas / Hallazgos resultado inspección",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "fechaUlMant": {
                "key": "fechaUlMant",
                "nombre": "Fecha de ultimo mantenimiento",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "fecha",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "mantenimiento": {
                "key": "mantenimiento",
                "nombre": "Mantenimiento",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "fecha",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "ntHallMant": {
                "key": "ntHallMant",
                "nombre": "Notas / hallazgos de mantenimiento",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
        },
        "historicosDeActuacion": {
            "chatter": {
                "key": "chatter",
                "nombre": "Chatter",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "SI",
                    "NO",
                    "Sin información"
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "disparos": {
                "key": "disparos",
                "nombre": "Disparos ¿Más de 5 disparos en un año?",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "SI",
                    "NO",
                    "Sin información"
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "vibra": {
                "key": "vibra",
                "nombre": "Vibración ¿bombas de desplazamiento positivo aguas abajo?",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "SI",
                    "NO",
                    "Sin información"
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            }
        }
    },
    "informacionEquipoProtegido": {
        "label": "Información del equipo protegido",
        "informacionGeneral": {
            "TAGEquipoProtegido": {
                "key": "TAGEquipoProtegido",
                "nombre": "TAG del equipo protegido",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "tipoDeEquipo": {
                "key": "tipoDeEquipo",
                "nombre": "Tipo de equipo API 581 (Calculado)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "valorConstante",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Compressor",
                    "Heat exchanger",
                    "Pipe",
                    "Pump",
                    "Tank",
                    "Vessel/FinFan"
                ],
            },
            "tipoCompAPI581": {
                "key": "tipoCompAPI581",
                "nombre": "Tipo de componente API 581",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "COMPC",
                    "COMPR",
                    "HEXSS",
                    "HEXTS",
                    "PIPE-1",
                    "PIPE-2",
                    "PIPE-4",
                    "PIPE-6",
                    "PIPE-8",
                    "PIPE-10",
                    "PIPE-12",
                    "PIPE-16",
                    "PIPEGT16",
                    "PUMP2S",
                    "PUMP1S",
                    "PUMPR",
                    "TANKBOTTOM",
                    "COURSE-1",
                    "COURSE-2",
                    "COURSE-3",
                    "COURSE-4",
                    "COURSE-5",
                    "COURSE-6",
                    "COURSE-7",
                    "COURSE-8",
                    "COURSE-9",
                    "COURSE-10",
                    "KODRUM",
                    "DRUM",
                    "FINFAN",
                    "FILTER",
                    "REACTOR",
                    "COLTOP",
                    "COLMID",
                    "COLBTM",
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "temperaturaOperacion": {
                "key": "temperaturaOperacion",
                "nombre": "Temperatura de la operación",
                "valor": null,
                "unidades": "ºf",
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "presionOperacion": {
                "key": "presionOperacion",
                "nombre": "Presión de operación",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "maxPresTrabPermitida": {
                "key": "maxPresTrabPermitida",
                "nombre": "MAWP",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "diametroDeActivo": {
                "key": "diametroDeActivo",
                "nombre": "Diámetro del activo",
                "valor": null,
                "unidades": "IN",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "longitud": {
                "key": "longitud",
                "nombre": "Longitud (en equipo protegido - requerido para tanques)",
                "valor": null,
                "unidades": "IN",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "mopPsi": {
                "key": "mopPsi",
                "nombre": "MOP",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
        },
        "probabilidadDeFallaCondicionesOperacionalesNormales": {
            "tieneRBI": {
                "key": "tieneRBI",
                "nombre": "¿El equipo tiene RBI?",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "SI", "NO"
                ],
                'modelo': MODELO_CONSECUENCIA.SyC,
            },
            "DF": {
                "key": "DF",
                "nombre": "Factor de daño total DF",
                "tooltip": "Valor utilizado para cálculos",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "pofc": {
                "key": "pofc",
                "nombre": "Probabilidad de falla cuantitativa",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": []
            },
            "fogeFMS": {
                "key": "fogeFMS",
                "nombre": "Factor de gerenciamiento empleado FMS",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "claseDF": {
                "key": "claseDF",
                "nombre": "Clase DF*",
                "tooltip": "Si se modifica no se verán afectados los cálculos (modificar en cambio el factor de daño)",
                "valueTooltip": "Si se modifica no se verán afectados los cálculos (modificar en cambio el factor de daño)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Ninguno",
                    "Mínimo",
                    "Menor",
                    "Moderado",
                    "Severo",
                ]
            },
            "descripcionDF": {
                "key": "descripcionDF",
                "nombre": "Descripción DF*",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tooltip": "Si se modifica no se verán afectados los cálculos (modificar en cambio el factor de daño)",
                "valueTooltip": "Si se modifica no se verán afectados los cálculos (modificar en cambio el factor de daño)",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Equipo nuevo ó la inspección muestra poco o ningún daño",
                    "El equipo ha estado en servicio durante un período de tiempo razonable y la inspección muestra evidencia de daños menores. Se han identificado los mecanismos de daño y se dispone de datos de inspección.",
                    "Se han identificado uno o más mecanismos de daño, se dispone de datos de inspección limitados y evidencia de daño moderada",
                    "Un único mecanismo de daño identificado, e inspección reciente indica evidencia moderada de daño.",
                    "Se encontró daño moderado durante una inspección reciente.",
                    "Baja susceptibilidad a uno o más mecanismos de daño y existe una inspección limitada",
                    "Uno o más mecanismos de daño activos sin ningún historial de inspección reciente.",
                    "Inspección limitada indica alta susceptibilidad de daño.",
                ],
            },
        },
        "eventosDeSobrepresion": {
            "fuego": {
                "key": "fuego",
                "nombre": "Fuego",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento': VSP,
            },
            "poePSI": {
                "key": "poePSI",
                "nombre": "Pérdida de enfriamiento",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "fsencPSI": {
                "key": "fsencPSI",
                "nombre": "Falla del suministro de energía eléctrica",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "ccaPSI": {
                "key": "ccaPSI",
                "nombre": "Descarga bloqueada (Válvula manual) con controles administrativos",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "dbVL": {
                "key": "dbVL",
                "nombre": "Descarga bloqueada (Válvula manual) sin controles administrativos",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "faVcoEvDeLAmimaDirtiPosicN": {
                "key": "faVcoEvDeLAmimaDirtiPosicN",
                "nombre": "Falla de la válvula de control, El evento de inicio es la misma dirección que la posición de falla normal (A prueba de Fallas)",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "faVcoEvDeInicioDirtOp": {
                "key": "faVcoEvDeInicioDirtOp",
                "nombre": "Falla de la válvula de control, el evento de inicio es en dirección opuesta a la posición de falla normal (Falla en sentido opuesto)",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "ReacQuiFcPSI": {
                "key": "ReacQuiFcPSI",
                "nombre": "Reacción química fuera de control",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "RupTInterC": {
                "key": "RupTInterC",
                "nombre": "Ruptura de tubo de intercambiador de calor",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "FallBomRefriSisPSI": {
                "key": "FallBomRefriSisPSI",
                "nombre": "Falla en bombas de circulación de refrigerante o sistemas de enfriamiento",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "ApEthCcadm": {
                "key": "ApEthCcadm",
                "nombre": "Alivio por expansión térmica / hidráulica con controles administrativos",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "ApEthScadm": {
                "key": "ApEthScadm",
                "nombre": "Alivio por expansión térmica / hidráulica sin controles administrativos",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "SlSadmin": {
                "key": "SlSadmin",
                "nombre": "Sobrellenado de líquidos con controles administrativos",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
            "SlCadmin": {
                "key": "SlCadmin",
                "nombre": "Sobrellenado de líquidos sin controles administrativos",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'evento':VSP,
            },
        }
    },
    "informacionCalculosDeConsecuenciaCuantitativa": {
        "label": "Información de calculos de consecuencia cuantitativa",
        "informacionGeneral": {
            "potencialDeSobrepresion": {
                "key": "potencialDeSobrepresion",
                "nombre": "Presión para cálculo consecuencia (Calculado)",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorConstante",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "descripcionAPV": {
                "key": "descripcionAPV",
                "nombre": "Describa brevemente el evento desencadenado por la no apertura de la válvula",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "descripcionFUV": {
                "key": "descripcionFUV",
                "nombre": "Describa brevemente el evento desencadenado por fuga de la válvula",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "pAtm": {
                "key": "pAtm",
                "nombre": "Presión atmosférica",
                "valor": null,
                "unidades": "PSI",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "costDayShutDown": {
                "key": "costDayShutDown",
                "nombre": "Costo por interrupción ó lucro cesante",
                "valor": null,
                "unidades": "$/día",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo': MODELO_CONSECUENCIA.SEMICUANT,
            },
            "densidadDePoblacion": {
                "key": "densidadDePoblacion",
                "nombre": "Densidad de la población",
                "valor": null,
                "unidades": "personas / ft2",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "injCosto": {
                "key": "injCosto",
                "nombre": "Costos por lesiones graves o muerte",
                "valor": null,
                "unidades": "$",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "costAmbiental": {
                "key": "costAmbiental",
                "nombre": "Costo de remediación ambiental.",
                "valor": null,
                "unidades": "$/barril",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "costFlu": {
                "key": "costFlu",
                "nombre": "Costo por barril de fluido (valor del fluido)",
                "valor": null,
                "unidades": "$/Barril",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "CostSd": {
                "key": "CostSd",
                "nombre": "Costo de mantenimiento y reparación PRD",
                "valor": null,
                "unidades": "$",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "SrSDdEprV": {
                "key": "SrSDdEprV",
                "nombre": "¿Se requiere shut down del equipo para reparar la válvula?",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
        },
        "equipoProtegido": {
            "daysShutDown": {
                "key": "daysShutDown",
                "nombre": "Número de días de shut down del equipo para reparar el PRD",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "volumenDeComponente": {
                "key": "volumenDeComponente",
                "nombre": "Volumen del componente",
                "valor": null,
                "unidades": "BBL",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "grupoDeInventario": {
                "key": "grupoDeInventario",
                "nombre": "Grupo de inventario",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "sistemaDeDeteccion": {
                "key": "sistemaDeDeteccion",
                "nombre": "Tipo de sistema de detección",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "A).Instrumentation designed specifically to detect material losses by changes in operating conditions",
                    "B).Suitably located detector to determine when the material is present outside the pressure-coating envelope",
                    " C).Visual detection, cameras or detectors with marginal coverage",
                ],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "sistemaDeAislamiento": {
                "key": "sistemaDeAislamiento",
                "nombre": "Tipo de sistema de aislamiento",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "A).Isolation or shutdown systems activated directly from process instrumentation or detectors, with no operator intervention",
                    "B).Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak",
                    "C).Isolation depent on manually operated valves",
                ],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "sistemaDeMitigacion": {
                "key": "sistemaDeMitigacion",
                "nombre": "Sistema de mitigación",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Inventory blowdown, coupled with isolation systemhigher  classification B or",
                    "Fire water deluge system and monitors",
                    "Fire water monitors only",
                    "Foam spray system",
                ],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "tipoDeMaterial": {
                "key": "tipoDeMaterial",
                "nombre": "Tipo de material",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Carbon Steel",
                    "1.25Cr-0.5Mo",
                    "2.25Cr-1Mo",
                    "5Cr-0.5Mo",
                    "7Cr-0.5Mo",
                    "Clad 304 SS",
                    "Polypropylene Lined (pp)",
                    "9Cr-1Mo",
                    "405SS",
                    "410SS",
                    "304SS",
                    "Clad316 SS",
                    "CS“Saran” Lined",
                    "CSRubber Lined",
                    "316SS",
                    "CSGlass Lined",
                    "CladAlloy 400",
                    "90/10Cu/Ni",
                    "CladAlloy",
                    "CS“Teflon” Lined",
                    "CladNickel",
                    "Alloy800",
                    "70/30Cu/Ni",
                    "904L",
                    "Alloy20",
                    "Alloy400",
                    "Alloy600",
                    "Nickel",
                    "Alloy 625",
                    "Titanium",
                    "Alloy“C”",
                    "Zirconium",
                    "Alloy“B”",
                    "Tantalum",
                    "Acero al carbono",
                    "1.25Cr-0.5Mo",
                    "2.25Cr-1Mo",
                    "5Cr-0.5Mo",
                    "7Cr-0.5Mo",
                    "Clad 304 SS",
                    "Polypropylene Lined (pp)",
                    "9Cr-1Mo",
                    "405SS",
                    "410SS",
                    "304SS",
                    "Clad316 SS",
                    "CS“Saran” Lined",
                    "CSRubber Lined",
                    "316SS",
                    "CSGlass Lined",
                    "CladAlloy 400",
                    "90/10Cu/Ni",
                    "CladAlloy",
                    "CS“Teflon” Lined",
                    "CladNickel",
                    "Alloy800",
                    "70/30Cu/Ni",
                    "904L",
                    "Alloy20",
                    "Alloy400",
                    "Alloy600",
                    "Nickel",
                    "Alloy 625",
                    "Titanium",
                    "Alloy“C”",
                    "Zirconium",
                    "Alloy“B”",
                    "Tantalum",
                ],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "materialDeConstruccion": {
                "key": "materialDeConstruccion",
                "nombre": "Material de construcción",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "A134",
                    "A672 A45",
                    "API 5L A25",
                    "A53 A",
                    "A139 A",
                    "A106 A",
                    "A135 A",
                    "A369 FPA",
                    "API 5L A",
                    "A134",
                    "A672 A50",
                    " A671 ca55",
                    "A672 A55",
                    "A672 C55",
                    "A671 CC60",
                    "A671 CB60",
                    "A672 B60",
                    "A672 C60",
                    "A139 B",
                    "A135 B",
                    "A53 B",
                    "A106 B",
                    "A381 Y35",
                    "API 5L B",
                ],
            },
            "equipCost": {
                "key": "equipCost",
                "nombre": "Equipcost",
                "valor": null,
                "unidades": "$/ft2",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "outageMult": {
                "key": "outageMult",
                "nombre": "Outage mult factor multiplicador de parada",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "sensibilidadAmbiental": {
                "key": "sensibilidadAmbiental",
                "nombre": "Sensibilidad ambiental (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Baja",
                    "Media",
                    "Alta",
                ],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "plvDique": {
                "key": "plvDique",
                "nombre": "% fluido que deja el dique. P,lv,dike (0-100) (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "pOnSite": {
                "key": "pOnSite",
                "nombre": "% fluido que deja el dique P,onsite (0-100) (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "pOffSite": {
                "key": "pOffSite",
                "nombre": "P,offsite (0-100) (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "tipoDeSuelo": {
                "key": "tipoDeSuelo",
                "nombre": "Tipo de suelo (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Coarse Sand (arena gruesa)",
                    "Fine Sand (arena fina)",
                    "Very Fine Sand (arena muy fina)",
                    "Silt (Limo)",
                    "Sandy Clay (arena arcillosa)",
                    "Clay (arcilla)",
                    "Concrete-Asphalt (asfalto-concreto)",
                    "Gravel (gravilla)",
                ],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "barreraDePrevencion": {
                "key": "barreraDePrevencion",
                "nombre": "Tiene barrera de protección en el fondo del tanque (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
            "sGW": {
                "key": "sGW",
                "nombre": "Distancia a agua subterranea (para tanques almacenamiento AST)",
                "valor": null,
                "unidades": "ft",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.SEMICUANT,
            },
        }
    },
    "informacionCalculosDeConsecuenciaCualitativa": {
        "label": "Información de calculos de consecuencia cualitativa",
        "apertura": {
            "costoReparacionPRDAF": {
                "key": "costoReparacionPRDAF",
                "nombre": "Costo de reparación PRD (apertura)",
                "valor": null,
                "unidades": "USD",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "descripcionAF": {
                "key": "descripcionAF",
                "nombre": "Describa brevemente el evento desencadenado por la no apertura de la válvula",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "causaParadaAF": {
                "key": "causaParadaAF",
                "nombre": "Causa parada de planta - apertura",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "tiempoTotalDeParadaAF": {
                "key": "tiempoTotalDeParadaAF",
                "nombre": "Tiempo total de parada (incluye tiempo detección de fuga, tiempo de alistamiento y tiempo de reparación y puesta en marcha del equipo)",
                "valor": null,
                "unidades": "horas",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "lucroCesanteAF": {
                "key": "lucroCesanteAF",
                "nombre": "Lucro cesante",
                "valor": null,
                "unidades": "USD/DÍA",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "costoReparacionEquipoProtegidoAF": {
                "key": "costoReparacionEquipoProtegidoAF",
                "nombre": "Costo de reparación del equipo protegido",
                "valor": null,
                "unidades": "USD",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "cofTipoPersonasApertura": {
                "key": "cofTipoPersonasApertura",
                "nombre": "Cof Personas - Apertura",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Una o más fatalidades",
                    "Incapacidad Permanente (Total o Parcial)",
                    "Incapacidad Temporal",
                    "Lesión Leve (sin incapacidad)",
                    "Sin lesiones",
                ],
            },
            "cofTipoReputacionApertura": {
                "key": "cofTipoReputacionApertura",
                "nombre": "Cof Reputación - Apertura",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Presencia en medios internacionales con cubrimiento inmediato y directo y de alta importancia.",
                    "Presencia en  medios masivos de comunicación nacionales.",
                    "Presencia en  medios masivos de comunicación regionales; causando situaciones que requieren acciones correctivas por parte de la compañía.",
                    "Mención en Medios Locales: Mención adversa por parte de medios de comunicación locales.",
                    "Ningún impacto",
                ],
            },
            "cofTipoAmbientalApertura": {
                "key": "cofTipoAmbientalApertura",
                "nombre": "Cof Ambiental - Apertura",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    'Afectación mayor: Liberación de producto con afectación en cuerpos de agua, cultivos o centros poblados.',
                    'Afectación importante: Liberación de producto con afectación de área fuera de los límites de las facilidades o terrenos de Geopark.',
                    'Efectos Localizados: Liberación de producto con afectación de área entre 11 - 50 m² (dentro de terrenos de Geopark).',
                    'Efectos Menores: Liberación de producto con afectación de área menor a 10 m² (dentro de terrenos de Geopark).',
                    'Sin impacto ambiental.',
                ],
            },
        },
        "fuga": {
            "costoReparacionPRDFF": {
                "key": "costoReparacionPRDFF",
                "nombre": "Costo de reparación PRD (fuga)",
                "valor": null,
                "unidades": "USD",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "descripcionFF": {
                "key": "descripcionFF",
                "nombre": "Describa brevemente el evento desencadenado por fuga de la válvula (especifique si tiene pérdida de producto o este recircula nuevamente al proceso)",
                "valor": null,
                "unidades": null,
                "categoria": "valorÚnico",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
            },
            "causaParadaFF": {
                "key": "causaParadaFF",
                "nombre": "Causa parada de planta - fuga",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": ["SI", "NO"],
            },
            "tiempoTotalDeParadaFF": {
                "key": "tiempoTotalDeParadaFF",
                "nombre": "Tiempo de detección de pase en la PRD",
                "valor": null,
                "unidades": "horas",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "productoPerdidoDiarioFF": {
                "key": "productoPerdidoDiarioFF",
                "nombre": "Producto perdido por día",
                "valor": null,
                "unidades": "Barril/Día",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "costFluidoFF": {
                "key": "costFluidoFF",
                "nombre": "Valor del fluido",
                "valor": null,
                "unidades": "Usd/Barril",
                "categoria": "valorÚnico",
                "tipo": "númerico",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "cofTipoPersonasFuga": {
                "key": "cofTipoPersonasFuga",
                "nombre": "Cof Personas - Fuga",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Una o más fatalidades",
                    "Incapacidad Permanente (Total o Parcial)",
                    "Incapacidad Temporal",
                    "Lesión Leve (sin incapacidad)",
                    "Sin lesiones",
                ],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "cofTipoReputacionFuga": {
                "key": "cofTipoReputacionFuga",
                "nombre": "Cof Reputación - Fuga",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    "Presencia en medios internacionales con cubrimiento inmediato y directo y de alta importancia.",
                    "Presencia en  medios masivos de comunicación nacionales.",
                    "Presencia en  medios masivos de comunicación regionales; causando situaciones que requieren acciones correctivas por parte de la compañía.",
                    "Mención en Medios Locales: Mención adversa por parte de medios de comunicación locales.",
                    "Ningún impacto",
                ],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
            "cofTipoAmbientalFuga": {
                "key": "cofTipoAmbientalFuga",
                "nombre": "Cof Ambiental - Fuga",
                "valor": null,
                "unidades": null,
                "categoria": "valorDeSeleccionMultiple",
                "tipo": "texto",
                "condicionesDeLaVariable": [],
                "listaDeOpciones": [
                    'Afectación mayor: Liberación de producto con afectación en cuerpos de agua, cultivos o centros poblados.',
                    'Afectación importante: Liberación de producto con afectación de área fuera de los límites de las facilidades o terrenos de Geopark.',
                    'Efectos Localizados: Liberación de producto con afectación de área entre 11 - 50 m² (dentro de terrenos de Geopark).',
                    'Efectos Menores: Liberación de producto con afectación de área menor a 10 m² (dentro de terrenos de Geopark).',
                    'Sin impacto ambiental.',
                ],
                'modelo':MODELO_CONSECUENCIA.CUALIT,
            },
        }
    }
}