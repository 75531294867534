import React from 'react'
import MUIDataTable from 'mui-datatables'
import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import CustomTableFooter from './customComponents/Footer'
import CustomResize from './customComponents/Resize'
import BodyLoadingComponent from './customComponents/BodyLoadingComponent';
import DescargaTablaInsepccion from '../../../../formatoAnomalias/GestorOrdenesDeTrabajo/components/DescargaTablaInsepccion'
import findIndex from 'lodash/findIndex';

// POSIBLE CUSTOM : https://material-ui.com/es/components/data-grid/rendering/
// ALL COMPONENTES : https://github.com/gregnb/mui-datatables/tree/master/src/components
// MODIFICAR TEXT LABELS : https://github.com/gregnb/mui-datatables#localization
// ALL EJEMPLOS: https://codesandbox.io/s/github/gregnb/mui-datatables?file=/src/index.js

const defaultComponents = {
    TableFooter: CustomTableFooter,
    TableResize: CustomResize,
}

const useStyles = makeStyles((theme)=>({
    styleNoMatch:{
        position: "relative",
        zIndex: 1000
    },
    responsiveTitle:{
        [theme.breakpoints.down("sm")]:{
            justifyContent:"center"
        }
    }
}))

function CustomMUIDataTable({
    customOnDownload,
    keyFindOnDownload,
    noMatchComponent,
    cargandoEnTitulo,
    cargandoEnBody,
    title,
    options,
    loading,
    components,
    ...props }) {
    const classes = useStyles();
    const defaultOptions = {
        enableNestedDataAccess: '.', // EN CASO DE NECESITAR UN PATH PARA EL VALOR DE LA VARIABLE EN LA DATA
        selectableRows: "none",

        resizableColumns: true, //!loading
        draggableColumns: { enabled: true },
        responsive: "standard",
        /* fixedHeader: true, */
        /* tableBodyHeight: '400px', */ // PARA ASIGNAR tamaño fijo del body
        tableBodyMaxHeight: "50vh",
        /* rowsPerPage: 9, */
        filter: true,
        search: true,
        print: false,
        download: false,
        viewColumns: true,
        customToolbar: props.download ? () => {
            return (
                <DescargaTablaInsepccion/>
                
            );
        } : null,
        elevation: 0,
        denseTable: true,
        textLabels: {
            body: {
                noMatch: noMatchComponent || (loading && cargandoEnBody ? <BodyLoadingComponent className={classes.styleNoMatch} {...cargandoEnBody} /> : <div className={classes.styleNoMatch} >Sin información para mostrar.</div>),
                toolTip: "Ordenar",
            },
            pagination: {
                next: "Siguiente Página",
                previous: "Anterior",
                rowsPerPage: "Filas por páginas:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "BORRAR",
            },
            viewColumns: {
                title: "Ver Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "Fila(s) seleccionada(s)",
                delete: "Borrar",
                deleteAria: "Borrar Filas Seleccionadas",
            },
        },
        downloadOptions: {
            filterOptions: {
                useDisplayedRowsOnly: true,
            }
        },
        setTableProps: () => {
            return {
                // material ui v4 only, */
                size: 'small',
            };
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            const descargarIDsPRD = getArrayDataByColumn(columns, data, keyFindOnDownload);
            customOnDownload(descargarIDsPRD, columns, data);
            return false;
        }
    }

    return (
        <MUIDataTable
            components={{
                ...defaultComponents,
                ...components
            }}
            options={{
                ...defaultOptions,
                ...options
            }}
            title={ //RECIBE TAMBIÉN UN COMPONENTE PERSONALIZADO
                <Grid container direction="row" alignItems="center" className={classes.responsiveTitle}>
                    {typeof title === "string" ? <Typography variant="h6">
                        {title}
                    </Typography>
                        : title}
                    {cargandoEnTitulo && loading && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
                </Grid>
            }
            {...props}
        />
    )
}

export default CustomMUIDataTable

export const getArrayDataByColumn = (columns, data, keyColumn = "id") => {
    const indexID = findIndex(columns, { 'name': keyColumn });
    if (indexID === -1) return [];
    return data.map((row) => (row.data[indexID]))
}