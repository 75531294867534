import React from 'react';
import { Card, ListGroup, ListGroupItem, CardHeader, Button } from 'reactstrap';

//Imports auxiliares
import rolesDeUsuarios from '../scripts/parametrizacionRoles';
import PerfectScrollbar from 'react-perfect-scrollbar';

let defaultAvatar = "/assets/img/iconos/avatar.webp";

const ListaUsuariosPermitidos = (props) => {
    return (
        <Card className="main-card mb-0 shadow-sm border border-light">
            <CardHeader style={{fontSize: 18, backgroundColor: '#F8F9F9'}}>Usuarios existentes</CardHeader>
            <div className="scroll-area">
                <PerfectScrollbar>
                    <ListGroup className="todo-list-wrapper" flush>
                        {obtenerRenderDeTarjetasUsuario(props)}
                    </ListGroup>
                </PerfectScrollbar>
            </div>

        </Card>
    );
}

const obtenerRenderDeTarjetasUsuario = (props) =>{
    if(props.usuarios){
        return props.usuarios.map((usuario, index) =>{
            return(
                <ListGroupItem className='py-1' key={'permitido'+ usuario.correo + index}>
                    <div className="widget-content p-0">
                    <div className="todo-indicator d-flex align-items-center" style={{height: 50, width: 5, backgroundColor: rolesDeUsuarios[usuario.rol]? rolesDeUsuarios[usuario.rol].color : '--'}}/>
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                                <div className="widget-content-left">
                                    <img width={60} className="rounded" src={usuario.fotoDePerfil? usuario.fotoDePerfil.url : defaultAvatar} alt="" />
                                </div>
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading" style={{fontSize: 17}}>
                                    {usuario.nombre}
                                </div>
                                <div className="widget-subheading" style={{color: '#273746', fontWeight: 'bold'}}>
                                    {rolesDeUsuarios[usuario.rol]? rolesDeUsuarios[usuario.rol].titulo : '--'}
                                </div>
                                <div className="widget-subheading">
                                    {usuario.correo}
                                </div>
                            </div>
                            <div className="widget-content-right">
                                <Button size="lg" color="success" onClick={() => props.verDetalle(usuario)}>Ver</Button>
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            );
        })
    }
}

export default ListaUsuariosPermitidos;