import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';


//JsZip
import JSZip from "jszip";
import saveAs from "file-saver";

//Icons:
import { RiFileExcel2Line } from 'react-icons/ri';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FaRegImages } from 'react-icons/fa';

//Componentes auxiliares:
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal';
import CustomLoadingOverlay from '../../../../../utilidades/componentes/plantillaBase/CustomLoadingOverlay';
import { dispararAlertExitoso, dispararAlertFallido } from '../../../../../utilidades/Swal/Swal';

//Services
import { servicioGenerarInformeFinal, servicioDescargarImagenes } from '../../services/paraGenerarInformes'

const ConsolaDeDescarga = (props) => {
  const rolDeUsuario = props.usuarioActivo.rol

  const [ loading, setLoading ] = useState(false)

  /* ----------------------------- Logic functions ---------------------------- */
  const descargarOrdenDeTrabajo = async (formato) => {
    setLoading(true)

    const data = {
      ordenDeTrabajoHija: props.ordenDeInspeccion,
      formato: formato
    }

    try {
      const downloadUrl = await servicioGenerarInformeFinal(data)

      if (props.elModalEstaAbierto) {
        props.toggleDelModal();
        dispararAlertExitoso('Descarga exitosa', 'Revisa las ventanas emergentes');
      }

      window.open(downloadUrl);
    }
    catch (error) {
      if (props.elModalEstaAbierto) {
        props.toggleDelModal();
      }

      dispararAlertFallido(
        'Descarga fallida', 
        error.message || 'El proceso de exportación no pudo finalizar correctamente'
      );
    } finally {
      setLoading(false)
    }
  }

  const descargarImagenes = async () => {
    try {
      setLoading(true)

      const listadoDeImagenes = await servicioDescargarImagenes(props.ordenDeInspeccion)

      /* --------------------------- Generar archivo ZIP -------------------------- */
      await generarArchivoZip(listadoDeImagenes, props.ordenDeInspeccion);

      if (props.elModalEstaAbierto) {
        props.toggleDelModal();
      }

      dispararAlertExitoso(
        'Descarga exitosa',
        'Revisa las ventanas emergentes'
      );

    } catch (error) {
      if (props.elModalEstaAbierto) {
        props.toggleDelModal();
      }

      dispararAlertFallido(
        'Descarga fallida',
        error.message || 'El proceso de exportación no pudo finalizar correctamente'
      );
    } finally {
      setLoading(false)
    }
  }

  /* ---------------------------- Render functions ---------------------------- */
  const obtenerBotonExcel = () => {    
    if(!['admin', 'supervisor', 'tecnico'].includes(rolDeUsuario)) return 

    return (
      <Col sm={12} lg={6}>
        <Button
          className="btn-wide pusheable-button w-100"
          style={{ marginBottom: 20, backgroundColor: '#138D75' }}
          onClick={() => descargarOrdenDeTrabajo("EXCEL")}
        >
          <RiFileExcel2Line className='mr-3' size={18} />
        Excel
      </Button>
      </Col>
    )
  }
  
  const obtenerBotonPDF = () => {
    if(!['admin', 'supervisor', 'tecnico', 'cliente'].includes(rolDeUsuario)) return 
    
    return (
      <Col sm={12} lg={ rolDeUsuario === 'cliente' ? 12 : 6 }>
        <Button
          className="btn-wide pusheable-button w-100"
          style={{ marginBottom: 20, backgroundColor: '#CB4335' }}
          onClick={() => descargarOrdenDeTrabajo("PDF")}
        >
          <AiOutlineFilePdf className='mr-3' size={18} />
        PDF
      </Button>
      </Col>
    )
  }

  const obtenerBotonImagenes = () => {
    if(!['admin', 'supervisor', 'tecnico'].includes(rolDeUsuario)) return 

    return (
      <>
        <p><b> Descargar fotografías</b></p>
        <Row>
          <Col>
            <Button
              className="btn-wide pusheable-button w-100"
              style={{ marginBottom: 20, backgroundColor: '#3498DB' }}
              color="success"
              onClick={descargarImagenes}
            >
              <FaRegImages className='mr-3' size={18} />
              Archivo zip
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <CustomModal
      elModalEstaAbierto={props.elModalEstaAbierto}
      hideFooter={true}
      size="md"
      titulo={
        <span className='h5 font-weight-bold'>Consola de descarga</span>
      }
      toggleDelModal={props.toggleDelModal}
    >
      <Row
        style={{
          padding: 15,
          fontSize: 16
        }}
      >
        <Col xs="12" md="12" lg="12" xl="12">
            <div className="widget-heading">
              <p>
                Órden de Trabajo: <b>{props.ordenDeInspeccion}</b>
              </p>
            </div>
            <div
              className="widget-subheading text-justify"
              style={{
                opacity: 1
              }}
            >
              Selecciona una opción para iniciar el proceso. Este puede tardar algunos segundos
            </div>
            <hr />
            <CustomLoadingOverlay
              active={loading}
            >
              <p><b> Descargar informe</b></p>
              <Row>
                {obtenerBotonExcel()}
                {obtenerBotonPDF()}
              </Row>
              {obtenerBotonImagenes()}
            </CustomLoadingOverlay>
        </Col>
      </Row>
    </CustomModal>
  )
}

/* ------------ Funciones auxiliares servicio Descargar Imagenes ------------ */
/**
 * Nombre: generarArchivoZip
 * 
 * @param {Array<Object>} listaDeUrl - Nombre de la imagen 
 * @param {String} ordenDeTrabajoHija - Enlace de descarga de la imagen 
 */
const generarArchivoZip = async (listaDeUrl, ordenDeTrabajoHija) => {
  try {
    const jszip = new JSZip();

    const descargarImagenes = listaDeUrl.map(url => obtenerDataDeLaImagen(url))

    const respuestasDeImagenes = await Promise.all(descargarImagenes)

    respuestasDeImagenes.forEach(imagen => {
      jszip.file(
        imagen.nombreDeLaImagen,
        imagen.dataDeLaImagen,
        { base64: true }
      );
    });

    const nombreDeLArchivo = `OT_${ordenDeTrabajoHija}_imagenes.zip`

    const content = await jszip.generateAsync({ type: "blob" })

    await saveAs(content, nombreDeLArchivo);

  } catch (error) {
    throw error
  }
}

/**
 * Nombre: obtenerDataDeLaImagen
 * 
 * @param {String} key - Nombre de la imagen 
 * @param {String} url - Enlace de descarga de la imagen 
 * @returns {Promise<Object>} - Objecto que contiene el nombre y los datos de la imagen
 */
const obtenerDataDeLaImagen = ({ key, url }) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = function (evt) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve({
            nombreDeLaImagen: key,
            dataDeLaImagen: xhr.response
          });
        } else {
          reject(`Error  al descargar ${key}:  ${xhr.status}`);
        }
      }
    };
    xhr.send();
  });
}

const mapStateToProps = state => ({
  usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ConsolaDeDescarga);