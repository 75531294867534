import React from "react";
import PropTypes from "prop-types";
import { Button, Row, Col } from "reactstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

class FetchPaginator extends React.Component {
    constructor(props) {
        super();

        this.changePage = this.changePage.bind(this);

        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages),
            paginas: 1,
        };
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
        previousText: PropTypes.string,
        nextText: PropTypes.string
    };

    componentDidMount(){
        this.calcularPaginas(this.props.cantidadDeDatos)
    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    };


    getVisiblePages = (page, total) => {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total];
            } else {
                return [1, 2, 3, 4, 5, total];
            }
        }
    };

    changePage(page, goBack) {

        // this.props.callback(
        //     'insercor.com', 
        //     'Manifold', 
        //     page, 
        //     this.props.posicionUltimoDato,
        //     this.props.limiteDeDescargas
        // )

        if (goBack) {
            const activePage = this.props.page + 1;
            if (page === activePage) {
                return;
            }
            
            this.props.onPageChange(page - 1);
        } else {
            this.props.callback(
                'insercor.com', 
                'Manifold', 
                page, 
                this.props.posicionUltimoDato,
                this.props.limiteDeDescargas,
                false
            )
            // .then( (res) => {
            // })
            // .catch(error => console.log(error))
            setTimeout(() => {
                const activePage = this.props.page + 1;
                if (page === activePage) {
                    return;
                }

                this.props.onPageChange(page - 1);
                
            }, 3000)
        }
    }

    calcularPaginas = (cantidadDeDatos) => {
        let nuemeroDePaginas 
        if (cantidadDeDatos % this.props.largoDePaginacion === 0){
            nuemeroDePaginas = cantidadDeDatos/this.props.largoDePaginacion
        } else {
            nuemeroDePaginas = Math.floor(cantidadDeDatos/this.props.largoDePaginacion) + 1
        }
        this.setState({paginas: nuemeroDePaginas})
    }

    render() {
        const activePage = this.props.page + 1;
        return (
            <Row>
                <Col xs="0" md="0" lg="6" xl="6" />

                <Col xs="12" md="12" lg="6" xl="6" style={{ display: "flex", padding: 10 }}>
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button className="pagination-button"  disabled={activePage === 1}
                            onClick={() => { if (activePage === 1) return; this.changePage(activePage - 1, true); }}>
                            <FaAngleLeft />
                        </Button>
                    </div>

                    <div className="pagination-button"
                        style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5, }}>
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {"Página " + activePage + " de " + this.state.paginas}
                        </span>
                    </div>

                    <div style={{ flex: 1, display: "flex", justifyContent: "center", }}>
                        <Button className="pagination-button" disabled={activePage ===  this.state.paginas}
                            onClick={() => { if (activePage === this.state.paginas) return; this.changePage(activePage + 1) }}>
                            <FaAngleRight />
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default FetchPaginator

