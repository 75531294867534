import { firebaseFunctions } from '../../firebase';
// const cargarOrdenDeTrabajo = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajo');
//const cargarOrdenDeTrabajoConEstado = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajoConEstado');
const crearOrdenDeTrabajoMadre = firebaseFunctions.httpsCallable('crearOrdenDeTrabajoMadre')
const crearOrdenDeTrabajoHija = firebaseFunctions.httpsCallable('odt_crearOrdenDeTrabajoHija')

export const cargarDeDatosDeInspeccionEnFirebase = (matrizDeDatos, callbackParaCargue) => {
    return new Promise((resolve, reject) =>{
        let contador = [];
        contador = matrizDeDatos.length;
        matrizDeDatos.forEach(element => {
            let odt = {"ordenDeTrabajo":element}
            crearOrdenDeTrabajoMadre(odt)
                .then((result) =>{
                    switch(result.data.status){
                        case "ORDEN_MADRE_CREADA":
                            callbackParaCargue("La orden de trabajo <b>"+odt.ordenDeTrabajo.numeroDeFormato+"</b> fue creada con exito",'success');
                        break;
                        case "ORDEN_MADRE_NO_CREADA":
                            callbackParaCargue("La orden de trabajo <b>"+odt.ordenDeTrabajo.numeroDeFormato+"</b> no pudo ser creada",'danger');
                        break;
                        case "ORDEN_MADRE_EXISTENTE":
                            callbackParaCargue("La orden de trabajo <b>"+odt.ordenDeTrabajo.numeroDeFormato+"</b> ya existe",'warning');
                        break;
                        default:
                            console.log('status no encontrado');
                        break;
                    }
                    contador--;
                    if(contador===0) resolve();
                })
                .catch(e =>{
                    console.log('error cargar odt', e);
                    contador--;
                    if(contador===0) reject();
                })
        });
        
    })
}

export const cargarDeDatosDeInspeccionHijaEnFirebase = (matrizDeDatos, callbackParaCargue) => {
    return new Promise((resolve, reject) => {
        let contador = [];
        contador = matrizDeDatos.length;
        if (contador > 0) {
            matrizDeDatos.forEach(element => {
                let odt = { "ordenDeTrabajoHija": element }
                crearOrdenDeTrabajoHija(odt)
                    .then((result) => {
                        switch (result.data.status) {
                            case "ORDEN_HIJA_CREADA":
                                callbackParaCargue("La orden de trabajo hija <b>" + odt.ordenDeTrabajoHija.numeroDeFormato + "</b> fue creada con exito", 'success');
                                break;
                            case "ORDEN_HIJA_NO_CREADA":
                                callbackParaCargue("La orden de trabajo hija <b>" + odt.ordenDeTrabajoHija.numeroDeFormato + "</b> no pudo ser creada", 'danger');
                                break;
                            case "ORDEN_HIJA_EXISTENTE":
                                callbackParaCargue("La orden de trabajo hija <b>" + odt.ordenDeTrabajoHija.numeroDeFormato + "</b> ya existe", 'warning');
                                break;
                            default:
                                console.log('status no encontrado');
                                break;
                        }
                        contador--;
                        if (contador === 0) resolve();
                    })
                    .catch(e => {
                        console.log('error cargar odt', e);
                        contador--;
                        if (contador === 0) reject();
                    })
            });
        }

    })
}