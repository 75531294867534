import React from 'react';
import { Progress } from 'reactstrap';

//Estilos base:
const estiloDelTitulo = { fontSize: 12, marginBottom: 2, textAlign: "left" };

let ProgressBarDeDescarga = (props) => {
    let colorDeLaBarra = "primary";
    if (props.porcentajeCompletado >= 100) { colorDeLaBarra = "success" };
    if (props.status === "ERROR") { colorDeLaBarra = "danger" };
    let valorPorcentaje = 0;
    try{
        valorPorcentaje = Math.round(props.porcentajeCompletado);
    }catch(e){}
    
    return (
        <div>
            <p style={{ ...estiloDelTitulo }}>
                <b style={{ marginRight: 5 }}>{props.titulo}</b>
                {"( " + valorPorcentaje + "% )"}
            </p>
            <Progress striped animated color={colorDeLaBarra}
                value={valorPorcentaje}
                className="mb-3 progress-bar-animated-alt" />
        </div>
    );
}

export default ProgressBarDeDescarga;