import { firebaseFunctions } from '../../../../../firebase.js';
const obtenerListaDeOpciones = firebaseFunctions.httpsCallable('obtenerListaDeOpciones');

//Funciones auxiliares:
const obtenerFiltrosComoLista = (objeto) => {
    let listaTarget = [];
    let keys = Object.keys(objeto);

    keys.forEach((key, i) => { listaTarget.push({ ...objeto[key], key: key }); });
    return listaTarget;
}

export const categorias = [
    {
        tipo: "CONSECUENCIA",
        keyDelNodo: "consecuenciaCualitativa",
        value: "consecuencia",
        label: "Consecuencia cualitativa",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los elementos con <b>consencuencia</b> igual a <b>" + valorTarget + " $</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>consencuencia</b> mayor o igual a <b>" + valorTarget + " $</b>";
                case "Mayor":
                    return "Todos los elementos con <b>consencuencia</b> mayor a <b>" + valorTarget + " $</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>consencuencia</b> menor o igual a <b>" + valorTarget + " $</b>";
                case "Menor":
                    return "Todos los elementos con <b>consencuencia</b> menor a <b>" + valorTarget + " $</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "NIVEL_DE_RIESGO",
        keyDelNodo: "nivelDeRiesgo",
        value: "nivelDeRiesgo",
        label: "Nivel de riesgo",
        valorDefault: { value: 'L' },
        tipoDeCategoria: "valoresContinuos",
        esTexto: true,
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los elementos con <b>riesgo cualitativo</b> igual a <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "PROBABILIDAD",
        keyDelNodo: "probabilidadCualitativa",
        value: "probabilidad",
        label: "Probabilidad cualitativa",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los elementos con <b>probabilidad</b> igual a " + valorTarget + "</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>probabilidad</b> mayor o igual a " + valorTarget + "</b>";
                case "Mayor":
                    return "Todos los elementos con <b>probabilidad</b> mayor a " + valorTarget + "</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>probabilidad</b> menor o igual a " + valorTarget + "</b>";
                case "Menor":
                    return "Todos los elementos con <b>probabilidad</b> menor a " + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "TIPO_DE_ACTIVO",
        keyDelNodo: "tipoDeActivo",
        value: "tipoDeActivo",
        label: "Tipo de activo",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "línea",
                label: "Línea"
            },
            {
                value: "tanque",
                label: "Tanque"
            },
            {
                value: "vasija",
                label: "Vasija"
            },
            {
                value: "válvula",
                label: "Válvula"
            }
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los <b>activos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "CATEGORIA",
        keyDelNodo: "categoria",
        value: "categoria",
        label: "Categoría",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "ingenieria",
                label: "Ingeniería"
            },
            {
                value: "inspeccion",
                label: "Inspección"
            },
            {
                value: "mantenimiento",
                label: "Mantenimiento"
            },
            {
                value: "monitoreo",
                label: "Monitoreo"
            },
            {
                value: "otros",
                label: "Otros"
            }
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los <b>segmentos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "MECANISMO_PREDOMINANTE",
        keyDelNodo: "dfPred",
        value: "mecanismoPredominante",
        label: "Mecanismo predominante",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "Corrosión Interna",
                label: "Corrosión interna"
            },
            {
                value: "Corrosión Externa",
                label: "Corrosión externa"
            },
            {
                value: "Lining",
                label: "Lining"
            },
            {
                value: "Corrosión Bajo Aislamiento",
                label: "Corrosión bajo aislamiento"
            },
            {
                value: "Fatiga Mecanica",
                label: "Fatiga mecánica"
            }
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los <b>segmentos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "TIENE_INSPECCION",
        keyDelNodo: "tieneInspeccion",
        value: "tieneInspeccion",
        label: "¿Tiene inspección?",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "Si",
                label: "Si"
            },
            {
                value: "No",
                label: "No"
            }
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los <b>segmentos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "SISTEMA",
        keyDelNodo: "nombreDelSistema",
        valorDefault: { value: "" },
        value: "sistema",
        label: "Sistema al que pertenece",
        placeholder: "Cargando sistemas",
        tipoDeCategoria: "opcionesAsincronas",
        comparadoresPermitidos: [{ value: "==", label: "Igual" },],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los elementos <b>que pertenecen</b> al sistema <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        },
        obtenerOpcionesDesdeCF: (props, otrosFiltrosActivos) => {
            let listaDePrecondiciones = [];
            let filtrosComoLista = obtenerFiltrosComoLista(otrosFiltrosActivos);
            let filtroDelCampoPadre = filtrosComoLista.find(item => item.tipo === "CAMPO" && item.activo);

            if (filtroDelCampoPadre !== undefined) {
                listaDePrecondiciones.push({
                    "key": "informacionDeLosPadres.campoAsociado.nombre",
                    "comparador": "==",
                    "valorTarget": filtroDelCampoPadre.valorTarget
                })
            }

            return obtenerListaDeOpciones({
                "coleccion": "sistemas",
                "dominioCorporativo": props.dominioCorporativo,
                "keyDeAgrupamiento": "informacionGeneral.nombre",

                "precondicionesDeFiltrado": listaDePrecondiciones
            });
        }
    },
    {
        tipo: "TIPO_DE_COMPONENTE",
        keyDelNodo: "informacionGeneral.tipoDeComponente.valor",
        value: "tipoDeComponente",
        label: "Tipo de componente",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "PIPE-1",
                label: "PIPE-1"
            },
            {
                value: "PIPE-2",
                label: "PIPE-2"
            },
            {
                value: "PIPE-4",
                label: "PIPE-4"
            },
            {
                value: "PIPE-6",
                label: "PIPE-6"
            },
            {
                value: "PIPE-8",
                label: "PIPE-8"
            },
            {
                value: "PIPE-10",
                label: "PIPE-10"
            },
            {
                value: "PIPE-12",
                label: "PIPE-12"
            },
            {
                value: "PIPE-16",
                label: "PIPE-16"
            },
            {
                value: "PIPEGT-16",
                label: "PIPEGT-16"
            },
            {
                value: "KOLDRUM",
                label: "KOLDRUM"
            },
            {
                value: "COLBTM",
                label: "COLBMT"
            },
            {
                value: "FINFAN",
                label: "FINFAN"
            },
            {
                value: "FILTER",
                label: "FILTER"
            },
            {
                value: "DRUM",
                label: "DRUM"
            },
            {
                value: "REACTOR",
                label: "REACTOR"
            },
            {
                value: "COLTOP",
                label: "COLTOP"
            },
            {
                value: "COLMID",
                label: "COLMID"
            },
            {
                value: "TANKBOTTOM",
                label: "TANKBOTTOM"
            },
            {
                value: "COURSE-1",
                label: "COURSE-1"
            },
            {
                value: "COURSE-2",
                label: "COURSE-2"
            },
            {
                value: "COURSE-3",
                label: "COURSE-3"
            },
            {
                value: "COURSE-4",
                label: "COURSE-4"
            },
            {
                value: "COURSE-5",
                label: "COURSE-5"
            },
            {
                value: "COURSE-6",
                label: "COURSE-6"
            },
            {
                value: "COURSE-7",
                label: "COURSE-7"
            },
            {
                value: "COURSE-8",
                label: "COURSE-8"
            },
            {
                value: "COURSE-9",
                label: "COURSE-9"
            },
            {
                value: "COURSE-10",
                label: "COURSE-10"
            },
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorSegmento, valorTarget) => {
            switch (comparadorSegmento) {
                case "Igual":
                    return "Todos los <b>segmentos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
]