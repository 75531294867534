import React from 'react'

import { Label, Input, Col, Row } from 'reactstrap';
import { MdEdit } from "react-icons/md";
import { MdAdd } from "react-icons/md";

//Componentes Auxiliares
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

let IdentificacionAnomaliaILI = (props) => {
    return (
        <React.Fragment>
            <br />
            <br />
            <div className={"mb-0 p-3 d-flex " + (props.estado ? 'border rounded-top' : 'border rounded')} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                <Label style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black'}}>IDENTIFICACIÓN ANOMALÍA ILI</Label>                    
                <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                    {props.authRol ? <BotonCallToAction key={'btn_identificacion_ili'}
                        usuarioActivo={props.usuarioActivo}
                        color={props.estado ? 'primary' : 'success'}
                        icono={props.estado ? MdEdit : MdAdd}
                        titulo={props.estado ? 'Editar Datos' : 'Agregar Datos'}
                        onClick={props.toggleModalLI}
                    /> : null}
                </div>
            </div>
            {obtenerRenderDeDatos(props)}
        </React.Fragment>
    );
}

const obtenerRenderDeDatos = (props) => {
    if (props.estado) {
        return (
            <div className="p-3 shadow-none border rounded-bottom">
                <Row>
                    <Col className='ml-xs-0 ' xs='12' md='6' lg='6' >
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="distanciaRegistro" className='mb-0 mt-2'>Distancia Registro:</Label>
                                <Input type="text" name="distanciaRegistro" id="distanciaRegistro" placeholder="Distancia Registro" defaultValue={props.keysDelRegistro.distanciaRegistroDucto} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="longitudAnomalia" className='mb-0 mt-2'>Longitud Anomalía:</Label>
                                <Input type="text" name="longitudAnomalia" id="longitudAnomalia" placeholder="Longitud Anomalía" defaultValue={props.keysDelRegistro.longitudAnomaliaDelDucto} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="longitud1" className='mb-0 mt-2'>Longitud(°'"):</Label>
                                <Input type="text" name="longitud1" id="longitud1" placeholder="Longitud" defaultValue={props.keysDelRegistro.longitudDeDuctoDecimal} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="anchoAnomalia" className='mb-0 mt-2'>Ancho Anomalía:</Label>
                                <Input type="text" name="anchoAnomalia" id="anchoAnomalia" placeholder="Ancho Anomalía" defaultValue={props.keysDelRegistro.anchoAnomaliaDelDucto} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="latitud1" className='mb-0 mt-2'>Latitud(°'"):</Label>
                                <Input type="text" name="latitud1" id="latitud1" placeholder="Latitud" defaultValue={props.keysDelRegistro.latitudDeDuctoDecimal} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="distanciaJAnt" className='mb-0 mt-2'>Distancia J. Ant:</Label>
                                <Input type="text" name="distanciaJAnt" id="distanciaJAnt" placeholder="Distancia J. Ant" defaultValue={props.keysDelRegistro.distanciaJuntaAnteriorDelDucto} disabled />
                            </Col>
                        </Row>
                        <Row >
                            <Col xs='12' md='6' lg='6' xl='3'>
                                <Label for="LTubo" className='mb-0 mt-2'>Longitud Tubo:</Label>
                                <Input type="text" name="LTubo" id="LTubo" placeholder="LTubo" defaultValue={props.keysDelRegistro.lTubo} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6' xl='3' >
                                <Label for="djr" className='mb-0 mt-2'>D.JR:</Label>
                                <Input type="text" name="djr" id="djr" placeholder="D.JR" defaultValue={props.keysDelRegistro.distanciaAlRegistroDelDucto} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="distanciaJPost" className='mb-0 mt-2'>Distancia J. Post:</Label>
                                <Input type="text" name="distanciaJPost" id="distanciaJPost" placeholder="Distancia J. Post" defaultValue={props.keysDelRegistro.distanciaJuntaPosteriorDelDucto} disabled />
                            </Col>
                        </Row>
                    </Col>
                    <Col className='ml-lg-3'>
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="posicionHoraria1" className='mb-0 mt-2'>Posición horaria:</Label>
                                <Input type="text" name="posicionHoraria1" id="posicionHoraria1" placeholder="Posición horaria" defaultValue={(props.keysDelRegistro.posicionHorariaDelDucto) ? props.keysDelRegistro.posicionHorariaDelDucto : ""} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="anhoDeLaCorrida" className='mb-0 mt-2'>Año de la corrida:</Label>
                                <Input type="text" name="anhoDeLaCorrida" id="anhoDeLaCorrida" placeholder="Año de la corrida" defaultValue={props.keysDelRegistro.anhoDeLaCorridaDelDucto} disabled />
                            </Col>
                        </Row>
                        <Row >
                            <Col xs='12' md='12' lg='12'>
                                <Label for="perdidaDeMetalOReduccionDelID" className='mb-0 mt-2'>% Pérdida de metal o reducción del ID:</Label>
                                <Input type="text" name="perdidaDeMetalOReduccionDelID" id="perdidaDeMetalOReduccionDelID" placeholder="% Pérdida de metal o reducción del ID" defaultValue={props.keysDelRegistro.porcentajeDeMetalOReduccionDelIdDelDucto} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='12' lg='12' xl='6'>
                                <Label for="tipoAnomalia" className='mb-0 mt-2'>Tipo anomalía:</Label>
                                <Input type="text" name="tipoAnomalia" id="tipoAnomalia" placeholder="Tipo anomalía" defaultValue={props.keysDelRegistro.tipoDeAnomaliaDelDucto} disabled />
                            </Col>
                            <Col xs='12' md='12' lg='12' xl='6'>
                                <Label for="programadaOAdicional" className='mb-0 mt-2'>¿Programada o adicional?:</Label>
                                <Input type="text" name="programadaOAdicional" id="programadaOAdicional" placeholder="¿Programada o adicional?" defaultValue={props.keysDelRegistro.programadaOAdicionalDelDucto} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="clasificacion" className='mb-0 mt-2'>Clasificación:</Label>
                                <Input type="text" name="clasificacion" id="clasificacion" placeholder="Clasificación" defaultValue={props.keysDelRegistro.clasificacionDelDucto} disabled />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <Label for="enPared" className='mb-0 mt-2'>En pared:</Label>
                                <Input type="text" name="enPared" id="enPared" placeholder="En pared" defaultValue={props.keysDelRegistro.enParedDelDucto} disabled />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return(<></>)
    }
}

export default IdentificacionAnomaliaILI