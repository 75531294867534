const detallesDeAnomalias = {
    "PERDIDA_DE_METAL": {
        "tipoDeAnomalia": "PERDIDA_DE_METAL",
        "titulo": "Pérdida de Metal",
        "icono": "minus-circle-multiple",
        "seccion": "perdidaDeMetal",
        "navegacion": "registroDeAnomalias"
    },
    "ABOLLADURA" : {
        "tipoDeAnomalia": "ABOLLADURA",
        "titulo": "Abolladuras",
        "icono": "sticker-circle-outline",
        "seccion": "abolladuras",
        "navegacion": "registroDeAnomalias"
    },
    "CAMISA": {
        "tipoDeAnomalia": "CAMISA",
        "titulo": "Camisa",
        "icono": "card",
        "seccion": "camisas",
        "navegacion": "gestorDeCamisas"
    },
    "CAMISA_SOBRE_ANILLOS": {
        "tipoDeAnomalia": "CAMISA_SOBRE_ANILLOS",
        "titulo": "Camisa Sobre Anillos",
        "icono": "card-outline",
        "seccion": "camisaSobreAnillos",
        "navegacion": "gestorDeCamisas"
    },
    "PARCHE" : {
        "tipoDeAnomalia": "PARCHE",
        "titulo": "Parche",
        "icono": "sticker",
        "seccion": "parche",
        "navegacion": "gestorDeCamisas"
    },
    "BENDING_STRAIN" : {
        "tipoDeAnomalia": "BENDING_STRAIN",
        "titulo": "Bending Strain",
        "icono": "redo",
        "seccion": "bendingStrain",
        "navegacion": "gestorDeBs"
    },
}

export default detallesDeAnomalias;