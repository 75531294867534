import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

class SelectAsincrono extends React.Component {

    render() {
        return (
            <Select options={[]} isLoading placeholder={this.props.placeholder} />
        );
    }

    componentDidMount() {
        try {
            this.props.funcionParaObtenerOpciones({ ...this.props.usuarioActivo }, this.props.otrosFiltrosActivos)
                .then(response => {
                    let opcionesProcesadas = [];

                    response.data.opciones.forEach(opcion => {
                        if (JSON.stringify(opcion).includes("valor")) {
                            opcionesProcesadas.push({ "value": opcion.value.valor, "label": opcion.label.valor });
                        }
                        else { opcionesProcesadas.push(opcion); }
                    });

                    this.props.onSync(opcionesProcesadas);
                })
                .catch(error => { console.log(error); });
        }
        catch (error) { console.log(error); };
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(SelectAsincrono);