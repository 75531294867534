import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FaSave } from 'react-icons/fa';
import { TiFilter } from 'react-icons/ti';

//Funciones para manipular state redux:
import { actualizarDataPlanesDeAccion } from '../../../../storage/reducerParaData';

//Tablas de React
import listaDeActivosDePrueba from './scripts/listaDeActivosDePrueba';

//Componentes auxiliares:
import CustomSection from '../../../../utilidades/componentes/plantillaBase/CustomSection.jsx';

//Estilos base:
import insercorLogo from '../../../../assets/utils/images/logos/giaCore-logo.png';
import BotonParaSection from '../../../../utilidades/componentes/plantillaBase/BotonParaSection.jsx';

//classnames
import cx from 'classnames';

//guardarAviso
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//span de campo
import SpanDeCampo from '../../../../utilidades/componentes/resumenDeModulo/SpanDeCampo.jsx';

//Cloud functions
import { firebaseFunctions, firestoreDB } from '../../../../firebase';

//Preloader
import Preloader from '../../../../utilidades/componentes/plantillaBase/Preloader';
import { confirmacionDeActualizacionDeNodos } from '../../../../utilidades/almacen/alertasParaSwal';

import { obtenerElementosQueCumplanConLasCondiciones } from '../../../../utilidades/scripts/paraCondicionalesMultiples';
import ManagerDeFiltros from '../../../../utilidades/componentes/resumenDeModulo/ManagerDeFiltros.jsx';
import { categorias } from './estaticos/opcionesDeFiltrosPlanesAccion.js';
import { obtenerNodosConSubcolecciones } from '../../../../utilidades/scripts/paraConsultaDeNodos';
import { obtenerTablaDePlanesDeAccion, limpiarNodos, organizarDatosParaActualizarTabla } from '../../../../utilidades/scripts/paraPlanesDeAccion';
import TablaPlanesDeAccion from './componentes/TablaPlanesDeAccion';

const actualizarEstadoDePlanDeAccion = firebaseFunctions.httpsCallable('actualizarEstadoDePlanDeAccion')

//Remover tildes
var accents = require('remove-accents');

//Estilos base:
const estilosParaResumenesEnTop = { marginBottom: 2.5 };

//Constantes para el desarrollo:
const permitirConsultas = true;

class VisualizadorPlanesDeAccion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeSearch: false,
            tablaSinFiltro: (permitirConsultas) ? [] : obtenerTablaDePlanesDeAccion(listaDeActivosDePrueba),
            tablaConFiltro: (permitirConsultas) ? [] : obtenerTablaDePlanesDeAccion(listaDeActivosDePrueba),
            query: '',
            coleccionActiva: "segmentos",
            campoTarget: undefined,
            cargando: false,
            camposDescargadosLaPrimeraVez: false,
            limiteDeDescargas: 5,
            filtrosActivos: {},
            laConsolaDeFiltrosEstaAbierta: false,
        };

        if (this.state.tablaConFiltro.length < this.state.numeroDePaginas) {
            this.setState({
                numeroDePaginas: this.state.tablaConFiltro.length
            });
        }
    }

    componentDidMount() {
        if (permitirConsultas) {
            let nodosDeRedux = this.props["/app/tratamiento/planesDeAccion"];

            if (nodosDeRedux.nodosDescargados) {
                const tablaDePlanesDeAccion = obtenerTablaDePlanesDeAccion(nodosDeRedux["segmentos"]);

                this.setState({
                    campoTarget: nodosDeRedux["campoTarget"],
                    limiteDeDescargas: nodosDeRedux["limiteDeDescargas"],
                    tablaSinFiltro: tablaDePlanesDeAccion,
                    tablaConFiltro: tablaDePlanesDeAccion,
                });
            }
            else {
                this.setState({
                    limiteDeDescargas: nodosDeRedux["limiteDeDescargas"],
                });
            }
        }
    }

    //Consultas a DB:
    actualizarSegmentos = () => {
        if (permitirConsultas) {
            window.document.body.style.cursor = "wait";
            this.setState({
                cargando: true,
            });

            let promiseDeConsultaDeSegmentos = firestoreDB.collection("segmentos");
            promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
            promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("informacionDeLosPadres.campoAsociado.nombre", "==", this.state.campoTarget);
            if (this.state.limiteDeDescargas !== undefined) { promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.limit(this.state.limiteDeDescargas); }

            obtenerNodosConSubcolecciones(promiseDeConsultaDeSegmentos, "API581", true, false)
                .then(segmentos => {
                    let listaDeSegmentos = limpiarNodos(segmentos);

                    this.setState({
                        tablaSinFiltro: obtenerTablaDePlanesDeAccion(listaDeSegmentos),
                        tablaConFiltro: obtenerTablaDePlanesDeAccion(listaDeSegmentos),
                    });

                    this.props.actualizarDataPlanesDeAccion({
                        "nodosDescargados": true,
                        "segmentos": listaDeSegmentos,
                        "campoTarget": this.state.campoTarget,
                        "limiteDeDescargas": this.state.limiteDeDescargas
                    });

                    setTimeout(() => {
                        this.setState({
                            cargando: false,
                        });
                    }, 500);

                    window.document.body.style.cursor = "default";
                })
                .catch(error => { console.log(error); window.document.body.style.cursor = "default"; });
        }
    }

    //Otros métodos:    
    cambiarEstadoDelCheck(evento, row) {
        let tablaConFiltroPrevia = this.state.tablaConFiltro
        tablaConFiltroPrevia[row._index].estado = !tablaConFiltroPrevia[row._index].estado
        this.setState({
            tablaConFiltro: tablaConFiltroPrevia
        })
    }

    filtrarComponentes(event) {
        this.setState({ cargando: true });

        let query = event.target.value;
        let querySinTildes = accents(query)

        let tablaConFiltroBuscador = this.state.tablaSinFiltro;

        let filasFiltradas = tablaConFiltroBuscador.map((item, i) => {
            let cumpleConElCriterio = false;
            let keysDelItem = Object.keys(item);

            for (let k = 0; k < keysDelItem.length; k++) {
                try {
                    if ((accents(item[keysDelItem[k]].toLowerCase().trim())).includes(querySinTildes.toLowerCase().trim())) {
                        cumpleConElCriterio = true;
                        break;
                    }
                }
                catch (error) { };
            }

            if (cumpleConElCriterio) { return item; }
            else { return null; }
        });

        setTimeout(() => {
            this.setState({
                cargando: false,
                tablaConFiltro: filasFiltradas.filter((item) => item),
            });
        }, 1000);
    }

    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: true,
            autoClose: 60000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: true,
            autoClose: 60000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    actualizarCampoTarget = (nuevoCampoTarget) => {
        this.setState({ campoTarget: nuevoCampoTarget });
        try {
            setTimeout(() => { this.actualizarSegmentos(nuevoCampoTarget); }, 50);
        }
        catch (error) { };
    }

    //Métodos auxiliares de renderizado:
    obtenerMensajeDeLimiteDeDescargas = () => {
        if (!this.state.cargando) {
            if (this.state.limiteDeDescargas !== undefined) {
                return (
                    <p style={{ ...estilosParaResumenesEnTop }}>
                        {"Se descargaron los planes de acción de los "}
                        <b>{this.state.limiteDeDescargas}</b>
                        {" segmentos con cambios más recientes. "}
                        <span style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
                            onClick={() => {
                                Swal.fire(confirmacionDeActualizacionDeNodos)
                                    .then(result => {
                                        if (result.value) {
                                            this.setState({ limiteDeDescargas: undefined });
                                            setTimeout(() => { this.actualizarSegmentos(); }, 50);
                                        }
                                    });
                            }}>
                            Ver todos
                        </span>
                    </p>
                );
            }
            else {
                return (
                    <p style={{ ...estilosParaResumenesEnTop }}
                        dangerouslySetInnerHTML={{
                            __html: "Se descargaron <b>todos los planes de acción </b> del campo ("
                                + this.state.tablaSinFiltro.length + ")"
                        }} />
                );
            }
        }
        else {
            return (
                <p style={{ ...estilosParaResumenesEnTop }}
                    dangerouslySetInnerHTML={{ __html: "..." }} />
            );
        }
    }

    agregarFiltro = () => {
        let filtrosActualizados = this.state.filtrosActivos;
        let keysActuales = Object.keys(filtrosActualizados);

        let keyTarget = 0;

        if (keysActuales.length > 0) {
            keysActuales.forEach((key, i) => { filtrosActualizados[key]['desplegado'] = false; });
            keyTarget = Math.max(...keysActuales) + 1
        }

        filtrosActualizados[keyTarget] = { tipo: "CATEGORIA", activo: true, desplegado: true };
        this.setState({ filtrosActivos: filtrosActualizados, });
    }

    actualizarValorDeFiltro = (keyTarget, nuevoValorDelFiltro, ejecutarConsulta) => {
        let filtrosActualizados = this.state.filtrosActivos;
        filtrosActualizados[keyTarget] = nuevoValorDelFiltro;
        this.setState({
            filtrosActivos: filtrosActualizados,
            tablaConFiltro: obtenerElementosQueCumplanConLasCondiciones(this.state.tablaSinFiltro, filtrosActualizados)
        });
    }

    desactivarFiltro = (keyTarget) => {
        let filtrosActualizados = this.state.filtrosActivos;
        filtrosActualizados[keyTarget].activo = false;
        this.setState({
            filtrosActivos: filtrosActualizados,
            tablaConFiltro: obtenerElementosQueCumplanConLasCondiciones(this.state.tablaSinFiltro, filtrosActualizados)
        });
    }

    obtenerBotonDeFiltros = () => {
        return (
            <BotonParaSection key="botonDeFiltrosEnPlanesDeAccion"
                onClick={this.toggleDeLaConsolaDeFiltros}
                icono={<TiFilter size={15} />} title="Filtrar datos" />
        );
    }

    toggleDeLaConsolaDeFiltros = () => {
        this.setState({ laConsolaDeFiltrosEstaAbierta: !this.state.laConsolaDeFiltrosEstaAbierta });
    }

    render() {
        return (
            <CustomSection titulo="Planes de acción integral" icono={insercorLogo}
                subtitulo="Revisa las recomendaciones según el diagnóstico realizado"
                botonesAdicionales={[<BotonParaSection key="SectionBoton1" icono={<FaSave />} onClick={() => {
                    actualizarEstadoDePlanDeAccion(organizarDatosParaActualizarTabla(this.state.tablaConFiltro))
                        .then((rta) => {
                            this.avisoDeGuardadoSatisfactorio('Operación realizada satisfactoriamente')
                        })
                        .catch(e => {
                            this.avisoDeGuardadoFallido('No se pudo realizar la operación')
                        })
                }
                } />, this.obtenerBotonDeFiltros()]}>

                <Row style={{ marginBottom: "1rem" }}>
                    <Col xs="12" md="12" lg="6" xl="6">
                        <div style={{}} className={cx("search-wrapper", {
                            'active': this.state.activeSearch
                        })}>
                            <div className="input-holder mb-3 justify-contentent-center">
                                <input type="text" className="search-input" placeholder="Buscar..."
                                    onKeyDown={(ev) => { if (ev.keyCode === 13) { this.filtrarComponentes(ev); } }}
                                />
                                <button onClick={() => this.setState({ activeSearch: !this.state.activeSearch })}
                                    className="search-icon"><span /></button>
                            </div>
                            <button onClick={() => this.setState({ activeSearch: !this.state.activeSearch })} className="close" />
                        </div>
                    </Col>

                    <Col xs="12" md="12" lg="6" xl="6" style={{ textAlign: "right" }}>
                        <p style={{ ...estilosParaResumenesEnTop }}>
                            {this.state.cargando
                                ? "Descargando "
                                : "Estoy viendo los " + this.state.tablaConFiltro.length + " "
                            }
                            <b>{"planes de acción"}</b>
                            {" para el campo "}
                            <b>
                                <SpanDeCampo
                                    campoTarget={this.state.campoTarget}
                                    usuarioActivo={this.props.usuarioActivo}
                                    permitirConsultaInicial={!this.props["/app/tratamiento/planesDeAccion"].nodosDescargados}
                                    onChange={(nuevoCampoTarget) => {
                                        this.actualizarCampoTarget(nuevoCampoTarget);
                                    }}
                                />
                            </b>
                        </p>

                        {this.obtenerMensajeDeLimiteDeDescargas()}
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <TablaPlanesDeAccion
                            tablaConFiltro={this.state.tablaConFiltro}
                            cambiarEstadoDelCheck={this.cambiarEstadoDelCheck}
                        />
                    </Col>
                </Row>

                <ToastContainer />

                <Preloader elPreloaderEstaAbierto={this.state.cargando} />

                <ManagerDeFiltros
                    agregarFiltro={this.agregarFiltro}
                    desactivarFiltro={this.desactivarFiltro}
                    filtrosActivos={this.state.filtrosActivos}
                    toggleDelModal={this.toggleDeLaConsolaDeFiltros}
                    actualizarValorDeFiltro={this.actualizarValorDeFiltro}
                    elModalEstaAbierto={this.state.laConsolaDeFiltrosEstaAbierta}
                    categorias={categorias}
                />
            </CustomSection>
        );
    }
}


const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa,

    "/app/tratamiento/planesDeAccion": state.reducerParaData["/app/tratamiento/planesDeAccion"]
});

const mapDispatchToProps = dispatch => ({
    actualizarDataPlanesDeAccion: nuevaData => { dispatch(actualizarDataPlanesDeAccion(nuevaData)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizadorPlanesDeAccion);