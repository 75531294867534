import React from 'react';
//Iconos
import { FaListOl } from 'react-icons/fa';
import { Grid } from '@material-ui/core';
import CustomMUIDataTable from '../../../gestion/api581Valvulas/componentes/CustomMUIDataTable';
import LinkConHistory from '../../../gestion/api581Valvulas/componentes/LinkConHistory';

//Componentes:
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';

let TablaGestionDeInformes2 = (props) => {   
  
  const columnas = [
    {
        name: "id",
        label: "ID",
        options: {
            filterType: 'textField',
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Grid container >
                        <LinkConHistory to={`/app/ordenDeTrabajo/${value}`} style={{ width: "100%", textAlign: "center" }}>
                            {value}
                        </LinkConHistory>
                    </Grid>
                );
            },
        }
    },
    {
        name: "estado",
        label: "Estado",
        options: {
            display: true,
            viewColumns: false,
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return props.crearBadgeSegunEstado(value);
            },
        }
    },
    {
        name: "responsable",
        label: "Supervisor",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "lineaDeDucto",
        label: "Línea",
        options: {
            display: true,
            viewColumns: false,
            filter: true
        }
    },
    {
        name: "descripcionODT",
        label: "Descripción",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },

    {
        name: "fechaDeActualizacion",
        label: "Fecha de Actualización",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
  ]
    return (
        <CardConEncabezado
            titulo={'Formatos de inspección'}
            icono={FaListOl}
        >
            <div style={{overflowX:'auto'}}>
                <CustomMUIDataTable
                    title={"Ordenes de Trabajo Madre"}
                    download={true}
                    columns={columnas}
                    data={props.listaDeFormatos}
                    loading={true}
                    cargandoEnTitulo={false}
                    cargandoEnBody={{ component: "cargandoLabel" }}
                />
            </div>
        </CardConEncabezado>

  )

}
export default TablaGestionDeInformes2