import React from 'react';
import { Button } from 'reactstrap';
import { FaAlignCenter } from 'react-icons/fa';

//Propiedades base para el icono del card:
const baseParaIconoDelCard = { size: 15, style: { strokeWidth: 0.75 } };

let BotonDeCardConEncabezado = (props) => {
    return (
        <Button className="btn-icon btn-icon-only font-icon-wrapper text-primary " color="link" onClick={props.onClick}
            style={{ marginBottom: 0, marginLeft: 2.5, marginRight: 2.5, border: 0, width: 20 }} title={props.title}>
            <div style={{ paddingLeft: 0 }}>
                {obtenerIcono(props)}
            </div>
        </Button>
    );
}

let obtenerIcono = (props) => {
    let Componente = props.icono;
    if (props.icono) {
        return (<Componente {...baseParaIconoDelCard} />);
    }
    else {
        return (<FaAlignCenter {...baseParaIconoDelCard} />);
    }
}

export default BotonDeCardConEncabezado;