import React from 'react';
import Swal from 'sweetalert2'

//Cloud functions
import { firebaseFunctions } from '../../../firebase';

//Sweet alerts:
import { confirmacionDeActualizacionDeNodos } from '../../almacen/alertasParaSwal';

const obtenerListaDeOpciones = firebaseFunctions.httpsCallable('obtenerListaDeOpciones');

class SpanDeCantidad extends React.Component {

    constructor() {
        super();

        this.state = {
            campoTarget: "",
            camposDescargadosLaPrimeraVez: false,
            campos: [],
            mostrarSelect: false
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.obtenerSpan()}
                {this.obtenerSelect()}
            </React.Fragment>
        );
    }

    //Se adiciona campos al estado
    componentDidMount() {
        if(!this.props.camposDescargadosLaPrimeraVez) this.obtenerCampos();
        else {
            this.setState({
                campos: this.props.campos,
                campoTarget: this.props.campoTarget || this.props.campos[0].label,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.campoTarget !== this.props.campoTarget) {
            this.setState({ campoTarget: this.props.campoTarget });
        }
    }

    //Métodos para renderizado:
    obtenerSelect = () => {
        if (this.state.mostrarSelect) {
            return (
                <select
                    key={1}
                    style={{ fontSize: 12 }}
                    defaultValue={this.state.campoTarget}
                    onBlur={() => { this.setState({ mostrarSelect: false }); }}
                    onChange={(e) => {
                        let nuevoTarget = e.target.value;
                        let targetPrevio = this.state.campoTarget;
                        this.setState({ campoTarget: e.target.value });
                        const showSwal = !!this.props.onChangePreAlert ? this.props.onChangePreAlert(nuevoTarget) : true;
                        if (showSwal || !!this.props.conAlerta ) Swal.fire(confirmacionDeActualizacionDeNodos)
                            .then(result => {
                                if (result.value) {
                                    this.setState({ campoTarget: nuevoTarget });
                                    this.props.onChange(nuevoTarget);
                                }
                                else { this.setState({ campoTarget: targetPrevio }); }
                            });
                        else this.props.onChange(nuevoTarget);                  
                    }}>
                    {this.obtenerOpciones()}
                </select>
            );
        }
    }

    obtenerSpan = () => {
        if (!this.state.mostrarSelect) {
            return (
                <span style={{ cursor: "pointer" }}
                    onClick={() => { this.setState({ mostrarSelect: true }); }}>
                    {this.state.campoTarget}
                </span>
            );
        }
    }

    obtenerOpciones = () => {
        return this.state.campos.map(campo => {
            return (
                <option value={campo.value} key={campo.value}>{campo.label}</option>
            );
        });
    }

    obtenerCampos = () => {
        return obtenerListaDeOpciones({
            "coleccion": this.props.coleccion || "campos" ,
            "dominioCorporativo": this.props.usuarioActivo.dominioCorporativo,
            "keyDeAgrupamiento": "informacionGeneral.nombre",
            "precondicionesDeFiltrado": []
        })
            .then(response => {
                const campos = response.data["opciones"].filter(option => option.label || option.value);
                console.log('campos', campos)
                if (campos.length > 0) {
                    this.setState({
                        campos: campos,
                        campoTarget: this.props.campoTarget || campos[0].label,
                    });

                    if (this.props.permitirConsultaInicial) {
                        this.props.onChange(campos[0].label);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export default SpanDeCantidad;