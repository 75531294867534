import React, {useState, useEffect} from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FaListUl } from 'react-icons/fa';
import TablaEditable from '../../../../analisis/riesgo/visualizadorDeNodos/componentes/TablaEditable';
import { Typography } from '@material-ui/core';
import {Label} from 'reactstrap';
import useStoreApi581PRD from '../../hooks/useStoreApi581PRD';
import aplicarGetters from '../../../../analisis/riesgo/api581Valvulas/scripts/getters'
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';
import { isEmpty } from 'lodash';

const clonedeepPRDenVisualizador = require('lodash/cloneDeep');
const { glosarioDeVariablesPRD: glosarioPRD } = require('../../../../../../utilidades/scripts/glosarioDeVariablesPRD')

let listaDeObjetosPRD = {};

export default function TablaDePropiedades(props) {
    const [getLista, setLista] = useState([]);
    const [listaVariable, setListaVariable] = useState([]);
    const [getData, setData] = useState();
    const { getStateApi581PRD, dispatch } = useStoreApi581PRD();
    
    
    useEffect(()=>{
        if(props.data){
            setData(props.data);
            listaParaBuscador(props.data);            
        }
    },[props]);

    useEffect(()=>{
        if(!isEmpty(getData)){
            listaParaBuscador(getData, props);            
        }
    },[getData]);

    function obtenerListadoParaPropiedades(objeto, nombre, data) {
        let list = [];
        let nuevoObjeto = {};
        const datos = data ? data.parametrosDeEntrada ? data.parametrosDeEntrada : {} : {};
        Object.keys(objeto).forEach(item => {
            if (item !== "label") {
                Object.keys(objeto[item]).forEach(item2 => {
                    if (has(datos, [nombre, item, item2])) {
                        const Oj = objeto[item][item2];
                        const dataValue = datos[nombre][item][item2]
                        Oj.valor = dataValue === "" || isNil(dataValue) || isNaN(dataValue) ? "-" : dataValue;
                        list.push(Oj);
                    }
                    nuevoObjeto[item2] = objeto[item][item2];
                });
            }
        });

        const prd = list.map((item, i) => { // Se agrega el indice, requerido para la modificacion desde la tabla
            return ({
                ...item,
                "indice": i,
            })
        })
        listaDeObjetosPRD = {...listaDeObjetosPRD, ...nuevoObjeto};
        
        return { "lista": prd, "objetoPRD": nuevoObjeto };
    }

    function ListaDePropiedades(props) {
        const glosarioParaLista = clonedeepPRDenVisualizador(glosarioPRD);
        const propiedades = Object.keys(glosarioParaLista).map((item, i) => {
            const subData = obtenerListadoParaPropiedades(glosarioParaLista[item], item, props.data);
            return (
                <Accordion key={glosarioParaLista[item].label + i}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        key={glosarioParaLista[item].label + (i + 1)}
                    >
                        <Typography >{glosarioParaLista[item].label}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        key={glosarioParaLista[item].label + (i + 2)}
                    >
                        <TablaEditable
                            titulo={"Lista de propiedades"}
                            icono={FaListUl}
                            listaDeDatos={subData.lista}
                            objetoPRD={subData.objetoPRD}
                            coleccionActiva={'prd'}
                            idNodo={getData ? getData.id : null}
                            isPRD={true}
                            listaDeCambios={[]}
                            onActualizacionExitosa={(value, key, pathDeActualizacion) => { onActualizacionExitosa(value,key,pathDeActualizacion,props.info) }}
                        />
                    </AccordionDetails>
                </Accordion>
            )
        })
        return propiedades;
    }

    function listaParaBuscador(datos){
        if(!datos || Object.keys(datos).length === 0) return;
        const objeto = clonedeepPRDenVisualizador(glosarioPRD);
        let listaBuscador = [];
        Object.keys(objeto).forEach(item1 => {            
                Object.keys(objeto[item1]).forEach(item2 => {
                    if (item2 !== "label") {
                    Object.keys(objeto[item1][item2]).forEach(variable=>{
                        let Oj = objeto[item1][item2][variable];
                        const dataValue = datos["parametrosDeEntrada"][item1][item2][variable];
                        Oj.valor = dataValue === "" || isNil(dataValue) || isNaN(dataValue) ? "-" : dataValue;
                        listaBuscador.push(Oj);
                    });
                    }                   
                });            
        });

        //onGetListaDEVariables es un call back que devuelve la lista de datos formateada.
        if(props.onGetListaDeVariables) props.onGetListaDeVariables(listaBuscador);

        setLista(listaBuscador)
    }

    function onActualizacionExitosa (value,key,pathDeActualizacion, props){
        props.onActulizarLista({'value':value,'key':key});
        const newState= cloneDeep(getData);
        set(newState,pathDeActualizacion,value)
        aplicarGetters(newState);
        const nuevosDatosStore = { ...getStateApi581PRD(),data:{} }
        if(key === "campo" && has(nuevosDatosStore,"resumen.campos"))nuevosDatosStore.resumen.campos = union(nuevosDatosStore.resumen.campos,[value])
        dispatch(nuevosDatosStore);
        setData(newState)
    }

    return (
        <>
            <div className={"mb-0 d-flex border rounded"} style={{ marginBottom: 2, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none', padding: '5px 10px' }}>
                <Label style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black' }}>INFORMACIÓN DETALLADA</Label>
                <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={getLista}
                        getOptionLabel={(option) => option.nombre}
                        defaultValue={[]}
                        onChange={(event, value) => {
                            setListaVariable(value);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" size='small' label="Buscar" style={{ width: 'auto', minWidth: '30vw' }} />
                        )}

                    />
                </div>
            </div>
            {listaVariable.length > 0 ?
                <TablaEditable
                    id={"dhf"}
                    titulo={"Lista de propiedades"}
                    icono={FaListUl}
                    listaDeDatos={listaVariable}
                    objetoPRD={listaDeObjetosPRD}
                    coleccionActiva={'prd'}
                    idNodo={getData.id}
                    isPRD={true}
                    onActualizacionExitosa={(value, key, pathDeActualizacion) => { onActualizacionExitosa(value,key,pathDeActualizacion, props) }}
                    obtenerCambios={(datos) => { }}
                />
                : <ListaDePropiedades data={getData} info={props} />}

        </>
    )

}