import { firebaseFunctions } from "../../../../../../firebase"
import { errorToast } from "../../../../../../utilidades/Toastify/Toastify"

const exportarConsultas = firebaseFunctions.httpsCallable('exportarConsultas');

export async function obtenerResultadosPorPRD(id, alert = true) {
    return exportarConsultas({
        option: "obtenerSubcolecciones",
        id,
        conLogDeCalculos: false,
        conResultados: true,
        getSubCollectionsAsArray: false
    }).then((res) => res.data.resultados).catch(() => {
        alert && errorToast("Error cargando Reportes", "error")
        throw new Error("Error cargando Reportes")
    })
}

export function obtenerResultados(ids) {
    return Promise.all(ids.map((id) => obtenerResultadosPorPRD(id)))
}