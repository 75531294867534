import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import * as serviceWorker from './serviceWorker';

//Componente principal:
import App from './App';

//Assets del template:
import './assets/base.scss';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/number/is-nan';
import 'core-js/features/array/includes';

//Reducers del aplicativo:
import reducerGeneral from './storage/reducerGeneral.js';
import reducerParaData from './storage/reducerParaData.js'
import reducerParaTema from './storage/reducerParaTema.js';
import reducerParaUsuario from './storage/reducerParaUsuario.js';
import reducerParaFunctions from './storage/reducerParaFunctions.js';

//Se debería importar en index.js?
import JavascriptTimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es-MX'
import en from 'javascript-time-ago/locale/en'
import { MuiThemeProvider } from '@material-ui/core';
import theme from './themeMaterial';
JavascriptTimeAgo.addLocale(es);
JavascriptTimeAgo.addLocale(en);


const mainReducer = combineReducers({
    reducerGeneral: reducerGeneral,
    reducerParaData: reducerParaData,
    reducerParaTema: reducerParaTema,
    reducerParaUsuario: reducerParaUsuario,
    reducerParaFunctions: reducerParaFunctions,
});
const globalStore = createStore(mainReducer);

const renderApp = (store) => {
    ReactDOM.render(<Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </Provider>, document.getElementById("root"));
}

renderApp(globalStore)

serviceWorker.unregister();

// Enable Store Hot Module replacement
if (module.hot) {
    module.hot.accept("./App", ()=>{
      renderApp(globalStore);
    });
  }