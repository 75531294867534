import forEach from 'lodash/forEach';
import get from 'lodash/get';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import { MODELO_CONSECUENCIA, SIN_VALORAR } from '../../../scripts/constantes';



/**
 * funcion se usa debido a que en store se guardan por campo y por modelo de consecuencia 
 * y en visualizador de inventario se guardan directamente 
 * (esto porque es mas frecuente adaptar la info solo cuando se cambie de campo, en lugar de hacerlo cada renderizado, debido a que las tablas necesitan la informacion sin modelo)
 */
export const infoCampoDeInventarioAStore = (dbData) => {
    const toReturn = {}
    const estructuraDeCampo = {
        [MODELO_CONSECUENCIA.CUALIT]: [],
        [MODELO_CONSECUENCIA.SEMICUANT]: [],
        [SIN_VALORAR]: []
    }
    forEach(dbData, function (prds, campo) {
        toReturn[campo] = cloneDeep(estructuraDeCampo);
        prds.forEach((prd) => {
            const estaValorada = get(prd, "estados.valorada");
            toReturn[campo][estaValorada ? prd.parametrosDeEntrada.modeloDeConsecuencia : SIN_VALORAR].push(prd)
        })
    });
    return toReturn;
};
/**
 * funcion se usa debido a que en store se guardan por campo y por modelo de consecuencia 
 * y en visualizador de inventario se guardan directamente 
 * (esto porque es mas frecuente adaptar la info solo cuando se cambie de campo, en lugar de hacerlo cada renderizado, debido a que las tablas necesitan la informacion sin modelo)
 */
export const infoCampoStoreAInventario = (storeData) => {
    const toReturn = {}
    //for each campo
    forEach(storeData, function (infoCampo, campo) {
        //{semicuantitativo:[prds]} en [prds]
        toReturn[campo] = flatten(values(infoCampo))
    });
    return toReturn
}