import React from 'react';
import { connect } from 'react-redux';
import { Row, Col,  } from 'reactstrap';
import Swal from 'sweetalert2';

import { FaListUl, FaMapPin, FaChartArea, FaCalculator, FaRedoAlt, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

//Componentes auxiliares:
import Error404 from "../../../../404";
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import CardDeFicheros from './componentes/CardDeFicheros';
import CardDeResultados from './componentes/CardDeResultados';
import CustomSection from '../../../../../utilidades/componentes/plantillaBase/CustomSection';
import GraficoCartesianoCompuesto from '../../../../../utilidades/componentes/visualizadores/GraficoCartesianoCompuesto'
import LogDeCalculos from './componentes/LogDeCalculos';
import MatrizDeRiesgo from '../../../../../utilidades/componentes/resumenDeModulo/MatrizDeRiesgo';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';
import TablaDeNodos from '../../../../../utilidades/componentes/resumenDeModulo/TablaDeNodos';
import TablaEditable from './componentes/TablaEditable';
import ConsolaDeEdicionDeActivo from './componentes/ConsolaDeEdicionDeActivo';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//Utilidad de base de datos:
import { firestoreDB, firebaseFunctions } from '../../../../../firebase';

//Funciones para manipular state redux:
import { actualizarDataApi580, actualizarDataApi1160, actualizarDataAsmeB318s  } from '../../../../../storage/reducerParaData';


//Scripts auxiliares:
import { obtenerNodosConSubcolecciones, obtenerNodoConSubcoleccion } from '../../../../../utilidades/scripts/paraConsultaDeNodos';
import {confirmacionDeBorradoDeActivo} from '../../../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/estaticos/alertasParaSwal';
import MapaDeActivo from './componentes/MapaDeActivo';

import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection';
import { get } from 'lodash';

const eliminarActivo = firebaseFunctions.httpsCallable('eliminarActivo');
const calcularActivo = firebaseFunctions.httpsCallable('calcularActivo');

//Estilos base:
const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaParrafosDeInfo = { ...estiloDeFuentesBase, textAlign: "right", marginBottom: 2 };
const estiloParaHipervinculo = { marginLeft: 5, marginRight: 5, cursor: "pointer", textDecoration: "underline" };

//Constantes para el desarrollo:
const permitirConsultas = true;

class VisualizadorDeActivos extends React.Component {

    constructor() {
        super();

        this.state = {
            idTarget: "",
            nodo: undefined,
            coleccionActiva: "",
            cargando: false,
            error: false,

            //modal Edicion 
            modalEdicionVisible: false,

            cargaCompleta: false,

            todosLosSegmentos: [],

            itemsColumnaIzquierda: {
                1: {
                    activo: true,
                    tipo: "TABLA_DE_NODOS",
                    mostrar: true,
                    props: {
                        criterioDeOrdenamiento: {
                            id: "riesgo", desc: true
                        }
                    }
                },
                2: {
                    activo: true,
                    mostrar: true,
                    tipo: "GRAFICA_ABSCISADA",
                    tituloDelCard: "PoF por amenazas independientes del tiempo",
                    props: {
                        titulo: 'PoF cuantitativa vs abscisa [km]',
                        xAxisKey: 'variablesDeLaInspeccion.abscisaInicial.valor',

                        componentesGraficos: [
                            {
                                dataKey: 'variablesDeLaInspeccion.areaDeAltaConsecuencia.valor',
                                name: 'Zona de alta consecuencia',
                                check: true,
                                stroke: '#8d93ab',
                                fill: '#8d93ab',
                                fillOpacity: 0.3,
                                tipo: 'area',
                                yAxisId: 'left',
                                funcionDeFormato: (dato) => {
                                    if (!dato) { return 0; }
                                    else {
                                        try {
                                            const datoEnMinusculas = dato.toLowerCase();

                                            if (datoEnMinusculas === "si" || datoEnMinusculas === "s" || datoEnMinusculas === "sí") { return 1; }
                                            else { return 0; }
                                        }
                                        catch (error) { return 0; };
                                    }
                                }
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.climaYFuerzasExternas.pof',
                                name: 'Agrietamiento por fatiga',
                                check: true,
                                stroke: 'red',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.dañosPorTerceros.pof',
                                name: 'Daños por terceros',
                                check: true,
                                stroke: 'green',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.defectosDeConstruccion.pof',
                                name: 'Defectos de construcción',
                                check: true,
                                stroke: 'blue',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.defectosDeManufactura.pof',
                                name: 'Defectos de manufactura',
                                check: true,
                                stroke: 'orange',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.fallaDeEquipos.pof',
                                name: 'Falla de equipos',
                                check: true,
                                stroke: 'black',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.operacionesIncorrectas.pof',
                                name: 'Operaciones incorrectas',
                                check: true,
                                stroke: 'purple',
                                tipo: 'line',
                                yAxisId: 'left',
                            }
                        ],
                    }
                },
                3: {
                    activo: true,
                    mostrar: true,
                    tipo: "GRAFICA_ABSCISADA",
                    tituloDelCard: "PoF por amenazas  dependientes del tiempo",
                    props: {
                        titulo: 'PoF cuantitativa vs abscisa [km]',
                        xAxisKey: 'variablesDeLaInspeccion.abscisaInicial.valor',

                        componentesGraficos: [
                            {
                                dataKey: 'variablesDeLaInspeccion.areaDeAltaConsecuencia.valor',
                                name: 'Zona de alta consecuencia',
                                check: true,
                                stroke: '#8d93ab',
                                fill: '#8d93ab',
                                fillOpacity: 0.3,
                                tipo: 'area',
                                yAxisId: 'left',
                                funcionDeFormato: (dato) => {
                                    if (!dato) { return 0; }
                                    else {
                                        try {
                                            const datoEnMinusculas = dato.toLowerCase();

                                            if (datoEnMinusculas === "si" || datoEnMinusculas === "s" || datoEnMinusculas === "sí") { return 1; }
                                            else { return 0; }
                                        }
                                        catch (error) { return 0; };
                                    }
                                }
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.agrietamientoFatiga.pof',
                                name: 'Agrietamiento por fatiga',
                                check: true,
                                stroke: 'orange',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.agrietamientoSCC.pof',
                                name: 'Agrietamiento SCC',
                                check: true,
                                stroke: 'blue',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.corrosionInterna.pof',
                                name: 'Corrosión interna',
                                check: true,
                                stroke: 'red',
                                tipo: 'line',
                                yAxisId: 'left',
                            },
                            {
                                dataKey: 'resultadosDeLaValoracion.probabilidad.corrosionExterna.pof',
                                name: 'Corrosión externa',
                                check: true,
                                stroke: 'green',
                                tipo: 'line',
                                yAxisId: 'left',
                            }
                        ],
                    }
                },
                4: {
                    activo: true,
                    tipo: "LISTA_DE_PROPIEDADES",
                    mostrar: true
                },
            },
            itemsColumnaDerecha: {
                1: {
                    activo: true,
                    tipo: "MATRIZ_DE_RIESGO",
                    props: {
                        criterioDeOrdenamiento: {
                            id: "riesgo", desc: true
                        }
                    },
                    mostrar: true,
                },
                2: {
                    activo: true,
                    tipo: "MAPA_DEL_ACTIVO",
                    mostrar: true,
                },
                3: {
                    activo: true,
                    mostrar: true,
                    tipo: "GRAFICA_ABSCISADA",
                    tituloDelCard: "Riesgo total del activo",
                    props: {
                        titulo: 'Riesto total [$/y] vs abscisa [km]',
                        xAxisKey: 'variablesDeLaInspeccion.abscisaInicial.valor',

                        componentesGraficos: [
                            {
                                dataKey: 'resultadosDeLaValoracion.riesgo.riesgoFinanciero',
                                name: 'Riesgo total',
                                check: true,
                                stroke: 'blue',
                                fill: 'rgb(150,150,150,0.5)',
                                tipo: 'line',
                                yAxisId: 'left',
                            }
                        ],
                    }
                },
                4: {
                    activo: true,
                    tipo: "FICHEROS",
                    mostrar: true
                },
                5: {
                    activo: true,
                    tipo: "LOG_DE_CALCULOS",
                    props: {
                        scopeTarget: "probabilidad"
                    },
                    mostrar: true
                },
                6: {
                    activo: true,
                    tipo: "LOG_DE_CALCULOS",
                    props: {
                        scopeTarget: "consecuencia"
                    },
                    mostrar: true
                },
            },
        }
    }

    render() {
        const nodo = this.state.nodo;
        if (!this.state.error) {
            if (nodo !== undefined && this.state.cargaCompleta) {
                return (
                    <CustomSection
                        titulo={nodo.informacionGeneral.nombre.valor}
                        subtitulo={"Componente tipo: " + get(nodo,"informacionGeneral.tipoDeActivo.valor","No se pudo obtener este dato").toUpperCase()}
                        URLIconoPrincipal="/assets/img/iconos/iconoSegmento.jpg"
                        botonesAdicionales={[
                            <BotonParaSection key="BotonDeActualizacionDeActivo" icono={<FaRedoAlt />} onClick={this.actualizarNodo} title={'Actualizar'} />,
                            <BotonParaSection key="BotonDeCalculoDeActivo" icono={<FaCalculator />} onClick={this.reCalcularNodo} title={'Recalcular'} />,
                            <BotonParaSection 
                                key="BotonDeEdicionDeActivo" 
                                icono={<FaEdit />} 
                                onClick={this.toggleDelModalDeEdicion} 
                                title={'Editar Tag de Activo'} 
                            />,
                            <BotonParaSection 
                                key="BotonEliminarActivo" 
                                icono={<FaTrashAlt />} 
                                onClick={this.eliminarActivo} 
                                title={'Eliminar'} 
                            />
                        ]}>

                        {this.obtenerInfoDeLosPadres()}

                        <Row>
                            <CardDeResultados
                                consecuencia={nodo.resultadosDeLaValoracion.consecuencia}
                                scope="consecuencia" />

                            <CardDeResultados
                                probabilidad={nodo.resultadosDeLaValoracion.probabilidad}
                                scope="probabilidad"
                            />
                        </Row>
                        <Row>
                            <Col xs="12" md="12" lg="7" xl="7">
                                {this.obtenerContenidoDeColumna("itemsColumnaIzquierda")}
                            </Col>

                            <Col xs="12" md="12" lg="5" xl="5">
                                {this.obtenerContenidoDeColumna("itemsColumnaDerecha")}
                            </Col>
                        </Row>
                        <ToastContainer />
                        <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                        <ConsolaDeEdicionDeActivo
                            elModalEstaAbierto={this.state.modalEdicionVisible}
                            toggleDelModal={this.toggleDelModalDeEdicion}
                            activo={this.state.nodo}
                            onSuccess={this.edicionDeActivoExitosa}
                        />
                    </CustomSection>
                );
            }
            else {
                return (<Preloader elPreloaderEstaAbierto={true} />);
            }
        }
        else {
            return (<Error404 error={this.props.location.pathname} />);
        }

    }
    

    componentDidMount() {
        this.actualizarNodo();
        this.setState({ moduloTarget: this.props.match.params.modulo });
    }
    componentDidCatch() {
        this.setState({
            error: true,
        })
    }

    //Métodos operativos:
    actualizarDataEnVisualizadorPrincipal = (accion) => {
        if(!this.props.moduloActivo) return
/* ------------- Obtiene informacion de acuerdo a modulo activo ------------- */

        let dataActual
        let nuevoListadoDeActivos = []
        let actualizarDataEnRedux

        switch(this.props.moduloActivo){
            case 'API581':
                dataActual = this.props.dataDe580EnRedux
                nuevoListadoDeActivos = this.props.dataDe580EnRedux.activos
                actualizarDataEnRedux = this.props.actualizarDataApi580
                break;
            case 'API1160':
                dataActual = this.props.dataDe1160EnRedux
                nuevoListadoDeActivos = this.props.dataDe1160EnRedux.activos
                actualizarDataEnRedux = this.props.actualizarDataApi1160
                break;
            case 'ASMEB31_8S':
                dataActual = this.props.dataDeB318sEnRedux
                nuevoListadoDeActivos = this.props.dataDeB318sEnRedux.activos
                actualizarDataEnRedux = this.props.actualizarDataAsmeB318s
                break;
            default:
                break;
        }

        /* ---------- Reemplaza el nodo actual en el array con el nuevo tag --------- */
        
        let listadoActivos = nuevoListadoDeActivos
        const indexDelNodo = nuevoListadoDeActivos.findIndex(nodo => nodo.id === this.state.nodo.id)
        switch (accion) {
            case 'delete':
                if(indexDelNodo >= 0){
                    //nuevoListadoDeActivos.splice(indexDelNodo, 1)
                    listadoActivos.splice(indexDelNodo, 1)
                }
                break;
            case 'update':
                if(indexDelNodo >= 0){
                    nuevoListadoDeActivos.splice(indexDelNodo, 1, this.state.nodo)
                }
                break;

            default:
                break;
        }   
/* --------------------- Actualiza informacion en redux --------------------- */

        actualizarDataEnRedux(
            {
                ...dataActual, 
                activos: nuevoListadoDeActivos,
            }
        )
    }

    actualizarNodo = (actualizarRedux) => {
        if (permitirConsultas) {
            const coleccionActiva = "activos";
            const id = this.props.match.params.id;
            const moduloTarget = this.props.match.params.modulo;

            this.setState({ cargando: true, });

            firestoreDB.collection(coleccionActiva).doc(id).get()
                .then(snapshot => {
                    let nodoTarget = { id: snapshot.id, ...snapshot.data(), referencia: snapshot.ref };
                    obtenerNodoConSubcoleccion(nodoTarget, moduloTarget, true, true)
                        .then(nodo => {
                            this.setState({ id: snapshot.id, coleccionActiva: coleccionActiva, nodo: nodo, },
                                () => { this.actualizarNodosDeTablas(actualizarRedux) });
                        })
                        .catch(error => { console.log(error); });
                })
                .catch(error => { 
                    this.setState({ error: true })
                    console.log(error); });
        }
        else {
            this.setState({
                cargaCompleta: true,
                nodo: require('../api580/estaticos/ejemploDeActivos.json')[0],
                todosLosSegmentos: [require('../api580/estaticos/ejemploDeSegmentos.json')[0], require('../api580/estaticos/ejemploDeSegmentos.json')[1]],
            });
        }
    }

    actualizarNodosDeTablas(actualizarRedux) {

        const idDelActivo = this.state.nodo.id;
        const moduloTarget = this.props.match.params.modulo;

        let promiseDeConsultaDeSegmentos = firestoreDB.collection("segmentos");
        promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
        promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("informacionDeLosPadres.activoAsociado.referencia", "==", firestoreDB.collection("activos").doc(idDelActivo));

        obtenerNodosConSubcolecciones(promiseDeConsultaDeSegmentos, moduloTarget, true, false)
            .then(segmentos => {
                this.setState({ todosLosSegmentos: segmentos });

                actualizarRedux && this.actualizarDataEnVisualizadorPrincipal('update')

                setTimeout(() => { this.setState({ cargaCompleta: true, cargando: false }); }, 500);
            })
            .catch(error => {
                console.log(error);
                this.setState({ cargando: false });
                window.document.body.style.cursor = "default";
            });
    }

    avisoDeGuardadoSatisfactorio = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }

    cambiarVariableSeleccionada = (key) => {
        this.setState({
            mensajeValidacion: "Se modificó variable a validar"
        });
    }
    
    edicionDeActivoExitosa = () => {
        this.actualizarNodo(true);
        this.toggleDelModalDeEdicion()
    }

    eliminarActivo = () => {
        let estructuraElimicacion = {
            "activo": this.state.nodo.id,
        }
        let miConfirmacionDeBorradoDeActivo = { ...confirmacionDeBorradoDeActivo };
        miConfirmacionDeBorradoDeActivo.html = miConfirmacionDeBorradoDeActivo.html.replace("@nombreDelFichero", this.state.nodo.informacionGeneral.nombre.valor);
        Swal.fire(miConfirmacionDeBorradoDeActivo)
            .then(result => {
                if (result.isConfirmed) {
                    this.actualizarDataEnVisualizadorPrincipal('delete')
                    eliminarActivo(estructuraElimicacion)
                        .then(result2 => {
                            console.log(result2)
                            if(result2.data.status === "ACTIVO_ELIMINADO"){
                                this.avisoDeGuardadoSatisfactorio(result2.data.message || 'Activo eliminado', 'success')
                                this.actualizarDataEnVisualizadorPrincipal('delete')
                                this.navegarHaciaPath(localStorage.getItem('regresarA'))
                            } else {
                                this.avisoDeGuardadoSatisfactorio(result2.data.message || 'Error al eliminar activo', 'error')
                            }
                        })
                        .catch(error => { 
                            this.avisoDeGuardadoSatisfactorio(error.message || 'Error al eliminar activo', 'error')
                        });
                }
            });
    }

    obtenerContenidoDeColumna = (keyEnState) => {
        let columnaTarget = { ...this.state[keyEnState] };
        let keys = Object.keys(columnaTarget);
        let items = keys.map((key, i) => { return columnaTarget[key]; });
        items = items.filter(paramsDeComponente => paramsDeComponente.activo);
        return items.filter(item => item.mostrar).map((item, i) => {
            return (
                <React.Fragment key={keyEnState + "." + i}>
                    {this.obtenerContenidoBasadoEnTipo(item)}
                </React.Fragment>
            );
        });
    }

    obtenerContenidoBasadoEnTipo = (parametrizacionDelContenido) => {
        const moduloTarget = this.props.match.params.modulo;
        switch (parametrizacionDelContenido.tipo) {
            case "FICHEROS":
                return (
                    <CardDeFicheros
                        nodo={this.state.nodo}
                        coleccionTarget={this.state.coleccionActiva} />
                );
            case "GRAFICA_ABSCISADA":
                if (moduloTarget === "API1160" || moduloTarget === "ASME_B31_8S") {
                    return (
                        <CardConEncabezado titulo={parametrizacionDelContenido.tituloDelCard} icono={FaChartArea}>
                            <GraficoCartesianoCompuesto
                                nodos={this.state.todosLosSegmentos}
                                {...parametrizacionDelContenido.props}
                                 />
                        </CardConEncabezado>
                    );
                }
                else { return (<React.Fragment />); }
            case "LISTA_DE_PROPIEDADES":
                return (
                    <TablaEditable
                        tipoActivo = {this.state.nodo.informacionGeneral.tipoDeActivo.valor}
                        titulo={"Lista de propiedades"}
                        icono={FaListUl}
                        listaDeDatos={this.obtenerListaDeVariablesDesdeObjeto(this.state.nodo, 'variablesDeLaInspeccion')}
                        coleccionActiva={'activos'}
                        idNodo={this.state.nodo.id}
                    />
                );
            case "LOG_DE_CALCULOS":
                return (
                    <LogDeCalculos nodo={this.state.nodo}
                        {...parametrizacionDelContenido.props} />
                );
            case "MAPA_DEL_ACTIVO":
                if (moduloTarget === "API1160" || moduloTarget === "ASME_B31_8S") {
                    return (
                        <CardConEncabezado titulo={'Georeferencia del activo'} icono={FaMapPin}>
                            {/* <MapaDeActivo */}
                            <MapaDeActivo
                                nodo={this.state.nodo}
                                moduloTarget={moduloTarget}
                                parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget]}
                                segmentos={this.state.todosLosSegmentos} />
                        </CardConEncabezado>
                    );
                }
                else { return (<React.Fragment />); }
            case "MATRIZ_DE_RIESGO":
                return (
                    <MatrizDeRiesgo
                        coleccionActiva={'segmentos'}
                        listaDeNodos={this.state.todosLosSegmentos}
                        moduloTarget={moduloTarget}
                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget]} />
                );
            case "TABLA_DE_NODOS":
                return (
                    <CardConEncabezado titulo={`Segmentos que pertenecen al activo ${this.state.nodo.informacionGeneral.nombre.valor}`} icono={FaListUl}>
                        <TablaDeNodos
                            tipoActivo = {this.state.nodo.informacionGeneral.tipoDeActivo.valor}
                            coleccionActiva={'segmentos'}
                            listaDeNodos={this.state.todosLosSegmentos}
                            moduloTarget={moduloTarget}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget]}
                            {...parametrizacionDelContenido.props} />
                    </CardConEncabezado>
                );
            default:
                return (<React.Fragment />);
        }
    }

    obtenerListaDeVariablesDesdeObjeto = (objetoBase, keyDeDatosTarget) => {
        let objetoDeDatos = objetoBase[keyDeDatosTarget];
        let keysDeDatos = Object.keys(objetoBase[keyDeDatosTarget]);

        return keysDeDatos.map((key, i) => { return { key: key, ...objetoDeDatos[key], indice: i }; });
    }

    obtenerNombreDeColeccionEnSingular = (coleccion) => {
        switch (coleccion) {
            case "campos":
                return "campo";
            case "sistemas":
                return "sistema";
            case "activos":
                return "activo";
            case "segmentos":
                return "segmento";
            default:
                return coleccion;
        }
    }

    obtenerValorFormateado = (valor) => {
        let valorNumerico = parseFloat(valor);

        if (isNaN(valorNumerico)) { return valor; }
        else { return valorNumerico.toFixed(3); }
    }

    navegarHaciaPath = (path) => {
        this.props.history.push(path);
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }

    reCalcularNodo = () => {
        calcularActivo({ "id": this.state.nodo.id, "dominioCorporativo": this.state.nodo.dominioCorporativo })
            .then(respuesta => { this.actualizarNodo(); })
            .catch(error => console.log(error));

        this.avisoDeGuardadoSatisfactorio('Proceso de cálculo iniciado con éxito', 'warning');
    }

    toggleDelTab(tab) {
        if (this.state.tabActivo !== tab) {
            this.setState({
                tabActivo: tab
            });
        }
    }

    toggleDelModalDeEdicion = () => {
        this.setState({
            modalEdicionVisible: !this.state.modalEdicionVisible
        })
    }

    //Métodos para redirección:
    navegarHaciaPath = (path) => {
        this.props.history.push(path);
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }

    navergarHaciaSistemaPadre = () => {
        const moduloTarget = this.props.match.params.modulo;
        const pathTarget = "/app/detalleDeNodo/sistemas/" + this.state.nodo.informacionDeLosPadres.sistemaAsociado.referencia.id + "/" + moduloTarget;

        this.navegarHaciaPath(pathTarget);
    }

    navergarHaciaCampoPadre = () => {
        this.state.nodo.informacionDeLosPadres.sistemaAsociado.referencia.get()
            .then(snapshotDelSistema => {
                const sistemaPadre = snapshotDelSistema.data();
                const moduloTarget = this.props.match.params.modulo;
                const pathTarget = "/app/detalleDeNodo/campos/" + sistemaPadre.informacionDeLosPadres.campoAsociado.referencia.id + "/" + moduloTarget;
                this.navegarHaciaPath(pathTarget);
            })
            .catch(error => { console.log(error); });
    }

    //Métodos para renderizado:
    obtenerInfoDeLosPadres = () => {
        if (this.state.cargaCompleta) {
            let listaDeContenido = [];
            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo }} key={"infoDePadres." + this.state.nodo.id + ".nombre"}
                    dangerouslySetInnerHTML={{
                        __html:
                            "Estoy viendo los detalles del " + this.obtenerNombreDeColeccionEnSingular(this.state.coleccionActiva)
                            + " <b>" + this.state.nodo.informacionGeneral.nombre.valor + "</b>"
                    }} />
            );

            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo, marginBottom: 20, }}
                    key={"infoDePadres." + this.state.nodo.id + ".paraActivo"}>
                    Pertenece al sistema
                    <b style={{ ...estiloParaHipervinculo }} onClick={this.navergarHaciaSistemaPadre}>
                        {this.state.nodo.informacionDeLosPadres.sistemaAsociado.nombre}
                    </b>
                    del campo
                    <b style={{ ...estiloParaHipervinculo, marginRight: 0 }} onClick={this.navergarHaciaCampoPadre}>
                        {this.state.nodo.informacionDeLosPadres.campoAsociado.nombre}
                    </b>
                </p>
            );


            return listaDeContenido;
        }
        else {
            return "Cargando...";
        }
    }

}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa,
    dataDe580EnRedux: state.reducerParaData["/app/analisis/riesgo/api580"],
    dataDe1160EnRedux: state.reducerParaData["/app/analisis/riesgo/api1160"],
    dataDeB318sEnRedux: state.reducerParaData["/app/analisis/riesgo/b318s"],
    moduloActivo: state.reducerParaData['moduloActivo']
});

const mapDispatchToProps = dispatch => ({
    actualizarDataApi580: nuevaData => { dispatch(actualizarDataApi580(nuevaData)); },
    actualizarDataApi1160: nuevaData => { dispatch(actualizarDataApi1160(nuevaData)); },
    actualizarDataAsmeB318s: nuevaData => { dispatch(actualizarDataAsmeB318s(nuevaData)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisualizadorDeActivos));