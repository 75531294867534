import { firebaseFunctions } from '../../firebase';
// const cargarOrdenDeTrabajo = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajo');
const cargueMasivoAPI570 = firebaseFunctions.httpsCallable('cargueMasivoAPI570');
const cargueMasivoAPI510 = firebaseFunctions.httpsCallable('cargueMasivoAPI510');

export const cargarDatosDeIntegridad = async(matrizDeDatos, batch, callbackParaCargue, modulo) => {
    if (batch){
        try {
            let promesasResueltas;
            for (let promise of matrizDeDatos) {
                switch(modulo) {
                    case 'api510':
                        promesasResueltas = await cargueMasivoAPI510(promise)
                    default:
                        promesasResueltas = await cargueMasivoAPI570(promise)
                }
                procesarMensajesDeBack(promesasResueltas, callbackParaCargue) 
            }
        }
        catch(error){console.log(error)}
    } else {
        try {
            let promesa
            console.log('esta es la matriz de datos', matrizDeDatos)
            switch(modulo) {
                case 'api510':
                    promesa = await cargueMasivoAPI510(matrizDeDatos)
                default:
                    promesa = await cargueMasivoAPI570(matrizDeDatos)
            }
            procesarMensajesDeBack(promesa, callbackParaCargue)
        }
        catch(error){console.log(error)}
        // cargueMasivoAPI570(matrizDeDatos)
        //     .then( result =>{
        //         procesarMensajesDeBack(result, callbackParaCargue)
        //         resolve();
        //     })
        //     .catch(e =>{
        //         console.log('error cargar activo', e);
        //     })
    }
}

const procesarMensajesDeBack = (result, callbackParaCargue) => {
    console.log('este es el resultado de back', result)
    if(result.data.status === 'STATUS_SUCCESS') {
        for(let  msg in result.data.message) {
            if (typeof(result.data.message[msg]) === 'object') {
                for ( let status in result.data.message[msg]) {
                    callbackParaCargue(result.data.message[msg][status], 'success')
                }
            } else {
                callbackParaCargue(result.data.message[msg], 'success')
            }
        }
    } else if (result.data.status === 'STATUS_ERROR'){
        for(let  msg in result.data.message) {
            if (typeof(result.data.message[msg]) === 'object') {
                for ( let status in msg) {

                    callbackParaCargue(result.data.message[msg][status], 'danger')
                }
            } else {
                callbackParaCargue(result.data.message[msg], 'danger')
            }
        }

    }
}