export const parametrosDeEstados = {
    'PLANEADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'PLANEADA',
        'estado': 'PLANEADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#1E96FC'
    },
    'PROGRAMADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'PROGRAMADA',
        'estado': 'PROGRAMADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#ED0101'
    },
    'EN_SITIO': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'EN SITIO',
        'estado': 'EN_SITIO',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#F5CC00'
    },
    'INSPECCIONADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'INSPECCIONADA',
        'estado': 'INSPECCIONADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#6D15D0'
    },
    'EJECUTADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'EJECUTADA',
        'estado': 'EJECUTADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#001D3D'
    },
    'REVISADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'REVISADA',
        'estado': 'REVISADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#F56A00'
    },
    'CONTROL_CALIDAD': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'CONTROL DE CALIDAD',
        'estado': 'CONTROL_CALIDAD',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#922B21'
    },
    'FACTURADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'FACTURADA',
        'estado': 'FACTURADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#43B929'
    },
    'CANCELADA': {
        'claseDeLaCaja' : 'widget-chart widget-chart-hover border pt-1',
        'tituloDelModal' : 'CANCELADA',
        'estado': 'CANCELADA',
        'claseDelBadge' : 'widget-numbers mt-0',
        'claseNumeroInspecciones' : 'widget-subheading',
        'claseDelProgress' : 'widget-description',
        'color': '#ADACAC'
    },
}