export const glosarioDeInputsAgregarUsuario = {
    nombre: {
        key: 'nombre',
        type: 'text',
        mensaje: 'mensajeNombre',
        label: 'Nombre :',
        categoria: "valorAbierto",
        'col-sm': 12,
        'col-md': 12,
        'col-lg': 12,
        'col-xl': 12
    },
    correo: {
        key: 'correo',
        type: 'text',
        tipo: 'correo_electronico',
        mensaje: 'mensajeCorreo',
        label: 'Correo :',
        categoria: "valorUnico",
        'col-sm': 12,
        'col-md': 12,
        'col-lg': 12,
        'col-xl': 12
    },
    rol: {
        key: 'rol',
        type: 'select',
        opciones: [
            { domain: undefined, valor: 'tecnico', label: 'Técnico' },
            { domain: undefined, valor: 'supervisor', label: 'Supervisor' },
            { domain: undefined, valor: 'cliente', label: 'Cliente' },
            { domain: 'insercor.com', valor: 'admin', label: 'Administrador' },
        ],
        mensaje: 'mensajeRol',
        label: 'Rol que desempeña :',
        categoria: "valorAbierto",
        'col-sm': 12,
        'col-md': 12,
        'col-lg': 12,
        'col-xl': 12
    },
}



