import React from 'react';
import ReactTable from 'react-table';

import Pagination from './Pagination';

//Constantes auxiliares:
const largoDePaginacionDefault = 20;

let CustomTableDinamica = (props) => {
    let mostrarPaginacion = true;
    if (props.mostrarPaginacion !== undefined) { mostrarPaginacion = props.mostrarPaginacion };
    if (mostrarPaginacion && props.listaDeDatos && props.listaDeDatos.length < largoDePaginacionDefault) { mostrarPaginacion = false; };

    let largoDePaginacion = largoDePaginacionDefault;
    if (props.largoDePaginacion) { largoDePaginacion = props.largoDePaginacion; };
    if (props.listaDeDatos && props.listaDeDatos.length < largoDePaginacion) { largoDePaginacion = props.listaDeDatos.length; };

    return (
        <ReactTable
            key={props.keyDeTabla + 'Tabla dinámica'}
            PaginationComponent={Pagination}    
            defaultSorted={[props.criterioDeOrdenamiento || {}]}
            pageText={'Página'} ofText={'de'} showPagination={mostrarPaginacion}
            defaultPageSize={largoDePaginacion} previousText={'Anterior'} nextText={'Siguiente'}
            rowsText={'filas'} data={props.listaDeDatos} className="-striped -highlight"
            columns={props.columnas}
            style={props.style}
        />
    );
}

export default CustomTableDinamica;