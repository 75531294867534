import React from 'react';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';

import { FaListUl, FaCalculator, FaRedoAlt, FaWrench, FaRegTrashAlt } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

//Componentes auxiliares:
import Error404 from "../../../../404";
import CardDeResultados from './componentes/CardDeResultados';
import CustomSection from '../../../../../utilidades/componentes/plantillaBase/CustomSection';
import LogDeCalculos from './componentes/LogDeCalculos';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';
import TablaEditable from './componentes/TablaEditable';
import Swal from 'sweetalert2';
import confirmacionDeActualizacionDeTabla, { confirmacionDeEliminacionDeActivo } from '../../../../../utilidades/almacen/alertasParaSwal';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//Utilidad de base de datos:
import { firestoreDB, firebaseFunctions } from '../../../../../firebase';


//Scripts auxiliares:
import { obtenerNodosConSubcolecciones } from '../../../../../utilidades/scripts/paraConsultaDeNodos';

import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection';
//import VisualizadorModelado from '../api581Valvulas/componentes/VisualizadorModelado'
import { actualizarModeloDeConsecuencia as tryActualizarModeloDeConsecuencia, obtenerSoloUnPRD } from '../api581Valvulas/scripts/consultasDB';
import { Grid, Typography, Hidden, Divider, Chip } from '@material-ui/core';

// Accordions libraries 

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from "@material-ui/core/Badge";
import Tooltip from '@material-ui/core/Tooltip';
import has from 'lodash/has';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import aplicarGetters from '../api581Valvulas/scripts/getters';
import VisualizadorDePlanes from '../api581Valvulas/componentes/VisualizadorTablaDePlanes';
import CardDeFicheros from './componentes/CardDeFicheros';
import cloneDeep from 'lodash/cloneDeep';
import { actualizarDataApi581PRD } from '../../../../../storage/reducerParaData';
import union from 'lodash/union';
import { obtenerCantidadEnNotacionCorta } from '../../../../../utilidades/scripts/paraMoneda';
import { obtenerColorSegunValorCualitativo, obtenerColorSegunNivel } from '../../../../../utilidades/scripts/paraVisualizacionDeRangos';
import { get } from 'lodash';
import BarraTitulo from '../../../gestion/api581Valvulas/componentes/BarraTitulo';

const { glosarioDeVariablesPRD: glosarioPRD } = require('../../../../../utilidades/scripts/glosarioDeVariablesPRD')
const recalcularPRD = firebaseFunctions.httpsCallable('recalcularPRD');
const eliminarActivoPRD = firebaseFunctions.httpsCallable('eliminarActivoPRD');

const clonedeepPRDenVisualizador = require('lodash/cloneDeep');


//Constantes para el desarrollo:
const permitirConsultas = true;

// Items  


class VisualizadorDeValvulas extends React.Component {

    constructor() {
        super();

        this.state = {
            idTarget: "",
            nodo: undefined,
            coleccionActiva: "",
            cargando: false,
            error: false,

            cargaCompleta: false,

            todosLosSegmentos: [],
            listaVariable: [],
            contadorRender: 0,
            listaDeCambios: [], // Almacenada las variables con los cambios que se hagan en el resultado de la busqueda
            listaDeInterseccionConBuscador: [],
            parametrosModificados: false,
        }

        this.listaDeParametros = this.propiedadesParaELBuscador();
        this.listaDeObjectosPRD = {};
        this.listaConDatos = [];
        this.verificarPropiedades = false;
    }

    render() {
        const nodo = this.state.nodo;
        if (!this.state.error) {
            if (nodo !== undefined && this.state.cargaCompleta) {
                const infoGeneralPRD = nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral
                const infoEqProte = nodo.parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral
                const modeloDeConsecuencia = nodo.parametrosDeEntrada.modeloDeConsecuencia;
                const riesgoTarget = modeloDeConsecuencia === "Cualitativo" ? nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCual : nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCuant;
                return (
                    <CustomSection
                        titulo={nodo.nombre + ""}
                        subtitulo={`${infoGeneralPRD.campo || "Sin campo"}/${infoGeneralPRD.plantaOestacion || "Sin planta o estación"}/${infoGeneralPRD.servOsistem || "Sin serivicio o sistema"}/${infoEqProte.tipoDeEquipo || "Sin tipo de equipo"}`}
                        URLIconoPrincipal="/assets/img/iconos/iconoSegmento.jpg"
                        botonesAdicionales={[
                            <BotonParaSection key="BotonDeActualizacionDeActivo" icono={<FaRedoAlt />} onClick={this.actualizarNodo} title={'Actualizar'} />,
                            <Tooltip key="BotonDeCalculoDeActivo" arrow placement="top-end" disableHoverListener={!this.state.parametrosModificados} title="Se requiere recalcular debido a que se han detectado modificaciones en los parámetros"  >
                                <Badge color="secondary" variant="dot" invisible={!this.state.parametrosModificados}>
                                    <BotonParaSection icono={<FaCalculator />} onClick={this.reCalcularNodo} title={'Recalcular'} />
                                </Badge>
                            </Tooltip>,
                            <BotonParaSection key="BotonDeCambioDeModelo" icono={<FaWrench />} onClick={() => { this.cambiarModeloConsecuencia() }} title={'Cambiar Modelo de Consecuencia'} />,
                            <BotonParaSection key="BotonDeEliminarActivo" icono={<FaRegTrashAlt />} onClick={() => { this.eliminarActivo() }} title={'Eliminar Activo'} />
                        ]}>
                        <BarraTitulo titulo="VALORACIÓN DE RIESGO">
                            <Grid container spacing={2}  >
                                <Grid item >
                                    <Typography variant="caption" style={{ margin: 4 }}>Riesgo Target:</Typography>
                                    <Chip color="primary"
                                        style={{ backgroundColor: modeloDeConsecuencia === "Semicuantitativo" ? "none" : this.obtenerColorRiesgoTotal(riesgoTarget, true) }}
                                        /* variant="outlined"  */
                                        size="small"
                                        label={this.obtenerLabelRiesgoTotal(riesgoTarget)} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" style={{ margin: 4 }}>{`Riesgo ${nodo.parametrosDeEntrada.modeloDeConsecuencia === "Semicuantitativo" ? "Total: " : "Máximo: "}`}</Typography>
                                    <Chip color="primary"
                                        /* variant="outlined" */
                                        style={{ backgroundColor: this.obtenerColorRiesgoTotal(nodo.resultados[0].data.riesgo.total.valorCualitativo) }}
                                        size="small"
                                        label={this.obtenerLabelRiesgoTotal(nodo.resultados[0].data.riesgo.total.financiera) || nodo.resultados[0].data.riesgo.total.nivelDeRiesgo || '-'} />
                                </Grid>
                            </Grid>
                        </BarraTitulo>
                        <Grid container direction="row" style={{ marginTop: "1.5rem" }} >
                            <Grid item xs container direction="column" spacing={2}>
                                <Typography variant="h6" align="center" style={{ /* marginTop: "auto", marginBottom: "auto", */ fontWeight: "bold", color: "#504C4C", width: "100%" }}>
                                    Apertura
                                </Typography>
                                <Grid item container direction="row">
                                    <CardDeResultados
                                        consecuencia={nodo.resultados[0].data.consecuencia.apertura}
                                        scope="consecuencia"
                                        isPRD
                                        conBarraDeNivel
                                        modeloDeConsecuencia={nodo.parametrosDeEntrada.modeloDeConsecuencia}
                                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeConsecuencia}
                                        conFoot={`Modelo: ${nodo.parametrosDeEntrada.modeloDeConsecuencia}`}
                                    />
                                    <CardDeResultados
                                        probabilidad={{ total: nodo.resultados[0].data.probabilidad.apertura.total, nivelDeProbabilidad: nodo.resultados[0].data.probabilidad.apertura.nivelDeProbabilidad, ...nodo.resultados[0].data.probabilidad.apertura.pFprdj }}
                                        isPRD
                                        scope="probabilidad"
                                        conFoot="Modelo: Semicuantitativo"
                                        conBarraDeNivel
                                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeProbabilidad}
                                    />
                                    <CardDeResultados
                                        riesgo={nodo.resultados[0].data.riesgo.apertura}
                                        scope="riesgo"
                                        isPRD
                                        conFoot={<br/>}
                                        conBarraDeNivel
                                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeRiesgo}
                                    />
                                </Grid>
                            </Grid >
                            <Hidden xsDown>
                                <Grid item container direction="column" style={{ alignItems: "center", marginLeft: "0.5rem", marginRight: "0.5rem", width: "1rem" }}>
                                    <Divider orientation="vertical" />
                                </Grid>
                            </Hidden>
                            <Grid item xs container direction="column" spacing={2}>
                                <Typography variant="h6" align="center" style={{ /* marginTop: "auto", marginBottom: "auto", */ fontWeight: "bold", color: "#504C4C" }}>
                                    Fuga
                                </Typography>
                                <Grid item container direction="row">
                                    <CardDeResultados
                                        consecuencia={nodo.resultados[0].data.consecuencia.fuga}
                                        isPRD
                                        scope="consecuencia"
                                        conFoot={`Modelo: ${nodo.parametrosDeEntrada.modeloDeConsecuencia}`}
                                        conBarraDeNivel
                                        modeloDeConsecuencia={nodo.parametrosDeEntrada.modeloDeConsecuencia}
                                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeConsecuencia}
                                    />
                                    <CardDeResultados
                                        probabilidad={nodo.resultados[0].data.probabilidad.fuga}
                                        isPRD
                                        notClickable
                                        scope="probabilidad"
                                        conFoot="Modelo: Semicuantitativo"
                                        conBarraDeNivel
                                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeProbabilidad}
                                    />
                                    <CardDeResultados
                                        riesgo={nodo.resultados[0].data.riesgo.fuga}
                                        isPRD
                                        scope="riesgo"
                                        conBarraDeNivel
                                        conFoot={<br/>}
                                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeRiesgo}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {this.pintarListaDePlanes()}
                        <br />
                        <br />
                        {this.pintarListaDePropiedades()}
                        <br />
                        <br />
                        <BarraTitulo titulo={"LOG DE CALCULOS"} />
                        <Grid container direction="row" spacing={2} style={{ marginTop: 8 }}>
                            <Grid item xs>
                                <LogDeCalculos nodo={this.state.nodo}
                                    scopeTarget="probabilidad" />
                            </Grid>
                            <Grid item xs>
                                <LogDeCalculos nodo={this.state.nodo}
                                    scopeTarget="consecuencia" />
                                <CardDeFicheros
                                    nodo={this.state.nodo}
                                    coleccionTarget={this.state.coleccionActiva} />
                            </Grid>
                        </Grid>

                        <ToastContainer />
                        <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                    </CustomSection>
                );
            }
            else {
                return (<Preloader elPreloaderEstaAbierto={true} />);
            }
        }
        else {
            return (<Error404 error={this.props.location.pathname} />);
        }

    }


    componentDidMount() {
        this.actualizarNodo();
        this.setState({ moduloTarget: this.props.match.params.modulo });
    }
    componentDidCatch() {
        this.setState({
            error: true,
        })
    }

    //Métodos operativos:
    actualizarNodo = async () => {
        if (permitirConsultas) {
            const coleccionActiva = "prd";
            const id = this.props.match.params.id;
            // const moduloTarget = this.props.match.params.modulo;
            try {
                const datosValvula = await obtenerSoloUnPRD(id);
                if (datosValvula.resultados.length > 0) {
                    this.setState({ cargando: true, id: datosValvula.id, coleccionActiva: coleccionActiva, nodo: datosValvula, parametrosModificados: get(datosValvula, "estados.parametrosDeEntradaModificados", false) },
                        () => { this.actualizarNodosDeTablas() });
                } else {
                    this.setState({ error: true })
                }
            } catch (error) {
                this.snackbar("Error obteniendo información de base de datos","error")
                this.setState({ error: true })
            }

        }
        else {
            this.setState({
                cargaCompleta: true,
                nodo: require('../api580/estaticos/ejemploDeActivos.json')[0],
                todosLosSegmentos: [require('../api580/estaticos/ejemploDeSegmentos.json')[0], require('../api580/estaticos/ejemploDeSegmentos.json')[1]],
            });
        }
    }

    actualizarNodosDeTablas() {

        const idDelActivo = this.state.nodo.id;
        const moduloTarget = this.props.match.params.modulo;

        let promiseDeConsultaDeSegmentos = firestoreDB.collection("segmentos");
        promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
        promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("informacionDeLosPadres.activoAsociado.referencia", "==", firestoreDB.collection("activos").doc(idDelActivo));

        obtenerNodosConSubcolecciones(promiseDeConsultaDeSegmentos, moduloTarget, true, false)
            .then(segmentos => {
                this.setState({ todosLosSegmentos: segmentos });
                setTimeout(() => { this.setState({ cargaCompleta: true, cargando: false }); }, 500);
            })
            .catch(error => {
                console.log(error);
                this.setState({ cargando: false });
                window.document.body.style.cursor = "default";
            });
    }

    pintarListaDePlanes() {
        const isPlan = this.state.nodo.resultados.find(item => item.id === "API581" && item.data.planesDeAccion);
        if (isPlan) {
            return (
                <React.Fragment>
                    <BarraTitulo titulo={"PLANES DE INSPECCIÓN"} />
                    <br />
                    <VisualizadorDePlanes nodo={this.state.nodo} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }

    }

    pintarListaDePropiedades() {
        return (
            <React.Fragment>
                <div className={"mb-0 d-flex border rounded"} style={{ marginBottom: 2, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none', padding: '5px 10px' }}>

                    <Label style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black' }}>LISTA DE PROPIEDADES</Label>

                    <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                        <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={this.listaDeParametros}
                            getOptionLabel={(option) => option.nombre}
                            defaultValue={[]}
                            onChange={(event, value) => {
                                let contador = this.state.contadorRender;
                                this.setState({
                                    contadorRender: contador++,
                                    listaVariable: value
                                })
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" size='small' label="Buscar" style={{ width: 'auto', minWidth: '30vw' }} />
                            )}

                        />
                    </div>
                </div>
                {this.state.listaVariable.length > 0 ?
                    <TablaEditable
                        id={this.state.contadorRender + "dhf"}
                        titulo={"Lista de propiedades"}
                        icono={FaListUl}
                        listaDeDatos={this.state.listaVariable}
                        objetoPRD={this.listaDeObjectosPRD}
                        coleccionActiva={'prd'}
                        idNodo={this.state.nodo.id}
                        isPRD={true}
                        onActualizacionExitosa={(value, key, pathDeActualizacion) => this.onActualizacionExitosa(value, key, pathDeActualizacion)}
                        obtenerCambios={(datos) => { this.obtenerCambiosAlBuscar(datos) }}
                    />
                    : this.listaDePropiedades()
                }
            </React.Fragment>
        )
    }

    obtenerCambiosAlBuscar(datos) {
        let lista = [];
        lista = this.state.listaDeCambios;
        lista.push(datos);
        this.setState({
            listaDeCambios: lista
        })
    }

    obtenerListaParaElBuscador = (datos) => {
        let listaBuscador = [];
        listaBuscador = this.listaDeParametros || listaBuscador;
        datos.forEach(element => {
            listaBuscador.forEach((item, i) => {
                try {
                    if (element.nombre === item.nombre) {
                        listaBuscador[i].valor = element.valor === "" || isNil(element.valor) || isNaN(element.valor) ? "-" : element.valor;
                    }
                } catch (e) { }
            })
        })
        this.listaDeParametros = listaBuscador;
    }

    propiedadesParaELBuscador() {
        const objeto = clonedeepPRDenVisualizador(glosarioPRD);
        let listaBuscador = [];
        Object.keys(objeto).forEach(item1 => {
            Object.keys(objeto[item1]).forEach(item2 => {
                if (item2 !== "label") {
                    Object.keys(objeto[item1][item2]).forEach(variable => {
                        listaBuscador.push(objeto[item1][item2][variable]);
                    });
                }
            });
        });
        return listaBuscador;
    }


    listaDePropiedades() {
        const glosarioParaLista = clonedeepPRDenVisualizador(glosarioPRD);
        const propiedades = Object.keys(glosarioParaLista).map((item, i) => {
            const subData = this.obtenerListadoParaPropiedades(glosarioParaLista[item], item);
            return (
                <Accordion key={glosarioParaLista[item].label + i}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        // id="panel2a-header"
                        key={glosarioParaLista[item].label + (i + 1)}
                    >
                        <Typography >{glosarioParaLista[item].label}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        key={glosarioParaLista[item].label + (i + 2)}
                    >

                        <TablaEditable
                            titulo={"Lista de propiedades"}
                            icono={FaListUl}
                            listaDeDatos={subData.lista}
                            objetoPRD={subData.objetoPRD}
                            coleccionActiva={'prd'}
                            idNodo={this.state.nodo.id || null}
                            isPRD={true}
                            listaDeCambios={this.state.listaDeCambios}
                            onActualizacionExitosa={(value, key, pathDeActualizacion) => this.onActualizacionExitosa(value, key, pathDeActualizacion)}
                        />
                    </AccordionDetails>
                </Accordion>
            )
        })
        return propiedades;
    }

    onActualizacionExitosa(value, key, pathDeActualizacion) {
        const newState = cloneDeep(this.state.nodo);
        set(newState, pathDeActualizacion, value)
        aplicarGetters(newState);
        const nuevosDatosStore = { ...this.props.storeStatePRD, data: {} }
        if (key === "campo" && has(nuevosDatosStore, "resumen.campos")) nuevosDatosStore.resumen.campos = union(nuevosDatosStore.resumen.campos, [value])
        this.props.actualizarDataApi581PRD(nuevosDatosStore)
        this.setState({ parametrosModificados: true, nodo: newState })
    }

    obtenerListadoParaPropiedades = (objeto, nombre) => {
        let list = [];
        let nuevoObjeto = {};
        const datos = this.state.nodo ? this.state.nodo.parametrosDeEntrada ? this.state.nodo.parametrosDeEntrada : {} : {};
        Object.keys(objeto).forEach(item => {
            if (item !== "label") {
                Object.keys(objeto[item]).forEach(item2 => {
                    if (has(datos, [nombre, item, item2])) {
                        const Oj = objeto[item][item2];
                        const dataValue = datos[nombre][item][item2]
                        Oj.valor = dataValue === "" || isNil(dataValue) || isNaN(dataValue) ? "-" : dataValue;
                        list.push(Oj);
                    }

                    nuevoObjeto[item2] = objeto[item][item2];
                });
            }
        });

        const prd = list.map((item, i) => { // Se agrega el indice, requerido para la modificacion desde la tabla
            return ({
                ...item,
                "indice": i,
            })
        })

        let listaDeObjectosPRD = { ...this.listaDeObjectosPRD, ...nuevoObjeto };
        this.listaDeObjectosPRD = listaDeObjectosPRD;

        this.obtenerListaParaElBuscador(prd);
        return { "lista": prd, "objetoPRD": nuevoObjeto };
    }

    snackbar = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }



    reCalcularNodo = (actualizar) => {
        recalcularPRD({ "id": this.state.nodo.id })
            .then(respuesta => {
                if (respuesta.data.status === "ERROR_ACTUALIZANDO_PRD") throw respuesta.data
                this.snackbar("PRD Recalculado Exitosamente")
                this.actualizarNodo();
                this.props.actualizarDataApi581PRD({ ...this.props.storeStatePRD, data: {} })
            })
            .catch(error => {
                this.snackbar(`Error al recalcular PRD ${error.message}`, 'error')
                error.error && error.error.detalle && this.snackbar(`${error.error.detalle}`, 'error')
                !!actualizar && this.actualizarNodo()
            });
        this.snackbar('Proceso de cálculo iniciado con éxito', 'warning');
    }

    obtenerNuevoModelo(modeloActual) {
        return modeloActual === "Cualitativo" ? "Semicuantitativo" : "Cualitativo"
    }
    cambiarModeloConsecuencia() {
        const modelo = this.state.nodo.parametrosDeEntrada.modeloDeConsecuencia
        Swal.fire(confirmacionDeActualizacionDeTabla(`Modelo de consecuencia ${modelo}`, this.obtenerNuevoModelo(modelo), "Se recalculará el analisis de riesgo automaticamente"))
            .then(result => {
                if (result.value) {
                    setTimeout(() => {
                        this.setState({ parametrosModificados: true })
                        this.actualizarModeloDeConsecuencia();
                    }, 50);
                }
            })
    }
	actualizarVisualizadorPrincipal(){
        const nuevosDatosStore = { ...this.props.storeStatePRD, data: {} }
        this.props.actualizarDataApi581PRD(nuevosDatosStore)
	}
	eliminarActivo() {
        const idActivoAEliminar = {
			"idActivo":this.state.nodo.id
		};
        const nombreActivoAEliminar = this.state.nodo.nombre;
        Swal.fire(confirmacionDeEliminacionDeActivo(nombreActivoAEliminar))
            .then(result => {
				if (result.isConfirmed) {
					eliminarActivoPRD(idActivoAEliminar).then(response => {
						if(response.data.status === "ACTIVO_ELIMINADO"){
					       this.actualizarVisualizadorPrincipal()
						   this.snackbar(`Activo Eliminado ${nombreActivoAEliminar}`)
					       this.props.history.push('/app/analisis/riesgo/api581Valvulas')
						}else{
							this.snackbar(`Error al eliminar el activo ${nombreActivoAEliminar}`,'error')
						}
					})
					.catch(error => {
						this.snackbar(`Error al eliminar el activo ${nombreActivoAEliminar}`,'error');
					})
				}
			})
		}

    actualizarModeloDeConsecuencia() {
        tryActualizarModeloDeConsecuencia({
            id: this.state.nodo.id,
            modeloDeConsecuencia: this.obtenerNuevoModelo(this.state.nodo.parametrosDeEntrada.modeloDeConsecuencia),
            coleccionActiva: this.state.coleccionActiva
        })
            .then((result) => {
                this.snackbar('Actualización de modelo de consecuencia exitosa');
                this.reCalcularNodo(true);
            })
            .catch(error => {
                this.snackbar('El proceso de actualización de modelo de consecuencia falló. Intenta de nuevo', 'error');
            })
    }

    riesgoTotalResponsive = (nodo) =>
        <Grid item container >
            <Typography variant="h6" align="center" style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold", color: "#504C4C", width: "100%" }}>
                {nodo.parametrosDeEntrada.modeloDeConsecuencia === "Cualitativo" ? "Máximo" : "Total"}
            </Typography>
            <CardDeResultados
                conRiesgoTarget={nodo.parametrosDeEntrada.modeloDeConsecuencia === "Cualitativo" ? nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCual : nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCuant}
                riesgoTotal={nodo.resultados[0].data.riesgo.total}
                scope="riesgoTotal"
                isPRD
                conBarraDeNivel
                modeloDeConsecuencia={nodo.parametrosDeEntrada.modeloDeConsecuencia}
                parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeRiesgoFinanciero}
            />
            {/* <Hidden smDown>
                <Typography variant="body2" align="center" style={{ marginBottom: "auto", color: "#504C4C", width: "100%" }}>
                    Calculado utilizando el modelo de consecuencia:
                </Typography>
                <Typography variant="body2" align="center" style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold", color: "#504C4C", width: "100%" }}>
                    {nodo.parametrosDeEntrada.modeloDeConsecuencia}
                </Typography>
            </Hidden> */}
        </Grid>
    obtenerLabelRiesgoTotal = (valor) => {
        if (this.state.nodo.parametrosDeEntrada.modeloDeConsecuencia === "Cualitativo") return valor;
        else return (obtenerCantidadEnNotacionCorta(valor) || '-');
    }

    obtenerColorRiesgoTotal = (valorCualitativo, conEtiqueta) => {
        const parametros = this.props.parametrizacionCorporativa["rangos" + this.state.moduloTarget].rangosDeRiesgo;
        const color = !!conEtiqueta ?
            obtenerColorSegunNivel(valorCualitativo, parametros) :
            obtenerColorSegunValorCualitativo(valorCualitativo, parametros)
        if (color === "#DADADA") return "none"
        return color
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    storeStatePRD: state.reducerParaData["/app/analisis/riesgo/api581PRD"],
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa,
});
const mapDispatchToProps = dispatch => ({
    actualizarDataApi581PRD: nuevaData => { dispatch(actualizarDataApi581PRD(nuevaData)); }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisualizadorDeValvulas));