import React, { useState } from 'react'

import {  UncontrolledDropdown, DropdownToggle, DropdownMenu,} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

/** Scripts */
import { obtenerEstadoSegunFechaCalculada } from '../../scripts/obtenerEstadoSegunFechaCalculada';
import Preloader from '../../../../../../utilidades/componentes/plantillaBase/Preloader';

export const BotonFiltrarFechaEncabezado = ( { nodo, modulo, callback, setFechaDeInspeccion, fechaDeInspeccion } ) => {
    console.log('este es el nodo para las fechas de inspeccion', fechaDeInspeccion)
    /** Hooks */
    const [ cargandoFechaEnEstado ] = useState(false)

    // useEffect(() => {
    //     callback( nodo, fechaACalcular, fechaEnEstado, modulo, setCargandoFechaEnEstado )
    //     .then(response => setNodoCml(response) );
    // }, [fechaEnEstado])

    const crearListaFechas = () => {
        return (
            <React.Fragment>
                <div className="scroll-area-sm">
                    <PerfectScrollbar>
                        <div className="p-3">
                            {obtenerListadoDeOpciones()}
                        </div>
                    </PerfectScrollbar>
                </div>
            </React.Fragment>
        )
    }

    const obtenerListadoDeOpciones = () => {
        let menu = [];
        if ( nodo.fechasDeInspeccion ) {
            nodo.fechasDeInspeccion.forEach((opcion, i)=> {
                
                const fechaParaEstado = new Date(opcion.seconds* 1000).toLocaleString().split(',')[0];
                const fechaParaCalcular = opcion.seconds* 1000;
                let estadoFecha = obtenerEstadoSegunFechaCalculada(nodo.fechasCalculadas, opcion)
                
                menu.push(
                <div
                    className="boton-lista-fechas" 
                    onClick={() => {
                        cambiarEstadoBotonFiltro( fechaParaEstado, fechaParaCalcular ); 
                        callback(nodo.id, fechaDeInspeccion) 
                    }}
                    key={new Date(opcion.seconds* 1000).toLocaleString() + i}
                >
                    <span className={ estadoFecha ? "text-success" : "text-danger" }>
                        { estadoFecha ? 'Calculada': 'Sin calcular'}
                    </span>
    
                    <span >
                        {new Date(opcion.seconds* 1000).toLocaleString().split(',')[0] }
                    </span>
                </div>
                )
            })
            return menu
        }
    }

    const cambiarEstadoBotonFiltro = ( e, fechaCalcular ) => {
        setFechaDeInspeccion( e );
    }


    return (
        <React.Fragment>
            <UncontrolledDropdown key={new Date()}>
                <DropdownToggle className="p-0 mr-2" color="link">
                    <div className="btn-fecha">
                        <span className="contenido-btn-fecha">Seleccionar Fecha</span><i className="arrow downArrow"></i>
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-lg rm-pointers" >
                    <div className="dropdown-menu-header mb-0">
                        <div className="dropdown-menu-header-inner bg-royal">
                            <div className="menu-header-content text-light">
                                <h5 className="menu-header-title">{ fechaDeInspeccion }</h5>
                                <h6 className="menu-header-subtitle">
                                    Hay <b>
                                        { nodo.fechasDeInspeccion ? 
                                            nodo.fechasDeInspeccion.length :
                                            'No hay fechas de inspección' 
                                        }
                                        </b> fechas de inspección</h6>
                            </div>
                        </div>
                    </div>
                    {crearListaFechas()}
                </DropdownMenu>
            </UncontrolledDropdown>
            <Preloader elPreloaderEstaAbierto={ cargandoFechaEnEstado }/>        
        </React.Fragment>
            
    )
}
