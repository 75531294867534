import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

let CustomCleanModal = (props) => {
    return (
        <Modal isOpen={props.elModalEstaAbierto} toggle={props.toggleDelModal}
            size={props.size || "lg"} style={props.style} scrollable={true}>

            <ModalBody style={{ paddingTop: 0, paddingBottom: 0, ...props.estilos }}>
                {props.children}
            </ModalBody>
        </Modal>
    );
}

export default CustomCleanModal;


