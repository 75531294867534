import React from 'react';
import {connect} from 'react-redux';
//Icons e interfaz:
import { FaListUl, FaCloudDownloadAlt} from 'react-icons/fa';
import {  Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu,} from 'reactstrap';

// scripts:
import { obtenerNodoConSubcoleccion, obtenerNodosConSubcolecciones } from '../../../../../utilidades/scripts/paraConsultaDeNodos.js';
import armarDataParaTablaCondicionSegmento from '../scripts/armarDataParaTablaCondicionSegmento';
import armarCoordenadasParaMapaDeActivos from '../scripts/armarCoordenadasParaMapaDeActivos';
import obtenerEstadoSegunFechaCalculada from '../scripts/obtenerEstadoSegunFechaCalculada';

// Componentes:
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader.jsx';
import CustomSection from '../../../../../utilidades/componentes/plantillaBase/CustomSection.jsx';
import ConsolaDeDescargaIntegridad from './ConsolaDeDescargaIntegridad';
// Base de datos:
import { firestoreDB, firebaseApp, firebaseFunctions } from '../../../../../firebase';

import TablaConGraficoDeBarras from '../../../../../utilidades/componentes/visualizadores/TablaConGraficoDeBarras.jsx';
import MapaActivosIntegridad from '../../../../../utilidades/componentes/visualizadores/MapaActivosIntegridad.jsx';
import CrearTablaDinamicamente from '../../../../../utilidades/componentes/visualizadores/CrearTablaDinamicamente.jsx';
import GaleriaFotograficaIntegridad from './GaleriaFotograficaIntegridad.jsx';
// import { obtenerNodoConSubcoleccionIntegridad } from '../../../../utilidades/scripts/paraConsultaDeNodosIntegridad.js';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

import PerfectScrollbar from 'react-perfect-scrollbar';
import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection.jsx';

import armarDataParaTablaResumenIntegridad from '../scripts/armarDataParaTablaResumenIntegridad';
import armarDataParaTablaInformacionBase from '../scripts/armarDataParaTablaInformacionBase';
import { get } from 'lodash';

const procesosAPI570 = firebaseFunctions.httpsCallable('procesosAPI570');

//Estilos base:
const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaParrafosDeInfo = { ...estiloDeFuentesBase, textAlign: "right", marginBottom: 2 };
const estiloParaHipervinculo = { marginLeft: 5, marginRight: 5, cursor: "pointer", textDecoration: "underline" };

class VisualizadorDeActivosIntegridad extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            coleccionActiva: '',
            nodo: undefined,
            cargando: false,
            todosLosSegmentos: [],
            fechaEnEstado: undefined,
            idDelHistorial: undefined,
            resumenDeIntegridad: undefined,
            informacionBase: undefined,

            laConsolaDeDescartaEstaAbierta: false
        }
    }
    render(){
        if(this.state.nodo !== undefined){
            return(
                <React.Fragment>
                    <CustomSection
                        titulo={this.state.nodo.informacionGeneral.nombre.valor}
                        subtitulo={"Componente tipo: " + get(this.state,"nodo.informacionGeneral.tipoDeActivo.valor","No se pudo obtener este dato").toUpperCase() + " / " + this.state.fechaEnEstado}
                        URLIconoPrincipal="/assets/img/logos/giaCore-logo.png"
                        estiloPadreBotones={{display: 'flex'}}
                        botonesAdicionales={[this.generarBoton('fechas'), this.obtenerBotonExportacion()]}
                        >
                                
                        {this.obtenerInfoDeLosPadres()}
                        {this.renderizarContenido()}
                        {this.generarConsolaDescargaIntegridad()}
                    </CustomSection>
                    <ToastContainer />
                    <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                </React.Fragment>
            )
        } else {
            return(
                <React.Fragment>
                
                </React.Fragment>
            )
        }
    }

    componentDidMount(){
        this.actualizarNodo();
    }

    componentDidUpdate(prevProp, prevState){
        if (this.state.informacionBase !== prevState.informacionBase) {
            this.generarTablasInfoGeneral();
        }
    }

    renderizarContenido = () => {
        if(this.state.nodo !== undefined && this.state.todosLosSegmentos.length !== 0){
            return (
                <Row>
                    <Col xs='12' md='12' lg='12'>
                        {this.generarTablasInfoGeneral()}
                    </Col>
                    {this.renderizarMapa()}
                    <Col xs='12' md='12' lg='12'>
                        <TablaConGraficoDeBarras 
                            parametrizacion={
                                armarDataParaTablaCondicionSegmento(
                                    this.props.match.params.norma,
                                    this.state.todosLosSegmentos
                                )
                            }
                            // piernasMuertas={this.state.resumenDeIntegridad.variablesDeLaInspeccion.piernasMuertas}
                            height={'100%'}
                            radioInterno={150} 
                            radioExterno={180}
                            titulo={`${this.state.nodo.informacionGeneral.nombre.valor}`}
                        />
                    </Col>
                    <Col xs='12' md='12' lg='12'>
                        <GaleriaFotograficaIntegridad
                        idActivoIntegridad={this.props.match.params.id}/>
                    </Col>
                </Row>
            );
        }
    }

    generarConsolaDescargaIntegridad = () => {
        return(
            <ConsolaDeDescargaIntegridad
                alertExitoso={this.dispararAlertExitoso}
                alertFallido={this.dispararAlertFallido}
                elModalEstaAbierto={this.state.laConsolaDeDescartaEstaAbierta}
                toggleDelModal={this.toggleDeConsolaDeDescarga}
                nombreDelActivo={this.state.nodo.informacionGeneral.nombre.valor}
                idActivo={this.props.match.params.id}
                fechaDeInspeccion={this.state.fechaEnEstado}
            />
        )
    }

    generarTablasInfoGeneral = () => {
            return (
                <React.Fragment>
                    <Row>

                        <Col sm='12' md='12' lg='6' lx='4'>
                            <CardConEncabezado 
                                titulo={`INFORMACIÓN BASE DEL ACTIVO`} 
                                icono={FaListUl}>
                                    <CrearTablaDinamicamente
                                    datosDeTabla={this.state.informacionBase}/>
                            </CardConEncabezado>
                        </Col>
                        <Col sm='12' md='12' lg='6' lx='4'>
                            <CardConEncabezado 
                                titulo={`RESUMEN DE INTEGRIDAD`} 
                                icono={FaListUl}
                                key={new Date()}>
                                    <CrearTablaDinamicamente
                                    key={new Date()}
                                    keyDeTabla={new Date()}
                                    datosDeTabla={this.state.resumenDeIntegridad}/>
                            </CardConEncabezado>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        // }
    }

    renderizarMapa = () => {
        if(this.state.todosLosSegmentos !== undefined){
            return (
                <React.Fragment>
                    <Col sm='12' md='12' lg='12' lx='4'>
                        <CardConEncabezado 
                                titulo={ `MAPA DE SEGMENTOS` }
                                icono={FaListUl}>
                            <MapaActivosIntegridad
                                coordenadas= {
                                    armarCoordenadasParaMapaDeActivos(this.state.todosLosSegmentos)
                                }
                            />
                        </CardConEncabezado>
                    </Col>
                </React.Fragment>
            )
        }
    }

    toggleDeConsolaDeDescarga = () => {
        this.setState({ laConsolaDeDescartaEstaAbierta: !this.state.laConsolaDeDescartaEstaAbierta });
    }

    obtenerBotonExportacion = () => {
        return (
            <BotonParaSection
                key="botonExportarOrdenesInspeccion"
                icono={<FaCloudDownloadAlt />}
                onClick={this.toggleDeConsolaDeDescarga}
                title="Exportar"
            />
        );
    }

    //Métodos operativos:
    actualizarNodo = () => {
        const coleccionActiva = "activos";
        const id = this.props.match.params.id;
        const moduloTarget = this.props.match.params.modulo;

        this.setState({ cargando: true, });

        firestoreDB.collection('bdPrueba/9AKrQUDKh3UGtqDD2Rpk/'+ coleccionActiva).doc(id).get()
            .then(snapshot => {
                console.log('snapShot.data', snapshot.data())
                try {
                    let nodoTarget = { id: snapshot.id, ...snapshot.data(), referencia: snapshot.ref };
                    obtenerNodoConSubcoleccion(nodoTarget, moduloTarget, true, true)
                        .then(nodo => {
                            this.setState({ 
                                id: snapshot.id, 
                                coleccionActiva: coleccionActiva, 
                                nodo: nodo, 
                                fechaEnEstado: new Date(nodo.fechasDeInspeccion[nodo.fechasDeInspeccion.length - 1].seconds * 1000).toLocaleString().split(',')[0],
                                informacionBase: armarDataParaTablaInformacionBase(nodo),
                            },
                                () => { this.actualizarNodosDeTablas(nodo); this.consultarHistorialDeResultados()});
                        })
                        .catch(error => { console.log(error); });
                }
                catch(error){ console.log(error)}
            })
            .catch(error => { 
                this.setState({ error: true })
                console.log(error); 
            });
    }

    actualizarNodosDeTablas() {
        const idDelActivo = this.state.nodo.id;
        const moduloTarget = this.props.match.params.modulo;

        let promiseDeConsultaDeSegmentos = firestoreDB.collection("bdPrueba/9AKrQUDKh3UGtqDD2Rpk/segmentos");
        promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
        promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos
        .where("informacionDeLosPadres.activoAsociado.referencia", "==", 
            firestoreDB.collection("bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos").doc(idDelActivo)
        );

        try {
            obtenerNodosConSubcolecciones(promiseDeConsultaDeSegmentos, moduloTarget, true, false)
                .then(segmentos => {
                    this.setState({ todosLosSegmentos: segmentos });
                    setTimeout(() => { this.setState({ cargaCompleta: true, cargando: false }); }, 500);
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ cargando: false });
                    window.document.body.style.cursor = "default";
                });
        }
        catch(error) {console.log(error)}

    }

    consultarHistorialDeResultados = () => {
        if(this.state.nodo !== undefined){
            this.setState({cargando: true})
            let fechaSeleccionada = this.state.nodo.fechasDeInspeccion.filter(fecha => {
                return new Date(fecha.seconds * 1000).toLocaleString().split(',')[0] === this.state.fechaEnEstado
            })

            this.setState({fechaADescargar: new Date(new Date((fechaSeleccionada[0].seconds * 1000) - 60*60*24*1000).setHours(0,0,0,0))})
            const idDelActivo = this.state.nodo.id;
            firestoreDB.collection(`bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos/${idDelActivo}/resultados/API570/historialDeResultados`)
                .orderBy('fechaDeInspeccion')
                .startAt(firebaseApp.firestore.Timestamp.fromDate(new Date(new Date((fechaSeleccionada[0].seconds * 1000) - 60*60*24*1000).setHours(0,0,0,0))))
                .endBefore(firebaseApp.firestore.Timestamp.fromDate(new Date(new Date((fechaSeleccionada[0].seconds * 1000) + 60*60*24*1000).setHours(0,0,0,0))))
                .get().then(snapShots => {
                    try{
                        snapShots.forEach(snapShot => {
                            this.setState({idDelHistorial: snapShot.id})
                            let dataResumen = snapShot.data().variablesDeLaInspeccion;
                            this.setState({
                                resumenDeIntegridad: armarDataParaTablaResumenIntegridad(dataResumen, fechaSeleccionada, this.state.fechaEnEstado),
                                cargando: false
                            })
                        })
                        this.activarToast('Se han consultado el historial correctamente.')
                    }
                    catch(error){
                        console.log(error);
                        this.setState({cargando: false})
                    }
                })
                .catch(error => console.log(error))
        }
    }

    obtenerListadoDeOpciones = (tipo) => {
        let menu = [];
        if (tipo === 'fecha') {
            this.state.nodo.fechasDeInspeccion.forEach((opcion, i)=> {
                
                const fechaParaEstado = new Date(opcion.seconds* 1000).toLocaleString().split(',')[0];
                const fechaParaCalcular = opcion.seconds* 1000;

                menu.push(
                <div className="boton-lista-fechas" onClick={() => this.cambiarEstadoBotonFiltro(fechaParaEstado, fechaParaCalcular,'fecha')}
                key={new Date(opcion.seconds* 1000).toLocaleString()}>
                    <span className={obtenerEstadoSegunFechaCalculada(this.state.nodo.fechasCalculadas, opcion)? 
                        "text-success" : "text-danger" }>
                            {obtenerEstadoSegunFechaCalculada(this.state.nodo.fechasCalculadas, opcion) ? 'Calculada': 'Sin calcular'}
                    </span>
                    <span >
                        {new Date(opcion.seconds* 1000).toLocaleString().split(',')[0] }
                    </span>
                </div>
                )
            })
            return menu
        }
    }

    cambiarEstadoBotonFiltro = (e, fechaCalcular,tipo) => {
        if(tipo === 'fecha') {
            this.setState({fechaEnEstado: e}, () => { this.calcularCmls(fechaCalcular, e);});
        }
    }

    calcularCmls = (fechaCalcular, e) => {
        this.setState({cargando: true})
        const idDelActivo = this.state.nodo.id;
        const datosParaprocesosVidaRemanente = {
            idActivo: idDelActivo,
            fechaDeInspeccion: e.split('/').reverse().join('-'),
        }
        const fechaParaBuscar = fechaCalcular.toString()
        const urlActivo = `bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos/${idDelActivo}/resultados/API570/historialDeResultados`
        firestoreDB.collection(urlActivo).doc(fechaParaBuscar)
        .onSnapshot(snapShot => {
            if(snapShot.exists) {
                this.consultarHistorialDeResultados()
            } else {
                procesosAPI570(datosParaprocesosVidaRemanente)
                    .then(({data}) => {
                        if(data.status === 'STATUS_SUCCESS'){
                            this.actualizarNodo();
                            this.activarToast('Se han calculado los cml correctamente.')
                        } else if (data.status === 'STATUS_ERROR') {
                            this.activarToast(`Ha ocurrido un error, ${data.message}`, 'error')
                        }
                        this.setState({cargando: false})
                    })
                    .catch(error => {console.log(error); this.setState({cargando: false})})
            }
        })
    }

    generarBoton = (tipoDeBoton) => {
        if(this.state.fechaEnEstado){
            switch(tipoDeBoton){
                case 'fechas':
                    return(
                        <UncontrolledDropdown key={new Date()}>
                            <DropdownToggle className="p-0 mr-2" color="link">
                                <div className="btn-fecha">
                                    <span className="contenido-btn-fecha">Seleccionar fecha de inspección</span><i className="arrow downArrow"></i>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-lg rm-pointers" >
                                <div className="dropdown-menu-header mb-0">
                                    <div className="dropdown-menu-header-inner bg-royal">
                                        <div className="menu-header-content text-light">
                                            <h5 className="menu-header-title">{this.state.fechaEnEstado}</h5>
                                            <h6 className="menu-header-subtitle">Hay <b>{this.state.nodo.fechasDeInspeccion.length}</b> fechas de inspección</h6>
                                        </div>
                                    </div>
                                </div>
                                {this.crearListaFechas()}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )
                default:
                    return(<React.Fragment></React.Fragment>)
            }
        }
    }

    crearListaFechas = () => {
        return (
            <React.Fragment>
                <div className="scroll-area-sm">
                    <PerfectScrollbar>
                        <div className="p-3">
                            {this.obtenerListadoDeOpciones('fecha')}
                        </div>
                    </PerfectScrollbar>
                </div>
            </React.Fragment>
        )
    }


    obtenerInfoDeLosPadres = () => {
        if (this.state.cargaCompleta) {
            let listaDeContenido = [];

            listaDeContenido.push(
                <div className={{display:'flex', justifyContent: 'space-between'}} key={"infoDePadres." + this.state.nodo.id + ".nombre"}>
                    <p style={{ ...estiloParaParrafosDeInfo }}
                        dangerouslySetInnerHTML={{
                            __html:
                                "Estoy viendo los detalles del " + this.obtenerNombreDeColeccionEnSingular(this.state.coleccionActiva)
                                + " <b>" + this.state.nodo.informacionGeneral.nombre.valor + "</b>"
                        }} />
                </div>
            );

            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo, marginBottom: 20,  }}
                    key={"infoDePadres." + this.state.nodo.id + ".paraActivo"}>
                    Pertenece al sistema
                    <b style={{ ...estiloParaHipervinculo }} >
                        {this.state.nodo.informacionDeLosPadres.sistemaAsociado.nombre}
                    </b>
                    del campo
                    <b style={{ ...estiloParaHipervinculo, marginRight: 0 }} >
                        {this.state.nodo.informacionDeLosPadres.campoAsociado.nombre}
                    </b>
                </p>
            );

            return listaDeContenido;
        }
        else {
            return "Cargando...";
        }
    }

    obtenerNombreDeColeccionEnSingular = (coleccion) => {
        switch (coleccion) {
            case "campos":
                return "campo";
            case "sistemas":
                return "sistema";
            case "activos":
                return "activo";
            case "segmentos":
                return "segmento";
            default:
                return coleccion;
        }
    }

    activarToast = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 7000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }

}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa,
});
export default connect(mapStateToProps)(VisualizadorDeActivosIntegridad);



