import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card } from 'reactstrap';
import Swal from 'sweetalert2';

//Componentes auxiliares:
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal';

//Utilidades de firebase:
import { firebaseFunctions, firebaseStorage, firestoreDB } from '../../../../../firebase';

//JsZip
import JSZip from "jszip";
import saveAs from "file-saver";
import { FaFileExcel, FaFileImage, FaFilePdf } from 'react-icons/fa';

const pathReference = firebaseStorage.ref();

//Microservicios firebase:
const exportacionAPI570 = firebaseFunctions.httpsCallable('exportacionAPI570');

class ConsolaDeDescargaIntegridad extends React.Component {

    constructor() {
        super();

        this.state = {
            progresoExportacionResultados: 0,
            estadoDelProceso: "inicio" //inicio; cargandoDatos; procesoFinalizado; error; sinDatos
        }
    }

    componentWillReceiveProps() {
        if (!this.props.nombreDelActivo) {
            this.setState({
                estadoDelProceso: "sinDatos"
            });
        } else {
            if (this.state.estadoDelProceso === "sinDatos") {
                this.setState({
                    estadoDelProceso: "inicio"
                });
            }
        }
    }

    render() {
        return (
            <CustomModal size="lg" elModalEstaAbierto={this.props.elModalEstaAbierto}
                toggleDelModal={this.props.toggleDelModal} titulo={<span>Descargar activo: <b>{this.props.nombreDelActivo}</b></span>}>

                <Row style={{ padding: 15 }}>
                    <Col xs="12" md="12" lg="12" xl="12">
                        <Card className="widget-chart border mb-3 ">
                            <div className="widget-heading">
                                <p>
                                    Se van a descargar los datos del activo <b>{this.props.nombreDelActivo}</b>
                                </p>
                            </div>

                            <div className="widget-subheading" style={{ opacity: 1 }}
                                dangerouslySetInnerHTML={{ __html: this.obtenerSubtituloSegunEstado() }} />

                            <hr />
                            {this.obtenerBotonDeInicioDeCarga()}
                        </Card>
                    </Col>
                </Row>
            </CustomModal>
        );
    }

    obtenerBotonDeInicioDeCarga = () => {
        if (this.state.estadoDelProceso === "inicio") {
            let listaDeBotones = [
                {
                    clase: 'export-excel',
                    icono: <FaFileExcel />,
                    parametro: 'excel' 
                },
                {
                    clase: 'export-excel',
                    icono: <FaFileExcel />,
                    parametro: 'excel' 
                },
                {
                    clase: 'export-excel',
                    icono: <FaFileExcel />,
                    parametro: 'excel' 
                },
                {
                    clase: 'export-excel',
                    icono: <FaFileExcel />,
                    parametro: 'excel' 
                },
            ]
            return (
                <React.Fragment >
                    <div className="export-buttons-container">
                        <div className="button-space" onClick={() => { this.descargarActivos('excel'); }}>
                            <a id="excel"><FaFileExcel className="pusheable-button icon-button-excel"/></a>
                            <p>Descargar Excel</p>
                        </div>
                        <div className="button-space" onClick={() => { this.descargarActivos('xlsx'); }}>
                            <a id="xlsx"><FaFileExcel className="pusheable-button icon-button-xlsx"/></a>
                            <p>Abrir Excel en línea</p>
                        </div>
                        <div className="button-space" onClick={() => { this.descargarActivos('pdf'); }}>
                            <a id="pdf"><FaFilePdf className="pusheable-button icon-button-pdf"/></a>
                            <p>Descargar PDF</p>
                        </div>
                        <div className="button-space" onClick={() => { this.descargarImagenes(); }}>
                            <a id="img"><FaFileImage className="pusheable-button icon-button-img"/></a>
                            <p>Descargar Imágenes</p>
                        </div>
                        {/* <Button className="btn-wide pusheable-button"
                            style={{ marginBottom: 20 }} color="success"
                            onClick={() => { this.descargarActivos(); }}>
                            <FaFileExcel />
                            Exportar datos
                            <FaFilePdf />
                        </Button>
                        <Button className="btn-wide pusheable-button"
                            style={{ marginBottom: 20 }} color="success"
                            onClick={() => { this.descargarImagenes(); }}>
                            Descargar imagenes
                        </Button> */}
                    </div>
                </React.Fragment>
            );
        }
    }

    obtenerSubtituloSegunEstado = () => {
        switch (this.state.estadoDelProceso) {
            case "inicio":
                return "<br/>Requerimos confirmación para iniciar el proceso. Este puede tardar algunos segundos";
            case "cargandoDatos":
                return "<br/>Procesando datos...";
            case "procesoFinalizado":
                return "<br/>El proceso de exportación terminó.<br/><br/>";
            case "error":
                return "<br/>El proceso de exportación no pudo finalizar correctamente.<br/><br/>"
            case "sinDatos":
                return "<br/>No hay datos que exportar<br/><br/>"
            case "sinImagenes":
                return "<br/>No hay imágenes que descargar, por favor, agrega imágenes en la parte inferior<br/><br/>"
            default:
                return "";
        }
    }

    descargarActivos = async ( documentoADescargar ) => {
        this.setState({
            estadoDelProceso: "cargandoDatos"
        });
        const datosParaExportar = {
            idActivo: this.props.idActivo,
            fechaDeInspeccion: this.props.fechaDeInspeccion.split('/').reverse().join('-'),
            segmento: this.props.idSegmento? this.props.idSegmento : null
        }
        // idSegmento: si viene vacío descarga todos los segmentos si trae un ID descarga solo ese segmento 
        exportacionAPI570(datosParaExportar)
            .then(response => {
                try {
                    this.setState({
                        estadoDelProceso: "inicio",
                        progresoExportacionResultados: 0,
                        progresoExportacionLogs: 0,
                    });
                    if (this.props.elModalEstaAbierto) {
                        this.props.toggleDelModal();
                        this.dispararAlertExitoso('Descarga exitosa', 'Revisa las ventanas emergentes');
                    }
                    switch( documentoADescargar ) {
                        case 'excel':
                            window.open(response.data.link.link);
                            break;
                        case 'xlsx':
                            window.open(response.data.link.xlsx);
                            break;
                        case 'pdf':
                            window.open(response.data.link.pdf);
                            break;
                    }
                }
                catch (error) {
                    this.setState({
                        estadoDelProceso: "error",
                        progresoExportacionResultados: 0,
                        progresoExportacionLogs: 0,
                    });

                    if (this.props.elModalEstaAbierto) {
                        this.props.toggleDelModal();
                        this.dispararAlertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                    }
                };
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    estadoDelProceso: "error",
                    progresoExportacionResultados: 0,
                    progresoExportacionLogs: 0,
                });

                if (this.props.elModalEstaAbierto) {
                    this.props.toggleDelModal();
                    this.dispararAlertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                }
            });
    }

    descargarImagenes = async () => {
        this.setState({
            estadoDelProceso: "cargandoDatos"
        });

        const id = this.props.idActivo;
        try {
            firestoreDB.doc(
                `activos/${id}/resultados/API570/registrosDeInspeccion/registroFotografico`)
                .get()
                .then((snapShot) => {
                    if (snapShot.exists) {
                        const registroFotografico = snapShot.data();
                        const listaDeImagenes = registroFotografico.registro.imagenesDeLocalizacion ? registroFotografico.registro.imagenesDeLocalizacion : null;
                        if (listaDeImagenes) {
                            let pathsDeImagenes = [];
                            listaDeImagenes.forEach(imagen => {
                                let nombreDeLaImagen = imagen.split("/");
                                nombreDeLaImagen = nombreDeLaImagen[nombreDeLaImagen.length-1];
                                pathsDeImagenes.push({
                                    "nombreDeLaImagen": nombreDeLaImagen,
                                    "pathDeLaImagen": imagen
                                });
                            });
                            let promesasDeImagenes = [];
                            pathsDeImagenes.forEach(imagen =>{
                                const promesaDeImagen = this.generarEnlaceDeDescarga(imagen);
                                promesasDeImagenes.push(promesaDeImagen);
                            });
                            
                            if(promesasDeImagenes.length > 0){
                                return Promise.all(promesasDeImagenes);
                            }else{
                                this.setState({
                                    estadoDelProceso: "error",
                                    progresoExportacionResultados: 0,
                                    progresoExportacionLogs: 0,
                                });
                
                                if (this.props.elModalEstaAbierto) {
                                    this.props.toggleDelModal();
                                    this.dispararAlertFallido('Descarga fallida', 'Este activo no posee imagenes');
                                }
                            }
                        } else {
                            this.setState({
                                estadoDelProceso: "sinImagenes",
                                progresoExportacionResultados: 0,
                                progresoExportacionLogs: 0,
                            });
                        }
                    }
                })
                .then(listaDeUrl => {
                    if(listaDeUrl){
                        this.generarArchivoZip(listaDeUrl);
                    }
                })
                .catch(error => {
                    console.log('Error al obtener imagenes', error);
                    this.setState({
                        estadoDelProceso: "error",
                        progresoExportacionResultados: 0,
                        progresoExportacionLogs: 0,
                    });
    
                    if (this.props.elModalEstaAbierto) {
                        this.props.toggleDelModal();
                        this.dispararAlertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                    }
                });;
        }
        catch(error){ console.log( 'Hubo un problema al intentar descargar las imágenes', error ) }
    }

    generarEnlaceDeDescarga({nombreDeLaImagen,pathDeLaImagen}){
        return new Promise( (resolve, reject) =>{
            pathReference.child(pathDeLaImagen).getDownloadURL()
            .then(urlDeLaImagen =>{
                    resolve({ "nombreDeLaImagen": nombreDeLaImagen, "urlDeLaImagen": urlDeLaImagen });
                })
                .catch(error =>{
                    console.log('Error Firestore', error);
                    reject("Error Firestore: " + error );
                });
        });
    }

    generarArchivoZip = (listaDeUrl) => {
        const jszip = new JSZip();
        let descargarImagenes = [];

        listaDeUrl.forEach(url =>{
            descargarImagenes.push(this.obtenerDataDeLaImagen(url));
        });

        Promise.all(descargarImagenes)
            .then(respuestasDeImagenes => {

                respuestasDeImagenes.forEach(imagen => {
                    jszip.file(
                        imagen.nombreDeLaImagen,
                        imagen.dataDeLaImagen,
                        { base64: true }
                    );
                });

                const nombreDeLArchivo = "Activo_" + this.props.nombreDelActivo + '_imagenes.zip';
                jszip.generateAsync({ type: "blob" })
                    .then( (content) => {
                        saveAs(content, nombreDeLArchivo);
                        this.setState({
                            estadoDelProceso: "inicio",
                            progresoExportacionResultados: 0,
                            progresoExportacionLogs: 0,
    
                        });
                        if (this.props.elModalEstaAbierto) {
                            this.props.toggleDelModal();
                            this.dispararAlertExitoso('Descarga exitosa', 'Revisa las ventanas emergentes');
                        }    
                    });
            })
            .catch(error => {
                console.log('Error descarga de archivos', error);
                this.setState({
                    estadoDelProceso: "error",
                    progresoExportacionResultados: 0,
                    progresoExportacionLogs: 0,
                });

                if (this.props.elModalEstaAbierto) {
                    this.props.toggleDelModal();
                    this.dispararAlertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                }
            });
    }

    obtenerDataDeLaImagen({nombreDeLaImagen, urlDeLaImagen}) {
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", urlDeLaImagen, true);
            xhr.responseType = "arraybuffer";
            xhr.onreadystatechange = function (evt) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve({
                            nombreDeLaImagen: nombreDeLaImagen,
                            dataDeLaImagen: xhr.response
                        });
                    } else {
                        reject("Error  al descargar" + nombreDeLaImagen + ": " + xhr.status);
                    }
                }
            };
            xhr.send();
        });
    }

    dispararAlertExitoso = (titulo, subtitulo) => {
        Swal.fire({
            title: titulo,
            html: '<span style="font-size:14px;">' + subtitulo + '</span>',
            icon: 'success',
        });
    }

    dispararAlertFallido = (titulo, subtitulo) => {
        Swal.fire({
            title: titulo,
            html: '<span style="font-size:14px;">' + subtitulo + '</span>',
            icon: 'error',
        });
    }

}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ConsolaDeDescargaIntegridad);