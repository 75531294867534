
import React, { useEffect, useReducer } from 'react';
import { Grid } from '@material-ui/core';
import Preloader from '../../../../../../utilidades/componentes/plantillaBase/Preloader';
import reducer from './reducer';
import obtenerInventario from '../../scripts/consultas/obtenerInventario';
// import useStyles from './styles'
import obtenerColumnasInventario from "./scripts/columnasInventario";
import obtenerColumnasReportes from "./scripts/columnasReportes";
import CustomSection from "../../../../../../utilidades/componentes/plantillaBase/CustomSection";
import { has, isEmpty, set, get, xor, zipObject } from 'lodash';
import useStoreApi581PRD from '../../hooks/useStoreApi581PRD';
import CustomMUIDataTable from '../../componentes/CustomMUIDataTable';
import GraficasContadores from './GraficasContadores';
import ConsolaDeCargue from '../../../../../../utilidades/componentes/resumenDeModulo/ConsolaDeCargue'

import TabsGraficas from './Tabs';
import MensajeDeCampos from '../../componentes/MensajeDeCampos';
import { MODO_DES, SIN_VALORAR } from '../../scripts/constantes'
import { KEYS } from './scripts/constantes'
import { obtenerResultadosPorPRD } from '../../scripts/consultas/obtenerResultados';
import { resultadosDeInvetarioAStore, resultadosDeStoreAInventario } from './scripts/transformarResultados';
import { infoCampoDeInventarioAStore, infoCampoStoreAInventario } from './scripts/transformarInfoCampo';
import useResumen from '../../hooks/useResumen';
import SwalDeObtenerDatos, { SwalAlert } from '../../componentes/SwalDeObtenerDatos';
import { inventario as existenInventarioEnRedux, resultados as existenResultadosEnRedux } from './scripts/existeEnRedux';
import BotonCargueMasivo from '../../componentes/BotonCargueMasivo';
import ConsolaDeDescargaPRD from '../../../../../../utilidades/componentes/resumenDeModulo/ConsolaDeDescargaPRDFC';
import { errorToast } from "../../../../../../utilidades/Toastify/Toastify"

const initialState = {
    data: {},
    resumen: {},
    cargando: true,
    laConsolaDeCargueEstaAbierta: false,
    laConsolaDeDescargueEstaAbierta:false,
    tablaIndex: 0,
    cargandoReportes: false,
    campoTarget: undefined,
    descargarIDsPRD:[],
    modoDescarga:undefined, // inventario, reportes, detalle
}

const moduloTarget = "API581"

function VisualizadorInventarioValvulas() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { getStoreState, getStateApi581PRD, dispatch: dispatchStore, getDominioCorporativo } = useStoreApi581PRD()
    const [resumen, cargandoResumen] = useResumen()
    const storeState = getStoreState();
    const dominioCorporativo = getDominioCorporativo(storeState)
    const storePRD = getStateApi581PRD(storeState);

    useEffect(() => {
        if (!cargandoResumen && !state.campoTarget) {
            const nuevoCampoTarget = get(storePRD, "target.analisis.campo", get(resumen, "campos[0]"))
            if (!isEmpty(nuevoCampoTarget)) dispatch({ type: "target", payload: nuevoCampoTarget })
            else dispatch({ type: "cargado"})
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargandoResumen, state.campoTarget])

    useEffect(() => {
        function obtenerSoloInventario() {
            let campoActual = state.campoTarget;
            const existCampoEnState = has(state, `data.${campoActual}`)
            // el campo existe en el estado se retorna
            if (existCampoEnState) dispatch({ type: "cargado" })
            else {
                const existeCampoEnStore = has(storePRD, `data.analisis.${campoActual}`)
                //si el campo no existe en el state pero si en el store.
                if (existeCampoEnStore) {
                    const infoCampoActual = storePRD.data.analisis[campoActual]
                    const keysDeInfoFaltante = xor(Object.keys(infoCampoActual), KEYS.INFOCAMPO)
                    // se valida si lo que está en el store falta algo o ya está completo
                    if (keysDeInfoFaltante.length) {
                        // si hace falta obtener de la base de datos esas PRD. (los parámetros de entrada)
                        Promise.all(keysDeInfoFaltante.map((keyInfo) => {
                            // si hace falta informacion de las no valoradas las traigo. del mismo modo con las que lo están.
                            // el resultado se obtiene como se almacena en redux (campo.modeloDeConsecuencia...)
                            if (keyInfo === SIN_VALORAR) return obtenerInventario({
                                campo: campoActual,
                                dominioCorporativo,
                                where: [{ key: "estados.valorada", condicion: "==", value: false }]
                            })
                            return obtenerInventario({
                                campo: campoActual,
                                dominioCorporativo,
                                where: [{ key: "parametrosDeEntrada.modeloDeConsecuencia", condicion: "==", value: keyInfo }, { key: "estados.valorada", condicion: "==", value: true }]
                            })
                        })).then((inventarioPRDFaltantes) => {
                            // el objeto inventario es un array ordenado con los valores segun las keys de la informacion faltante.
                            const objPRDSFaltantes = zipObject(keysDeInfoFaltante, inventarioPRDFaltantes)
                            // este objeto se va para el estado para cargar la tabla de inventario. pero se continua trabajando con la tabla de reportes.
                            const nuevoInfoCampo = { ...objPRDSFaltantes, ...resultadosDeStoreAInventario(infoCampoActual) };
                            dispatch({ type: "data", payload: infoCampoStoreAInventario({ [campoActual]: nuevoInfoCampo }), extra: { cargandoReportes: state.tablaIndex === 1, cargando: state.tablaIndex === 1 } })
                            if (keysDeInfoFaltante.length === 1 && keysDeInfoFaltante[0] === SIN_VALORAR) {
                                set(storePRD, `data.analisis.${campoActual}`, { ...infoCampoActual, ...objPRDSFaltantes })
                                dispatchStore(storePRD)
                            }
                        }).catch(()=>{
                            dispatch({type:"cargado"})
                        })
                    }
                    else {
                        //Si ya está en el store es porque ya tiene resultados y esos resultados se guardan en modelo de inventario
                        dispatch({ type: "data", payload: infoCampoStoreAInventario({ [campoActual]: resultadosDeStoreAInventario(storePRD.data.analisis[campoActual]) }) })
                    }
                }
                else {
                    obtenerInventario({ campo: campoActual, dominioCorporativo }).then(async (payload) => {
                        const nuevoInfoCampo = { [campoActual]: payload }
                        dispatch({ type: "data", payload: nuevoInfoCampo, extra: { cargandoReportes: state.tablaIndex === 1, cargando: state.tablaIndex === 1 } })
                    }).catch(()=>{
                        dispatch({type:"cargado"})
                    })
                }
            }
            set(storePRD, `target.analisis.campo`, campoActual)
            dispatchStore(storePRD);
        }
        if (!isEmpty(state.campoTarget) && !cargandoResumen) obtenerSoloInventario();
        return () => {
        }
        // SE TIENE QUE CORRER CADA QUE CAMBIE EL CAMPO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.campoTarget, resumen])

    useEffect(() => {
        function obtenerSoloReportes() {
            const campoActual = state.campoTarget
            if (!isEmpty(campoActual)) {
                const inventarioPRDFaltantes = infoCampoDeInventarioAStore(state.data)
                const keysDeInfoFaltante = Object.keys(inventarioPRDFaltantes[campoActual])
                Promise.all(keysDeInfoFaltante.map((modeloDeConsecuencia, index) => {
                    const prds = inventarioPRDFaltantes[campoActual][modeloDeConsecuencia];
                    if (modeloDeConsecuencia === SIN_VALORAR) return prds
                    return Promise.all(prds.map(async (prd) => {
                        if (has(prd, "resultados")) return prd;
                        const resultados = await obtenerResultadosPorPRD(prd.id,false);
                        return { ...prd, resultados }
                    }))
                }
                )).then((campoConResultados) => {
                    const campoConResultadosObj = zipObject(keysDeInfoFaltante, campoConResultados)
                    dispatch({ type: "reportes", payload: infoCampoStoreAInventario({ [campoActual]: { ...campoConResultadosObj } }) })
                    const modificacioinResultadosObjToArray = resultadosDeInvetarioAStore(campoConResultadosObj);
                    set(storePRD, `data.analisis.${campoActual}`, modificacioinResultadosObjToArray)
                }).catch(()=>{
                    errorToast("Error cargando Reportes")
                    dispatch({type:"cargado"})
                })
            }
        }
        if (!isEmpty(state.campoTarget) && state.cargandoReportes ) obtenerSoloReportes()
        else if(state.cargandoReportes) dispatch({type:"cargado"})
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.cargandoReportes])

    const toggleDeLaConsolaDeCargue = () => dispatch({ type: "toggleDeLaConsolaDeCargue" })
    const toggleDeLaConsolaDeDescargue = (payload) => dispatch({ type: "toggleDeLaConsolaDeDescargue", payload })
    const actualizarDatos = () => { dispatch({ type: "cargando" }) }
    
    const onChangeTabTablas = ({ index }) => {
        if (index === 1 && !existenResultadosEnRedux(storePRD, state.campoTarget)) {
            return SwalDeObtenerDatos().then(result => {
                if (result.value) {
                    dispatch({ type: "index", payload: { tablaIndex: index, cargando: index === 1, cargandoReportes: index === 1 } })
                    return true;
                }
                else {
                    return false;
                }
            });
        } else {
            dispatch({ type: "index", payload: { tablaIndex: index } })
            return true;
        }
    }

    const MensajeDeCamposInventario = (
        <MensajeDeCampos
            campoTarget={state.campoTarget}
            cargando={state.cargando}
            campos={resumen.campos}
            onChangePreAlert={(campoTarget) => (!(state.tablaIndex === 1 ?
                existenResultadosEnRedux(storePRD, campoTarget) :
                (has(state, `data.${campoTarget}`) || existenInventarioEnRedux(storePRD, campoTarget))
            ))}
            onChange={(campoTarget) => dispatch({ type: "target", payload: campoTarget })}
        />)

    function TablaInventario() {
        return (
            <CustomMUIDataTable
                title={MensajeDeCamposInventario}
                columns={obtenerColumnasInventario({ moduloTarget })}
                data={state.data[state.campoTarget]}
                loading={state.cargando}
                cargandoEnTitulo={!state.cargando}
                options={{
                    download: true,
                }}
                customOnDownload={(descargarIDsPRD) => {
                    toggleDeLaConsolaDeDescargue({ descargarIDsPRD, modoDescarga: MODO_DES.INVENT })
                }}
            />
        )
    }

    function TablaReportes() {
        return (
            <CustomMUIDataTable
                title={MensajeDeCamposInventario}
                columns={obtenerColumnasReportes({ moduloTarget })}
                data={state.data[state.campoTarget]}
                loading={state.cargandoReportes}
                cargandoEnTitulo={!state.cargando}
                cargandoEnBody={{ component: "cargandoLabel" }}
                options={{
                    download:true,
                }}
                customOnDownload={(descargarIDsPRD) => {
                    toggleDeLaConsolaDeDescargue({ descargarIDsPRD, modoDescarga: MODO_DES.REPORTES })
                }}
            />
        )
    }

    return (
        <>
            <CustomSection
                titulo={"INVENTARIO PRD"}
                subtitulo={moduloTarget}
                URLIconoPrincipal="/assets/img/logos/giaCore-logo.png"
                botonesAdicionales={[
                    //<BotonDeActualizacion key="botonDeActualizacionEnApi581PRD" onClick={actualizarDatos} />,
                    <BotonCargueMasivo key="botonDeCargaEnApi581PRD" onClick={toggleDeLaConsolaDeCargue} />
                ]}
            >
                <Grid container spacing={2} direction="column">
                    <Grid item xs>
                        <GraficasContadores data={resumen} />
                    </Grid>
                    <Grid item xs>
                        <TabsGraficas
                            onChangeTab={onChangeTabTablas}
                            contentHeader={[
                                "Ver inventarios",
                                "Ver reportes",
                            ]}
                            contentBody={[TablaInventario, TablaReportes]}
                        />
                    </Grid>
                </Grid>
            </CustomSection>
            <ConsolaDeCargue
                toggleDelModal={toggleDeLaConsolaDeCargue}
                elModalEstaAbierto={state.laConsolaDeCargueEstaAbierta}
                isPRD
                fromInventario
                consultarDatosPRD={actualizarDatos}
            />
            <Preloader elPreloaderEstaAbierto={state.cargando} />
            <ConsolaDeDescargaPRD
                prd={state.descargarIDsPRD}
                alertExitoso={(titulo, subtitulo) => SwalAlert(titulo, subtitulo, "success")}
                alertFallido={(titulo, subtitulo) => SwalAlert(titulo, subtitulo, "error")}
                campoTarget={state.campoTarget}
                elModalEstaAbierto={state.laConsolaDeDescargueEstaAbierta}
                normaTarget={moduloTarget}
                toggleDelModal={toggleDeLaConsolaDeDescargue}
                modoDescarga={state.modoDescarga}
            />
        </>
    )
}


export default VisualizadorInventarioValvulas
