import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch} from 'react-router-dom';

//Scripts auxiliares:
import { obtenerPathSegunMenu, paths } from '../scripts/paths.js';

//Componentes estáticos:
import VisualizadorDeCampos from '../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/VisualizadorDeCampos.jsx';
import VisualizadorDeActivos from '../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/VisualizadorDeActivos.jsx';
import VisualizadorDeSistemas from '../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/VisualizadorDeSistemas.jsx';
import VisualizadorDeSegmentos from '../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/VisualizadorDeSegmentos.jsx';
import VisualizadorDeValvulas from '../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/VisualizadorDeValvulas.jsx';
import VisualizadorDetalleInventarioDeValvulas from '../../../vistas/aplicacion/gestion/api581Valvulas/visualizadores/DetalleInventario';
import VisualizadorAnalisisValvulas from "../../../vistas/aplicacion/analisis/riesgo/api581Valvulas/VisualizadorApi581Valvulas.jsx";

//En desarrollo:
import VisualizadorDeFormatoDeInspeccion from '../../../vistas/aplicacion/formatoAnomalias/DetalleOrdenDeTrabajo/VisualizadorDeFormatoDeInspecciones.jsx';
import VisualizadorDeFormatoDeInspeccionMadre from '../../../vistas/aplicacion/formatoAnomalias/DetalleOrdenDeTrabajo/VisualizadorDeFormatoDeInspeccionesMadre';
import VisualizadorListaDeFormatos from '../../../vistas/aplicacion/formatoAnomalias/GestorOrdenesDeTrabajo/VisualizadorListaDeFormatos.jsx';
import VisualizadorAdministradorDeUsuarios from '../../../vistas/aplicacion/administrador/VisualizadorAdministradorDeUsuarios.jsx';
import VisualizadorAdministradorDeEmpresas from '../../../vistas/aplicacion/administrador/VisualizadorAdministradorDeEmpresas.jsx';
import VisualizadorInventarioValvulas from '../../../vistas/aplicacion/gestion/api581Valvulas/visualizadores/Inventario';
import VisualizadorMantenimientoValvulas from '../../../vistas/aplicacion/gestion/api581Valvulas/visualizadores/Mantenimiento';

// Integridad API570:
import VisualizadorApi570 from '../../../vistas/aplicacion/integridad/API570/VisualizadorApi570.jsx';
import VisualizadorDeActivosIntegridad from '../../../vistas/aplicacion/integridad/API570/componentes/VisualizadorDeActivosIntegridad.jsx';
import VisualizadorDeSegmentosIntegridad from '../../../vistas/aplicacion/integridad/API570/componentes/visualizadorDeSegmentosIntegridad.jsx';
// Integridad API510:
import VisualizadorApi510 from '../../../vistas/aplicacion/integridad/API510/VisualizadorApi510.jsx';
import VisualizadorDetalleActivoApi510 from '../../../vistas/aplicacion/integridad/API510/componentes/visualizadores/VisualizadorDetalleActivoApi510.jsx';

let SelectorDeRutas = (props) => {
    return (
        <Switch>
            {/* Rutas para visualizadores de nodos: */}

            <Route path={'/app/detalleDeNodo/campos/:id/:modulo'}
                key={'/app/detalleDeNodo/campos/:id/:modulo'} component={VisualizadorDeCampos} />

            <Route path={'/app/detalleDeNodo/activos/:id/:modulo'}
                key={'/app/detalleDeNodo/activos/:id/:modulo'} component={VisualizadorDeActivos} />

            <Route path={'/app/detalleDeNodo/sistemas/:id/:modulo'}
                key={'/app/detalleDeNodo/sistemas/:id/:modulo'} component={VisualizadorDeSistemas} />

            <Route path={'/app/detalleDeNodo/segmentos/:id/:modulo'}
                key={'/app/detalleDeNodo/segmentos/:id/:modulo'} component={VisualizadorDeSegmentos} />

            <Route path={'/app/detalleDePRD/prd/:id/:modulo'}
                key={'/app/detalleDePRD/prd/:id/:modulo'} component={VisualizadorDeValvulas} />
                
            <Route path={'/app/detalleInventario/prd/:id/:modulo'}
                key={'/app/detalleInventario/prd/:id/:modulo'} component={VisualizadorDetalleInventarioDeValvulas} />
                
            <Route path={'/app/analisis/riesgo/api581Valvulas'}
                key={'/app/analisis/riesgo/api581Valvulas'} component={VisualizadorAnalisisValvulas} />

            <Route path={'/app/gestion/api581Valvulas/inventario'}
                key={'/app/gestion/api581Valvulas/inventario'} component={VisualizadorInventarioValvulas} />
            
            <Route path={'/app/gestion/api581Valvulas/mantenimiento'}
                key={'/app/gestion/api581Valvulas/mantenimiento'} component={VisualizadorMantenimientoValvulas} />

            {/* Rutas de desarrollo: */}
            {/* <Route path={'/app/dashboard'} key={'/app/dashboard'} component={VisualizadorListaDeFormatos} /> */}
            <Route path={'/app/formatoDeInspeccion/:id'} key={'/app/formatoDeInspeccion/:id'} component={VisualizadorDeFormatoDeInspeccion} />
            <Route path={'/app/ordenDeTrabajo/:id'} key={'/app/ordenDeTrabajo/:id'} component={VisualizadorDeFormatoDeInspeccionMadre} />
            <Route path={'/app/listaDeFormatos'} key={'/app/listaDeFormatos'} component={VisualizadorListaDeFormatos} />
            <Route path={'/app/administrador/empresas/:id'} key={'/app/administrador/empresas/:id'} component={VisualizadorAdministradorDeUsuarios} />
            <Route path={'/app/administrador/empresas'} key={'/app/administrador/empresas'} component={VisualizadorAdministradorDeEmpresas} />

            
            <Route path={'/app/analisis/integridad/lineas'} key={'/app/analisis/integridad/lineas'} component={VisualizadorApi570} />
            <Route path={'/app/analisis/integridad/api510'} key={'/app/analisis/integridad/api510'} component={VisualizadorApi510} />
            {/* VisualizadorApi510 */}
            {/* ¡¡ELIMINAR - SE HIZO UNICAMENTE MIENTRAS SE DESARROLLABA EL MÓDULO API510!!  */}
                {/* <Route path={'/app/analisis/integridad/api510'} key={'/app/analisis/integridad/api510'} component={VisualizadorDetalleActivoApi510} /> */}
            {/* ¡¡ELIMINAR - SE HIZO UNICAMENTE MIENTRAS SE DESARROLLABA EL MÓDULO API510!!  */}

            {/* API 510 */}
                <Route exact path={'/app/analisis/integridad/activo/:modulo/:id'} key={'/app/analisis/integridad/activo/:modulo/:id'} component={VisualizadorDetalleActivoApi510} />
            {/* API 510 */}

            {/* API 570 */}
                <Route exact path={'/app/analisis/detalle/lineas/activo/:id/:modulo/:norma'} key={'/app/detalle/integridad/lineas/activo/:id/:modulo/:norma'} component={VisualizadorDeActivosIntegridad} />
                <Route exact path={'/app/analisis/detalle/lineas/segmento/:id/:modulo/:norma'} key={'/app/detalle/integridad/lineas/segmento/:id/:modulo/:norma'} component={VisualizadorDeSegmentosIntegridad} />
            {/* API 570 */}

            {/* Rutas dinámicas en función de parametrización: */}
            {armarRoutes(obtenerPathSegunMenu(props.parametrizacionCorporativa["menuPrincipal"]["menu"], "to", "label"))}
        </Switch>
    );
}

const armarRoutes = (array) => {
    let array2 = [];
    let array3 = [];
    array3 = obtenerPathSegunMenu(paths, "path", "component");

    for (let item = 0; item < array[0].length; item++) {
        for (let x = 0; x < array3[0].length; x++) {
            if (array3[0][x] === array[0][item]) {
                array2.push(<Route path={array[0][item]} key={array[1][item]} component={array3[1][x]} />);
            }
        }
    }
    return array2;
}

const mapStateToProps = state => ({
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa
});

export default connect(mapStateToProps)(SelectorDeRutas);