export const confirmacionDeBorradoDeFormatoDeInspeccion = {
    title: 'Borrar Formato de Inspeccion',
    html: '<span style="font-size:14px;">¿Estás seguro de que quieres eliminar el archivo <b>@nombreDelFichero</b>?</span>',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm:false,
    focusCancel: true,
};