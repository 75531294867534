import React from 'react';
import { Card, ListGroup, ListGroupItem, CardHeader } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

let defaultAvatar = "/assets/img/iconos/avatar.webp";

const ListaUsuariosLogueados = (props) => {
    return (
        <Card className="main-card mb-0 shadow-sm border border-light">
            <CardHeader style={{fontSize: 18, backgroundColor: '#F8F9F9'}}>Usuarios Activos</CardHeader>
            <div className="scroll-area-sm">
                <PerfectScrollbar>
                    <ListGroup className="todo-list-wrapper" flush>
                    {obtenerRenderDeTarjetasUsuario(props)}
                    </ListGroup>
                </PerfectScrollbar>
            </div>
        </Card>
    );
}

const obtenerRenderDeTarjetasUsuario = (props) =>{
    if(props.usuarios){
        return props.usuarios.map((usuario,index) =>{
            return(
                <ListGroupItem className='py-1' key={'logueado_'+ usuario.correo + index}>
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                                <div className="widget-content-left">
                                    <img width={42} className="rounded" src={defaultAvatar} alt="" />
                                </div>
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading" style={{fontSize: 16}}>
                                    {usuario.nombre}
                                </div>
                                <div className="widget-subheading">
                                    {usuario.correo}
                                </div>
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            );
        })
    }
}

export default ListaUsuariosLogueados;