export const ACTUALIZAR_DATA_API_580 = 'DATA/ACTUALIZAR_DATA_API_580';
export const ACTUALIZAR_DATA_API_1160 = 'DATA/ACTUALIZAR_DATA_API_1160';
export const ACTUALIZAR_DATA_ASME_B31_8S = 'DATA/ACTUALIZAR_DATA_ASME_B31_8S';
export const ACTUALIZAR_DATA_PLANES_DE_ACCION = 'DATA/ACTUALIZAR_DATA_PLANES_DE_ACCION';
export const ACTUALIZAR_DATA_API_581_PRD = 'DATA/ACTUALIZAR_DATA_API_581_PRD';
export const ACTUALIZAR_MODULO_ACTIVO = 'DATA/ACTUALIZAR_MODULO_ACTIVO';
export const ACTUALIZAR_DATA_API_570 = 'DATA/ACTUALIZAR_DATA_API_570';
export const ACTUALIZAR_DATA_API_510 = 'DATA/ACTUALIZAR_DATA_API_510';

const initialState = {
    "/app/tratamiento/planesDeAccion": { "segmentos": [], nodosDescargados: false, campoActivo: "TUA", limiteDeDescargas: 5 },
    "/app/analisis/riesgo/api580": { "activos": [], "segmentos": [], nodosDescargados: false, coleccionActiva: "activos", campoActivo: "", limiteDeDescargas: 25 },
    "/app/analisis/riesgo/api1160": { "activos": [], "segmentos": [], nodosDescargados: false, coleccionActiva: "activos", campoActivo: "", limiteDeDescargas: 25 },
    "/app/analisis/riesgo/b318s": { "activos": [], "segmentos": [], nodosDescargados: false, coleccionActiva: "activos", campoActivo: "", limiteDeDescargas: 25 },
    "/app/analisis/riesgo/api581PRD": {}, //{ data: { analisis:{ campo: { semicuantitativo: [], cuantitativo: [] } } }, target: { analisis: { campo: "Campo X ", modeloDeConsecuencia: "Semicuantitativo" }},...resumenDePRD (coleccion)} // comentado porque se valida que exista la key
    "/app/analisis/integridad/api570": { "activos": [], "segmentos": [], nodosDescargados: false, coleccionActiva: "activos", campoActivo: "", limiteDeDescargas: 25 },
    "/app/analisis/integridad/api510": { "activos": [], "segmentos": [], nodosDescargados: false, coleccionActiva: "activos", campoActivo: "", limiteDeDescargas: 25 }, 
    moduloActivo: undefined
}

export const actualizarDataApi580 = nuevaData => ({
    type: ACTUALIZAR_DATA_API_580,
    nuevaData
});

export const actualizarDataApi1160 = nuevaData => ({
    type: ACTUALIZAR_DATA_API_1160,
    nuevaData
});

export const actualizarDataAsmeB318s = nuevaData => ({
    type: ACTUALIZAR_DATA_ASME_B31_8S,
    nuevaData
});

export const actualizarDataPlanesDeAccion = nuevaData => ({
    type: ACTUALIZAR_DATA_PLANES_DE_ACCION,
    nuevaData
});

export const actualizarModuloActivo = nuevoModulo => ({
    type: ACTUALIZAR_MODULO_ACTIVO,
    nuevoModulo
});

export const actualizarDataApi581PRD = nuevaData => ({
    type: ACTUALIZAR_DATA_API_581_PRD,
    nuevaData
});

export const actualizarDataApi570 = nuevaData => ({
    type: ACTUALIZAR_DATA_API_570,
    nuevaData
});

export const actualizarDataApi510 = nuevaData => ({
    type: ACTUALIZAR_DATA_API_510,
    nuevaData
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTUALIZAR_DATA_API_580:
            return {
                ...state,
                "/app/analisis/riesgo/api580": action.nuevaData
            };

        case ACTUALIZAR_DATA_API_1160:
            return {
                ...state,
                "/app/analisis/riesgo/api1160": action.nuevaData
            };

        case ACTUALIZAR_DATA_ASME_B31_8S:
            return {
                ...state,
                "/app/analisis/riesgo/b318s": action.nuevaData
            };

        case ACTUALIZAR_DATA_PLANES_DE_ACCION:
            return {
                ...state,
                "/app/tratamiento/planesDeAccion": action.nuevaData
            };

        case ACTUALIZAR_MODULO_ACTIVO:
            return {
                ...state,
                moduloActivo: action.nuevoModulo
            };

        case ACTUALIZAR_DATA_API_581_PRD:
            return {
                ...state,
                "/app/analisis/riesgo/api581PRD": action.nuevaData
            };

        case ACTUALIZAR_DATA_API_570:
            return {
                ...state,
                "/app/analisis/integridad/lineas": action.nuevaData
            };

        case ACTUALIZAR_DATA_API_510:
            return {
                ...state,
                "/app/analisis/integridad/lineas": action.nuevaData
            };

        default:
            return state;
    }
}