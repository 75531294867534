import { connect } from 'react-redux';
import { firestoreDB } from '../../../../firebase';
import { Row, Col } from 'reactstrap';
import React from 'react';

//Componentes Específicos
import FiltrosPorFecha from './components/FiltrosPorFecha';
import LeyendaDelMapa from './components/LeyendaDelMapa';
import TablaListaFormatosDeInspeccion2 from './components/TablaListaFormatosDeInspeccion2';
import TablaGestionDeInformes from './components/TablaGestionDeInformes';

//Componentes Genéricos:
import BotonParaSection from '../../../../utilidades/componentes/plantillaBase/BotonParaSection';
import CardConEncabezado from '../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import ConsolaDeCargueParaInspeccion from '../../../../utilidades/componentes/plantillaBase/ConsolaDeCargueParaInspeccion';
import CustomModalFAQ from '../../../../utilidades/componentes/plantillaBase/CustomModalFAQ';
import CustomModal from '../../../../utilidades/componentes/plantillaBase/CustomModal';
import CustomSection from '../../../../utilidades/componentes/plantillaBase/CustomSection.jsx';
import GraficoDeBarrasParaFacturadas from '../../../../utilidades/componentes/visualizadores/GraficoDeBarrasParaResumenFacturado';
import GraficoDeTortaParaFormatoDeInspeccion from '../../../../utilidades/componentes/visualizadores/GraficoDeTortaParaFormatosDeInspeccion';
import MapaDeFormatoDeInspeccion from '../components/Mapa/MapaDeFormatoDeInspeccion';
import Preloader from '../../../../utilidades/componentes/plantillaBase/Preloader';

//Iconos:
import { FaChartPie, FaChartBar, FaPlus, FaCloudUploadAlt, FaQuestion } from 'react-icons/fa';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { RiRoadMapLine } from 'react-icons/ri';

//Toast:
import 'react-toastify/scss/main.scss';
import { toast, ToastContainer } from 'react-toastify';

//Scripts Específicos
import obtenerOrdenesDeTrabajoConFiltro from './scripts/managerDeFiltros';
import armarNombre from './scripts/armarNombreInforme';

//Scripts Genéricos
import obtenerColorSegunEstado from '../../../../utilidades/scripts/paraVisualizacionDeRangos';

//Vistas
import Error404 from "../../../404";
import VisualizadorFormularioDeInspeccion from '../VisualizadorFormularioDeInspección';

//Fechas filtro
const fechaUltimaSemana = new Date(new Date(Date.now()) - 604800000)
const fechaUltimoMes = new Date(new Date(Date.now()) - 2629743000)
const fechaInicioDeAnho = new Date(new Date(Date.now()).getFullYear().toString() + '/01/01')

class VisualizadorListaDeFormatos extends React.Component {

    constructor() {
        super();

        this.unsubscribe = undefined;

        this.state = {
            listaDeFormatosAnho: [],
            listaDeFormatosMes: [],
            listaDeFormatos: [],
            listaDeOrdenesDeTrabajo: [],
            listaDeOrdenesDeTrabajoAnhoActual: [],
            listaDeOrdenesDeTrabajoHijaAnhoActual:[],
            listaDeOrdenesSinProgramar: [],
            tituloDelModal: '',
            estadosFiltrados: [],
            cargandoDatos: true,
            elModalEstaAbierto: false,
            estados: [],
            estadosSinProgramar: [],
            mostrarModalCargue:false,
            modalFAQ: false,
            mostrarFiltros: true,
            totalOrdenesEnDominio: undefined,
            filtroActivo: 'quincenal' //semanal, quincenal, mensual, anual, custom
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.crearVisualizador()}
            </React.Fragment>
        )
    }

    componentWillMount() {
        this.obtenerListaDeFormatos();
        this.obtenerTotalDeOrdenes();
        this.obtenerListaDeFormatoAnhoActual()
        this.obtenerListaDeFormatoHijoAnhoActual()
    }

    componentDidMount() {
        this.obtenerOrdenesDeTrabajoFiltrados(this.state.filtroActivo);
    }

    componentWillUnmount(){
        this.unsubscribe();
    }

    componentDidCatch(){
        this.setState({
            error: true,
        })
    }

    crearVisualizador = () => {
        if (this.state.listaDeFormatos !== undefined && this.state.estados !== undefined && !this.state.error) {
            return (
                <React.Fragment>
                    <CustomSection titulo='Órdenes de Trabajo' icono={GiMagnifyingGlass}
                        subtitulo='Tablero de control'
                        botonesAdicionales={this.obtenerValidacionPermisos()?
                            [
                                <BotonParaSection key="botonFAQ"
                                    onClick={() => { this.toggleDelModalFAQ() }}
                                    icono={<FaQuestion />} title="Preguntas Frecuentes" 
                                />,
                                <BotonParaSection key="botonCargaFormatoDeInpeccion"
                                    onClick={() => { this.toggleDelModal() }}
                                    icono={<FaPlus />} title="Cargar formato de inspección" 
                                />,
                                <BotonParaSection key="btnCargueMasivo"
                                    onClick={() => { this.toggleDelModalCargue() }}
                                    icono={<FaCloudUploadAlt />} title="Cargar datos" 
                                />,
                                <FiltrosPorFecha
                                    key={new Date(Date.now()).toISOString + 'filtro2'}
                                    filtroActivo={this.state.filtroActivo}
                                    aplicarFiltro={this.obtenerOrdenesDeTrabajoFiltrados}
                                />
                            ]:[
                                <BotonParaSection key="botonFAQ"
                                    onClick={() => { this.toggleDelModalFAQ() }}
                                    icono={<FaQuestion />} title="Preguntas Frecuentes" 
                                />,
                                <FiltrosPorFecha
                                    key={new Date(Date.now()).toISOString + 'filtro2'}
                                    filtroActivo={this.state.filtroActivo}
                                    aplicarFiltro={this.obtenerOrdenesDeTrabajoFiltrados}
                                />
                            ]
                        }
                    >
                        {this.obtenerValidacionPermisos() ? <ConsolaDeCargueParaInspeccion
                            toggleDelModal={this.toggleDelModalCargue}
                            elModalEstaAbierto={this.state.mostrarModalCargue}
                        // callbackFunction={() => { this.actualizarNodos() }}
                        /> : null}
                        <CustomModalFAQ
                            toggleDelModal={this.toggleDelModalFAQ}
                            elModalEstaAbierto={this.state.modalFAQ}
                        >
                        </CustomModalFAQ>
                        <CustomModal 
                            titulo={<div className="font-weight-bold text-uppercase" style={{fontSize: 18}}>Crear órden de Inspección</div>} 
                            children={
                            <VisualizadorFormularioDeInspeccion 
                                cerrarModal={this.toggleDelModal}
                                />}
                            elModalEstaAbierto={this.state.elModalEstaAbierto} 
                            toggleDelModal={this.toggleDelModal}
                            hideFooter={true}
                            >
                        </CustomModal>

                        <Row>
                            <Col xs='12' md='12' lg='12'>
                                <CardConEncabezado
                                    titulo={'Georeferenciación de inspecciones'}
                                    icono={RiRoadMapLine}
                                >
                                    <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Col xs='12' md='12' lg='8' xl='8'>
                                            <MapaDeFormatoDeInspeccion
                                                ordenesDeTrabajo={this.state.filtroActivo !== 'custom' ? [...this.state.listaDeFormatos, ...this.state.listaDeOrdenesSinProgramar] : this.state.listaDeFormatos}
                                                //ordenesDeTrabajo={this.state.listaDeFormatos}
                                                tipoMapa={'mapa'}
                                                crearBadgeSegunEstado={this.crearBadgeSegunEstado}
                                            />
                                        </Col>
                                        <Col xs='12' md='12' lg="4" xl="4">
                                            <LeyendaDelMapa
                                                listaDeFormatos={this.state.filtroActivo !== 'custom' ? [...this.state.listaDeFormatos, ...this.state.listaDeOrdenesSinProgramar] : this.state.listaDeFormatos}
                                                totalOrdenesEnDominio= {this.state.totalOrdenesEnDominio}
                                                dominioCorporativo= {this.props.usuarioActivo.dominioCorporativo}
                                                filtrarOrdenesPorEstado={this.filtrarOrdenesPorEstado}
                                                obtenerNumeroDeEstados={this.obtenerNumeroDeEstados}
                                                estados={this.state.filtroActivo !== 'custom' ? [...this.state.estados, ...this.state.estadosSinProgramar] : this.state.estados}
                                                estadosFiltrados={this.state.estadosFiltrados}
                                                crearBadgeSegunEstado={this.crearBadgeSegunEstado}
                                                filtroActivo={this.state.filtroActivo}
                                            />
                                        </Col>
                                    </Row>
                                </CardConEncabezado>

                            </Col>
                            <Col xs='12' md='12' lg='12' xl='12'>
                                <TablaListaFormatosDeInspeccion2
                                    listaDeFormatos={this.state.filtroActivo !== 'custom' ? [...this.state.listaDeFormatos, ...this.state.listaDeOrdenesSinProgramar] : this.state.listaDeFormatos}
                                    crearBadgeSegunEstado={this.crearBadgeSegunEstado}
                                />
                                {/* <TablaListaFormatosDeInspeccionMadre
                                    listaDeFormatos={this.state.filtroActivo !== 'custom' ? [...this.state.listaDeFormatos, ...this.state.listaDeOrdenesSinProgramar] : this.state.listaDeFormatos}
                                    crearBadgeSegunEstado={this.crearBadgeSegunEstado}
                                    
                                /> */}
                            </Col>
                            <Col xs='12' md='12' lg='12' xl='12'>
                                {this.pintarTablaGestorInformes()}
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='12'>
                                {this.pintarGrafica()}
                            </Col>
                            {/* <Col xs='12' md='12' lg='6' xl='4'>
                                {this.pintarGraficaMes()}
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='4'>
                                {this.pintarGraficaAnho()}
                            </Col> */}
                        </Row>
                    </CustomSection>
                    <ToastContainer />
                    <Preloader elPreloaderEstaAbierto={this.state.cargandoDatos} />
                </React.Fragment>
            )
        }
        else {
            return (
                <Error404
                error={this.props.location.pathname} />  
            );
        }
    }

    avisoDeGuardadoSatisfactorio = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }

    obtenerValidacionPermisos = () => {
        let rolActual = this.props.usuarioActivo.rol
        let listaDeRolesPermitidos = ['admin', 'tecnico', 'supervisor']
        return (listaDeRolesPermitidos.includes(rolActual))
    }
    filtrarOrdenesPorEstado = (estado) => {
        let estadosFiltrados = [];
        if(this.state.filtroActivo !== 'custom'){
            if(estado === 'PLANEADA'){
                const listaDeOrdenesSinProgramar = this.state.listaDeOrdenesSinProgramar.filter(orden => orden.estado === 'PLANEADA');
                estadosFiltrados.push(...listaDeOrdenesSinProgramar);
            }else if(estado === 'PROGRAMADA'){
                const listaDeOrdenesSinProgramar = this.state.listaDeOrdenesSinProgramar.filter(orden => orden.estado === 'PROGRAMADA');
                estadosFiltrados.push(...listaDeOrdenesSinProgramar);            
            }
            else if(estado === 'CANCELADA'){
                const listaDeOrdenesSinProgramar = this.state.listaDeOrdenesSinProgramar.filter(orden => orden.estado === 'CANCELADA');
                estadosFiltrados.push(...listaDeOrdenesSinProgramar);            
            }
        }
        const listaDeOrdenes  = this.state.listaDeFormatos.filter(e => e.estado === estado);
        estadosFiltrados.push(...listaDeOrdenes);
        this.setState({
            estadosFiltrados: estadosFiltrados
        })
    }

    obtenerNumeroDeEstados = (estado) => {
        let estados = [];
        if(this.state.filtroActivo !== 'custom'){
            if(estado === 'PLANEADA'){
                const cantidadOrdenesPlaneadas = this.state.estadosSinProgramar.filter(estado => estado === 'PLANEADA');
                estados.push(...cantidadOrdenesPlaneadas);
            }else if(estado === 'PROGRAMADA'){
                const cantidadOrdenesPlaneadas = this.state.estadosSinProgramar.filter(estado => estado === 'PROGRAMADA');
                estados.push(...cantidadOrdenesPlaneadas);            
            }
            else if(estado === 'CANCELADA'){
                const cantidadOrdenesPlaneadas = this.state.estadosSinProgramar.filter(estado => estado === 'CANCELADA');
                estados.push(...cantidadOrdenesPlaneadas);            
            }
        }

        if (this.state.estados !== undefined) {
            for (let i = 0; i < this.state.estados.length; i++) {
                if (this.state.estados[i] === estado) {
                    estados.push(this.state.estados[i])
                }
            };
            return estados.length;
        }
    }

    obtenerTotalDeOrdenes = () => {
        this.unsubscribe = firestoreDB.collection('empresas')
            .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
            .onSnapshot(
                (snapShots) => {
                    let totalOrdenes = 0
                    snapShots.forEach((snapshot) => {
                        let data = snapshot.data()
                        totalOrdenes=data.cantidadODT
                    })  
                    this.setState({
                        totalOrdenesEnDominio: totalOrdenes
                    })                  
                });

    }

    obtenerListaDeFormatos = () => {
        this.unsubscribe = firestoreDB.collection('ordenesDeTrabajoMadre')
            .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
            .where('fechaDeActualizacion', '==', null)
            .onSnapshot(
                (snapShots) => {
                    let ordenesDeTrabajo = [];
                    let estados = [];
                    snapShots.forEach((snapshot) => {
                        const data = snapshot.data();
                        try {
                            ordenesDeTrabajo.push({
                                id: snapshot.id,
                                fechaEstimadaDeProgramacion: data.fechaEstimadaDeProgramacion ? new Date(data.fechaEstimadaDeProgramacion.seconds * 1000).toLocaleDateString() : "Sin registrar",
                                fechaDeActualizacionComparador: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000) : "Sin registro",
                                fechaDeActualizacion: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000).toLocaleDateString() : "Sin registro",
                                responsable: data.responsable ? data.responsable.nombre ? data.responsable.nombre : "Sin registro" : "Sin registro",
                                correo: data.responsable ? data.responsable.correo ? data.responsable.correo : "Sin registro" : "Sin registro",
                                lineaDeDucto: data.lineaDeDucto ? data.lineaDeDucto : "Sin registro",
                                descripcionODT: data.descripcionODT ? data.descripcionODT : "Sin registro",
                                coordenadas: data.coordenadas ? data.coordenadas : "Sin registro",
                                valorFacturado: data.valorFacturado ? data.valorFacturado : 0,
                                estado: data.estado ? data.estado : "Sin registro",
                                tecnico: data.tecnico ? data.tecnico.nombre ? data.tecnico.nombre : "Sin registro" : "Sin registro",
                            });
                            if ((!data.fechaDeActualizacion || new Date(data.fechaDeActualizacion.seconds * 1000) >= fechaUltimaSemana)) {
                                estados.push(
                                    data.estado,
                                );
                            }
                        }
                        catch (error) {
                            console.log('error.... ',error);
                            this.setState({
                                error: true,
                            })
                        }
                    })
                    this.setState({
                        listaDeOrdenesSinProgramar: ordenesDeTrabajo,
                        estadosSinProgramar: estados,
                        cargandoDatos: false,
                    })
                });
        
        // unsubscribe();
    }

    obtenerListaDeFormatoAnhoActual = () => {
        this.unsubscribe = firestoreDB.collection('ordenesDeTrabajoMadre')
            .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
            .where('fechaDeActualizacion', '>=', new Date(new Date(new Date(Date.now())).getFullYear(), 0, 1, 0, 0, 0, 0))
            .onSnapshot(
                (snapShots) => {
                    let ordenesDeTrabajo = [];
                    snapShots.forEach((snapshot) => {
                        const data = snapshot.data();
                        try {
                            ordenesDeTrabajo.push({
                                id: snapshot.id,
                                fechaRealDeFinalizacionComparador: data.fechaRealDeFinalizacion ? new Date(data.fechaRealDeFinalizacion.seconds * 1000) : "Sin registro",  
                                fechaRealDeFinalizacion: data.fechaRealDeFinalizacion ? new Date(data.fechaRealDeFinalizacion.seconds * 1000).toLocaleDateString() : "Sin registro",                           
                                valorFacturado: data.valorFacturado ? data.valorFacturado : 0,
                                estado: data.estado ? data.estado : "Sin registro",
                            });
                        }
                        catch (error) {
                            console.log('error.... ',error);
                            this.setState({
                                error: true,
                            })
                        }
                    })
                    this.setState({
                        listaDeOrdenesDeTrabajoAnhoActual: ordenesDeTrabajo,
                    })
                });
        
        // unsubscribe();
    }

    obtenerListaDeFormatoHijoAnhoActual = () => {
        this.unsubscribe = firestoreDB.collection('ordenesDeTrabajo')
            .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
            .where('fechaDeActualizacion', '>=', new Date(new Date(new Date(Date.now())).getFullYear(), 0, 1, 0, 0, 0, 0))
            .onSnapshot(
                (snapShots) => {
                    let ordenesDeTrabajo = [];
                    snapShots.forEach((snapshot) => {
                        const data = snapshot.data();
                        try {
                            ordenesDeTrabajo.push({
                                id: snapshot.id,             
                                lineaDeDucto: data.lineaDeDucto ? data.lineaDeDucto : 'Sin registro',
                                fechaEstimadaDeProgramacion:data.fechaEstimadaDeProgramacion?new Date(data.fechaEstimadaDeProgramacion.seconds * 1000).toLocaleDateString():'Sin Registro',
                                fechaDeCargueHorus:data.fechaDeCargueHorus?new Date(data.fechaDeCargueHorus.seconds * 1000).toLocaleDateString():'Sin Registro',
                                distanciaDeInicio: data.distanciaRegistroDucto ? data.distanciaRegistroDucto : (data.distanciaDeInicio?data.distanciaDeInicio:0),
                                consecutivoOrden: data.consecutivo ? data.consecutivo : 0,
                                tecnico: data.tecnico.nombre ? data.tecnico.nombre : 'Sin registro',
                                actaDeFinalizacion: data.actaDeFinalizacion ? data.actaDeFinalizacion : 'Sin registro',
                                preliminar: (data.estado === 'FACTURADA' || data.estado === 'CONTROL_CALIDAD' || data.estado === 'REVISADA' || data.estado === 'EJECUTADA' || data.estado === 'INSPECCIONADA') ? 'Final' : 'Preliminar',
                                nombreDelArchivo: armarNombre(data, snapshot.id),
                                tipoDeAtencion: data.tipoActividad ? data.tipoActividad : 'Sin Registro',
                            });
                        }
                        catch (error) {
                            console.log('error.... ',error);
                            this.setState({
                                error: true,
                            })
                        }
                    })
                    this.setState({
                        listaDeOrdenesDeTrabajoHijaAnhoActual: ordenesDeTrabajo,
                    })
                });
        
        // unsubscribe();
    }

    obtenerListaDeFormatosHijo = () => {
        this.unsubscribe = firestoreDB.collection('ordenesDeTrabajoMadre')
            .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
            .where('fechaDeActualizacion', '>=', new Date(new Date(new Date(Date.now())).getFullYear(), 0, 1, 0, 0, 0, 0))
            .onSnapshot(
                (snapShots) => {
                    let ordenesDeTrabajo = [];
                    snapShots.forEach((snapshot) => {
                        const data = snapshot.data();
                        try {
                            ordenesDeTrabajo.push({
                                id: snapshot.id,
                                fechaEstimadaDeProgramacion: data.fechaEstimadaDeProgramacion ? new Date(data.fechaEstimadaDeProgramacion.seconds * 1000).toLocaleDateString() : "Sin registrar",
                                fechaDeActualizacion: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000).toLocaleDateString() : "Sin registro",
                                fechaDeActualizacionComparador: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000) : "Sin registro",
                                valorFacturado: data.valorFacturado ? data.valorFacturado : 0,
                                estado: data.estado ? data.estado : "Sin registro",
                            });
                        }
                        catch (error) {
                            console.log('error.... ',error);
                            this.setState({
                                error: true,
                            })
                        }
                    })
                    this.setState({
                        listaDeOrdenesDeTrabajoAnhoActual: ordenesDeTrabajo,
                    })
                });
        
        // unsubscribe();
    }

    toggleDelModal = () => {
        this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto })
    }

    toggleDelModalCargue = () => {
        this.setState({ mostrarModalCargue: !this.state.mostrarModalCargue })
    }

    toggleDelModalFAQ = () => {
        this.setState({ modalFAQ: !this.state.modalFAQ })
    }

    crearBadgeSegunEstado = (estado) => {
        switch (estado) {
            case 'TOTAL':
                return (<div style={{ fontSize: 14, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('TOTAL') }} className="badge badge-pill align-middle">TOTAL ORDENES</div>)
            case 'PLANEADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('PLANEADA') }} className="badge badge-pill align-middle">PLANEADA</div>)
            case 'PROGRAMADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('PROGRAMADA') }} className="badge badge-pill align-middle">PROGRAMADA</div>)
            case 'EN_SITIO':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('EN_SITIO') }} className="badge badge-pill align-middle">EN SITIO</div>)
            case 'INSPECCIONADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('INSPECCIONADA') }} className="badge badge-pill align-middle">INSPECCIONADA</div>)
            case 'EJECUTADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('EJECUTADA') }} className="badge badge-pill align-middle">EJECUTADA</div>)
            case 'REVISADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('REVISADA') }} className="badge badge-pill align-middle">REVISADA</div>)
            case 'FINALIZADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('FINALIZADA') }} className="badge badge-pill align-middle">FINALIZADA</div>)
            case 'CONTROL_CALIDAD':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('CONTROL_CALIDAD') }} className="badge badge-pill align-middle">CONTROL DE CALIDAD</div>)
            case 'FACTURADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('FACTURADA') }} className="badge badge-pill align-middle">FACTURADA</div>)
            case 'CANCELADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('CANCELADA') }} className="badge badge-pill align-middle">CANCELADA</div>)  
            default:
                return (<div style={{ fontSize: 10 }} className="badge badge-pill badge-secundary align-middle">NO DEFINIDO</div>)
        }
    }

    armarParametrizacionGraficaFacturadas = (listadoDeOrdenes) => {
        let meses=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        let parametrizacionCantidad =[]
        for (let mes = 0; mes < listadoDeOrdenes.length; mes++) {
            let valorFacturadoMes = 0
            listadoDeOrdenes[mes].forEach(orden => {
                valorFacturadoMes = valorFacturadoMes + parseFloat(orden.valorFacturado)                 
            });
            parametrizacionCantidad.push({
                etiqueta: meses[mes],
                cantidad: listadoDeOrdenes[mes].length,
                valor: valorFacturadoMes
            })
        }

        return parametrizacionCantidad
    }

    armarParametrizacion = (listadoDeOrdenes) => {
        let planeada = listadoDeOrdenes.filter(e => e.estado === 'PLANEADA').length;
        let programada = listadoDeOrdenes.filter(e => e.estado === 'PROGRAMADA').length;
        let enSitio = listadoDeOrdenes.filter(e => e.estado === 'EN_SITIO').length;
        let inspeccionada = listadoDeOrdenes.filter(e => e.estado === 'INSPECCIONADA').length;
        let ejecutada = listadoDeOrdenes.filter(e => e.estado === 'EJECUTADA').length;
        let revisada = listadoDeOrdenes.filter(e => e.estado === 'REVISADA').length;
        let controlDeCalidad = listadoDeOrdenes.filter(e => e.estado === 'CONTROL_CALIDAD').length;
        let facturada = listadoDeOrdenes.filter(e => e.estado === 'FACTURADA').length;
        let parametrizacion = []
        parametrizacion.push(
            {
                color: obtenerColorSegunEstado('PLANEADA'),
                etiqueta: 'PLANEADA',
                valor: planeada
            },
            {
                color: obtenerColorSegunEstado('PROGRAMADA'),
                etiqueta: 'PROGRAMADA',
                valor: programada
            },
            {
                color: obtenerColorSegunEstado('EN_SITIO'),
                etiqueta: 'EN SITIO',
                valor: enSitio
            },
            {
                color: obtenerColorSegunEstado('INSPECCIONADA'),
                etiqueta: 'INSPECCIONADA',
                valor: inspeccionada
            },
            {
                color: obtenerColorSegunEstado('EJECUTADA'),
                etiqueta: 'EJECUTADA',
                valor: ejecutada
            },
            {
                color: obtenerColorSegunEstado('REVISADA'),
                etiqueta: 'REVISADA',
                valor: revisada
            },
            {
                color: obtenerColorSegunEstado('CONTROL_CALIDAD'),
                etiqueta: 'QA',
                valor: controlDeCalidad
            },
            {
                color: obtenerColorSegunEstado('FACTURADA'),
                etiqueta: 'FACTURADA',
                valor: facturada
            })
        return parametrizacion
    }

    pintarTablaGestorInformes = () => {
        if (this.state.listaDeOrdenesDeTrabajoAnhoActual.length > 0) {
            let listaDeFormatos= this.state.listaDeOrdenesDeTrabajoHijaAnhoActual
            return (
                <TablaGestionDeInformes
                    data={listaDeFormatos}
                />
            )
        }
    }
    pintarGrafica = () => {
        if (this.state.listaDeOrdenesDeTrabajoAnhoActual.length > 0) {
            let fechaMeses = []
            let listadoDeOrdenesMes = []
            let listaDeFormatos= this.state.listaDeOrdenesDeTrabajoAnhoActual
            let listadoDeOrdenesFacturadas = listaDeFormatos.filter(e => e.estado === 'FACTURADA');
            for (let mes = 0; mes<=12; mes++){
                fechaMeses.push(new Date(new Date(new Date(Date.now())).getFullYear(), mes, 1, 0, 0, 0, 0))
            }
            for (let mes = 0; mes <= 11; mes++) {listadoDeOrdenesMes.push(listadoDeOrdenesFacturadas.filter(e => (new Date(e.fechaRealDeFinalizacionComparador) >= fechaMeses[mes] && new Date(e.fechaRealDeFinalizacionComparador) < fechaMeses[mes+1])));
            }
            let parametrizacionFacturadas = this.armarParametrizacionGraficaFacturadas(listadoDeOrdenesMes)
            return (
                <CardConEncabezado titulo={'Inspecciones Facturadas Año ' + new Date(new Date(Date.now())).getFullYear().toString()} icono={FaChartBar}>
                    <GraficoDeBarrasParaFacturadas
                        parametrizacion={parametrizacionFacturadas}
                    />
                </CardConEncabezado>
            )
        }
    }

    pintarGraficaMes = () => {
        if (this.state.listaDeFormatos.length > 0) {
            const listadoDeOrdenesSemana = this.state.listaDeFormatos.filter(e => new Date(e.fechaDeActualizacionComparador) >= fechaUltimoMes);
            let parametrizacion = this.armarParametrizacion(listadoDeOrdenesSemana)
            return (
                <CardConEncabezado titulo={'Inspecciones según estado (último mes)'} icono={FaChartPie}>
                    <GraficoDeTortaParaFormatoDeInspeccion
                        parametrizacion={parametrizacion}
                    />
                </CardConEncabezado>
            )
        }
    }

    pintarGraficaAnho = () => {
        if (this.state.listaDeFormatos.length > 0) {
            const listadoDeOrdenesSemana = this.state.listaDeFormatos.filter(e => new Date(e.fechaDeActualizacionComparador) >= fechaInicioDeAnho);
            let parametrizacion = this.armarParametrizacion(listadoDeOrdenesSemana)
            return (
                <CardConEncabezado titulo={'Inspecciones según estado (año actual)'} icono={FaChartPie}>
                    <GraficoDeTortaParaFormatoDeInspeccion
                        parametrizacion={parametrizacion}
                    />
                </CardConEncabezado>
            )
        }
    }

    obtenerOrdenesDeTrabajoFiltrados = async (tipoDeFiltro, fechaFiltroCustom, fechaFiltroCustomEnd) =>{
        this.setState({cargandoDatos: true},
            async () => {
                setTimeout(async ()=>{
                    try{
                        const [ unsubscribe, ordenesDeTrabajo, estados ] = await obtenerOrdenesDeTrabajoConFiltro(this.unsubscribe, this.props.usuarioActivo.dominioCorporativo, tipoDeFiltro, fechaFiltroCustom, fechaFiltroCustomEnd);
                        this.unsubscribe = unsubscribe;
                        this.setState({
                            listaDeFormatos: ordenesDeTrabajo,
                            estados: estados,
                            filtroActivo: tipoDeFiltro,
                            cargandoDatos: false
                        });
                    }catch(error){
                        console.log('error', error);
                    }
                   
                }, 500)
            });
    }


}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorListaDeFormatos);