import React from 'react';
import { connect } from 'react-redux';
import {  
    // Row, Col,
     Nav, NavItem, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

// Componentes:
import BotonDeCardConEncabezado from '../../../../utilidades/componentes/plantillaBase/BotonDeCardConEncabezado';
import CardConEncabezado from '../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import CustomSection from '../../../../utilidades/componentes/plantillaBase/CustomSection.jsx';
import SpanDeCampo from '../../../../utilidades/componentes/resumenDeModulo/SpanDeCampo.jsx';
// import CustomModal from '../../../../utilidades/componentes/plantillaBase/CustomModal';
import Preloader from '../../../../utilidades/componentes/plantillaBase/Preloader';
import ConsolaDeCargue from '../../../../utilidades/componentes/resumenDeModulo/ConsolaDeCargue';
import BotonParaSection from '../../../../utilidades/componentes/plantillaBase/BotonParaSection';
// import CrearTablaDinamicamente from '../../../utilidades/componentes/visualizadores/CrearTablaDinamicamente';

// Datos de prueba:
// import FormularioDeLineasIntegridad from './componentes/FormularioDeLineasIntegridad';

// Iconos:
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaCloudUploadAlt, FaListOl } from "react-icons/fa";

// Redux-storage:
import { actualizarDataApi570 } from '../../../../storage/reducerParaData';

// Consulta a la DB:
import { firestoreDB } from '../../../../firebase';
import TablaConServerPaginator from './componentes/TablaConServerPaginator';

//scripts
import armarDataParaTablaProceso from './scripts/armarDataParaTablaProceso';
import armarDataParaTablaProduccion from './scripts/armarDataParaTablaProduccion';
import {crearColumnasParaTabla, crearListaDatosParaTabla} from './scripts/crearTablaDinamicamente';
import { consultarActivosPorCampo } from './scripts/consultarActivosPorCampo';
import CustomTableDinamica from '../../../../utilidades/componentes/visualizadores/CustomTableDinamica';

class VisualizadorApi570 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lineasAsmeb313: [],
            lineasAsmeb314: [],
            elModalAgregarProyectoEstaAbierto: false,
            nodosDescargadosLaPrimeraVez: false,

            campoTarget: undefined,
            coleccionActiva: "activos",
            cargando: false,
            moduloTarget: 'API570',

            filtrosActivos: {},
            todosLosActivos: [],
            todosLosSegmentos: [],
            activosProceso: [],
            activosProduccion: [],

            activosProcesoPorCampo: [],
            activosProduccionPorCampo: [],

            cantidadDeActivosProduccion: undefined,
            cantidadDeActivosProceso: undefined,

            keyDeRenderizado: 0,

            mostrarLabelDeDescargaFallida: false,
            mostrarLabelDeDescargaEnProceso: false,
            activeTab: '1',
            botonActivo: '31.3',

            paginasCargadas313: [1],
            paginasCargadas314: [1],
            limiteDeDescargas: 5,
            posicionUltimoDatoProduccion: undefined,
            posicionUltimoDatoProceso: undefined,
            cargandoTablaProceso:false,
            cargandoTablaProduccion:false,
            listaDeCamposCalculados: [],

            laConsolaDeCargueEstaAbierta: false,
        }
    }
    render(){
        return(
            <React.Fragment>
                <CustomSection
                        titulo={'API 570'}
                        subtitulo={'Integridad'}
                        URLIconoPrincipal="/assets/img/logos/giaCore-logo.png"
                        botonesAdicionales={[this.obtenerBotonDeCargueMasivo()]}
                        >
                        {this.generarEncabezadoConResumen()}
                        {this.generarConsolaDeCargue()}
                        {this.generarTabs()}
                        {this.generarCardDeLasTablas()}
                        {/* {this.generarModalParaCrearActivos()} */}
                </CustomSection>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.consultarCantidadDeActivos();
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevState.lineasAsmeb313.lenght !== this.state.lineasAsmeb313.lenght ||
            prevState.lineasAsmeb314.lenght !== this.state.lineasAsmeb314.lenght ) {
                // this.renderizarTablaAsmeb313();
                // this.renderizarTablaAsmeb314();
                this.generarCardDeLasTablas();
        }
    }


    generarEncabezadoConResumen = () => {
        return (
            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                <p style={{ marginBottom: 2.5, display: ((this.state.campoTarget) ? "unset" : "none") }}>
                    {this.state.cargando
                        ? "Descargando "
                        : "Estoy viendo los "
                    }
                    <b>{this.state.coleccionActiva}</b>
                    {" del campo "}
                    <b>
                        <SpanDeCampo
                            coleccion={'bdPrueba/9AKrQUDKh3UGtqDD2Rpk/campos'}
                            campoTarget={this.state.campoTarget}
                            usuarioActivo={this.props.usuarioActivo}
                            onChange={(nuevoCampoTarget) => { this.actualizarCampoTarget(nuevoCampoTarget); }}
                            permitirConsultaInicial={!this.props.dataDe570EnRedux.nodosDescargados}
                        />
                    </b>
                </p>
                <p style={{ marginBottom: 2.5, display: ((!this.state.campoTarget) ? "unset" : "none") }}>
                    Obteniendo los <b>posibles campos</b>...
                </p>

                {this.obtenerMensajeDeDescargaFallida()}
                {this.obtenerMensajeDeLimiteDeDescargas()}
            </div>
        )
    }

    generarConsolaDeCargue = () => {
        return(
            <ConsolaDeCargue
                toggleDelModal={this.toggleDeLaConsolaDeCargue}
                elModalEstaAbierto={this.state.laConsolaDeCargueEstaAbierta}
                callbackFunction={() => this.ejecutarFuncionesDeConsulta()} 
                isIntegridad={true}
                />
        )
    }

    ejecutarFuncionesDeConsulta() {
        this.actualizarNodosProceso();
        this.actualizarNodosProduccion();
        this.activarToast('Se han ingresado los datos correctamente.')
    }

    activarToast = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 7000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }

    generarTabs = () => {
        return(
            <Nav>
                <NavItem>
                    <div 
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                            this.toggleTab('1');
                        }}
                    >
                        <span type='button' 
                            className={this.state.botonActivo === '31.3'? 'boton-para-tabs-activo' : 'boton-para-tabs'} 
                            onClick={() => this.cambiarBoton('31.3')}>
                            ASME B31.3
                        </span>
                    </div>
                </NavItem>
                <NavItem>
                    <div 
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                            this.toggleTab('2');
                        }}
                    >
                        <span 
                            type='button' 
                            className={this.state.botonActivo === '31.4'? 'boton-para-tabs-activo' : 'boton-para-tabs'} 
                            onClick={() => this.cambiarBoton('31.4')}>
                            ASME B31.4
                        </span>
                    </div>
                </NavItem>
            </Nav>
        )
    }

    generarCardDeLasTablas = () => {
        return(
            <CardConEncabezado
                titulo={`Lista de activos`}
                icono={FaListOl}
                clase='sombreadoDeHeader'
                /** Inhabilitado hasta que se realice el 
                 *  Formulario para ingresar activos
                 */
                // botonesAuxiliares={[this.generarBotones()]}
                >
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        {this.renderizarTablaAsmeb313()}
                    </TabPane>
                    <TabPane tabId="2">
                        {this.renderizarTablaAsmeb314()}
                    </TabPane>
                </TabContent>
            </CardConEncabezado>
        )
    }

    // generarModalParaCrearActivos = () => {
    //     return(
    //         <CustomModal 
    //             titulo={'Formulario Lineas de integridad'} 
    //             size={'xl'} 
    //             children={
    //                 <FormularioDeLineasIntegridad 
    //                     cerrarModal={this.toggleDelModalAgregarLinea} 
    //                 />
    //             }
    //             elModalEstaAbierto={this.state.elModalAgregarLineaEstaAbierto} 
    //             toggleDelModal={this.toggleDelModalAgregarLinea}
    //         >
    //         </CustomModal>
    //     )
    // }
    
    generarBotones= () => {
        return(
            <BotonDeCardConEncabezado key={'crear Linea'}
            icono={AiOutlinePlusCircle}
            title={'Crear linea'}
            onClick={() => {
                this.toggleDelModalAgregarLinea()
            }} />
        )
    }

    renderizarTablaAsmeb313 = () => {
        if (this.state.lineasAsmeb313.length > 0 ){
            const mensajeTablaVacia = 'No hay datos para mostrar, por favor, carga los datos presionando el botón "cargar datos"';
            return(
                <CustomTableDinamica
                    keyDeLaTabla={'Tabla informacion' + this.state.lineasAsmeb313.length}
                    listaDeDatos={ crearListaDatosParaTabla(this.state.lineasAsmeb313)  }
                    columnas={ crearColumnasParaTabla(this.state.lineasAsmeb313, mensajeTablaVacia) }
                    criterioDeOrdenamiento={ {id: "numero", desc: false} }
                />
                // <TablaConServerPaginator
                //     keyDeTabla={new Date()}
                //     norma={313}
                //     cantidadDeDatos={this.state.cantidadDeActivosProceso}
                //     listaDeDatos={crearListaDatosParaTabla(this.state.lineasAsmeb313)}
                //     criterioDeOrdenamiento={{ id: "numero", desc: false }}
                //     columnas={crearColumnasParaTabla(this.state.lineasAsmeb313, mensajeTablaVacia)}
                //     getDataPaginada={this.getDataPaginada}
                //     paginator={true}
                //     cargando={this.state.cargandoTablaProceso}
                // />
            )
        } 
        else {
            const estilosMensaje = {color: '#9fa5a9', margin: '0 auto'}
            return(
                <React.Fragment>
                    <div style={{display: 'flex'}}>
                        <span style={estilosMensaje}>No hay activos para ASME B31.3, por favor, carga los datos mediante la consona de cargue.</span>
                    </div>
                </React.Fragment>
            )
        }
    }

    renderizarTablaAsmeb314 = () => {
        if (this.state.lineasAsmeb314.length > 0 ) {
            const mensajeTablaVacia = 'No hay datos para mostrar, por favor, carga los datos presionando el botón "cargar datos"';
            return(
                <CustomTableDinamica
                    keyDeLaTabla={'Tabla informacion' + this.state.lineasAsmeb314.length}
                    listaDeDatos={ crearListaDatosParaTabla(this.state.lineasAsmeb314)  }
                    columnas={ crearColumnasParaTabla(this.state.lineasAsmeb314, mensajeTablaVacia) }
                    criterioDeOrdenamiento={ {id: "numero", desc: false} }
                />
                // <TablaConServerPaginator
                //     keyDeTabla={new Date()}
                //     norma={314}
                //     cantidadDeDatos={this.state.cantidadDeActivosProduccion}
                //     listaDeDatos={crearListaDatosParaTabla(this.state.lineasAsmeb314)}
                //     criterioDeOrdenamiento={{ id: "numero", desc: false }}
                //     columnas={crearColumnasParaTabla(this.state.lineasAsmeb314, mensajeTablaVacia)}
                //     getDataPaginada={this.getDataPaginada}
                //     paginator={true}
                //     cargando={this.state.cargandoTablaProduccion}/>
            )
        } else {
            const estilosMensaje = {color: '#9fa5a9', margin: '0 auto'}
            return(
                <React.Fragment>
                    <div style={{display: 'flex'}}>
                        <span style={estilosMensaje}>No hay activos para ASME B31.4, por favor, carga los datos mediante la consona de cargue.</span>
                    </div>
                </React.Fragment>
            )
        }
    }

    actualizarCampoTarget = (nuevoCampoTarget) => {
        let camposCalculados = this.state.listaDeCamposCalculados
        try { 
            this.setState({ 
                campoTarget: nuevoCampoTarget 
            }, () => { 
                if (!camposCalculados.includes(nuevoCampoTarget)) {
                    camposCalculados.push(nuevoCampoTarget)
                    this.setState({listaDeCamposCalculados: camposCalculados})
                    this.actualizarNodosProceso(); this.actualizarNodosProduccion()
                }
                this.setState({
                    lineasAsmeb313: armarDataParaTablaProceso(this.state.activosProceso.filter(activos => 
                        activos.informacionDeLosPadres.campoAsociado.nombre === nuevoCampoTarget
                    )),
                    lineasAsmeb314: armarDataParaTablaProduccion(this.state.activosProduccion.filter(activos => 
                        activos.informacionDeLosPadres.campoAsociado.nombre === nuevoCampoTarget
                    )),
                }, () => {
                    this.generarCardDeLasTablas()
                })
            });
        }
        catch (error) { };
    }

    getDataPaginada = async (paginaACargar, norma)  => {
       
        let paginasCargadas313 = this.state.paginasCargadas313
        let paginasCargadas314 = this.state.paginasCargadas314
        if (norma === 313) {
            if (!paginasCargadas313.includes(paginaACargar)){
                paginasCargadas313.push(paginaACargar);
                this.setState({paginasCargadas313: paginasCargadas313});
                try{
                    await this.actualizarNodosProceso(true)
                }
                catch(error){ console.log(error) }
            }
        } else if (norma === 314) {
            if (!paginasCargadas314.includes(paginaACargar)){
                paginasCargadas314.push(paginaACargar);
                this.setState({paginasCargadas314: paginasCargadas314});
                try{
                    await this.actualizarNodosProduccion(true)
                }
                catch(error){ console.log(error)}
            }
        }

    }

    consultarCantidadDeActivos = () => {
        const urlEmpresas = 'bdPrueba/9AKrQUDKh3UGtqDD2Rpk/empresas'
        firestoreDB.collection(urlEmpresas)
            .where('dominioCorporativo', '==',  this.props.usuarioActivo.dominioCorporativo)
            .get()
            .then(snapShots => {
                snapShots.forEach(snapShot => {
                    this.setState({
                        cantidadDeActivosProduccion: snapShot.data().cantidadDeActivosProduccion,
                        cantidadDeActivosProceso: snapShot.data().cantidadDeActivosProceso
                    });
                });
            });
    }

    // actualizarNodosProceso = async (seConsultoProceso) => {
    //     if ( !seConsultoProceso ) {
    //         this.setState({
    //             // cargando: true
    //         });
    //     } else {
    //         this.setState({
    //             cargandoTablaProceso: true
    //         });
    //     }
        
    //     try{
    //     let promiseDeConsultaDeActivos = firestoreDB.collection("bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos");
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionDeLosPadres.campoAsociado.nombre", "==", this.state.campoTarget);
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionGeneral.tipoDeActividad.nombre", "==", "Proceso");
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("modulos.API570" , "==", true);
    //         if (seConsultoProceso) { 
    //             promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.startAfter(this.state.posicionUltimoDatoProceso); 
    //             promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.limit(this.state.limiteDeDescargas); 
    //         } else {
    //             promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.limit(this.state.limiteDeDescargas); 
    //         }
    //         let activosAntiguos = [];
    //         let activosProceso = await this.consultarNodos(promiseDeConsultaDeActivos, 'proceso');
    //         this.setState({cargando: false})
    //         if (this.state.activosProceso.length > 0) {
    //             activosAntiguos.push(...this.state.activosProceso)
    //             activosAntiguos.push(...activosProceso)
    //             this.setState({
    //                 activosProceso: activosAntiguos,

    //                 activosProcesoPorCampo: activosAntiguos.filter(activos => 
    //                     activos.informacionDeLosPadres.campoAsociado.nombre === this.state.campoTarget
    //                 ),
                    
    //                 nodosDescargadosLaPrimeraVez: true,
    //                 cargando: false
                    
    //             }, () => { 
                    
    //                 this.actualizarDataEnReduxStorage();  
    //                 this.setState({
    //                     lineasAsmeb313: armarDataParaTablaProceso(this.state.activosProcesoPorCampo),
    //                 })
    //             });
    //         } else {
    //             this.setState({
    //                 activosProceso: activosProceso,

    //                 activosProcesoPorCampo: activosProceso.filter(activos => 
    //                     activos.informacionDeLosPadres.campoAsociado.nombre === this.state.campoTarget
    //                 ),
                    
    //                 nodosDescargadosLaPrimeraVez: true,
    //                 cargando: false
    
    //             }, () => { 
    //                 this.actualizarDataEnReduxStorage(); 
    //                 this.setState({
    //                     lineasAsmeb313: armarDataParaTablaProceso(this.state.activosProcesoPorCampo),
    //                 })
    //             });
    //         }
                
    //         setTimeout(() => { 
    //             this.setState({ 
    //                 cargando: false, 
    //                 keyDeRenderizado: this.state.keyDeRenderizado + 1, 
    //                 cargandoTablaProceso: false 
    //             }); 
    //         }, 1000);


    //     }
    //     catch(error){
    //         console.log(error);
    //         this.setState({ cargando: false, cargandoTablaProceso: false  });
    //         window.document.body.style.cursor = "default";
    //     }
    // }

    // actualizarNodosProduccion = async (seConsultoProduccion) => {
    //     if ( !seConsultoProduccion ) {
    //         this.setState({
    //             // cargando: true
    //         });
    //     } else {
    //         this.setState({
    //             cargandoTablaProduccion: true
    //         });
    //     }


    //     try{
    //     let promiseDeConsultaDeActivos = firestoreDB.collection("bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos");
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionDeLosPadres.campoAsociado.nombre", "==", this.state.campoTarget);
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionGeneral.tipoDeActividad.nombre", "==", "Producción");
    //         promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("modulos.API570" , "==", true);
    //         if (seConsultoProduccion) { 
    //             promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.startAfter(this.state.posicionUltimoDatoProduccion); 
    //             promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.limit(this.state.limiteDeDescargas); 
    //         } else {
    //             promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.limit(this.state.limiteDeDescargas); 
    //         }

    //         let activosAntiguos = [];
    //         let activosProduccion = await this.consultarNodos(promiseDeConsultaDeActivos, 'produccion');
    //         if (this.state.activosProduccion.length > 0) {
    //             activosAntiguos.push(...this.state.activosProduccion)
    //             activosAntiguos.push(...activosProduccion)
    //             this.setState({
    //                 activosProduccion: activosAntiguos,

    //                 activosProduccionPorCampo: activosAntiguos.filter(activos => 
    //                     activos.informacionDeLosPadres.campoAsociado.nombre === this.state.campoTarget
    //                 ),
    
    //                 nodosDescargadosLaPrimeraVez: true,
    //                 cargando: false
    
    //             }, () => { 
    //                 this.actualizarDataEnReduxStorage();  
    //                 this.setState({
    //                     lineasAsmeb314: armarDataParaTablaProduccion(this.state.activosProduccionPorCampo),
    //                     cargando: false
    //                 })
    //             });
    //         } else {
    //             this.setState({
    //                 activosProduccion: activosProduccion,

    //                 activosProduccionPorCampo: activosProduccion.filter(activos => 
    //                     activos.informacionDeLosPadres.campoAsociado.nombre === this.state.campoTarget
    //                 ),
    
    //                 nodosDescargadosLaPrimeraVez: true,
    //                 cargando: false
    
    //             }, () => { 
    //                 this.actualizarDataEnReduxStorage();  
    //                 this.setState({
    //                     lineasAsmeb314: armarDataParaTablaProduccion(this.state.activosProduccionPorCampo),
    //                     cargando: false
    //                 })
    //             });
    //         }


    //         setTimeout(() => { this.setState({ cargando: false, keyDeRenderizado: this.state.keyDeRenderizado + 1, cargandoTablaProduccion: false }); }, 1000);
    //     }
    //     catch(error){
    //         console.log(error);
    //         this.setState({ cargando: false, cargandoTablaProduccion: false  });
    //         window.document.body.style.cursor = "default";
    //     }
    // }

    actualizarNodosProceso = async(seConsultoProceso) => {
        try {
            // this.setState({cargando: true})
            let activosProceso = await consultarActivosPorCampo(
                this.props.usuarioActivo.dominioCorporativo,
                this.state.campoTarget,
                'Proceso',
                'API570'
            )
            let activosProcesoPorCampo = activosProceso.filter(activos => 
                activos.informacionDeLosPadres.campoAsociado.nombre === this.state.campoTarget
            )
            this.setState({
                    activosProceso: activosProceso,
                    lineasAsmeb313: armarDataParaTablaProceso(activosProcesoPorCampo),
                },
                () => {
                    this.setState({
                        cargando: false,
                    })
                }
            )
        }
        catch(error) {
            console.log('Hubo un error al consultar los nodos de producción : ',error)
            this.setState({cargando: false})
        }
    }

    actualizarNodosProduccion = async(seConsultoProceso) => {
        try {
            // this.setState({cargando: true})
            let activosProduccion = await consultarActivosPorCampo(
                this.props.usuarioActivo.dominioCorporativo,
                this.state.campoTarget,
                'Producción',
                'API570'
            )
            let activosProduccionPorCampo = activosProduccion.filter(activos => 
                activos.informacionDeLosPadres.campoAsociado.nombre === this.state.campoTarget
            )
            this.setState(
                {
                    activosProduccion: activosProduccion,
                },
                () => {
                    this.setState({
                        cargando: false,
                        lineasAsmeb314: armarDataParaTablaProduccion(activosProduccionPorCampo),
                    })
                }
            )
        }
        catch(error) {
            console.log('Hubo un error al consultar los nodos de producción : ',error)
            this.setState({cargando: false})
        }
    }

    toggleDelModalAgregarLinea = () => {
        this.setState({elModalAgregarLineaEstaAbierto: !this.state.elModalAgregarLineaEstaAbierto})
    }

    consultarNodos = (query, tabla) => {
        return new Promise((resolve, reject) => {
            const listaDeNodos = [];
            query.get()
                .then(snapShots => {
                    const last = snapShots.docs[snapShots.docs.length - 1];
                    if (tabla === 'produccion'){
                        this.setState({posicionUltimoDatoProduccion: last})
                    } else if (tabla === 'proceso') {
                        this.setState({posicionUltimoDatoProceso: last})
                    }
                    snapShots.forEach(snapShot => {
                        listaDeNodos.push({
                            id: snapShot.id,
                            ...snapShot.data(),
                            referencia: snapShot.ref
                        });
                        resolve(listaDeNodos);
                    })
                })
                .catch(error => {console.log(error); reject(error)});
        })
    }   

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    obtenerBotonDeCargueMasivo = () => {
        return (
            <BotonParaSection key="botonDeCargaEnApi580"
                onClick={this.toggleDeLaConsolaDeCargue}
                icono={<FaCloudUploadAlt />} title="Cargar datos" />
        );
    }

    //Toggles de los modales:
    toggleDeLaConsolaDeCargue = () => {
        this.setState({ laConsolaDeCargueEstaAbierta: !this.state.laConsolaDeCargueEstaAbierta });
    }

    cambiarBoton = (boton) => {
        this.setState({botonActivo: boton})
    }

    actualizarDataEnReduxStorage = () => {
        this.props.actualizarDataApi570({
            "nodosDescargados": true,
            "campoActivo": this.state.campoTarget,
            "activos": this.state.todosLosActivos,
            "coleccionActiva": 'activos',
        });
    }

    obtenerMensajeDeDescargaFallida = () => {
        if (this.state.mostrarLabelDeDescargaFallida) {
            return (
                <p style={{ marginBottom: 2.5 }}
                    dangerouslySetInnerHTML={{ __html: "La descarga de exportación a excel <b>falló</b>" }} />
            );
        }
    }

    obtenerMensajeDeLimiteDeDescargas = () => {
        if (!this.state.cargando && this.state.campoTarget) {
            return (
                <p style={{ marginBottom: 2.5 }}
                    dangerouslySetInnerHTML={{
                        __html: "Se descargaron <b>todos los activos" 
                            + "</b> del campo ("
                            + (this.state.lineasAsmeb313.length + this.state.lineasAsmeb314.length) + ")"
                    }} />
            );
        }
        else {
            return (
                <p style={{ marginBottom: 2.5 }}
                    dangerouslySetInnerHTML={{ __html: "..." }} />
            );
        }
    }
}
const mapStateToProps = state => ({
    dataDe570EnRedux: state.reducerParaData["/app/analisis/integridad/api570"],
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

const mapDispatchToProps = dispatch => ({
    actualizarDataApi570: nuevaData => { dispatch(actualizarDataApi570(nuevaData)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizadorApi570);
