export const obtenerCantidadEnNotacionCorta = (cantidad, mostrarPrefijo = true) => {
    try {
        if (cantidad === 0) {  //0,001 -> 0,099
            return `${mostrarPrefijo?'$ ':''}0`;
        }
        if (cantidad >= (10**(-3)) && cantidad < (10**(-1))) {  //0,001 -> 0,099
            return ((mostrarPrefijo) ? "$ " : "") + cantidad.toFixed(3);
        }
        else if (cantidad > 0 && cantidad < (10**(-3))) {   //0 -> 0,00099999 
            return ((mostrarPrefijo) ? "$ " : "") + cantidad.toExponential(1);
        }        
        if (cantidad >= 0 && cantidad < 1000) {
            return ((mostrarPrefijo) ? "$ " : "") + cantidad.toFixed(1);
        }
        else {
            if (cantidad >= 1000 && cantidad < 1000000) {
                return ((mostrarPrefijo) ? "$ " : "")  + (cantidad / 1000).toFixed(1) + "k";
            }
            else {
                if (cantidad >= 1000000) {
                    return ((mostrarPrefijo) ? "$ " : "")  + (cantidad / 1000000).toFixed(1) + "M";
                }
            }
        }
    }
    catch (error) {
        return 0;
    }
}