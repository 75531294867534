export var MainNav = []

MainNav = [
    {
        icon: 'pe-7s-gym',
        label: 'Devs',
        to: '/app/dev'
    },
    {
        icon: 'pe-7s-way',
        label: 'Contexto',
        content: [
            {
                label: 'Objetivos2',
                to: '/app/dashboard'
            },
            {
                label: 'Criterios',
                content:[
                    {
                        label: 'Definición de modelos',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Definición de criterios técnicos',
                        to: '/app/dashboard'
                    },
                ]
            },
        ]
    },
    {
        icon: 'pe-7s-id',
        label: 'Identificación',
        content: [           
            {
                label:'Definición de Activos',
                to: '/app/dashboard'
            },           
            {
                label:'Arbol de Base de Datos',
                to: '/app/dashboard'
            },           
        ]
    },
    {
        icon: 'pe-7s-search',
        label: 'Análisis',
        content: [           
            {
                label: 'Valoración de Riesgo',
                content:[
                    {
                        label: 'API 580',
                        to: '/app/analisis/riesgo/api580'
                    },
                    {
                        label: 'ASME B31.8S',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'API 1160',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'NORSOK',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Valoración de válvulas',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                label: 'Integridad',
                content:[
                    {
                        label: 'API 570',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'API 510',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'API 653',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'API Hornos',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'API Rotores',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                label: 'Corrosión Interna',
                content:[
                    {
                        label: 'ICDA',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                label: 'Corrosión Externa',
                content:[
                    {
                        label: 'ECDA',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                label: 'Pipeline Assestment',
                content:[
                    {
                        label: 'Atenciones mecánicas',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Bending Stress',
                        to: '/app/dashboard'
                    },
                ]
            },
        ]
    },
    {
        icon: 'pe-7s-calculator',
        label: 'Evaluación',
        content: [    
            {
                label: 'Determinación de esfuerzos',
                to: '/app/dashboard'
            },
            {
                label: 'Análisis de flexibilidad',
                to: '/app/dashboard'
            },
            {
                label: 'FFS',
                to: '/app/dashboard'
            },
        ]
    },
    {
        icon: 'pe-7s-note2',
        label: 'Tratamiento',
        content: [
            {
                label:'Planes de Inspección',
                to: '/app/tratamiento/planesDeAccion'
            },
            {
                label: 'Planes de Mantenimiento',
                to: '/app/dashboard'
            },
            {
                label: 'Planes de Monitoreo',
                to: '/app/dashboard'
            },
            {
                label: 'Sistema de protección Catódica',
                to: '/app/dashboard'
            },
            {
                label: 'Monitoreo de Corroison interna',
                to: '/app/dashboard'
            },
            {
                label: 'AFA',
                to: '/app/dashboard'
            },
            {
                label: 'ACR',
                to: '/app/dashboard'
            },
        ]
    },
    {
        icon: 'pe-7s-shuffle',
        label: 'Seguimiento',
        content: [
            { 
                label: 'Definición de indicadres ',
                 to: '/app/dashboard'
             },
            { 
                label: 'Cumplimiento de indicadores',
                 to: '/app/dashboard'
             },
            { 
                label: 'Anállsis de Brechas',
                 to: '/app/dashboard'
             },
            { 
                label: 'Auditorias técnicas',
                 to: '/app/dashboard'
             },
        ]
    },
    {
        icon: 'pe-7s-headphones',
        label: 'Comunicación y Consulta',
        content: [
           { 
               label: 'Exportación de datos',
                to: '/app/dashboard'
            },
           { 
               label: 'Interfas ARGis',
                to: '/app/dashboard'
            },
           { 
               label: 'Interfas CAD',
                to: '/app/dashboard'
            },
        ]
    }
];

export const AdminNav = [
    {
        icon: 'pe-7s-users',
        label: 'Usuarios',
        content: [
            {
                label: 'Roles',
                to: '/app/dashboard'
            },
            {
                label: 'Historial',
                to: '/app/dashboard'
            }
        ]
    },
    {
        icon: 'pe-7s-lock',
        label: 'Seguridad',
        to: '/app/dashboard'
    },
    {
        icon: 'pe-7s-tools',
        label: 'Parametrización',
        to: '/app/dashboard',
    }
];