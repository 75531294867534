export const recorrerMatrizDeDatosIntegridad = (matrizDeDatos, contador) => {
    let batch = [];
        let objetoConDataIntegridad = {};
        matrizDeDatos[0].forEach((key, indiceKey) => {
            if(key === 'fechaDeActivacion' || key === 'fechaDeInspeccion') {
                objetoConDataIntegridad[key] =  (matrizDeDatos[contador][indiceKey].getTime() / 1000);
                // / (3600 * 24)) + (365*70) + 19
            } else {
                objetoConDataIntegridad[key] =  matrizDeDatos[contador][indiceKey];
            }
        })
        batch.push(objetoConDataIntegridad);
    return batch;
}