import {
    glosarioDeInputsOrdenDeTrabajo1,
    glosarioDeInputsOrdenDeTrabajo2,
    glosarioDeInputsIDAnomaliaILI,
    glosarioDeInputsUbGeograficaILI,
    glosarioDeInputsDatosExcavacionILI,
    glosarioDeInputsGeoILI,
    glosarioEdicionAnomaliaPM1,
    glosarioEdicionAnomaliaPM2,
    glosarioDeInputsObservaciones
} from './glosarioVariablesDeInput';

export const obtenerValidacionDeVariable = (key, seccion) =>{
    const variable = obtenerGlosario(seccion, key);
    if(variable != null){
        switch(variable["categoria"]){
            case "valorUnico":
                return validacionValorUnico(variable);
            case "valorAbierto" : 
                return validacionValorAbierto();
            default:
                return {
                    "status" : "VARIABLE_INVALIDA",
                    "error" : "La categoría no se encuentrá en el glosario"
                };
        }
    }else{
        return {
            "status" : "VARIABLE_INVALIDA",
            "error" : "La variable no se encuentrá en el glosario"
        };
    }
}

const obtenerGlosario = (seccion, key) => {
    switch (seccion) {
        case 'id_anomalia_ili':
                return glosarioDeInputsIDAnomaliaILI[`${key}`]   
        case 'ub_geografica_ili':
            return glosarioDeInputsUbGeograficaILI[`${key}`] 
        case 'datos_excavacion':
            return glosarioDeInputsDatosExcavacionILI[`${key}`]
        case 'ub_anomalia_campo':
            return glosarioDeInputsGeoILI[`${key}`]
            case 'observaciones':
            return glosarioDeInputsObservaciones[`${key}`]
        case 'orden_trabajo_1':
            return glosarioDeInputsOrdenDeTrabajo1[`${key}`];
        case 'orden_trabajo_2':
            return glosarioDeInputsOrdenDeTrabajo2[`${key}`]
        case 'edicion_anomalia_pm_1':
            return glosarioEdicionAnomaliaPM1[`${key}`]
        case 'edicion_anomalia_pm_2':
            return glosarioEdicionAnomaliaPM2[`${key}`]
        default:
            return glosarioDeInputsOrdenDeTrabajo1[`${key}`]
    }
}
const validacionValorAbierto = () =>{
    return {
        "status" : "VALIDACION_REQUERIDA",
        "validation" : convertirVariableATexto
    };
}

const convertirVariableATexto = (valor) =>{
    try{
        valor.toString();
        return true;
    }catch(e){  
        return false;
    }
}



const validacionValorUnico = (variable) =>{
    switch(variable["tipo"]){
        case "numerico":
            return {
                "status" : "VALIDACION_REQUERIDA",
                "validation" : validarValorNumerico
            };
        case "fecha":
            return {
                "status" : "VALIDACION_REQUERIDA",
                "validation" : validarValorFecha
            };
        case "correo_electronico":
            return {
                "status" : "VALIDACION_REQUERDIDA",
                "validation": validarCorreo
            };         
        default:
            return {
                "status" : "TIPO_INVALIDO",
                "success" : false,
                "error" : "El tipo de variable no está registrado"
            }; 
    }
}

const validarValorFecha = (variable, valor) =>{
    try{
        let flag = true;
        const fecha = Date.parse(valor);
        if(variable['condicionesDeLaVariable'] !== undefined){
            for (var i = 0; i < variable["condicionesDeLaVariable"].length; i++) {
                const condicion = variable["condicionesDeLaVariable"][i];
                switch(condicion["operador"]){
                    case "<=" : 
                        flag =  fecha <= (Date.now() - new Date().getTimezoneOffset()*60*1000);
                        break;
                    default:
                        break;
                }
                if(!flag){
                    return flag;
                }
            }
        }
        return flag;
    }catch(e){
        return false;
    }
}

const validarValorNumerico = (variable, valor) =>{
    try{
        let flag = true;
        const numero = parseFloat(valor);
        for (let i = 0; i < variable["condicionesDeLaVariable"].length; i++) {
            const condicion = variable["condicionesDeLaVariable"][i];
            switch(condicion["operador"]){
                case ">" : 
                    flag =  numero > condicion['valorTarget'];
                    break;
                case ">=" : 
                    flag =  numero >= condicion['valorTarget'];
                    break;
                case "<" : 
                    flag =  numero < condicion['valorTarget'];
                    break;
                case "<=" : 
                    flag =  numero <= condicion['valorTarget'];
                    break;
                case "==" : 
                    flag =  numero === condicion['valorTarget'];
                    break;
                default:
                    break;
            }
            if(!flag){
                return flag;
            }
        }
        return flag;
    }catch(e){
        return false;
    }
}

const validarCorreo = (variable,valor) => {
    try {
        const correoRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return correoRegex.test(String(valor).toLowerCase());
    } catch (error) {
        console.log('error correo electrónico');
        return false;
    }
}