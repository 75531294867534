import React, { Component } from 'react'
import { ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts'
import PropTypes from 'prop-types'

const objectPath = require("object-path")
var Interval = require('math.interval')

class GraficoDeTorta extends Component {
    constructor() {
        super();
        this.state = {
            activeIndex: 0,
            data: []
        }
    }

    componentDidMount() {
        this.setState({ data: ajustarData(this.props.data, this.props.keyVariable, this.props.parametrizacion, this.props.modeloDeConsecuencia) })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.keyVariable !== this.props.keyVariable || prevProps.data !== this.props.data) {
            this.setState({ data: ajustarData(this.props.data, this.props.keyVariable, this.props.parametrizacion, this.props.modeloDeConsecuencia) });
        }
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        return (
            <ResponsiveContainer height='100%' aspect={10.0 / 5} >
                <PieChart style={{ backgroundColor: "rgb(252,252,252)", borderRadius: 5 }}>
                    <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={(activeProps) => renderizarMouseOver(activeProps, this.state.data)}
                        data={this.state.data}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#b0b0b0"
                        dataKey="valor"
                        onMouseEnter={this.onPieEnter}
                    >
                        {this.state.data.map((data) => <Cell key={'segmento' + data.nombre} fill={data.color + '80'} />)}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        )
    }
}

const ajustarData = (lista, keyDeVariable, parametros, modeloDeConsecuencia) => {
    let dataAjustada = [];
    let listaDeEtiquetas = Object.keys(parametros);

    for (let k = 0; k < listaDeEtiquetas.length; k++) {
        let objetoDeNivel = {};
        objetoDeNivel['nombre'] = parametros[listaDeEtiquetas[k]].etiqueta;
        objetoDeNivel['valor'] = 0;
        objetoDeNivel['color'] = parametros[listaDeEtiquetas[k]].color;
        dataAjustada.push(objetoDeNivel);
    }

    for (let j = 0; j < lista.length; j++) {
        for (let k = 0; k < listaDeEtiquetas.length; k++) {
            try {

                if (lista[j].resultados) {
                    lista[j].resultados.forEach(item => {
                        switch (item.id) {
                            case "API581":
                               
                                if(modeloDeConsecuencia === "Cualitativo"){
                                    if(valoresConsecuencia(listaDeEtiquetas[k], lista[j].resultados[0], keyDeVariable, parametros)){
                                        dataAjustada[k]['valor']++
                                        break;
                                    }
                                }else{
                                    if (new Interval(listaDeEtiquetas[k]).contains(objectPath.get(lista[j].resultados[0], keyDeVariable))) {
                                        dataAjustada[k]['valor']++
                                        break;
                                    }
                                }
                                break;
                            default:
                                console.log("Tipo de evaluacion no identificado");
                                break;
                        }
                    })
                } else {
                    if (new Interval(listaDeEtiquetas[k]).contains(objectPath.get(lista[j], keyDeVariable))) {
                        dataAjustada[k]['valor']++
                        break;
                    }
                }
            }
            catch (error) { };
        }
    }

    return dataAjustada
}

const valoresConsecuencia = (listaDeEtiquetas, item, keyDeVariable, parametros) =>{  
    return parametros[listaDeEtiquetas].valorCualitativo === objectPath.get(item, keyDeVariable);  
}

const renderizarMouseOver = (props, info) => {
    const radianes = Math.PI / 180;
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        payload, percent, valor, fill
    } = props;
    const sin = Math.sin(-radianes * midAngle);
    const cos = Math.cos(-radianes * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx}
                y={cy}
                dy={8}
                textAnchor="middle"
                fill='#525252'
                style={{ fontWeight: "bold" }}>
                {payload.nombre}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={obtenerColor(info, payload.nombre)}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={obtenerColor(info, payload.nombre, true)}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#525252">{valor}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={obtenerColor(info, payload.nombre)}>
                {'(' + (percent * 100).toFixed(1) + ' %)'}
            </text>
        </g>
    );
}

const obtenerColor = (info, nombre, ponerOpacidad) => {
    ponerOpacidad = ponerOpacidad === undefined ? false : true;
    for (let k = 0; k < info.length; k++) {
        if (info[k].nombre === nombre) {
            return ponerOpacidad ? info[k].color + 'B3' : info[k].color
        }
    }
}

GraficoDeTorta.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    keyVariable: PropTypes.string,
    parametrizacion: PropTypes.object
}

export default GraficoDeTorta;