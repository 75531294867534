import React from 'react';
import MapaBase from './MapaBase';

//Utilidades de conexión con Google maps Api:
import { googleMapsCredentials } from '../../../firebase';

const defaultMapOptions = {
    fullscreenControl: false,
    mapTypeId: "terrain",
    mapTypeControl: false,
    streetViewControl: false,
    tilt: 45,
};

const Mapa = (props) => {
    defaultMapOptions.mapTypeId = props.tipoMapa === 'mapa' ? "terrain" : "hybrid"
    return (
        <MapaBase
            containerElement={<div style={{ height: 400, ...props.containerStyles }} />}
            googleMapURL={googleMapsCredentials.googleMapURL}
            mapElement={<div style={{ height: "100%" }} />}

            loadingElement={<p>Cargando...</p>}

            defaultCenter={props.defaultCenter}
            defaultOptions={{ ...defaultMapOptions, ...props.defaultOptions }}
            defaultZoom={props.defaultZoom}

            tipoMapa={props.tipoMapa}
            >

            {props.children}
        </MapaBase>
    );
}

export default Mapa;