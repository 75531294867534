import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';

class MapaBase extends React.Component {

    constructor() {
        super();

        this.state = {
            trazas: undefined,
        };
    }
    
    render() {
        return (
            <GoogleMap
            ref={"myMap"}
            {...this.props}>
                {this.props.children}
            </GoogleMap>
        );
    }
    
    componentDidMount() {
        this.setearLimitesDelMapa();
    }

    componentDidUpdate(prevProps){
        if(this.props.children !== prevProps.children){
            this.setState({
                trazas: this.props.children
            })
            this.setearLimitesDelMapa();
        }
    }

    //Métodos operativos:
    setearLimitesDelMapa = () => {
        setTimeout(()=> {
            let limitesDelMapa = new window.google.maps.LatLngBounds();
                try{
                this.props.children.forEach((traza) => {
                    if (traza.props.segmento) {
                        try {
                            traza.props.segmento.trazaGeoreferenciada.puntos.forEach((punto) => {
                                limitesDelMapa.extend(new window.google.maps.LatLng(punto.lat, punto.lng));
                            });
                        }
                        catch (error) { console.log(error); };
                    }else if(traza.props.ordenDeTrabajo){
                        try {
                            traza.props.ordenDeTrabajo.puntosGeoreferencia.forEach((punto) => {
                                limitesDelMapa.extend(new window.google.maps.LatLng(punto.lat, punto.lng));
                            });
                        }
                        catch (error) { console.log(error); };
                    } else if(traza.props.activoIntegridad){
                        try{
                            traza.props.activoIntegridad.forEach((punto) => {
                                limitesDelMapa.extend(new window.google.maps.LatLng(punto.lat, punto.lng));
                            });
                        }
                        catch(error){console.log(error)}
                    } else if(traza.props.segmentoIntegridad){
                        try{
                            traza.props.segmentoIntegridad.forEach((punto) => {
                                limitesDelMapa.extend(new window.google.maps.LatLng(punto.lat, punto.lng));
                            });
                        }
                        catch(error){console.log(error)}
                    }
                });
            
            }
            catch(error){console.log(error)}
                try{
                    this.refs.myMap.fitBounds(limitesDelMapa);
                }
                catch(error){console.log(error)}
            }, 500)        
    }
}

export default withScriptjs(withGoogleMap(MapaBase));