export const formatearvalorsegunestado = ( valor, decimales ) => {
    let valorARetornar = undefined;
    if (typeof(decimales) === 'string') {
        valorARetornar = valor !== undefined || valor !== null ? valor : 'Aún sin calcular'
    } else if (typeof(decimales) === 'number') {
        valorARetornar = valor !== undefined || valor !== null ? isNaN( valor ) || valor == 0 ? ' - ' : parseFloat(valor).toFixed(decimales) : 'Aún sin calcular'
    } else {
        valorARetornar = valor !== undefined || valor !== null ? isNaN( valor ) || valor == 0 ? ' - ' : valor : 'Aún sin calcular'
    }
    return valorARetornar
}
