import listarValoresSegunRango from './listarValoresSegunRango';

const armarDataParaGraficoDeTortaUtScanA = (tipo, nodosCml) => {
    const datosParaGrafica = [];

    const vidaRemanenteMenor = [];
    const vidaRemanenteMedia = [];
    const vidaRemanenteMayor = [];  

    const velocidadCorrosionMenor = [];
    const velocidadCorrosionMedio = [];
    const velocidadCorrosionMayor = [];

    const mawpMenor = [];
    const mawpMedio = [];
    const mawpMayor = [];
    
    nodosCml.forEach((data, i) => {
        try{


            const resVidaRem = data?.resultadosIntegridad?.vidaRemanente?.valor;
            const dataVidaRem = listarValoresSegunRango(resVidaRem, 5, 20, 20);
            vidaRemanenteMenor.push(...dataVidaRem['menor']);
            vidaRemanenteMedia.push(...dataVidaRem['medio']);
            vidaRemanenteMayor.push(...dataVidaRem['mayor']);


            const resVelCor = data?.resultadosIntegridad?.velocidadCorrosion?.valor;
            const dataVelCor = listarValoresSegunRango(resVelCor, 2, 4, 4);
            velocidadCorrosionMenor.push(...dataVelCor['menor'])
            velocidadCorrosionMedio.push(...dataVelCor['medio'])
            velocidadCorrosionMayor.push(...dataVelCor['mayor'])

            const resMawp = data?.resultadosIntegridad?.MAWP?.valor;
            const dataResMap = listarValoresSegunRango(resMawp, 2, 4, 4)
            mawpMenor.push(...dataResMap['menor'])
            mawpMedio.push(...dataResMap['medio'])
            mawpMayor.push(...dataResMap['mayor'])

        }
        catch(e){console.log(e)} 
    })
    switch(tipo){
        case 'Vida remanente':
            datosParaGrafica.push(
                {color: "#d22928", etiqueta: "VidaRemanente menor a 5 años", valor: vidaRemanenteMenor.length || 0  },
                {color: "#e7961e", etiqueta: "VidaRemanente entre 5 y 20 años", valor: vidaRemanenteMedia.length || 0  },
                {color: "#66be33", etiqueta: "VidaRemanente mayor a 20 años", valor: vidaRemanenteMayor.length || 0 },
                )
            return datosParaGrafica;
        case 'Porcentaje de pérdida':
            datosParaGrafica.push(
                {color: "#d22928", etiqueta: "Velocidad de corrosión menor a 2 mpy", valor: velocidadCorrosionMenor.length|| 0  },
                {color: "#e7961e", etiqueta: "Velocidad de corrosión entre 2 y 4 mpy", valor: velocidadCorrosionMedio.length|| 0  },
                {color: "#66be33", etiqueta: "Velocidad de corrosión mayor a 4mpy", valor: velocidadCorrosionMayor.length || 0 },
                )
            return datosParaGrafica;
        case 'MAWP':
            datosParaGrafica.push(
                {color: "#d22928", etiqueta: "mawp menor a 2700 ", valor: mawpMenor.length|| 0  },
                {color: "#e7961e", etiqueta: "mawp entre 2700 y 3000 ", valor: mawpMedio.length|| 0  },
                {color: "#66be33", etiqueta: "mawp mayor a 3000 ", valor: mawpMayor.length || 0 },
                )
            return datosParaGrafica;
        default:
            datosParaGrafica.push(
                {color: "#333", etiqueta: "No hay datos para mostrar ", valor: 0 },
                )
            return datosParaGrafica;

    }
}

export default armarDataParaGraficoDeTortaUtScanA