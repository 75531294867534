export const formatearFechaParaHistorialDeResultados = () => {

    /** Método para seleccionar la fecha mas reciente 
     * @param {Array} fechasDeInspeccion lista de las fechas de inspección del activo
     */
    const seleccionarLaFechaMasReciente = ( fechasDeInspeccion ) => {
        const fechasOrdenadas = fechasDeInspeccion.sort( ( a, b ) => (a.posicion > b.posicion) ? 1 : ((a.posicion < b.posicion) ? -1 : 0));
        return fechasOrdenadas[0];
    }

    /**
     * Filtra la fecha que está en el estado de a cuerdo a una lista de fechas
     * @param {*} fechasDeInspeccion Lista de fechas de inspección.
     * @param {*} fechaEnEstado Fecha de inspección a la cual se quiere 
     */
    const filtrarFechaSeleccionada = ( fechasDeInspeccion, fechaEnEstado ) => {
        const fechaSeleccionada = fechasDeInspeccion.filter( fecha => new Date( fecha.seconds * 1000 ).toLocaleString().split(',')[0] === fechaEnEstado );
        return fechaSeleccionada;
    }

    /**
     * Da el formato para la fecha inicial previamente filtrada
     * @param {*} fechasDeInspeccion Lista de fechas de inspección.
     * @param {*} fechaEnEstado Fecha de inspección a la cual se quiere 
     */
    const darFormatoAFechaInicio = ( fechasDeInspeccion, fechaEnEstado ) => {
        const fechaSeleccionada = filtrarFechaSeleccionada( fechasDeInspeccion, fechaEnEstado );
        const fechaInicioFormateada = new Date(new Date((fechaSeleccionada[0].seconds * 1000) - 60*60*24*1000).setHours(0,0,0,0));
        return fechaInicioFormateada;
    }

    /**
     * Da el formato para la fecha final previamente filtrada
     * @param {*} fechasDeInspeccion Lista de fechas de inspección.
     * @param {*} fechaEnEstado Fecha de inspección a la cual se quiere 
     */
    const darFormatoAFechaFin = ( fechasDeInspeccion, fechaEnEstado ) => {
        const fechaSeleccionada = filtrarFechaSeleccionada( fechasDeInspeccion, fechaEnEstado );
        const fechaFinFormateada = new Date(new Date((fechaSeleccionada[0].seconds * 1000) + 60*60*24*1000).setHours(0,0,0,0))
        return fechaFinFormateada; 
    }

    return { filtrarFechaSeleccionada, darFormatoAFechaInicio, darFormatoAFechaFin, seleccionarLaFechaMasReciente }
}
