import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card } from 'reactstrap';

//Componentes auxiliares:
import ProgressBarDeDescarga from './ProgressBarDeDescarga';
import CustomModal from '../plantillaBase/CustomModal';

//Cloud functions
import { firebaseFunctions } from '../../../firebase';

//Tablas de React
// import datosPruebaExportacion from '../scripts/datosPrueba';


const exportarResultados = firebaseFunctions.httpsCallable('exportarResultados');
const exportarLogs = firebaseFunctions.httpsCallable('exportarLogs');


class ConsolaDeDescarga extends React.Component {

    constructor() {
        super();

        this.state = {
            normaTarget: undefined,
            tamañoParticion: 50,
            progresoExportacionResultados: 0,
            progresoExportacionLogs: 0,
            estadoDelProceso: "inicio" //inicio; cargandoDatos; procesoFinalizado; error; sinDatos
        }
    }

    componentWillReceiveProps() {
        if (this.props.segmentos.length === 0 && this.props.activos.length === 0) {
            this.setState({
                estadoDelProceso: "sinDatos"
            });
        } else {
            if (this.state.estadoDelProceso === "sinDatos") {
                this.setState({
                    estadoDelProceso: "inicio"
                });
            }
        }
    }

    render() {
        return (
            <CustomModal size="lg" elModalEstaAbierto={this.props.elModalEstaAbierto}
                toggleDelModal={this.props.toggleDelModal} titulo={<span>Consola para <b>descargar</b> los activos y segmentos filtrados</span>}>

                <Row style={{ padding: 15 }}>
                    <Col xs="12" md="12" lg="6" xl="6">
                        <Card className="widget-chart border mb-3">
                            <div className="widget-heading">
                                <p>
                                    Se van a descargar <b>{this.props.activos.length}</b> activos y <b>{this.props.segmentos.length}</b> segmentos del campo <b>{this.props.campoTarget}</b>
                                </p>
                            </div>

                            <div className="widget-subheading" style={{ opacity: 1 }}
                                dangerouslySetInnerHTML={{ __html: this.obtenerSubtituloSegunEstado() }} />

                            <hr />
                            {this.obtenerBotonDeInicioDeCarga()}
                        </Card>
                    </Col>

                    <Col xs="12" md="12" lg="6" xl="6">
                        {this.obtenerProgressBars()}
                    </Col>
                </Row>
            </CustomModal>
        );
    }

    obtenerProgressBars = () => {
        return (
            <Card className="widget-chart border mb-3">
                <ProgressBarDeDescarga
                    titulo={'Resultados'}
                    porcentajeCompletado={this.state.progresoExportacionResultados}
                    status={"OK"}
                    key={"descargaResultados." + (new Date()).getTime()} />
                <ProgressBarDeDescarga
                    titulo={'Log de cálculos'}
                    porcentajeCompletado={this.state.progresoExportacionLogs}
                    status={"OK"}
                    key={"descargarLogs." + (new Date()).getTime()} />
            </Card>
        );
    }

    obtenerBotonDeInicioDeCarga = () => {
        if (this.state.estadoDelProceso === "inicio") {
            return (
                <Button className="btn-wide pusheable-button"
                    style={{ marginBottom: 20 }} color="success"
                    onClick={() => {
                        this.descargarNodos();
                    }}>
                    Iniciar descarga
                </Button>
            );
        }
    }

    obtenerSubtituloSegunEstado = () => {
        switch (this.state.estadoDelProceso) {
            case "inicio":
                return "<br/>Requerimos confirmación para iniciar el proceso. Este puede tardar algunos segundos";
            case "cargandoDatos":
                return "<br/>Procesando datos...";
            case "procesoFinalizado":
                return "<br/>El proceso de exportación terminó.<br/><br/>";
            case "error":
                return "<br/>El proceso de exportación no pudo finalizar correctamente.<br/><br/>"
            case "sinDatos":
                return "<br/>No hay datos que exportar<br/><br/>"
            default:
                return "";
        }
    }

    descargarNodos = async () => {
        this.setState({
            estadoDelProceso: "cargandoDatos"
        });
        const particionActivos = this.props.activos.slice(0, this.state.tamañoParticion);
        const particionSegmentos = this.props.segmentos.slice(0, this.state.tamañoParticion);

        Promise.all([
            this.cargarDatosAResultados(this.props.normaTarget, null, particionSegmentos, particionActivos, 0),
            this.cargarDatosALogs(this.props.normaTarget, null, particionSegmentos, 0)
        ])
            .then(response => {
                try {
                    this.setState({
                        estadoDelProceso: "inicio",
                        progresoExportacionResultados: 0,
                        progresoExportacionLogs: 0,

                    });
                    if (this.props.elModalEstaAbierto) {
                        this.props.toggleDelModal();
                        this.props.alertExitoso('Descarga exitosa', 'Revisa las ventanas emergentes');
                    }

                    window.open(response[0].download_url);
                    window.open(response[1].download_url);

                }
                catch (error) {
                    this.setState({
                        estadoDelProceso: "error",
                        progresoExportacionResultados: 0,
                        progresoExportacionLogs: 0,
                    });

                    if (this.props.elModalEstaAbierto) {
                        this.props.toggleDelModal();
                        this.props.alertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                    }
                };
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    estadoDelProceso: "error",
                    progresoExportacionResultados: 0,
                    progresoExportacionLogs: 0,
                });

                if (this.props.elModalEstaAbierto) {
                    this.props.toggleDelModal();
                    this.props.alertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                }
            });
    }

    cargarDatosAResultados = (normaTarget, documento, segmentos, activos, puntoInicial) => {
        const limiteMaximo = Math.max(this.props.activos.length, this.props.segmentos.length);
        this.actualizarProgresoResultados((puntoInicial < limiteMaximo) ? puntoInicial : limiteMaximo, limiteMaximo);
        return new Promise((resolve, reject) => {
            exportarResultados({
                "norma": normaTarget,
                "documento": documento,
                "segmentos": segmentos,
                "activos": activos,
                "sheetId": this.props.sheetId	
            })
                .then(response => {
                    if (puntoInicial < this.props.activos.length || puntoInicial < this.props.segmentos.length) {
                        const nuevoInicio = puntoInicial + this.state.tamañoParticion;
                        const nuevoDocumento = documento = response["data"]["documento"];
                        const particionActivos = this.props.activos.slice(nuevoInicio, nuevoInicio + this.state.tamañoParticion);
                        const particionSegmentos = this.props.segmentos.slice(nuevoInicio, nuevoInicio + this.state.tamañoParticion);
                        resolve(this.cargarDatosAResultados(this.props.normaTarget, nuevoDocumento, particionSegmentos, particionActivos, nuevoInicio));
                    }
                    resolve(response["data"]);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    cargarDatosALogs = (normaTarget, documento, segmentos, puntoInicial) => {
        this.actualizarProgresoLogs((puntoInicial < this.props.activos.length) ? puntoInicial : this.props.activos.length, this.props.activos.length);
        return new Promise((resolve, reject) => {
            exportarLogs({
                "norma": normaTarget,
                "documento": documento,
                "segmentos": segmentos,
            })
                .then(response => {
                    if (puntoInicial < this.props.segmentos.length) {
                        const nuevoInicio = puntoInicial + this.state.tamañoParticion;
                        const nuevoDocumento = documento = response["data"]["documento"];
                        const particionSegmentos = this.props.segmentos.slice(nuevoInicio, nuevoInicio + this.state.tamañoParticion);
                        resolve(this.cargarDatosALogs(this.props.normaTarget, nuevoDocumento, particionSegmentos, nuevoInicio));
                    }
                    resolve(response["data"]);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    actualizarProgresoResultados = (progreso, total) => {
        this.setState({
            progresoExportacionResultados: progreso / total * 100
        });
    }

    actualizarProgresoLogs = (progreso, total) => {
        this.setState({
            progresoExportacionLogs: progreso / total * 100
        });
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ConsolaDeDescarga);