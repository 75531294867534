// El activador del modal de este componente está en el state del padre...
import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, CardBody,
    CardTitle, ListGroup, ListGroupItem, Button, Card, Collapse, 
    CardHeader, CardText,
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar'

import { glosarioDeOpciones, glosarioDeRespuestas} from '../../scripts/glosarioFAQ'

class CustomModalFAQ extends React.Component {

    constructor() {
        super();

        this.state = {
            accordion: [false, false, false],
            opcionActiva: 'opcion1',
        }
    }

    render() {
        return (
            <Modal 
                isOpen={this.props.elModalEstaAbierto}
                toggle={() => { 
                    this.props.toggleDelModal() }}
                className={'modal-content-transparent modal-content-transparent-image'}
                size={"xl"} >

                <ModalHeader
                    toggle={() => {
                        this.setState({ imagePath: undefined })
                        this.props.toggleDelModal()
                    }}>
                    <div>
                        Preguntas Frecuentes
                    </div>
                </ModalHeader>

                <ModalBody style={{ padding: 10 }} className={'modal-body-faq'} >
                    <PerfectScrollbar>
                        <div className={'modal-faq-body'}>
                            <div className={'modal-faq-body-1'}>
                                <CardBody>
                                    <CardTitle>GIACORE WEB</CardTitle>
                                    <ListGroup flush>
                                        {glosarioDeOpciones.map(opcion => {
                                            if (opcion.type === 'web') {
                                                if (opcion.key === this.state.opcionActiva) {
                                                    return (<ListGroupItem
                                                        active
                                                        action
                                                        key={opcion.key}
                                                        tag="button"
                                                        onClick={() => { this.setState({ opcionActiva: opcion.key }) }}
                                                    >
                                                        {opcion.nombre}
                                                    </ListGroupItem>
                                                    )
                                                }
                                                else {
                                                    return (<ListGroupItem
                                                        action
                                                        key={opcion.key}
                                                        tag="button"
                                                        onClick={() => { this.setState({ opcionActiva: opcion.key }) }}
                                                    >
                                                        {opcion.nombre}
                                                    </ListGroupItem>
                                                    )
                                                }
                                            }
                                            else {
                                                return (
                                                    <React.Fragment key={`void_${opcion.key}`}></React.Fragment>
                                                )
                                            }
                                        })}
                                    </ListGroup>
                                    <br></br>
                                    <CardTitle>GIACORE APP</CardTitle>
                                    <ListGroup flush>
                                        {glosarioDeOpciones.map(opcion => {
                                            if (opcion.type === 'app') {
                                                if (opcion.key === this.state.opcionActiva) {
                                                    return (<ListGroupItem
                                                        active
                                                        action
                                                        key={opcion.key}
                                                        tag="button"
                                                        onClick={() => { this.setState({ opcionActiva: opcion.key }) }}
                                                    >
                                                        {opcion.nombre}
                                                    </ListGroupItem>
                                                    )
                                                }
                                                else {
                                                    return (<ListGroupItem
                                                        action
                                                        key={opcion.key}
                                                        tag="button"
                                                        onClick={() => { this.setState({ opcionActiva: opcion.key }) }}
                                                    >
                                                        {opcion.nombre}
                                                    </ListGroupItem>
                                                    )
                                                }
                                            }
                                            else {
                                                return (
                                                    <React.Fragment key={`void_${opcion.key}`}></React.Fragment>
                                                )
                                            }
                                        })}
                                    </ListGroup>
                                </CardBody>
                            </div>
                            <div className={'modal-faq-body-2'}>
                                {glosarioDeRespuestas.map((respuesta, index) => {
                                    if (respuesta.padre === this.state.opcionActiva) {
                                        return (
                                            <Card key={`card_${respuesta.key}`}>
                                                <CardHeader key={`cardHeader_${respuesta.key}`} id="headingOne">
                                                    <Button block color="link" className="text-left m-0 p-0"
                                                        key={`button_${respuesta.key}`}
                                                        onClick={() => this.toggleAccordion(index)}
                                                        aria-expanded={this.state.accordion[index]}
                                                        aria-controls="collapseOne">
                                                        <h5 className="m-0 p-0">{respuesta.nombre}</h5>
                                                    </Button>
                                                </CardHeader>
                                                <Collapse key={`collapse_${respuesta.key}`} isOpen={this.state.accordion[index]} data-parent="#accordion"
                                                    id={`collapse${index}`} aria-labelledby="headingOne">
                                                    {respuesta.content.map((contenido, index2) => {
                                                        switch (contenido.type) {
                                                            case 'text':
                                                                return (
                                                                    <CardBody key={`CardBody_${respuesta.key}_${index}_${index2}`}>
                                                                        <CardText>{contenido.value}</CardText>
                                                                    </CardBody>
                                                                )
                                                            case 'img':
                                                                return (
                                                                    <div key={`div_${respuesta.key}_${index}_${index2}`} className={'modal-body-faq-image'}>
                                                                        <img className={'img-resize'} style={{ height: '400px' }} src={contenido.value} alt={contenido.value} />
                                                                    </div>
                                                                )
                                                            case 'card':
                                                                return (
                                                                    <CardBody key={`CardBody_${respuesta.key}_${index}_${index2}`}>
                                                                        <CardTitle tag="h5">{contenido.title}</CardTitle>
                                                                        <CardText>{contenido.value}</CardText>
                                                                    </CardBody>
                                                                )
                                                            default:
                                                                return <></>
                                                        }
                                                    })}
                                                </Collapse>
                                            </Card>
                                        )
                                    }
                                    else {
                                        return (
                                            <React.Fragment key={index}></React.Fragment>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter className={'modal-footer-tranparent-2'}>
                </ModalFooter>
            </Modal>
        );
    }

    componentDidMount() {
        const accordion = glosarioDeRespuestas.map(() => false)
        
        this.setState({
            accordion: accordion,
        })
    }

    toggleAccordion(tab) {

        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }
}    
    export default CustomModalFAQ;