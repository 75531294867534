import React, { Component } from 'react'
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import PropTypes from 'prop-types'

class GraficoDeBarrasParaResumenFacturado extends Component {
    constructor() {
        super();
        this.state = {
            activeIndex: 0,
            data: []
        }
    }

    componentDidMount() {
        this.setState({ data: 
            this.props.parametrizacion
         })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.parametrizacion !== this.props.parametrizacion) {
            this.setState({ data: 
                this.props.parametrizacion
             });
        }
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    renderColorfulLegendText = (value) => {
        return <span style={{ fontWeight: '100', fontSize:16 }}>{value}</span>;
    }
    uppeCasetoLowerCase = (texto) => {
        switch (texto) {
            case 'valor':
                return 'Valor Facturado'
            case 'cantidad':
                return 'Cantidad de Ordenes'
            default:
                return 'Cantidad de Ordenes'
        }
    } 

     DataFormater = (number) => {
        if(number > 1000000000){
          return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
          return (number/1000000).toString() + 'M';
        }else if(number > 1000){
          return (number/1000).toString() + 'K';
        }else{
          return number.toString();
        }
      }

      DataFormaterMoneda = (number) => {
        if(number > 1000000000){
          return '$' + ((number/1000000000).toFixed(2)).toString() + 'B';
        }else if(number > 1000000){
          return '$' + ((number/1000000).toFixed(2)).toString() + 'M';
        }else if(number > 1000){
          return '$' + ((number/1000).toFixed(2)).toString() + 'K';
        }else{
          return number.toString();
        }
      }

    render() {
            return (
                <ResponsiveContainer height='5%' aspect={10.0 / 3} >
                    <ComposedChart data={this.state.data}
                        margin={{ right: 10, left: 10}}
                        //margin={{ top: 0, right: 20, bottom: 20, left: 20 }}
                        style={{ backgroundColor: "rgb(252,252,252)", borderRadius: 5 }}>
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis dataKey="etiqueta" />
                        <YAxis type='number' label={{ value: "Cantidad de Ordenes", angle: -90, position: 'right', offset:-10 }} orientation='right' yAxisId={0} domain={[0, 100]} />
                        <YAxis type='number' label={{ value: "Total Facturado", angle: -90, position: 'insideLeft', }} yAxisId={1} tickFormatter={this.DataFormater} />
                        {/* <Tooltip content={this.CustomTooltip}/> */}
                        <Tooltip
                            formatter={(value, name, props) => {return [this.DataFormaterMoneda(value), name,]}}
                        />
                        <Legend
                            formatter={this.renderColorfulLegendText}
                            iconSize={20}
                            onMouseEnter={this.onPieEnter}
                            verticalAlign={'bottom'}
                            payload={[{
                                id: 'leyendaValor',
                                type: "line",
                                color: '#ff7300',
                                value: `${this.uppeCasetoLowerCase('valor')}`,
                            },
                            {
                                id: 'leyendaCantidad',
                                type: "square",
                                color: '#43B929',
                                value: `${this.uppeCasetoLowerCase('cantidad')}`,
                            }]
                            }
                        />
                        <Bar name= 'Cantidad de Ordenes' dataKey='cantidad' barSize={20} fill='#43B929' yAxisId={0} />
                        <Line name= 'Valor Total Facturado' type='monotone' dataKey='valor' stroke='#ff7300' yAxisId={1}/>
                    </ComposedChart>
                </ResponsiveContainer>
            )
        
    }
}

GraficoDeBarrasParaResumenFacturado.propTypes = {
    parametrizacion: PropTypes.array
}

export default GraficoDeBarrasParaResumenFacturado;