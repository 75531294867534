export const LOGEAR_USUARIO = 'USUARIO/LOGEAR_USUARIO';
export const DESLOGEAR_USUARIO = 'USUARIO/DESLOGEAR_USUARIO';
export const SETEAR_PARAMETRIZACION = 'USUARIO/SETEAR_PARAMETRIZACION';

const initialState = {
    elUsuarioEstaLogeado: false,

    usuarioActivo: {
        photoURL: "",
        email: "example@insercor.com",
        displayName: "Usuario Activo",
        rol: "tecnico",
        dominioCorporativo: "insercor.com",
        companyId: undefined, 
        administradores: [],
        clientes: [],
        firma: {
            fechaActualizacionFirma: undefined, //firebase date 
            pathStorage: "", //path Signatures/<email>/Sign_<email.name>.<extension>
            tieneFirma: false
        }
    },


    parametrizacionCorporativa: {
        "rangosDeRiesgo": {
            "(0,1]": { color: "#66be33", etiqueta: 'L', valorCualitativo: 1 },
            "(1,2]": { color: "#e7961c", etiqueta: 'M', valorCualitativo: 2 },
            "(2,3]": { color: "#d2571e", etiqueta: 'H', valorCualitativo: 3 },
            "(3,4]": { color: "#d21e1e", etiqueta: 'E', valorCualitativo: 4 },
        },
        "rangosDeConsecuencia": {
            "(0, 10000]": {
                "color": "#66be33",
                "valorCualitativo": 1,
                "etiqueta": "1"
            },
            "(10000, 100000]": {
                "color": "#f1ca1c",
                "valorCualitativo": 2,
                "etiqueta": "2"
            },
            "(100000, 1000000]": {
                "color": "#e7961c",
                "valorCualitativo": 3,
                "etiqueta": "3"
            },
            "(1000000, 10000000]": {
                "color": "#d2571e",
                "valorCualitativo": 4,
                "etiqueta": "4"
            },
            "(10000000, 10000000000]": {
                "color": "#d21e1e",
                "valorCualitativo": 5,
                "etiqueta": "5"
            }
        },
        "rangosDeProbabilidad": {
            "(0, 3.06E-05]": {
                "color": "#66be33",
                "valorCualitativo": 1,
                "etiqueta": "1"
            },
            "(3.06E-05, 3.06E-04]": {
                "color": "#f1ca1c",
                "valorCualitativo": 2,
                "etiqueta": "2"

            },
            "(3.06E-04, 3.06E-03]": {
                "color": "#e7961c",
                "valorCualitativo": 3,
                "etiqueta": "3"
            },
            "(3.06E-03, 3.06E-02]": {
                "color": "#d2571e",
                "valorCualitativo": 4,
                "etiqueta": "4"
            },
            "(3.06E-02, 1]": {
                "color": "#d21e1e",
                "valorCualitativo": 5,
                "etiqueta": "5"
            }
        },
        "rangosMatriz": {
            "4,5": {
                "riesgoCualitativo": 4,
                "color": "#d21e1e",
                "nivelDeRiesgo": "E"
            },
            "1,2": {
                "riesgoCualitativo": 1,
                "color": "#66be33",
                "nivelDeRiesgo": "L"
            },
            "5,2": {
                "riesgoCualitativo": 3,
                "color": "#d2571e",
                "nivelDeRiesgo": "H"
            },
            "5,1": {
                "nivelDeRiesgo": "M",
                "riesgoCualitativo": 2,
                "color": "#e7961c"
            },
            "1,4": {
                "nivelDeRiesgo": "M",
                "riesgoCualitativo": 2,
                "color": "#e7961c"
            },
            "4,2": {
                "nivelDeRiesgo": "M",
                "riesgoCualitativo": 2,
                "color": "#e7961c"
            },
            "5,5": {
                "riesgoCualitativo": 4,
                "color": "#d21e1e",
                "nivelDeRiesgo": "E"
            },
            "3,4": {
                "riesgoCualitativo": 3,
                "color": "#d2571e",
                "nivelDeRiesgo": "H"
            },
            "5,3": {
                "nivelDeRiesgo": "H",
                "color": "#d2571e",
                "riesgoCualitativo": 3
            },
            "2,3": {
                "riesgoCualitativo": 2,
                "color": "#e7961c",
                "nivelDeRiesgo": "M"
            },
            "3,2": {
                "nivelDeRiesgo": "M",
                "riesgoCualitativo": 2,
                "color": "#e7961c",
            },
            "4,1": {
                "nivelDeRiesgo": "M",
                "riesgoCualitativo": 2,
                "color": "#e7961c",
            },
            "2,5": {
                "riesgoCualitativo": 3,
                "color": "#d2571e",
                "nivelDeRiesgo": "H"
            },
            "2,4": {
                "riesgoCualitativo": 2,
                "color": "#e7961c",
                "nivelDeRiesgo": "M"
            },
            "2,2": {
                "nivelDeRiesgo": "L",
                "riesgoCualitativo": 1,
                "color": "#66be33"
            },
            "5,4": {
                "nivelDeRiesgo": "E",
                "riesgoCualitativo": 4,
                "color": "#d21e1e"
            },
            "1,1": {
                "riesgoCualitativo": 1,
                "color": "#66be33",
                "nivelDeRiesgo": "L"
            },
            "3,3": {
                "nivelDeRiesgo": "M",
                "riesgoCualitativo": 2,
                "color": "#e7961c",
            },
            "1,5": {
                "riesgoCualitativo": 2,
                "color": "#e7961c",
                "nivelDeRiesgo": "M"
            },
            "3,1": {
                "riesgoCualitativo": 1,
                "color": "#66be33",
                "nivelDeRiesgo": "L"
            },
            "1,3": {
                "nivelDeRiesgo": "L",
                "riesgoCualitativo": 1,
                "color": "#66be33"
            },
            "3,5": {
                "riesgoCualitativo": 3,
                "color": "#d2571e",
                "nivelDeRiesgo": "H"
            },
            "4,3": {
                "riesgoCualitativo": 3,
                "color": "#d2571e",
                "nivelDeRiesgo": "H"
            },
            "4,4": {
                "riesgoCualitativo": 3,
                "color": "#d2571e",
                "nivelDeRiesgo": "H"
            },
            "2,1": {
                "nivelDeRiesgo": "L",
                "riesgoCualitativo": 1,
                "color": "#66be33"
            }
        },
        "menu": [
            {
                icon: 'pe-7s-gym',
                label: 'Devs',
                to: '/app/dev'
            },
            {
                icon: 'pe-7s-way',
                label: 'Contexto',
                content: [
                    {
                        label: 'Objetivos2',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Criterios',
                        content: [
                            {
                                label: 'Definición de modelos',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'Definición de criterios técnicos',
                                to: '/app/dashboard'
                            },
                        ]
                    },
                ]
            },
            {
                icon: 'pe-7s-id',
                label: 'Identificación',
                content: [
                    {
                        label: 'Definición de Activos',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Arbol de Base de Datos',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                icon: 'pe-7s-search',
                label: 'Análisis',
                content: [
                    {
                        label: 'Valoración de Riesgo',
                        content: [
                            {
                                label: 'API 580',
                                to: '/app/analisis/riesgo/api580'
                            },
                            {
                                label: 'ASME B31.8S',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'API 1160',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'NORSOK',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'Valoración de válvulas',
                                to: '/app/dashboard'
                            },
                        ]
                    },
                    {
                        label: 'Integridad',
                        content: [
                            {
                                label: 'API 570',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'API 510',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'API 653',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'API Hornos',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'API Rotores',
                                to: '/app/dashboard'
                            },
                        ]
                    },
                    {
                        label: 'Corrosión Interna',
                        content: [
                            {
                                label: 'ICDA',
                                to: '/app/dashboard'
                            },
                        ]
                    },
                    {
                        label: 'Corrosión Externa',
                        content: [
                            {
                                label: 'ECDA',
                                to: '/app/dashboard'
                            },
                        ]
                    },
                    {
                        label: 'Pipeline Assestment',
                        content: [
                            {
                                label: 'Atenciones mecánicas',
                                to: '/app/dashboard'
                            },
                            {
                                label: 'Bending Stress',
                                to: '/app/dashboard'
                            },
                        ]
                    },
                ]
            },
            {
                icon: 'pe-7s-calculator',
                label: 'Evaluación',
                content: [
                    {
                        label: 'Determinación de esfuerzos',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Análisis de flexibilidad',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'FFS',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                icon: 'pe-7s-note2',
                label: 'Tratamiento',
                content: [
                    {
                        label: 'Planes de Inspección',
                        to: '/app/tratamiento/planesDeAccion'
                    },
                    {
                        label: 'Planes de Mantenimiento',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Planes de Monitoreo',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Sistema de protección Catódica',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Monitoreo de Corroison interna',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'AFA',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'ACR',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                icon: 'pe-7s-shuffle',
                label: 'Seguimiento',
                content: [
                    {
                        label: 'Definición de indicadres ',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Cumplimiento de indicadores',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Anállsis de Brechas',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Auditorias técnicas',
                        to: '/app/dashboard'
                    },
                ]
            },
            {
                icon: 'pe-7s-headphones',
                label: 'Comunicación y Consulta',
                content: [
                    {
                        label: 'Exportación de datos',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Interfas ARGis',
                        to: '/app/dashboard'
                    },
                    {
                        label: 'Interfas CAD',
                        to: '/app/dashboard'
                    },
                ]
            }
        ]
    }
}

export const logearUsuario = (usuarioTarget) => ({
    type: LOGEAR_USUARIO,
    usuarioTarget
});

export const deslogearUsuario = () => ({
    type: DESLOGEAR_USUARIO
});

export const setearParametrizacion = (nuevaParametrizacion) => {
    return ({
        type: SETEAR_PARAMETRIZACION,
        nuevaParametrizacion
    });
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case LOGEAR_USUARIO:
            let dominioCorporativoSecundario = action.usuarioTarget.dominioCorporativo
            return {
                ...state,
                elUsuarioEstaLogeado: true,
                usuarioActivo: { ...action.usuarioTarget, dominioCorporativo: dominioCorporativoSecundario }
            };

        case DESLOGEAR_USUARIO:
            return {
                ...state,
                elUsuarioEstaLogeado: false
            };

        case SETEAR_PARAMETRIZACION:
            return {
                ...state,
                parametrizacionCorporativa: { ...action.nuevaParametrizacion }
            };

        default:
            return state;
    }
}
