// El activador del modal de este componente está en el state del padre...
import React from 'react';
import { connect } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import { Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//Componentes auxiliares:
import CardDeResumen from '../resumenDeModulo/CardDeResumen.jsx';
import CustomModal from './CustomModal.jsx';
import TimelineDeProceso from '../resumenDeModulo/TimelineDeProceso.jsx';

//Utilidades de almacén y scripts:
import { plantillasDeCargue } from '../../almacen/plantillasDeCargue.js';

//Utilidades para cargar datos para inspecciones
import { cargarDeDatosDeInspeccionEnFirebase, cargarDeDatosDeInspeccionHijaEnFirebase } from '../../scripts/paraCargueMasivoDeInspecciones';
// Utilidades para validar los datos de cargue 
import {
    validarDatosDeInspeccion,
    validarDatosDeInspeccionODTHija,
} from '../../scripts/validarDatosDeInspeccion';
// import {ejemploDeMatrizDeCargue} from '../estaticos/ejemploDeMatrizDeCargue.js';

//Constantes auxiliares:
const timeoutDeLecturaDelFichero = 2000;
const itemDeInicioDelLog = {
    status: "info",
    fecha: new Date(),
    contenido: <div className="widget-subheading" style={{ textAlign: "left", margin: 0 }}>Esperando a que comience el proceso...</div>
}
const obtenerItemDeFinalizacionExitosa = (segundos) => {
    return (
        <div className="widget-subheading" style={{ textAlign: "left", margin: 0 }}>{"El proceso terminó con éxito. Tardó " + segundos + " segundos"}</div>
    );
}

class ConsolaDeCargue extends React.Component {

    constructor() {
        super();

        this.state = {
            puedoCerrarElModal: true,

            tipoDeActivos: "", //líneas, vasijas, válvulas, etc.
            estadoDelProceso: "inicio", //inicio; leyendoFichero; errorLeyendoElFichero; ficheroListo; cargandoData; procesoFinalizado
            normaTecnicaAsociada: "580",
            ficheroSeleccionado: { path: "miExcel.xlsx" },

            subtitulo: "<span style='opacity:0.6;'>Selecciona un archivo para comenzar</span>",

            matrizDeDatos: [],
            matrizDeDatosHija: [],
            logDeProceso: [itemDeInicioDelLog]
        }
    }

    render() {
        return (
            <CustomModal size="lg" elModalEstaAbierto={this.props.elModalEstaAbierto}
                toggleDelModal={() => { this.toggleDelModal() }} titulo={<span>Herramienta para <b>cargue masivo</b></span>}>

                <Row style={{ padding: 15 }}>
                    <Col xs="12" md="6" lg="6" xl="4">
                        <CardDeResumen
                            subtitulo={this.state.subtitulo}
                            icono={this.obtenerIconoSegunActivo()}
                            titulo={this.obtenerTituloDeResumenSegunState()}>

                            <div style={{ justifyContent: "center" }}>
                                {this.obtenerBotonParaIniciarProcesoEnBackend()}
                                {this.obtenerBotonParaSeleccionarArchivo()}
                                {this.obtenerBotonParaDescargarPlantilla()}
                                {this.obtenerBotonParaResetearArchivo()}
                            </div>
                        </CardDeResumen>
                        <input type="file" ref="miInputDeFichero" style={{ display: "none" }}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => { this.alistarFicheroDeExcel(e.target.files[0]); }} />
                    </Col>

                    <Col xs="12" md="6" lg="6" xl="8">
                        <TimelineDeProceso logDeProceso={this.state.logDeProceso} />
                    </Col>

                </Row>

            </CustomModal>
        );
    }

    componentDidMount() {
    }

    descargarPlantilla = () => { }
    //Métodos para renderizado:
    obtenerBotonParaIniciarProcesoEnBackend = () => {
        let estadoDelProceso = this.state.estadoDelProceso;

        if (estadoDelProceso === "ficheroListo") {
            return (
                <Button className="btn-wide pusheable-button"
                    color="success" style={{ marginBottom: 20 }}
                    href
                    onClick={() => {
                        // window.document.getElementById("miInputParaPruebas").value = JSON.stringify(this.state.matrizDeDatos);
                        this.cargarElementosDesdeMatrizDeDatos();
                    }}>
                    Iniciar proceso
                </Button>
            );
        }else{
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }

    obtenerBotonParaResetearArchivo = () => {
        let estadoDelProceso = this.state.estadoDelProceso;

        if (estadoDelProceso === "ficheroListo" || estadoDelProceso === "errorLeyendoElFichero" || estadoDelProceso === "procesoFinalizado") {
            return (
                <Button className="btn-wide pusheable-button"
                    color="info" style={{ marginBottom: 0 }} outline
                    onClick={() => { this.refs.miInputDeFichero.click(); }}>
                    Seleccionar otro archivo
                </Button>
            );
        }
    }

    obtenerBotonParaDescargarPlantilla = () => {
        return (
            <Button className="btn-wide pusheable-button"
                color="info" style={{ marginBottom: 20 }}
                onClick={() => { console.log('Plantilla') }}>
                <a className="ot-plantilla" href="https://drive.google.com/file/d/1VnXGr2MIgIZnV3PtDDGYNeuXn25qW8RS/view?usp=sharing"
                    target='_blank' rel="noopener noreferrer" download>Descargar Plantilla</a>
            </Button>
        );
    }
    obtenerBotonParaSeleccionarArchivo = () => {
        let estadoDelProceso = this.state.estadoDelProceso;

        if (estadoDelProceso === "inicio") {
            return (
                <Button className="btn-wide pusheable-button"
                    color="primary" style={{ marginBottom: 20 }}
                    onClick={() => { this.refs.miInputDeFichero.click(); }}>
                    Seleccionar archivo
                </Button>
            );
        }
    }

    obtenerIconoSegunActivo = () => {
        if (this.state.estadoDelProceso === "ficheroListo" || this.state.estadoDelProceso === "cargandoData") {
            return plantillasDeCargue["ductos"].urlDelIcono;
        }
    }

    obtenerTituloDeResumenSegunState = () => {

        switch (this.state.estadoDelProceso) {
            case "ficheroListo":
                return "Voy a cargar las ODT desde:";
            case "cargandoData":
                return "Estoy cargando desde:";
            case "procesoFinalizado":
                return "El proceso de carga ha finalizado";
            default:
                return "";
        }
    }

    //Métodos operativos:
    agregarElementoAlLogDeProceso = (contenido, status, usarTemplateBasico = true, limpiarLog = false) => {
        let nuevoLogDeProceso = (!limpiarLog) ? this.state.logDeProceso : [itemDeInicioDelLog];

        let nuevoItemDeProceso = { status: status, fecha: new Date(), contenido: contenido };
        if (usarTemplateBasico) {
            nuevoItemDeProceso.contenido =
                <div className="" style={{ textAlign: "left" }}
                    dangerouslySetInnerHTML={{ __html: contenido }} />
        }
        nuevoLogDeProceso.push(nuevoItemDeProceso);

        this.setState({ logDeProceso: nuevoLogDeProceso });
    }

    alistarFicheroDeExcel = (ficheroTarget) => {
        this.setState({ estadoDelProceso: "leyendoFichero", subtitulo: "Leyendo el archivo. Espera un poco...", });
        this.agregarElementoAlLogDeProceso("Inició la lectura del archivo <b>" + ficheroTarget.name + "</b>", "info", true, true);

        readXlsxFile(ficheroTarget,{ sheet: 1 }) //{ sheet: 1 } para primera hoja del excel
            .then(matrizDeDatos => {
                const nuevaMatriz = this.preparaDatos(matrizDeDatos,'madre');
                setTimeout(() => {
                    if (nuevaMatriz.length > 0) {
                        this.setState({
                            matrizDeDatos: nuevaMatriz,
                            subtitulo: ficheroTarget.name,
                            estadoDelProceso: "ficheroListo",
                            ficheroSeleccionado: { ...this.state.ficheroSeleccionado, nombre: ficheroTarget.name }
                        })
                    } else {
                        let mensajeDeError = "Se presentó el siguiente error interpretando al archivo: <br/><br/>";
                        mensajeDeError += "<span style='color: var(--danger);'> Las ordenes de trabajo no cuentan con toda la información </span>";

                        this.setState({ subtitulo: mensajeDeError, estadoDelProceso: "errorLeyendoElFichero", });
                        this.agregarElementoAlLogDeProceso("No se pudo cargar este archivo", "danger", true);
                    }
                }, timeoutDeLecturaDelFichero);

            });
        readXlsxFile(ficheroTarget, { sheet: 2 }) //{ sheet: 2 } para segunda hoja del excel
            .then(matrizDeDatos => {
                const nuevaMatriz = this.preparaDatos(matrizDeDatos, 'hija');
                setTimeout(() => {
                    if (nuevaMatriz.length >= 0) {
                        this.setState({
                            matrizDeDatosHija: nuevaMatriz,
                            subtitulo: ficheroTarget.name,
                            estadoDelProceso: "ficheroListo",
                            ficheroSeleccionado: { ...this.state.ficheroSeleccionado, nombre: ficheroTarget.name }
                        })
                    } else {
                        let mensajeDeError = "Se presentó el siguiente error interpretando al archivo: <br/><br/>";
                        mensajeDeError += "<span style='color: var(--danger);'> Las ordenes de trabajo no cuentan con toda la información </span>";

                        this.setState({ subtitulo: mensajeDeError, estadoDelProceso: "errorLeyendoElFichero", });
                        this.agregarElementoAlLogDeProceso("No se pudo cargar este archivo", "danger", true);
                    }
                }, timeoutDeLecturaDelFichero);

            });
    }

    preparaDatos = (matrizDeDatos, tipoODT) => {
        const dominioCorporativo = this.props.usuarioActivo.dominioCorporativo;
        let nuevaMatriz = [];
        switch (tipoODT) {
            case 'madre':
                nuevaMatriz = validarDatosDeInspeccion(matrizDeDatos,dominioCorporativo ,(contenido, estado) => {this.agregarElementoAlLogDeProceso(contenido,estado, true, false);} )
                break;
            case 'hija':
                nuevaMatriz = validarDatosDeInspeccionODTHija(matrizDeDatos,dominioCorporativo ,(contenido, estado) => {this.agregarElementoAlLogDeProceso(contenido,estado, true, false);} )
                break;
            default:
                break;
        }
        return nuevaMatriz;
    }

    cargarElementosDesdeMatrizDeDatos = () => {
        this.agregarElementoAlLogDeProceso("<b>Comenzó</b> el proceso de <b>carga masiva</b>", "info", true, true);
        this.setState({
            puedoCerrarElModal: false, estadoDelProceso: "cargandoData",
            subtitulo: "<span>" + this.state.ficheroSeleccionado.nombre +
                "</span><br/></br><span style='opacity:0.6;'>Por favor espera, esto puede tardar algunos minutos</span>"
        });

        cargarDeDatosDeInspeccionEnFirebase(this.state.matrizDeDatos,  (contenido, status) => {
            this.agregarElementoAlLogDeProceso(contenido, status, true, false);
        })
        .then(() =>{
            let tiempoTardado = "...";
            try { tiempoTardado = new Date() - this.state.logDeProceso[1].fecha; tiempoTardado = Math.ceil(tiempoTardado / 1000); }
            catch (error) { };

            let contenidoDeFinExitoso = obtenerItemDeFinalizacionExitosa(tiempoTardado);
            this.agregarElementoAlLogDeProceso(contenidoDeFinExitoso, "success", false); 
            if (this.state.matrizDeDatosHija.length === 0) {
                this.setState({
                    puedoCerrarElModal: true,
                    estadoDelProceso: "procesoFinalizado",
                    subtitulo: "<span style='color: var(--success);'><b>Con éxito</b></span>"
                });
            }
            else {
                cargarDeDatosDeInspeccionHijaEnFirebase(this.state.matrizDeDatosHija, (contenido, status) => {
                    this.agregarElementoAlLogDeProceso(contenido, status, true, false);
                })
                    .then(() => {
                        let tiempoTardado = "...";
                        try { tiempoTardado = new Date() - this.state.logDeProceso[1].fecha; tiempoTardado = Math.ceil(tiempoTardado / 1000); }
                        catch (error) { };

                        let contenidoDeFinExitoso = obtenerItemDeFinalizacionExitosa(tiempoTardado);
                        this.agregarElementoAlLogDeProceso(contenidoDeFinExitoso, "success", false);
                        this.setState({
                            puedoCerrarElModal: true,
                            estadoDelProceso: "procesoFinalizado",
                            subtitulo: "<span style='color: var(--success);'><b>Con éxito</b></span>"
                        });
                    })
                    .catch(() => {
                        this.setState({
                            puedoCerrarElModal: true,
                            estadoDelProceso: "procesoFinalizado",
                            subtitulo: "<span style='color: var(--danger);'><b>Con errores</b></span>"
                        });
                    })
            }
        })
        .catch(() =>{
            this.setState({
                puedoCerrarElModal: true,
                estadoDelProceso: "procesoFinalizado",
                subtitulo: "<span style='color: var(--danger);'><b>Con errores</b></span>"
            });
        })
    }

    toggleDelModal = () => {
        if (this.state.puedoCerrarElModal && this.props.toggleDelModal) {
            this.props.toggleDelModal();
        }
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    endpointParaCargarCampo: state.reducerParaFunctions.endpointParaCargarCampo,
    endpointParaCargarSistema: state.reducerParaFunctions.endpointParaCargarSistema,
    endpointParaCargarActivo: state.reducerParaFunctions.endpointParaCargarActivo,
    endpointParaCargarSegmento: state.reducerParaFunctions.endpointParaCargarSegmento,
});

export default withRouter(connect(mapStateToProps)(ConsolaDeCargue));