
//Componentes para las rutas:
import Home from '../../../vistas/Home.jsx';
import PlantillaBase from '../../../vistas/PlantillaBase.jsx';
import VisualizadorApi581Valvulas from '../../../vistas/aplicacion/analisis/riesgo/api581Valvulas/VisualizadorApi581Valvulas.jsx'
import VisualizadorApi580 from '../../../vistas/aplicacion/analisis/riesgo/api580/VisualizadorApi580.jsx';
import VisualizadorApi1160 from '../../../vistas/aplicacion/analisis/riesgo/api1160/VisualizadorApi1160.jsx';
import VisualizadorAsmeB318s from '../../../vistas/aplicacion/analisis/riesgo/asmeB318s/VisualizadorAsmeB318S.jsx';
import VisualizadorPlanesDeAccion from '../../../vistas/aplicacion/tratamiento/planesDeInspeccion/VisualizadorPlanesDeAccion.jsx';
import VizualizadorDashboardValvulas from "../../../vistas/aplicacion/gestion/api581Valvulas/visualizadores/Dashboard"

//En desarrollo:
import VisualizadorListaDeFormatos from '../../../vistas/aplicacion/formatoAnomalias/GestorOrdenesDeTrabajo/VisualizadorListaDeFormatos.jsx';
import VisualizadorAdministradorDeUsuarios from '../../../vistas/aplicacion/administrador/VisualizadorAdministradorDeUsuarios.jsx';

export const paths = [
    {
        path: '/app/dev',
        component: PlantillaBase
    },
    {
        path: '/app/dashboard',
        component: Home
    },
    {
        path: "",
        component: Home
    },
    {
        path: '/app/analisis/riesgo/api580',
        component: VisualizadorApi580
    },
    {
        path: '/app/analisis/riesgo/api1160',
        component: VisualizadorApi1160
    },
    {
        path: '/app/analisis/riesgo/asmeb318s',
        component: VisualizadorAsmeB318s
    },
    // Gestion de valvulas
    {
        path: '/app/analisis/riesgo/api581Valvulas',
        component: VisualizadorApi581Valvulas
    },
    {
        path: '/app/gestion/dashboard/api581Valvulas',
        component: VizualizadorDashboardValvulas
    },
    {
        path: '/app/tratamiento/planesDeAccion',
        component: VisualizadorPlanesDeAccion
    },
    // En desarrollo:
    {
        path: '/app/identificacion/ordenesDeTrabajo',
        component: VisualizadorListaDeFormatos
    },
    {
        path: '/app/administrador/usuarios',
        component: VisualizadorAdministradorDeUsuarios
    },
];

const obtenerPath = (obj, name, n, result, result2) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if ("object" === typeof (obj[key])) {
                obtenerPath(obj[key], name, n, result, result2);
            }
            else if (key === name) {
                if (result.indexOf(obj[key]) === -1) {
                    result.push(obj[key]);
                    result2.push(obj[n]);
                }
            }
        }
    }
    return [result, result2];
}

export const obtenerPathSegunMenu = (obj, name, n) => {
    let array = obtenerPath(obj, name, n, [], []);
    return array;
}