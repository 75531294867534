import { useState } from 'react'

/** Base de datos */
import { firestoreDB } from '../../../../../firebase'

export const useFetchHistorialDeResultados = () => {

    const [datosHistorial, setdatosHistorial] = useState([])

    const [cargandoHistorial, setcargandoHistorial] = useState(false)

    /**
     * @param {string} coleccion Colección a la cual se consultará el historial.
     * @param {string} idDocumento Identificación del documento que contiene el historial.
     * @param {string} modulo Modulo al cual pertenece este documento.
     * @param {string} ordenarPor Valor por el cual se organizaran los datos.
     * @param {Date} fechaInicio fecha inicial a la cual se filtrará la data.
     * @param {Date} fechaFin fecha final a la cual se filtrará la data.
     */
    const consultarHistorialDeResultados = ( coleccion, idDocumento, modulo, ordenarPor, fechaInicio, fechaFin ) => {
        try {
            setcargandoHistorial(true)
            firestoreDB.collection(`${coleccion}/${idDocumento}/resultados/${modulo}/historialDeResultados`)
            .orderBy( ordenarPor )
            .startAt( fechaInicio )
            .endBefore( fechaFin )
            .get().then( snapShots => {
                snapShots.forEach( snapShot => {
                    setdatosHistorial( snapShot.data() );
                });
                setcargandoHistorial(false)
            });
        }
        catch(error){console.log(error); setcargandoHistorial( false )}
    }

    return { datosHistorial, cargandoHistorial, consultarHistorialDeResultados }
}
