export const obtenerNodosConSubcolecciones = (
    queryBase,
    normaTecnicaTarget,
    consultarResultados = true,
    consultarLogs = false
) => {
    return new Promise((resolve, reject) => {
        queryBase.get()
            .then(snapshots => {
                let listaDeNodos = [];
                snapshots.forEach(snapshot => {
                    listaDeNodos.push({
                        id: snapshot.id,
                        ...snapshot.data(),
                        referencia: snapshot.ref
                    });
                });

                let listaDePromisesDeObtencionDeDataAdicional = [];
                listaDeNodos.forEach(nodo => {
                    listaDePromisesDeObtencionDeDataAdicional.push(
                        obtenerNodoConSubcoleccion(nodo, normaTecnicaTarget, consultarResultados, consultarLogs)
                    );
                });

                Promise.all(listaDePromisesDeObtencionDeDataAdicional)
                    .then(nodos => {
                        resolve(nodos);
                    })
                    .catch(error => { console.log(error); reject(error); });
            })
            .catch(error => { console.log(error); reject(error); });
    });
}

export const obtenerNodoConSubcoleccion = (
    nodo, normaTecnicaTarget,
    consultarResultados = true,
    consultarLogs = false
) => {
    return new Promise((resolve, reject) => {
        let promisesDeSubcolecciones = [];
        let listaDeSubcoleccionesConsultadas = [];
        if (consultarResultados) {
            promisesDeSubcolecciones.push(nodo.referencia.collection("resultados").doc(normaTecnicaTarget).get());
            listaDeSubcoleccionesConsultadas.push("resultadosDeLaValoracion");
        }
        
        if (consultarLogs) {
            promisesDeSubcolecciones.push(nodo.referencia.collection("logsDeCalculos").doc(normaTecnicaTarget).get());
            listaDeSubcoleccionesConsultadas.push("logsDeCalculos");
        }
        Promise.all(promisesDeSubcolecciones)
        .then(resultadosDeConsultas => {
            let nodoAjustado = { ...nodo };
            try {
                listaDeSubcoleccionesConsultadas.forEach((subcoleccion, i) => {
                        nodoAjustado[subcoleccion] = resultadosDeConsultas[i].data();
                    });
                }
                catch (error) {
                    console.log(error);
                };

                if (!nodoAjustado["resultadosDeLaValoracion"] || JSON.stringify(nodoAjustado["resultadosDeLaValoracion"]) === JSON.stringify({})) {
                    nodoAjustado["resultadosDeLaValoracion"] = {
                        "riesgo": {
                            "nivelDeRiesgo": "L",
                            "riesgoFinanciero": 0,
                            "riesgoCualitativo": 5
                        },
                        "planesDeAccion": {
                            "monitoreo": {},
                            "ingenieria": {},
                            "mantenimiento": {},
                            "informacionAdicional": {},
                            "otros": {},
                            "inspeccion": {}
                        },
                        "consecuencia": {
                            "consecuenciaFinanciera": 0,
                            "consecuenciaFinancieraCualitativa": 5,

                        },
                        "probabilidad": {
                            "probabilidad": 0,
                            "probabilidadCualitativa": 5,
                        }
                    }
                }

                resolve(nodoAjustado);
            })
            .catch(error => { reject(error); });
    });
}