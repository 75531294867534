import React from 'react';

import { Polyline } from 'react-google-maps';

// import obtenerColorSegunEstado from '../../scripts/paraVisualizacionDeRangos'

//Componentes auxiliares:
// import CustomCleanModal from '../plantillaBase/CustomCleanModal';
// import ModalPerfilDeTraza from './ModalPerfilDeTraza';


class PuntoActivoIntegridad extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            elModalEstaAbierto: false,
            // colorTraza: obtenerColorSegunEstado(this.props.ordenDeTrabajo.estado),
            keyDelColor: 0
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.ordenDeTrabajo.estado !== this.props.ordenDeTrabajo.estado) {
        //     this.setState({
        //         colorTraza: obtenerColorSegunEstado(this.props.ordenDeTrabajo.estado),
        //         keyDelColor: this.state.keyDelColor + 1
        //     })
        // }
    }

    render() {
        return (
            !!this.props.activoIntegridad &&
            <React.Fragment>
                <Polyline
                    key={'activoIntegridad'}
                    defaultOptions={{ strokeColor: '#ED0101', strokeWeight: 15 }}
                    onClick={() => {
                        this.toggleDelModal();
                        this.setState({
                            colorTraza: '#ED0101'
                        });
                    }}
                    path={this.props.activoIntegridad}
                />
                {/* <CustomCleanModal
                    elModalEstaAbierto={this.state.elModalEstaAbierto}
                    size="lg"
                    toggleDelModal={this.toggleDelModal}
                    estilos={{ padding: 0 }}
                >
                    <div style={{ padding: 1 }}>
                        {this.crearPerfilTraza(this.props.ordenDeTrabajo.estado)}
                    </div>
                </CustomCleanModal> */}
            </React.Fragment>
        );
    }

    //Métodos operativos: 

    // crearPerfilTraza = (estado) => {
    //     let colorDeFondo = '';
    //     let estadoFormateado = '';
    //     switch (estado) {
    //         case 'PLANEADA':
    //             estadoFormateado = 'PLANEADA';
    //             colorDeFondo = obtenerColorSegunEstado('PLANEADA');
    //             break
    //         case 'PROGRAMADA':
    //             estadoFormateado = 'PROGRAMADA';
    //             colorDeFondo = obtenerColorSegunEstado('PROGRAMADA');
    //             break
    //         case 'EN_SITIO':
    //             estadoFormateado = 'EN SITIO'
    //             colorDeFondo = obtenerColorSegunEstado('EN_SITIO');
    //             break
    //         case 'INSPECCIONADA':
    //             estadoFormateado = 'INSPECCIONADA'
    //             colorDeFondo = obtenerColorSegunEstado('INSPECCIONADA');
    //             break
    //         case 'EJECUTADA':
    //             estadoFormateado = 'EJECUTADA'
    //             colorDeFondo = obtenerColorSegunEstado('EJECUTADA');
    //             break
    //         case 'REVISADA':
    //             estadoFormateado = 'REVISADA'
    //             colorDeFondo = obtenerColorSegunEstado('REVISADA');
    //             break
    //         case 'FINALIZADA':
    //             estadoFormateado = 'FINALIZADA'
    //             colorDeFondo = obtenerColorSegunEstado('FINALIZADA');
    //             break
    //         case 'FACTURADA':
    //             estadoFormateado = 'FACTURADA'
    //             colorDeFondo = obtenerColorSegunEstado('FACTURADA');
    //             break
    //         default:
    //             estadoFormateado = 'NO DEFINIDO'
    //             colorDeFondo = obtenerColorSegunEstado();
    //     }
    //     return (
    //         <ModalPerfilDeTraza
    //             colorDeFondo={colorDeFondo}
    //             estadoFormateado={estadoFormateado}
    //             ordenDeTrabajo={this.props.ordenDeTrabajo}
    //         />
    //     )
    // }

    toggleDelModal = () => {
        this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto });
    }
}

export default PuntoActivoIntegridad;