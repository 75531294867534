import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { Col, Row, Input } from 'reactstrap';

// material select
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import confirmacionDeActualizacionDeTabla from '../../almacen/alertasParaSwal';

class EditorDeVariables extends Component {
    constructor() {
        super();
        this.state = {
            error: '',
            estadoValidacion: true
        }
        this.valoresAsumidosComoNulos = [""," ","-"]
    }

    componentDidMount() {
        this.setState({ variable: this.props.variable, validacion: this.props.validacion })
    }

    render() {
        return (
            <div>
                {this.obtenerInputParaVariable()}
            </div>
        )
    }



    obtenerInputParaVariable = () => {
        switch (this.props.variable['categoria']) {
            case 'valorDeSeleccionMultiple':
                const opciones = this.props.validacion['options'];
                return (
                    <Col className='pl-0 pr-0'>
                        <Select
                            autoFocus
                            defaultValue={this.props.valor}
                            onBlur={this.props.onBlur}
                            onChange={e => {
                                let valorDelEvento = e.target.value
                                Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valorMensaje, valorDelEvento))
                                    .then(result => {
                                        if (result.value) {
                                            setTimeout(() => { this.props.actualizarCelda(valorDelEvento); }, 50);
                                        }
                                    })
                            }}
                            style={{ width: '100%', maxHeight: '45px', backgroundColor: '#ffffff' }}
                            variant="outlined"
                        >
                            {this.obtenerListadoDeOpciones(this.props.variable.key, opciones, this.props.tipoActivo)}
                        </Select>
                        <span style={{ color: "red" }}>{this.state.error}</span>
                    </Col>
                );
            case "valorAbierto":
                return (
                    <Col>
                        <Row>
                            <Input
                                autoFocus type="text"
                                onChange={event => this.validarEntrada(event.target.value)}
                                onBlur={this.props.onBlur}
                                defaultValue={this.props.valor}
                                onKeyUp={e => {
                                    if (this.state.estadoValidacion) {
                                        if (e.keyCode === 13) {
                                            let valorDelEvento = !!this.props.vacioComoNulo ? this.valorPermitiendoNulos(e.target.value):e.target.value
                                            Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valorMensaje, valorDelEvento))
                                                .then(result => {
                                                    if (result.value) {
                                                        setTimeout(() => { this.props.actualizarCelda(valorDelEvento); }, 50);
                                                    }
                                                })
                                        }
                                    } else {
                                        if (e.keyCode === 13) {
                                            alert(`Los valores ingresados no son válidos`)
                                        }
                                    }
                                }}
                                style={{ width: '100%', boxShadow: 'none' }}
                            >
                            </Input>
                        </Row>
                        <span style={{ color: "red" }}>{this.state.error}</span>
                    </Col>
                );
            case "valorÚnico":
                if (this.props.variable["tipo"] === 'fecha') {
                    return (
                        <Col>
                            <Row>
                                <Input
                                    autoFocus
                                    type="date"
                                    onChange={e => {
                                        this.validarEntrada(e.target.value)
                                        if (this.state.estadoValidacion) {
                                            let valorDelEvento = !!this.props.vacioComoNulo ? this.valorPermitiendoNulos(e.target.value):e.target.value
                                            Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valorMensaje, valorDelEvento))
                                                .then(result => {
                                                    if (result.value) {
                                                        setTimeout(() => { this.props.actualizarCelda(valorDelEvento); }, 50);
                                                    }
                                                })
                                        } else {
                                            alert(`Los valores ingresados no son válidos`)
                                        }
                                    }}
                                    defaultValue={this.props.valor}
                                    onBlur={this.props.onBlur}
                                    style={{ width: '100%', boxShadow: 'none' }}
                                ></Input>
                            </Row>
                            <span style={{ color: "red" }}>{this.state.error}</span>
                        </Col>
                    );
                } else {
                    return (
                        <Col style={{ paddingBottom: 10 }}>
                            <span style={{ color: "red", fontSize: 12 }}>{this.state.error}</span>
                            <Row>
                                <Input
                                    autoFocus
                                    type="text"
                                    onChange={event => this.validarEntrada(event.target.value)}
                                    defaultValue={(this.props.variable.funcionDeTransformacion === 'paraPorcentajes') ? this.props.valor * 100 : this.props.valor}
                                    onKeyUp={e => {
                                        if (this.state.estadoValidacion) {
                                            if (this.props.variable.funcionDeTransformacion !== undefined) {
                                                if (this.props.variable.funcionDeTransformacion === 'paraPorcentajes') {
                                                    if (e.keyCode === 13) {
                                                        let valorDelEvento = e.target.value
                                                        let valor = undefined
                                                        valorDelEvento.match(',') ? valor = valorDelEvento.replace(/,/g, '.') : valor = valorDelEvento
                                                        Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valor * 100, valor))
                                                            .then(result => {
                                                                if (result.value) {
                                                                    setTimeout(() => {
                                                                        this.props.actualizarCelda(valor / 100);
                                                                    }, 50);
                                                                }
                                                            })
                                                    }
                                                }
                                            } else {
                                                if (e.keyCode === 13) {
                                                    let valorDelEvento = e.target.value
                                                    Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valorMensaje, valorDelEvento))
                                                        .then(result => {
                                                            if (result.value) {
                                                                setTimeout(() => { this.props.actualizarCelda(valorDelEvento); }, 50);
                                                            }
                                                        })
                                                }
                                            }
                                        } else {
                                            if (e.keyCode === 13) {
                                                alert(`Los valores ingresados no son válidos`)
                                            }
                                        }
                                    }}
                                    onBlur={this.props.onBlur}
                                    style={{ width: '100%', boxShadow: 'none' }}

                                >
                                </Input>
                            </Row>
                        </Col>
                    );
                }
            case "valorConstante":
                return (
                    <Col style={{ paddingBottom: 10 }}>
                        <span style={{ color: "red", fontSize: 12 }}>{this.state.error}</span>
                        <Row>
                            <Input
                                autoFocus
                                type="text"
                                disabled={true}
                                onChange={event => this.validarEntrada(event.target.value)}
                                defaultValue={(this.props.variable.funcionDeTransformacion === 'paraPorcentajes') ? this.props.valor * 100 : this.props.valor}
                                onKeyUp={e => {
                                    if (this.state.estadoValidacion) {
                                        if (this.props.variable.funcionDeTransformacion !== undefined) {
                                            if (this.props.variable.funcionDeTransformacion === 'paraPorcentajes') {
                                                if (e.keyCode === 13) {
                                                    let valorDelEvento = e.target.value
                                                    let valor = undefined
                                                    valorDelEvento.match(',') ? valor = valorDelEvento.replace(/,/g, '.') : valor = valorDelEvento
                                                    Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valor * 100, valor))
                                                        .then(result => {
                                                            if (result.value) {
                                                                setTimeout(() => {
                                                                    this.props.actualizarCelda(valor / 100);
                                                                }, 50);
                                                            }
                                                        })
                                                }
                                            }
                                        } else {
                                            if (e.keyCode === 13) {
                                                let valorDelEvento = e.target.value
                                                Swal.fire(confirmacionDeActualizacionDeTabla(this.props.valorMensaje, valorDelEvento))
                                                    .then(result => {
                                                        if (result.value) {
                                                            setTimeout(() => { this.props.actualizarCelda(valorDelEvento); }, 50);
                                                        }
                                                    })
                                            }
                                        }
                                    } else {
                                        if (e.keyCode === 13) {
                                            alert(`Los valores ingresados no son válidos`)
                                        }
                                    }
                                }}
                                onBlur={this.props.onBlur}
                                style={{ width: '100%', boxShadow: 'none' }}

                            >
                            </Input>
                        </Row>
                    </Col>
                );
            default:
                return (<div></div>);
        }
    }

    validarEntrada = (value) => {
        let funcionValidacion = this.props.validacion["validation"];
        if (funcionValidacion != null) {
            const respuesta = funcionValidacion(this.props.variable, value);
            if (!respuesta) {
                this.setState({
                    error: "El valor no es válido!",
                    estadoValidacion: false
                })
            } else {
                this.setState({
                    error: "",
                    estadoValidacion: true
                })
            }
        }
    }

    obtenerListadoDeOpciones(key, opciones, tipoActivo) {
        let listadoOpciones = opciones.map(opcion => {
            if (key === 'fluidoRepresentativo')
            {
                if (opcion.filter===tipoActivo){
                    return (
                        <MenuItem key={key + opcion.value} value={opcion.value}>{opcion.value}</MenuItem>
                    );}
            }
            else return <MenuItem key={key + opcion} value={opcion}>{opcion}</MenuItem>
        });
        return listadoOpciones;
    }
    valorPermitiendoNulos(valor){ //funcion que retorna el valor a modificar o nulo dependiendo los valores que se asumen como nulos
        return this.valoresAsumidosComoNulos.includes(valor)?null:valor
    }

}


const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(EditorDeVariables);

