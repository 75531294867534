import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

let CustomModal = (props) => {
    return (
        <Modal isOpen={props.elModalEstaAbierto} toggle={props.toggleDelModal}
            size={props.size || "lg"} style={props.style} scrollable={true}>

            <ModalHeader toggle={props.toggleDelModal} tag="h6">
                {props.titulo}
            </ModalHeader>

            <ModalBody style={{ paddingTop: 0, paddingBottom: 0, ...props.estilos }}>
                {props.children}
            </ModalBody>
            {renderFooter(props)}
           
        </Modal>
    );
}

const renderFooter = (props) =>{
    if(!props.hideFooter){
        return (
            <ModalFooter>
                {props.botonesParaElFooter}
                <Button color="secondary" onClick={props.toggleDelModal}>Cerrar</Button>
            </ModalFooter>
        );
    }else{
        return(<></>)
    }
}

export default CustomModal;