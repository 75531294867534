
export default [
    {
      'Tipo de activo': 'Compressor',
      'Tipo de componente': 'COMPC',
    },
    {
      'Tipo de activo': 'Compressor',
      'Tipo de componente': 'COMPR',
    },
    {
      'Tipo de activo': 'Heat exchanger',
      'Tipo de componente': 'HEXSS',
    },
    {
      'Tipo de activo': 'Heat exchanger',
      'Tipo de componente': 'HEXTS',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-1',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-2',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-4',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-6',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-8',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-10',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-12',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPE-16',
    },
    {
      'Tipo de activo': 'Pipe',
      'Tipo de componente': 'PIPEGT16',
    },
    {
      'Tipo de activo': 'Pump',
      'Tipo de componente': 'PUMP2S',
    },
    {
      'Tipo de activo': 'Pump',
      'Tipo de componente': 'PUMPR',
    },
    {
      'Tipo de activo': 'Pump',
      'Tipo de componente': 'PUMP1S',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'TANKBOTTOM',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-1',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-2',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-3',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-4',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-5',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-6',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-7',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-8',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-9',
    },
    {
      'Tipo de activo': 'Tank650',
      'Tipo de componente': 'COURSE-10',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'KOLDRUM',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'COLBTM',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'FINFAN',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'FILTER',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'DRUM',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'REACTOR',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'COLTOP',
    },
    {
      'Tipo de activo': 'Vessel/FinFan',
      'Tipo de componente': 'COLMID',
    },
  ];
  



/* [
    ['Compressor', 'COMPC'],
    ['Compressor', 'COMPR'],
    ['Heat exchanger', 'HEXSS'],
    ['Heat exchanger', 'HEXTS'],
    ['Pipe', 'PIPE-1'],
    ['Pipe', 'PIPE-2'],
    ['Pipe', 'PIPE-4'],
    ['Pipe', 'PIPE-6'],
    ['Pipe', 'PIPE-8'],
    ['Pipe', 'PIPE-10'],
    ['Pipe', 'PIPE-12'],
    ['Pipe', 'PIPE-16'],
    ['Pipe', 'PIPEGT-16'],
    ['Pump', 'PUMP2S'],
    ['Pump', 'PUMPR'],
    ['Pump', 'PUMP1S'],
    ['Tank650', 'TANKBOTTOM'],
    ['Tank650', 'COURSE-1'],
    ['Tank650', 'COURSE-2'],
    ['Tank650', 'COURSE-3'],
    ['Tank650', 'COURSE-4'],
    ['Tank650', 'COURSE-5'],
    ['Tank650', 'COURSE-6'],
    ['Tank650', 'COURSE-7'],
    ['Tank650', 'COURSE-8'],
    ['Tank650', 'COURSE-9'],
    ['Tank650', 'COURSE-10'],
    ['Vessel/FinFan', 'KOLDRUM'],
    ['Vessel/FinFan', 'COLBTM'],
    ['Vessel/FinFan', 'FINFAN'],
    ['Vessel/FinFan', 'FILTER'],
    ['Vessel/FinFan', 'DRUM'],
    ['Vessel/FinFan', 'REACTOR'],
    ['Vessel/FinFan', 'COLTOP'],
    ['Vessel/FinFan', 'COLMID'],
]; */