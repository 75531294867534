import React, { Component } from 'react'
import { ResponsiveContainer, Legend, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts'
// import CrearTablaDinamicamente from './CrearTablaDinamicamente';
import CardConEncabezado from '../plantillaBase/CardConEncabezado';
import { FaChartBar, FaChartPie, FaListUl } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';
import { Select } from '@material-ui/core';    // MenuItem, 
// scripts:
import obtenerListadoDeOpcionesParaSelect from '../../scripts/obtenerListadoDeOpcionesParaSelect';
import organizarDataGraficaDeBarras from '../../scripts/organizarDataGraficaDeBarras';
import organizarDataGraficaDeTorta from '../../scripts/organizarDataGraficaDeTorta';
import { crearColumnasParaTabla, crearListaDatosParaTabla } from '../../../vistas/aplicacion/integridad/API570/scripts/crearTablaDinamicamente';
import { formatearvalorsegunestado } from '../../../vistas/aplicacion/integridad/scripts/formatearValorSegunEstado';
// componente:
import GraficoDeTortaIntegridad from './GraficoDeTortaIntegridad';
import CustomTableDinamica from './CustomTableDinamica';

class TablaConGraficoDeBarras extends Component {
    constructor() {
        super();
        this.state = {
            activeIndex: 0,
            data: [],
            copiaDataOriginal: [],
            botonFiltro: 'Todo',
            datosParaGraficaBarras: undefined,
            listaDeSegmentos: undefined,
            contador: 0,
            graficaRenderizada: 'Barras',
            datosParaGraficaTorta: undefined,
        }
    }

    componentDidMount() {
        this.setState({
            data: this.props.parametrizacion,
            copiaDataOriginal: this.props.parametrizacion,
        });
        this.organizarDataParaTablaSegmentacion();
        // this.organizarDataParaGraficoBarras();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data:
                    this.props.parametrizacion
            });
            // this.organizarDataParaGraficoBarras();
        }
        if(prevState.botonFiltro !== this.state.botonFiltro){
            this.organizarDataParaTablaSegmentacion();
            this.rederizarGraficaSegunSelect();
        }
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    renderColorfulLegendText = (value) => {
        return <span style={{ fontWeight: '100' }}>{value}</span>;
    }

    uppeCasetoLowerCase = (texto) => {
        let textSize = texto.length
        let nuevoTexto = texto[0]
        for (let n = 1; n < textSize; n++) {
            nuevoTexto = nuevoTexto.concat(texto[n].toLowerCase())
        }
        return nuevoTexto
    }

    organizarDataParaTablaSegmentacion = () => {
        const listaDeSegmentos = [];
        if(this.state.botonFiltro === 'Todo'){
            this.props.parametrizacion.forEach((item, i) => {
                listaDeSegmentos.push({
                    'Item': { 
                        label: 'Item', 
                        valor: i, 
                        minWidth: 40, 
                        condicion: 'hover'
                    },
                    'Segmento': {
                        label: 'Segmento', 
                        valor: item.nombreSegmento, 
                        minWidth: 110, 
                        condicion: 'link', 
                        ruta: '/app/analisis/detalle/lineas/segmento' 
                    },
                    'PK': { 
                        label: 'PK Inicial-Final', 
                        valor: item.pk, 
                        minWidth: 80
                    },
                    'Condición del segmento': { 
                        label: 'Condición del segmento', 
                        valor: item.etiqueta, 
                        minWidth: 120
                    },
                    'Longitud del segmento(m)': { 
                        label: 'Longitud del segmento (m)', 
                        valor: formatearvalorsegunestado(item.long, 1), 
                        minWidth: 60
                    },
                    'Porcentaje': {
                        label: 'Porcentaje (%)', 
                        valor: formatearvalorsegunestado(item.valor, 1), 
                        minWidth: 60
                    },
                    // 'Piernas muertas': {label: 'Piernas muertas', valor: `${item.piernasMuertas}`, minWidth: 60},
                    // 'Rango Cmls': {label: 'Rango Cmls', valor: `${item.rangoCmls}`, minWidth: 60},
                });
            })
            this.setState({data: this.state.copiaDataOriginal})
        }else{
            const dataFiltrada = this.props.parametrizacion.filter(item => item.etiqueta === this.state.botonFiltro)
            dataFiltrada.forEach((item, i) => {
                listaDeSegmentos.push({
                    'Item': { 
                        label: 'Item', 
                        valor: i, 
                        minWidth: 40, 
                        condicion: 'hover'
                    },
                    'Segmento': {
                        label: 'Segmento',
                        valor: item.nombreSegmento, 
                        maxWidth: 110, 
                        condicion: 'link', 
                        ruta: '/app/analisis/detalle/lineas/segmento' 
                    },
                    'PK': { 
                        label: 'PK Inicial-Final', 
                        valor: item.pk, 
                        minWidth: 80
                    },
                    'Condición del segmento': { 
                        label: 'Condición del segmento', 
                        valor: item.etiqueta, 
                        minWidth: 120
                    },
                    'Longitud del segmento(m)': { 
                        label: 'Longitud del segmento (m)', 
                        valor: formatearvalorsegunestado(item.long, 1), 
                        minWidth: 60
                    },
                    'Porcentaje': {
                        label: 'Porcentaje (%)', 
                        valor: formatearvalorsegunestado(item.valor, 1),
                        minWidth: 60
                    },
                    // 'Piernas muertas': {label: 'Piernas muertas (un)', valor: `${item.piernasMuertas}`, minWidth: 60},
                    // 'Rango Cmls': {label: 'Rango Cmls', valor: `${item.rangoCmls}`, minWidth: 60},
                });
            })
            this.setState({data: dataFiltrada})
        }
       this.setState({listaDeSegmentos: listaDeSegmentos})
    }

    /**
     * Método para crear los valores de una tabla leyenda de tener muchos valores o nombres muy largos
     */
    crearTablaLeyendaGraficas = () => {
        const items = [
            {
            'alias': { label: 'Alias', valor: 'A', minWidth: 10,},
            'valor': { label: 'Valor', valor: 'Aérea', minWidth: 45},
            },
            {
            'alias': { label: 'Alias', valor: 'B', minWidth: 10,},
            'valor': { label: 'Valor', valor: 'Enterrada', minWidth: 45},
            },
            {
            'alias': { label: 'Alias', valor: 'C', minWidth: 10,},
            'valor': { label: 'Valor', valor: 'Otra', minWidth: 45},
            },
        ];
        return items
    }

    agruparPorcentajes = (condicionSegmento) => {
        const agrupador = [0];
        let porcentajeSumado = undefined;
        if(condicionSegmento !== []){
            const reducer = (acumulador, valorActual) => acumulador + valorActual;
            condicionSegmento.forEach(item => {
                agrupador.push(item.valor);
            })
            porcentajeSumado = agrupador.reduce(reducer)
        }else {
            porcentajeSumado = [0]
        }
        return porcentajeSumado
    }

    // organizarDataParaGraficoBarras = (tipoGrafica) => {
    //     const datosParaGrafica = [];
    //     let data = this.state.contador === 0? this.props.parametrizacion : this.state.data;
        
    //     try{
    //         organizarDataGraficaDeBarras(data)
    //         const aerea = data.filter((item) => item.etiqueta === 'Aérea');
    //         const porcentajeTotalAerea = this.agruparPorcentajes(aerea);

    //         const enterrada = data.filter((item) => item.etiqueta === 'Enterrada');
    //         const porcentajeEnterrada =  this.agruparPorcentajes(enterrada);

    //         const libre = data.filter((item) => item.etiqueta === 'Libre');
    //         const porcentajeLibre = this.agruparPorcentajes(libre);

    //         if(tipoGrafica === 'barras'){
    //             datosParaGrafica.push(
    //                 {name: 'Aérea', alias: 'Aérea', cantidad: aerea.length || 0, porcentaje: porcentajeTotalAerea || 0},
    //                 {name: 'Enterrada', alias: 'Enterrada', cantidad: enterrada.length || 0, porcentaje:porcentajeEnterrada || 0},
    //                 {name: 'Libre', alias: 'Libre', cantidad: libre.length || 0, porcentaje:porcentajeLibre || 0},
    //             );
    //             return datosParaGrafica;
    //         }else if(tipoGrafica === 'torta'){
    //             datosParaGrafica.push(
    //                 {color: "#1E96FC", etiqueta: "Aérea", valor: porcentajeTotalAerea || 0},
    //                 {color: "#f5cc00", etiqueta: "Enterrada", valor: porcentajeEnterrada || 0},
    //                 {color: "#6D15D0", etiqueta: "Libre", valor: porcentajeLibre || 0},
    //                 )
    //             return datosParaGrafica;
    //         }
    //     }catch(error){ console.log(error)}
    // }

    cambiarEstadoBotonFiltro = (e, tipo) => {
        if(tipo === 'filtro'){
            this.setState({botonFiltro: e.target.value});
            this.setState({contador: 1});
            this.organizarDataParaTablaSegmentacion();
        } else if(tipo === 'tipoGrafica'){
            this.setState({graficaRenderizada: e.target.value});
        }
    }

    generarBoton = (tipoDeBoton) => {
        switch(tipoDeBoton){
            case 'filtro':
                let listaDeCondiciones = ['Todo','Aérea', 'Enterrada', 'Otra']
                return(
                    <Select
                    key={'boton de filtro'}
                    name={'Botón filtro'}
                    value={this.state.botonFiltro}
                    onChange={(e) => {this.cambiarEstadoBotonFiltro(e, 'filtro')}}
                    className={'select-filtro-tabla'}
                    variant="outlined"
                    >
                        {obtenerListadoDeOpcionesParaSelect(listaDeCondiciones)}
                    </Select>
                )
            case 'tipoGrafica':
                let listaDeGraficas = ['Barras', 'Torta']
                return(
                    <Select
                    key={'boton de tipoGrafica'}
                    name={'Botón tipoGrafica'}
                    value={this.state.graficaRenderizada}
                    onChange={(e) => {this.cambiarEstadoBotonFiltro(e, 'tipoGrafica')}}
                    className={'select-filtro-tabla'}                    
                    variant="outlined"
                    >
                        {obtenerListadoDeOpcionesParaSelect(listaDeGraficas)}
                    </Select>
                )
            default:
                return(<React.Fragment></React.Fragment>)
        }
        
    }

    
    
    rederizarGraficaSegunSelect = () => {
        let data = this.state.contador === 0? this.props.parametrizacion : this.state.data;
        if(this.state.graficaRenderizada === 'Barras'){
            return(
                <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                    <BarChart data={organizarDataGraficaDeBarras(data)} barGap={4}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="alias" />
                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                        <YAxis yAxisId="right" orientation="right" stroke="#333"/>
                        <Legend />
                        <Tooltip cursor={{ stroke: '#333', strokeWidth: 1 }} />
                        <Bar yAxisId="left" name="Cantidad" dataKey="cantidad" fill="#0779e4" />
                        <Bar yAxisId="right" name="Porcentaje (%)" dataKey="porcentaje" fill="#333" />
                    </BarChart>
                </ResponsiveContainer>
            ) 
        }else if(this.state.graficaRenderizada === 'Torta'){
            return (
                <GraficoDeTortaIntegridad parametrizacion={organizarDataGraficaDeTorta(data)}/>
            )
        }        
    }

    generarLeyendaTablaBarras = () => {
        if(this.state.graficaRenderizada === 'Barras'){
            return (
                <CustomTableDinamica
                    keyDeTabla={'leyenda de barras'}
                    listaDeDatos={crearListaDatosParaTabla(this.crearTablaLeyendaGraficas())}
                    criterioDeOrdenamiento={{ id: "numero", desc: false }}
                    columnas={crearColumnasParaTabla(this.crearTablaLeyendaGraficas())}
                />
            )
        }
    }


    render() {
        if(this.state.listaDeSegmentos !==  undefined){
            let data = this.state.contador === 0? this.props.parametrizacion : this.state.data;
            return (
                <React.Fragment>
                    <Row>
                        <Col sm='12' md='12' lg='12' xl='12' className='responsive-table-grafica'>
                            <CardConEncabezado
                                    titulo={`Resultados de inspección - Segmentos del activo: ${this.props.titulo} `}
                                    icono={FaListUl}
                                    botonesAuxiliares={[this.generarBoton('filtro')]}
                                    >
                                    
                                    {/* <CrearTablaDinamicamente
                                        key={'lista de segmentos' }
                                        datosDeTabla={}
                                    /> */}
                                     <CustomTableDinamica
                                        keyDeTabla={'lista de segmentos'}
                                        listaDeDatos={crearListaDatosParaTabla(this.state.listaDeSegmentos)}
                                        criterioDeOrdenamiento={{ id: "numero", desc: false }}
                                        columnas={crearColumnasParaTabla(this.state.listaDeSegmentos)}
                                    />
                                {/* {this.organizarDataParaTablaSegmentacion()} */}
                            </CardConEncabezado>
                        </Col>
                        <Col sm='12' md='12' lg='6' xl='6' className='responsive-table-grafica'>
                            <CardConEncabezado
                                titulo={`Agrupamiento por condición de segmento (Tabla valores medición)`}
                                icono={ FaChartBar }
                                // botonesAuxiliares={[this.generarBoton('tipoGrafica')]}
                                >
                                  {this.rederizarGraficaSegunSelect()}
                                {/* <Row>
                                    <Col md='12' lg='12'>
                                    <GraficoDeTortaIntegridad parametrizacion={organizarDataGraficaDeTorta(data)}/>
                                        {this.generarTablaLeyendaBarras()}
                                    </Col>
                                </Row> */}
                            </CardConEncabezado>
                        </Col>
                        <Col sm='12' md='12' lg='6' xl='6' className='responsive-table-grafica'>
                            <CardConEncabezado
                                titulo={`Agrupamiento por condición de segmento (Tabla Resultados de inspección)`}
                                icono={ FaChartPie }
                                // botonesAuxiliares={[this.generarBoton('tipoGrafica')]}
                                >
                                <GraficoDeTortaIntegridad parametrizacion={organizarDataGraficaDeTorta(data)}/>
                                {/* <Row>
                                  {this.rederizarGraficaSegunSelect()}
                                    <Col md='12' lg='12'>
                                        {this.generarTablaLeyendaBarras()}
                                    </Col>
                                </Row> */}
                            </CardConEncabezado>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }
}



export default TablaConGraficoDeBarras;