import React from 'react';
import { connect } from 'react-redux';

//Componentes auxiliares:
import ResumenDeModulo from '../../../../../utilidades/componentes/resumenDeModulo/ResumenDeModulo';

//Scripts auxiliares:
import { categorias } from './estaticos/opcionesDeFiltros';
import { opcionesParaGraficas } from './estaticos/opcionesParaGraficas';

//Funciones para manipular state redux:
import { actualizarDataApi580, actualizarModuloActivo } from '../../../../../storage/reducerParaData';
import Error404 from "../../../../404";

const activosDePrueba = require('./estaticos/ejemploDeActivos.json');
const segmentosDePrueba = require('./estaticos/ejemploDeSegmentos.json');

const itemsColumnaIzquierda = {
    1: {
        activo: true,
        tipo: "MATRIZ_DE_RIESGO",
        props: {
            criterioDeOrdenamiento: {
                id: "riesgo", desc: true
            }
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[0]
        }
    },
    3: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[1]
        }
    },
    4: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[2]
        }
    }
};

const itemsColumnaDerecha = {
    1: {
        activo: true,
        tipo: "TABLA_DE_NODOS",
        props: {
            criterioDeOrdenamiento: {
                id: "riesgo", desc: true
            }
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[1]
        }
    },
    3: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[6]
        }
    },
    4: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[3]
        }
    },
};

class VisualizadorApi580 extends React.Component {

    constructor() {
        super();

        this.state = {
            error: false,
        };
    }

    render() {
        if(!this.state.error) {
            return (
                <React.Fragment>
                    <ResumenDeModulo
                        categoriasDeFiltrado={categorias}
                        itemsColumnaDerecha={itemsColumnaDerecha}
                        itemsColumnaIzquierda={itemsColumnaIzquierda}
                        metodoParaActualizarDataEnRedux={this.props.actualizarDataApi580}
                        moduloTarget="API581"
                        nodosEnRedux={this.props.dataDe580EnRedux}
                        nodosParaDesarrollo={{ "todosLosSegmentos": segmentosDePrueba, "todosLosActivos": activosDePrueba }}
                        opcionesParaGraficas={opcionesParaGraficas}
                        permitirConsultas={true}
                        titulo="API 581"
                        subtitulo="Risk based inspection"
                    />
                </React.Fragment>
            );}
            else {
                return (
                    <Error404
                        error={this.props.location.pathname} />
                );
            }
    }

    componentDidMount(){
        this.props.actualizarModuloActivo('API581')
    }
    
    static getDerivedStateFromError(error) {
        this.setState({ error: true })
    }
}

const mapStateToProps = state => ({
    dataDe580EnRedux: state.reducerParaData["/app/analisis/riesgo/api580"],
    
});

const mapDispatchToProps = dispatch => ({
    actualizarDataApi580: nuevaData => { dispatch(actualizarDataApi580(nuevaData)); },
    actualizarModuloActivo: nuevoModulo => { dispatch(actualizarModuloActivo(nuevoModulo)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizadorApi580);