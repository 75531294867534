export const posicionarVidaRemanenteEnIntervalo = ( valor ) => {
    let posicionIntervalo;

    if ( valor < 5 ) {
        posicionIntervalo = 0.175;
    } else if ( valor < 20 ) {
        posicionIntervalo = 0.495;
    } else if ( valor >= 20 ) {
        posicionIntervalo = 0.831;
    }

    return posicionIntervalo
}
