import React from 'react';
import { connect } from 'react-redux';
import { Col, Input, Label, Row } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

//componentes:
import { obtenerValidacionDeVariable } from '../../scripts/paraValidarVariablesAgregarUsuarios';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';

//glosario de inputs:
import { glosarioDeInputsAgregarEmpresa, glosarioDeInputsParametrizaciones } from '../../scripts/glosarioDeInputsAgregarEmpresa';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//Utilidades Gestión Usuarios
import { servicioCrearEmpresa, servicioCrearParametrizacionEmpresa } from '../../services/paraGestionDeEmpresas';

require('firebase/firestore');

const estiloLabel = 'mb-0 mt-2'

class ContenidoModalAgregarEmpresa extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            nombre: '',
            dominio: '',
            usuariosPermitidos: '',
            parametros:{},
            cargando: false,

            mensajeNombre: false,
            mensajeDominio: false,
            mensajeUsuariosPermitidos: false,

            estadoValidacion: {},
        }
        this.cargarImagen = React.createRef(); 
    }

    componentDidMount(){
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        const estadoInicialCheckBoxes = this.estadoInicialParametros();
        this.setState({
            estadoValidacion: estadoDeValidacionInicial,
            parametros: estadoInicialCheckBoxes
        });
    }

    render(){
        return(
            <React.Fragment>
                <form className='my-3 offset-lg-1' onSubmit={this.submitDelFormulario}>
                    <Row className='mx-auto' xs='12' lg='10'>
                        <Col className="px-0" lg='10'>
                            {this.generarInputsDelModal(glosarioDeInputsAgregarEmpresa)}
                        </Col>
                    </Row>
                    <Row xs='12' lg='10'>
                        <Col className="px-0" lg='12'>
                            <Label key={'labelTituloParametrizaciones'}>Parametrizaciones</Label>
                        </Col>
                    </Row>
                    <Row xs='12' lg='10'>
                        <Col className="px-0" lg='4'>
                            <Label key={'labelAPI581'}>API 581</Label>
                            {this.generarCheckBoxParametrizaciones('API581')}
                        </Col>
                        <Col className="px-0" lg='4'>
                            <Label key={'labelAPI1160'}>API 1160</Label>
                            {this.generarCheckBoxParametrizaciones('API1160')}
                        </Col>
                        <Col className="px-0" lg='4'>
                            <Label key={'labelAPI318S'}>API 31.8S</Label>
                            {this.generarCheckBoxParametrizaciones('API318S')}
                        </Col>
                    </Row>
                    <Row className='mt-4' xs='12' lg='10'>
                        <Col lg='11' xs='12'>
                            <Input 
                                key='inputSubmit'
                                type='submit' 
                                value="Crear" 
                                style={{ textAlign: 'center', color: '#ffffff' }} 
                                className={'bg-royal pusheable-button mb-3'} 
                            />
                        </Col>
                    </Row>
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    //Funciones de renderizado
    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    estadoInicialParametros = () => {
        let tempData = {}
        glosarioDeInputsParametrizaciones.map((parametro, index)=>{
            if (parametro.key==='menuPrincipal') tempData[parametro.key]=true
            else tempData[parametro.key]=false
        })
        return tempData
    }

    generarInputsDelModal = (parametrizacion) => {
        return Object.keys(parametrizacion).map(input => this.generarInputSegunGlosario(parametrizacion[input]));
    }

    generarInputSegunGlosario = (input) => {
        switch(input.type){
            case 'text':
                return (
                    <Col
                        key={`input:${input.key}:`} 
                        xs={input['col-sm']} 
                        md={input['col-md']}
                        lg={input['col-lg']}
                        xl={input['col-xl']}
                    >
                        <Label key={`label_${input.key}`}  for={input.type} className={estiloLabel}>{input.label}</Label>
                        <Input
                            key={`input_${input.key}`}
                            type={input.type}
                            name={input.key}
                            value={this.state[input.key]}
                            onChange={(e) => this.cambiosEnInput(e, input)}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state[input.mensaje] ? 'El valor del campo es invalido' : '')}</small>
                    </Col>
                )
            
            default: 
                return(null)
        }
    }

    generarCheckBoxParametrizaciones = (padre) => {
        return (
            <PerfectScrollbar key={`scroll_${padre}`} style={{ height:80, width:'100%', justifyContent:'flex-start', alignContent:'center', display:'flex', flexDirection:'column'}}>
            <div key={`div_${padre}`} className='parameters-checkbox-list-container'>
                {glosarioDeInputsParametrizaciones.map((parametro, index)=>{
                    if (padre===parametro.padre){
                        return (
                            <Row key={`row_${parametro.key}`} className='parameters-checkbox-list' lg='12' xs='12'>
                                <Label check key={`label_${parametro.key}`}>
                                    <Input key={`input_${parametro.key}`} type="checkbox" onChange={() => this.handleCheckBox(parametro.key)}/>{' '}
                                        {parametro.label}
                                </Label>
                            </Row>
                        )
                    } else return (null)
                })}
            </div>
            </PerfectScrollbar>
        )
    }

    handleCheckBox = (name) => {
        let tempData = this.state.parametros
        tempData[name] = !this.state.parametros[name]
        this.setState({ parametros:tempData })
    }
    //Funciones Operativas
    cambiosEnInput = (event, input, type) => {
        const value =  event.target.value
        const name = event.target.name;
        let funcionValidacion = obtenerValidacionDeVariable(input.key)
        let validacion = funcionValidacion['validation']
        
        const estadoValidacionActual = this.state.estadoValidacion;

        if (validacion) {
            const respuesta = validacion(input.key, value);
            if (!respuesta) {
                estadoValidacionActual[input.key] = false;
                this.setState({
                    [name]: value,
                    error: "El valor no es válido!",
                    estadoValidacion: estadoValidacionActual,
                    [input.mensaje]: true
                })
                return;
            }
        }
        estadoValidacionActual[input.key] = true;
        this.setState({
            [name]: value,
            error: "",
            estadoValidacion: estadoValidacionActual,
            [input.mensaje]: false
        })
    }

    generarEstadoDeValidacioninicial = () =>{
        let estadoDeValidacionInicial = {};
        Object.keys(glosarioDeInputsAgregarEmpresa).forEach(variable =>{
            estadoDeValidacionInicial[variable] = false;
        })
        return estadoDeValidacionInicial;
    }

    
    obtenerEstadoDeFormulario = () =>{
        const estadoDeValidacion = this.state.estadoValidacion
        let flag = true;
        Object.keys(this.state.estadoValidacion).forEach(variable =>{
            if(!estadoDeValidacion[variable]) flag = false;
        });

        return flag;
    }

    resetearFormulario = () =>{
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        this.setState({
            nombre: '',
            dominio: '',
            usuariosPermitidos: '',
            cargando: false,

            mensajeNombre: false,
            mensajeDominio: false,
            mensajeUsuariosPermitidos: false,

            estadoValidacion: estadoDeValidacionInicial,
        })
    }

    submitDelFormulario = async (evento) => {
        evento.preventDefault();
        this.setState({cargando: true})
        let dataCreacionEmpresa = {
            dominioCorporativo: this.state.dominio,
            nombre: this.state.nombre,
            usuariosPermitidos: this.state.usuariosPermitidos
        }
        let dataParametrizacion = {
            dominioCorporativo: this.state.dominio,
            listaDeParametros: this.state.parametros
        }
        this.setState({cargando: false})
        if (this.obtenerEstadoDeFormulario()) {
            try {
                let idEmpresa = await servicioCrearEmpresa(dataCreacionEmpresa)
                this.props.agregarNuevaEmpresa(idEmpresa.data, dataCreacionEmpresa)
            }
            catch (error) {
                this.setState({ cargando: false })
                this.avisoDeGuardadoFallido(error.message);
            }
            try {
                await servicioCrearParametrizacionEmpresa(dataParametrizacion)
            }
            catch (error) {
                this.setState({ cargando: false })
                this.avisoDeGuardadoFallido(error.message);
            }
        }else{
            this.setState({ cargando: false })
            this.avisoDeGuardadoFallido('Los datos ingresados están incompletos no son válidos');
        }
        this.setState({ cargando: false })
        this.props.toggleDelModal()
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ContenidoModalAgregarEmpresa);