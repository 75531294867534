import React from 'react'

//toast:
import { toast } from 'react-toastify';
import 'react-toastify/scss/main.scss';

/** Base de datos */
import { firebaseFunctions } from '../../../../../firebase';

const procesosAPI510 = firebaseFunctions.httpsCallable('procesosAPI510');

export const calcularActivoPorFecha = async( id, fechaEnEstado, fechaACalcular, modulo, setCargando ) => {
    // setCargando( true );
    const datosParaprocesosVidaRemanente = {
        idActivo: id,
        fechaDeInspeccion: fechaEnEstado?.split('/').reverse().join('-'),
    }
    // const fechaParaBuscar = fechaACalcular?.toString()
    // const urlActivo = `bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos/${idDelActivo}/resultados/${modulo}/historialDeResultados`
    // firestoreDB.collection(urlActivo).doc(fechaParaBuscar)
    // .onSnapshot(snapShot => {
    //     if(snapShot.exists) {
            // setCargando( false );
            // return []
        // } else {
            procesosAPI510(datosParaprocesosVidaRemanente)
                .then(({data}) => {
                    if(data.status === 'STATUS_SUCCESS'){
                        // actualizarNodo();
                        console.log('sale y vale', data)
                    } else if (data.status === 'STATUS_ERROR') {
                        activarToast(`Ha ocurrido un error, ${data.message}`, 'error')
                    }
                })
                .catch(error => {console.log(error); })
        // }
    // })
 
}
const activarToast = (palabra, tipo) => {
    toast(palabra, {
        closeButton: true,
        hideProgressBar: false,
        autoClose: 7000,
        position: 'bottom-right',
        closeOnClick: true,
        pauseOnHover: true,
        type: tipo || 'success'
    });
}
