import React from 'react';
import GaleriaDeBS from './GaleriaDeBS';
import {
    Breadcrumb, BreadcrumbItem, CardBody, Card, CardHeader, Row, Col, Table
} from 'reactstrap';

import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';


let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = {
    display: "flex",
    width: "200%",
    justifyContent: 'center',
    cursor: 'pointer',
    // textDecoration: 'underline', 
    overflowX: 'auto',
    // justifyContent: "none", 
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'rgba(32, 39, 140, 0.5)'
}

class CaracterizacionBS extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            datosDeBS: undefined
        }
        this.cargarImagen = React.createRef();

    }    
    render(){
        return (
            <React.Fragment>
                <br/>
                <br/>
                <Breadcrumb>
                    <BreadcrumbItem className='BreadcrumbItem-color'>CARACTERIZACIÓN, VALORACIÓN Y EVALUACIÓN DE BENDING STRAIN</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader  style={{ alignContent: "center", justifyContent: "center", paddingTop:0, paddingBottom:0}}>DETALLE DE LA ANOMALIA BINDING STRAIN</CardHeader>
                    <CardBody>                    
                        {this.renderInformacionZona()}
                        {this.renderInformacionExcavacion()}
                        {this.renderJuntas()}
                        {this.renderTablaDistanciaJuntas()}
                        <GaleriaDeBS
                            idOrdenDeTrabajo={this.props.idOrdenDeTrabajo}
                        />
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

    renderInformacionZona = () => {
        return (
            <Row>
                <Col xs='12' md='12' lg='12' xl='12' style={{ overflowX: 'auto', margin: '10px 0' }} >
                    <CustomTable
                        listaDeDatos={this.props.datosDeBending}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Detalle de Zona BS</p>),
                                    columns: [
                                        {
                                            accesor: 'zonaBS',
                                            Header: () => (<p style={estiloParaHeader}>Zona BS</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.zonaBS ? row._original.detalleDeZonaBs.zonaBS : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'fechaCorridaILI',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Fecha de Corrida ILI</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.fechaCorridaILI ? new Date(row._original.detalleDeZonaBs.fechaCorridaILI.seconds * 1000).toISOString().split('T')[0] : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'distanciaRegistroIliInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Distancia de Registro ILI Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.distanciaRegistroIliInicio ? row._original.detalleDeZonaBs.distanciaRegistroIliInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 120
                                        },
                                        {
                                            accesor: 'distanciaRegistroIliFin   ',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Distancia de Registro ILI Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.distanciaRegistroIliFin ? row._original.detalleDeZonaBs.distanciaRegistroIliFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 120
                                        },
                                        {
                                            accesor: 'latitudBsInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Latitud Inicio</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.latitudBsInicio ? row._original.detalleDeZonaBs.latitudBsInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudBsInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud Inicio</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.longitudBsInicio ? row._original.detalleDeZonaBs.longitudBsInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'latitudBsFin',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Latitud Fin</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.latitudBsFin ? row._original.detalleDeZonaBs.latitudBsFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudBsFin',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud Fin</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.longitudBsFin ? row._original.detalleDeZonaBs.longitudBsFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'altitudBsInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Altitud Inicio</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.altitudBsInicio ? row._original.detalleDeZonaBs.altitudBsInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'altitudBsFin',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Altitud Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaBs.altitudBsFin ? row._original.detalleDeZonaBs.altitudBsFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                    ]
                                },
                            ]
                        }
                    />
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' style={{ overflowX: 'auto', margin: '10px 0' }}>
                    <CustomTable
                        listaDeDatos={this.props.datosDeBending}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Detalle de Zona PM</p>),
                                    columns: [
                                        {
                                            accesor: 'zonaPM',
                                            Header: () => (<p style={estiloParaHeader}>Zona PM</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.zonaPM ? row._original.detalleDeZonaPM.zonaPM : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'distanciaRegistroIliInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Distancia de Registro ILI Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.distanciaRegistroIliInicio ? row._original.detalleDeZonaPM.distanciaRegistroIliInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'distanciaRegistroIliFin   ',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Distancia de Registro ILI Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.distanciaRegistroIliFin ? row._original.detalleDeZonaPM.distanciaRegistroIliFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'latitudBsInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Latitud Inicio</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.latitudBsInicio ? row._original.detalleDeZonaPM.latitudBsInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudBsInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud Inicio</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.longitudBsInicio ? row._original.detalleDeZonaPM.longitudBsInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'latitudBsFin',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Latitud Fin</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.latitudBsFin ? row._original.detalleDeZonaPM.latitudBsFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudBsFin',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud Fin</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.longitudBsFin ? row._original.detalleDeZonaPM.longitudBsFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'altitudBsInicio',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Altitud Inicio</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.altitudBsInicio ? row._original.detalleDeZonaPM.altitudBsInicio : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'altitudBsFin',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Altitud Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleDeZonaPM.altitudBsFin ? row._original.detalleDeZonaPM.altitudBsFin : '-'}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                    ]
                                },
                            ]
                        }
                    />
                </Col>
            </Row>
        );
    }

    renderInformacionExcavacion = () => {
        return (
            <Row>
                <Col md='12' lg='12' style={{ overflowX: 'auto', margin: '10px 0'  }}>
                    <CustomTable
                        listaDeDatos={this.props.datosDeBending}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Detalle de la Excavación</p>),
                                    columns: [
                                        {
                                            accesor: 'distanciaRegistroIliInicial',
                                            Header: () => (<p style={estiloParaHeader}>Distancia de Registro ILI Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.distanciaRegistroIliInicial}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'distanciaRegistroIliFinal',
                                            Header: () => (<p style={estiloParaHeader}>Distancia de Registro ILI Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.distanciaRegistroIliFinal}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'pkCampoInicial',
                                            Header: () => (<p style={estiloParaHeader}>PK Campo Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.pkCampoInicial}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'pkCampoFinal',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>PK Campo Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.pkCampoFinal}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'latitudInicial   ',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Latitud Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.latitudInicial}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudInicial',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.longitudInicial}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'latitudFinal',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Latitud Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.latitudFinal}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudFinal',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.longitudFinal}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudDeExcavacion',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud de Excavación</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.longitudDeExcavacion}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'longitudTuberiaExpuesta',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud de Tuberia Expuesta</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.longitudTuberiaExpuesta}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'anchoDeExcavacion',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Ancho de la Excavación</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.anchoDeExcavacion}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'ubicacionTuberia',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Ubicación de la Tuberia</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.ubicacionTuberia}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'profundidadDeExcavacion',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Profundidad de la Excavación</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.profundidadDeExcavacion}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'tipoDeTerreno',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Tipo de Terreno</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.detalleExcavacion.tipoDeTerreno}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                    ]
                                },
                            ]
                        }
                    />
                </Col>
            </Row>
        );
    }

    obtenerNombre = (dato) => {
        switch (dato) {
            case 'distanciaRegistroILI':
                return 'Distancia Registro ILI'
            case 'distanciaEntreJuntasILI':
                return 'Distancia entre Juntas ILI'
            case 'distanciaRegistroReal':
                return 'Distancia Registro Real'
            case 'distanciaEntreJuntasReal':
                return 'Distancia entre Juntas Real'
            case 'abscisaCampo':
                return 'Abscisa Campo'
            case 'desplazamientoManguito':
                return 'Desplazamiento Manguito'
            case 'desplazamientoTuberiaHorizontal':
                return 'Desplazamiento Manguito Horizontal'
            case 'desplazamientoTuberiaVertical':
                return 'Desplazamiento Manguito Vertical'
            case 'desplazamientoTuberiaAxial':
                return 'Desplazamiento Manguito Axial'
            case 'curvasTuberoHorizontal':
                return 'Curvas por Tubero Horizontal'
            case 'curvasTuberoVertical':
                return 'Curvas por Tubero Vertical'
            case 'ovalidad':
                return 'Ovalidad'
            case 'hilow':
                return 'Hilow'
            case 'abolladura':
                return 'Abolladura'
            case 'perdidaDeMetal':
                return 'Pérdida de Metal'
            case 'latitudJuntaILI':
                return 'Latitud Junta ILI'
            case 'longitudJuntaILI':
                return 'Longitud Junta ILI'
            case 'latitudJuntaReal':
                return 'Latitud Junta Real'
            case 'longitudJuntaIReal':
                return 'Longitud Junta Real'
            case 'resultadoEnsayoUT':
                return 'Resultado Ensayo UT'
            case 'resultadoEnsayoMT':
                return 'Resultado Ensayo MT'
            case 'resultadoEnsayoVT':
                return 'Resultado Ensayo VT'
            default:
                break;
        }
    }

    dataJuntasTranspose = () => {
        let listaDeDatos = {
            distanciaRegistroILI: {},
            distanciaEntreJuntasILI: {},
            distanciaRegistroReal: {},
            distanciaEntreJuntasReal: {},
            abscisaCampo: {},
            desplazamientoManguito: {},
            desplazamientoTuberiaHorizontal: {},
            desplazamientoTuberiaVertical: {},
            desplazamientoTuberiaAxial: {},
            curvasTuberoHorizontal: {},
            curvasTuberoVertical: {},
            ovalidad: {},
            hilow: {},
            abolladura: {},
            perdidaDeMetal: {},
            latitudJuntaILI: {},
            longitudJuntaILI: {},
            latitudJuntaReal: {},
            longitudJuntaIReal: {},
            resultadoEnsayoUT: {},
            resultadoEnsayoMT: {},
            resultadoEnsayoVT: {},
        }
        let listaDeData = []
        this.props.datosDeBending[0].listadoDeSubAnomalias.forEach(junta => {
            Object.keys(listaDeDatos).forEach(dato => {
                listaDeDatos[dato]['dato'] = this.obtenerNombre(dato)
                listaDeDatos[dato][junta.idDeSubAnomalia] = junta.detalle[dato]
            });
        });
        Object.keys(listaDeDatos).forEach(dato => {
            listaDeData.push(listaDeDatos[dato])
        });
        this.renderColumnasJuntas(listaDeData)
        this.listaDeDatos = listaDeData 
        return listaDeData
    }

    renderColumnasJuntas = (data) => {
        if (data) {
            let arrayColumnas = []
            Object.keys(data[0]).forEach(element => {
                if (element !== 'dato') {
                    let columna = {
                        accesor: element,
                        Header: () => (<p style={estiloParaHeader}>{element}</p>),
                        Cell: ({ row }) => (
                            <div style={{ ...estiloSegmentos }}>
                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                >{row._original[element] ? row._original[element] : '-'}</p>
                            </div>
                        ),
                        minWidth: 90
                    }
                    arrayColumnas.push(columna)
                }
            });
            this.columnasJuntas = arrayColumnas;
        }
    }

    renderJuntas = () => {
        return (
            <Row>
                <Col md='12' lg='12' style={{ overflowX: 'auto', margin: '10px 0' }}>
                    <CustomTable
                        listaDeDatos={this.dataJuntasTranspose()}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Datos</p>),
                                    columns: [
                                        {
                                            accesor: 'dato',
                                            width: 400,
                                            Header: () => (<p style={estiloParaHeader}></p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', fontWeight: 'bold' }}
                                                    >{row._original.dato}</p>
                                                </div>
                                            ),
                                            minWidth: 150,
                                            //minWidth: 150
                                        },]
                                },
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Juntas</p>),
                                    columns: this.columnasJuntas
                                },
                            ]
                        }
                    />
                </Col>
            </Row>
        );
    }

    renderTablaDistanciaJuntas = () => { 
        return(
            <Table striped bordered>
                <thead>
                    <tr>
                        <th className="joint-column-title">Distancia entre Juntas</th>
                        {this.obtenerEncabezados()}
                    </tr>
                </thead>
                <tbody>
                    {this.obtenerFilas()}
                </tbody>
            </Table>
        );
    }

    obtenerEncabezados = () => {
        let elements = []
        this.listaDeDatos.forEach(element => {
            if (element.dato === 'Distancia entre Juntas Real') {
                Object.keys(element).forEach(key => {
                    if (key !== 'dato') {
                        elements.push(
                            <th className="joint-column-title" key={'table_' + key}>{key.toString()}</th>
                        )
                    }
                })
            }
        });
        return elements
    }
    
    obtenerCeldas = (n, size, key) => {
        let cells = []
        for (let x = 1; x < size; x++) {
            if (x===n){
                cells.push(<td colSpan='2' align="center" bgcolor="yellow" key={'row_' + Object.keys(key)[n] + "_" + x.toString()}>{key[Object.keys(key)[n + 1]]}</td>)
            }
            else {
                cells.push(<td key={'row_' + Object.keys(key)[n] + "_" + x.toString()}></td>)
            }
        }
        return cells
    }

    obtenerFilas = () => {
        let elements = []
        this.listaDeDatos.forEach(element => {
            if (element.dato === 'Distancia entre Juntas Real') {
                for (let n = 1; n < Object.keys(element).length - 1; n++) {
                    elements.push(
                        <tr key={'header_' + Object.keys(element)[n]}>
                            <th scope="row" className="joint-row-title" key={'column' + Object.keys(element)[n]}> {Object.keys(element)[n]} - {Object.keys(element)[n+1]}</th>
                            {this.obtenerCeldas(n,Object.keys(element).length - 1, element)}
                        </tr>
                    )
                }
            }
        });
        return elements
    }
}

export default CaracterizacionBS;
