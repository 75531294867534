function reducer(state, action) {
    switch (action.type) {
        case 'data':
            return { ...state, cargando: false, data: { ...state.data, ...action.payload }, ...action.extra};
        case 'resumen':
            return { ...state, resumen: action.payload, ...action.extra };
        case 'toggleDeLaConsolaDeCargue':
            return { ...state, laConsolaDeCargueEstaAbierta: !state.laConsolaDeCargueEstaAbierta }
        case 'toggleDeLaConsolaDeDescargue':
            return { ...state, laConsolaDeDescargueEstaAbierta: !state.laConsolaDeDescargueEstaAbierta, ...action.payload }
        case 'reportes':
            return { ...state, data: { ...state.data, ...action.payload }, cargandoReportes: false, cargando: false };
        case 'target':
            return { ...state, campoTarget: action.payload, cargando: true }
        case "cargado":
            return { ...state, cargando: false, cargandoReportes: false }
        case "cargando":
            return { ...state, cargando: true, cargandoReportes: true }
        case "index":
            return {...state,...action.payload}
        default:
            throw new Error();
    }
}


export default reducer