import React from 'react';
import { Divider, Grid, Hidden } from '@material-ui/core'
import BarrasDobleAgrupadas from '../../componentes/BarrasDoblementeAgrupadas';
import TabsGraficas from './Tabs';
import useStyles from './styles'
import COLORES from '../../scripts/colores';
import ApexChart from '../../componentes/graficas/GraficarTorta2';

import zipObject from "lodash/zipObject"

// zipObject(data, objColors);

import {
    Row, Col,
    Card, CardBody,
    CardTitle
} from 'reactstrap';
import { ESTADO_PRD } from '../../scripts/constantes';
import styles from './styles';

function generarColores(data) {
    let keys = []
    if (typeof (data) === 'object') keys = Object.keys(data);
    if (Array.isArray(data)) keys = data;

    return zipObject(keys, COLORES.DEFAULT);
}


function detalle(resumen, classes) {
    const datosLista = { "Válvulas por sistema": resumen.sistema, "Válvulas por tipo": resumen.tipoDeValvula }
    return (
        <React.Fragment>
            {Object.keys(datosLista).map((sectionId, i) => (
                <Card key={sectionId + i} style={{ width: "100%", marginBottom: "1rem", padding: "15px" }}  >
                    <CardTitle>{sectionId}</CardTitle>
                    <CardBody key={sectionId + "bb"} style={{ padding: '0.4rem', margin: 1 }}>
                        {Object.keys(datosLista[sectionId]).map((item) => (
                            <Row key={item + sectionId} >
                                <Col key={item + "11"} >{item}</Col>
                                <Col xs={{ size: "auto" }} style={{ marginRight: "1rem", marginLeft: "auto" }} key={item + "22"} >{datosLista[sectionId][item]}</Col>
                            </Row>
                        ))}
                    </CardBody>
                </Card>
            ))}
        </React.Fragment>
    );
}

function opcionesDeTorta(props) {
    const { variables, datos, colores } = props;
    if (variables && datos && colores) {
        return {
            series: datos,
            options: {
                colors: colores,
                chart: {
                    width: '100%',
                    type: 'donut',
                },
                labels: variables,
                responsive: [{
                    breakpoint: 430,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                dataLabels: {
                    enabled: true,
                    formatter: function (datos) {
                        return `${datos.toFixed(0)}%`
                    },
                },
                legend: {
                    position: 'bottom'
                }
            },

        };
    }
    return {}
}


function GraficasTorta(resumen, colores) {
    const classes = useStyles(styles)
    return (
        <Grid container justify="center" alignContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} xl={6} className={classes.graficasContadoresDetalle} >
                <Grid container justify="center">
                    <CardTitle >Total de válvulas por sistema</CardTitle>
                </Grid>
                <ApexChart opciones={opcionesDeTorta(transfor(resumen.sistema))} />
            </Grid>
            <Hidden xlUp>
                <Grid item xs={12} >
                    <Divider />
                </Grid>
            </Hidden>
            <Hidden lgDown>
                <Divider orientation="vertical" flexItem />
            </Hidden>
            <Grid item xs={12} xl={6} className={classes.graficasContadoresDetalle} >
                <Grid container justify="center">
                    <CardTitle>Total de válvulas por tipo</CardTitle>
                </Grid>
                <ApexChart
                    opciones={opcionesDeTorta(transfor(resumen.tipoDeValvula))}
                />
            </Grid>
        </Grid>
    )
}

/**
 * Nombre: Válvulas existentes vs inspeccionadas
 * @param {Object} resumen 
 */
function TortaVEvsINSP(resumen) {
    const classes = useStyles(styles);
    const { inspeccionadas = 0, sinInspeccion = 0 } = resumen;
    const data = {
        "Sin Inspeccionar": sinInspeccion,
        "Inspeccionadas": inspeccionadas,
    }

    return (
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
            <Grid item className={classes.graficasContadores} >
                <ApexChart opciones={opcionesDeTorta(transfor(data))} />
            </Grid>
        </Grid>

    )
}

function transfor(data) {
    if (!data) return
    let variables = Object.keys(data);
    const color = generarColores(variables);
    let datos = [];
    let colores = [];
    variables.forEach((item, i) => {
        datos[i] = data[item];
        colores[i] = color[item];
    })

    return {
        'variables': variables,
        'datos': datos,
        'colores': colores,
    };
}


/**
 * Nombre: Válvulas operativas vs disponibles
 * @param {Object} resumen 
 */
function TortaVOvsDisp(resumen) {
    const classes = useStyles(styles);
    const {
        [ESTADO_PRD.OPERATIVA]: operativas = 0,
        [ESTADO_PRD.DISPONIBLE]: Disponible = 0,
        [ESTADO_PRD.FUERA_DE_SERVICIO]: fueraDeServicio = 0
    } = resumen.estado;

    const data = {
        "Operativas": operativas,
        "Disponibles": Disponible,
        "Fuera de servicio": fueraDeServicio,
    }
    return (
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
            <Grid item className={classes.graficasContadores} >
                <ApexChart opciones={opcionesDeTorta(transfor(data))} />
            </Grid>

        </Grid>
    )
}

/**
 * Nombre: barrasPEvsTV
 * 
 * Descripción: función que grafica el total de válvulas por planta o estavión vs tipo de válvula.
 * @param {Objct} dataBarra 
 */
function BarrasPEvsTV(dataBarra) {
    const classes = useStyles(styles);
    return (
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
            <Grid item className={classes.graficasBarrasContadores} >
                <BarrasDobleAgrupadas
                    data={dataBarra}
                    colores={COLORES.TIPODEVALVULA}
                    nombreGrafica={"Planta/estación vs tipo de activo"}
                    barSize={50}
                    mostrarValores
                />
            </Grid>
        </Grid>
    )
}

function BarrasPEvsSIS(dataBarraSIS, colores) {
    const classes = useStyles(styles);
    return (
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
            <Grid item className={classes.graficasBarrasContadores} >
                <BarrasDobleAgrupadas
                    data={dataBarraSIS}
                    colores={colores}
                    nombreGrafica={"Planta/estación vs tipo de activo"}
                    barSize={50}
                    mostrarValores
                />
            </Grid>
        </Grid>
    )
}

export default function GraficasContadores(props) {
    const resumen = props.data;
    const classes = useStyles();

    if (resumen && resumen.tipoDeValvula) {
        const dataBarra = Object.keys(resumen.PEvsTV).map(item => {
            let data = resumen.PEvsTV[item];
            delete data.total;
            return { "nombre": item, ...data }
        })

        const dataBarraSIS = Object.keys(resumen.PEvsSIS).map(item => {
            let data = resumen.PEvsSIS[item];
            delete data.total;
            return { "nombre": item, ...data }
        })

        const colorSistem = generarColores(resumen.sistema);

        return (
            <Grid item container direction="row" className={classes.contadoresContainer} spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={7} xl={5}className={classes.contador} >
                    <TabsGraficas
                        contentHeader={[
                            "Total de válvulas planta/estacion por tipo",
                            "Total de válvulas planta/estacion por sistema",
                            "Válvulas inspeccionadas vs sin inspeccionar",
                            "Válvulas operativas vs disponibles",
                        ]}
                        contentBody={[
                            () => BarrasPEvsTV(dataBarra),
                            () => BarrasPEvsSIS(dataBarraSIS, colorSistem),
                            () => TortaVEvsINSP(resumen),
                            () => TortaVOvsDisp(resumen)
                        ]}
                    />
                </Grid>
                <Grid style={{ height: '100%' }} item xs={12} sm={12} md={4} lg={5} xl={7}>
                    <TabsGraficas
                        contentHeader={["General", "Detalle"]}
                        styleBody={{ overflow: "auto" }}
                        contentBody={[
                            () => GraficasTorta(resumen, {"sistem":colorSistem})
                            , () => detalle(resumen, classes)
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
    return (<></>)

}