import React from 'react'

import { Label, Input, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import { MdEdit } from "react-icons/md";
import { MdAdd } from "react-icons/md";

//Componentes Auxiliares
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = { display: "flex", width: "100%", justifyContent: 'center', cursor: 'pointer', textDecoration: 'underline' }

const DatosDeCampo = (props) => {
    return (
        <React.Fragment>
            <br />
            <br />
            <div className={"mb-0 p-3 d-flex " + (props.estado ? 'border rounded-top' : 'border rounded')} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                <Label style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black'}}>DATOS DEL CAMPO</Label>                    
                <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                    {props.authRol ? < BotonCallToAction key={'btn_campo_detalle'}
                        usuarioActivo={props.usuarioActivo}
                        color={props.estado ? 'primary' : 'success'}
                        icono={props.estado ? MdEdit : MdAdd}
                        titulo={props.estado ? 'Editar Datos' : 'Agregar Datos'}
                        onClick={props.toogleModal}
                    /> : null}
                </div>
            </div>
            {obtenerRenderDeDatos(props)}
        </React.Fragment>
    )
}

const obtenerRenderDeDatos = (props) => {
    if(props.estado){
        return(
            <div className="p-3 shadow-none border rounded-bottom">  
                <Row>
                    <Col>
                        <Row>
                            <Col xs='12' md='12' lg='6'>
                                <Label for="pkCampo" className='mb-0 mt-2'>Pk. Campo:</Label>
                                <Input type="text" name="pkCampo" id="pkCampo" placeholder="Pk. Campo" defaultValue={props.keysDelRegistro.pkCampo} disabled/>
                            </Col>
                            <Col xs='12' md='12' lg='6'>
                                <Label for="ubicacionTuberia" className='mb-0 mt-2'>Ubicación tubería:</Label>
                                <Input type="text" name="ubicacionTuberia" id="ubicacionTuberia" placeholder="Ubicación tubería" defaultValue={props.keysDelRegistro.ubicacionTuberiaAnomalia} disabled/>
                            </Col>
                    
                            <Col xs='12' md='12' lg='6'>
                                <Label for="longitud" className='mb-0 mt-2'>Longitud(°'"):</Label>
                                <Input type="text" name="longitud" id="longitud" placeholder="Longitud" defaultValue={props.keysDelRegistro.longitudDeAnomaliaDecimal} disabled/>
                            </Col>
                            <Col xs='12' md='12' lg='6'>
                                <Label for="longitudTubo" className='mb-0 mt-2'>Longitud tubo:</Label>
                                <Input type="text" name="longitudTubo" id="longitudTubo" placeholder="Longitud tubo" defaultValue={props.keysDelRegistro.longitudTubo} disabled/>
                            </Col>
                    
                            <Col xs='12' md='12' lg='6'>
                                <Label for="latitud" className='mb-0 mt-2'>Latitud(°'"):</Label>
                                <Input type="text" name="latitud" id="latitud" placeholder="Latitud" defaultValue={props.keysDelRegistro.latitudDeAnomaliaDecimal} disabled/>
                            </Col>
                            <Col xs='12' md='12' lg='6'>
                                <Label for="precisionGPS" className='mb-0 mt-2'>Precisión del GPS:</Label>
                                <Input type="text" name="precisionGPS" id="precisionGPS" placeholder="Precisión del GPS" defaultValue={props.keysDelRegistro.precisionDelGPS} disabled/>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className='medicion-de-espesores'>
                            <CustomTable
                                listaDeDatos={props.espesores}
                                columnas={
                                    [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Medición de espesores alrededor del área afectada</p>),
                                            columns: [
                                                {
                                                    accesor: 'MM',
                                                    Header: () => (<p style={estiloParaHeader}>(mm)</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.MM}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'Z1',
                                                    Header: () => (<p style={estiloParaHeader}>Z1</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.Z1}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'Z2',
                                                    Header: () => (<p style={estiloParaHeader}>Z2</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.Z2}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'Z3',
                                                    Header: () => (<p style={estiloParaHeader}>Z3</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.Z3}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'Z4',
                                                    Header: () => (<p style={estiloParaHeader}>Z4</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.Z4}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'Z5',
                                                    Header: () => (<p style={estiloParaHeader}>Z5</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.Z5}</p>
                                                        </div>
                                                    ),
                                                },
                                            ]
                                        }
                                    ]
                                }
                            />
                        </Row>
                        <Row className='medicion-de-espesores'>
                            <CustomTable
                                listaDeDatos={[props.keysDelRegistro]}
                                columnas={
                                    [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Espesor (mm)</p>),
                                            columns: [
                                                {
                                                    accesor: 'espesorMinimo',
                                                    Header: () => (<p style={estiloParaHeader}>Mínimo</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.espesorMinimo}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'espesorMaximo',
                                                    Header: () => (<p style={estiloParaHeader}>Máximo</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.espesorMaximo}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'espesorPromedio',
                                                    Header: () => (<p style={estiloParaHeader}>Promedio</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.espesorPromedio}</p>
                                                        </div>
                                                    ),
                                                },
                                            ]
                                        }
                                    ]
                                }
                            />
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }else{
        return(<></>);
    }
}

export default DatosDeCampo;