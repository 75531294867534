import React from 'react';
import Select from 'react-select';
import { Col, Card, CardBody,  Button, Input, CardHeader } from 'reactstrap';
import { FaTrash, FaCheckSquare, FaArrowAltCircleUp, FaArrowAltCircleDown, FaRedoAlt } from 'react-icons/fa';

//Componentes auxiliares:
import SelectAsincrono from './SelectAsincrono.jsx';
import get from 'lodash/get';

//Constantes para el desarrollo:
const indiceDelElementoDefault = 0;

class FiltroDinamico extends React.Component {
    
    constructor() {
        super();
        
        this.state = {
            valorTarget: undefined,
            categoriaActiva: undefined,
            comparadorActivo: undefined,
            
            elFiltroHaSidoSeteado: false,
            elFiltroSeHaModificado: false,
            opcionesAsincronas: undefined,
            
            mostrarContenido: false,
            valorComparador:undefined,

            valorInput: undefined,
            conterRender:0,
        };
    }
    
    render() {
        if (this.state.elFiltroHaSidoSeteado) {
            return (
                <Col xs="12" sm="12" md="12" lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 15 }}>
                    <Card className="widget-chart border mb-2" style={{ padding: 0 }}>
                        
                        <CardHeader style={{ padding: 0, display: "inline", alignItems: "center", height: "100%" }}
                            className={(this.state.mostrarContenido) ? "bg-royal text-light" : "bg-royal text-light bordered-radius"}>
                            <div style={{ fontSize: "0.7rem", textAlign: "left", padding: 10, paddingLeft: 20, paddingBottom: 0 }}>
                                <span style={{ textTransform: "none" }}
                                    dangerouslySetInnerHTML={{ __html: this.obtenerLeyendaDelFiltro() }} />
                            </div>

                            <div style={{ display: "flex", justifyContent: "flex-end", paddingLeft: 10, paddingRight: 10, paddingBottom: 5 }}>
                                
                                {this.obtenerBotonDeConfirmacion()}
                                {this.obtenerBotonDeActualizacionDeSelectAsincrono()}

                                <Button className="btn-icon btn-icon-only filter-manager-quick-button" 
                                    title={(this.state.mostrarContenido) ? "Ocultar" : "Expandir"}
                                    onClick={() => { this.setState({ mostrarContenido: !this.state.mostrarContenido }); }}>
                                    {(this.state.mostrarContenido) ? <FaArrowAltCircleUp size={12} /> : <FaArrowAltCircleDown size={12} />}
                                </Button>

                                <Button className="btn-icon btn-icon-only filter-manager-quick-button" title="Eliminar filtro"
                                    onClick={() => { this.props.desactivarFiltro(this.props.keyAsociado); }}>
                                    <FaTrash size={10}/>
                                </Button>
                            </div>
                        </CardHeader>
                        
                        <CardBody className={("text-" + this.props.textColor + " " || " ")}
                            style={{
                                display: (this.state.mostrarContenido) ? "flex" : "none",
                                backgroundColor: "rgb(" + this.props.rgbBackground + ")",
                                flexDirection: "column", alignItems: "center"
                            }}>

                            <div style={{ flex: "100%", width: "100%", marginTop: 5, marginBottom: 5 }}>
                                <span className="widget-subheading" style={{ textAlign: "left", marginTop: 2.5, marginBottom: 2.5 }}>
                                    Categoría:
                                </span>

                                {this.obtenerSelectDeCategorias()}
                            </div>

                            <div style={{ flex: "100%", width: "100%", marginTop: 5, marginBottom: 5 }}>
                                <span className="widget-subheading" style={{ textAlign: "left", marginTop: 2.5, marginBottom: 2.5 }}>
                                    Criterio de filtrado:
                                </span>

                                {this.obtenerSelectDeComparadores()}
                            </div>

                            <div style={{ flex: "100%", width: "100%", marginTop: 5, marginBottom: 5 }}>
                                <span className="widget-subheading" style={{ textAlign: "left", marginTop: 2.5, marginBottom: 2.5 }}>
                                    Valor objetivo:
                                </span>

                                {this.obtenerInputDeValores()}
                                {this.obtenerSelectDeValores()}
                                {this.obtenerSelectDeOpcionesAsincronas()}
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            );
        }
        else { return (<React.Fragment />); }
    }

    componentDidMount() {
        if (this.props.tipo !== undefined) {
            const categorias = this.props.categorias;
            let categoriaTarget = categorias.filter(item => item.tipo === this.props.tipo);
            categoriaTarget = categoriaTarget[0];
            let mostrarContenido = false;
            if (this.props.mostrarContenido !== undefined) { mostrarContenido = this.props.mostrarContenido; };
            this.setState({
                elFiltroHaSidoSeteado: true,
                categoriaActiva: categoriaTarget,
                valorTarget: this.props.filtroAsociadoActual.valorTarget ? {"value":this.props.filtroAsociadoActual.valorTarget} : categoriaTarget.valorDefault,
                comparadorActivo: categoriaTarget.comparadoresPermitidos[indiceDelElementoDefault],

                mostrarContenido: mostrarContenido,    
                valorInput:this.props.filtroAsociadoActual.valorTarget,
            },()=>this.obtenerValorCategoria(categoriaTarget.comparadoresPermitidos[0]));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const categorias = this.props.categorias;
        let categoriaTarget = categorias.filter(item => item.tipo === this.props.tipo);
        categoriaTarget = categoriaTarget[0];
        if(prevProps !== this.props){
            this.setState({
                valorInput:this.props.filtroAsociadoActual.valorTarget,
            })
            this.obtenerValorCategoria(categoriaTarget.comparadoresPermitidos[0]);
        } 
    }

    //Métodos para renderizado:
    obtenerBotonDeActualizacionDeSelectAsincrono = () => {
        if (this.state.categoriaActiva.tipoDeCategoria === "opcionesAsincronas") {
            if (this.state.opcionesAsincronas !== undefined) {
                return (
                    <Button className="btn-icon btn-icon-only filter-manager-quick-button" title="Actualizar opciones"
                        onClick={() => { this.setState({ opcionesAsincronas: undefined }); }}>
                        <FaRedoAlt size={12} />
                    </Button>
                );
            }
        }
    }

    obtenerBotonDeConfirmacion = () => {
        if (this.state.elFiltroSeHaModificado) {
            return (
                <Button className="btn-icon btn-icon-only filter-manager-quick-button" title="Confirmar cambios"
                    onClick={this.eventoDeConfirmacionDeCambios}>
                    <FaCheckSquare size={12} />
                </Button>
            );
        }
    }

    obtenerLeyendaDelFiltro = () => {
        try {
            let valorTarget = this.state.valorTarget.value;
            if (this.props.filtroAsociadoActual.valorTarget !== undefined) {
                valorTarget =  get(this.state,"valorTarget.value","---")
            };
            return this.state.categoriaActiva.descripcion(this.state.comparadorActivo.label, valorTarget);
        }
        catch (error) { return "No se encontraron alternativas que cumplan con los criterios" }
    }

    obtenerValorCategoria(dato){
        let valor = undefined;
        let comparador = this.state.categoriaActiva.comparadoresPermitidos.find(item => item.value === this.props.filtroAsociadoActual.comparador)
        if(comparador && this.state.comparadorActivo === dato){
            valor = comparador
        }else if(comparador && this.state.comparadorActivo !== dato){
            valor = this.state.comparadorActivo;
        }else{
            valor = dato;
        }
        this.setState({
            valorComparador: valor,
            comparadorActivo:valor,
        })
    }

    obtenerInputDeValores = () => {
        if (this.state.categoriaActiva.tipoDeCategoria === "valoresContinuos") {
            return (
                <Input className="custom-border-form-control" placeholder="..."
                    key={this.state.conterRender}
                    defaultValue={this.state.valorInput}
                    onKeyUp={(e) => { if (e.keyCode === 13) { this.eventoDeConfirmacionDeCambios(); e.target.blur(); } }}
                    onChange={(e) => {
                        let valorTarget = this.state.valorTarget || {};
                        valorTarget.value = e.target.value;
                        if (this.state.categoriaActiva.tipoDeCategoria === "valoresContinuos") {
                            if (!this.state.categoriaActiva.esTexto) {
                                try { valorTarget.value = parseFloat(valorTarget.value); }
                                catch (error) { valorTarget.value = 0 };

                                if (isNaN(valorTarget.value)) { valorTarget.value = 0 };
                            }
                        }

                        this.setState({ 
                            valorTarget: valorTarget, elFiltroSeHaModificado: true,
                            valorInput:valorTarget.value,
                        });
                    }} />
            );
        }
    }

    obtenerSelectDeCategorias = () => {
        const categorias = this.props.categorias;
        const categoriasOrdenadas = categorias.sort((a, b) => (a.label > b.label) ? 1 : -1);
        return (
            <Select options={categoriasOrdenadas} key={(new Date()).getTime()}
                defaultValue={this.state.categoriaActiva} onChange={this.eventoDeCambioDeCategoria} />
        );
    }

    obtenerSelectDeComparadores = () => {
        return (
            <Select key={(new Date()).getTime()}
                value={this.state.valorComparador || this.state.comparadorActivo}
                options={this.state.categoriaActiva.comparadoresPermitidos}    
                onChange={(e) => {
                    this.setState({ 
                        comparadorActivo: e, 
                        elFiltroSeHaModificado: true,
                        valorComparador:e
                    });
                }} />
        );
    }

    obtenerSelectDeValores = () => {
        if (this.state.categoriaActiva.tipoDeCategoria === "opcionesDiscretas") {
            let valorDefault = this.state.categoriaActiva.opcionesDeLaCategoria.find((item) =>
                item.value === this.props.filtroAsociadoActual.valorTarget && 
                this.state.categoriaActiva.tipo === this.props.filtroAsociadoActual.tipo);

            if (!valorDefault) { valorDefault = this.state.categoriaActiva.opcionesDeLaCategoria[0].value };

            valorDefault = typeof(valorDefault) !== "object" ? {"value":valorDefault,"label":valorDefault} : valorDefault;
          
            return (
                <Select key={this.state.conterRender} defaultValue={valorDefault} options={this.state.categoriaActiva.opcionesDeLaCategoria}
                    onChange={(e) => { this.setState({ valorTarget: e, elFiltroSeHaModificado: true }); }} />
            );
        }
    }

    obtenerSelectDeOpcionesAsincronas = () => {
        if (this.state.categoriaActiva.tipoDeCategoria === "opcionesAsincronas") {
            if (this.state.opcionesAsincronas === undefined) {
                return (
                    <SelectAsincrono
                        placeholder={this.state.categoriaActiva.placeholder}
                        otrosFiltrosActivos={this.props.otrosFiltrosActivos}
                        funcionParaObtenerOpciones={this.state.categoriaActiva.obtenerOpcionesDesdeCF}
                        onSync={(opciones) => {
                            try {
                                this.setState({ 
                                    valorTarget: opciones[0], 
                                    opcionesAsincronas: opciones, 
                                });
                            }
                            catch(error){}
                        }} />
                );
            }
            else {
                return (
                    <Select options={this.state.opcionesAsincronas}
                        defaultValue={this.state.opcionesAsincronas[0]}
                        onChange={(e) => { this.setState({ valorTarget: e, elFiltroSeHaModificado: true }); }} />
                );
            }
        }
    }

    //Métodos operativos:
    eventoDeCambioDeCategoria = (nuevaCategoria) => {
        switch (nuevaCategoria.tipoDeCategoria) {
            case "opcionesDiscretas":
                this.setState({
                    elFiltroSeHaModificado: true,
                    categoriaActiva: nuevaCategoria,
                    valorTarget: {"value":nuevaCategoria.opcionesDeLaCategoria[0].value},
                    valorComparador: nuevaCategoria.comparadoresPermitidos[0],
                    comparadorActivo: nuevaCategoria.comparadoresPermitidos[0],
                    valorInput: nuevaCategoria.opcionesDeLaCategoria[0].value || "Seleccione una opción",
                    conterRender:this.state.conterRender+1
                });
                break;

            case "valoresContinuos":
                this.setState({
                    elFiltroSeHaModificado: true,
                    categoriaActiva: nuevaCategoria,
                    valorTarget: { value: 0, label: "" },
                    valorComparador:nuevaCategoria.comparadoresPermitidos[0],
                    comparadorActivo: nuevaCategoria.comparadoresPermitidos[0],
                    valorInput:0,
                    conterRender:this.state.conterRender+1
                });
                break;

            case "opcionesAsincronas":
                this.setState({
                    elFiltroSeHaModificado: true,
                    opcionesAsincronas: undefined,
                    categoriaActiva: nuevaCategoria,
                    valorComparador:nuevaCategoria.comparadoresPermitidos[0],
                    comparadorActivo: nuevaCategoria.comparadoresPermitidos[0],
                    valorInput:0,
                    conterRender:this.state.conterRender+1
                });
                break;

            default:
                break;
        }
    }

    eventoDeConfirmacionDeCambios = (ejecutarConsulta = true) => {
        this.setState({ elFiltroSeHaModificado: false });

        try { this.props.actualizarValorDeFiltro(this.props.keyAsociado, this.obtenerNuevoCriterioDeFiltrado(), ejecutarConsulta);}
        catch (error) { console.log(error) }
    }

    obtenerNuevoCriterioDeFiltrado = () => {
        return {
            activo: true,
            tipo: this.state.categoriaActiva.tipo,
            valorTarget: this.state.valorTarget.value,
            // valorTarget: this.state.valorInput === 0 ? this.state.valorTarget.value: this.state.valorInput,
            key: this.state.categoriaActiva.keyDelNodo,
            comparador: this.state.comparadorActivo.value,
            desplegado: this.state.mostrarContenido,
        }
    }

    toggleDelContenido = () => {
        this.setState({ mostrarContenido: !this.state.mostrarContenido });
    }
}

export default FiltroDinamico;