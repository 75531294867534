export const confirmacionDeEliminarUsuario = (nombre) => {
    let variablesDelSwal = {
        title: 'Confirma los cambios',
        html: `<span style="font-size:14px;">¿Seguro que quieres eliminar a <b>${nombre}</b>?</span>`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        focusConfirm: true
    }
    return (
        variablesDelSwal
    )
};
export const confirmacionDeActualizacionDeNodos = {
    title: 'Confirma los cambios',
    html: '<span style="font-size:14px;">Requerimos confirmar los cambios porque la descarga será grande. El proceso puede tardar algunos segundos.</span>',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm: true
};

export const confirmacionDeActualizacionDeComponentesDeCalculo = {
    title: 'Confirma los cambios',
    html: '<span style="font-size:14px;">Este cambio puede ser revertido en cualquier momento</span>',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm: true
};

export const confirmarBajaDeValvula = {
    title: 'Confirma los cambios',
    html: '<span style="font-size:14px;">¿Seguro que desea sacar de servicio esta válvula?</span>',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    focusConfirm: true
};

export const confirmacionDeActualizacionDeTabla = (guardarCambios, value,msg="") => {
    let variablesDelSwal = {
        title: 'Confirma los cambios',
        html: `<span style="font-size:14px;">¿Seguro que quieres modificar ${guardarCambios} por ${value}? ${msg}</span>`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        focusConfirm: true
    }
    return (
        variablesDelSwal
    )
};

export default confirmacionDeActualizacionDeTabla;

//Confirmación Eliminar Activo de Válvulas

export const confirmacionDeEliminacionDeActivo = (nombreActivo) => {
    let variablesDelSwal = {
        title: 'Confirma los cambios',
        html: `<span style="font-size:14px;">¿Seguro que deseea eliminar el activo <b>${nombreActivo}</b>?</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        focusConfirm: true
    }
    return (
        variablesDelSwal
    )
};


//  confirmacionDeEliminarUsuario;

export const mensajeErrorDeDominio = {
    icon: 'error',
    title: '¡Algo anda mal!',
    text: 'Tu correo corporativo no está registrado',
    footer: '<p>Por favor, contactate con tu empresa para verificar el correo</p>'
}

export const mensajeLimiteDeUsuarios = {
    icon: 'error',
    title: '¡Algo anda mal!',
    text: 'Tu empresa ha alcanzado el límite de usuarios',
    footer: '<p>Por favor, contactate con tu empresa para verificar el límite de <b>usuarios activos</b> en la plataforma.</p>'
}

export const mensajeIngresoUsuarioExitoso = {
    icon: 'success',
    title: '¡Bienvenido!',
    text: 'El proceso de ingreso ha sido exitoso',
}
export const mensajeErrorDeUsuario = {
    icon: 'error',
    title: '¡Algo anda mal!',
    text: 'Error al inicial sesion, intentalo de nuevo',
}
export const mensajeUsuarioEliminadoExitosamente = {
    icon: 'success',
    title: '¡Usuario eliminado!',
}
export const mensajeUsuarioEliminadoError = {
    icon: 'error',
    title: '¡Ups...!',
    text: 'El usuario no se ha podido eliminar',
}
export const mensajeConfirmacionEliminar = (imagen) => {
    let variablesDelSwal = {
        title: '¿Seguro que quieres eliminar esta imagen?',
        html: '<span style="font-size:14px;">El proceso puede tardar algunos segundos...</span>',
        imageUrl: imagen,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        focusConfirm: true
    }
    return variablesDelSwal
}

