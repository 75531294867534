const crearTablaLeyendaGraficaBarrasUtScanC = () => {
    const items = [
        {
        'alias': { label: 'Alias', valor: 'A', minWidth: 10,},
        'valor': { label: 'Valor', valor: 'Porcentaje de pérdida menor a 10', minWidth: 45},
        },
        {
        'alias': { label: 'Alias', valor: 'B', minWidth: 10,},
        'valor': { label: 'Valor', valor: 'Porcentaje de pérdida entre 10 y 30', minWidth: 45},
        },
        {
        'alias': { label: 'Alias', valor: 'C', minWidth: 10,},
        'valor': { label: 'Valor', valor: 'Porcentaje de pérdida mayor a 30', minWidth: 45},
        },
       
    ];
    
    return items
}

export default crearTablaLeyendaGraficaBarrasUtScanC