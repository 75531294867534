import React, {useState} from 'react'
import { firebaseFunctions } from "../../../../../../firebase";
import { Label, Input, Col, Row } from 'reactstrap';
import { MdEdit, MdDone, MdAdd, MdClear } from "react-icons/md";

//Componentes Auxiliares
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";
import { dispararAlertExitoso, dispararAlertFallido } from '../../../../../../utilidades/Swal/Swal';

//Cloud function Edicion
const editarObservaciones = firebaseFunctions.httpsCallable('odt_editarObservaciones');

const Observaciones = (props) => {
    const [estado]=useState(props.estado)
    const [modificando, setModificando]=useState(false)
    const [numeroDeFormato]=useState(props.keysDelRegistro.numeroDeFormato)
    const [observaciones, setObservaciones]=useState(props.keysDelRegistro.observaciones)
    const [observacionesTemp, setObservacionesTemp]=useState(props.keysDelRegistro.observaciones)

    const toggleEdicion = () => setModificando(prevModificando=>!prevModificando)

    const handleCancelacion = () => {
        const tempState = {...observaciones}
        setObservacionesTemp(tempState)    
        toggleEdicion()    
    }

    const handleGuardar = async () => {
        let response
        props.loading()
        const data = {
            dataObservaciones: {
                numeroDeFormato: numeroDeFormato,
                observacionGeneral: observacionesTemp.observacionGeneral,
                observacionReparacion: observacionesTemp.observacionReparacion,
                observacionEND: observacionesTemp.observacionEND,
                observacionAdicional: observacionesTemp.observacionAdicional,
            }
        }
        try{
            response = await editarObservaciones(data)
        }
        catch(error){
            dispararAlertFallido('Hubo un error', error.message||'Hubo un error en la modificación de las observaciones')
        }
        switch (response.data.status)
            {
            case 'ODT_ACTUALIZADA':
                setObservaciones(observacionesTemp)
                dispararAlertExitoso('Actualización Exitosa', response.data.message||'Las observaciones fueron actualizadas con exito!')
            break;
            default:
                dispararAlertFallido('Actualización fallida', response.data.message||'Hubo un error en la modificación de las observaciones')
                break;
        }
        toggleEdicion()
        props.loading()
    }

    const handleTextMod=(key, value)=>{
        let tempState = {...observacionesTemp}
        tempState[key]=value
        setObservacionesTemp(tempState)
    }

    const renderBoton = () => {
        if(!modificando){
            return (
                <BotonCallToAction key={'btn_mod_obs_mod'}
                        usuarioActivo={props.usuarioActivo}
                        color={props.estado ? 'primary' : 'success'}
                        icono={props.estado ? MdEdit : MdAdd}
                        titulo={props.estado ? 'Editar Datos' : 'Agregar Datos'}
                        onClick={toggleEdicion}
                    />
            )
        }
        else {
            return (
                <>
                <div className='observations-button-area'>
                    <BotonCallToAction
                        key={'btn_mod_obs_cancelar'}
                        usuarioActivo={props.usuarioActivo}
                        color={'danger'}
                        icono={MdClear}
                        titulo={'Cancelar'}
                        onClick={handleCancelacion}
                        />
                </div>
                <div className='observations-button-area'>
                    <BotonCallToAction
                        key={'btn_mod_obs_guardar'}
                        usuarioActivo={props.usuarioActivo}
                        color={'success'}
                        icono={MdDone}
                        titulo={'Guardar'}
                        onClick={handleGuardar}
                    />
                </div>
                </>
            )
        }
    }

    const obtenerRenderDeDatos = (params) => {
        const {observaciones, estado, modificando}=params
        if(estado){
            return(
                <div className="p-3 shadow-none border rounded-bottom">  
                    <Row>
                        <Col>
                            <Row>
                                {(observaciones.observacionGeneral || modificando) ? <Col xs='12' md='12' lg='12'>
                                    <Label for="pkCampo" className='mb-0 mt-2 labelcustom' style={{fontWeight:'bold'}}>Observaciones Generales:</Label>
                                    <Input style={{backgroundColor:'white'}} type="textarea" className='w-100 textareacustom' name="pkCampo" id="pkCampo" placeholder="Pk. Campo" defaultValue={observaciones.observacionGeneral} onChange={(e)=>{handleTextMod('observacionGeneral', e.target.value)}} disabled={!modificando} />
                                </Col>:null}
                                {(observaciones.observacionReparacion || modificando) ? <Col xs='12' md='12' lg='12'>
                                    <Label for="ubicacionTuberia" className='mb-0 mt-2 labelcustom'>Observaciones Reparacion:</Label>
                                    <Input style={{backgroundColor:'white'}} type="textarea" className='textareacustom' name="ubicacionTuberia" id="ubicacionTuberia" placeholder="Ubicación tubería" defaultValue={observaciones.observacionReparacion} onChange={(e)=>{handleTextMod('observacionReparacion', e.target.value)}} disabled={!modificando} />
                                </Col> : null}
                                {(observaciones.observacionEND || modificando) ? <Col xs='12' md='12' lg='12'>
                                    <Label for="ubicacionTuberia" className='mb-0 mt-2 labelcustom'>Observaciones Ensayos no Destructivos:</Label>
                                    <Input style={{backgroundColor:'white'}} type="textarea" className='textareacustom' name="ubicacionTuberia" id="ubicacionTuberia" placeholder="Ubicación tubería" defaultValue={observaciones.observacionEND} onChange={(e)=>{handleTextMod('observacionEND', e.target.value)}} disabled={!modificando} />
                                </Col> : null}
                                {(observaciones.observacionAdicional || modificando) ? <Col xs='12' md='12' lg='12'>
                                    <Label for="ubicacionTuberia" className='mb-0 mt-2 labelcustom'>Observaciones Adicionales:</Label>
                                    <Input style={{backgroundColor:'white'}} type="textarea" className='textareacustom' name="ubicacionTuberia" id="ubicacionTuberia" placeholder="Ubicación tubería" defaultValue={observaciones.observacionAdicional} onChange={(e)=>{handleTextMod('observacionAdicional', e.target.value)}} disabled={!modificando} />
                                </Col> : null}
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }else{
            return(<></>);
        }
    }

    return (
        <React.Fragment>
            <br />
            <br />
            <div className={"mb-0 p-3 d-flex " + (props.estado ? 'border rounded-top' : 'border rounded')} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                <Label style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black'}}>OBSERVACIONES</Label>                    
                <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                    {props.authRol ? renderBoton() : null}
                </div>
            </div>
            {obtenerRenderDeDatos({observaciones:observaciones, estado:estado, modificando:modificando})}
        </React.Fragment>
    )
}

export default Observaciones;