import React from 'react';
//Iconos
import { FaListOl } from 'react-icons/fa';
import { Grid } from '@material-ui/core';
import CustomMUIDataTable from '../../../gestion/api581Valvulas/componentes/CustomMUIDataTable';
import LinkConHistory from '../../../gestion/api581Valvulas/componentes/LinkConHistory';

//Componentes:
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';

let TablaGestionDeInformes = (props) => {   
  
  const columnas = [
    {
        name: "id",
        label: "ODT",
        options: {
            filterType: 'textField',
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Grid container >
                        <LinkConHistory to={`/app/formatoDeInspeccion/${value}`} style={{ width: "100%", textAlign: "center" }}>
                            {value}
                        </LinkConHistory>
                    </Grid>
                );
            },
        }
    },
    {
        name: "lineaDeDucto",
        label: "Linea",
        options: {
            display: true,
            viewColumns: false,
            filter: true
        }
    },
    {
        name: "distanciaDeInicio",
        label: "PK",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "fechaEstimadaDeProgramacion",
        label: "Fecha de Inspección",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "preliminar",
        label: "Preliminar/Final",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "nombreDelArchivo",
        label: "Nombre Archivo",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },

    {
        name: "fechaDeCargueHorus",
        label: "Fecha de Cargue Horus",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "preliminar",
        label: "Observaciones",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "tecnico",
        label: "Responsable",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "tipoDeAtencion",
        label: "Tipo de Atención",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "actaDeFinalizacion",
        label: "Acta",
        options: {
            display: true,
            viewColumns: false,
            filter: false
        }
    },
  ]
    return (
        <CardConEncabezado
            titulo={'Formatos de inspección'}
            icono={FaListOl}
        >
            <CustomMUIDataTable
                title={"Gestor de Informes"}
                download={true}
                columns={columnas}
                data={props.data}
                loading={true}
                cargandoEnTitulo={false}
                cargandoEnBody={{ component: "cargandoLabel" }}
            />
        </CardConEncabezado>

  )

}
export default TablaGestionDeInformes