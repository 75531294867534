import { firebaseFunctions, firebaseStorage } from '../../../../../../firebase';

const recalcularPRD = firebaseFunctions.httpsCallable('recalcularPRD');
const exportarConsultas = firebaseFunctions.httpsCallable('exportarConsultas');

export async function cargarImagen(ruta,archivo){
    const storageRef = firebaseStorage.ref();
    return await storageRef.child(ruta).put(archivo)
}

export async function descargarArchivos(carpeta){
    const storageRef = firebaseStorage.ref();
    let promesas = [];
    await storageRef.child(carpeta)
    .list()
    .then(result=>{
        if(result){
            result.items.forEach(item=>{
                promesas.push(descargarUnArchivo(item.location.path));
            })
        }
    })

    const resultado =  await Promise.all(promesas)
    .then(result=>{
        return  result;
    })

    return resultado;
}

async function descargarUnArchivo(ruta){
    const storageRef = firebaseStorage.ref();
    const url = await storageRef.child(ruta).getDownloadURL();
    return {
        'ruta': ruta,
        'url': url
    }
}

export function eliminarUnArchivo(ruta){
    const storageRef = firebaseStorage.ref();
    return  storageRef.child(ruta).delete();
}

export function recalcularNodo(id) {
    return recalcularPRD({ "id": id })
        .then(respuesta => {
            return respuesta;
        })
        .catch(error => {
           return error;
        });
}

export function cambiarModeloDeConsecuencia(id, modelo) {
    return exportarConsultas({ option: "cambiarModeloDeConsecuencia", id, modelo })
}

export default function quitarDeServcio(id) {
    return exportarConsultas({ option: "quitarDeServcio", id })
}