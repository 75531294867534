import values from 'lodash/values';
import set from 'lodash/set';
import max from 'lodash/max';
import isEmpty from 'lodash/isEmpty';
import listaTipoDeEquipo from '../utilidades/tipoDeEquipo';
import find from 'lodash/find';

export default (data) => {
    if (isEmpty(data)) return data
    try {
        set(data, 'parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion',
        {
            ...data.parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion,
            get tInsp() {
                if (!this.fechaDeValoracionRBI) return "-"
                const fechaInsp = (this.fechaUltimaInspeccion === "Sin Inspección" || this.fechaUltimaInspeccion === null) ? this.fechaDeInstalacion : this.fechaUltimaInspeccion
                return (Date.parse(this.fechaDeValoracionRBI).toString() - Date.parse(fechaInsp)) / 31540000000
            }
        })
    set(data, 'parametrosDeEntrada.informacionCalculosDeConsecuenciaCuantitativa.informacionGeneral.potencialDeSobrepresion',
        max(values(data.parametrosDeEntrada.informacionEquipoProtegido.eventosDeSobrepresion))
    );
    set(data, 'parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral',{
        ...data.parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral,
        get tipoDeEquipo() {
            return find(listaTipoDeEquipo,{"Tipo de componente":this.tipoCompAPI581})['Tipo de activo'];
        }
    } 
    );
    } catch (error) {}
    return data
}
