const initialState = {
    // endpointParaCalcularSegmento: "http://localhost:5000/api/API@normaTecnica/valorarSegmento/"
    // endpointParaCalcularSegmento: "https://giacore-back.herokuapp.com/api/API@normaTecnica/valorarSegmento/"

    endpointParaCargarCampo:"https://us-central1-giacore-dev-ns.cloudfunctions.net/cargarCammpo",
    endpointParaCargarSistema:"https://us-central1-giacore-dev-ns.cloudfunctions.net/cargarSistema",
    endpointParaCargarActivo:"https://us-central1-giacore-dev-ns.cloudfunctions.net/cargarActivo",
    endpointParaCargarSegmento:"https://us-central1-giacore-dev-ns.cloudfunctions.net/cargarSegmento",

}

const reducer = (state = initialState, action) => {
    return state;
}

export default reducer;