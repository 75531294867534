
export const crearListaTablaResumenActivo = (data) => {

    let listaResumenActivo = [];
    listaResumenActivo.push(
        {
            '-': {
                label: ' - ', 
                valor:  data?.variablesDeLaInspeccion?.tipoForma?.nombre || 'Forma de activo',
            },
            casco: {
                label: 'Casco', 
                valor:  data?.variablesDeLaInspeccion?.tipoForma?.tipoFormaBody || 'aún sin calcular'
            },
            caps: {
                label: 'Caps',
                valor: data?.variablesDeLaInspeccion?.tipoForma?.tipoFormaHead || 'Aún sin calcular'
            },
            boquillas: {
                label: 'Boquillas',
                valor: data?.variablesDeLaInspeccion?.tipoForma?.tipoFormaHead || 'Aún sin calcular'
            }
        },
        {
            '-': {
                label: ' - ', 
                valor:  data?.informacionGeneral?.materialDeConstruccion?.nombre || 'Materiales de construccion',
            },
            casco: {
                label: 'Casco', 
                valor:  data?.informacionGeneral?.materialDeConstruccion?.materialBody || 'aún sin calcular'
            },
            caps: {
                label: 'Caps',
                valor: data?.informacionGeneral?.materialDeConstruccion?.materialHead || 'Aún sin calcular'
            },
            boquillas: {
                label: 'Boquillas',
                valor: data?.informacionGeneral?.materialDeConstruccion?.materialNozzle || 'Aún sin calcular'
            }
        },
        {
            '-': {
                label: ' - ', 
                valor:  data?.informacionGeneral?.esfuerzo?.nombre || 'Esfuerzo',
            },
            casco: {
                label: 'Casco', 
                valor:  data?.informacionGeneral?.esfuerzo?.esfuerzoBody || 'aún sin calcular'
            },
            caps: {
                label: 'Caps',
                valor: data?.informacionGeneral?.esfuerzo?.esfuerzoHead || 'Aún sin calcular'
            },
            boquillas: {
                label: 'Boquillas',
                valor: data?.informacionGeneral?.esfuerzo?.esfuerzoNozzle || 'Aún sin calcular'
            }
        },
        {
            '-': {
                label: ' - ', 
                valor:  data?.variablesDeLaInspeccion?.tNom?.nombre || 'Espesores nominales',
            },
            casco: {
                label: 'Casco', 
                valor:  data?.variablesDeLaInspeccion?.tNom?.tNomBody || 'aún sin calcular'
            },
            caps: {
                label: 'Caps',
                valor: data?.variablesDeLaInspeccion?.tNom?.tNomHead || 'Aún sin calcular'
            },
            boquillas: {
                label: 'Boquillas',
                valor: data?.variablesDeLaInspeccion?.tNom?.tNomNozzle || 'Aún sin calcular'
            }
        },
        {
            '-': {
                label: ' - ', 
                valor:  data?.variablesDeLaInspeccion?.tMin?.nombre || 'Espesores mínimos',
            },
            casco: {
                label: 'Casco', 
                valor:  data?.variablesDeLaInspeccion?.tMin?.tMinBody || 'aún sin calcular'
            },
            caps: {
                label: 'Caps',
                valor: data?.variablesDeLaInspeccion?.tMin?.tMinHead || 'Aún sin calcular'
            },
            boquillas: {
                label: 'Boquillas',
                valor: data?.variablesDeLaInspeccion?.tMin?.tMinNozzle || 'Aún sin calcular'
            }
        },
    );

    return listaResumenActivo;

}
