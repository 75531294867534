import { toast } from 'react-toastify';

const successToast = (mensaje) => {
        toast(mensaje, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

const errorToast = (mensaje) => {
    toast(mensaje, {
        closeButton: true,
        hideProgressBar: false,
        autoClose: 3000,
        position: 'bottom-right',
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error'
    })
}

export { successToast , errorToast }