//Utilidades de firebase:
import { firebaseStorage, firestoreAuth } from '../../firebase';
import { Observable, throwError } from 'rxjs';

  //GET
  //RETORNA EL URL DE DESCARGA DEL ARCHIVO
  export const descargarArchivo = (filePath) => {
    return new Promise((resolve,reject) =>{
        if(!firestoreAuth.currentUser){
            return reject({"status" : "USUARIO_NO_AUTENTICADO", "message": "El usuario no se ha autenticado"})
        }
    
        //Se define una referencia directamente al archivo
        let storageRef = firebaseStorage.ref(filePath);

        //Peticion para enlace de descarga
        storageRef.getDownloadURL()
        .then(url => {
            return resolve({ "status" : "OK" , "data" : url});
        }).catch(function(error) {
            return reject({"status"  : "ERROR_DESCARGAR_ARCHIVO", "message" : error.message});
        });
    });
}

//GET
//OBTIENE EL LISTADO DE TODOS LOS ARCHIVOS
//ADECUADO PARA COLECCIONES PEQUEÑAS
export const obtenerTodosLosArchivos = (idCompany, collectionName, itemId) => {
    return new Promise((resolve, reject) =>{
        if(!firestoreAuth.currentUser){
            return reject({ "status" : "USUARIO_SIN_AUTENTIFICACION", "message": "El usuario no se ha autenticado"});
        }
        if(!collectionName || !itemId || !idCompany){
            return reject({ "status" : "DATOS_INSUFICIENTES", "message": "Los datos no son suficientes"});
        }

        //Se define referencia al segmento
        let segmentRef = firebaseStorage.ref(`empresas/${idCompany}/${collectionName}/${itemId}`);


        segmentRef.listAll()
        .then(response =>{
            return resolve({ "status" : "OK", "data" : response.items });
        })
        .catch(error => {
            return reject({ "status" : "ERROR_FIREBASE_STORAGE", "message" : error.message });
        });
    });

}

//GET
export const obtenerListadoDeArchivos = (idCompany, collectionName, itemId, pageSize = 100, nextPageToken) => {
    return new Promise((resolve, reject) =>{
        if(!firestoreAuth.currentUser){
            return reject({ "status" : "USUARIO_SIN_AUTENTIFICACION", "message": "El usuario no se ha autenticado"});
        }
        if(!collectionName || !itemId || !idCompany){
            return reject({ "status" : "DATOS_INSUFICIENTES", "message": "Los datos no son suficientes"});
        }

        //Se define referencia al segmento
        let segmentRef = firebaseStorage.ref(`empresas/${idCompany}/${collectionName}/${itemId}`);

        segmentRef.list({ 
            maxResults: pageSize,
            pageToken: nextPageToken
        })
        .then(response =>{
            return resolve({ 
                "status" : "OK",
                 "data" :  {
                     "files" : response.items,
                     "nextPageToken" : response.nextPageToken
                 }
            });
        })
        .catch(error => {
            return reject({ "status" : "ERROR_FIREBASE_STORAGE", "message" : error.message });
        });
    });
}

//CREATE
//CARGA EL ARCHIVO EN FIREBASE STORAGE
export const cargarArchivo =  (file, idCompany, collectionName, itemId) => {
    if(file){
        if(!firestoreAuth.currentUser){
            //Retorna observable unico de error
            return throwError({ "status" : "USUARIO_SIN_AUTENTIFICACION", "message": "El usuario no se ha autentificado"});
        }
        if(!collectionName || !itemId  || !idCompany){
            return throwError({ "status" : "DATOS_INSUFICIENTES", "message": "Los datos no son suficientes"});
        }
        
        //Se define referencia a la raiz
        let storageRef = firebaseStorage.ref();
    
        //Se define ruta del archivo
        let fileRef = storageRef.child(`empresas/${idCompany}/${collectionName}/${itemId}/${file.name}`);
    
        //Se realiza petición
        let uploadTask = fileRef.put(file);

        //Se genera stream para enviar datos de progreso de carga
        return new Observable(observer =>{
            uploadTask.on('state_changed',
                snapshot => {
                    let progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                    progress =  Math.round(progress*10)/10 
                    observer.next({"status": snapshot.state.toUpperCase(), "progress" : progress});
                },
                error => {
                    observer.next({"status" : "ERROR_CARGAR_ARCHIVO", "message": error.message});
                },
                () => {
                    observer.complete();
                }
            );
        });
    }else{
        return throwError({"status" : "ARCHIVO_NO_SELECCIONADO", "message": "No se ha seleccionado ningún archivo"})
    }
}

//DELETE
//ELIMINA EL ARCHIVO EN FIREBASE STORAGE
export const eliminarArchivo = (filePath) => {
    return new Promise((resolve,reject) =>{
        if(!firestoreAuth.currentUser){
            return reject({"status" : "USUARIO_NO_AUTENTICADO", "message": "El usuario no se ha autenticado"})
        }
    
        //Se define una referencia directamente al archivo
        let storageRef = firebaseStorage.ref(filePath);
        //Peticion para eliminacion
        storageRef.delete()
        .then(response => {
            return resolve({"status" : "OK", "message" : "Archivo eliminado exitosamente"})
        }).catch(function(error) {
            return reject({"status" : "ERROR_ELIMINAR_ARCHIVO", "message" : error.message})
        });
    });
}

