import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FaBalanceScaleRight, FaApple } from 'react-icons/fa';

//Componentes auxiliares:
import CardConEncabezado from '../utilidades/componentes/plantillaBase/CardConEncabezado.jsx';
import CustomSection from '../utilidades/componentes/plantillaBase/CustomSection.jsx';

//Estilos base:
import insercorLogo from '../assets/utils/images/logos/giaCore-logo.png';
import BotonParaSection from '../utilidades/componentes/plantillaBase/BotonParaSection.jsx';
import BotonDeCardConEncabezado from '../utilidades/componentes/plantillaBase/BotonDeCardConEncabezado.jsx';

//Utilidades de firebase:
import { firebaseFunctions } from '../firebase.js';

import GraficoCartesianoCompuesto from '../utilidades/componentes/visualizadores/GraficoCartesianoCompuesto';

//Utilidades firebase storage;

//Callable functions a utilizar:
const funcionDeEjemplo = firebaseFunctions.httpsCallable('funcionDeEjemplo');

class PlantillaBase extends React.Component {

    constructor() {
        super();

        this.state = {};
    }

    render() {
        return (
            <CustomSection titulo='Development I&D' icono={insercorLogo}
                subtitulo='Esta es una plantilla base para el desarrollo de GIAcore'
                botonesAdicionales={[<BotonParaSection key='SectionBoton1' />]}>
                <Row>
                    <Col xs='12' md='4' lg='4' xl='4'>
                        <CardConEncabezado
                            icono={FaApple}
                            botonesAuxiliares={[<BotonDeCardConEncabezado key='Boton1' icono={FaBalanceScaleRight} />]}>

                            <div>
                                <p>Soy el contenido de un card con encabezado</p>
                                <div><Button onClick={this.onClickBotonDePruebas}>Botón de pruebas</Button></div>
                            </div>

                        </CardConEncabezado>
                    </Col>

                    <Col xs='12' md='8' lg='8' xl='8'>
                        <CardConEncabezado
                            titulo='Un card con título'
                            icono={FaBalanceScaleRight}>

                            <GraficoCartesianoCompuesto
                                titulo={'Probabilidad vs abscisa [km]'}
                                nodos={require('../vistas/aplicacion/analisis/riesgo/api1160/estaticos/ejemploDeSegmentos.json')}
                                xAxisKey={'variablesDeLaInspeccion.abscisaInicial.valor'}

                                componentesGraficos={[
                                    {
                                        dataKey: 'resultadosDeLaValoracion.probabilidad.climaYFuerzasExternas.pof',
                                        name: 'Agrietamiento por fatiga',
                                        stroke: 'red',
                                        tipo: 'line',
                                        yAxisId: 'left',
                                    },
                                    {
                                        dataKey: 'resultadosDeLaValoracion.probabilidad.dañosPorTerceros.pof',
                                        name: 'Daños por terceros',
                                        stroke: 'red',
                                        tipo: 'line',
                                        yAxisId: 'left',
                                    },
                                    {
                                        dataKey: 'resultadosDeLaValoracion.probabilidad.defectosDeConstruccion.pof',
                                        name: 'Defectos de construcción',
                                        stroke: 'red',
                                        tipo: 'line',
                                        yAxisId: 'left',
                                    },
                                    {
                                        dataKey: 'resultadosDeLaValoracion.probabilidad.defectosDeManufactura.pof',
                                        name: 'Defectos de manufactura',
                                        stroke: 'red',
                                        tipo: 'line',
                                        yAxisId: 'left',
                                    },
                                    {
                                        dataKey: 'resultadosDeLaValoracion.probabilidad.fallaDeEquipos.pof',
                                        name: 'Falla de equipos',
                                        stroke: 'red',
                                        tipo: 'line',
                                        yAxisId: 'left',
                                    },
                                    {
                                        dataKey: 'resultadosDeLaValoracion.probabilidad.operacionesIncorrectas.pof',
                                        name: 'Operaciones incorrectas',
                                        stroke: 'red',
                                        tipo: 'line',
                                        yAxisId: 'left',
                                    }
                                ]}
                            />

                        </CardConEncabezado>
                    </Col>
                </Row>
            </CustomSection>
        );
    }

    //Métodos para renderizado:

    //Métodos operativos:
    onClickBotonDePruebas = () => {
        funcionDeEjemplo({ 'nombre': 'Nicolás' })
            .then(response => { console.log(response); })
            .catch(error => { console.log(error); });
    }
}

export default PlantillaBase;