import React from 'react'

import { Label, Input, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';

let DetalleDeReparaciones = (props) => {
    return (
        <React.Fragment>
            <Breadcrumb>
                <BreadcrumbItem className='BreadcrumbItem-color'>CONDICIÓN DE RECUBRIMIENTO DE TUBERÍA</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col className='ml-xs-0 ' xs='12' md='12' lg='12' >
                    <Row>
                        <Col xs='12' md='6' lg='3'>
                            <Label for="tipo" className='mb-0 mt-2'>Tipo:</Label>
                            <Input type="text" name="tipoCubrimiento" id="tipoCubrimiento" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.tipoCubrimiento} />
                        </Col>
                        <Col xs='12' md='6' lg='3'>
                            <Label for="longitudAnomalia" className='mb-0 mt-2'>Longitud Retirada:</Label>
                            <Input type="text" name="longitudAnomalia" id="longitudA" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.longitudRetirada} />
                        </Col>
                        <Col xs='12' md='6' lg='3'>
                            <Label for="distanciaRegistroInicial" className='mb-0 mt-2'>Distancia Registro Inicial:</Label>
                            <Input type="text" name="distanciaRegistroInicial" id="distanciaRegistroInicial" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.distanciaRegistroInicial} />
                        </Col>
                        <Col xs='12' md='6' lg='3'>
                            <Label for="anchoAnomalia" className='mb-0 mt-2'>Distancia Registro Final:</Label>
                            <Input type="text" name="distanciaRegistroFinal" id="distanciaRegistroFinal" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.distanciaRegistroInicial} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='6' lg='3'>
                            <Label for="potencialTomadoOFF" className='mb-0 mt-2'>Potencial Tomado en OFF:</Label>
                            <Input type="text" name="potencialTomadoOFF" id="potencialTomadoOFF" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.potencialTomadoOFF} />
                        </Col>
                        <Col xs='12' md='6' lg='3'>
                            <Label for="anchoAnomalia" className='mb-0 mt-2'>Potencial Tomado en ON:</Label>
                            <Input type="text" name="potencialTomadoON" id="potencialTomadoON" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.potencialTomadoON} />
                        </Col>
                        <Col xs='12' md='12' lg='6'>
                            <Label for="descripcionODT" className='mb-0 mt-2'>Observaciones y Recomendaciones Generales:</Label>
                            <Input type="textarea" name="descripcionODT" id="descripcionODT" placeholder="Sin asignar" defaultValue={props.keysDeReparacion.observacionesGenerales}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>

            </Row>
        </React.Fragment>
    );
}

export default DetalleDeReparaciones