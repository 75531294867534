import React from 'react';
import '../assets/LaunchView.scss';
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";
import pipe from '../assets/utils/images/error404/oil-pipe-HD.png';
import logo from '../assets/utils/images/logos/giaCore-logo2.png';


function Error404(props) {
    const history = useHistory();

    const routeChange = () => {
        let path = '/app/dashboard/';
        history.push(path);
    }


    return (
        <div style={{ margin: 'auto' }}>
            <Animated animationIn="fadeInDown" animationInDuration={3000}>
            <div className="giacore-logo img-fluid ">
                <img alt= {'logo'} src={logo} style={{ width: 100, display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
            </div>
            </Animated>
            <p style={{ color: "#1e2f52", fontSize: 60, textAlign: 'center', fontWeight:'bold' }}>
                404 OOPS!
            </p>
            <p style={{ color: "#1e2f52", fontSize: 18, textAlign: 'center' }}>
                Lo sentimos, pero la página {props.error} no esta disponible
            </p>
            <Animated animationIn="fadeInDown" animationInDuration={3000}>
                <div className="giacore-logo img-fluid ">
                    <img alt={'pipe-icon'} src={pipe} style={{ width: 350, display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
            </Animated>
            
            <div>
                <Button className="btn-icon btn-icon-only font-icon-wrapper text-primary " color="link" onClick={routeChange}
                    style={{ border: 0, width: '10%', borderColor: 'black', marginLeft:'45%', marginTop:25}} title='Ir a Home'>
                    <div style={{ paddingLeft: 0 }}>
                        <span style={{ fontSize: 18 }}>Volver al Inicio</span>
                    </div>
                </Button>
            </div>   
                     
        </div>
    );
}



export default Error404;
