import React from 'react';
import { BsTable } from 'react-icons/bs';
import Grid from '@material-ui/core/Grid'

//Componentes auxiliares:
import CeldaConModal from './CeldaConModal.jsx';

//Scripts auxiliares:
import { obtenerCantidadEnNotacionCorta } from '../../scripts/paraMoneda';
import CardConEncabezado from '../plantillaBase/CardConEncabezado.jsx';

import { errorToast } from "../../Toastify/Toastify"
import join from 'lodash/join';
import { get } from 'lodash-es';
//Estilos base:
const estilosBase = {
    altoDeFila: 50,
    margenEntreCeldas: 10,
    anchoDeCeldaMaximo: 100,
}
const estiloDeFila = {
    display: 'flex', flexDirection: 'row', justifyContent: 'center',
    marginTop: estilosBase.margenEntreCeldas / 2, marginBottom: estilosBase.margenEntreCeldas / 2
}

class MatrizDeRiesgo extends React.Component {

    constructor() {
        super();

        this.state = {
            matrizLlena: {},
            contadorDeRenders: 0,
        }
    }

    render() {
        let matrizComoLista = this.obtenerFilasDeLaMatriz(this.state.matrizLlena);

        if (matrizComoLista.length > 0) {
            return (<div>
                {!this.props.sinCardEncabezado ? <CardConEncabezado titulo="Matriz de riesgo" icono={BsTable} style={{ paddingRight: 0 }}>
                    {this.renderMatrizDeRiesgo(matrizComoLista)}
                </CardConEncabezado> :<Grid style={{width:"100%"}}>
                    {this.renderMatrizDeRiesgo(matrizComoLista)}</Grid>
                }
            </div>
            );
        }
        else { return (<React.Fragment />); }
    }

    componentDidMount() {
        this.llenarMatrizSegunProps();
    }

    componentDidUpdate(prevProps, prevState) {

        if ((prevProps.listaDeNodos.length !== this.props.listaDeNodos.length)) {
            this.llenarMatrizSegunProps();
        }else{
            let deberiaPintarMatriz = false;
            prevProps.listaDeNodos.forEach((nodo , i) => {
                if(nodo.id !== this.props.listaDeNodos[i].id){
                    deberiaPintarMatriz = true;
                }
            })
            if(deberiaPintarMatriz){
                this.llenarMatrizSegunProps();
            }
        }
    }

    //Métodos operativos:
    llenarMatrizSegunProps = () => {
        const listaDeNodos = this.props.listaDeNodos || [];
        const rangosDeMatriz = {...this.props.parametrizacionCorporativa['rangosMatriz']};
        let matrizLlena = this.obtenerCeldasDeMatrizAsociadasConNodos(listaDeNodos, rangosDeMatriz);
        this.setState({ matrizLlena: matrizLlena, contadorDeRenders: this.state.contadorDeRenders + 1 });
    }

    obtenerCeldasDeMatrizAsociadasConNodos = (nodos, parametrizacionDeMatriz) => {
        let matrizLlena = parametrizacionDeMatriz;
        Object.keys(matrizLlena).forEach((key, i) => { matrizLlena[key] = { ...matrizLlena[key], nodosAsociados: [] }; });
        const arrayDeErrores = []
        let consecuenciaCualitativa, probabilidadCualitativa
        nodos.forEach((nodo, i) => {
            if (this.props.parametrizacionCorporativa.invertir)
            {
                consecuenciaCualitativa = get(nodo,`resultadosDeLaValoracion.probabilidad.probabilidadCualitativa`)
                || get(nodo,`resultados[0].data.probabilidad[${this.props.tipoDeFalla}].nivelDeProbabilidad`);
            probabilidadCualitativa = get(nodo,`resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa`) 
                || get(nodo,`resultados[0].data.consecuencia[${this.props.tipoDeFalla}][${this.props.modeloDeConsecuencia === "Cualitativo" ? "maximaCOF":"nivelDeConsecuenciaFinanciera"}]`)
            }
            else{
                probabilidadCualitativa = get(nodo,`resultadosDeLaValoracion.probabilidad.probabilidadCualitativa`)
                || get(nodo,`resultados[0].data.probabilidad[${this.props.tipoDeFalla}].nivelDeProbabilidad`);
                consecuenciaCualitativa = get(nodo,"resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa") 
                || get(nodo,`resultados[0].data.consecuencia[${this.props.tipoDeFalla}][${this.props.modeloDeConsecuencia === "Cualitativo" ? "maximaCOF":"nivelDeConsecuenciaFinanciera"}]`)
            }
            try {matrizLlena[consecuenciaCualitativa + ',' + probabilidadCualitativa].nodosAsociados.push(nodo); }
            catch (error) { 
                arrayDeErrores.push(nodo.nombre)
                console.log(error, nodo);
            }
        });
        const countErrores = arrayDeErrores.length
        this.props.isPRD && countErrores && errorToast(`En matriz de ${this.props.tipoDeFalla} ${countErrores > 1 ? "los PRD's" : "el PRD"} ${join(arrayDeErrores)} contiene${countErrores>1?"n":""} errores en su RBI`)
        return matrizLlena;
    }

    obtenerFilasDeLaMatriz = (matrizLlena) => {
        let posiblesValoresEnX = Object.keys(matrizLlena).map((key, i) => { return key.split(',')[0]; });
        posiblesValoresEnX = [...new Set(posiblesValoresEnX)];
        posiblesValoresEnX.sort((a, b) => a < b ? -1 : 1);

        let posiblesValoresEnY = Object.keys(matrizLlena).map((key, i) => { return key.split(',')[1]; });
        posiblesValoresEnY = [...new Set(posiblesValoresEnY)];
        posiblesValoresEnY.sort((a, b) => a < b ? -1 : 1);

        let celdasDeLaMatriz = Object.keys(matrizLlena).map((key, i) => {
            return { valorEnY: key.split(',')[1] };
        });

        let filasDeLaMatriz = posiblesValoresEnY.map((valorEnY, i) => {
            return celdasDeLaMatriz.find(item => item.valorEnY === valorEnY);
        });

        for (let i = 0; i < filasDeLaMatriz.length; i++) {
            filasDeLaMatriz[i].celdas = [];

            posiblesValoresEnX.forEach((valorEnX, k) => {
                filasDeLaMatriz[i].celdas.push({
                    valorEnX: valorEnX,
                    ...matrizLlena[valorEnX + ',' + filasDeLaMatriz[i].valorEnY],
                });
            })
        }
        return filasDeLaMatriz;
    }

    obtenerRangoFormateadoEnMoneda = (rango, invertir, eje) => {
        let intervaloInicio = rango[0];
        let intervaloFin = rango[rango.length - 1];

        rango = rango.replace("(", "");
        rango = rango.replace("[", "");
        rango = rango.replace(")", "");
        rango = rango.replace("]", "");

        let valorInicial = rango.split(", ")[0];
        let valorFinal = rango.split(", ")[1];

        // let valorInicialFormateado = valorInicial
        // let valorFinalFormateado = valorFinal
        let valorInicialFormateado = (invertir && eje==='X')?valorInicial:obtenerCantidadEnNotacionCorta(parseFloat(valorInicial), false);
        let valorFinalFormateado = (invertir && eje==='X')?valorFinal:obtenerCantidadEnNotacionCorta(parseFloat(valorFinal), false);

        if (valorFinal > 1 * 1000 * 1000000) { valorFinalFormateado = "∞"; intervaloFin = ")"; }

        return (intervaloInicio + valorInicialFormateado + ", " + valorFinalFormateado + intervaloFin);
    }

    //Métodos para renderizado:
    obtenerFilaDeEjeX = (rangosDelEje, prefijo, invertir) => {
        
        let arrayDeRangosOrdenado = [];
        let objetoDeRangosOrdenado = {};

        for (const key in rangosDelEje) {
            arrayDeRangosOrdenado.push({
                ...rangosDelEje[key],
                key: key
            });
        }

        arrayDeRangosOrdenado = arrayDeRangosOrdenado.sort((a, b) => (a.valorCualitativo > b.valorCualitativo) ? 1 : -1);

        arrayDeRangosOrdenado.forEach((rango, i) => {
            objetoDeRangosOrdenado[rango.key] = rango;
        });

        let celdasDelEje = Object.keys(objetoDeRangosOrdenado).map((rango, i) => {
            return (
                <div key={"labelDelEjeX." + i}
                    style={{
                        maxWidth: estilosBase.anchoDeCeldaMaximo, alignItems: 'center',
                        flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column',
                        marginLeft: estilosBase.margenEntreCeldas / 4, marginRight: estilosBase.margenEntreCeldas / 4,
                    }}>
                    <div style={{ flex: 1 }}>
                        <p className="table-header" style={{ fontSize: 10, color: 'rgb(75,75,75)' }}>
                            {prefijo + " " + objetoDeRangosOrdenado[rango].etiqueta}
                        </p>
                    </div>

                    <div style={{ flex: 1 }}>
                        <p className="table-header" style={{ fontSize: 8 }}>
                            {invertir?this.obtenerRangoFormateadoEnMoneda(rango, true, "X"):"("+obtenerCantidadEnNotacionCorta(parseInt(rango.split(",")[0].replace("(","")))+","+obtenerCantidadEnNotacionCorta(parseInt(rango.split(",")[1].replace("]","")))+"]"}
                        </p>
                    </div>
                </div>
            );
        });

        celdasDelEje.push(<div key={"labelDelEjeX.blank"} style={{ flex: 1, maxWidth: estilosBase.anchoDeCeldaMaximo }} />);
        
        return (
            <div style={{ ...estiloDeFila }}>
                {celdasDelEje}
            </div>
        );
    }

    obtenerFilaRenderizada = (fila, parametrizacionCorporativa) => {
        let celdasRenderizadas = fila.celdas.map((celda, i) => {
            return (
                <CeldaConModal
                    isPRD={this.props.isPRD||false}
                    tipoDeFalla={this.props.tipoDeFalla}
                    coleccionActiva={this.props.coleccionActiva}
                    estilosBase={estilosBase} celda={celda} fila={fila}
                    moduloTarget={this.props.moduloTarget}
                    parametrizacionCorporativa={this.props.parametrizacionCorporativa}
                    key={"filaDeMatriz." + fila.valorEnY + "." + celda.valorEnX + "." + this.state.contadorDeRenders} />
            );
        });

        let rangosAsociados = parametrizacionCorporativa.invertir?parametrizacionCorporativa['rangosDeConsecuencia']:parametrizacionCorporativa['rangosDeProbabilidad'];
        rangosAsociados = Object.keys(rangosAsociados).map((key, i) => { return { ...rangosAsociados[key], rango: key } });

        let rangoTarget = rangosAsociados.find(item => item.valorCualitativo === parseInt(fila.valorEnY));
        let rangoInferior = rangoTarget.rango.split(", ")[0];
        let rangoSuperior = rangoTarget.rango.split(", ")[1];

        if (rangoInferior === "(0") { rangoInferior = "(0,00000"; };
        if (rangoSuperior === "1]") { rangoSuperior = "1,00000]"; };
        celdasRenderizadas.push(
            <div key={"labelDelEjeY." + fila.valorEnY}
                style={{
                    maxWidth: estilosBase.anchoDeCeldaMaximo, alignItems: 'center',
                    flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column',
                    marginLeft: estilosBase.margenEntreCeldas / 4, marginRight: estilosBase.margenEntreCeldas / 4,
                }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                    <p className="table-header" style={{ fontSize: 10, color: 'rgb(75,75,75)' }}>
                        {this.props.parametrizacionCorporativa.invertir?"CoF " + fila.valorEnY:"PoF " + fila.valorEnY}
                    </p>
                </div>

                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className="table-header" style={{ fontSize: 8 }}>
                        {
                            !this.props.parametrizacionCorporativa.invertir?
                            this.obtenerRangoFormateadoEnMoneda(rangoTarget.rango, true, "Y") :"("+
                            obtenerCantidadEnNotacionCorta(parseInt(rangoInferior.replace('(',''))) + ", "
                        }
                    </p>
                    <p className="table-header" style={{ fontSize: 8 }}>
                        {
                            !this.props.parametrizacionCorporativa.invertir? 
                            null :
                            obtenerCantidadEnNotacionCorta(parseInt(rangoSuperior.replace(']','')))+"]"
                        }
                    </p>
                </div>
            </div>
        );

        return celdasRenderizadas;
    }

    obtenerMatrizDeRiesgoRenderizada = (filasDeLaMatriz, parametrizacionCorporativa) => {
        let filasRenderizadas = [];


        for (let i = filasDeLaMatriz.length - 1; i >= 0; i--) {
            filasRenderizadas.push(
                <div key={"filaDeMatriz." + filasDeLaMatriz[i].valorEnY + "." + this.state.contadorDeRenders} style={{ ...estiloDeFila }}>
                    {this.obtenerFilaRenderizada(filasDeLaMatriz[i],parametrizacionCorporativa)}
                </div>
            );
        }

        return filasRenderizadas;
    }
    renderMatrizDeRiesgo = (matrizComoLista) => {
        return (
        <div style={{ width: '100%' }}>
            {this.obtenerMatrizDeRiesgoRenderizada(matrizComoLista, this.props.parametrizacionCorporativa)}
            {this.props.parametrizacionCorporativa.invertir?this.obtenerFilaDeEjeX(this.props.parametrizacionCorporativa['rangosDeProbabilidad'], 'PoF', true):this.obtenerFilaDeEjeX(this.props.parametrizacionCorporativa['rangosDeConsecuencia'], 'CoF', false)}
        </div>
    )
    }

    
}

export default MatrizDeRiesgo;