import React from 'react';
import { connect } from 'react-redux';
//iconos:
import { GrUserAdmin } from 'react-icons/gr';
import { FaPlus } from 'react-icons/fa';

//componentes:
import CustomSection from '../../../utilidades/componentes/plantillaBase/CustomSection';
import ListaUsuariosPermitidos from './componentes/ListaUsuariosPermitidos';
import ListaUsuariosLogueados from './componentes/ListaUsuariosLogueados';
import ModalDetalleUsuario from './componentes/ModalDetalleUsuario';
import DetalleDeDominioCorporativo from './componentes/DetalleDeDominioCorporativo';
import BotonParaSection from '../../../utilidades/componentes/plantillaBase/BotonParaSection';
import CustomModal from '../../../utilidades/componentes/plantillaBase/CustomModal';
import ContenidoModalAgregarUsuarios from './componentes/ContenidoModalAgregarUsuarios';
import ContenidoModalEditarUsuarios from './componentes/ContenidoModalEditarUsuarios';

//imagenes de fondo Cards:
import { bgHorizontales } from '../../../utilidades/almacen/bancoDeImagenes';
//swal: 
import Swal from 'sweetalert2';
import { confirmacionDeEliminarUsuario, mensajeUsuarioEliminadoExitosamente, mensajeUsuarioEliminadoError } from '../../../utilidades/almacen/alertasParaSwal';
//reacstrap:
import { Button, Card, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
//llamada a base de datos:
import { firestoreDB } from '../../../firebase';
const firebase = require('firebase/app');
require('firebase/firestore');

const backgroundImageUrl = bgHorizontales[0];

class VisualizadorAdministradorDeUsuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listaDeUsuariosActivos: [],
            listaDeUsuariosPermitidos: [],
            parametrizacionEmpresa: {},
            usuarioSeleccionado: {},
            datosUsuariosPermitidos: undefined,
            elModalAgregarEstaAbierto: false,
            elModalEditarEstaAbierto: false,
            elModalDetalleEstaAbierto: false,
            nombre: '',
            correo: '',
            dominio: '',
            rol: '',
        };

        this.unsubscribe = undefined;
    }

    componentDidMount() {
        this.obtenerListaUsuariosPermitidos()
    }

    componentWillUnmount(){
        this.unsubscribe();
    }
    
    render() {
        if (this.state.listaDeUsuariosActivos !== undefined) {
            return (
                <CustomSection
                    titulo='Administrador de usuarios'
                    icono={GrUserAdmin}
                    botonesAdicionales={[
                        <BotonParaSection key="BotonAgregarUsuario" icono={<FaPlus />} onClick={this.toggleDelModalAgregar} title={'Agregar'} />
                    ]}
                >
                    <CustomModal
                        elModalEstaAbierto={this.state.elModalAgregarEstaAbierto}
                        toggleDelModal={this.toggleDelModalAgregar}
                        titulo={
                            <span className='h5 font-weight-bold'>Agregar usuario</span>
                        }
                        size="lg"
                        hideFooter={true}
                    >
                        <ContenidoModalAgregarUsuarios
                            dominio={this.state.parametrizacionEmpresa.dominioCorporativo}
                            idEmpresa={this.props.match.params.id}
                        />
                    </CustomModal>
                    <CustomModal
                        elModalEstaAbierto={this.state.elModalEditarEstaAbierto}
                        toggleDelModal={this.toggleDelModalEditar}
                        titulo="Editar Usuario Usuario"
                        size="lg"
                    >
                        <ContenidoModalEditarUsuarios
                            nombre={this.state.nombre}
                            correo={this.state.correo}
                            dominio={this.state.dominio}
                            rol={this.state.rol}
                        />
                    </CustomModal>
                    <ModalDetalleUsuario
                          elModalEstaAbierto={this.state.elModalDetalleEstaAbierto}
                          toggleDelModal={this.toggleDelModalDetalle}
                          usuario={this.state.usuarioSeleccionado}
                    />
                    <Row className='mx-2'>
                        <Col xs='12' xl='6'>
                            <DetalleDeDominioCorporativo {...this.state.parametrizacionEmpresa}/>
                            <ListaUsuariosLogueados
                                usuarios={this.state.listaDeUsuariosActivos}
                            />
                        </Col>
                        <Col xs='12' xl='6'>
                            <ListaUsuariosPermitidos
                                usuarios={this.state.listaDeUsuariosPermitidos}
                                verDetalle={(usuario) => {
                                    this.setState({
                                        usuarioSeleccionado: usuario,
                                        elModalDetalleEstaAbierto: true
                                    });
                                }}
                            />
                        </Col>
                    </Row> 
                </CustomSection>
            )
        } else {
            return (<React.Fragment></React.Fragment>)
        }
    }

    obtenerListaUsuariosPermitidos = () => {
        const id = this.props.match.params.id??this.props.usuarioActivo.companyId;
        firestoreDB.collection('empresas').doc(id)
            .onSnapshot(snapShot => {
                const parametrosEmpresarial = snapShot.data();
                this.obtenerListaUsuariosConRol(parametrosEmpresarial)
            })
    }

    obtenerListaUsuariosConRol = (parametrosEmpresarial) => {
        this.unsubscribe =  firestoreDB.collection('usuarios').where('dominioAlQuePertenece', '==', parametrosEmpresarial.dominioCorporativo)
            .onSnapshot(snapShots => {
                let usuariosPermitidos = [];
                let usuariosActivos = [];
                snapShots.forEach(snapShot => {
                try {
                    const usuarioPermitido = parametrosEmpresarial.listaDeUsuariosPermitidos.find(correo => correo === snapShot.data().correo);
                    const usuarioActivo = parametrosEmpresarial.listaDeUsuarios.find(correo => correo === snapShot.data().correo);
                    if(usuarioPermitido){
                        usuariosPermitidos.push({...snapShot.data(), id: snapShot.id})
                    }

                    if(usuarioActivo){
                        usuariosActivos.push({...snapShot.data(), id: snapShot.id})
                    }

                    usuariosPermitidos = usuariosPermitidos.sort((a,b) => {
                        if(a.nombre < b.nombre) { return -1; }
                        if(a.nombre > b.nombre) { return 1; }
                        return 0;
                    });

                    usuariosActivos = usuariosActivos.sort((a,b) => {
                        if(a.nombre < b.nombre) { return -1; }
                        if(a.nombre > b.nombre) { return 1; }
                        return 0;
                    });
                }
                catch (error) { console.log(error) }
            });

            this.setState({
                listaDeUsuariosPermitidos: usuariosPermitidos,
                listaDeUsuariosActivos: usuariosActivos,
                parametrizacionEmpresa: parametrosEmpresarial
            })
        })
    }

    obtenerDatosUsuariosPermitidos = () => {
        let usuariosPermitidos = this.state.listaDeUsuariosPermitidos;
        let usuarios = this.state.listaDeUsuarios;
        let datosUsuariosPermitidos = []
        usuarios.forEach(item => {
            usuariosPermitidos.forEach(usuario => {
                if (item.correo === usuario.correo) {
                    datosUsuariosPermitidos.push({
                        nombre: item.nombre,
                        rol: item.rol,
                        correo: item.correo
                    })
                }
            })
        })
        let cards = [];
        datosUsuariosPermitidos.forEach((usuario, i) => {
            cards.push(
                    <Col sm="12" md='6' lg="6" xl="3" key={`cardDeUsuario${i}`}>
                        <Card className="mb-3 profile-responsive">
                            <div className="dropdown-menu-header" >
                                <div className="dropdown-menu-header-inner bg-dark">
                                    <div className="menu-header-image opacity-2"
                                        style={{
                                            backgroundImage: "url('" + backgroundImageUrl + "')",
                                            backgroundPositionX: "center", backgroundPositionY: "top", backgroundSize: "cover"
                                        }}
                                    />
                                    <div className="menu-header-content btn-pane-right">
                                        <div className="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
                                            <div className="avatar-icon rounded">
                                                {/* <img src={avatar1} alt="Avatar 5"/> */}
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className="menu-header-title">{usuario.nombre}</h5>
                                            <h6 className="menu-header-subtitle">{this.transformarDatos(usuario.rol)}</h6>
                                        </div>
                                        <div className="menu-header-btn-pane">
                                            <Button color="success" 
                                                onClick={()=>{
                                                    this.generarModalEditarPerfil(usuario.nombre, usuario.correo, usuario.rol, this.props.usuarioActivo.dominioCorporativo)
                                                    this.toggleDelModalEditar()
                                                }}>
                                                Editar Perfil
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ListGroup flush>
                                <ListGroupItem>
                                    <div className="widget-content pt-1 pl-0 pr-0">
                                        <div className="text-center">
                                            <h5 className="widget-heading opacity-4 mb-0">
                                                {usuario.correo}
                                            </h5>
                                            <h6 className="mt-3 mb-3">
                                                <span className="pr-2">
                                                    <b className="text-danger">12</b> Inspecciones realizadas,
                                                </span>
                                                <span>
                                                    En el año<b className="text-success"> 2020</b> 
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem className="p-0">
                                    <div className="grid-menu grid-menu-2col">
                                        <Row className="no-gutters">
                                            <Col sm="6">
                                                <Button
                                                    className="btn-icon-vertical btn-square btn-transition br-bl"
                                                    outline color="primary">
                                                    <i className="lnr-license btn-icon-wrapper btn-icon-lg "> </i>
                                                    Ver perfil
                                                </Button>
                                            </Col>
                                            <Col sm="6">
                                                <Button
                                                    className="btn-icon-vertical btn-square btn-transition br-br"
                                                    outline color="danger" onClick={() =>this.eliminarUsuario(usuario.correo, usuario.nombre)}>
                                                    <i className="lnr-trash btn-icon-wrapper btn-icon-lg "> </i>
                                                    Eliminar Perfil
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
            )
        })
        return cards
    }

    transformarDatos = (dato) => {
        switch(dato){
            case 'tecnico':
                return 'Técnico'
            default:
                return (<React.Fragment></React.Fragment>)
        }
    }

    toggleDelModalAgregar = () => {
        this.setState({elModalAgregarEstaAbierto: !this.state.elModalAgregarEstaAbierto})
    }
    toggleDelModalEditar = () => {
        this.setState({elModalEditarEstaAbierto: !this.state.elModalEditarEstaAbierto})
    }
    toggleDelModalDetalle = () => {
        this.setState({elModalDetalleEstaAbierto: !this.state.elModalDetalleEstaAbierto})
    }

    generarModalEditarPerfil = (nombre, correo, rol, dominio) => {
        this.setState({
            nombre,
            correo,
            rol,
            dominio
        })
    }

    eliminarUsuario = (correo, nombre) => {
        Swal.fire(confirmacionDeEliminarUsuario(nombre))
        .then(result => {
            if (result.value) {
                    let promiseEliminarUsuario = [];
                    promiseEliminarUsuario.push(firestoreDB.collection('usuarios').doc(correo).delete())
                    promiseEliminarUsuario.push(firestoreDB.doc(`empresas/${this.props.usuarioActivo.companyId}`).update({
                        listaDeUsuariosPermitidos: firebase.firestore.FieldValue.arrayRemove(correo)
                    }))
                    Promise.all(promiseEliminarUsuario)
                .then(() => {
                    Swal.fire(mensajeUsuarioEliminadoExitosamente)
                })
                .catch(() => {Swal.fire(mensajeUsuarioEliminadoError)})
            }
        })
        
    }

}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorAdministradorDeUsuarios);