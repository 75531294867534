import { firebaseFunctions } from "../../../../../../firebase"
import { errorToast } from "../../../../../../utilidades/Toastify/Toastify"


const exportarConsultas = firebaseFunctions.httpsCallable('exportarConsultas');


export async function obtenerSoloUnPRD(id, conLogDeCalculos = false) {
    return exportarConsultas({ 
        option: "obtenerUnSoloPRD", 
        conLogDeCalculos, 
        id, 
        getSubCollectionsAsArray: true 
    }).then((response) => response.data)
        .catch(() => {
            errorToast("Error obteniendo información de PRD", "error")
            throw new Error("Error obteniendo información de PRD")
        })
}