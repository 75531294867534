import React from 'react';

import cx from 'classnames';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Componentes auxiliares:
import Logo from './LogoPrincipal.jsx';
import MenuDeUsuario from './MenuDeUsuario.jsx';
import BotonIrAtras from './BotonIrAtras.jsx';

// import SearchBox from './Components/SearchBox';
// import MegaMenu from './Components/MegaMenu';
// import UserBox from './Components/UserBox';
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";
// import HeaderDots from "./Components/HeaderDots";

class Header extends React.Component {

    render() {
        let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = this.props;

        return (
            <React.Fragment>
                <ReactCSSTransitionGroup component="div" className={cx("app-header", headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
                    transitionName="HeaderAnimation" transitionAppear={true} transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>

                    <Logo />
                    <div className={cx("app-header__content", { 'header-mobile-open': enableMobileMenuSmall })}>
                        <div className="app-header-left">
                            <BotonIrAtras/>
                            {/* <SearchBox />
                            <MegaMenu /> */}
                        </div>
                        <div className="app-header-right">
                            <MenuDeUsuario />
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.reducerParaTema.enableHeaderShadow,
    closedSmallerSidebar: state.reducerParaTema.closedSmallerSidebar,
    headerBackgroundColor: state.reducerParaTema.headerBackgroundColor,
    enableMobileMenuSmall: state.reducerParaTema.enableMobileMenuSmall,
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);