import React from 'react';
//diseño
import { CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import { GoChecklist } from 'react-icons/go';
import PerfectScrollbar from 'react-perfect-scrollbar';
//componentes
import CardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import Swal from 'sweetalert2';
//estáticos
import { confirmacionDeActualizacionDeComponentesDeCalculo } from '../../../../../../utilidades/almacen/alertasParaSwal'

const objectPath = require("object-path");

class CardDeComponentesACalcular extends React.Component {

    constructor() {
        super();

        this.state = {
            hayCambiosPendientes: false
        }
    }

    render() {
        return (
            <CardConEncabezado titulo={this.props.titulo} icono={GoChecklist}>
                <PerfectScrollbar>
                    <ListGroup className="todo-list-wrapper" flush>
                        {this.crearCheckboxes()}
                    </ListGroup>
                </PerfectScrollbar>
            </CardConEncabezado>
        )
    }

    componentWillMount = () => {
        this.checkSeleccionado = new Set();
        this.props.componentesDisponibles.forEach((componenteDeCalculo) => {
            this.checkSeleccionado.add(componenteDeCalculo)
        })
    }

    actualizarCheck = (nuevoCheck) => {
        Swal.fire(confirmacionDeActualizacionDeComponentesDeCalculo)
            .then(result => {
                if (result.isConfirmed) {
                    this.props.actualizarComponentesDeCalculo({
                        ...nuevoCheck,
                        valor: (nuevoCheck.checked) ? "Si" : "No"
                    });
                }
                return result.isConfirmed
            });
    }

    crearListaDeCheckbox = (componenteDeCalculo) => {
        return (
            <ListGroupItem key={componenteDeCalculo.nombre}>
                <div className="todo-indicator  bg-focus" />
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-2">
                            <CustomInput
                                type='checkbox'
                                id={componenteDeCalculo.nombre}
                                label="&nbsp;"
                                disabled={!componenteDeCalculo.editable}
                                onChange={(e) => {
                                    this.actualizarCheck({
                                        ...componenteDeCalculo,
                                        checked: e.target.checked
                                    })
                                }}
                                defaultChecked={this.obtenerValorDefault(componenteDeCalculo)}
                            />
                        </div>
                        <div className="widget-content-left">
                            <div className="widget-heading">
                                {componenteDeCalculo.nombre}
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        );
    }

    crearCheckboxes = () => {
        return this.props.componentesDisponibles.map((componenteDeCalculo) => {
            return this.crearListaDeCheckbox(componenteDeCalculo)
        });
    }

    obtenerValorDefault = (componenteDeCalculo) => {
        if (!componenteDeCalculo.editable) {
            return true;
        }
        else {
            return objectPath.get(this.props.nodo, componenteDeCalculo.key) === "Si";
        }
    }
}

export default CardDeComponentesACalcular;