import React from 'react';

import { Button, CardImg, Card, Col, Row, CardImgOverlay, Spinner } from 'reactstrap';
import { firebaseStorage, firestoreDB } from "../../../../../firebase";
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal'
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader'
import { MdAdd } from "react-icons/md";

//Componentes Auxiliares
import BotonCallToAction from "../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

//iconos
import { BsXCircleFill } from "react-icons/bs";
import { FaListUl } from 'react-icons/fa';
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import Swal from 'sweetalert2';
import { mensajeConfirmacionEliminar } from '../../../../../utilidades/almacen/alertasParaSwal';

class GaleriaFotograficaIntegridad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detalleIntegridad: undefined,
            listaDeUrl: [],
            listaDeImagenes: [],
            modalAbierto: false,
            imagenAEliminar: undefined,
            cargando: false
        }
        this.cargarImagen = React.createRef();
        this.unsuscribeImagen = undefined;
    }

    render() {
        return (
            <React.Fragment>
                <CardConEncabezado 
                                titulo={`Fotos inspección visual`} 
                                icono={FaListUl}
                                botonesAuxiliares={[this.generarBotones()]}
                                >
                </CardConEncabezado>

                <input ref={this.cargarImagen} type="file" style={{ display: "none" }}
                    onChange={(e) => { this.guardarImagen(e) }} />
                    {
                        this.state.listaDeImagenes.length > 0 ?
                            <div className="p-3 shadow-none border rounded-bottom">
                                <Row>
                                    {
                                        this.state.listaDeImagenes ?
                                            this.state.listaDeImagenes
                                            : <React.Fragment>
                                                <Spinner animation="border" />
                                            </React.Fragment>
                                    }
                                </Row>
                            </div>
                            : <React.Fragment></React.Fragment>

                    }
                <CustomModal
                    titulo={"Eliminar imagen"}
                    elModalEstaAbierto={this.state.modalAbierto}
                    toggleDelModal={this.toggleDelModalBS}
                    children={this.childrenModal()}
                    size={"sm"}
                    botonesParaElFooter={this.botonesDeAccionParaModal()}
                />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>

        )
    }

    componentDidMount() {
        this.mostrarImagenes();
    }

    componentWillUnmount() {
        this.unsuscribeImagen();
    }

    toggleDelModalBS = () => {
        this.setState({
            modalAbierto: !this.state.modalAbierto
        })
    }

    generarBotones= () => {
        return(
            <BotonCallToAction key={'btn_integridad'}
            usuarioActivo={this.props.usuarioActivo}
            estilos={'btn-add-image-integridad'}
            icono={MdAdd}
            titulo={'Agregar Imagen'}
            onClick={() => this.cargarImagen.current.click()}
            />
        )
    }

    childrenModal = () => {
        return (
            <React.Fragment>
                <p>
                    ¿Está seguro que desea eliminar la imagen?
                </p>
            </React.Fragment>
        )
    }

    botonesDeAccionParaModal = () => {
        return (
            <Row>
                <Col>
                    <Button className="btn btn-danger"
                        onClick={() => {
                            this.setState({ cargando: true }, () => {
                                this.eliminarImagen();
                                this.toggleDelModalBS();
                            })
                        }}
                    >Eliminar</Button>
                </Col>
            </Row>
        )
    }

    guardarImagen = (imagen) => {
        const newImagen = imagen.target.files[0];
        if (newImagen) {
            this.setState({ cargando: true });
            firebaseStorage.ref(`pictures/${this.props.idActivoIntegridad}/${"img_" + newImagen.name}`).put(newImagen)
                .then(() => {
                    let detalleIntegridad = this.state.detalleIntegridad;
                    // let listaImagenes = [];

                    if(!detalleIntegridad.imagenesDeLocalizacion){
                        detalleIntegridad.imagenesDeLocalizacion = [];
                    }

                    detalleIntegridad.imagenesDeLocalizacion.push(`pictures/${this.props.idActivoIntegridad}/${"img_" + newImagen.name}`);
                    return firestoreDB.collection('activos').doc(this.state.idActivoIntegridad)
                        .collection('resultados/API570/registrosDeInspeccion')
                        .doc('registroFotografico')
                        .set({ "registro": detalleIntegridad }, { merge: true }).then(() => this.setState({ cargando: false}));
                })
                .catch(e => {
                    this.setState({ cargando: false });
                    console.log('error al guardar la imagen: ', e);
                })
        }
    }

    mostrarImagenes = () => {
        this.unsuscribeImagen = firestoreDB.collection('activos').doc(this.props.idActivoIntegridad)
            .collection('resultados/API570/registrosDeInspeccion').doc('registroFotografico')
            .onSnapshot(snapshot => {
                if (snapshot) {
                    const registroFotografico = snapshot.data();
                    let imagenes = [];
                    if(registroFotografico !== undefined && registroFotografico.registro !== undefined){
                        if (registroFotografico.registro && registroFotografico.registro.imagenesDeLocalizacion) {
                            imagenes = registroFotografico.registro.imagenesDeLocalizacion;
                        }
                    }
            
                    if (imagenes && registroFotografico !== undefined) {
                        const newList = imagenes.map(item => this.obtenerImagenes(item));

                        Promise.all(newList)
                            .then(res => {
                                let listImg = Object.keys(res).map(index => {
                                    return (
                                        <Col xs='12' md='6' lg='4' key={res[index].identificador + "col"}>
                                            <Card className={"bg-royal"} style={{ marginTop: 15, height: 320 }}>
                                                <CardImg key={res[index].identificador} top width="100%" style={{ height: 320 }} src={res[index].url} alt={res[index].url} />
                                                <CardImgOverlay style={{ padding: 1 }}>
                                                    <div style={{ float: 'right', margin: 3 }}>
                                                        <BsXCircleFill
                                                            size={20} color={"white"}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    // modalAbierto: true,
                                                                    imagenAEliminar: res[index].identificador
                                                                });
                                                                Swal.fire(mensajeConfirmacionEliminar(res[index].url)).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        this.setState({ cargando: true }, () => {
                                                                            this.eliminarImagen();
                                                                        })
                                                                    } 
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </CardImgOverlay>
                                            </Card>
                                        </Col>
                                    )
                                })
                                this.setState({
                                    idActivoIntegridad : this.props.idActivoIntegridad,
                                    detalleIntegridad: registroFotografico.registro,
                                    listaDeImagenes: listImg,
                                    cargando: false
                                })
                            })

                    } else {
                        return (
                            <React.Fragment>
                                <Spinner animation="border">

                                </Spinner>
                            </React.Fragment>
                        )
                    }
                }
            })
    }

    obtenerImagenes = async (nombreImagen) => {
        let dataImg = await firebaseStorage.ref().child(nombreImagen).getDownloadURL();
        return { "url": dataImg, "identificador": nombreImagen };
    }

    eliminarImagen = () => {
        const imagen = this.state.imagenAEliminar
        firebaseStorage.ref().child(imagen)
            .delete()
            .then(() => {
                let detalleIntegridad = this.state.detalleIntegridad;
                const nuevoListadoDeImagenes = detalleIntegridad.imagenesDeLocalizacion.filter(item => item !== imagen);

                detalleIntegridad.imagenesDeLocalizacion = nuevoListadoDeImagenes;
                
                return firestoreDB.collection('activos').doc(this.state.idActivoIntegridad)
                    .collection('resultados/API570/registrosDeInspeccion')
                    .doc('registroFotografico')
                    .set({ "registro": detalleIntegridad }, { merge: true }).then(() => {
                        this.setState({cargando: false})
                    });
            })
            .then(() =>{
            })
            .catch((e) => {
                this.setState({ cargando: false })
                console.log('Error al eliminar la imagen: ', e)
            })
    }
}



export default GaleriaFotograficaIntegridad;