import React from 'react';
import { Progress } from 'reactstrap';

//Estilos base:
const estiloDelTitulo = { fontSize: 12, marginBottom: 2, textAlign: "left" };

let ProgressBarDeCarga = (props) => {
    let colorDeLaBarra = "primary";
    if (props.fichero.porcentajeCompletado >= 100) { colorDeLaBarra = "success" };
    if (props.fichero.status === "ERROR") { colorDeLaBarra = "danger" };
    
    return (
        <div>
            <p style={{ ...estiloDelTitulo }}>
                <b style={{ marginRight: 5 }}>{props.fichero.archivo.name}</b>
                {"( " + props.fichero.porcentajeCompletado + "% )"}
            </p>
            <Progress striped animated color={colorDeLaBarra}
                value={props.fichero.porcentajeCompletado}
                className="mb-3 progress-bar-animated-alt" />
        </div>
    );
}

export default ProgressBarDeCarga;