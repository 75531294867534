import { Button, Fab, Grid, ListItemText, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { RViewer, RViewerTrigger } from 'react-viewerjs';

import {
    CardImg,
    Card,
    Col,
    Row,
    Badge
} from 'reactstrap';
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal';
import { RiCloseCircleLine } from "react-icons/ri";
import { GiValve } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    },
    btnVerTodo: {
        width: '33%',
        margin: theme.spacing(1),
    },
    btnAgregar: {
        width: '33%',
        margin: theme.spacing(1),
    },
    badge: {
        margin: theme.spacing(1)
    }
}))

export default function GaleriaDeFotos(props) {
    const inputRef = useRef();
    const [openModal, setStateModal] = useState(false);
    const classes = useStyles();


    const { imagen, alto, ancho } = props;
    const urlImg = imagen ? imagen.length > 0 ? imagen[0].url : '' : '';
    function uploadImage(e) {
        if (!props.getImgToSave) return alert('Hubo un error al guardar la imagen')
        const index = (props.imagen.length || 0) + 1;
        props.getImgToSave(e, index);
    }

    function toggleDelModal() {
        setStateModal(!openModal);
    }


    function PintarListaDeImagenes({ lista }) {
        if (!lista) return (<></>)
        return (
            <Grid style={{ padding: 10 }} >
                <Row>
                    {lista.map(item => (
                        <Col sm={4} key={item.url} style={{ marginBottom: 10 }} >
                            <div style={{ position: 'relative', }}>
                                <div
                                    className='div-delete-img'
                                    style={{ position: 'absolute', zIndex: 10, width: '100%', }}>
                                    <Button
                                        color="default"
                                        outline="true"
                                        style={{ color: '#b2b2b2' }}
                                        size="large"
                                        onClick={() => { props.onDelete(item.ruta) }}>
                                        <RiCloseCircleLine
                                            size={24}
                                        />
                                    </Button>
                                </div>
                                <div>
                                    <Card className={"container"} style={{ height: 243, width: 243 }}>
                                        <CardImg top width="80%"
                                            style={{ height: 240, width: 240 }}
                                            src={item.url} alt={item} />
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Grid>
        )
    }


    function Render({ imagen, btnText }) {
        if (!imagen || imagen.length === 0) return (
            <>
                <Grid container alignItems='center' justify="center" >
                    <Grid item>
                        <GiValve size={200} />
                    </Grid>
                </Grid>
                <Grid container justify='center'>
                    <Fab
                        variant='extended'
                        size='small'
                        color='primary'
                        className={classes.btnAgregar}
                        style={{ width: '40%' }}
                        onClick={() => {
                            inputRef.current.click();
                        }}
                    >
                        {btnText}
                    </Fab>
                </Grid>
            </>
        )
        const listaImg = imagen.map(item => (item.url))
        return (
            <>
                <Grid container >
                    <Grid container>
                        <RViewer imageUrls={listaImg}>
                            <RViewerTrigger>
                                <img style={{ cursor: 'pointer' }} height={alto} width={ancho} className="rounded mx-auto d-block my-2" src={urlImg}></img>
                            </RViewerTrigger>

                        </RViewer>
                    </Grid>

                    <Grid container justify='center'>
                        <Fab
                            variant='extended'
                            size='small'
                            color='primary'
                            className={classes.btnAgregar}
                            style={{ width: '40%' }}
                            onClick={() => {
                                inputRef.current.click();
                            }}
                        >
                            {btnText}
                        </Fab>
                        <Fab
                            variant='extended'
                            size='small'
                            color='primary'
                            className={classes.btnVerTodo}
                            style={{ width: '40%' }}
                            onClick={() => { toggleDelModal() }}
                        >
                            Ver todo
                            <Badge
                                className={classes.badge}
                                style={{ width: 'auto' }}
                            >{imagen?.length || 0}</Badge>
                        </Fab>
                    </Grid>
                </Grid>


                <CustomModal
                    titulo={'Galería de imágenes'}
                    elModalEstaAbierto={openModal}
                    toggleDelModal={toggleDelModal}
                    hideFooter
                    children={<PintarListaDeImagenes lista={imagen} />}
                />
            </>
        )

    }

    return (
        <>
            <Render
                imagen={imagen}
                btnText={props.btnText}
            />
            <input
                type='file'
                ref={inputRef}
                style={{ display: 'none' }}
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(e) => {
                    uploadImage(e.target.files[0]);
                }}
            />
        </>
    )
}
