import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { firebaseStorage, firestoreDB } from "../../../../src/firebase";

import '../../../assets/custom.css';
import {glosarioDeTitulos} from '../../scripts/glosarioDeTitulosImagenes';
const addImg = require('../../../assets/utils/images/utils/addImg.png');

class CustomModalImage extends React.Component {
    constructor() {
        super();
        this.state = {
            fileSelected: false,
            image: undefined,
            name: undefined,
            cambioTitulo: false,
            cambioImagen: false,
            warningSize:false,
            tituloPersonalizado: true,
            detalleImg: undefined,
            path: undefined,
            imagePath: undefined,
        }
        this.cargarImagen = React.createRef();
    }

    componentDidMount = () => { 
        let path = this.props.addImg ? `pictures/${this.props.idOrdenDeTrabajo}/Img_${new Date().getTime().toString()}.jpeg` : this.props.path
        if (this.props.name) {
            this.setState({
                path: path,
                name: this.props.name
            })
        }
    }

    checkInputFile=(e)=>{
        if (e.target.files[0]) {
            let sizeValidation = false
            if (e.target.files[0].size / 1024000 >= 1.5) {
                sizeValidation= true
            }
            let path = this.props.addImg ? `pictures/${this.props.idOrdenDeTrabajo}/Img_${new Date().getTime().toString()}.jpeg`: this.state.path
            this.setState({
                warningSize: sizeValidation,
                cambioImagen: true,
                fileSelected: true,
                path: path,
                image: e.target.files[0],
                imagePath: URL.createObjectURL(e.target.files[0]),
            })
        }
    }

    dropDrownTitulos = () => {
        return (
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className="mb-2 mr-2" color="primary">
                    {this.state.name ? this.state.name : 'Seleccionar Título'}
                 </DropdownToggle>
                <DropdownMenu>
                <DropdownItem header>Seleccionar Título</DropdownItem>
                    {glosarioDeTitulos.map(titulo => {
                        return (
                            <DropdownItem
                                key={titulo.key}
                                onClick={() => {
                                    this.setState({
                                        cambioTitulo: true,
                                        name: titulo.nombre,
                                    })
                                }}>
                                {titulo.nombre}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        )
    }

    getDetalle = async (url) => {
        if (this.props.imagen.includes('Galeria')) {
            let array = this.props.array
            if (this.props.addImg) {
                array.push({
                    'url':url,
                    'path':this.state.path,
                    'titulo':this.state.name,
                })   
         
                this.setState({ detalleImg: array })
            }
            else {
                array[this.props.index]['titulo'] = this.state.name
                array[this.props.index]['path'] = this.props.addImg ? `pictures/20210601/Img_${this.props.index}.jpeg` : array[this.props.index]['path']
                array[this.props.index]['url'] = url ? url : array[this.props.index]['url']
                this.setState({ detalleImg: array })
            }
        }
        else {
            switch (this.props.imagen) {
                case 'primeraImagenCamisas':
                    this.setState({
                        detalleImg: {
                            primeraImagenCamisas: {
                                titulo: this.state.name,
                            },
                        }
                    })
                    break;
                case 'segundaImagenCamisas':
                    this.setState({
                        detalleImg: {
                            segundaImagenCamisas: {
                                titulo: this.state.name,
                            },
                        }
                    })
                    break;
                case 'primeraImagenCamisasSA':
                    this.setState({
                        detalleImg: {
                            primeraImagenCamisasSA: {
                                titulo: this.state.name,
                            },
                        }
                    })
                    break;
                case 'segundaImagenCamisasSA':
                    this.setState({
                        detalleImg: {
                            segundaImagenCamisasSA: {
                                titulo: this.state.name,
                            },
                        }
                    })
                    break;
                case 'primeraImagenParches':
                    this.setState({
                        detalleImg: {
                            primeraImagenParches: {
                                titulo: this.state.name,
                            },
                        }
                    })
                    break;
                case 'segundaImagenParches':
                    this.setState({
                        detalleImg: {
                            segundaImagenParches: {
                                titulo: this.state.name,
                            },
                        }
                    })
                    break;
                default:
                    break;
            }
        }
        
    }

    getFirestoreFunction = () => {
        if (this.props.imagen.includes('Camisas') || this.props.imagen.includes('Parches')) {
            return firestoreDB.collection('ordenesDeTrabajo')
                .doc(this.props.idOrdenDeTrabajo)
                .collection('anomalias')
                .doc(this.props.idDeAnomalia)
        } else if (this.props.imagen.includes('Galeria')) {
            return firestoreDB.collection('ordenesDeTrabajo')
                .doc(this.props.idOrdenDeTrabajo)
                .collection('registrosDeInspeccion')
                .doc('registroFotografico')
        }
    }

    guardarImagen = () => {
        if (this.props.authRol) {
            this.props.cargandoTrue()
            const newImagen = this.state.image;
            if ((this.state.cambioImagen && this.state.cambioTitulo) || (this.state.cambioImagen && !this.state.cambioTitulo)) {
                this.setState({ cargando: true });
                let task = firebaseStorage.ref(this.state.path).put(newImagen)
                task.then(() => {
                    return task.snapshot.ref.getDownloadURL()
                })
                    .then((downloadURL) => {
                        return this.getDetalle(downloadURL)
                    })
                    .then(() => {
                        return this.getFirestoreFunction()
                            .set({ "detalle": this.state.detalleImg }, { merge: true })
                    })
                    .then(() => {
                        this.props.actualizarURL(URL.createObjectURL(newImagen), this.state.name)
                        this.resetVariables()
                        this.props.cargandoFalse()
                        this.props.avisoDeGuardadoSatisfactorio('¡La imagen se actualizó satisfactoriamente!');
                        setTimeout(() => { this.props.toggleDelModal() }, 100);
                    })
                    .catch(e => {
                        this.props.cargandoFalse()
                        this.props.avisoDeGuardadoFallido('¡Ocurrió un problema al actualizar la imagen!');
                        setTimeout(() => { this.props.toggleDelModal() }, 100);
                        console.log('error al guardar la imagen: ', e);
                    })
            }
            else if (!this.state.cambioImagen && this.state.cambioTitulo) {

                this.getDetalle(this.props.array[this.props.index]['url'])
                    .then(() => {
                        return this.getFirestoreFunction()
                            .set({ "detalle": this.state.detalleImg }, { merge: true })
                    })
                    .then(() => {
                        this.props.cargandoFalse()
                        this.props.actualizarURL(this.state.imagePath, this.state.name)
                        this.resetVariables()
                        this.props.avisoDeGuardadoSatisfactorio('¡El título se actualizó satisfactoriamente!');
                        setTimeout(() => { this.props.toggleDelModal() }, 100);
                    })
                    .catch(e => {
                        this.props.cargandoFalse()
                        this.props.avisoDeGuardadoFallido('¡Ocurrió un problema al actualizar el título!');
                        setTimeout(() => { this.props.toggleDelModal() }, 100);
                        console.log('error al guardar la imagen: ', e);
                    })
            }
        }
        else {
            this.props.cargandoFalse()
            this.props.avisoDeGuardadoFallido('No tiene permisos para realizar esta acción');
        }
    }

    resetVariables = () => {
        this.setState({
            imagePath: undefined,
            name: undefined,
        })
    }

    tituloModal = () => {
        return this.state.name?this.state.name:'Sin título'
     }
    render() {
        return (
            <Modal 
                isOpen={this.props.elModalEstaAbierto}
                toggle={() => { 
                    this.setState({ imagePath: undefined })
                    this.props.toggleDelModal() }}
                className={'modal-content-transparent modal-content-transparent-image'}
                size={this.props.size || "lg"} 
                style={this.props.style}>

                <ModalHeader 
                    toggle={() => {
                        this.setState({ imagePath: undefined })
                        this.props.toggleDelModal()
                    }}>
                    <div>
                        {this.tituloModal()}
                    </div>
                </ModalHeader>

                <ModalBody style={{ padding: 10 }} className={'modal-body-image'} >
                    {(this.props.addImg && !this.state.imagePath) ?
                        <img className={'img-resize'} style={{ height: '600px' }} src={addImg} alt={addImg} />
                        : <img style={{ height: '500px', width: '100%' }} src={this.state.imagePath ? this.state.imagePath : this.props.children} alt={this.state.imagePath ? this.state.imagePath : this.props.children} />}
                    <div className={'modal-body-footer'}>
                        <div>
                            {this.state.warningSize ? <Label className={'modal-warning'} for="labelWarningSize">El tamaño de la imagen supera el limite de 1.5MB</Label> : null}
                        </div>
                        {this.props.authRol ? <div>
                            {(!this.props.ensayo) ? <Button
                                style={{ marginTop: 5 }}
                                color="primary"
                                onClick={() =>
                                    this.cargarImagen.current.click()
                                }
                            >
                                {this.props.addImg ? 'Agregar Imagen' : 'Actualizar Imagen'}
                            </Button> : null}
                            <input ref={this.cargarImagen} type="file" style={{ display: "none" }}
                                onChange={(event) => {
                                    this.checkInputFile(event)
                                }} />
                        </div> : <div></div>}
                    </div>
                    
                </ModalBody>

                <ModalFooter className={'modal-footer-tranparent-2'}>
                    {this.props.authRol ? <div className={'modal-footer-2'} style={{ justifyContent: 'space-between', alignContent: 'center', margin: 0, padding: 0, width: '100%' }}>
                        <div className={'modal-footer-first-element'}>
                            <div>
                                <ButtonGroup size="sm" className="mb-2">
                                    <Button outline className="btn-pill btn-dashed" color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tituloPersonalizado: true,
                                            })
                                        }}
                                        active={this.state.tituloPersonalizado}>Custom</Button>
                                    <Button outline className="btn-pill btn-dashed" color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tituloPersonalizado: false,
                                            })
                                        }}
                                        active={!this.state.tituloPersonalizado}>Lista</Button>
                                </ButtonGroup>
                            </div>

                        </div>
                        <div className={'modal-footer-third-element'}>

                            {(!this.props.ensayo && this.state.tituloPersonalizado) ? <Input type="text" name="tituloImg" id="tituloImg" value={this.state.name ? this.state.name : ''} style={{ width: '50%' }} placeholder="Título de la imagen"
                                onChange={(event) => {
                                    this.setState({
                                        cambioTitulo: true,
                                        name: event.target.value
                                    })
                                }}
                            /> : (!this.props.ensayo && !this.state.tituloPersonalizado) ? this.dropDrownTitulos()
                                : <Input readOnly type="text" name="tituloImg" id="tituloImg" value={this.state.name ? this.state.name : ''} style={{ width: '50%' }} placeholder="Título de la imagen"
                                    onChange={(event) => {
                                        this.setState({
                                            cambioTitulo: true,
                                            name: event.target.value
                                        })
                                    }}
                                />}
                            {((this.state.cambioImagen || this.state.cambioTitulo) && (!this.props.ensayo && !this.state.warningSize)) ?
                                <Button
                                    color="success"
                                    onClick={() => {
                                        this.guardarImagen()
                                    }}
                                >
                                    Guardar
                                </Button> : (!this.props.ensayo || this.state.warningSize) ?
                                    <Button
                                        disabled
                                        color="success"
                                        onClick={() => {
                                            this.guardarImagen()
                                        }}
                                    >
                                        Guardar
                            </Button> : null}
                        </div>
                        <div >


                        </div>
                    </div> : <div></div>}
                </ModalFooter>
            </Modal>
        );
    }
    
}

export default CustomModalImage;