import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataUtScanA = (nodoCml) => {
    const datosTabla = [];
    const listaDeNodos = nodoCml;

    try{
        listaDeNodos.sort(( a, b ) => 
        a?.variablesDeLaInspeccion?.utScanA?.ordenDeCargue?.valor > 
        b?.variablesDeLaInspeccion?.utScanA?.ordenDeCargue?.valor ? 1 : 
        b?.variablesDeLaInspeccion?.utScanA?.ordenDeCargue?.valor > 
        a?.variablesDeLaInspeccion?.utScanA?.ordenDeCargue?.valor ? -1 : 0)
        
        listaDeNodos.forEach((data, i) => {
            const resInt = data?.resultadosIntegridad;
            const resVarA = data?.variablesDeLaInspeccion?.utScanA;
            datosTabla.push({
                item: {label:'Item', valor: i+1, minWidth: 50},
                'id/cml': { label: 'id/cml', valor: resVarA?.locacion?.valor || 0, minWidth: 80, resizable: true },
                ubicacion: { label: 'Ubicación', valor: resVarA?.ubicacion?.valor || 0, minWidth: 90  },
                diametro: {label:'Diámetro (in)',valor: formatearvalorsegunestado(resVarA?.diametro?.valor, 0), minWidth: 100 },
                tNom: {label:'T<sub>nom</sub> (in)', valor: formatearvalorsegunestado(resVarA?.tNom?.valor, 3), minWidth: 80, condicion: 'dataEnriquecida'},
               '1': {label: 'Tmed<br> 1 (in)', valor: formatearvalorsegunestado(resVarA?.scanA['12H'], 3), minWidth: 80, condicion: 'dataEnriquecida' },
               '2': {label: 'Tmed<br> 2 (in)', valor: formatearvalorsegunestado(resVarA?.scanA['3H'], 3), minWidth: 80, condicion: 'dataEnriquecida' },
               '3': {label: 'Tmed<br> 3 (in)', valor: formatearvalorsegunestado(resVarA?.scanA['6H'], 3), minWidth: 80, condicion: 'dataEnriquecida' },
               '4': {label: 'Tmed<br> 4 (in)', valor: formatearvalorsegunestado(resVarA?.scanA['9H'], 3), minWidth: 80, condicion: 'dataEnriquecida' },
                tMinScanC: {label: 'T <sub>min</sub> Scan C (in)', valor: formatearvalorsegunestado(data?.variablesDeLaInspeccion?.utScanC?.espesorMinimoEncontrado?.valor, 3), minWidth: 80, condicion: 'dataEnriquecida' },
                tMin: {label: 'T<sub>min</sub> (in)', valor: formatearvalorsegunestado(resInt?.tMin?.valor, 3), minWidth: 80, condicion: 'dataEnriquecida' },
                tReq: {label: 'T<sub>req</sub> (in)', valor: formatearvalorsegunestado(resInt?.tRet?.valor, 3), minWidth: 80, condicion: 'dataEnriquecida' },
                PerdidaT: {label: 'Pérdida T (%)', valor: formatearvalorsegunestado(resInt?.PM?.valor, 1), minWidth: 80 },
                tServicio: {label: 'Tiempo en servicio (años)', valor: formatearvalorsegunestado(resVarA?.tServicio?.valor, 1), minWidth: 150 },
                velocidadCorrosion: {label: 'Velocidad de corrosión (MPY)', valor: formatearvalorsegunestado(resInt?.velocidadCorrosion?.valor, 1), minWidth: 150 },
                vidaRemanente: {label: 'Vida remanente (años)', valor: formatearvalorsegunestado(resInt?.vidaRemanente?.valor1), minWidth: 130 },
                MAWP: {label: 'MAWP (psi)', valor: formatearvalorsegunestado(resInt?.MAWP?.valor, 1), minWidth: 80 },
                proximaFechaInspeccion: {label: 'Proxima fecha de inspección (años)', valor: `${new Date(data?.informacionGeneral?.fechaDeInspeccion?.seconds * 1000 + (3600 * 24 * 365 * resInt?.anhosEntreInspecciones?.valor * 1000)).toLocaleString().split(',')[0]} (${resInt?.anhosEntreInspecciones?.valor}) `, minWidth: 150 },
            })
        })
    }
    catch(error){console.log(error)}
    return datosTabla
}

export default armarDataUtScanA
