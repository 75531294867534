import React from 'react'

import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = { 
    display: "flex", 
    width: "100%", 
    justifyContent: 'center', 
    cursor: 'pointer', 
    // textDecoration: 'underline', 
    overflowX: 'auto', 
    // justifyContent: "none", 
    textDecoration: 'none', 
    fontWeight: 'bold', 
    color: 'rgba(32, 39, 140, 0.5)' 
}

let DetalleDeAtenciones = (props) => {
    if(props.acciones)
    return (
        <React.Fragment>
            <br />
            <br />
            <Breadcrumb>
                <BreadcrumbItem className='BreadcrumbItem-color'>REPARACION POR {props.tipo.toUpperCase()}</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col md='12' lg='12' className='marcador-tabla-comparacion' style={{ overflowX: 'auto', margin: '20px 0px' }} >
                    <CustomTable 
                        listaDeDatos={props.acciones}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Acción</p>),
                                    columns: [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Tipo </p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.tipoDeAtencionRecomendada}</p>
                                                    </div>
                                            ),
                                        },
                                        {
                                            accesor: 'longitudAReparar',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Longitud a Reparar</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{parseFloat(row._original.longitudAReparar).toFixed(3)}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'distanciaRegistroInicialRecomendacion',

                                            Header: () => (<p style={estiloParaHeader}>Distancia Registro Inicial</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.distanciaRegistroInicialRecomendacion}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'distanciaRegistroFinalRecomendacion',

                                            Header: () => (<p style={estiloParaHeader}>Distancia Registro Final</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.distanciaRegistroFinalRecomendacion}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'distanciaJuntaAnteriorRecomendacion',

                                            Header: () => (<p style={estiloParaHeader}>Distancia J. Anterior</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{parseFloat(row._original.distanciaJuntaAnteriorRecomendacion).toFixed(3)}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'distanciaJuntaPosteriorRecomendacion',

                                            Header: () => (<p style={estiloParaHeader}>Distancia J. Posterior</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{parseFloat(row._original.distanciaJuntaPosteriorRecomendacion).toFixed(3)}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'latitudDeReparacionDecimal',

                                            Header: () => (<p style={estiloParaHeader}>Latitud</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.latitudDeReparacionDecimal}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'longitudDeReparacionDecimal',

                                            Header: () => (<p style={estiloParaHeader}>Longitud</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.longitudDeReparacionDecimal}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'observacionesReparacion',

                                            Header: () => (<p style={estiloParaHeader}>Observaciones</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos, flex: 4,justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.observacionesReparacion}</p>
                                                </div>
                                            ),
                                            minWidth: 240
                                        }
                                    ]
                                },
                            ]
                        }
                    />
                </Col>
                <Col md='12' lg='12' className='marcador-tabla-comparacion' style={{ overflowX: 'auto', margin: '20px 0px' }} >
                    <CustomTable 
                        listaDeDatos={props.acciones}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Espesor de Zona de la Junta Circunferencial Anterior</p>),
                                    columns: [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>12:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior12}</p>
                                                    </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior1',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>1:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior1}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior2',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>2:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior2}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior3',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>3:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior3}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior4',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>4:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior4}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior5',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>5:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior5}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior6',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>6:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior6}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior7',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>7:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior7}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior8',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>8:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior8}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior9',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>9:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior9}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior10',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>10:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior10}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialAnterior11',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>11:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialAnterior11}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'espesorMinimoJuntaAnterior',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Espesor Mínimo</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.espesorMinimoJuntaAnterior}</p>
                                                </div>
                                            ),
                                            minWidth: 180
                                        }
                                    ]
                                },
                            ]
                        }
                    />
                </Col>
                <Col md='12' lg='12' className='marcador-tabla-comparacion' style={{ overflowX: 'auto', margin: '20px 0px' }} >
                    <CustomTable 
                        listaDeDatos={props.acciones}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Espesor de Zona de la Junta Circunferencial Posterior</p>),
                                    columns: [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>12:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior12}</p>
                                                    </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior1',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>1:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior1}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior2',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>2:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior2}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior3',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>3:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior3}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior4',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>4:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior4}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior5',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>5:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior5}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior6',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>6:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior6}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior7',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>7:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior7}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior8',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>8:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior8}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior9',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>9:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior9}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior10',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>10:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior10}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'zonaJuntaCircunferencialPosterior11',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>11:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.zonaJuntaCircunferencialPosterior11}</p>
                                                </div>
                                            ),
                                            minWidth: 90
                                        },
                                        {
                                            accesor: 'espesorMinimoJuntaPosterior',

                                            Header: () => (<p style={{ ...estiloParaHeader }}>Espesor Mínimo</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos}}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.espesorMinimoJuntaPosterior}</p>
                                                </div>
                                            ),
                                            minWidth: 180
                                        }
                                    ]
                                },
                            ]
                        }
                    />
                </Col>
            </Row>
        </React.Fragment>
    )

}

export default DetalleDeAtenciones;