import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card } from 'reactstrap';

//Componentes auxiliares:
import ProgressBarDeDescarga from './ProgressBarDeDescarga';
import CustomModal from '../plantillaBase/CustomModal';

//Cloud functions
import { firebaseFunctions } from '../../../firebase';

//Tablas de React
// import datosPruebaExportacion from '../scripts/datosPrueba';


const exportarResultadosPRD = firebaseFunctions.httpsCallable('exportarResultadosPRD');


const ConsolaDeDescarga = (props) => {

    const [state, setState] = useState({
        normaTarget: undefined,
        tamañoParticion: 50,
        progresoExportacionResultados: 0,
        estadoDelProceso: "inicio" //inicio; cargandoDatos; procesoFinalizado; error; sinDatos
    })

    useEffect(() => {
        if (props.prd.length === 0 && props.elModalEstaAbierto) {
            setState((prevState) => ({
                ...prevState,
                estadoDelProceso: "sinDatos"
            }));

        } else {

            if (state.estadoDelProceso === "sinDatos") {
                setState((prevState) => ({
                    ...prevState,
                    estadoDelProceso: "inicio"
                }));
            }

            if (props.prd.length && props.prd.length >= 4 && props.prd.length <= 90) {
                const nuevoTamaño = Math.round(props.prd.length / 4);
                setState((prevState) => ({
                    ...prevState,
                    tamañoParticion: nuevoTamaño
                }));
            }


            if(props.prd.length > 90) {
                setState((prevState) => ({
                    ...prevState,
                    tamañoParticion: 50
                }));
            }

        }

        return () => {
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.elModalEstaAbierto, props.prd])

    const obtenerProgressBars = () => {
        return (
            <Card className="widget-chart border mb-3">
                <ProgressBarDeDescarga
                    titulo={'Resultados'}
                    porcentajeCompletado={state.progresoExportacionResultados}
                    status={"OK"}
                    key={"descargaResultados." + (new Date()).getTime()} />
            </Card>
        );
    }

    const obtenerBotonDeInicioDeCarga = () => {
        if (state.estadoDelProceso === "inicio") {
            return (
                <Button className="btn-wide pusheable-button"
                    style={{ marginBottom: 20 }} color="success"
                    onClick={() => {
                        descargarNodos();
                    }}>
                    Iniciar descarga
                </Button>
            );
        }
    }

    const obtenerSubtituloSegunEstado = () => {
        switch (state.estadoDelProceso) {
            case "inicio":
                return "<br/>Requerimos confirmación para iniciar el proceso. Este puede tardar algunos segundos";
            case "cargandoDatos":
                return "<br/>Procesando datos...";
            case "procesoFinalizado":
                return "<br/>El proceso de exportación terminó.<br/><br/>";
            case "error":
                return "<br/>El proceso de exportación no pudo finalizar correctamente.<br/><br/>"
            case "sinDatos":
                return "<br/>No hay datos que exportar<br/><br/>"
            default:
                return "";
        }
    }

    const descargarNodos = async () => {
        setState((prevState) => ({
            ...prevState,
            estadoDelProceso: "cargandoDatos"
        }));

        const particionPRD = props.prd.slice(0, state.tamañoParticion);

        Promise.all([
            cargarDatosAResultados(props.normaTarget, particionPRD, 0)
        ])
            .then(response => {
                try {
                    setState((prevState) => ({
                        ...prevState,
                        estadoDelProceso: "inicio",
                        progresoExportacionResultados: 0,

                    }));
                    if (props.elModalEstaAbierto) {
                        props.toggleDelModal();
                        props.alertExitoso('Descarga exitosa', 'Revisa las ventanas emergentes');
                    }

                    window.open(response[0].download_url);
                    // window.open(response[1].download_url);

                }
                catch (error) {
                    setState((prevState) => ({
                        ...prevState,
                        estadoDelProceso: "error",
                        progresoExportacionResultados: 0,
                    }));

                    if (props.elModalEstaAbierto) {
                        props.toggleDelModal();
                        props.alertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                    }
                };
            })
            .catch(error => {
                console.log(error);
                setState((prevState) => ({
                    ...prevState,
                    estadoDelProceso: "error",
                    progresoExportacionResultados: 0,
                }));

                if (props.elModalEstaAbierto) {
                    props.toggleDelModal();
                    props.alertFallido('Descarga fallida', 'El proceso de exportación no pudo finalizar correctamente');
                }
            });
    }

    const cargarDatosAResultados = (normaTarget, particionPRD, puntoInicial, documento = null) => {
        const limiteMaximo = props.prd.length;
        actualizarProgresoResultados((puntoInicial < limiteMaximo) ? puntoInicial : limiteMaximo, limiteMaximo);
        return new Promise((resolve, reject) => {
            exportarResultadosPRD({
                "norma": normaTarget,
                "prd": particionPRD,
                documento,
                "modeloDeConsecuencia": props.modeloDeConsecuencia,
                "modoDescarga": props.modoDescarga,
            })
                .then(response => {
                    const nuevoInicio = puntoInicial + state.tamañoParticion;
                    if (nuevoInicio < props.prd.length) {
                        const nuevoDocumento = documento = response["data"]["documento"];
                        const particionPRD = props.prd.slice(nuevoInicio, nuevoInicio + state.tamañoParticion);
                        resolve(cargarDatosAResultados(normaTarget, particionPRD, nuevoInicio, nuevoDocumento));
                    }
                    resolve(response["data"]);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    const actualizarProgresoResultados = (progreso, total) => {
        setState((prevState) => ({
            ...prevState,
            progresoExportacionResultados: progreso / total * 100
        }));
    }
    return (
        <CustomModal size="lg" elModalEstaAbierto={props.elModalEstaAbierto}
            toggleDelModal={props.toggleDelModal} titulo={<span>Consola para <b>descargar</b> los resultados de los cálculos de PRD</span>}>

            <Row style={{ padding: 15 }}>
                <Col xs="12" md="12" lg="6" xl="6">
                    <Card className="widget-chart border mb-3">
                        <div className="widget-heading">
                            <p>
                                Se van a descargar <b>{props.prd.length}</b> PRD
                                </p>
                        </div>

                        <div className="widget-subheading" style={{ opacity: 1 }}
                            dangerouslySetInnerHTML={{ __html: obtenerSubtituloSegunEstado() }} />

                        <hr />
                        {obtenerBotonDeInicioDeCarga()}
                    </Card>
                </Col>

                <Col xs="12" md="12" lg="6" xl="6">
                    {obtenerProgressBars()}
                </Col>
            </Row>
        </CustomModal>
    );
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ConsolaDeDescarga);