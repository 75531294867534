import React, { useState, useEffect } from 'react';

import { Polyline, Marker, InfoWindow, } from 'react-google-maps';
import { Label } from 'reactstrap';

//Scripts
import { parametrosDeEstados } from '../../scripts/parametrosDeEstados'

//Componentes auxiliares:
import CustomCleanModal from '../../../../../utilidades/componentes/plantillaBase/CustomCleanModal';
import ModalDetalleDeTraza from './ModalDetalleDeTraza';

const PuntoDeInspeccion = ({ estado, renderKey, ...props }) => {
  const [elModalEstaAbierto, setEstadoModal] = useState(false)
  const [colorTraza, setColorTraza] = useState(parametrosDeEstados[estado]?.color || '#DADADA')
  const [keyDelColor, setKeyDelColor] = useState(0)
  const [showInfoWindow, setEstadoInfo] = useState(false)

  /* ----------------------------- Logic functions ---------------------------- */
  const handleMouseExit = () => setEstadoInfo(false)
  const handleMouseOver = () => setEstadoInfo(true)

  const toggleDelModal = () => setEstadoModal(!elModalEstaAbierto)

  /* ---------------------------- Render functions ---------------------------- */
  const obtenerMapaSatelital = () => {
    const puntoGeoreferencia = props.ordenDeTrabajo.puntosGeoreferencia[0]

    if (puntoGeoreferencia === undefined) return

    return (
      <React.Fragment>
        <Marker
          icon={
            {
              path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
              fillColor: parametrosDeEstados[estado]?.color || '#DADADA',
              fillOpacity: 1,
              strokeColor: parametrosDeEstados[estado]?.color || '#DADADA',
              strokeWeight: 5,
              scale: 5,
            }
          }
          position={
            {
              lat: (puntoGeoreferencia['lat'] || 4.803196),
              lng: (puntoGeoreferencia['lng'] || -74.095906)
            }
          }
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseExit}
        >
          {showInfoWindow && (
            <InfoWindow>
              <Label>
                ID: {props.id}
                <br />
                                Latitud:  {puntoGeoreferencia['lat'] || 'Sin Coordenadas'}
                <br />
                                Longitud: {puntoGeoreferencia['lng'] || 'Sin Coordenadas'}
              </Label>
            </InfoWindow>
          )}
        </Marker>
      </React.Fragment>
    )
  }

  const obtenerMapaTerreno = () => {
    return (
      <React.Fragment>
        <Polyline
          key={`ordenDeTrabajo_${props.ordenDeTrabajo.numero}_${props.ordenDeTrabajo.id}_${keyDelColor}`}
          defaultOptions={
            {
              strokeColor: colorTraza,
              strokeWeight: 15
            }
          }
          onClick={() => {
            toggleDelModal();
            setColorTraza(colorTraza)
          }}
          path={props.ordenDeTrabajo.puntosGeoreferencia}
        />
        <CustomCleanModal
          elModalEstaAbierto={elModalEstaAbierto}
          size="md"
          toggleDelModal={toggleDelModal}
          estilos={{ padding: 0 }}
        >
          <div style={{ padding: 1 }}>
            <ModalDetalleDeTraza
              colorDeFondo={parametrosDeEstados[estado]?.color || '#DADADA'}
              estadoFormateado={parametrosDeEstados[estado]?.estado}
              ordenDeTrabajo={props.ordenDeTrabajo}
            />
          </div>
        </CustomCleanModal>
      </React.Fragment>
    )
  }

  useEffect(() => {
    setColorTraza(parametrosDeEstados[estado]?.color || '#DADADA')
    setKeyDelColor(keyDelColor => keyDelColor + 1)
  }, [estado, renderKey])

  if (props.ordenDeTrabajo === undefined) return

  switch (props.tipoMapa) {
    case 'satelite':
      return obtenerMapaSatelital();
    case 'mapa':
      return obtenerMapaTerreno();
    default:
      break;
  }
}

export default PuntoDeInspeccion;