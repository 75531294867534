export const posicionarVelocidadCorrosionEnIntervalo = ( valor ) => {

    let posicionIntervalo;

    if ( valor < 1 ) {
        posicionIntervalo = 0.125;
    } else if ( valor < 4.9 ) {
        posicionIntervalo = 0.375;
    } else if ( valor < 10 ) {
        posicionIntervalo = 0.625;
    } else if ( valor >= 10 ) {
        posicionIntervalo = 0.875;
    }
    return posicionIntervalo
}
