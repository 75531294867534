import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Card } from 'reactstrap';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

//Componentes
import CustomTable from '../../../../../utilidades/componentes/visualizadores/CustomTable';
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal';

//Scripts Genéricos
import obtenerColorSegunEstado from '../../../../../utilidades/scripts/paraVisualizacionDeRangos';

//Scripts Específicos
import { parametrosDeEstados } from '../../scripts/parametrosDeEstados';

const estiloParaHeader = { 
  color: "rgba(32,39,140,0.5)", 
  marginBottom: 0, 
};

const estiloCeldas = { 
  display: "flex", 
  width: "100%", 
  wordBreak: 'break-word', 
  justifyContent: 'center', 
  cursor: 'pointer', 
  textDecoration: 'none',
  fontWeight: 'bold', 
  color: 'rgba(32, 39, 140, 0.5)' 
}

class LeyendaDelMapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tituloDelModal: '',
      elModalEstaAbierto: false,
      pintarLeyendasDelMapa: undefined,
      estados: undefined,
      listaDeFormatos: undefined
    }
  }
  render() {
    return (
      <Card className="main-card">
        <div className="">
          <Row className="no-gutters">
            {this.state.pintarLeyendasDelMapa}
            <CustomModal
              elModalEstaAbierto={this.state.elModalEstaAbierto}
              size="xl"
              titulo={this.state.tituloDelModal}
              toggleDelModal={this.toggleDelModal}
            >
              <div style={{ padding: 15 }}>
                <CustomTable
                  listaDeDatos={this.props.estadosFiltrados}
                  criterioDeOrdenamiento={{ id: "numero", desc: false }}
                  columnas={[
                    {
                      accesor: 'id',
                      resizable: false,
                      Header: () => (<p style={estiloParaHeader}>ID</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}>
                          <Link to={'/app/ordenDeTrabajo/' + row._original.id}>
                            <p style={{ margin: '0 auto', color: '#545cd8', fontWeight: 300, textDecoration: 'underline', overflowX: 'auto' }}
                            >{row._original.id}</p>
                          </Link>
                        </div>
                      ),
                      maxWidth: 120
                    },
                    {
                      accesor: 'estado',
                      resizable: false,
                      Header: () => (<p style={estiloParaHeader}>Estado</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}>
                          {this.props.crearBadgeSegunEstado(row._original.estado)}
                        </div>
                      ),
                      minWidth: 80
                    },
                    {
                      accesor: 'responsable',
                      resizable: false,
                      Header: () => (<p style={estiloParaHeader}>Supervisor</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}>

                          <p style={{ margin: '0 auto', color: '#000000', fontWeight: 300, overflowX: 'auto' }}
                          >{row._original.responsable}</p>
                        </div>
                      ),
                      minWidth: 120
                    },
                    {
                      accesor: 'lineaDeDucto',
                      resizable: false,
                      Header: () => (<p style={estiloParaHeader}>Línea</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}
                        >
                          <p className='d-inline-block text-truncate' style={{ margin: '0 auto', color: '#000000', fontWeight: 300 }}
                          >{row._original.lineaDeDucto ? row._original.lineaDeDucto : '-'}</p>
                        </div>
                      ),
                      minWidth: 180
                    },
                    {
                      accesor: 'descripcionODT',
                      resizable: false,
                      Header: () => (<p style={estiloParaHeader}>Descripción</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}
                        >
                          <p 
                            style={{ margin: '0 auto', color: '#000000', fontWeight: 300, maxLines: 2, overflow: 'hidden'}}
                            >
                            {row._original.descripcionODT ? row._original.descripcionODT : '-'}
                          </p>
                        </div>
                      ),
                      minWidth: 220
                    },
                    {
                      accesor: 'fechaDeActualización',
                      resizable: false,
                      Header: () => (<p style={estiloParaHeader}>Fecha de Actualización</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}
                        >
                          <p type='date' style={{ margin: '0 auto', color: '#000000', fontWeight: 300, overflowX: 'auto' }}
                          >{row._original.fechaDeActualizacion}</p>
                        </div>
                      ),
                    },
                  ]
                  }
                />
              </div>
            </CustomModal>
          </Row>
        </div>
      </Card>
    )
  }

  componentDidMount() {
    this.setState({
      listaDeFormatos: this.props.listaDeFormatos,
      estados: this.props.listaDeFormatos.map(element => { return element.estado })
    }, () => {
      this.crearLeyendaDelMapa()
    })
  }

  componentDidUpdate(prevenProps, prevenUpdate) {
    if (prevenProps !== this.props) {
      this.setState({
        listaDeFormatos: this.props.listaDeFormatos,
        estados: this.props.listaDeFormatos.map(element => { return element.estado })
      }, () => {
        this.crearLeyendaDelMapa()
      })
    }
  }

  textoFiltroActivo = () => {
    switch (this.props.filtroActivo) {
      case 'quincenal':
        return 'Última quincena: '
      case 'semanal':
        return 'Última semana:'
      case 'mensual':
        return 'Último mes: '
      case 'anual':
        return 'Último año: '
      case 'custom':
        return 'Filtradas: '
      default:
        return 'Filtradas: '
    }
  }
  crearLeyendaDelMapa = () => {
    let cajas = [];
    let dominio = this.props.dominioCorporativo.split('.')[0]
    let dominioCorp = dominio.charAt(0).toUpperCase() + dominio.split(dominio.charAt(0))[1]
    cajas.push(
      <Col sm='12' key={`leyenda  total ordenes Dominio`}>
        <div className='widget-chart widget-chart-hover border pt-1'
        >
          <Row>
            <Col>
              <div className='widget-numbers mt-0'>
                {this.props.crearBadgeSegunEstado('TOTAL')}
              </div>
              <div style={{ fontSize:16 }} className='widget-subheading'>
                Existentes de {dominioCorp}:
                    <span style={{ fontSize:16, color: obtenerColorSegunEstado('TOTAL') }} className="pl-1">{this.props.totalOrdenesEnDominio}</span>
              </div>
              <br/>
              <div style={{ fontSize:16 }} className='widget-subheading'>
                {this.textoFiltroActivo()}
                    <span style={{ fontSize:16, color: obtenerColorSegunEstado('TOTAL') }} className="pl-1">{this.state.listaDeFormatos.length}</span>
              </div>
            </Col>
            <Col>
              <div className='widget-description'>
                <Progress
                  type="circle"
                  percent={this.state.listaDeFormatos.length > 0 ? ((this.state.listaDeFormatos.length / this.props.totalOrdenesEnDominio) * 100).toFixed(2) : 0}
                  width={100}
                  theme={
                    {
                      active: {
                        trailColor: 'rgba(0,0,0,0.1)',
                        color: obtenerColorSegunEstado('TOTAL')
                      }
                    }
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    )
    let keys = Object.keys(parametrosDeEstados)
    for (let i = 0; i < keys.length; i++) {
      let key = parametrosDeEstados[keys[i]]
      cajas.push(
        <Col sm='12' key={`leyenda  ${key.estado}`}>
          <div className={key.claseDeLaCaja}
            onClick={() => {
              this.setState({
                tituloDelModal: key.tituloDelModal
              });
              this.props.filtrarOrdenesPorEstado(key.estado);
              this.toggleDelModal()
            }}
          >
            <Row>
              <Col>
                <div className={key.claseDelBadge}>
                  {this.props.crearBadgeSegunEstado(key.estado)}
                </div>
                <div className={key.claseNumeroInspecciones}>
                  Número de ordenes:
                      <span style={{ color: obtenerColorSegunEstado(key.estado) }} className="pl-1">{this.props.obtenerNumeroDeEstados(key.estado)}</span>
                </div>
              </Col>
              <Col>
                <div className={key.claseDelProgress}>
                  <Progress
                    type="circle"
                    percent={this.props.estados.length > 0 ? ((this.props.obtenerNumeroDeEstados(key.estado) / this.props.estados.length) * 100).toFixed(2) : 0}
                    width={70}
                    theme={
                      {
                        active: {
                          trailColor: 'rgba(0,0,0,0.1)',
                          color: obtenerColorSegunEstado(key.estado)
                        }
                      }
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      )
    }
    this.setState({
      pintarLeyendasDelMapa: cajas
    })
  }

  obtenerNumeroDeEstados = (estado) => {
    let estados = [];
    if (this.state.estados !== undefined) {
      for (let i = 0; i < this.state.estados.length; i++) {
        if (this.state.estados[i] === estado) {
          estados.push(this.state.estados[i])
        }
      };
      return estados.length;
    }
  }

  toggleDelModal = () => {
    this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto });
  }

  obtenerValorPorcentualFormateado = (scopeTarget) => {
    try {
      return ((this.props.obtenerNumeroDeEstados(scopeTarget) / this.props.estados.length) * 100).toFixed(2);
    }
    catch (error) {
      return 0;
    }
  }
}

export default LeyendaDelMapa