import React from 'react'
import drumVertical from '../../../../../../assets/utils/images/api510/drum-vertical.png';
import drumHorizontal from '../../../../../../assets/utils/images/api510/drum-horizontal.png';

export const DrumSeparador = ({posicion, cap, body, nozzle}) => {

    return (
        <>
            <img 
            src={posicion === 'Horizontal' ? drumHorizontal : drumVertical}
            alt="tambor"
            className='drumVertical'
            />
                {
                    !!cap &&
                    <div className={posicion === 'Horizontal' ? 'campo1H' : 'campo1V'}>
                        <span className="title">CAP semielíptico</span>
                        <span className="content">{cap} (in)</span>    
                    </div>
                }
                {
                    !!body &&
                    <div className={posicion === 'Horizontal' ? 'campo2H' : 'campo2V'}>
                        <span className="title">Cuerpo</span>
                        <span className="content">{body} (in)</span>  
                        
                    </div>
                }
                {
                    !!cap &&
                    <div className={posicion === 'Horizontal' ? 'campo3H' : 'campo3V'}>
                        <span className="title">CAP semielíptico</span> 
                        <span className="content">{cap} (in)</span>  
                    </div>
                }
                {
                    !!nozzle &&
                    <div className={posicion === 'Horizontal' ? 'campo4H' : 'campo4V'}>
                        <span className="title">Boquilla</span> 
                        <span className="content">{nozzle} (in)</span>  
                    </div>
                }
        </>
    )
}
