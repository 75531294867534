export const glosarioDeInputsOrdenDeTrabajo1 = {
    "numeroDeFormato": {
        "name": "numeroDeFormato",
        "contenido": "Número de Formato",
        "type": "text",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeNumeroDeFormato",
        "col-md": 12,
        "col-lg": 6
    },
    "responsable": {
        "name": "responsable",
        "type": "text",
        "contenido": "Responsable:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeResponsable",
        "col-md": 12,
        "col-lg": 6
    },
    "correo": {
        "name": "correo",
        "type": "email",
        "contenido": "Correo:",
        "tipo": "correo_electronico",
        "categoria": "valorUnico",
        "mensaje": "mensajeCorreo",
        "col-md": 12,
        "col-lg": 6
    },
    "estado": {
        "name": "estado",
        "opciones": [
            { agrupador: undefined, valor: "PLANEADA", label: "Planeada", },
        ],
        "contenido": "Estado:",
        "disabled": "true",
        "type": "select",
        "categoria": "valorDeSeleccionMultiple",
        "mensaje": "mensajeEstado",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaDeInicio": {
        "name": "distanciaDeInicio",
        "type": "text",
        "contenido": "Distancia de inicio de la anomalía:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeDistanciaDeInicio",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaDeFin": {
        "name": "distanciaDeFin",
        "type": "text",
        "contenido": "Distancia de fin de la anomalía:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeDistanciaDeFin",
        "col-md": 12,
        "col-lg": 6
    },
    "fechaEstimadaDeProgramacion": {
        "name": "fechaEstimadaDeProgramacion",
        "type": "fecha",
        "contenido": "Fecha estimada de programación:",
        "tipo": "fecha",
        "categoria": "valorUnico",
        "mensaje": "mensajeFechaEstimada",
        "col-md": 6,
        "col-lg": 6,
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "descripcionODT": {
        "name": "descripcionODT",
        "type": "textarea",
        "contenido": "Descripción de la órden de trabajo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDescripcionODT",
        "col-md": 12,
        "col-lg": 12
    },
}

export const glosarioDeInputsOrdenDeTrabajo2 = {
    "latInicial": {
        "name": "latInicial",
        "type": "text",
        "contenido": "Latitud inicial: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLatInicial",
        "col-md": 12,
        "col-lg": 6
    },
    "lngInicial": {
        "name": "lngInicial",
        "type": "text",
        "contenido": "Longitud inicial: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLngInicial",
        "col-md": 12,
        "col-lg": 6
    },
    "latFinal": {
        "name": "latFinal",
        "type": "text",
        "contenido": "Latitud final: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLatFinal",
        "col-md": 12,
        "col-lg": 6
    },
    "lngFinal": {
        "name": "lngFinal",
        "type": "text",
        "contenido": "Longitud final: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLngFinal",
        "col-md": 12,
        "col-lg": 6
    },
    "zona": {
        "name": "zona",
        "type": "select",
        opciones: [
            //{ valor: "Sur", label: "Sur" },
            { valor: "Norte", label: "Norte" },
        ],
        "contenido": "Zona:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeZona",
        "col-md": 12,
        "col-lg": 6
    },
    "region": {
        "name": "region",
        "type": "select",
        opciones: [
            { agrupador: "Norte", valor: "Región Caribe", label: "Región Caribe", },
            { agrupador: "Sur", valor: "Región Centro", label: "Región Centro", },
            { agrupador: "Norte", valor: "Región Magdalena", label: "Región Magdalena", },
            { agrupador: "Sur", valor: "Región Occidental", label: "Región Occidental", },
            { agrupador: "Norte", valor: "Región Oriente", label: "Región Oriente", },
            { agrupador: "Sur", valor: "Región Sur", label: "Región Sur", },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.zona;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'zona',
        "contenido": "Región:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRegion",
        "col-md": 12,
        "col-lg": 6
    },
    "depto": {
        "name": "depto",
        "type": "select",
        opciones: [
            { agrupador: "Región Oriente", valor: "Oriente", label: "Oriente", },
            { agrupador: "Región Caribe", valor: "Caribe", label: "Caribe", },
            { agrupador: "Región Caribe", valor: "Coveñas", label: "Coveñas", },
            { agrupador: "Región Magdalena", valor: "Magdalena", label: "Magdalena", },
            { agrupador: "Región Centro", valor: "Meta", label: "Meta", },
            { agrupador: "Región Centro", valor: "Casanare", label: "Casanare", },
            { agrupador: "Región Sur", valor: "Sur", label: "Sur", },
            { agrupador: "Región Occidental", valor: "Occidente", label: "Occidente", },
            { agrupador: "Región Centro", valor: "Andina", label: "Andina", },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.region;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'region',
        "contenido": "Depto O&M:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDepto",

        "col-md": 12,
        "col-lg": 6
    },
    "sistema": {
        "name": "sistema",
        "type": "select",
        opciones: [
            { agrupador: "Oriente", valor: "Oleoducto Caño Limón - Ayacucho 24”", label: "Oleoducto Caño Limón - Ayacucho 24”" },
            { agrupador: "Caribe", valor: "Oleoducto Ayacucho - Galán 8”", label: "Oleoducto Ayacucho - Galán 8”" },
            { agrupador: "Caribe", valor: "Combustoleoducto Galán - Ayacucho 18”", label: "Combustoleoducto Galán - Ayacucho 18”" },
            { agrupador: "Caribe", valor: "Oleoducto Galán - Ayacucho 14”", label: "Oleoducto Galán - Ayacucho 14”" },
            { agrupador: "Caribe", valor: "Poliducto Pozos Colorados - Ayacucho 14”", label: "Poliducto Pozos Colorados - Ayacucho 14”" },
            { agrupador: "Caribe", valor: "Poliducto Ayacucho - Galán 14”", label: "Poliducto Ayacucho - Galán 14”" },
            { agrupador: "Caribe", valor: "Combustoleoducto Ayacucho -Coveñas 16”", label: "Combustoleoducto Ayacucho -Coveñas 16”" },
            { agrupador: "Caribe", valor: "Oleoducto Ayacucho - Coveñas 24”", label: "Oleoducto Ayacucho - Coveñas 24”" },
            { agrupador: "Caribe", valor: "Poliducto Cartagena - Baranoa 12”", label: "Poliducto Cartagena - Baranoa 12”" },
            { agrupador: "Coveñas", valor: "Combustoleoducto Ayacucho -Coveñas 16”", label: "Combustoleoducto Ayacucho -Coveñas 16”" },
            { agrupador: "Coveñas", valor: "Loop Since - Coveñas 16”", label: "Loop Since - Coveñas 16”" },
            { agrupador: "Coveñas", valor: "Oleoducto Ayacucho - Coveñas 24”", label: "Oleoducto Ayacucho - Coveñas 24”" },
            { agrupador: "Coveñas", valor: "Combustoleoducto Coveñas - Cartagena 18”", label: "Combustoleoducto Coveñas - Cartagena 18”" },
            { agrupador: "Coveñas", valor: "Oleoducto Caucasia - Coveñas 24”", label: "Oleoducto Caucasia - Coveñas 24”" },
            { agrupador: "Magdalena", valor: "Poliducto Galán-Chimitá", label: "Poliducto Galán-Chimitá" },
            { agrupador: "Magdalena", valor: "Poliducto Sebastopol - Medellín ", label: "Poliducto Sebastopol - Medellín " },
            { agrupador: "Magdalena", valor: "Poliducto Galán-Salgar 12”", label: "Poliducto Galán-Salgar 12”" },
            { agrupador: "Magdalena", valor: "Poliducto Galán-Salgar 16”", label: "Poliducto Galán-Salgar 16”" },
            { agrupador: "Magdalena", valor: "Propanoducto Galán-Salgar 8”", label: "Propanoducto Galán-Salgar 8”" },
            { agrupador: "Magdalena", valor: "Poliducto Sebastopol - Salgar 12”", label: "Poliducto Sebastopol - Salgar 12”" },
            { agrupador: "Magdalena", valor: "Poliducto Sebastopol - Salgar 16”", label: "Poliducto Sebastopol - Salgar 16”" },
            { agrupador: "Magdalena", valor: "Propanoducto Sebastopol - Salgar 8”", label: "Propanoducto Sebastopol - Salgar 8”" },
            { agrupador: "Magdalena", valor: "PoliOriente", label: "PoliOriente" },
            { agrupador: "Magdalena", valor: "Oleoducto Ayacucho - Galán 8”", label: "Oleoducto Ayacucho - Galán 8”" },
            { agrupador: "Magdalena", valor: "Combustoleoducto Galán - Ayacucho 18”", label: "Combustoleoducto Galán - Ayacucho 18”" },
            { agrupador: "Magdalena", valor: "Oleoducto Galán - Ayacucho 14”", label: "Oleoducto Galán - Ayacucho 14”" },
            { agrupador: "Magdalena", valor: "Poliducto Pozos Colorados - Galán 14”", label: "Poliducto Pozos Colorados - Galán 14”" },
            { agrupador: "Magdalena", valor: "Naftaducto Vasconia-Velásquez", label: "Naftaducto Vasconia-Velásquez" },
            { agrupador: "Magdalena", valor: "Oleoducto Vasconia-CIB", label: "Oleoducto Vasconia-CIB" },
            { agrupador: "Magdalena", valor: "Oleoducto Vasconia - Caucasia ", label: "Oleoducto Vasconia - Caucasia " },
            { agrupador: "Meta", valor: "Poliducto de Oriente.", label: "Poliducto de Oriente." },
            { agrupador: "Meta", valor: "Poliandino", label: "Poliandino" },
            { agrupador: "Meta", valor: "Oleoducto Apiay - Monterrey 20”", label: "Oleoducto Apiay - Monterrey 20”" },
            { agrupador: "Meta", valor: "Oleoducto Castilla Apiay 16”", label: "Oleoducto Castilla Apiay 16”" },
            { agrupador: "Meta", valor: "Naftaducto Apiay - Chichimene 10”", label: "Naftaducto Apiay - Chichimene 10”" },
            { agrupador: "Meta", valor: "Oleoducto Rubiales - Monterrey", label: "Oleoducto Rubiales - Monterrey" },
            { agrupador: "Meta", valor: "Oleoducto El Viento - Cusiana", label: "Oleoducto El Viento - Cusiana" },
            { agrupador: "Casanare", valor: "Poliandino", label: "Poliandino" },
            { agrupador: "Casanare", valor: "Oleoducto Araguaney - El Porvenir", label: "Oleoducto Araguaney - El Porvenir" },
            { agrupador: "Casanare", valor: "Oleoducto Apiay - El Porvenir 20", label: "Oleoducto Apiay - El Porvenir 20" },
            { agrupador: "Casanare", valor: "Oleoducto Apiay - El Porvenir 16", label: "Oleoducto Apiay - El Porvenir 16" },
            { agrupador: "Casanare", valor: "Oleoducto Santiago - El Porvenir 10”", label: "Oleoducto Santiago - El Porvenir 10”" },
            { agrupador: "Sur", valor: "Oleoducto Churuyaco - Orito", label: "Oleoducto Churuyaco - Orito" },
            { agrupador: "Sur", valor: "Oleoducto Mansoya - Orito", label: "Oleoducto Mansoya - Orito" },
            { agrupador: "Sur", valor: "Oleoducto Orito - Tumaco", label: "Oleoducto Orito - Tumaco" },
            { agrupador: "Sur", valor: "Oleoducto San Miguel - Orito", label: "Oleoducto San Miguel - Orito" },
            { agrupador: "Occidente", valor: "Poliducto Sebastopol - Medellín", label: "Poliducto Sebastopol - Medellín" },
            { agrupador: "Occidente", valor: "Poliducto Medellín - Cartago 10”", label: "Poliducto Medellín - Cartago 10”" },
            { agrupador: "Occidente", valor: "Poliducto ODECA Salgar-Cartago", label: "Poliducto ODECA Salgar-Cartago" },
            { agrupador: "Occidente", valor: "Poliducto ODECA Cartago-Yumbo", label: "Poliducto ODECA Cartago-Yumbo" },
            { agrupador: "Occidente", valor: "Poliducto Medellín Cartago-Yumbo 10”", label: "Poliducto Medellín Cartago-Yumbo 10”" },
            { agrupador: "Occidente", valor: "Poliducto Buenaventura-Yumbo", label: "Poliducto Buenaventura-Yumbo" },
            { agrupador: "Andina", valor: "Poliducto Salgar - Neiva", label: "Poliducto Salgar - Neiva" },
            { agrupador: "Andina", valor: "Oleoducto Tello-Dina-Tenay", label: "Oleoducto Tello-Dina-Tenay" },
            { agrupador: "Andina", valor: "Poliducto Salgar - Mansilla", label: "Poliducto Salgar - Mansilla" },
            { agrupador: "Andina", valor: "Poliducto Salgar - Mansilla.", label: "Poliducto Salgar - Mansilla." },
            { agrupador: "Andina", valor: "Jet ducto Puente Aranda - El Dorado.", label: "Jet ducto Puente Aranda - El Dorado." },
            { agrupador: "Andina", valor: "Propanoducto Salgar - Mansilla", label: "Propanoducto Salgar - Mansilla" },
            { agrupador: "Andina", valor: "Propanoducto Mansilla - Vista Hermosa.", label: "Propanoducto Mansilla - Vista Hermosa." },
            { agrupador: "Andina", valor: "Poliducto ODECA Salgar-Cartago", label: "Poliducto ODECA Salgar-Cartago" },
            { agrupador: "Andina", valor: "Oleoducto Toldado-Gualanday", label: "Oleoducto Toldado-Gualanday" },
            { agrupador: "Andina", valor: "Oleoducto Yaguara-Tenay", label: "Oleoducto Yaguara-Tenay" },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.depto;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'depto',
        "contenido": "Sistema:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeSistema",
        "col-md": 12,
        "col-lg": 6
    },
    "lDucto": {
        "name": "lDucto",
        "type": "select",
        opciones: [
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Caño Limón - Banadia", label: "Caño Limón - Banadia" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Banadia - Samore", label: "Banadia - Samore" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Samore - Toledo", label: "Samore - Toledo" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Toledo - Río Zulia", label: "Toledo - Río Zulia" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Rio Zulia - Oru", label: "Rio Zulia - Oru" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Oru - Ayacucho", label: "Oru - Ayacucho" },
            { agrupador: "Oleoducto Ayacucho - Galán 8”", valor: "Ayacucho - Galán 8”", label: "Ayacucho - Galán 8”" },
            { agrupador: "Combustoleoducto Galán - Ayacucho 18”", valor: "Galán - Ayacucho 18”", label: "Galán - Ayacucho 18”" },
            { agrupador: "Oleoducto Galán - Ayacucho 14”", valor: "Galán - Ayacucho 14”", label: "Galán - Ayacucho 14”" },
            { agrupador: "Poliducto Pozos Colorados - Ayacucho 14”", valor: "Pozos Colorados - El Copey 14”", label: "Pozos Colorados - El Copey 14”" },
            { agrupador: "Poliducto Pozos Colorados - Ayacucho 14”", valor: "El Copey - Ayacucho 14”", label: "El Copey - Ayacucho 14”" },
            { agrupador: "Poliducto Ayacucho - Galán 14”", valor: "Ayacucho-Galán 14”", label: "Ayacucho-Galán 14”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Ayacucho - Retiro 16”", label: "Ayacucho - Retiro 16”" },
            { agrupador: "Oleoducto Ayacucho - Coveñas 24”", valor: "Ayacucho - Coveñas 24”", label: "Ayacucho - Coveñas 24”" },
            { agrupador: "Poliducto Cartagena - Baranoa 12”", valor: "Cartagena - Baranoa 12”", label: "Cartagena - Baranoa 12”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Ayacucho - Retiro 16”", label: "Ayacucho - Retiro 16”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Retiro - Sincé 12”", label: "Retiro - Sincé 12”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Sincé - Coveñas 12”", label: "Sincé - Coveñas 12”" },
            { agrupador: "Loop Since - Coveñas 16”", valor: "Loop Sincé - Coveñas 16”", label: "Loop Sincé - Coveñas 16”" },
            { agrupador: "Oleoducto Ayacucho - Coveñas 24”", valor: "Ayacucho - Coveñas 24”", label: "Ayacucho - Coveñas 24”" },
            { agrupador: "Combustoleoducto Coveñas - Cartagena 18”", valor: "Coveñas - Cartagena 18”", label: "Coveñas - Cartagena 18”" },
            { agrupador: "Oleoducto Caucasia - Coveñas 24”", valor: "Caucasia - Coveñas 24”", label: "Caucasia - Coveñas 24”" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Galán-Lizama", label: "Galán-Lizama" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Lizama-Trampa Tienda Nueva", label: "Lizama-Trampa Tienda Nueva" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Trampa Tienda Nueva - Trampa Guayacan", label: "Trampa Tienda Nueva - Trampa Guayacan" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Trampa Guayacan - Chimita", label: "Trampa Guayacan - Chimita" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "Sebastopol - San Jose del Nus", label: "Sebastopol - San Jose del Nus" },
            { agrupador: "Poliducto Galán-Salgar 12”", valor: "Galán-Sebastopol 12”", label: "Galán-Sebastopol 12”" },
            { agrupador: "Poliducto Galán-Salgar 16”", valor: "Galán-Sebastopol 16”", label: "Galán-Sebastopol 16”" },
            { agrupador: "Propanoducto Galán-Salgar 8”", valor: "Galán-Sebastopol 8”", label: "Galán-Sebastopol 8”" },
            { agrupador: "Poliducto Sebastopol - Salgar 12”", valor: "Sebastopol - Salgar 12 ”", label: "Sebastopol - Salgar 12 ”" },
            { agrupador: "Poliducto Sebastopol - Salgar 16”", valor: "Sebastopol - Salgar 16 ”", label: "Sebastopol - Salgar 16 ”" },
            { agrupador: "Propanoducto Sebastopol - Salgar 8”", valor: "Sebastopol - Salgar  8”", label: "Sebastopol - Salgar  8”" },
            { agrupador: "PoliOriente", valor: "Sebastopol - SantaRosa", label: "Sebastopol - SantaRosa" },
            { agrupador: "Oleoducto Ayacucho - Galán 8”", valor: "Ayacucho - Galán 8”", label: "Ayacucho - Galán 8”" },
            { agrupador: "Combustoleoducto Galán - Ayacucho 18”", valor: "Galán - Ayacucho 18”", label: "Galán - Ayacucho 18”" },
            { agrupador: "Oleoducto Galán - Ayacucho 14”", valor: "Galán - Ayacucho 14”", label: "Galán - Ayacucho 14”" },
            { agrupador: "Poliducto Pozos Colorados - Galán 14”", valor: "Ayacucho-Galán 14”", label: "Ayacucho-Galán 14”" },
            { agrupador: "Naftaducto Vasconia-Velásquez", valor: "Vasconia - Velásquez 12”", label: "Vasconia - Velásquez 12”" },
            { agrupador: "Oleoducto Vasconia-CIB", valor: "Vasconia - CIB 20”", label: "Vasconia - CIB 20”" },
            { agrupador: "Oleoducto Vasconia - Caucasia ", valor: "Vasconia - Caucasia ", label: "Vasconia - Caucasia " },
            { agrupador: "Poliducto de Oriente.", valor: "Santa Rosa - Sutamarchan", label: "Santa Rosa - Sutamarchan" },
            { agrupador: "Poliducto de Oriente.", valor: "Sutamarchan - Tocancipa", label: "Sutamarchan - Tocancipa" },
            { agrupador: "Poliandino", valor: "Sutamarchan - Miraflores", label: "Sutamarchan - Miraflores" },
            { agrupador: "Poliandino", valor: "Monterrey- Apiay 16", label: "Monterrey- Apiay 16" },
            { agrupador: "Oleoducto Apiay - Monterrey 20”", valor: "Apiay-Monterrey 20", label: "Apiay-Monterrey 20”" },
            { agrupador: "Oleoducto Castilla Apiay 16”", valor: "Castilla-Apiay 16”", label: "Castilla-Apiay 16”" },
            { agrupador: "Naftaducto Apiay - Chichimene 10”", valor: "Chichimene - Apiay 10”", label: "Chichimene - Apiay 10”" },
            { agrupador: "Oleoducto Rubiales - Monterrey", valor: "Rubiales - Monterrey", label: "Rubiales - Monterrey" },
            { agrupador: "Oleoducto El Viento - Cusiana", valor: "El Viento - Cusiana", label: "El Viento - Cusiana" },
            { agrupador: "Poliandino", valor: "Miraflores - Monterrey 12”", label: "Miraflores - Monterrey 12”" },
            { agrupador: "Oleoducto Araguaney - El Porvenir", valor: "Araguaney - El Porvenir", label: "Araguaney - El Porvenir" },
            { agrupador: "Oleoducto Apiay - El Porvenir 20", valor: "Monterrey - Altos del Porvenir 20”", label: "Monterrey - Altos del Porvenir 20”" },
            { agrupador: "Oleoducto Apiay - El Porvenir 20", valor: "Altos del Porvenir - El Porvenir 24”", label: "Altos del Porvenir - El Porvenir 24”" },
            { agrupador: "Oleoducto Apiay - El Porvenir 16", valor: "Monterrey - El Porvenir 12", label: "Monterrey - El Porvenir 12" },
            { agrupador: "Oleoducto Santiago - El Porvenir 10”", valor: "Santiago - Monterrey 10", label: "Santiago - Monterrey 10”" },
            { agrupador: "Oleoducto Santiago - El Porvenir 10”", valor: "Monterrey - El Porvenir 10", label: "Monterrey - El Porvenir 10”" },
            { agrupador: "Oleoducto Churuyaco - Orito", valor: "Churuyaco - Orito", label: "Churuyaco - Orito" },
            { agrupador: "Oleoducto Mansoya - Orito", valor: "Mansoya - Santana", label: "Mansoya - Santana" },
            { agrupador: "Oleoducto Mansoya - Orito", valor: "Santana - Yarumo", label: "Santana - Yarumo" },
            { agrupador: "Oleoducto Orito - Tumaco", valor: "Orito - Guamuez", label: "Orito - Guamuez" },
            { agrupador: "Oleoducto Orito - Tumaco", valor: "Guamuez - Alisales", label: "Guamuez - Alisales" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Buenaventura-Gallineros 8”", label: "Buenaventura-Gallineros 8”" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Gallineros -Dagua 12”", label: "Gallineros -Dagua 12”" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Dagua - Mulalo 12”", label: "Dagua - Mulalo 12”" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Mulalo - Yumbo 6”", label: "Mulalo - Yumbo 6”" },
            { agrupador: "Poliducto Medellín - Cartago 10”", valor: "Medellín - La Felisa 10”", label: "Medellín - La Felisa 10”" },
            { agrupador: "Poliducto Medellín - Cartago 10”", valor: "La Felisa - Cartago 10”", label: "La Felisa - Cartago 10”" },
            { agrupador: "Poliducto Medellín Cartago-Yumbo 10”", valor: "Cartago-Yumbo 10”", label: "Cartago-Yumbo 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Cartago-Zarzal 10”", label: "Cartago-Zarzal 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Zarzal-Lucerna 10”", label: "Zarzal-Lucerna 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Lucerna-Guacari 8” 10”", label: "Lucerna-Guacari 8” 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Guacari-Yumbo 6” 8”", label: "Guacari-Yumbo 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Mariquita - Fresno 6” 8”", label: "Mariquita - Fresno 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Fresno - Herveo 6” 8”", label: "Fresno - Herveo 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Herveo - Manizales 6” 8”", label: "Herveo - Manizales 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Manizales - Cartago 8”", label: "Manizales - Cartago 8”" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "San Jose del Nus - Est. Cisneros Nueva 12”", label: "San Jose del Nus - Est. Cisneros Nueva 12”" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "Est. Cisneros Nueva - Est. Cisneros Antigua 12”", label: "Est. Cisneros Nueva - Est. Cisneros Antigua 12”" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "Est. Cisneros Antigua - Medellín 12”", label: "Est. Cisneros Antigua - Medellín 12”" },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.sistema;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'sistema',
        "contenido": "Línea de ducto",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "diametro": {
        "name": "diametro",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: 6, label: "6", },
            { agrupador: undefined, valor: 8, label: "8", },
            { agrupador: undefined, valor: 10, label: "10", },
            { agrupador: undefined, valor: 12, label: "12", },
            { agrupador: undefined, valor: 14, label: "14", },
            { agrupador: undefined, valor: 16, label: "16", },
            { agrupador: undefined, valor: 18, label: "18", },
            { agrupador: undefined, valor: 20, label: "20", },
            { agrupador: undefined, valor: 24, label: "24", },
            { agrupador: undefined, valor: 30, label: "30", },
            { agrupador: undefined, valor: 36, label: "36", },
        ],
        // obtenerOpciones: (data) => {
        //     return this.opciones
        // },
        "contenido": "Diámetro:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDiametro",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorNominal": {
        "name": "espesorNominal",
        "type": "text",
        "contenido": "Espesor nominal:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeEspesorNominal",
        "col-md": 12,
        "col-lg": 6
    },
    "SMYS": {
        "name": "SMYS",
        "type": "text",
        "contenido": "SMYS:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeSMYS",
        "col-md": 12,
        "col-lg": 6
    },
    "MAOP": {
        "name": "MAOP",
        "type": "text",
        "contenido": "MAOP:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeMAOP",
        "col-md": 12,
        "col-lg": 6
    },
    "CEMOP": {
        "name": "CEMOP",
        "type": "text",
        "contenido": "CEMOP:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeCEMOP",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioDeInputsIDAnomaliaILI = {
    "distanciaRegistroDucto": {
        "name": "distanciaRegistroDucto",
        "type": "text",
        "contenido": "Distancia de Registro:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistroDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudDeDuctoDecimal": {
        "name": "longitudDeDuctoDecimal",
        "type": "text",
        "contenido": "Longitud (º):",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudDeDuctoDecimal",
        "col-md": 12,
        "col-lg": 6
    },
    "latitudDeDuctoDecimal": {
        "name": "latitudDeDuctoDecimal",
        "type": "text",
        "contenido": "Latitud (º):",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLatitudDeDuctoDecimal",
        "col-md": 12,
        "col-lg": 6
    },
    "lTubo": {
        "name": "lTubo",
        "type": "text",
        "contenido": "Longitud Tubo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLTubo",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaAlRegistroDelDucto": {
        "name": "distanciaAlRegistroDelDucto",
        "type": "text",
        "contenido": "Espesor nominal:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaAlRegistroDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudAnomaliaDelDucto": {
        "name": "longitudAnomaliaDelDucto",
        "type": "text",
        "contenido": "Longitud de la Anomalía:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudAnomaliaDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoAnomaliaDelDucto": {
        "name": "anchoAnomaliaDelDucto",
        "type": "text",
        "contenido": "Ancho de la Anomalía:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnchoAnomaliaDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaJuntaAnteriorDelDucto": {
        "name": "distanciaJuntaAnteriorDelDucto",
        "type": "text",
        "contenido": "Distancia a la Junta Anterior:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaAnteriorDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaJuntaPosteriorDelDucto": {
        "name": "distanciaJuntaPosteriorDelDucto",
        "type": "text",
        "contenido": "Distancia a la Junta Posterior:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaPosteriorDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "posicionHorariaDelDucto": {
        "name": "posicionHorariaDelDucto",
        "type": "text",
        "contenido": "Posición Horaria de la Anomalía:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajePosicionHorariaDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "anhoDeLaCorridaDelDucto": {
        "name": "anhoDeLaCorridaDelDucto",
        "type": "text",
        "contenido": "Año de la Corrida:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnhoDeLaCorridaDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "porcentajeDeMetalOReduccionDelIdDelDucto": {
        "name": "porcentajeDeMetalOReduccionDelIdDelDucto",
        "type": "text",
        "contenido": "Pérdida de Metal o Reducción del ID:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeporcentajeDeMetalOReduccionDelIdDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "tipoDeAnomaliaDelDucto": {
        "name": "tipoDeAnomaliaDelDucto",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: "Anomalías de perdida de metal", label: "Anomalías de perdida de metal", },
            { agrupador: undefined, valor: "Anomalías tipo grieta", label: "Anomalías tipo grieta", },
            { agrupador: undefined, valor: "Anomalías por deformación", label: "Anomalías por deformación", },
            { agrupador: undefined, valor: "Anomalías por metalúrgia", label: "Anomalías por metalúrgia", },
            { agrupador: undefined, valor: "Otras anomalías", label: "Otras anomalías", },
        ],
        "contenido": "Tipo de Anomalía:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoDeAnomaliaDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "programadaOAdicionalDelDucto": {
        "name": "programadaOAdicionalDelDucto",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: "Programada", label: "Programada", },
            { agrupador: undefined, valor: "No programada", label: "No programada", },
            { agrupador: undefined, valor: "Oportunidad", label: "Oportunidad", },
            { agrupador: undefined, valor: "Validación", label: "Validación", },
            { agrupador: undefined, valor: "Adicional", label: "Adicional", },
        ],
        "contenido": "Programada o Adicional:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProgramadaOAdicionalDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "clasificacionDelDucto": {
        "name": "clasificacionDelDucto",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: "Pinhole", label: "Pinhole", },
            { agrupador: undefined, valor: "Pitting", label: "Pitting", },
            { agrupador: undefined, valor: "General", label: "General", },
            { agrupador: undefined, valor: "Axial Slotting", label: "Axial Slotting", },
            { agrupador: undefined, valor: "Circumferential Slotting", label: "Circumferential Slotting", },
            { agrupador: undefined, valor: "Axial Grooving", label: "Axial Grooving", },
            { agrupador: undefined, valor: "Circumferential Grooving", label: "Circumferential Grooving", },
            { agrupador: undefined, valor: "Daños Mecánimos", label: "Daños Mecánicos", },
        ],
        "contenido": "Clasificación:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeClasificacionDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "enParedDelDucto": {
        "name": "enParedDelDucto",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: "Externa", label: "Externa", },
            { agrupador: undefined, valor: "Interna", label: "Interna", },
        ],
        "contenido": "En Pared?:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEnParedDelDucto",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioDeInputsUbGeograficaILI = {
    "departamentoUbicacionGeografica": {
        "name": "departamentoUbicacionGeografica",
        "type": "text",
        "contenido": "Departamento:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDepartamentoUbicacionGeografica",
        "col-md": 12,
        "col-lg": 6
    },
    "municipioUbicacionGeografica": {
        "name": "municipioUbicacionGeografica",
        "type": "text",
        "contenido": "Municipio:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeMunicipioUbicacionGeografica",
        "col-md": 12,
        "col-lg": 6
    },
    "sectorOVeredaUbicacionGeografica": {
        "name": "sectorOVeredaUbicacionGeografica",
        "type": "text",
        "contenido": "Sector o Vereda:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeSectorOVeredaUbicacionGeografica",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioDeInputsDatosExcavacionILI = {
    "distanciaRegistroExcavacionInicial": {
        "name": "distanciaRegistroExcavacionInicial",
        "type": "text",
        "contenido": "Distancia de Excavación Inicial:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistroExcavacionInicial",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaRegistroExcavacionFinal": {
        "name": "distanciaRegistroExcavacionFinal",
        "type": "text",
        "contenido": "Distancia de Excavación Final:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistroExcavacionFinal",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudDeExcavacion": {
        "name": "longitudDeExcavacion",
        "type": "text",
        "contenido": "Longitud De Excavación:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudDeExcavacion",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoDeExcavacion": {
        "name": "anchoDeExcavacion",
        "type": "text",
        "contenido": "Ancho de la Excavación:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnchoDeExcavacion",
        "col-md": 12,
        "col-lg": 6
    },
    "profundidadDeExcavacion": {
        "name": "profundidadDeExcavacion",
        "type": "text",
        "contenido": "Profundidad de la Excavación:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidadDeExcavacion",
        "col-md": 12,
        "col-lg": 6
    },
    "tipoDeTerreno": {
        "name": "tipoDeTerreno",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: "Arenoso", label: "Arenoso", },
            { agrupador: undefined, valor: "Calizo", label: "Calizo", },
            { agrupador: undefined, valor: "Tierra Negra", label: "Tierra Negra", },
            { agrupador: undefined, valor: "Arcilloso", label: "Arcilloso", },
            { agrupador: undefined, valor: "Pedregoso", label: "Pedregoso", },
            { agrupador: undefined, valor: "Mixto (Arenoso-Arcilloso)", label: "Mixto (Arenoso-Arcilloso)", },
        ],
        "contenido": "Tipo de Terreno:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoDeTerreno",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioDeInputsGeoILI = {
    "pkCampo": {
        "name": "pkCampo",
        "type": "text",
        "contenido": "PK de Campo:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajePkCampo",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudDeAnomaliaDecimal": {
        "name": "longitudDeAnomaliaDecimal",
        "type": "text",
        "contenido": "Longitud (º):",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudDeAnomaliaDecimal",
        "col-md": 12,
        "col-lg": 6
    },
    "latitudDeAnomaliaDecimal": {
        "name": "latitudDeAnomaliaDecimal",
        "type": "text",
        "contenido": "Latitud (º):",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLatitudDeAnomaliaDecimal",
        "col-md": 12,
        "col-lg": 6
    },
    "ubicacionTuberiaAnomalia": {
        "name": "ubicacionTuberiaAnomalia",
        "type": "select",
        opciones: [

            { agrupador: undefined, valor: "Enterrada", label: "Enterrada" },
            { agrupador: undefined, valor: "Aérea", label: "Aérea" },
            { agrupador: undefined, valor: "Interfaz", label: "Interfaz" },

        ],
        "contenido": "Ubicación de la Tubería:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeUbicacionTuberiaAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudTubo": {
        "name": "longitudTubo",
        "type": "text",
        "contenido": "Longitud del Tubo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudTubo",
        "col-md": 12,
        "col-lg": 6
    },
    "precisionDelGPS": {
        "name": "precisionDelGPS",
        "type": "text",
        "contenido": "Precisión del GPS:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajePrecisionDelGPS",
        "col-md": 12,
        "col-lg": 6
    },
    "breadCurumbEspesores": {
        'name': 'ESPESORES Z'
    },
    "separador_12_i": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "espesorZ1_12": {
        "name": "espesorZ1_12",
        "type": "text",
        "contenido": "Z1 a las 12:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ1_12",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ2_12": {
        "name": "espesorZ2_12",
        "type": "text",
        "contenido": "Z2 a las 12:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ2_12",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ3_12": {
        "name": "espesorZ3_12",
        "type": "text",
        "contenido": "Z3 a las 12:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ3_12",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ4_12": {
        "name": "espesorZ4_12",
        "type": "text",
        "contenido": "Z4 a las 12:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ4_12",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ5_12": {
        "name": "espesorZ5_12",
        "type": "text",
        "contenido": "Z5 a las 12:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ5_12",
        "col-md": 12,
        "col-lg": 2
    },
    "separador_12_d": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "separador_3_i": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "espesorZ1_3": {
        "name": "espesorZ1_3",
        "type": "text",
        "contenido": "Z1 a las 3:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ1_3",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ2_3": {
        "name": "espesorZ2_3",
        "type": "text",
        "contenido": "Z2 a las 3:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ2_3",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ3_3": {
        "name": "espesorZ3_3",
        "type": "text",
        "contenido": "Z3 a las 3:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ3_3",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ4_3": {
        "name": "espesorZ4_3",
        "type": "text",
        "contenido": "Z4 a las 3:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ4_3",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ5_3": {
        "name": "espesorZ5_3",
        "type": "text",
        "contenido": "Z5 a las 3:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ5_3",
        "col-md": 12,
        "col-lg": 2
    },
    "separador_3_d": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "separador_6_i": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "espesorZ1_6": {
        "name": "espesorZ1_6",
        "type": "text",
        "contenido": "Z1 a las 6:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ1_6",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ2_6": {
        "name": "espesorZ2_6",
        "type": "text",
        "contenido": "Z2 a las 6:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ2_6",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ3_6": {
        "name": "espesorZ3_6",
        "type": "text",
        "contenido": "Z3 a las 6:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ3_6",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ4_6": {
        "name": "espesorZ4_6",
        "type": "text",
        "contenido": "Z4 a las 6:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ4_6",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ5_6": {
        "name": "espesorZ5_6",
        "type": "text",
        "contenido": "Z5 a las 6:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ5_6",
        "col-md": 12,
        "col-lg": 2
    },
    "separador_6_d": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "separador_9_i": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "espesorZ1_9": {
        "name": "espesorZ1_9",
        "type": "text",
        "contenido": "Z1 a las 9:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ1_9",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ2_9": {
        "name": "espesorZ2_9",
        "type": "text",
        "contenido": "Z2 a las 9:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ2_9",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ3_9": {
        "name": "espesorZ3_9",
        "type": "text",
        "contenido": "Z3 a las 9:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ3_9",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ4_9": {
        "name": "espesorZ4_9",
        "type": "text",
        "contenido": "Z4 a las 9:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ4_9",
        "col-md": 12,
        "col-lg": 2
    },
    "espesorZ5_9": {
        "name": "espesorZ5_9",
        "type": "text",
        "contenido": "Z5 a las 9:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorZ5_9",
        "col-md": 12,
        "col-lg": 2
    },
    "separador_9_d": {
        "type": "separador",
        "col-md": 12,
        "col-lg": 1
    },
    "espesorMinimo": {
        "name": "espesorMinimo",
        "type": "text",
        "contenido": "Espesor Mínimo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorMinimo",
        "col-md": 12,
        "col-lg": 4
    },
    "espesorMaximo": {
        "name": "espesorMaximo",
        "type": "text",
        "contenido": "Espesor Máximo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorMaximo",
        "col-md": 12,
        "col-lg": 4
    },
    "espesorPromedio": {
        "name": "espesorPromedio",
        "type": "text",
        "contenido": "Espesor Promedio:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorPromedio",
        "col-md": 12,
        "col-lg": 4
    },
}

export const glosarioDeInputsODTHija = {
    "numeroDeFormato": {
        "name": "numeroDeFormato",
        "contenido": "Número de Formato" ,
        "type": "text",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeNumeroDeFormato",
        "col-md": 12,
        "col-lg": 4
    },
    "index": {
        "name": "index",
        "contenido": "+ Index",
        "type": "text",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeIndex",
        "col-md": 12,
        "col-lg": 2
    },
    "ordenDeTrabajo": {
        "name": "ordenDeTrabajo",
        "contenido": "Orden de Trabajo",
        "type": "text",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeOrdenDeTrabajo",
        "col-md": 12,
        "col-lg": 6
    },
    "estado": {
        "name": "estado",
        "opciones": [
            { agrupador: undefined, valor: "PLANEADA", label: "Planeada", },
        ],
        "contenido": "Estado:",
        "disabled": "true",
        "type": "select",
        "categoria": "valorDeSeleccionMultiple",
        "mensaje": "mensajeEstado",
        "col-md": 12,
        "col-lg": 6
    },
    "fechaEstimadaDeProgramacion": {
        "name": "fechaEstimadaDeProgramacion",
        "type": "fecha",
        "contenido": "Fecha estimada de programación:",
        "tipo": "fecha",
        "categoria": "valorUnico",
        "mensaje": "mensajeFechaEstimada",
        "col-md": 6,
        "col-lg": 6,
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "descripcionODT": {
        "name": "descripcionODT",
        "type": "textarea",
        "contenido": "Descripción de la órden de trabajo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDescripcionODT",
        "col-md": 12,
        "col-lg": 12
    },
}

export const glosarioEdicionODTMadre = {
    "estado": {
        "name": "estado",
        "opciones": [
            { agrupador: undefined, valor: "PLANEADA", label: "Planeada", },
            { agrupador: undefined, valor: "PROGRAMADA", label: "Programada", },
            { agrupador: undefined, valor: "EN_SITIO", label: "En Sitio", },
            { agrupador: undefined, valor: "INSPECCIONADA", label: "Inspeccionada", },
            { agrupador: undefined, valor: "EJECUTADA", label: "Ejecutada", },
            { agrupador: undefined, valor: "REVISADA", label: "Revisada", },
            { agrupador: undefined, valor: "CONTROL_CALIDAD", label: "Control de Calidad", },
            { agrupador: undefined, valor: "FACTURADA", label: "Facturada", },
            { agrupador: undefined, valor: "CANCELADA", label: "Cancelada", },
        ],
        "contenido": "Estado:",
        "disabled": "true",
        "type": "select",
        "categoria": "valorDeSeleccionMultiple",
        "mensaje": "mensajeEstado",
        "col-md": 12,
        "col-lg": 6
    },
    "fechaEstimadaDeProgramacion": {
        "name": "fechaEstimadaDeProgramacion",
        "type": "fecha",
        "contenido": "Fecha estimada de programación:",
        "tipo": "fecha",
        "categoria": "valorUnico",
        "mensaje": "mensajeFechaEstimada",
        "col-md": 6,
        "col-lg": 6,
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaRealDeInicio": {
        "name": "fechaRealDeInicio",
        "type": "fecha",
        "contenido": "Fecha real de inicio:",
        "tipo": "fecha",
        "categoria": "valorUnico",
        "mensaje": "mensajeFechaRealDeInicio",
        "col-md": 6,
        "col-lg": 6,
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaRealDeProgramacion": {
        "name": "fechaRealDeProgramacion",
        "type": "fecha",
        "contenido": "Fecha real de programación:",
        "tipo": "fecha",
        "categoria": "valorUnico",
        "mensaje": "mensajeRealDeProgramacion",
        "col-md": 6,
        "col-lg": 6,
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "fechaRealDeFinalizacion": {
        "name": "fechaRealDeFinalizacion",
        "type": "fecha",
        "contenido": "Fecha real de finalización:",
        "tipo": "fecha",
        "categoria": "valorUnico",
        "mensaje": "mensajeFechaRealFinalizacion",
        "col-md": 6,
        "col-lg": 6,
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": "fecha_actual"
            }
        ]
    },
    "descripcionODT": {
        "name": "descripcionODT",
        "type": "textarea",
        "contenido": "Descripción de la órden de trabajo:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDescripcionODT",
        "col-md": 12,
        "col-lg": 12
    },
    "latInicial": {
        "name": "latInicial",
        "type": "text",
        "contenido": "Latitud inicial: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLatInicial",
        "col-md": 12,
        "col-lg": 6
    },
    "lngInicial": {
        "name": "lngInicial",
        "type": "text",
        "contenido": "Longitud inicial: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLngInicial",
        "col-md": 12,
        "col-lg": 6
    },
    "latFinal": {
        "name": "latFinal",
        "type": "text",
        "contenido": "Latitud final: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLatFinal",
        "col-md": 12,
        "col-lg": 6
    },
    "lngFinal": {
        "name": "lngFinal",
        "type": "text",
        "contenido": "Longitud final: (decimal)",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": "<=",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeLngFinal",
        "col-md": 12,
        "col-lg": 6
    },
    "zonaDeDucto": {
        "name": "zonaDeDucto",
        "type": "select",
        opciones: [
            //{ valor: "Sur", label: "Sur" },
            { valor: "Norte", label: "Norte" },
        ],
        "contenido": "Zona:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeZona",
        "col-md": 12,
        "col-lg": 6
    },
    "regionDeDucto": {
        "name": "regionDeDucto",
        "type": "select",
        opciones: [
            { agrupador: "Norte", valor: "Región Caribe", label: "Región Caribe", },
            { agrupador: "Sur", valor: "Región Centro", label: "Región Centro", },
            { agrupador: "Norte", valor: "Región Magdalena", label: "Región Magdalena", },
            { agrupador: "Sur", valor: "Región Occidental", label: "Región Occidental", },
            { agrupador: "Norte", valor: "Región Oriente", label: "Región Oriente", },
            { agrupador: "Sur", valor: "Región Sur", label: "Región Sur", },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.zonaDeDucto;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'zonaDeDucto',
        "contenido": "Región:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRegion",
        "col-md": 12,
        "col-lg": 6
    },
    "departamentoOYMDeDucto": {
        "name": "departamentoOYMDeDucto",
        "type": "select",
        opciones: [
            { agrupador: "Región Oriente", valor: "Oriente", label: "Oriente", },
            { agrupador: "Región Caribe", valor: "Caribe", label: "Caribe", },
            { agrupador: "Región Caribe", valor: "Coveñas", label: "Coveñas", },
            { agrupador: "Región Magdalena", valor: "Magdalena", label: "Magdalena", },
            { agrupador: "Región Centro", valor: "Meta", label: "Meta", },
            { agrupador: "Región Centro", valor: "Casanare", label: "Casanare", },
            { agrupador: "Región Sur", valor: "Sur", label: "Sur", },
            { agrupador: "Región Occidental", valor: "Occidente", label: "Occidente", },
            { agrupador: "Región Centro", valor: "Andina", label: "Andina", },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.regionDeDucto;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'regionDeDucto',
        "contenido": "Depto O&M:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDepto",

        "col-md": 12,
        "col-lg": 6
    },
    "sistemaDeDucto": {
        "name": "sistemaDeDucto",
        "type": "select",
        opciones: [
            { agrupador: "Oriente", valor: "Oleoducto Caño Limón - Ayacucho 24”", label: "Oleoducto Caño Limón - Ayacucho 24”" },
            { agrupador: "Caribe", valor: "Oleoducto Ayacucho - Galán 8”", label: "Oleoducto Ayacucho - Galán 8”" },
            { agrupador: "Caribe", valor: "Combustoleoducto Galán - Ayacucho 18”", label: "Combustoleoducto Galán - Ayacucho 18”" },
            { agrupador: "Caribe", valor: "Oleoducto Galán - Ayacucho 14”", label: "Oleoducto Galán - Ayacucho 14”" },
            { agrupador: "Caribe", valor: "Poliducto Pozos Colorados - Ayacucho 14”", label: "Poliducto Pozos Colorados - Ayacucho 14”" },
            { agrupador: "Caribe", valor: "Poliducto Ayacucho - Galán 14”", label: "Poliducto Ayacucho - Galán 14”" },
            { agrupador: "Caribe", valor: "Combustoleoducto Ayacucho -Coveñas 16”", label: "Combustoleoducto Ayacucho -Coveñas 16”" },
            { agrupador: "Caribe", valor: "Oleoducto Ayacucho - Coveñas 24”", label: "Oleoducto Ayacucho - Coveñas 24”" },
            { agrupador: "Caribe", valor: "Poliducto Cartagena - Baranoa 12”", label: "Poliducto Cartagena - Baranoa 12”" },
            { agrupador: "Coveñas", valor: "Combustoleoducto Ayacucho -Coveñas 16”", label: "Combustoleoducto Ayacucho -Coveñas 16”" },
            { agrupador: "Coveñas", valor: "Loop Since - Coveñas 16”", label: "Loop Since - Coveñas 16”" },
            { agrupador: "Coveñas", valor: "Oleoducto Ayacucho - Coveñas 24”", label: "Oleoducto Ayacucho - Coveñas 24”" },
            { agrupador: "Coveñas", valor: "Combustoleoducto Coveñas - Cartagena 18”", label: "Combustoleoducto Coveñas - Cartagena 18”" },
            { agrupador: "Coveñas", valor: "Oleoducto Caucasia - Coveñas 24”", label: "Oleoducto Caucasia - Coveñas 24”" },
            { agrupador: "Magdalena", valor: "Poliducto Galán-Chimitá", label: "Poliducto Galán-Chimitá" },
            { agrupador: "Magdalena", valor: "Poliducto Sebastopol - Medellín ", label: "Poliducto Sebastopol - Medellín " },
            { agrupador: "Magdalena", valor: "Poliducto Galán-Salgar 12”", label: "Poliducto Galán-Salgar 12”" },
            { agrupador: "Magdalena", valor: "Poliducto Galán-Salgar 16”", label: "Poliducto Galán-Salgar 16”" },
            { agrupador: "Magdalena", valor: "Propanoducto Galán-Salgar 8”", label: "Propanoducto Galán-Salgar 8”" },
            { agrupador: "Magdalena", valor: "Poliducto Sebastopol - Salgar 12”", label: "Poliducto Sebastopol - Salgar 12”" },
            { agrupador: "Magdalena", valor: "Poliducto Sebastopol - Salgar 16”", label: "Poliducto Sebastopol - Salgar 16”" },
            { agrupador: "Magdalena", valor: "Propanoducto Sebastopol - Salgar 8”", label: "Propanoducto Sebastopol - Salgar 8”" },
            { agrupador: "Magdalena", valor: "PoliOriente", label: "PoliOriente" },
            { agrupador: "Magdalena", valor: "Oleoducto Ayacucho - Galán 8”", label: "Oleoducto Ayacucho - Galán 8”" },
            { agrupador: "Magdalena", valor: "Combustoleoducto Galán - Ayacucho 18”", label: "Combustoleoducto Galán - Ayacucho 18”" },
            { agrupador: "Magdalena", valor: "Oleoducto Galán - Ayacucho 14”", label: "Oleoducto Galán - Ayacucho 14”" },
            { agrupador: "Magdalena", valor: "Poliducto Pozos Colorados - Galán 14”", label: "Poliducto Pozos Colorados - Galán 14”" },
            { agrupador: "Magdalena", valor: "Naftaducto Vasconia-Velásquez", label: "Naftaducto Vasconia-Velásquez" },
            { agrupador: "Magdalena", valor: "Oleoducto Vasconia-CIB", label: "Oleoducto Vasconia-CIB" },
            { agrupador: "Magdalena", valor: "Oleoducto Vasconia - Caucasia ", label: "Oleoducto Vasconia - Caucasia " },
            { agrupador: "Meta", valor: "Poliducto de Oriente.", label: "Poliducto de Oriente." },
            { agrupador: "Meta", valor: "Poliandino", label: "Poliandino" },
            { agrupador: "Meta", valor: "Oleoducto Apiay - Monterrey 20”", label: "Oleoducto Apiay - Monterrey 20”" },
            { agrupador: "Meta", valor: "Oleoducto Castilla Apiay 16”", label: "Oleoducto Castilla Apiay 16”" },
            { agrupador: "Meta", valor: "Naftaducto Apiay - Chichimene 10”", label: "Naftaducto Apiay - Chichimene 10”" },
            { agrupador: "Meta", valor: "Oleoducto Rubiales - Monterrey", label: "Oleoducto Rubiales - Monterrey" },
            { agrupador: "Meta", valor: "Oleoducto El Viento - Cusiana", label: "Oleoducto El Viento - Cusiana" },
            { agrupador: "Casanare", valor: "Poliandino", label: "Poliandino" },
            { agrupador: "Casanare", valor: "Oleoducto Araguaney - El Porvenir", label: "Oleoducto Araguaney - El Porvenir" },
            { agrupador: "Casanare", valor: "Oleoducto Apiay - El Porvenir 20", label: "Oleoducto Apiay - El Porvenir 20" },
            { agrupador: "Casanare", valor: "Oleoducto Apiay - El Porvenir 16", label: "Oleoducto Apiay - El Porvenir 16" },
            { agrupador: "Casanare", valor: "Oleoducto Santiago - El Porvenir 10”", label: "Oleoducto Santiago - El Porvenir 10”" },
            { agrupador: "Sur", valor: "Oleoducto Churuyaco - Orito", label: "Oleoducto Churuyaco - Orito" },
            { agrupador: "Sur", valor: "Oleoducto Mansoya - Orito", label: "Oleoducto Mansoya - Orito" },
            { agrupador: "Sur", valor: "Oleoducto Orito - Tumaco", label: "Oleoducto Orito - Tumaco" },
            { agrupador: "Sur", valor: "Oleoducto San Miguel - Orito", label: "Oleoducto San Miguel - Orito" },
            { agrupador: "Occidente", valor: "Poliducto Sebastopol - Medellín", label: "Poliducto Sebastopol - Medellín" },
            { agrupador: "Occidente", valor: "Poliducto Medellín - Cartago 10”", label: "Poliducto Medellín - Cartago 10”" },
            { agrupador: "Occidente", valor: "Poliducto ODECA Salgar-Cartago", label: "Poliducto ODECA Salgar-Cartago" },
            { agrupador: "Occidente", valor: "Poliducto ODECA Cartago-Yumbo", label: "Poliducto ODECA Cartago-Yumbo" },
            { agrupador: "Occidente", valor: "Poliducto Medellín Cartago-Yumbo 10”", label: "Poliducto Medellín Cartago-Yumbo 10”" },
            { agrupador: "Occidente", valor: "Poliducto Buenaventura-Yumbo", label: "Poliducto Buenaventura-Yumbo" },
            { agrupador: "Andina", valor: "Poliducto Salgar - Neiva", label: "Poliducto Salgar - Neiva" },
            { agrupador: "Andina", valor: "Oleoducto Tello-Dina-Tenay", label: "Oleoducto Tello-Dina-Tenay" },
            { agrupador: "Andina", valor: "Poliducto Salgar - Mansilla", label: "Poliducto Salgar - Mansilla" },
            { agrupador: "Andina", valor: "Poliducto Salgar - Mansilla.", label: "Poliducto Salgar - Mansilla." },
            { agrupador: "Andina", valor: "Jet ducto Puente Aranda - El Dorado.", label: "Jet ducto Puente Aranda - El Dorado." },
            { agrupador: "Andina", valor: "Propanoducto Salgar - Mansilla", label: "Propanoducto Salgar - Mansilla" },
            { agrupador: "Andina", valor: "Propanoducto Mansilla - Vista Hermosa.", label: "Propanoducto Mansilla - Vista Hermosa." },
            { agrupador: "Andina", valor: "Poliducto ODECA Salgar-Cartago", label: "Poliducto ODECA Salgar-Cartago" },
            { agrupador: "Andina", valor: "Oleoducto Toldado-Gualanday", label: "Oleoducto Toldado-Gualanday" },
            { agrupador: "Andina", valor: "Oleoducto Yaguara-Tenay", label: "Oleoducto Yaguara-Tenay" },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.departamentoOYMDeDucto;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'departamentoOYMDeDucto',
        "contenido": "Sistema:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeSistema",
        "col-md": 12,
        "col-lg": 6
    },
    "lineaDeDucto": {
        "name": "lineaDeDucto",
        "type": "select",
        opciones: [
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Caño Limón - Banadia", label: "Caño Limón - Banadia" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Banadia - Samore", label: "Banadia - Samore" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Samore - Toledo", label: "Samore - Toledo" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Toledo - Río Zulia", label: "Toledo - Río Zulia" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Rio Zulia - Oru", label: "Rio Zulia - Oru" },
            { agrupador: "Oleoducto Caño Limón - Ayacucho 24”", valor: "Oru - Ayacucho", label: "Oru - Ayacucho" },
            { agrupador: "Oleoducto Ayacucho - Galán 8”", valor: "Ayacucho - Galán 8”", label: "Ayacucho - Galán 8”" },
            { agrupador: "Combustoleoducto Galán - Ayacucho 18”", valor: "Galán - Ayacucho 18”", label: "Galán - Ayacucho 18”" },
            { agrupador: "Oleoducto Galán - Ayacucho 14”", valor: "Galán - Ayacucho 14”", label: "Galán - Ayacucho 14”" },
            { agrupador: "Poliducto Pozos Colorados - Ayacucho 14”", valor: "Pozos Colorados - El Copey 14”", label: "Pozos Colorados - El Copey 14”" },
            { agrupador: "Poliducto Pozos Colorados - Ayacucho 14”", valor: "El Copey - Ayacucho 14”", label: "El Copey - Ayacucho 14”" },
            { agrupador: "Poliducto Ayacucho - Galán 14”", valor: "Ayacucho-Galán 14”", label: "Ayacucho-Galán 14”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Ayacucho - Retiro 16”", label: "Ayacucho - Retiro 16”" },
            { agrupador: "Oleoducto Ayacucho - Coveñas 24”", valor: "Ayacucho - Coveñas 24”", label: "Ayacucho - Coveñas 24”" },
            { agrupador: "Poliducto Cartagena - Baranoa 12”", valor: "Cartagena - Baranoa 12”", label: "Cartagena - Baranoa 12”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Ayacucho - Retiro 16”", label: "Ayacucho - Retiro 16”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Retiro - Sincé 12”", label: "Retiro - Sincé 12”" },
            { agrupador: "Combustoleoducto Ayacucho -Coveñas 16”", valor: "Sincé - Coveñas 12”", label: "Sincé - Coveñas 12”" },
            { agrupador: "Loop Since - Coveñas 16”", valor: "Loop Sincé - Coveñas 16”", label: "Loop Sincé - Coveñas 16”" },
            { agrupador: "Oleoducto Ayacucho - Coveñas 24”", valor: "Ayacucho - Coveñas 24”", label: "Ayacucho - Coveñas 24”" },
            { agrupador: "Combustoleoducto Coveñas - Cartagena 18”", valor: "Coveñas - Cartagena 18”", label: "Coveñas - Cartagena 18”" },
            { agrupador: "Oleoducto Caucasia - Coveñas 24”", valor: "Caucasia - Coveñas 24”", label: "Caucasia - Coveñas 24”" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Galán-Lizama", label: "Galán-Lizama" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Lizama-Trampa Tienda Nueva", label: "Lizama-Trampa Tienda Nueva" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Trampa Tienda Nueva - Trampa Guayacan", label: "Trampa Tienda Nueva - Trampa Guayacan" },
            { agrupador: "Poliducto Galán-Chimitá", valor: "Trampa Guayacan - Chimita", label: "Trampa Guayacan - Chimita" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "Sebastopol - San Jose del Nus", label: "Sebastopol - San Jose del Nus" },
            { agrupador: "Poliducto Galán-Salgar 12”", valor: "Galán-Sebastopol 12”", label: "Galán-Sebastopol 12”" },
            { agrupador: "Poliducto Galán-Salgar 16”", valor: "Galán-Sebastopol 16”", label: "Galán-Sebastopol 16”" },
            { agrupador: "Propanoducto Galán-Salgar 8”", valor: "Galán-Sebastopol 8”", label: "Galán-Sebastopol 8”" },
            { agrupador: "Poliducto Sebastopol - Salgar 12”", valor: "Sebastopol - Salgar 12 ”", label: "Sebastopol - Salgar 12 ”" },
            { agrupador: "Poliducto Sebastopol - Salgar 16”", valor: "Sebastopol - Salgar 16 ”", label: "Sebastopol - Salgar 16 ”" },
            { agrupador: "Propanoducto Sebastopol - Salgar 8”", valor: "Sebastopol - Salgar  8”", label: "Sebastopol - Salgar  8”" },
            { agrupador: "PoliOriente", valor: "Sebastopol - SantaRosa", label: "Sebastopol - SantaRosa" },
            { agrupador: "Oleoducto Ayacucho - Galán 8”", valor: "Ayacucho - Galán 8”", label: "Ayacucho - Galán 8”" },
            { agrupador: "Combustoleoducto Galán - Ayacucho 18”", valor: "Galán - Ayacucho 18”", label: "Galán - Ayacucho 18”" },
            { agrupador: "Oleoducto Galán - Ayacucho 14”", valor: "Galán - Ayacucho 14”", label: "Galán - Ayacucho 14”" },
            { agrupador: "Poliducto Pozos Colorados - Galán 14”", valor: "Ayacucho-Galán 14”", label: "Ayacucho-Galán 14”" },
            { agrupador: "Naftaducto Vasconia-Velásquez", valor: "Vasconia - Velásquez 12”", label: "Vasconia - Velásquez 12”" },
            { agrupador: "Oleoducto Vasconia-CIB", valor: "Vasconia - CIB 20”", label: "Vasconia - CIB 20”" },
            { agrupador: "Oleoducto Vasconia - Caucasia ", valor: "Vasconia - Caucasia ", label: "Vasconia - Caucasia " },
            { agrupador: "Poliducto de Oriente.", valor: "Santa Rosa - Sutamarchan", label: "Santa Rosa - Sutamarchan" },
            { agrupador: "Poliducto de Oriente.", valor: "Sutamarchan - Tocancipa", label: "Sutamarchan - Tocancipa" },
            { agrupador: "Poliandino", valor: "Sutamarchan - Miraflores", label: "Sutamarchan - Miraflores" },
            { agrupador: "Poliandino", valor: "Monterrey- Apiay 16", label: "Monterrey- Apiay 16" },
            { agrupador: "Oleoducto Apiay - Monterrey 20”", valor: "Apiay-Monterrey 20", label: "Apiay-Monterrey 20”" },
            { agrupador: "Oleoducto Castilla Apiay 16”", valor: "Castilla-Apiay 16”", label: "Castilla-Apiay 16”" },
            { agrupador: "Naftaducto Apiay - Chichimene 10”", valor: "Chichimene - Apiay 10”", label: "Chichimene - Apiay 10”" },
            { agrupador: "Oleoducto Rubiales - Monterrey", valor: "Rubiales - Monterrey", label: "Rubiales - Monterrey" },
            { agrupador: "Oleoducto El Viento - Cusiana", valor: "El Viento - Cusiana", label: "El Viento - Cusiana" },
            { agrupador: "Poliandino", valor: "Miraflores - Monterrey 12”", label: "Miraflores - Monterrey 12”" },
            { agrupador: "Oleoducto Araguaney - El Porvenir", valor: "Araguaney - El Porvenir", label: "Araguaney - El Porvenir" },
            { agrupador: "Oleoducto Apiay - El Porvenir 20", valor: "Monterrey - Altos del Porvenir 20”", label: "Monterrey - Altos del Porvenir 20”" },
            { agrupador: "Oleoducto Apiay - El Porvenir 20", valor: "Altos del Porvenir - El Porvenir 24”", label: "Altos del Porvenir - El Porvenir 24”" },
            { agrupador: "Oleoducto Apiay - El Porvenir 16", valor: "Monterrey - El Porvenir 12", label: "Monterrey - El Porvenir 12" },
            { agrupador: "Oleoducto Santiago - El Porvenir 10”", valor: "Santiago - Monterrey 10", label: "Santiago - Monterrey 10”" },
            { agrupador: "Oleoducto Santiago - El Porvenir 10”", valor: "Monterrey - El Porvenir 10", label: "Monterrey - El Porvenir 10”" },
            { agrupador: "Oleoducto Churuyaco - Orito", valor: "Churuyaco - Orito", label: "Churuyaco - Orito" },
            { agrupador: "Oleoducto Mansoya - Orito", valor: "Mansoya - Santana", label: "Mansoya - Santana" },
            { agrupador: "Oleoducto Mansoya - Orito", valor: "Santana - Yarumo", label: "Santana - Yarumo" },
            { agrupador: "Oleoducto Orito - Tumaco", valor: "Orito - Guamuez", label: "Orito - Guamuez" },
            { agrupador: "Oleoducto Orito - Tumaco", valor: "Guamuez - Alisales", label: "Guamuez - Alisales" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Buenaventura-Gallineros 8”", label: "Buenaventura-Gallineros 8”" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Gallineros -Dagua 12”", label: "Gallineros -Dagua 12”" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Dagua - Mulalo 12”", label: "Dagua - Mulalo 12”" },
            { agrupador: "Poliducto Buenaventura-Yumbo", valor: "Mulalo - Yumbo 6”", label: "Mulalo - Yumbo 6”" },
            { agrupador: "Poliducto Medellín - Cartago 10”", valor: "Medellín - La Felisa 10”", label: "Medellín - La Felisa 10”" },
            { agrupador: "Poliducto Medellín - Cartago 10”", valor: "La Felisa - Cartago 10”", label: "La Felisa - Cartago 10”" },
            { agrupador: "Poliducto Medellín Cartago-Yumbo 10”", valor: "Cartago-Yumbo 10”", label: "Cartago-Yumbo 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Cartago-Zarzal 10”", label: "Cartago-Zarzal 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Zarzal-Lucerna 10”", label: "Zarzal-Lucerna 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Lucerna-Guacari 8” 10”", label: "Lucerna-Guacari 8” 10”" },
            { agrupador: "Poliducto ODECA Cartago-Yumbo", valor: "Guacari-Yumbo 6” 8”", label: "Guacari-Yumbo 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Mariquita - Fresno 6” 8”", label: "Mariquita - Fresno 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Fresno - Herveo 6” 8”", label: "Fresno - Herveo 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Herveo - Manizales 6” 8”", label: "Herveo - Manizales 6” 8”" },
            { agrupador: "Poliducto ODECA Salgar-Cartago", valor: "Manizales - Cartago 8”", label: "Manizales - Cartago 8”" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "San Jose del Nus - Est. Cisneros Nueva 12”", label: "San Jose del Nus - Est. Cisneros Nueva 12”" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "Est. Cisneros Nueva - Est. Cisneros Antigua 12”", label: "Est. Cisneros Nueva - Est. Cisneros Antigua 12”" },
            { agrupador: "Poliducto Sebastopol - Medellín", valor: "Est. Cisneros Antigua - Medellín 12”", label: "Est. Cisneros Antigua - Medellín 12”" },
        ],
        obtenerOpciones: function (data) {
            let valorDelPadre = data.sistemaDeDucto;
            return this.opciones.filter(item => item.agrupador === valorDelPadre);
        },
        "padre": 'sistemaDeDucto',
        "contenido": "Línea de ducto",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLDucto",
        "col-md": 12,
        "col-lg": 6
    },
    "diametroDeDucto": {
        "name": "diametroDeDucto",
        "type": "select",
        opciones: [
            { agrupador: undefined, valor: 6, label: "6", },
            { agrupador: undefined, valor: 8, label: "8", },
            { agrupador: undefined, valor: 10, label: "10", },
            { agrupador: undefined, valor: 12, label: "12", },
            { agrupador: undefined, valor: 14, label: "14", },
            { agrupador: undefined, valor: 16, label: "16", },
            { agrupador: undefined, valor: 18, label: "18", },
            { agrupador: undefined, valor: 20, label: "20", },
            { agrupador: undefined, valor: 24, label: "24", },
            { agrupador: undefined, valor: 30, label: "30", },
            { agrupador: undefined, valor: 36, label: "36", },
        ],
        // obtenerOpciones: (data) => {
        //     return this.opciones
        // },
        "contenido": "Diámetro:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDiametro",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorNominalDeDucto": {
        "name": "espesorNominalDeDucto",
        "type": "text",
        "contenido": "Espesor nominal:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeEspesorNominal",
        "col-md": 12,
        "col-lg": 6
    },
    "SMYSDeDucto": {
        "name": "SMYSDeDucto",
        "type": "text",
        "contenido": "SMYS:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeSMYS",
        "col-md": 12,
        "col-lg": 6
    },
    "MAOPDeDucto": {
        "name": "MAOPDeDucto",
        "type": "text",
        "contenido": "MAOP:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeMAOP",
        "col-md": 12,
        "col-lg": 6
    },
    "CEMOPDeDucto": {
        "name": "CEMOPDeDucto",
        "type": "text",
        "contenido": "CEMOP:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeCEMOP",
        "col-md": 12,
        "col-lg": 6
    },
    "valorFacturado": {
        "name": "valorFacturado",
        "type": "text",
        "contenido": "Valor Facturado:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeValorFacturado",
        "col-md": 12,
        "col-lg": 6
    },
    "actaDeFinalizacion": {
        "name": "actaDeFinalizacion",
        "type": "text",
        "contenido": "Acta De Finalización:",
        "tipo": "numerico",
        "categoria": "valorUnico",
        "condicionesDeLaVariable": [
            {
                "operador": ">",
                "valorTarget": 0
            }
        ],
        "mensaje": "mensajeActaDeFinalizacion",
        "col-md": 12,
        "col-lg": 6
    },
    
}
export const glosarioDeInputsObservaciones = {
    "observacionAdicional": {
        "name": "observacionAdicional",
        "type": "textarea",
        "contenido": "Observaciones Adicionales:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeObservacionAdicional",
        "col-md": 12,
        "col-lg": 12
    },
}

export const glosarioEdicionAnomaliaPM1 = {
    "id": {
        "name": "id",
        "type": "text",
        "contenido": "id:",
        "tipo": "texto",
        "disabled": "true",
        "categoria": "valorAbierto",
        "mensaje": "mensajeId",
        "col-md": 12,
        "col-lg": 6
    },
    "tipoDeAnomalia": {
        "name": "tipoDeAnomalia",
        "type": "text",
        "contenido": "Tipo de Anomalía",
        "tipo": "texto",
        "disabled": "true",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoDeAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "evaluacionOComparacion": {
        "name": "evaluacionOComparacion",
        "type": "select",
        opciones: [
            { valor: "C", label: "Comparación" },
            { valor: "E", label: "Evaluación" },
            { valor: "CyE", label: "Comparación y Evaluación" },
        ],
        "contenido": "Evaluacion o Comparación:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEvaluacionOComparacion",
        "col-md": 12,
        "col-lg": 6
    },
    "programadaOAdiccional": {
        "name": "programadaOAdiccional",
        "type": "select",
        opciones: [
            { valor: "Programada", label: "Programada" },
            { valor: "No Programada", label: "No Programada" },
            { valor: "Opor", label: "Opor" },
            { valor: "Val", label: "Val" },
            { valor: "Adicional", label: "Adicional" },
        ],
        "contenido": "Programada o Adicional:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProgramadaOAdiccional",
        "col-md": 12,
        "col-lg": 6
    },
    "evento": {
        "name": "evento",
        "type": "select",
        opciones: [
            { valor: "GR", label: "GR" },
            { valor: "AG", label: "AG" },
            { valor: "AN", label: "AN" },
        ],
        "contenido": "Evento:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEvento",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaAlRegistroMedicion": {
        "name": "distanciaAlRegistroMedicion",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaAlRegistroMedicion",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaJuntaAnterior": {
        "name": "distanciaJuntaAnterior",
        "type": "text",
        "contenido": "Distancia Junta Anterior (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaAnterior",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaJuntaPosterior": {
        "name": "distanciaJuntaPosterior",
        "type": "text",
        "contenido": "Distancia Junta Posterio (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaPosterior",
        "col-md": 12,
        "col-lg": 6
    },
    "posicionDelDuctoILIDistanciaMedicion": {
        "name": "posicionDelDuctoILIDistanciaMedicion",
        "type": "text",
        "contenido": "Distancia desde las 12:00 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajePosicionDelDuctoILIDistanciaMedicion",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudAnomaliaMedicion": {
        "name": "longitudAnomaliaMedicion",
        "type": "text",
        "contenido": "Longitud de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudAnomaliaMedicion",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoAnomaliaMedicion": {
        "name": "anchoAnomaliaMedicion",
        "type": "text",
        "contenido": "Ancho de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnchoAnomaliaMedicion",
        "col-md": 12,
        "col-lg": 6
    },
    "profundidad": {
        "name": "profundidad",
        "type": "text",
        "contenido": "Profundidad (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidad",
        "col-md": 12,
        "col-lg": 6
    },
    "interactuaConJunta": {
        "name": "interactuaConJunta",
        "type": "select",
        opciones: [
            { valor: "SI", label: "SI", },
            { valor: "NO", label: "NO", },
        ],
        "contenido": "Interactua con Junta:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeInteractuaConJunta",
        "col-md": 12,
        "col-lg": 6
    },
    "tipoAnomalia": {
        "name": "tipoAnomalia",
        "type": "select",
        opciones: [
            { valor: "PMC", label: "PMC", },
            { valor: "PDM", label: "PDM", },
        ],
        "contenido": "Tipo de Anomalía:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "paredInternaMedicion": {
        "name": "paredInternaMedicion",
        "type": "select",
        opciones: [
            { valor: "SI", label: "SI", },
            { valor: "NO", label: "NO", },
            { valor: "No Aplica", label: "No Aplica", },
        ],
        "contenido": "Pared Interna:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeParedInternaMedicion",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionAnomaliaPM2 = {
    "idAnomaliaILI": {
        "name": "idAnomaliaILI",
        "type": "text",
        "contenido": "id Anomalía ILI:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdAnomaliaILI",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaAlRegistro": {
        "name": "distanciaAlRegistro",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaAlRegistro",
        "col-md": 12,
        "col-lg": 6
    },
    "posicionDelDuctoILI": {
        "name": "posicionDelDuctoILI",
        "type": "text",
        "contenido": "Posicion horaria (HH:MM):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeposicionDelDuctoILI",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudAnomialia": {
        "name": "longitudAnomialia",
        "type": "text",
        "contenido": "Longitud de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudAnomialia",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoAnomialia": {
        "name": "anchoAnomialia",
        "type": "text",
        "contenido": "Ancho de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnchoAnomialia",
        "col-md": 12,
        "col-lg": 6
    },
    "porcentajeReduccionEspesor": {
        "name": "porcentajeReduccionEspesor",
        "type": "text",
        "contenido": "Procentaje de Reducción de Espesor (%):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajePorcentajeReduccionEspesor",
        "col-md": 12,
        "col-lg": 6
    },
    "paredInterna": {
        "name": "paredInterna",
        "type": "select",
        opciones: [
            { valor: "SI", label: "SI", },
            { valor: "NO", label: "NO", },
            { valor: "No Aplica", label: "No Aplica", },
        ],
        "contenido": "Pared Interna:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeParedInterna",
        "col-md": 12,
        "col-lg": 6
    },

}

export const glosarioEdicionAnomaliaAB1 = {
    "idDeAnomalia": {
        "name": "idDeAnomalia",
        "type": "text",
        "disabled": "true",
        "contenido": "Id Anomalía",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdDeAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "idAnomaliaILI": {
        "name": "idAnomaliaILI",
        "type": "text",
        "disabled": "true",
        "contenido": "Id Anomalía ILI:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdAnomaliaILI",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaRegistro": {
        "name": "distanciaRegistro",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistro",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudAbolladura": {
        "name": "longitudAbolladura",
        "type": "text",
        "contenido": "Longitud de la Abolladura (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudAbolladura",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoAbolladura": {
        "name": "anchoAbolladura",
        "type": "text",
        "contenido": "Ancho de la Abolladura (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnchoAbolladura",
        "col-md": 12,
        "col-lg": 6
    },
    "profundidad": {
        "name": "profundidad",
        "type": "text",
        "contenido": "Profundidad (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidad",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaH3JuntaAnterior": {
        "name": "distanciaH3JuntaAnterior",
        "type": "text",
        "contenido": "Distancia H3 Junta Anterior (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaH3JuntaAnterior",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaH4JuntaPosterior": {
        "name": "distanciaH4JuntaPosterior",
        "type": "text",
        "contenido": "Distancia H4 Junta Posterior (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaH4JuntaPosterior",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaJuntaLongitudinal": {
        "name": "distanciaJuntaLongitudinal",
        "type": "text",
        "contenido": "Distancia Junta Longitudinal (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaLongitudinal",
        "col-md": 12,
        "col-lg": 6
    },
    "interaccionConArrugasOConcentradorDeEsfuerzos": {
        "name": "interaccionConArrugasOConcentradorDeEsfuerzos",
        "type": "select",
        opciones: [
            { valor: "SI", label: "SI" },
            { valor: "NO", label: "NO" },
        ],
        "contenido": "Interacción Con Arrugas o Concentradores?:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeInteraccionConArrugasOConcentradorDeEsfuerzos",
        "col-md": 12,
        "col-lg": 6
    },
    "interSoldadura": {
        "name": "interSoldadura",
        "type": "select",
        opciones: [
            { valor: "SI", label: "SI" },
            { valor: "NO", label: "NO" },
        ],
        "contenido": "Inter Soldadura:",
        "tipo": "texto",
        "categoria": "valorAbierto",
        "mensaje": "mensajeInterSoldadura",
        "col-md": 12,
        "col-lg": 6
    },
    "posicionHoraria": {
        "name": "posicionHoraria",
        "type": "text",
        "contenido": "Posición Horaria:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajePosicionHoraria",
        "col-md": 12,
        "col-lg": 6
    },
    "breadCurumbEspesores": {
        'name': 'HOMBROS'
    },
    "distanciaCAALosHombrosH1": {
        "name": "distanciaCAALosHombrosH1",
        "type": "text",
        "contenido": "Distancia CA a los Hombros H1 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaCAALosHombrosH1",
        "col-md": 12,
        "col-lg": 6
    },
    "localizaciónDeHombrosH1Distancia": {
        "name": "localizaciónDeHombrosH1Distancia",
        "type": "text",
        "contenido": "Localizacion de Hombros H1 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLocalizaciónDeHombrosH1Distancia",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaCAALosHombrosH2": {
        "name": "distanciaCAALosHombrosH2",
        "type": "text",
        "contenido": "Distancia CA a los Hombros H2 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaCAALosHombrosH2",
        "col-md": 12,
        "col-lg": 6
    },
    "localizaciónDeHombrosH2Distancia": {
        "name": "localizaciónDeHombrosH2Distancia",
        "type": "text",
        "contenido": "Localizacion de Hombros H2 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLocalizaciónDeHombrosH2Distancia",
        "col-md": 12,
        "col-lg": 6
    },

    "distanciaCAALosHombrosH3": {
        "name": "distanciaCAALosHombrosH3",
        "type": "text",
        "contenido": "Distancia CA a los Hombros H3 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaCAALosHombrosH3",
        "col-md": 12,
        "col-lg": 6
    },

    "localizaciónDeHombrosH3Distancia": {
        "name": "localizaciónDeHombrosH3Distancia",
        "type": "text",
        "contenido": "Localizacion de Hombros H3 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLocalizaciónDeHombrosH3Distancia",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaCAALosHombrosH4": {
        "name": "distanciaCAALosHombrosH4",
        "type": "text",
        "contenido": "Distancia CA a los Hombros H4 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaCAALosHombrosH4",
        "col-md": 12,
        "col-lg": 6
    },
    
    "localizaciónDeHombrosH4Distancia": {
        "name": "localizaciónDeHombrosH4Distancia",
        "type": "text",
        "contenido": "Localizacion de Hombros H4 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLocalizaciónDeHombrosH4Distancia",
        "col-md": 12,
        "col-lg": 6
    },
    "localizaciónDeHombrosCADistancia": {
        "name": "localizaciónDeHombrosCADistancia",
        "type": "text",
        "contenido": "Localizacion de Hombros CA (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLocalizaciónDeHombrosCADistancia",
        "col-md": 12,
        "col-lg": 6
    },
    "tipoDeAnomaliaInteraccion": {
        "name": "tipoDeAnomaliaInteraccion",
        "type": "select",
        opciones:[
            {valor:"Corrosión",label:"Corrosión"},
            {valor:"Rayón",label:"Rayón"},
            {valor:"Muesca",label:"Muesca"},
            {valor:"Entalla",label:"Entalla"},
        ],
        "contenido": "Tipo de Anomalía:",
        "tipo": "text",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoDeAnomaliaInteraccion",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionAnomaliaAB2 = {
    "distanciaRegistroInteraccion": {
        "name": "distanciaRegistroInteraccion",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajedistanciaRegistroInteraccion",
        "col-md": 12,
        "col-lg": 6
    },
    "distaciaALas12": {
        "name": "distaciaALas12",
        "type": "text",
        "contenido": "Distancia a las 12:00 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajedistaciaALas12",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudInterracion": {
        "name": "longitudInterracion",
        "type": "text",
        "contenido": "Longitud (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajelongitudInterracion",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoInterracion": {
        "name": "anchoInterracion",
        "type": "text",
        "contenido": "Ancho (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeanchoInterracion",
        "col-md": 12,
        "col-lg": 6
    },
    "profundidadInterracion": {
        "name": "profundidadInterracion",
        "type": "text",
        "contenido": "Profundidad (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeprofundidadInterracion",
        "col-md": 12,
        "col-lg": 6
    },

}

export const glosarioEdicionCamisa = {
    "idDeAnomalia": {
        "name": "idDeAnomalia",
        "type": "text",
        "disabled": "true",
        "contenido": "Id Anomalía",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdDeAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorTuberia": {
        "name": "espesorTuberia",
        "type": "text",
        "contenido": "Espesor Tuberia (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorTuberia",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorCamisa": {
        "name": "espesorCamisa",
        "type": "text",
        "contenido": "Espesor Camisa (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorCamisa",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudCamisa": {
        "name": "longitudCamisa",
        "type": "text",
        "contenido": "Longitud Camisa (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudCamisa",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionCamisaSA = {
    "espesorTuberia": {
        "name": "espesorTuberia",
        "type": "text",
        "contenido": "Espesor Tuberia (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorTuberia",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorAnillo1": {
        "name": "espesorAnillo1",
        "type": "text",
        "contenido": "Espesor Anillo 1 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorAnillo1",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorAnillo2": {
        "name": "espesorAnillo2",
        "type": "text",
        "contenido": "Espesor Anillo 2 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorAnillo2",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudEntreAnillos": {
        "name": "longitudEntreAnillos",
        "type": "text",
        "contenido": "Longitud entre Anillos (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudEntreAnillos",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionParche = {
    "espesorTuberia": {
        "name": "espesorTuberia",
        "type": "text",
        "contenido": "Espesor Tuberia (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorTuberia",
        "col-md": 12,
        "col-lg": 6
    },
    "espesorParche": {
        "name": "espesorParche",
        "type": "text",
        "contenido": "Espesor Parche (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorParche",
        "col-md": 12,
        "col-lg": 6
    },
    "diametroParche": {
        "name": "diametroParche",
        "type": "text",
        "contenido": "Diámetro Parche (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDiametroParche",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionComentariosCamisas = {
    "condicionVT": {
        "name": "condicionVT",
        "type": "text",
        "contenido": "Condición Ensayo VT:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeCondicionVT",
        "col-md": 12,
        "col-lg": 6
    },
    "recomendacionVT": {
        "name": "recomendacionVT",
        "type": "text",
        "contenido": "Recomendación Ensayo VT:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRecomendacionVT",
        "col-md": 12,
        "col-lg": 6
    },
    "condicionMT": {
        "name": "condicionMT",
        "type": "text",
        "contenido": "Condición Ensayo MT:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeCondicionMT",
        "col-md": 12,
        "col-lg": 6
    },
    "recomendacionMT": {
        "name": "recomendacionMT",
        "type": "text",
        "contenido": "Recomendación Ensayo MT:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRecomendacionMT",
        "col-md": 12,
        "col-lg": 6
    },
    "condicionPAUT": {
        "name": "condicionPAUT",
        "type": "text",
        "contenido": "Condición Ensayo PAUT:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeCondicionPAUT",
        "col-md": 12,
        "col-lg": 6
    },
    "recomendacionPAUT": {
        "name": "recomendacionPAUT",
        "type": "text",
        "contenido": "Recomendación Ensayo PAUT:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRecomendacionPAUT",
        "col-md": 12,
        "col-lg": 6
    },
    "condicionEspesores": {
        "name": "condicionEspesores",
        "type": "text",
        "contenido": "Condición Ensayo Espesores:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeCondicionEspesores",
        "col-md": 12,
        "col-lg": 6
    },
    "recomendacionEspesores": {
        "name": "recomendacionEspesores",
        "type": "text",
        "contenido": "Recomendación Ensayo Espesores:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRecomendacionEspesores",
        "col-md": 12,
        "col-lg": 6
    },
    "condicionOtros": {
        "name": "condicionOtros",
        "type": "text",
        "contenido": "Condición Ensayo Otros:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeCondicionOtros",
        "col-md": 12,
        "col-lg": 6
    },
    "recomendacionOtros": {
        "name": "recomendacionOtros",
        "type": "text",
        "contenido": "Recomendación Ensayo Otros:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeRecomendacionOtros",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionSubanomalias = {
    "idDeSubAnomalia": {
        "name": "idDeSubAnomalia",
        "type": "text",
        "disabled": "true",
        "contenido": "Id SubAnomalía",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdDeSubAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "largoAnomaliaCamisa": {
        "name": "largoAnomaliaCamisa",
        "type": "text",
        "contenido": "Largo de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLargoAnomaliaCamisa",
        "col-md": 12,
        "col-lg": 6
    },
    "anchoAnomaliaCamisa": {
        "name": "anchoAnomaliaCamisa",
        "type": "text",
        "contenido": "Ancho de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnchoAnomaliaCamisa",
        "col-md": 12,
        "col-lg": 6
    },
    "posicionHorariaAnomalia": {
        "name": "posicionHorariaAnomalia",
        "type": "text",
        "contenido": "Posición Horaria (HH:MM):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajePosicionHorariaAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "ubicacionEnLaReparacion": {
        "name": "ubicacionEnLaReparacion",
        "type": "select",
        opciones: [
            { valor: "C1", label: "C1" },
            { valor: "C2", label: "C2" },
            { valor: "C3", label: "C3" },
            { valor: "C4", label: "C4" },
            { valor: "L1", label: "L1" },
            { valor: "L2", label: "L2" },
            { valor: "L3", label: "L3" },
            { valor: "L5", label: "L5" },
            { valor: "cuerpo_camisa", label: "Cuerpo Camisa" },
            { valor: "parche", label: "Parche" },
        ],
        "contenido": "Ubicación en la reparación:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeCondicionVT",
        "col-md": 12,
        "col-lg": 6
    },
    "conceptoAnomalia": {
        "name": "conceptoAnomalia",
        "type": "text",
        "contenido": "Concepto de la Anomalía:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeConceptoAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
    "ensayoReparacion": {
        "name": "ensayoReparacion",
        "type": "select",
        opciones: [
            { valor: "VT", label: "VT" },
            { valor: "MT", label: "MT" },
            { valor: "PAUT", label: "PAUT" },
            { valor: "otro", label: "Otro" },
        ],
        "contenido": "Ensayo:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEnsayoReparacion",
        "col-md": 12,
        "col-lg": 6
    },


}

export const glosarioEdicionGrietas = {
    "idDeAnomalia": {
        "name": "idDeAnomalia",
        "type": "text",
        "disabled": "true",
        "contenido": "ID de Anomalía",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdDeAnomalia",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaRegistroGrietaMed": {
        "name": "distanciaRegistroGrietaMed",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistroGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaJuntaAnteriorGrietaMed": {
        "name": "distanciaJuntaAnteriorGrietaMed",
        "type": "text",
        "contenido": "Distancia Junta Anterior",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaAnteriorGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaJuntaPosteriorGrietaMed": {
        "name": "distanciaJuntaPosteriorGrietaMed",
        "type": "text",
        "contenido": "Distancia Junta Posterior (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaPosteriorGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaJuntaLongitudinalGrietaMed": {
        "name": "distanciaJuntaLongitudinalGrietaMed",
        "type": "text",
        "contenido": "Distancia Junta Longitudinal (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaLongitudinalGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaALas12GrietaMed": {
        "name": "distanciaALas12GrietaMed",
        "type": "text",
        "contenido": "Distancia a las 12:00 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaALas12GrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"longitud2CGrietaMed": {
        "name": "longitud2CGrietaMed",
        "type": "text",
        "contenido": "Longitud 2C (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitud2CGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"longitud2AGrietaMed": {
        "name": "longitud2AGrietaMed",
        "type": "text",
        "contenido": "Longitud 2A (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitud2AGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"profundidadDGrietaMed": {
        "name": "profundidadDGrietaMed",
        "type": "text",
        "contenido": "Profundidad (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidadDGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"anguloDeInclinacionGrietaMed": {
        "name": "anguloDeInclinacionGrietaMed",
        "type": "text",
        "contenido": "Angulo (Θ):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnguloDeInclinacionGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"espesorTuboGrietaMed": {
        "name": "espesorTuboGrietaMed",
        "type": "text",
        "contenido": "Espesor del Tubo (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorTuboGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
"tipoDeGrietaMed": {
        "name": "tipoDeGrietaMed",
        "type": "select",
        opciones: [
            { valor: "Aisladas", label: "Aisladas" },
            { valor: "Colonia", label: "Colonia" },
            { valor: "Muescas", label: "Muescas" },
            { valor: "Sold. Long.", label: "Sold. Long." },
            { valor: "Sold. Circ.", label: "Sold. Circ." },
            { valor: "Anomalía", label: "Anomalía" },
        ],
        "contenido": "Tipo de Grieta:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoDeGrietaMed",
        "col-md": 12,
        "col-lg": 6
    },
}

export const glosarioEdicionGrietasCorridaUTCD = {
"distanciaRegistroGrietaUTCD": {
        "name": "distanciaRegistroGrietaUTCD",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistroGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaJuntaAnteriorGrietaUTCD": {
        "name": "distanciaJuntaAnteriorGrietaUTCD",
        "type": "text",
        "contenido": "Distancia Junta Anterior (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaAnteriorGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaJuntaPosteriorGrietaUTCD": {
        "name": "distanciaJuntaPosteriorGrietaUTCD",
        "type": "text",
        "contenido": "Distancia Junta Posterior (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaPosteriorGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"distanciaJuntaLongitudinalGrietaUTCD": {
        "name": "distanciaJuntaLongitudinalGrietaUTCD",
        "type": "text",
        "contenido": "Distancia Junta Longitudinal (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaJuntaLongitudinalGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"posicionHorariaGrietaUTCD": {
        "name": "posicionHorariaGrietaUTCD",
        "type": "text",
        "contenido": "Posición Horaria (HH:MM):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajePosicionHorariaGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"longitudGrietaUTCD": {
        "name": "longitudGrietaUTCD",
        "type": "text",
        "contenido": "Longitud de la anomalía(mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"profundidadDGrietaUTCD": {
        "name": "profundidadDGrietaUTCD",
        "type": "text",
        "contenido": "Profundidad de la Anomalía (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidadDGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"anguloDeInclinacionGrietaUTCD": {
        "name": "anguloDeInclinacionGrietaUTCD",
        "type": "text",
        "contenido": "Angulo de Inclinación (Θ):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeAnguloDeInclinacionGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"espesorTuboGrietaUTCD": {
        "name": "espesorTuboGrietaUTCD",
        "type": "text",
        "contenido": "Espesor Tubo (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEspesorTuboGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },
"tipoDeGrietaUTCD": {
        "name": "tipoDeGrietaUTCD",
        "type": "select",
        opciones: [
            { valor: "Aisladas", label: "Aisladas" },
            { valor: "Colonia", label: "Colonia" },
            { valor: "Muescas", label: "Muescas" },
            { valor: "Sold. Long.", label: "Sold. Long." },
            { valor: "Sold. Circ.", label: "Sold. Circ." },
            { valor: "Anomalía", label: "Anomalía" },
        ],
        "contenido": "Tipo de Grieta:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeTipoDeGrietaUTCD",
        "col-md": 12,
        "col-lg": 6
    },

}

export const glosarioEdicionEnsayosGrietas = {
    "idDeEnsayo": {
        "name": "idDeEnsayo",
        "type": "text",
        "disabled": "true",
        "contenido": "ID del Ensayo:",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeIdDeEnsayo",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaRegistroEnsayoGrieta": {
        "name": "distanciaRegistroEnsayoGrieta",
        "type": "text",
        "contenido": "Distancia de Registro (m):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaRegistroEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "distanciaALas12EnsayoGrieta": {
        "name": "distanciaALas12EnsayoGrieta",
        "type": "text",
        "contenido": "Distancia a las 12:00 (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeDistanciaALas12EnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "longitudEnsayoGrieta": {
        "name": "longitudEnsayoGrieta",
        "type": "text",
        "contenido": "Longitud (mm):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeLongitudEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "profundidadInicialEnsayoGrieta": {
        "name": "profundidadInicialEnsayoGrieta",
        "type": "text",
        "contenido": "Profundidad Inicial (desde A):",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidadInicialEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "profundidadFinalEnsayoGrieta": {
        "name": "profundidadFinalEnsayoGrieta",
        "type": "text",
        "contenido": "Profundidad Final (Desde A)",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeProfundidadFinalEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "naturalezaIndicacionEnsayoGrieta": {
        "name": "naturalezaIndicacionEnsayoGrieta",
        "type": "text",
        "contenido": "Naturaleza de la Indicación",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeNaturalezaIndicacionEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "estadoEnsayoGrieta": {
        "name": "estadoEnsayoGrieta",
        "type": "text",
        "contenido": "Estado del Ensayo",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeEstadoEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
    "comentariosEnsayoGrieta": {
        "name": "comentariosEnsayoGrieta",
        "type": "text",
        "contenido": "Comentarios",
        "tipo": "numerico",
        "categoria": "valorAbierto",
        "mensaje": "mensajeComentariosEnsayoGrieta",
        "col-md": 12,
        "col-lg": 6
    },
}
