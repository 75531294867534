import React from 'react';
import { Row, Col } from 'reactstrap';
import { FaListUl, FaCalculator, FaRedoAlt } from 'react-icons/fa';
import { BsTable } from "react-icons/bs";
import { withRouter } from 'react-router-dom';

//Componentes auxiliares:
import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection';
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import CardDeComponentesACalcular from './componentes/CardDeComponentesACalcular';
import CardDeFicheros from './componentes/CardDeFicheros';
import CardDeResultados from './componentes/CardDeResultados';
import CustomSection from '../../../../../utilidades/componentes/plantillaBase/CustomSection';
import LogDeCalculos from './componentes/LogDeCalculos';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';
import TablaEditable from './componentes/TablaEditable';
import TablaPlanesDeAccion from '../../../tratamiento/planesDeInspeccion/componentes/TablaPlanesDeAccion';

//Utilidad de base de datos:
import { firestoreDB, firebaseFunctions } from '../../../../../firebase';
//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//Scripts auxiliares:
import { obtenerNodoConSubcoleccion } from '../../../../../utilidades/scripts/paraConsultaDeNodos';
import { obtenerTablaDePlanesDeAccion, encapsularObjetoEnArray } from '../../../../../utilidades/scripts/paraPlanesDeAccion';

//Esáticos:
import { amenazasPara1160YB318s, mecanismosParaAPI581 } from './estaticos/componentesDeCalculoDisponibles';
import { get } from 'lodash';

//cloud Function
const calcularActivo = firebaseFunctions.httpsCallable('calcularActivo');

//Estilos base:
const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaParrafosDeInfo = { ...estiloDeFuentesBase, textAlign: "right", marginBottom: 2 };
const estiloParaHipervinculo = { marginLeft: 5, marginRight: 5, cursor: "pointer", textDecoration: "underline" };

class VisualizadorDeNodos extends React.Component {

    constructor() {
        super();

        this.state = {
            idTarget: "",
            nodo: undefined,
            coleccionActiva: "",
            cargaCompleta: false,
            cargando: false,
            tablaPlanesDeAccion: [],
            // tipoActivoPadre: undefined
        }
    }

    render() {
        if (!this.state.error) {
            const nodo = this.state.nodo;
            if (nodo !== undefined && this.state.cargaCompleta) {
                return (
                    <CustomSection
                        titulo={nodo.informacionGeneral.nombre.valor}
                        subtitulo={"Componente tipo: " + get(nodo,"informacionGeneral.tipoDeComponente.valor","No se pudo obtener este dato")}
                        URLIconoPrincipal="/assets/img/iconos/iconoSegmento.jpg"
                        botonesAdicionales={[
                            <BotonParaSection key="BotonDeActualizacionDeSegmento" icono={<FaRedoAlt />} onClick={this.actualizarNodo} title={'Actualizar'} />,
                            <BotonParaSection key="BotonDeCalculoDeSegmento" icono={<FaCalculator />} onClick={this.reCalcularNodo} title={'Recalcular'} />
                        ]}>

                        {this.obtenerInfoDeLosPadres()}

                        <Row>
                            <CardDeResultados
                                consecuencia={nodo.resultadosDeLaValoracion.consecuencia}
                                scope="consecuencia" />

                            <CardDeResultados
                                probabilidad={nodo.resultadosDeLaValoracion.probabilidad}
                                scope="probabilidad"
                            />
                        </Row>

                        <Row>
                            <Col xs="12" md="12" lg="8" xl="8">
                                <TablaEditable
                                    titulo={"Lista de propiedades"}
                                    icono={FaListUl}
                                    listaDeDatos={this.obtenerListaDeVariablesDesdeObjeto(this.state.nodo, 'variablesDeLaInspeccion')}
                                    coleccionActiva={'segmentos'}
                                    idNodo={this.state.nodo.id}
                                />
                            </Col>
                            <Col xs="12" md="12" lg="4" xl="4">

                                <CardDeComponentesACalcular
                                    actualizarComponentesDeCalculo={this.actualizarComponentesDeCalculo}
                                    nodo={nodo}
                                    componentesDisponibles={this.obtenerComponentesDeCalculoDisponibles()}
                                    titulo={this.obtenerTituloDeComponentesDeCalculo()}
                                />

                                <CardDeFicheros nodo={nodo} coleccionTarget={this.state.coleccionActiva} />
                            </Col>
                            <Col xs="12" md="12" lg="12" xl="12">
                                <CardConEncabezado titulo={"Tabla de planes de acción"} icono={BsTable}>
                                    <TablaPlanesDeAccion
                                        tablaConFiltro={this.state.tablaPlanesDeAccion}
                                        cambiarEstadoDelCheck={this.cambiarEstadoDelCheck}
                                    />
                                </CardConEncabezado>
                            </Col>
                            <Col xs="12" md="12" lg="6" xl="6">
                                <LogDeCalculos nodo={nodo} scopeTarget="probabilidad" />
                            </Col>
                            <Col xs="12" md="12" lg="6" xl="6">
                                <LogDeCalculos nodo={nodo} scopeTarget="consecuencia" />
                            </Col>

                        </Row>
                        <ToastContainer />
                        <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                    </CustomSection>
                );
            }
            else { return (<Preloader elPreloaderEstaAbierto={true} />); }
        }
        else { return (<Preloader elPreloaderEstaAbierto={true} />); }
    }

    componentDidMount = () => {
        this.actualizarNodo();
        this.setState({ moduloTarget: this.props.match.params.modulo });
    }

    reCalcularNodo = () => {
        calcularActivo({ "id": this.state.nodo.informacionDeLosPadres.activoAsociado.referencia.id, "dominioCorporativo": this.state.nodo.dominioCorporativo })
            .then(respuesta => { this.actualizarNodo(); })
            .catch(error => console.log(error));

        this.avisoDeGuardadoSatisfactorio('Proceso de cálculo iniciado con éxito', 'warning');
    }

    //Métodos operativos:
    actualizarNodo = async () => {
        const id = this.props.match.params.id;
        const coleccionActiva = "segmentos";
        this.setState({
            cargando: true,
        });

        const snapshot = await firestoreDB.collection(coleccionActiva).doc(id).get()
        const moduloTarget = this.props.match.params.modulo;
        let nodoTarget = { id: snapshot.id, ...snapshot.data(), referencia: snapshot.ref };
        const nodo = await obtenerNodoConSubcoleccion(nodoTarget, moduloTarget, true, true)
        // const snapShotActivoPadre = await nodo.informacionDeLosPadres.activoAsociado.referencia.get()
        // const dataActivoPadre = snapShotActivoPadre.data()
        this.setState({
            id: snapshot.id,
            coleccionActiva: coleccionActiva,
            nodo: nodo,
            cargaCompleta: true,
            tablaPlanesDeAccion: obtenerTablaDePlanesDeAccion(encapsularObjetoEnArray(nodo)),
            cargando: false,
            // tipoActivoPadre:dataActivoPadre.informacionGeneral.tipoDeActivo.valor
        })
    }

    cambiarEstadoDelCheck = (evento, row) => {
        let tablaConFiltroPrevia = this.state.tablaPlanesDeAccion;
        tablaConFiltroPrevia[row._index].estado = !tablaConFiltroPrevia[row._index].estado

        this.setState({
            tablaPlanesDeAccion: tablaConFiltroPrevia
        });
    }

    avisoDeGuardadoSatisfactorio = (palabra, tipo) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: tipo || 'success'
        });
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    actualizarComponentesDeCalculo = (nuevoSet) => {
        let objetoDeActualizacion = {};
        objetoDeActualizacion[nuevoSet.key] = nuevoSet.valor;

        firestoreDB.collection('segmentos').doc(this.state.nodo.id).update(objetoDeActualizacion)
            .then((result) => {
                this.avisoDeGuardadoSatisfactorio('Actualización exitosa. Los cambios se reflejarán en el próximo cálculo');
            })
            .catch(error => {
                console.log(error);
                this.avisoDeGuardadoFallido('El proceso de actualización falló. Intenta de nuevo');
            });
    }

    obtenerComponentesDeCalculoDisponibles = () => {
        switch (this.props.match.params.modulo) {
            case "API581":
                return mecanismosParaAPI581;
            case "API1160":
            case "ASME_B31_8S":
                return amenazasPara1160YB318s;
            default:
                return [];
        }
    }

    obtenerListaDeVariablesDesdeObjeto = (objetoBase, keyDeDatosTarget) => {
        let objetoDeDatos = objetoBase[keyDeDatosTarget];
        let keysDeDatos = Object.keys(objetoBase[keyDeDatosTarget]);

        return keysDeDatos.map((key, i) => { return { key: key, ...objetoDeDatos[key], indice: i }; });
    }

    obtenerNombreDeColeccionEnSingular = (coleccion) => {
        switch (coleccion) {
            case "campos":
                return "campo";
            case "sistemas":
                return "sistema";
            case "activos":
                return "activo";
            case "segmentos":
                return "segmento";
            default:
                return coleccion;
        }
    }

    obtenerTituloDeComponentesDeCalculo = () => {
        switch (this.props.match.params.modulo) {
            case "API581":
                return "Mecanismos a calcular";
            case "API1160":
            case "ASME_B31_8S":
                return "Amenazas a calcular";

            default:
                return [];
        }
    }

    obtenerValorFormateado = (valor) => {
        let valorNumerico = parseFloat(valor);

        if (isNaN(valorNumerico)) { return valor; }
        else { return valorNumerico.toFixed(3); }
    }

    verificarSiEsNumero = (valor) => {
        return !isNaN(parseFloat(valor));
    }

    //Métodos para renderizado:
    obtenerInfoDeLosPadres = () => {
        if (this.state.cargaCompleta) {
            let listaDeContenido = [];
            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo }} key={"infoDePadres." + this.state.nodo.id + ".nombre"}
                    dangerouslySetInnerHTML={{
                        __html:
                            "Estoy viendo los detalles del " + this.obtenerNombreDeColeccionEnSingular(this.state.coleccionActiva)
                            + " <b>" + this.state.nodo.informacionGeneral.nombre.valor + "</b>"
                    }} />
            );

            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo, marginBottom: 20 }}
                    key={"infoDePadres." + this.state.nodo.id + ".paraSegmento"}>
                    Pertenece al activo
                    <b style={{ ...estiloParaHipervinculo }} onClick={this.navegarHaciaActivoPadre}>
                        {this.state.nodo.informacionDeLosPadres.activoAsociado.nombre}
                    </b>
                    del sistema
                    <b style={{ ...estiloParaHipervinculo }} onClick={this.navergarHaciaSistemaPadre}>
                        {this.state.nodo.informacionDeLosPadres.sistemaAsociado.nombre}
                    </b>
                    del campo
                    <b style={{ ...estiloParaHipervinculo }} onClick={this.navergarHaciaCampoPadre}>
                        {this.state.nodo.informacionDeLosPadres.campoAsociado.nombre}
                    </b>
                </p>
            );


            return listaDeContenido;
        }
        else {
            return "Cargando...";
        }
    }

    //Métodos para redirección:
    navegarHaciaPath = (path) => {
        this.props.history.push(path);
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }

    navegarHaciaActivoPadre = () => {      
        const moduloTarget = this.props.match.params.modulo;
        const pathTarget = "/app/detalleDeNodo/activos/" + this.state.nodo.informacionDeLosPadres.activoAsociado.referencia.id + "/" + moduloTarget;
        this.navegarHaciaPath(pathTarget);
    }

    navergarHaciaSistemaPadre = () => {
        const moduloTarget = this.props.match.params.modulo;
        this.state.nodo.informacionDeLosPadres.activoAsociado.referencia.get()
            .then(snapshotDelActivo => {
                const activoPadre = snapshotDelActivo.data();
                const pathTarget = "/app/detalleDeNodo/sistemas/" + activoPadre.informacionDeLosPadres.sistemaAsociado.referencia.id + "/" + moduloTarget;
                this.navegarHaciaPath(pathTarget);
            })
            .catch(error => { console.log(error); });
    }

    navergarHaciaCampoPadre = () => {
        const moduloTarget = this.props.match.params.modulo;
        this.state.nodo.informacionDeLosPadres.activoAsociado.referencia.get()
            .then(snapshotDelActivo => {
                let activoPadre = snapshotDelActivo.data();
                activoPadre.informacionDeLosPadres.sistemaAsociado.referencia.get()
                    .then(snapshotDelSistema => {
                        const sistemaPadre = snapshotDelSistema.data();
                        const pathTarget = "/app/detalleDeNodo/campos/" + sistemaPadre.informacionDeLosPadres.campoAsociado.referencia.id + "/" + moduloTarget;
                        this.navegarHaciaPath(pathTarget);
                    })
                    .catch(error => { console.log(error); });
            })
            .catch(error => { console.log(error); });
    }

}

export default withRouter(VisualizadorDeNodos);