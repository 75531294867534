import { Card, CardHeader, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Row, Col, Button } from 'reactstrap';
import useStoreApi581PRD from '../../hooks/useStoreApi581PRD';
import CustomSection from '../../../../../../utilidades/componentes/plantillaBase/CustomSection'
import { forEach, get, isEmpty } from 'lodash';
import { obtenerSoloUnPRD } from '../../../api581Valvulas/scripts/consultas/obtenerReporte';
import quitarDeServcio,
{
    cambiarModeloDeConsecuencia,
    recalcularNodo,
    descargarArchivos,
    cargarImagen,
    eliminarUnArchivo
} from '../../scripts/consultas/consultasBasicas';
import Preloader from '../../../../../../utilidades/componentes/plantillaBase/Preloader';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { RUTAS, GRUPOS, MODELOS_CONSECUENCIA, } from '../Inventario/scripts/constantes';
import {
    ListGroup,
    ListGroupItem,
    Popover,
    PopoverBody
} from 'reactstrap';

import TablaDePropiedades from './TablaDePropiedades';
import { Link, useHistory } from 'react-router-dom';
import BotonParaSection from '../../../../../../utilidades/componentes/plantillaBase/BotonParaSection';
import { FaRedoAlt, FaWrench, FaRegTrashAlt } from 'react-icons/fa';
import { confirmarBajaDeValvula, mensajeConfirmacionEliminar, confirmacionDeEliminacionDeActivo } from '../../../../../../utilidades/almacen/alertasParaSwal';
import { ESTADO_PRD, MODELO_CONSECUENCIA, VSP } from '../../scripts/constantes';
import validarModelos from '../../scripts/validarVariablesModelo';
import CustomModal from '../../../../../../utilidades/componentes/plantillaBase/CustomModal';
import CardBody from 'reactstrap/lib/CardBody';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { BsFileText, BsQuestionCircle } from 'react-icons/bs'
import GaleriaDeFotos from '../../componentes/GaleriaDeFotos';
import Error404 from '../../../../../404';
import { firebaseFunctions } from '../../../../../../firebase';
const eliminarActivoPRD = firebaseFunctions.httpsCallable('eliminarActivoPRD');


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 380,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: 450,
    },
    contenModel: {
        marginTop: 10,
        marginBottom: 10
    },
    margenCard: {
        padding: '8px'
    }
}));

const variables = {
    "TAG": {
        "id": "TAG",
        "ruta": RUTAS.TAG,
        "color": "green",
        "grupo": GRUPOS.INFORMACION_PRD
    },
    "SISTEMA": {
        "id": "Sistema o servicio",
        "ruta": RUTAS.SERVOSISTEM,
        "color": "red",
        "grupos": GRUPOS.LOCALIZACION,
    },
    "EQUIPO": {
        "id": "Tipo de equipo protegido",
        "ruta": RUTAS.TIPO_DE_EQUIPO,
        "color": "yello",
        "grupos": GRUPOS.INFORMACION_PRD,
    },
    "DIÁMETRO": {
        "id": "Diámetro del activo",
        "ruta": RUTAS.DIAMETRO_DE_ACTIVO,
        "color": "gray",
        "grupos": GRUPOS.INFORMACION_PRD,
    },
    "RATING ENTRADA": {
        "id": "Rating de entrada",
        "ruta": RUTAS.RATING_ENTRADA,
        "grupos": GRUPOS.INFORMACION_PRD,
    },
    "RATING SALIDA": {
        "id": "Rating de salida",
        "ruta": RUTAS.RATING_SALIDA,
        "grupos": GRUPOS.INFORMACION_PRD,
    },
    "MARCA": {
        "id": "Marca",
        "ruta": RUTAS.FABRICANTE,
        "grupos": GRUPOS.INFORMACION_FABRICANTE,
    },
    "SERIAL": {
        "id": "Serial",
        "ruta": RUTAS.SERIAL,
        "grupos": GRUPOS.INFORMACION_FABRICANTE,
    },
    "NÚMERO DEL ACTIVO": {
        "id": "Código del activo",
        "ruta": RUTAS.COD_ACTIVO,
        "grupos": GRUPOS.INFORMACION_FABRICANTE,
    },
    "PRESIÓN DE DISEÑO": {
        "id": "Resultado de la prueba de presión",
        "ruta": "parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.resultPruebPresion"
    },
}

async function loadData(storage, id) {
    let data = {};
    if (Object.keys(storage).length === 0 || !get(storage, 'data.analisis')) {
        data = await obtenerSoloUnPRD(id).catch(() => ({ error: "error" }))
    } else {
        try {
            const analisis = get(storage, 'data.analisis');
            const campo = get(storage, 'target.analisis.campo');

            if (!analisis || !campo) throw new Error("Error: no existe campo o analisis");
            forEach(analisis[campo], (value, key) => {
                const r = value.find(item => item.id === id)
                if (r) data = r;
            })
            if (isEmpty(data)) throw new Error("NO SE ENCONTRARON EN EL STORE");
        } catch (e) {
            data = await obtenerSoloUnPRD(id).catch(() => ({ error: "error" }))
        }
    }
    return data;
}

function DetalleInventario() {
    const { id, modulo } = useParams();
    const { getStateApi581PRD, dispatch } = useStoreApi581PRD()
    const [getData, setData] = useState();
    const [getImg, setImg] = useState();
    const [estaCargando, setCargando] = useState(true);
    const [getReloadPage, setReloadPage] = useState(false);
    const [openModal, setToggle] = useState(false);
    const redirecTo = useRef();
	const history = useHistory();

    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);

    const [listaVariable, setLista] = useState([]);
    const classes = useStyles();

    const dominio = get(getData, RUTAS.DOMINIO_CORPORATIVO);
    const tipoDeValvula = get(getData, RUTAS.TIPO_DE_VALVULA);

    useEffect(() => {
        async function fetchData() {
            const obtenerDatos = await loadData(getStateApi581PRD(), id);
            setData(obtenerDatos);
            setCargando(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getReloadPage]);

    useEffect(() => {
        async function fetchData() {
            if (dominio && tipoDeValvula) {
                const imagenes = await descargarArchivos(`imagenesPRD/${dominio}/${tipoDeValvula}`);
                setImg(imagenes);
                setCargando(false);
            }
        }
        fetchData();
    }, [getReloadPage, dominio, tipoDeValvula]);

    function renderGrupos(grup, props) {
        return (
            Object.keys(variables).filter(item => variables[item].grupos === grup).map((item, i) => (
                <Row key={item + i}>
                    <Col key={`${item}${i}${1}`} style={{ "fontFamily": "system-ui" }}>
                        {variables[item].id}
                    </Col>
                    <Col key={`${item}${i}${3}`} xs={{ size: "auto" }} style={{ color: variables[item].color }}>
                        {get(props.data, variables[item].ruta) || "---"}
                    </Col>
                </Row>
            ))
        );
    }

    function guardarImagen(img, index) {
        const dominio = get(getData, RUTAS.DOMINIO_CORPORATIVO);
        const tipoDeValvula = get(getData, RUTAS.TIPO_DE_VALVULA);
        toastMessage('Subiendo imagen...', 'warning');
        cargarImagen(`imagenesPRD/${dominio}/${tipoDeValvula}/valvula@${img.name}`, img)
            .then(() => {
                toastMessage('La imagen fue subida con exito')
                reloadPage()
            })
            .catch(() => toastMessage('La imagen no pudo ser cargada', 'danger'));
    }

    function eliminarImagen(ruta) {
        Swal.fire(mensajeConfirmacionEliminar())
            .then(result => {
                if (result.value) {
                    eliminarUnArchivo(ruta)
                        .then(() => {
                            toastMessage('La imagen fue eliminada con exito', 'success');
                            reloadPage();
                        })
                        .catch(() => {
                            toastMessage('Ocurrio un error al eliminar la imagen', 'danger');
                        })
                }
            })

    }

    function PanelPrincipal(props) {
        return (
            <Card className="card-shadow-primary profile-responsive card-border mb-3">
                <Row>
                    <Col xs="12" md="4" /*className="opacity-3 pr-0"*/
                        style={{ backgroundColor: "#F5F5EF", backgroundImage: props.bkImg, paddingLeft: '28px' }}>
                        <GaleriaDeFotos
                            imagen={getImg}
                            btnText={'Agregar'}
                            ancho={350}
                            alto={220}
                            getImgToSave={(img, index) => { guardarImagen(img, index) }}
                            onDelete={(ruta) => { eliminarImagen(ruta) }}
                        />
                    </Col>
                    <Col xs="12" md="8" style={{ height: "100%" }}>
                        <ListGroup flush>
                            <ListGroupItem className="p-0">
                                <div className="grid-menu grid-menu-2col">
                                    <Row className="no-gutters" style={{ height: '285px' }}>
                                        <Col xs="12" md="6" >
                                            <Button
                                                className="btn-icon-vertical btn-transition  btn-transition-text"
                                                outline color="link"
                                                style={{ padding: 0, height: "100%", cursor: "default" }}

                                            >
                                                <Row style={{ height: "100%", width: "100%" }} >
                                                    <Col xs="3" style={{ padding: "10px", textAlign: "center", writingMode: "vertical-rl" }}>
                                                        <i style={{ color: "green" }} className="lnr-book btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    </Col>
                                                    <Col xs="9" style={{ padding: "10px", textAlign: "left", paddingTop: "20px" }}>
                                                        {renderGrupos(GRUPOS.INFORMACION_PRD, props)}
                                                    </Col>
                                                </Row>

                                            </Button>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <Button
                                                className="btn-icon-vertical btn-square  btn-transition-text btn-transition"
                                                outline color="link"
                                                style={{ cursor: "default" }}
                                            >
                                                <i style={{ color: "red" }} className="lnr-map-marker btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                <p style={{ marginBottom: 0 }}>Localización</p>
                                                <p style={{ marginBottom: 0 }}>{`${get(props.data, RUTAS.CAMPO) || "Sin campo"} / ${get(props.data, RUTAS.PLANTAOESTACION) || "Sin planta"} /${get(props.data, RUTAS.SERVOSISTEM) || "Sin sistema"}`}</p>

                                            </Button>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <Button
                                                className="btn-icon-vertical btn-square  btn-transition-text btn-transition"
                                                outline color="link"
                                                style={{ padding: 0, height: "100%", cursor: "default" }}
                                            >
                                                <Row style={{ height: "100%", width: "100%" }} >
                                                    <Col xs="3" style={{ padding: "10px", textAlign: "center", writingMode: "vertical-rl" }}>

                                                        <i style={{ color: "blue" }} className="lnr-cog btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    </Col>
                                                    <Col xs="9" style={{ padding: "10px", textAlign: "left", paddingTop: "20px" }}>
                                                        {renderGrupos(GRUPOS.INFORMACION_FABRICANTE, props)}
                                                    </Col>
                                                </Row>

                                            </Button>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <Row>
                                                <Col sm="6">
                                                    <Button
                                                        className="btn-icon-vertical btn-square  btn-transition-text btn-transition"
                                                        outline color="link"
                                                        onClick={() => {
                                                            if (props.data && get(props, "data.estados.valorada")) {
                                                                redirecTo.current.click();
                                                            } else {
                                                                return alert("Actualmente no existe informacion de valoración");
                                                            };
                                                        }}

                                                    >
                                                        <i style={{ color: "#ECCC00" }} className="lnr-pie-chart btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                        <p style={{ marginBottom: 0 }}>Valoración</p>

                                                    </Button>
                                                </Col>
                                                <Col sm="6">
                                                    <Button
                                                        className="btn-icon-vertical btn-square  btn-transition-text btn-transition pl-0"
                                                        outline color="link"
                                                        onClick={() => { alert("Estamos trabajando en el módulo de mantenimiento") }}
                                                    >
                                                        <i className="lnr-history btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                        <p style={{ marginBottom: 0 }}>Mantenimiento</p>

                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
            </Card>
        )
    }

    function reloadPage() {
        setCargando(true);       
        setReloadPage(!getReloadPage);
    }

    function sacarDeServicio(props) {
        if (props.id) {
            quitarDeServcio(props.id).then(() => {
                dispatch({});
                toastMessage("La válvula fue sacada de servicio");
                reloadPage();
            })
                .catch(e => {
                    toastMessage("Hubo un problema en la operación", "warning");
                })
        }
    }

    const toastMessage = (palabra, type) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: type || 'success'
        })
    }

    function cambioDeModelo(modelo) {
        setPopoverOpen(false);
        cambiarModeloDeConsecuencia(getData.id, modelo)
            .then(result => {
                dispatch({});
                toastMessage("Inicia el proceso de cálculos", "warning");
                recalcularNodo(getData.id)
                    .then(result => {
                        switch (result.data.status) {
                            case "ERROR_ACTUALIZANDO_PRD":
                                toastMessage("Error al relizar cálculos", "warning");
                                break;
                            case "PRD_ACTUALIZADO_CON_EXITO":
                                toastMessage("Recáculo exitoso");
                                break;
                            default:
                                break;
                        }
                        reloadPage();
                    })
                    .catch(e => {
                        toastMessage("Error al realizar cálculos", "warning");
                    })
            })
            .catch(e => {
                toastMessage("Ocurrio un error al calcular", "warning")
            })
    }

    function validarVariablesParaModelos(lista) {
        const variablesSemicuantitativas = validarModelos(lista, MODELO_CONSECUENCIA.SEMICUANT);
        const variablesCualitativas = validarModelos(lista, MODELO_CONSECUENCIA.CUALIT);
        return {
            'listaCualitativos': variablesCualitativas,
            'listaSemicuant': variablesSemicuantitativas,
        }
    }

    function toggleDelModal() {
        setToggle(!openModal);
    }

    function PintarItemsVariables({ item, EVSP }) {
        const icono = (item) => {
            const validarValor = (item) =>{
                if(item.tipo === 'númerico' && item.valor !== '-'  && item.valor >= 0) return true; // 0 = false 
                if(item.valor && item.valor !== '-') return true;                
                return false;
            }
            if (validarValor(item)) return <CheckCircleOutlineIcon style={{ color: 'green' }} />

            if (validarValor(item) && item.evento === VSP) return <CheckCircleOutlineIcon style={{ color: 'green' }} />
            if (item.evento === VSP && EVSP) return <CheckCircleOutlineIcon style={{ color: '#DF9316' }} />
            return (
                <>
                    <Tooltip title="Algunas variables dependen del equipo protegido">
                        <CheckCircleOutlineIcon style={{ color: 'red', cursor: 'pointer' }} />
                    </Tooltip>
                </>
            );
        }
        return (
            <>
                <ListItem key={item.key}>
                    <ListItemIcon>
                        {icono(item)}
                    </ListItemIcon>
                    <ListItemText primary={item.nombre} />
                </ListItem>
            </>
        )
    }

    function PintarListaDeVariables() {

        if (listaVariable.length > 0) {
            const { listaCualitativos, listaSemicuant } = validarVariablesParaModelos(listaVariable);
            listaCualitativos.lista.sort((a) => {
                if (a.valor === '-' || !a.valor) return -1;
                return 0
            })
            listaSemicuant.lista.sort((a) => {
                if (a.valor === '-' || 
                !a.valor 
                ) return -1;
                return 0
            })
            return (
                <>
                    <Row>
                        <Col style={{ paddingRight: 8 }}>
                            <Card className={classes.contenModel}>
                                <CardHeader className={classes.margenCard} title={'Modelo Cualitativo'} />
                                <CardBody className={classes.margenCard}>
                                    <List component="nav" className={classes.root} aria-label="contacts">
                                        {listaCualitativos.lista.map((item, index) => <PintarItemsVariables key={item.nombre + index} item={item} EVSP={listaCualitativos.EVSP} />)}
                                    </List>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col style={{ paddingLeft: 8 }}>
                            <Card className={classes.contenModel}>
                                <CardHeader className={classes.margenCard} title={'Modelo Semicuantitativo'} />
                                <CardBody className={classes.margenCard} >
                                    <List component="nav" className={classes.root} aria-label="contacts">
                                        {listaSemicuant.lista.map((item, index) => <PintarItemsVariables key={item.key + index} item={item} EVSP={listaCualitativos.EVSP} />)}
                                    </List>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            )
        }
        return <></>
    }

	function clickEliminarActivo(){
		const idActivoAEliminar = {
	        "idActivo": getData.id
        }
        const nombreActivoAEliminar = getData.nombre
        Swal.fire(confirmacionDeEliminacionDeActivo(nombreActivoAEliminar))
        	.then((result) => {
        		if(result.isConfirmed){
        			setCargando(true);					
        			eliminarActivoPRD(idActivoAEliminar)
					.then((response) => {
						if(response.data.status === "ACTIVO_ELIMINADO"){
							dispatch({});
						    toastMessage(`Activo ${nombreActivoAEliminar} Eliminado`)
						    history.push('/app/gestion/api581Valvulas/inventario')
						}else{
							toastMessage(`Error al eliminar el activo ${nombreActivoAEliminar}`, 'error')
						}
					}).catch(e => {
						toastMessage(`Error al eliminar el activo ${nombreActivoAEliminar}`, 'error');
					})				
        		}
        	});
    }
    function MensajeTooltip() {
        return (
            <Grid container direction="column">
                <Grid item container direction="row" alignItems="center" spacing={1} >
                    < Grid item>
                        <CheckCircleOutlineIcon style={{ color: '#4ADF16' }} />
                    </Grid>
                    < Grid item>
                        Información completa
                </Grid>
                </Grid>
                <Grid item container direction="row" alignItems="center" spacing={1} >
                    < Grid item>
                        <CheckCircleOutlineIcon style={{ color: '#DF9316' }} />
                    </Grid>
                    < Grid item>
                        Información opcional
                </Grid>
                </Grid>
                <Grid item container direction="row" alignItems="center" spacing={1} >
                    < Grid item>
                        <CheckCircleOutlineIcon style={{ color: 'red' }} />
                    </Grid>
                    < Grid item>
                        Información faltante
                </Grid>
                </Grid>
            </Grid>)
    }

    if (getData && !getData.error) {
        return (
            <CustomSection
                titulo={`Hoja de vida de la válvula ${getData ? get(getData, RUTAS.TAG) : ""} `}
                subtitulo={`${get(getData, RUTAS.CAMPO) || "Sin campo"} / ${get(getData, RUTAS.SERVOSISTEM) || "Sin sistema"} / ${get(getData, RUTAS.TIPO_DE_VALVULA) || "Sin tipo de valvula"}`}
                URLIconoPrincipal="/assets/img/iconos/iconoSegmento.jpg"
                subtitulo2={`Estado: ${get(getData, RUTAS.ESTADO) || "No definido"}`}
                botonesAdicionales={[
                    <BotonParaSection key="btn-lista-model" icono={<BsFileText fontSize='large' />} onClick={() => toggleDelModal()} />,
                    <BotonParaSection key="btn-btn-actulizar" icono={<FaRedoAlt />} onClick={() => reloadPage()} />,
                    get(getData, RUTAS.ESTADO) !== ESTADO_PRD.FUERA_DE_SERVICIO ?
                        <BotonParaSection id="BotonAgregarModelo" key="BotonAgregarModelo" icono={<FaWrench />} onClick={() => { }} title={'Cambiar Modelo de Consecuencia'} />
                        : <React.Fragment key={'botonNulo'} />,
					<BotonParaSection key="BotonDeEliminarActivo" icono={<FaRegTrashAlt />} onClick={() => { clickEliminarActivo() }} title={'Eliminar Activo'} />
                ]}
            >
                <Grid className="pt-2">
                    <PanelPrincipal data={getData} bkImg={getImg} modulo={modulo} />
                </Grid>
                <Grid>
                    <TablaDePropiedades data={getData} onGetListaDeVariables={(lista) => {
                        if (!listaVariable.length > 0) setLista(lista)
                    }}
                        onActulizarLista={({ key, value }) => {
                            const lista = listaVariable;
                            const index = lista.findIndex(item => item.key === key);
                            lista[index].valor = value;
                            setLista(lista);
                        }}
                    />
                </Grid>
                <Link to={'/app/detalleDePRD/prd/' + id + '/' + modulo} ref={redirecTo} />
                <Preloader elPreloaderEstaAbierto={estaCargando} />
                {
                    get(getData, RUTAS.ESTADO) !== ESTADO_PRD.FUERA_DE_SERVICIO ?
                        <Popover
                            placement={"bottom"}
                            isOpen={popoverOpen}
                            target="BotonAgregarModelo"
                            toggle={toggle}
                        >
                            <PopoverBody>
                                <Row className="no-gutters">

                                    <Col xs="12" style={{ padding: 2 }}>
                                        <Button key={"botonSacarDeServicio"} style={{ width: "100%" }} onClick={() => {
                                            setPopoverOpen(false);
                                            Swal.fire(confirmarBajaDeValvula)
                                                .then(result => {
                                                    if (result.value) sacarDeServicio(getData);
                                                })
                                        }}>Sacar de servicio</Button>
                                    </Col>

                                    <Col xs="12" style={{ padding: 2 }}>
                                        <Button style={{ width: "100%" }} onClick={() => cambioDeModelo(MODELOS_CONSECUENCIA.SEMICUANTITATIVO)}>Valorar por modelo semicuantitativo</Button>
                                    </Col>
                                    <Col xs="12" style={{ padding: 2 }}>
                                        <Button style={{ width: "100%" }} onClick={() => cambioDeModelo(MODELOS_CONSECUENCIA.CUALITATIVO)}>Valorar por modelo cualitativo</Button>
                                    </Col>
                                </Row>
                            </PopoverBody>
                        </Popover>
                        : <></>
                }

                <CustomModal
                    titulo={<>
                        <Row>
                            <Col xs='11'>
                                <h4 style={{ marginBottom: '0px' }}>Lista de variables por modelo de consecuencia</h4>
                            </Col>
                            <Col xs='1'>
                                <Tooltip title={<MensajeTooltip />} arrow>
                                    <Grid>
                                        <BsQuestionCircle fontSize='large' />
                                    </Grid>
                                </Tooltip>
                            </Col>
                        </Row>
                    </>
                    }
                    elModalEstaAbierto={openModal}
                    toggleDelModal={toggleDelModal}
                    hideFooter
                    children={<PintarListaDeVariables />}
                />
            </CustomSection>
        )
    }

    if (getData && getData.error) return <Error404 error={'Detalle de inventario'} />
    if (!getData) return <>
        <Preloader elPreloaderEstaAbierto={estaCargando} />
    </>
}

export default DetalleInventario
