import React from 'react';

import { FaHardHat, FaGlasses, FaScrewdriver, FaDesktop, FaBuffer, FaClipboardCheck } from 'react-icons/fa';

import CustomTable from '../../../../../utilidades/componentes/visualizadores/CustomTable';
import ModalDeLosPlanesDeAccion from './ModalDeLosPlanesDeAccion';

//Table
let estiloParaCheck = { width: '100%', cursor: 'pointer' };
let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloCentrarItems = { display: "flex", width: "100%", justifyContent: 'center' };
let estiloSegmentos = { display: "flex", width: "100%", justifyContent: 'center', cursor: 'pointer', textDecoration: 'underline' }


class TablaPlanesDeAccion extends React.Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            componenteEncontrado: {},
            modalDesplegado: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalDeLosPlanesDeAccion
                    componenteEncontrado={this.state.componenteEncontrado}
                    modalDesplegado={this.state.modalDesplegado}
                    toggleModal={this.toggleModal}
                />
                <CustomTable listaDeDatos={this.props.tablaConFiltro} showPagination={true}
                    loadingText="Cargando datos..." largoDePaginacion={20} columnas={[
                        {
                            accessor: 'tipoDeActivo',
                            Header: () => (<p style={estiloParaHeader}>Tipo</p>),
                            Cell: ({ row }) => (
                                <div style={estiloCentrarItems}>
                                    <p className="text-capitalize" style={{ margin: 0 }}> {row.tipoDeActivo}</p>
                                </div>
                            ),
                            minWidth: 65
                        },
                        {
                            accessor: 'nombreDelActivo',
                            Header: () => (<p style={estiloParaHeader}>Activo</p>),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloCentrarItems, justifyContent: "none" }}>
                                    <p style={{ margin: 0 }}> {row.nombreDelActivo}</p>
                                </div>
                            ),
                            minWidth: 100
                        },
                        {
                            accessor: 'nombreDelComponente',
                            Header: () => (
                                <p style={estiloParaHeader}>Componente</p>
                            ),
                            Cell: ({ row }) => (
                                <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                    <p style={{ margin: 0, color: "black" }} onClick={() => {
                                        this.toggleModal()
                                        this.encontrarObjetoDelComponente(row._original)
                                    }}>{row.nombreDelComponente}</p>
                                </div>
                            ),
                            minWidth: 100
                        },
                        {
                            accessor: 'categoria',
                            Header: () => (<p style={estiloParaHeader}>Categoría</p>),
                            Cell: row => {
                                let icono;
                                switch (row.original.categoria) {
                                    case 'inspeccion':
                                        icono = <FaGlasses />;
                                        break;
                                    case 'ingenieria':
                                        icono = <FaHardHat />;
                                        break;
                                    case 'mantenimiento':
                                    case 'mitigacion':
                                        icono = <FaScrewdriver />;
                                        break;
                                    case 'monitoreo':
                                        icono = <FaDesktop />;
                                        break;
                                    case 'otros':
                                        icono = <FaBuffer />;
                                        break;
                                    default:
                                        return <React.Fragment />;
                                }
                                return <div style={{ ...estiloCentrarItems, flexDirection: 'column', alignItems: 'center' }}>
                                    <p style={{ fontSize: '16px', marginBottom: 4 }}
                                        id="TooltipIconos"
                                        href='#'
                                        title={row.original.categoria}
                                    >
                                        {icono}</p>
                                    <p style={{ fontSize: '10px', marginBottom: 0 }}>{this.obtenerNombre(row.original.categoria)}</p>
                                </div>
                            },
                            maxWidth: 100
                        },
                        {
                            accessor: 'accion',
                            Header: () => (<p style={estiloParaHeader}>Acción</p>),
                            minWidth: 200
                        },
                        {
                            accessor: 'alcance',
                            Header: () => (<p style={estiloParaHeader}>Alcance</p>),
                            minWidth: 100
                        },
                        {
                            accessor: 'frecuencia',
                            Header: () => (<p style={estiloParaHeader}>FreQ</p>),
                            Cell: ({ row }) => (
                                <div style={{ display: "flex", width: "100%", justifyContent: 'center' }}>
                                    <p style={{ margin: 0 }} >{row.frecuencia}</p>
                                </div>
                            ),
                            minWidth: 100
                        },
                        {
                            accessor: 'estado',
                            Header: () => (<p style={estiloParaHeader}><FaClipboardCheck /></p>),
                            Cell: ({ row }) => (
                                <div style={{ display: "flex", width: "100%", justifyContent: 'center' }}>
                                    <input checked={
                                        row.estado
                                    } label="&nbsp" style={estiloParaCheck} type="checkbox" onChange={(ev) => { this.props.cambiarEstadoDelCheck(ev, row) }} />                                                </div>
                            ),
                            maxWidth: 50
                        }
                    ]}
                />
            </React.Fragment>
        )
    }

    encontrarObjetoDelComponente(componente) {
        let propiedadesDeLaFila = {
            categoria: componente.tipoEvaluacion['API581']?componente.tipoEvaluacion['API581']:false,
            id: componente.id,
            nombreDelComponente: componente.nombreDelComponente,
            nombreDelCampo: componente.nombreDelCampo,
            nombreDelSistema: componente.nombreDelSistema,
            nombreDelActivo: componente.nombreDelActivo,
            dfPred: componente.dfPred,
            amenazaPred: componente.amenazaPred,
            nivelDeRiesgo: componente.nivelDeRiesgo,
            probabilidad: componente.probabilidad,
            consecuencia: componente.consecuencia,
            tieneInspeccion: componente.tieneInspeccion,
            ubicacionDeCMLscanA: componente.ubicacionDeCMLscanA,
            ubicacionDeCMLscanBC: componente.ubicacionDeCMLscanBC
        };
        this.setState({
            componenteEncontrado: propiedadesDeLaFila
        }
        )
    }
    
    toggleModal() {
        this.setState(prevState => ({
            modalDesplegado: !prevState.modalDesplegado
        }));
    }

    obtenerNombre = (accion) => {
        switch (accion) {
            case 'inspeccion':
                return 'Inspección';
            case 'ingenieria':
                return 'Ingeniería';
            case 'mantenimiento':
            case 'mitigacion':
                return 'Mitigación';
            case 'monitoreo':
                return 'Monitoreo';
            case 'otros':
                return 'Otros';
            default:
                return 'Otros';
        }
    }
};


export default TablaPlanesDeAccion;