import React from 'react'

import { Col, Row, BreadcrumbItem } from 'reactstrap';
import { MdEdit } from "react-icons/md";
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import CustomModal from '../../../../../../utilidades/componentes/plantillaBase/CustomModal';

import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

//Iconos
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = { display: "flex", width: "100%", justifyContent: 'center', cursor: 'pointer', textDecoration: 'underline' }

class CaracterizacionDeAbolladuras extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            elModalDeAbolladurasEstaAbierto: false,
            distanciaCaALosHombros: [],
            localizacionDeHombros: [],
            tituloDelModal: ''
        }
    }

    render() {
        return (
            <React.Fragment>
                <br />
                <div className={"mb-0 p-3 d-flex border rounded"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                    <BreadcrumbItem className='BreadcrumbItem-color' style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black'}}>CARACTERIZACIÓN, VALORACIÓN Y EVALUACIÓN DE ABOLLADURAS</BreadcrumbItem>
                    <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom",}}>
                        {this.props.authRol ? <BotonCallToAction key={'btn_ubicacion_ili'}
                            usuarioActivo={this.props.usuarioActivo}
                            color={'primary'}
                            icono={MdEdit}
                            titulo={'Editar Datos'}
                            onClick={() => {
                                this.props.toggleModalEdicionAnomalias()
                                this.props.setTipoAnomaliaEdicion('ABOLLADURA')
                                this.props.setAnomaliaEdicion(this.props.anomaliasAbolladuras[0].idDeAnomalia)
                            }}
                        /> : null}
                    </div>
                </div>
                
                {this.obtenerRenderDeModal()}
                
                <br />
                <CustomTable
                    listaDeDatos={this.props.anomaliasAbolladuras}
                    columnas={
                        [
                            {
                                Header: () => (<p style={estiloParaHeader}>Anomalias de abolladuras</p>),
                                columns: [
                                    {
                                        accesor: 'idAnomaliaILI',
                                        Header: () => (<p style={estiloParaHeader}>Ver detalle</p>),
                                        Cell: ({ row }) => (
                                            <div className="shadow-sm mx-auto d-flex" 
                                                style={{borderRadius: '50%', width:30, height:30, backgroundColor: '#21618C', cursor: 'pointer'}}
                                                onClick={() => {
                                                    this.toggleDelModalAbolladuras();
                                                    this.filtrarPortipoAnomaliaPrincipal(row._original.idAnomaliaILI);
                                                }}   
                                            >
                                                <FontAwesomeIcon 
                                                    className="m-auto opacity-9 justify-content-center" 
                                                    icon={faSearch}
                                                    color={'white'}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'idAnomaliaILI',
                                        Header: () => (<p style={estiloParaHeader}>ID Anomalia ILI</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: '#545cd8', fontWeight: 300 }}
                                                >{row._original.idAnomaliaILI}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'distanciaRegistro',
                                        Header: () => (<p style={estiloParaHeader}>Distancia Registro (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.distanciaRegistro}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'longitudAbolladura',
                                        Header: () => (<p style={estiloParaHeader}>Longitud Abolladura (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.longitudAbolladura}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'anchoAbolladura',
                                        Header: () => (<p style={estiloParaHeader}>Ancho Abolladura (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.anchoAbolladura}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'profundidad',
                                        Header: () => (<p style={estiloParaHeader}>Profundidad (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.profundidad}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'reduccionDiametro',
                                        Header: () => (<p style={estiloParaHeader}>Reducción Diámetro (%)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{isNaN(row._original.reduccionDiametro) ? '' : parseFloat(row._original.reduccionDiametro).toFixed(3)}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'distanciaH3JuntaAnterior',
                                        Header: () => (<p style={estiloParaHeader}>Distancia H3 a J. Anterior (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.distanciaH3JuntaAnterior}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'distanciaH4JuntaPosterior',
                                        Header: () => (<p style={estiloParaHeader}>distancia H4 a J. Posterior (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.distanciaH4JuntaPosterior}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'distanciaJuntaLongitudinal',
                                        Header: () => (<p style={estiloParaHeader}>Distancia a J. Longitudinal (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.distanciaJuntaLongitudinal}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'interaccionConArrugasOConcentradorDeEsfuerzos',
                                        Header: () => (<p style={estiloParaHeader}>Interracción con arrugas o concentrador de esfuerzos</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.interaccionConArrugasOConcentradorDeEsfuerzos}</p>
                                            </div>
                                        ),
                                        width: 120
                                    },
                                    {
                                        accesor: 'interSoldadura',
                                        Header: () => (<p style={estiloParaHeader}>Inter Soldadura</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.interSoldadura}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'posicionHoraria',
                                        Header: () => (<p style={estiloParaHeader}>Posición Horaria</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.posicionHoraria}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        accesor: 'evaluacionDeLaAbolladura',
                                        Header: () => (<p style={estiloParaHeader}>Evaluación de la abolladura</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                >{row._original.evaluacionDeLaAbolladura || ''}</p>
                                            </div>
                                        ),
                                    },
                                ]
                            }
                        ]
                    }
                />
                {this.pintarInteraccionPerdidaMetal()}
            </React.Fragment>
        )
    }

    //Funciones Render
    obtenerRenderDeModal = () =>{
        return (
            <CustomModal
                elModalEstaAbierto={this.state.elModalDeAbolladurasEstaAbierto}
                toggleDelModal={this.toggleDelModalAbolladuras}
                titulo={`ID de la anomalía ILI: ${this.state.tituloDelModal}`}
                children={
                    <Row>
                        <Col className='margin-top-de-tablas' xs='12' md='12' lg='6'>
                            <CustomTable
                                listaDeDatos={this.state.distanciaCaALosHombros}
                                columnas={
                                    [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Dist. CA a los Hombros</p>),
                                            columns: [
                                                {
                                                    accesor: 'H',
                                                    Header: () => (<p style={estiloParaHeader}>Hombro</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.H}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'distanciaCAALosHombros',
                                                    Header: () => (<p style={estiloParaHeader}>distancia</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.distanciaCAALosHombros}</p>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    accesor: 'mm',
                                                    Header: () => (<p style={estiloParaHeader}>Medida</p>),
                                                    Cell: ({ row }) => (
                                                        <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                            <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                            >{row._original.mm}</p>
                                                        </div>
                                                    ),
                                                },
                                            ]
                                        }
                                    ]
                                }
                            />

                </Col>
                <Col className='margin-top-de-tablas margin-bottom-de-tablas' xs='12' md='12' lg='6' >
                    <CustomTable
                        listaDeDatos={this.state.localizacionDeHombros}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={estiloParaHeader}>Localización de hombros</p>),
                                    columns: [
                                        {
                                            accesor: 'hombro',
                                            Header: () => (<p style={estiloParaHeader}>Hombro</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.hombro}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'distanciaCAALosHombros',
                                            Header: () => (<p style={estiloParaHeader}>Distancia desde 12:00</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.distanciaCAALosHombros}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            accesor: 'posicionHoraria',
                                            Header: () => (<p style={estiloParaHeader}>Medida</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    >{row._original.posicionHoraria}</p>
                                                </div>
                                            ),
                                        },
                                    ]
                                }
                            ]
                        }
                    />
                </Col>
            </Row>
            }
            />
        )
    }

    pintarInteraccionPerdidaMetal = () => {
        const existeInteraccion =  this.props.anomaliasAbolladuras.some(anomalia => anomalia.tipoDeAnomaliaInteraccion)
        if (existeInteraccion) {
            return (
                <Row>
                    <Col className='margin-top-de-tablas'>
                        <CustomTable
                            listaDeDatos={this.props.anomaliasAbolladuras}
                            columnas={
                                [
                                    {
                                        Header: () => (<p style={estiloParaHeader}>INTERACCIÓN CON PÉRDIDA DE METAL (CORROSIÓN O DAÑO MECÁNICO)</p>),
                                        columns: [
                                            {
                                                accesor: 'tipoDeAnomaliaInteraccion',
                                                Header: () => (<p style={estiloParaHeader}>Tipo de anomalía</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.tipoDeAnomaliaInteraccion}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'distanciaRegistroInteraccion',
                                                Header: () => (<p style={estiloParaHeader}>Distancia Registro (m)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.distanciaRegistroInteraccion}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'distaciaALas12',
                                                Header: () => (<p style={estiloParaHeader}>Distancia a las 12:00 (mm)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.distaciaALas12}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'posicionHorariaInteraccion',
                                                Header: () => (<p style={estiloParaHeader}>Posición Horaria (hh:mm)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.posicionHorariaInteraccion}</p>
                                                        </div>
                                                ),
                                            },
                                            {
                                                accesor: 'longitudInterracion',
                                                Header: () => (<p style={estiloParaHeader}>Longitud (mm)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.longitudInterracion}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'anchoInterracion',
                                                Header: () => (<p style={estiloParaHeader}>Ancho (mm)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.anchoInterracion}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'profundidadInterracion:',
                                                Header: () => (<p style={estiloParaHeader}>Profundidad (mm)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{row._original.profundidadInterracion}</p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                accesor: 'reduccionDeEspesor',
                                                Header: () => (<p style={estiloParaHeader}>Reducción de espesor (%)</p>),
                                                Cell: ({ row }) => (
                                                    <div style={{ ...estiloSegmentos, justifyContent: "none", textDecoration: 'none', fontWeight: 'bold', color: 'rgba(32, 39, 140, 0.5)' }}>
                                                        <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                        >{isNaN(row._original.reduccionDeEspesor) ? '--' : parseFloat(row._original.reduccionDeEspesor).toFixed(3)}</p>
                                                    </div>
                                                ),
                                            },
                                        ]
                                    }
                                ]
                            }
                        />
                    </Col>
                </Row>
            )
        }
    }

    //Funciones Lógicas
    filtrarPortipoAnomaliaPrincipal = (idDeTabla) => {
        let filtroDedistanciaCaALosHombros = this.props.distanciaCaALosHombros.filter((algo) => {
            return algo.idAnomaliaILI === idDeTabla
        })
        let filtroDelocalizacionDeHombros = this.props.localizacionDeHombros.filter((algo) => {
            return algo.idAnomaliaILI === idDeTabla
        })
        this.setState({
            distanciaCaALosHombros: filtroDedistanciaCaALosHombros,
            localizacionDeHombros: filtroDelocalizacionDeHombros,
            tituloDelModal: idDeTabla
        })
    }

    toggleDelModalAbolladuras = () => {
        this.setState({
            elModalDeAbolladurasEstaAbierto: !this.state.elModalDeAbolladurasEstaAbierto
        })
    }
}

export default CaracterizacionDeAbolladuras;