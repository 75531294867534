import React from 'react';
import { Button } from 'reactstrap';
import { FaArrowUp } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { listaDeRutasSegunRutaActual } from '../scripts/listaDeRutasSegunRutaActual';


function BotonIrAtras(props) {
    const history = useHistory();

    const navegarSegunRutaActual = () => {
        let ruta;
        let link = window.location.href
        for (let key in listaDeRutasSegunRutaActual) {
            if (link.includes(key)) {
                if (key === 'formatoDeInspeccion/') {
                    ruta = listaDeRutasSegunRutaActual[key] + localStorage.getItem('ultimaOrdenVisitada').toString()
                }
                else {
                    ruta = listaDeRutasSegunRutaActual[key]
                }
            }
        }
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(ruta));
        history.push(ruta);
    }
    return (
        <React.Fragment>
            <Button className="btn-icon btn-icon-only text-primary shadow boton-atras m-0" outline color="link" onClick={navegarSegunRutaActual}
                style={{ border: 0, marginTop: 5 }} title='Volver'>
                <div>
                    <FaArrowUp style={{color:'#dce2ec'}} size={24}/>
                </div>
            </Button>
        </React.Fragment>
    );
}

export default BotonIrAtras;