import { firestoreDB } from "../../../../../firebase";

export const consultarActivosPorCampo = (dominioCorporativo, campoTarget, tipoActividad, modulo) => {

    return new Promise(resolve => {
        const listaDeNodos = [];
        let promiseDeConsultaDeActivos = firestoreDB.collection("bdPrueba/9AKrQUDKh3UGtqDD2Rpk/activos");
        promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("dominioCorporativo", "==", dominioCorporativo);
        promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionDeLosPadres.campoAsociado.nombre", "==", campoTarget);
        promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionGeneral.tipoDeActividad.nombre", "==", tipoActividad);
        promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where(`modulos.${modulo}` , "==", true);
        promiseDeConsultaDeActivos.get().then(snapShots => {
            snapShots.forEach(snapShot => {
                listaDeNodos.push({
                    id: snapShot.id,
                    ...snapShot.data(),
                    referencia: snapShot.ref
                });
                resolve(listaDeNodos);
            })
        })
        .catch(error => {console.log(error)});
        
    })
}
