import isNil from 'lodash/isNil';
import React from 'react'
import {
    Row, Col,
    Button,
    CardHeader,
    Card,
    CardFooter,
} from 'reactstrap';
import LinkConHistory from './LinkConHistory';


/**
 * 
 * @param {object} param 
 * containerProps,
 * titulo, 
 * info, 
 * bodyProps,
 * body: [{ containerProps , iconProps  , tituloSecundario, valor, 
 *          subtitulos:[ { label , valor, valorProps} ]
 *       }]
 * footer: { button: { label , props , conLink: { props , toPath }} } }
 * 
 */
function ValoresAgrupados({ titulo, info, bodyProps, containerProps, body = [], footer, ...props }) {
    return (
        <Card className="mb-1" {...containerProps}>
            {(!!titulo || !!info) && <CardHeader className="card-header-tab z-index-6">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal" >
                    {titulo}
                </div>
                {!!info && <div className="btn-actions-pane-right text-capitalize">
                    <span className="d-inline-block ml-2" style={{ marginRight: "1rem" }}>
                        {info}
                    </span>
                </div>}
            </CardHeader>}
            <Row className="no-gutters" {...bodyProps}>
                {[].concat(body).map((element, index) => (
                    <Col key={`${titulo || info}${index}`}{...element.containerProps}>
                        <div className="card no-shadow rm-border bg-transparent widget-chart text-left" style={{ overflowWrap: "anywhere" }}>
                            <div className="icon-wrapper rounded-circle">
                                <div className="icon-wrapper-bg opacity-10 bg-warning" {...element.containerIconProps} />
                                <i className="lnr-laptop-phone text-dark opacity-8" {...element.iconProps} />
                            </div>
                            <div className="widget-chart-content">
                                <div className="widget-subheading">
                                    {element.tituloSecundario}
                                </div>
                                <div className="widget-numbers">
                                    {element.valor}
                                </div>
                                {element.subtitulos && [].concat(element.subtitulos).map((sub, indexSub) => (
                                    <div key={`${titulo}${index}sub${indexSub}`} className="widget-description opacity-8 text-focus">
                                        {sub.label}
                                        {!isNil(sub.valor) && <div className="d-inline text-success pr-1" {...sub.valorProps}>
                                            <span className="pl-1">
                                                {sub.valor}
                                            </span>
                                        </div>}
                                    </div>)
                                )}
                            </div>
                        </div>
                        <div className="divider m-0 d-md-none d-sm-block" />
                    </Col>)
                )}
            </Row>
            {!!footer && <CardFooter className="text-center d-block p-3">
                {!!footer.button && !!footer.button.conLink && <LinkConHistory to={footer.button.conLink.toPath}
                    {...footer.button.conLink.props}
                >
                    <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg" {...footer.button.props}>
                        <span className="mr-1">
                            {footer.button.label}
                        </span>
                    </Button>
                </LinkConHistory>}
                {!!footer.button && !footer.button.conLink && <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg" {...footer.button.props}>
                    <span className="mr-1">
                        {footer.button.label}
                    </span>
                </Button>}
            </CardFooter>}
        </Card>
    )
}
export default ValoresAgrupados