const obtenerEstadoSegunFechaCalculada = (fechasCalculadas, fechaDeInspeccion) => {
    let estaCalculada = false;
    if (fechasCalculadas?.find( fecha => fecha.seconds === fechaDeInspeccion.seconds ) !== undefined) {
        estaCalculada = true;
    } else {
        estaCalculada = false;
    }
    return estaCalculada;
}
export default obtenerEstadoSegunFechaCalculada
