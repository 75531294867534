import { useEffect, useState } from "react";
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import set from 'lodash/set';
import useStoreApi581PRD from "./useStoreApi581PRD";
import obtenerResumen from "../scripts/consultas/obtenerResumen";
const initialState = {
    resumen: {},
    cargando: true
}
export default function useResumen() {
    const [state, setResumen] = useState(initialState)
    const { getStoreState, getStateApi581PRD, dispatch: dispatchStore, getDominioCorporativo } = useStoreApi581PRD()

    useEffect(() => {
        if (isEmpty(state.resumen) && state.cargando) {
            const storeState = getStoreState();
            const dominioCorporativo = getDominioCorporativo(storeState)
            const storePRD = getStateApi581PRD(storeState);
            // si existe información del resumen en redux store se guarda de una vez en el state. 
            // si no, se consulta, el resultado se guarda enel estado local y se guarda en objeto que va a ir al redux store
            if (has(storePRD, "resumen") && !isEmpty(storePRD.resumen)) {
                setResumen({ resumen: storePRD.resumen, cargando: false })
            }
            else {
                obtenerResumen(dominioCorporativo).then((resumen) => {
                    setResumen({ resumen, cargando: false })
                    set(storePRD, "resumen", resumen)
                    dispatchStore(storePRD)
                }).catch(()=>{
                    setResumen((st)=>({...st,cargando:false}))
                })
            }
        }
        return () => {
        }
    }, [dispatchStore, getDominioCorporativo, getStateApi581PRD, getStoreState, state.cargando, state.resumen])
    return [state.resumen, state.cargando, setResumen];
}



/*
BASADO EN
async function obtenerResumenDeInventario (){
            // si existe información del resumen en redux store se guarda de una vez en el state.
            // si no, se consulta, el resultado se guarda enel estado local y se guarda en objeto que va a ir al redux store
            if (has(storePRD, "resumen")) dispatch({ type: "resumen", payload: storePRD.resumen, extra: { campoTarget: campoActual } })
            else {
                await obtenerResumen(dominioCorporativo).then((resumen) => {
                    campoActual = campoActual || resumen.campos[0];
                    dispatch({ type: "resumen", payload: resumen, extra: { campoTarget: campoActual } })
                    set(storePRD,"resumen",resumen)
                    set(storePRD,"target.analisis.campo",campoActual)
                    dispatchStore(storePRD)
                })
            }
        }
        if(isEmpty(state.resumen))obtenerResumenDeInventario();
*/