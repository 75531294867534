import React from 'react';

import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal';

let ModalDeLosPlanesDeAccion = (props) => {
        return (
            <React.Fragment>
                <CustomModal size={"lg"} elModalEstaAbierto={props.modalDesplegado} toggleDelModal={props.toggleModal}
                    titulo={<span>Informacion adicional de <b>{props.componenteEncontrado.nombreDelComponente}</b></span>}
                    children={
                        <Row>
                            <Col md="12" className="p-0">
                                <ListGroup className="todo-list-wrapper" flush>
                                    <ListGroupItem>
                                        <div className="todo-indicator bg-focus" />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2"></div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading">
                                                        Campo
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {props.componenteEncontrado.nombreDelCampo}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <div className="todo-indicator bg-focus" />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2"></div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading">
                                                        Sistema
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {props.componenteEncontrado.nombreDelSistema}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                    {props.componenteEncontrado.nombreDelActivo !== undefined &&
                                        <ListGroupItem>
                                        <div className="todo-indicator bg-focus" />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2"></div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading">
                                                        Activo
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {props.componenteEncontrado.nombreDelActivo}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>}
                                    {(props.componenteEncontrado.dfPred !== undefined || props.componenteEncontrado.amenazaPred !== undefined) &&
                                        <ListGroupItem>
                                        <div className="todo-indicator bg-focus" />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2"></div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading">
                                                        Mecánismo Predominante
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {props.componenteEncontrado.dfPred?props.componenteEncontrado.dfPred:props.componenteEncontrado.amenazaPred}                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>}
                                    <ListGroupItem>                                        
                                        <div className="todo-indicator bg-warning" />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2"></div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading">
                                                        Nivel de riesgo
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {props.componenteEncontrado.nivelDeRiesgo}
                                                    </div>
                                                </div>
                                                <div className="widget-content-right ml-3">
                                                    <div className="badge badge-pill" style={{ backgroundColor: '#d21e1e', color: 'white' }}>POF:
                                                        {props.componenteEncontrado.probabilidad}
                                                    </div>
                                                </div>
                                                <div className="widget-content-right ml-3">
                                                    <div className="badge badge-pill" style={{ backgroundColor: '#e7961c', color: 'white' }}>COF:
                                                        {props.componenteEncontrado.consecuencia}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                    {props.componenteEncontrado.categoria &&
                                        <ListGroupItem>
                                            <div className="todo-indicator bg-focus" />
                                            <div className="widget-content p-0">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left mr-2"></div>
                                                    <div className="widget-content-left flex2">
                                                        <div className="widget-heading">
                                                            ¿Tiene inspección?
                                                    </div>
                                                        <div className="widget-subheading">
                                                            {props.componenteEncontrado.tieneInspeccion}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    }
                                    {props.componenteEncontrado.ubicacionDeCMLscanA !== undefined &&
                                        <ListGroupItem>
                                        <div className="todo-indicator bg-focus" />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2"></div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading">
                                                        Ubicación de CML scan A:
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {props.componenteEncontrado.ubicacionDeCMLscanA}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>   }                                 
                
                                    {
                                    props.componenteEncontrado.ubicacionDeCMLscanBC !== undefined &&
                                        <ListGroupItem>
                                            <div className="todo-indicator bg-focus" />
                                            <div className="widget-content p-0">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left mr-2"></div>
                                                    <div className="widget-content-left flex2">
                                                        <div className="widget-heading">
                                                            Ubicación de CML scan B,C:
                                                    </div>
                                                        <div className="widget-subheading">
                                                            {props.componenteEncontrado.ubicacionDeCMLscanBC}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>   
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                    }
                >
                </CustomModal>
            </React.Fragment>
        )
}

export default ModalDeLosPlanesDeAccion;