import React, {useEffect } from 'react';

import { Col, Row, BreadcrumbItem, Label, Breadcrumb, Button, Input, CardHeader, CardBody } from 'reactstrap';
import { MdEdit } from "react-icons/md";
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import RegistroFotografico from '../../components/RegistroFotografico';

const estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, justifyContent:'center', alignContent:'center'};
const estiloSegmentos = { display: "flex", width: "100%", justifyContent: 'center', cursor: 'pointer' }

function CaracterizacionDeGrietas(props) {
    useEffect(() => {
    });
    return (
        <React.Fragment>
            <br></br>
            <Breadcrumb>
                <BreadcrumbItem className='BreadcrumbItem-color'>CARACTERIZACIÓN DE GRIETAS</BreadcrumbItem>
            </Breadcrumb>
            <CardHeader
                className="card-header-grietas"
            >
                VALORACIÓN DE GRIETAS
                        </CardHeader>
            <CardBody
                className="card-body-grietas"
                style={{ paddingTop: 1, paddingBottom: 1 }}
            >
                <TablaDeAnomalias
                    anomalias={props.anomalias}
                    toggleModalEdicionAnomalias={() => { props.toggleModalEdicionAnomalias() }}
                    setTipoAnomaliaEdicion={(e) => { props.setTipoAnomaliaEdicion(e) }}
                    setAnomaliaEdicion={(e) => { props.setAnomaliaEdicion(e) }}
                    authRol={props.authRol}
                />
                <br/>
                <Label for="pkCampo" className='mb-0 mt-2 labelcustom' style={{ fontWeight: 'bold' }}>Observaciones y Recomendaciones Generales:</Label>
                <Input style={{ backgroundColor: 'white' }} type="textarea" className='w-100 textareacustom' name="pkCampo" id="pkCampo" placeholder="Pk. Campo" defaultValue={props.informacionGeneral[0].detalle.observacionesGrietas?props.informacionGeneral[0].detalle.observacionesGrietas:''} disabled />
            </CardBody>
            <DatosEspeficicacionesTecnicas
                detalle={props.informacionGeneral[0].detalle}
                idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                authRol={props.authRol}
            />
            <TablaDeEnsayos
                ensayos={props.ensayos}
                authRol={props.authRol}
                toggleModalEdicionAnomalias={() => { props.toggleModalEdicionAnomalias() }}
                setTipoAnomaliaEdicion={(e) => { props.setTipoAnomaliaEdicion(e) }}
                setAnomaliaEdicion={(e) => { props.setAnomaliaEdicion(e) }}
                setSubAnomaliaEdicion={(e) => { props.setSubAnomaliaEdicion(e) }}
            />
            <Label for="pkCampo" className='mb-0 mt-2 labelcustom' style={{ fontWeight: 'bold' }}>Observaciones y Recomendaciones:</Label>
            <Input style={{ backgroundColor: 'white' }} type="textarea" className='w-100 textareacustom' name="pkCampo" id="pkCampo" placeholder="Pk. Campo" defaultValue={props.informacionGeneral[0].detalle.observacionesEnsayoGrietas?props.informacionGeneral[0].detalle.observacionesEnsayoGrietas:''} disabled />
        </React.Fragment>
    );
}    

function TablaDeAnomalias(props) {
    return (
        <Row>
            <Col className='d-flex marcador-tabla-comparacion' style={{ overflowX: 'auto', margin: '0 15px' }} >
                <CustomTable
                    setAnomaliaEdicion={(e) => { props.setAnomaliaEdicion(e) }}
                    listaDeDatos={props.anomalias}
                    columnas={
                        [
                            {
                                Header: () => (<p style={{ ...estiloParaHeader, }}>Datos suministrados corrida UTCD</p>),
                                columns: [
                                    {
                                        Header: () => (<p style={estiloParaHeader}>      Editar      </p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                {props.authRol ? <Button
                                                    color="link"
                                                    onClick={() => {
                                                        props.toggleModalEdicionAnomalias()
                                                        props.setTipoAnomaliaEdicion('GRIETA')
                                                    }}
                                                >
                                                    <MdEdit
                                                    />
                                                </Button> : <Button
                                                    color="link"
                                                    disabled
                                                >
                                                    <MdEdit
                                                    />
                                                </Button>}
                                            </div>
                                        ),
                                        minWidth: 60
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>id</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.idDeAnomalia || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia Registro (m) </p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaRegistroGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                        minWidth: 100
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia  J. Anterior (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaJuntaAnteriorGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia  J. Posterior (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaJuntaPosteriorGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia  J. Longitudinal (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaJuntaLongitudinalGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Posición Horaria</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.posicionHorariaGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Longitud de la anomalía (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.longitudGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Profundidad (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.profundidadDGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Angulo de Inclinación (Θ)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.anguloDeInclinacionGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Espesor Tubo (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.espesorTuboGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Tipo de Grieta</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.tipoDeGrietaUTCD || ""}</p>
                                            </div>
                                        ),
                                    },
                                ]
                            },
                        ]
                    }
                />

                <CustomTable
                    //setAnomaliaEdicion={(e) => { props.setAnomaliaEdicion(e) }}
                    listaDeDatos={props.anomalias}
                    columnas={
                        [
                            {
                                Header: () => (<p style={{ ...estiloParaHeader, }}>Datos suministrados por medición en campo</p>),
                                columns: [
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia Registro (m) </p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaRegistroGrietaMed || ""}</p>
                                            </div>
                                        ),
                                        minWidth: 100
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia  J. Anterior (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaJuntaAnteriorGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia  J. Posterior (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaJuntaPosteriorGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Distancia  J. Longitudinal (m)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.distanciaJuntaLongitudinalGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Posición Horaria</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.posicionHorariaGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Longitud 2c (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.longitud2CGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Longitud 2a (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.longitud2AGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Profundidad (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.profundidadDGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Angulo de Inclinación (Θ)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.anguloDeInclinacionGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Espesor Tubo (mm)</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.espesorTuboGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: () => (<p style={estiloParaHeader}>Tipo de Grieta</p>),
                                        Cell: ({ row }) => (
                                            <div style={{ ...estiloSegmentos }}>
                                                <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                > {row._original.detalle.tipoDeGrietaMed || ""}</p>
                                            </div>
                                        ),
                                    },
                                ]
                            },
                        ]
                    }
                />
            </Col>
        </Row>
    );
}

function TablaDeEnsayos(props) {
    return (
        <Row>
            <Col className="d-flex marcador-tabla-comparacion card-ensayos">
                <CardHeader
                    className="card-header-grietas">
                    VALORACIÓN DE GRIETAS
                </CardHeader>
                <CardBody
                    className="card-body-grietas"
                >
                    <CustomTable
                        setAnomaliaEdicion={(e) => { props.setAnomaliaEdicion(e) }}
                        listaDeDatos={props.ensayos}
                        columnas={
                            [
                                {
                                    Header: () => (<p style={{ ...estiloParaHeader, }}>Datos Ensayo Ultrasonido</p>),
                                    columns: [
                                        {
                                            Header: () => (<p style={estiloParaHeader}>      Editar      </p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    {props.authRol ? <Button
                                                        color="link"
                                                        onClick={() => {
                                                            props.toggleModalEdicionAnomalias()
                                                            props.setTipoAnomaliaEdicion('ENSAYO_GRIETA')
                                                        }}
                                                    >
                                                        <MdEdit
                                                        />
                                                    </Button> : <Button
                                                        color="link"
                                                        disabled
                                                    >
                                                        <MdEdit
                                                        />
                                                    </Button>}
                                                </div>
                                            ),
                                            minWidth: 60,
                                            maxWidth: 150
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>id Anomalia</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.idAnomalia || ""}</p>
                                                </div>
                                            ),
                                            maxWidth: 150
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Index Ensayo</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.idDeEnsayo || ""}</p>
                                                </div>
                                            ),
                                            maxWidth: 150
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Distancia de Registro</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.distanciaRegistroEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Posición Horaria</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.posicionHorariaEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Longitud (mm)</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.longitudEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Profundidad Inicial (desde A)</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.profundidadInicialEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                            minWidth: 150
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Profundidad Final (desde A)</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.profundidadFinalEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                            minWidth: 150
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Naturaleza de la Indicación</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.naturalezaIndicacionEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                            minWidth: 150
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Estado</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.estadoEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: () => (<p style={estiloParaHeader}>Comentarios</p>),
                                            Cell: ({ row }) => (
                                                <div style={{ ...estiloSegmentos }}>
                                                    <p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
                                                    > {row._original.detalle.comentariosEnsayoGrieta || ""}</p>
                                                </div>
                                            ),
                                            minWidth: 300
                                        },
                                    ]
                                },
                            ]
                        }
                    />
                </CardBody>
            </Col>
        </Row>
    );
}

function DatosEspeficicacionesTecnicas(props){
    return (
        <div className="seccion-general-grietas-margin">
            <Row>
                <Col className='ml-xs-0 ' xs='12' md='12' lg='6'>
                    <CardHeader
                        className="card-header-grietas"
                    >
                        REGISTRO Y MARCACIÓN DE LAS INDICACIONES
                        </CardHeader>
                    <CardBody
                        className="card-body-grietas"
                        style={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                        <Row style={{ justifyContent: 'center' }}>
                            <div className={"seccion-general-grietas-imagenes"}>
                                <div className={"seccion-general-grietas-imagenes1"}>
                                    {(props.detalle.primeraImagenGrieta !== undefined) ? <RegistroFotografico
                                        key={'imgGrietas1'}
                                        path={props.detalle.primeraImagenGrieta.path}
                                        name={'Registro y marcación de las indicaciones'}
                                        idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                                        imagen={`imgGrietas1`}
                                        ensayo={true}
                                    /> : null}
                                </div>
                                <div className={"seccion-general-grietas-imagenes2"}>
                                    {(props.detalle.segundaImagenGrieta !== undefined) ? <RegistroFotografico
                                        key={'imgGrietas2'}
                                        path={props.detalle.segundaImagenGrieta.path}
                                        name={'Registro y marcación de las indicaciones'}
                                        idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                                        imagen={'imgGrietas2'}
                                        ensayo={true}
                                    /> : null}
                                </div>
                            </div>
                        </Row>
                    </CardBody>                    
                </Col>
                <Col className='ml-xs-0 ' xs='12' md='12' lg='6'>
                    <CardHeader
                        className="card-header-grietas"
                    //style={{ flexDirection: 'row', alignContent: "flex-start", justifyContent: "flex-start", paddingTop: 0, paddingBottom: 0 }}
                    >
                        ESPECIFICACIONES TECNICAS UT
                        </CardHeader>
                    <CardBody
                        className="card-body-grietas"
                        style={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                        <Row>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="equipoEnsayo" className='mb-0 mt-2'>Equipo:</Label>
                                <Input type="text" name="equipoEnsayo" id="equipoEnsayo" placeholder="Equipo" defaultValue={props.detalle.equipoEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="fabricanteEnsayo" className='mb-0 mt-2'>Fabricante:</Label>
                                <Input type="text" name="fabricanteEnsayo" id="fabricanteEnsayo" placeholder="Fabricante" defaultValue={props.detalle.fabricanteEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="cableEnsayo" className='mb-0 mt-2'>Cable:</Label>
                                <Input type="text" name="cableEnsayo" id="cableEnsayo" placeholder="Cable" defaultValue={props.detalle.cableEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="longitudEnsayo" className='mb-0 mt-2'>Longitud:</Label>
                                <Input type="text" name="longitudEnsayo" id="longitudEnsayo" placeholder="Longitud" defaultValue={props.detalle.longitudEnsayo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="palpadorEnsayo" className='mb-0 mt-2'>Palpador:</Label>
                                <Input type="text" name="palpadorEnsayo" id="palpadorEnsayo" placeholder="Palpador" defaultValue={props.detalle.palpadorEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="dimensionesEnsayo" className='mb-0 mt-2'>Dimensiones:</Label>
                                <Input type="text" name="dimensionesEnsayo" id="dimensionesEnsayo" placeholder="Dimensiones" defaultValue={props.detalle.dimensionesEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="anguloEnsayo" className='mb-0 mt-2'>Angulo:</Label>
                                <Input type="text" name="anguloEnsayo" id="anguloEnsayo" placeholder="Angulo" defaultValue={props.detalle.anguloEnsayo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="numProcedimientoEnsayo" className='mb-0 mt-2'>Numero de Procedimiento:</Label>
                                <Input type="text" name="numProcedimientoEnsayo" id="numProcedimientoEnsayo" placeholder="Numero de Procedimiento" defaultValue={props.detalle.numProcedimientoEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="codExamenEnsayo" className='mb-0 mt-2'>Código Examen:</Label>
                                <Input type="text" name="codExamenEnsayo" id="codExamenEnsayo" placeholder="Código Examen" defaultValue={props.detalle.codExamenEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="codAceptacionEnsayo" className='mb-0 mt-2'>Código Aceptacion:</Label>
                                <Input type="text" name="codAceptacionEnsayo" id="codAceptacionEnsayo" placeholder="Código Aceptacion" defaultValue={props.detalle.codAceptacionEnsayo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="bloqueCalibracionEnsayo" className='mb-0 mt-2'>Bloque de Calibración:</Label>
                                <Input type="text" name="bloqueCalibracionEnsayo" id="bloqueCalibracionEnsayo" placeholder="Bloque de Calibración" defaultValue={props.detalle.bloqueCalibracionEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="bloqueReferenciaEnsayo" className='mb-0 mt-2'>Bloque de Referencia:</Label>
                                <Input type="text" name="bloqueReferenciaEnsayo" id="bloqueReferenciaEnsayo" placeholder="Bloque de Referencia" defaultValue={props.detalle.bloqueReferenciaEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="tipoJuntaEnsayo" className='mb-0 mt-2'>Tipo de Junta:</Label>
                                <Input type="text" name="tipoJuntaEnsayo" id="tipoJuntaEnsayo" placeholder="Tipo de Junta" defaultValue={props.detalle.tipoJuntaEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='3'>
                                <Label for="materialEnsayo" className='mb-0 mt-2'>Material:</Label>
                                <Input type="text" name="materialEnsayo" id="materialEnsayo" placeholder="Material" defaultValue={props.detalle.materialEnsayo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="rangoBarridoEnsayo" className='mb-0 mt-2'>Rango de Barrido:</Label>
                                <Input type="text" name="rangoBarridoEnsayo" id="rangoBarridoEnsayo" placeholder="Rango de Barrido" defaultValue={props.detalle.rangoBarridoEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="volumenEscaneadoEnsayo" className='mb-0 mt-2'>Volumen Escaneado:</Label>
                                <Input type="text" name="volumenEscaneadoEnsayo" id="volumenEscaneadoEnsayo" placeholder="Volumen Escaneado" defaultValue={props.detalle.volumenEscaneadoEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='4'>
                                <Label for="aumentoNivelReferenciaEnsayo" className='mb-0 mt-2'>Aumento de Nivel Referencia:</Label>
                                <Input type="text" name="aumentoNivelReferenciaEnsayo" id="aumentoNivelReferenciaEnsayo" placeholder="Material" defaultValue={props.detalle.aumentoNivelReferenciaEnsayo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12' md='4' lg='6'>
                                <Label for="estadoSuperficieEnsayo" className='mb-0 mt-2'>Estado de la Superficie:</Label>
                                <Input type="text" name="estadoSuperficieEnsayo" id="estadoSuperficieEnsayo" placeholder="Rango de Barrido" defaultValue={props.detalle.estadoSuperficieEnsayo} disabled />
                            </Col>
                            <Col xs='12' md='4' lg='6'>
                                <Label for="acoplanteEnsayo" className='mb-0 mt-2'>Acoplante:</Label>
                                <Input type="text" name="acoplanteEnsayo" id="acoplanteEnsayo" placeholder="Acoplante" defaultValue={props.detalle.acoplanteEnsayo} disabled />
                            </Col>
                        </Row>
                    </CardBody>                    
                </Col>
            </Row>
        </div>
    );
}
export default CaracterizacionDeGrietas;