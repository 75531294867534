import { glosarioDeInputsAgregarUsuario } from './glosarioDeInputsAgregarUsuario';

export const obtenerValidacionDeVariable = (key) => {
  const variable = glosarioDeInputsAgregarUsuario[`${key}`];
  if (variable != null) {
    switch (variable["categoria"]) {
      case "valorUnico":
        return validacionValorUnico(variable);
      case "valorAbierto":
        return validacionValorAbierto();
      default:
        return {
          "status": "VARIABLE_INVALIDA",
          "error": "La categoría no se encuentrá en el glosario"
        };
    }
  } else {
    return {
      "status": "VARIABLE_INVALIDA",
      "error": "La variable no se encuentrá en el glosario"
    };
  }
}


const validacionValorAbierto = () => {
  return {
    "status": "VALIDACION_REQUERIDA",
    "validation": convertirVariableATexto
  };
}

const convertirVariableATexto = (valor) => {
  try {
    valor.toString();
    return true;
  } catch (e) {
    return false;
  }
}



const validacionValorUnico = (variable) => {
  switch (variable["tipo"]) {
    case "numerico":
      return {
        "status": "VALIDACION_REQUERIDA",
        "validation": validarValorNumerico
      };
    case "fecha":
      return {
        "status": "VALIDACION_REQUERIDA",
        "validation": validarValorFecha
      };
    case "correo_electronico":
      return {
        "status": "VALIDACION_REQUERDIDA",
        "validation": validarCorreo
      };
    default:
      return {
        "status": "TIPO_INVALIDO",
        "success": false,
        "error": "El tipo de variable no está registrado"
      };
  }
}

const validarValorFecha = (variable, valor) => {
  try {
    let flag = true;
    const fecha = Date.parse(valor);
    if (variable['condicionesDeLaVariable'] !== undefined) {
      for (var i = 0; i < variable["condicionesDeLaVariable"].length; i++) {
        const condicion = variable["condicionesDeLaVariable"][i];
        switch (condicion["operador"]) {
          case "<=":
            flag = fecha <= (Date.now() - new Date().getTimezoneOffset() * 60 * 1000);
            break;
          default:
            break;
        }
        if (!flag) {
          return flag;
        }
      }
    }
    return flag;
  } catch (e) {
    return false;
  }
}

const validarCorreo = (variable, valor) => {
  try {
    const correoRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return correoRegex.test(String(valor).toLowerCase());
  } catch (error) {
    return false;
  }
}

const validarValorNumerico = (variable, valor) => {
  try {
    let flag = true;
    const numero = parseFloat(valor);
    for (let i = 0; i < variable["condicionesDeLaVariable"].length; i++) {
      const condicion = variable["condicionesDeLaVariable"][i];
      switch (condicion["operador"]) {
        case ">":
          flag = numero > condicion['valorTarget'];
          break;
        case ">=":
          flag = numero >= condicion['valorTarget'];
          break;
        case "<":
          flag = numero < condicion['valorTarget'];
          break;
        case "<=":
          flag = numero <= condicion['valorTarget'];
          break;
        case "==":
          flag = numero === condicion['valorTarget'];
          break;
        default:
          break;
      }
      if (!flag) {
        return flag;
      }
    }
    return flag;
  } catch (e) {
    return false;
  }
}