import React from 'react'

import { Col, Row, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import { MdEdit, MdGridOn } from "react-icons/md";

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = {
  display: "flex",
  width: "100%",
  justifyContent: 'center',
  cursor: 'pointer',
  // textDecoration: 'underline', 
  overflowX: 'auto',
  // justifyContent: "none", 
  textDecoration: 'none',
  fontWeight: 'bold',
  color: 'rgba(32, 39, 140, 0.5)'
}

const rowStyle= { margin: '0 auto', color: "black", fontWeight: 300 }

let ComparacionDeDatos = (props) => {
  return (
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem className='BreadcrumbItem-color'>CARACTERIZACIÓN, VALORACIÓN Y EVALUACIÓN DE PERDIDA DE METAL</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col className='d-flex marcador-tabla-comparacion' style={{ overflowX: 'auto', margin: '0 15px' }} >
          <CustomTable
            setAnomaliaEdicion={(e) => { props.setAnomaliaEdicion(e) }}
            listaDeDatos={props.anomalias}
            columnas={
              [
                {
                  Header: () => (<p style={{ ...estiloParaHeader, }}>Anomalía</p>),
                  columns: [
                    {
                      Header: () => (<p style={estiloParaHeader}>Editar </p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          {props.authRol ? <Button
                            color="link"
                            onClick={() => {
                              props.toggleModalEdicionAnomalias()
                              props.setTipoAnomaliaEdicion('PERDIDA_DE_METAL')
                            }}
                          >
                            <MdEdit
                              size={20}
                            />
                          </Button> : <Button
                            color="link"
                            disabled
                          >
                            <MdEdit
                              size={20}
                            />
                          </Button>}
                        </div>
                      ),
                      minWidth: 70
                    },
                    {
                      Header: () => (<p style={estiloParaHeader}>Grid</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          {(row._original.detalleGrilla.export && props.authRol) ?
                            <Button color="link" onClick={() => { props.toggleModalGrid() }}>
                              <MdGridOn size={20} />
                            </Button> : <Button color="link" disabled >
                              <MdGridOn size={20} />
                            </Button>}
                        </div>
                      ),
                      minWidth: 70
                    },
                    {
                      Header: () => (<p style={estiloParaHeader}>Identificación </p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          > {row._original.anomalia.id || ""}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'evaluacionOComparacion',

                      Header: () => (<p style={{ ...estiloParaHeader }}>Evaluación(E)/ Comparación(C)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.anomalia.evaluacionOComparacion || ""}</p>
                        </div>
                      ),
                      minWidth: 120
                    },
                    {
                      accesor: 'programadaOAdiccional',

                      Header: () => (<p style={estiloParaHeader}>Programada o Adicional?</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.anomalia.programadaOAdiccional || ""}</p>
                        </div>
                      ),
                      minWidth: 120
                    }
                  ]
                },
              ]
            }
          />
          <CustomTable
            listaDeDatos={props.anomalias}
            columnas={
              [
                {
                  Header: () => (<p style={estiloParaHeader}>Datos suministrados corrida ILI</p>),
                  columns: [
                    {
                      accesor: 'idAnomaliaILI',

                      Header: () => (<p style={estiloParaHeader}>ID Anomalia ILI</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.idAnomaliaILI}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'evento',

                      Header: () => (<p style={estiloParaHeader}>Evento</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.evento || ""}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'distanciaAlRegistro',

                      Header: () => (<p style={estiloParaHeader}>Distancia al Registro (m)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.distanciaAlRegistro}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'posicionHorariaHorasILI',

                      Header: () => (<p style={estiloParaHeader}>Pos. Horaria (hh:mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.posicionDelDuctoILI}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'longitudAnomialia',

                      Header: () => (<p style={estiloParaHeader}>Longitud Anomalía (mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.longitudAnomialia}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'anchoAnomialia',

                      Header: () => (<p style={estiloParaHeader}>Ancho Anomalía (mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.anchoAnomialia}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'porcentajeReduccionEspesor',

                      Header: () => (<p style={estiloParaHeader}>% reducción de espesor</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.porcentajeReduccionEspesor ? parseFloat(row._original.datosSuministradosCorridaILI.porcentajeReduccionEspesor).toFixed(3) : ''}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'paredInterna',

                      Header: () => (<p style={estiloParaHeader}>Pared Interna</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosCorridaILI.paredInterna}</p>
                        </div>
                      ),
                    }
                  ]
                }
              ]
            }
          />
          <CustomTable
            listaDeDatos={props.anomalias}
            columnas={
              [
                {
                  Header: () => (<p style={estiloParaHeader}>Datos suministrados por medición en campo</p>),
                  columns: [
                    {

                      Header: () => (<p style={estiloParaHeader}>Distancia al Registro (m)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.distanciaAlRegistroMedicion}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'distanciaJuntaAnterior',

                      Header: () => (<p style={estiloParaHeader}>Distancia J. Anterior (m)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.distanciaJuntaAnterior}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'distanciaJuntaPosterior',

                      Header: () => (<p style={estiloParaHeader}>Distancia J. Posterior (m)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.distanciaJuntaPosterior}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'posicionHorariaHorasMedicion',

                      Header: () => (<p style={estiloParaHeader}>Pos. Horaria (hh:mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.posicionDelDuctoILIHoraMedicion}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'longitudAnomaliaMedicion',

                      Header: () => (<p style={estiloParaHeader}>Longitud Anomalía (mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.longitudAnomaliaMedicion}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'anchoAnomaliaMedicion',

                      Header: () => (<p style={estiloParaHeader}>Ancho Anomalía (mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.anchoAnomaliaMedicion}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'profundidad',

                      Header: () => (<p style={estiloParaHeader}>Profundidad (mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.profundidad}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'porcentajeReduccionEspesorMedicion',

                      Header: () => (<p style={estiloParaHeader}>% reducción de espesor</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.porcentajeReduccionEspesorMedicion ? parseFloat(row._original.datosSuministradosPorMedicion.porcentajeReduccionEspesorMedicion).toFixed(3) : ''}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'remanente',

                      Header: () => (<p style={estiloParaHeader}>Remanente (mm)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.remanente ? parseFloat(row._original.datosSuministradosPorMedicion.remanente).toFixed(3) : ''}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'interactuaConJunta',

                      Header: () => (<p style={estiloParaHeader}>Interactúa con  Junta S.</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.interactuaConJunta}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'tipoAnomalia',

                      Header: () => (<p style={estiloParaHeader}>Tipo anomalía</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.tipoAnomalia}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'clasificacionAnomalia',

                      Header: () => (<p style={estiloParaHeader}>Clasificación de Anomalía</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.clasificacionAnomalia}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'paredInternaMedicion',

                      Header: () => (<p style={estiloParaHeader}>Pared Interna</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.datosSuministradosPorMedicion.paredInternaMedicion}</p>
                        </div>
                      ),
                    }
                  ]
                }
              ]
            }
          />
          <CustomTable
            listaDeDatos={props.anomalias}
            columnas={
              [
                {
                  Header: () => (<p style={estiloParaHeader}>Eva. ASME B 31G M</p>),
                  columns: [
                    {
                      accesor: 'presionSegura',
                      Header: () => (<p style={estiloParaHeader}>Presión Segura (psi)</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.evaluacionASME.presionSegura ? parseFloat(row._original.evaluacionASME.presionSegura).toFixed(3) : ''}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'ERFs',
                      Header: () => (<p style={estiloParaHeader}>ERF</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.evaluacionASME.ERFs ? parseFloat(row._original.evaluacionASME.ERFs).toFixed(3) : ''}</p>
                        </div>
                      ),
                    },
                    {
                      accesor: 'evaluacionDeLaAnomalia',
                      Header: () => (<p style={estiloParaHeader}>Evaluación de la Anomalía</p>),
                      Cell: ({ row }) => (
                        <div style={{ ...estiloSegmentos }}>
                          <p style={rowStyle}
                          >{row._original.evaluacionASME.evaluacionDeLaAnomalia}</p>
                        </div>
                      ),
                    }
                  ]
                }
              ]
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  )

}

export default ComparacionDeDatos;