import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

let CustomRightModal = (props) => {
    return (
        <Modal isOpen={props.elModalEstaAbierto} toggle={props.toggleDelModal}
            wrapClassName="modal-right" style={props.style}>

            <ModalHeader toggle={props.toggleDelModal} style={{ height: 60 }} tag="h6">
                {props.titulo}
            </ModalHeader>

            <ModalBody className="overflow-scroll" style={{ paddingTop: 10, paddingBottom: 10, ...props.bodyStyle }}>
                {props.children}
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "flex-end", height: 60 }}>
                {props.botonesParaElFooter}
                {props.footerButton 
                    ? props.footerButton 
                    : <Button color="secondary" onClick={props.toggleDelModal}>Cerrar</Button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default CustomRightModal;