import React from 'react';
import { FaThinkPeaks } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { Area, Brush, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CardConEncabezado from '../plantillaBase/CardConEncabezado';


const datosDePruebaParaGraficaCompuesta = [
    // {tipoDeContenido: 'linea', eje: 'left', nombre: 'cml', tipo: 'number', dataKey: 'cml', borde: '#1E96FC' },
    // {tipoDeContenido: 'linea', eje: 'left', nombre: 'pm', tipo: 'number', dataKey: 'pm', borde: '#ED0101' },
    {tipoDeContenido: 'linea', eje: 'left', nombre: 'MAWP', tipo: 'number', dataKey: 'mawp', borde: '#66be33' },
    // {tipoDeContenido: 'linea', eje: 'left', nombre: 'desc', tipo: 'number', dataKey: 'desc', borde: '#9d0191' },
    // {tipoDeContenido: 'area', eje: 'left', nombre: 'vidaResidual', tipo: 'number', dataKey: 'vidaResidual', borde: '#fd3a69', relleno: '#fd3a69'},
    {tipoDeContenido: 'linea', eje: 'right', nombre: 'Espesor Nóminal', tipo: 'number', dataKey: 'tnom', borde: '#070d59', relleno: '#070d59' },
    {tipoDeContenido: 'area', eje: 'right', nombre: 'Espesor Min. Medido', tipo: 'number', dataKey: 'tmin', borde: '#fd8c04', relleno: '#bb2205'},
    // {tipoDeContenido: 'area', eje: 'left', nombre: 'tant', tipo: 'number', dataKey: 'tant', borde: '#fecd1a', relleno: '#fecd1a'},
    // {tipoDeContenido: 'area', eje: 'left', nombre: 't1', tipo: 'number', dataKey: 't1', borde: '#ec5858', relleno: '#ec5858'},
    {tipoDeContenido: 'area', eje: 'right', nombre: 'Espesor de Retiro', tipo: 'number', dataKey: 'tret', borde: '#f05454', relleno: '#9d0191'},
    {tipoDeContenido: 'area', eje: 'left', nombre: 'Presión de operación', tipo: 'number', dataKey: 'mop', borde: '#333', relleno: '#333'}
]


class GraficoCartesianoCompuestoIntegridad extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }


    render(){
        return (
            <React.Fragment>
                <CardConEncabezado 
                    titulo={this.props.titulo} 
                    // iconoPrincipal="pe-7s-graph3" 
                    icono={FaThinkPeaks}
                    bgColor={'grisCumbre'}>
                    <Row>
                        <Col>
                            <p>{this.props.nombreColumnaIzquierda}</p>
                        </Col>
                        <Col>
                            <p style={{ textAlign: 'right' }}>{this.props.nombreColumnaDerecha}</p>
                        </Col>
                    </Row>
                    <ResponsiveContainer width='100%' aspect={10.0 / 2.0}>
                        <ComposedChart data={this.obtenerTablaDeResultadosDeIntegridad(this.props.datosParaGrafica)} key='grafico cartesiano Integridad dinámica'>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="cml" />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend />
                            {/* {this.recorrerContenidosDeLaGrafica(this.props.datosParaGraficar)}                                    */}
                            {this.recorrerContenidosDeLaGrafica(datosDePruebaParaGraficaCompuesta)}                                   
                            {/* <Area yAxisId="left"  name="Espesor Min. Medido" type="number" dataKey="tmin" stroke="#ff5733" fill='#ff5733' />
                            <Line yAxisId="left"  name="Espesor Nóminal" type="number" dataKey="tnom" stroke="#900c3f"/>
                            <Area yAxisId="left"  name="Espesor de Retiro" type="number" dataKey="tret" stroke="#511845" fill='#511845' />
                            <Area yAxisId="right" name="MAWP" type="number" dataKey="mawp" stroke="#000" fill="#00000000" strokeDasharray="5 2"/> */}
                            <Brush />
                        </ComposedChart>
                    </ResponsiveContainer>
                </CardConEncabezado>
            </React.Fragment>
        )
    }

    convertirTablaEnData = (encabezados, tabla) => {
        let data = [];
        for (let i = 0; i < tabla.length; i++) {
            let fila = {};
            for (let j = 0; j < encabezados.length; j++) {
                if(typeof tabla[i][j] !== 'number'){
                    fila[encabezados[j]] = tabla[i][j];
                }else{
                    fila[encabezados[j]] = (tabla[i][j]).toFixed(2)
                }
            }
            data.push(fila)
        }
        return data;
    }

    obtenerTablaDeResultadosDeIntegridad = (resultadosDeIntegridad) => {
        let data = [];
        resultadosDeIntegridad.forEach(array => {
            data.push({
                cml: array.cml,
                tmin: array.tMin,
                tret: array.tRet,
                mawp: array.MAWP,
                tnom: array.tNom,
                mop: array.MOP
            })
        })
        return (data)
    }

    recorrerContenidosDeLaGrafica = (contenido) => {
        const todoContenido = [];
        contenido.forEach(objeto => {
            todoContenido.push(this.generarContenidoDeLaGrafica(
                objeto['tipoDeContenido'],
                objeto['eje'],
                objeto['nombre'],
                objeto['tipo'],
                objeto['dataKey'],
                objeto['borde'],
                objeto['relleno']
                ))
        })
        return todoContenido
    }

    generarContenidoDeLaGrafica = (tipoDeContenido, eje, nombre, tipo, dataKey, borde, relleno) => {
        const contenido = [];
        if(tipoDeContenido === 'linea'){
            contenido.push(
                <Line yAxisId={eje || 'rigth'} name={nombre || ''} curve={tipo || ''} dataKey={dataKey || ''} stroke={borde || '#333'}/>
            )
        }else if(tipoDeContenido === 'area'){
            contenido.push(
                <Area yAxisId={eje || 'rigth'} name={nombre || ''} curve={tipo || ''} dataKey={dataKey || ''} stroke={borde || '#333'} fill={relleno || '#333'} strokeDasharray="5 2"/>
            )
        }
        return contenido
    }
}

export default GraficoCartesianoCompuestoIntegridad;