import aplicarGetters from './getters';

const { firebaseFunctions } = require('../../../../../../firebase');

const exportarConsultas = firebaseFunctions.httpsCallable('exportarConsultas');

export async function obtenerPRD(modelo = ["Semicuantitativo", "Cualitativo"], dominioCorporativo, campo) {
    return exportarConsultas({
        option: "obtenerInventario", where: [
            { key: "parametrosDeEntrada.modeloDeConsecuencia", condicion: "in", value: modelo },
            { key: "parametrosDeEntrada.dominioCorporativo", condicion: "==", value: dominioCorporativo },
            { key: "parametrosDeEntrada.informacionDePRD.informacionGeneral.campo", condicion: "==", value: campo },
            { key: "estados.valorada", condicion: "==", value: true },
        ]
    }).then(async (result) => {
        return Promise.all(result.data.map((prdInventariado)=>{
            return exportarConsultas({
                option: "obtenerSubcolecciones",
                id: prdInventariado.id,
                conLogDeCalculos: false,
                conResultados: true,
                getSubCollectionsAsArray: true
            }).then((response) => {
                return ({...aplicarGetters(prdInventariado),...response.data})
            })

        }))
    })
}

/**
 * Para obtener data de PRD por id de documento en base de datos
 * @param {*} id id de documento en base de datos en la coleccion prd
 */
export async function obtenerSoloUnPRD(id, conLogDeCalculos = true) {
    return exportarConsultas({ option: "obtenerUnSoloPRD", conLogDeCalculos, id, getSubCollectionsAsArray: true }).then((response)=>aplicarGetters(response.data))
}

export async function actualizarModeloDeConsecuencia({ id, modeloDeConsecuencia }) {
    return exportarConsultas({ option: "cambiarModeloDeConsecuencia", id, modelo: modeloDeConsecuencia })
}

export async function actualizarPRD({ coleccionActiva, id, objetoDeActualizacion }) {
    return exportarConsultas({ option: "actualizarCampos", idNodo: id,coleccionActiva, objetoDeActualizacion })
}