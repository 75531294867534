import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataparaProceso = (activosProceso) => {
  let listaDeActivosAsmeb313 = [];
  if (activosProceso !== undefined) {
    activosProceso.forEach((nodo, i) => {
      let informacionActivo = nodo.variablesDeLaInspeccion
      // const cmlRemMin = informacionActivo?.cmlRemMin
      const maximaPresionTrabajoAdmisible = informacionActivo?.maximaPresionTrabajoAdmisible
      const porcentajeMaxPerdidaMaterial = informacionActivo?.porcentajeMaxPerdidaMaterial
      const velocidadMaximaDeCorrosion = informacionActivo?.velocidadMaximaDeCorrosion
      const vidaRemanente = informacionActivo?.vidaRemanente
      const tReq = informacionActivo?.tReq
      const tNom = informacionActivo?.tNom
      const tMin = informacionActivo?.tMin
      const corrosionAllowance = informacionActivo?.car
      try {
        listaDeActivosAsmeb313.push({
          'Item': {
            label: 'Item',
            valor: i + 1,
            maxWidth: 80,
          },
          'Nombre': {
            label: 'Tag',
            valor: `${nodo.informacionGeneral.nombre.valor}/${nodo.id}/API570/ASMEB31_3`,
            maxWidth: 130,
            spanStyle: {
              width: '100px'
            },
            condicion: 'link',
            ruta: '/app/analisis/detalle/lineas/activo'
          },
          'Diámetro' : {
            label: 'Diámetro del activo (in)',
            valor: formatearvalorsegunestado(nodo.informacionGeneral.diametro.valor),
            minWidth: 150
          },
          'Espesor requerido (in)': {
            label:  'Espesor requerido (in)',
            valor: parseFloat(tReq?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(tReq?.valor).toFixed(3),
            minWidth: 150
          },
          'Espesor nominal (in)': {
            label: 'Espesor nominal (in)',
            valor: parseFloat(tNom?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(tNom?.valor).toFixed(3),
            minWidth: 150
          },
          'Mínimo espesor medido (in)': {
            label: 'Mínimo espesor medido (in)',
            valor: parseFloat(tMin?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(tMin?.valor).toFixed(3),
            minWidth: 150
          },
          'CAR (in)': {
            label: 'CAR (in)',
            valor: parseFloat(corrosionAllowance?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(corrosionAllowance?.valor).toFixed(3),
            minWidth: 150
          },
          'Porcentaje máximo de pérdida de espesor (%)': {
            label: 'Porcentaje máximo de pérdida de espesor (%)',
            valor: parseFloat(porcentajeMaxPerdidaMaterial?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(porcentajeMaxPerdidaMaterial?.valor).toFixed(1),
            minWidth: 150
          },
          'Velocidad máxima de corrosión (mpy)': {
            label: 'Velocidad máxima de corrosión (mpy)',
            valor: parseFloat(velocidadMaximaDeCorrosion?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(velocidadMaximaDeCorrosion?.valor).toFixed(1),
            minWidth: 150
          },
          'Vida remanente (años)': {
            label: `Vida Remanente (años)`,
            valor: vidaRemanente?.valor ? parseFloat(vidaRemanente?.valor).toFixed(1) : 'Aún no calculado',
            minWidth: 150
          },
          'Maxima presión de trabajo admisible (psi)': {
            label: 'Maxima presión de trabajo admisible (psi)',
            valor: parseFloat(maximaPresionTrabajoAdmisible?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(maximaPresionTrabajoAdmisible?.valor).toFixed(1),
            minWidth: 150
          },
          'Fecha proxima inspeccion': {
            label: `Fecha próxima inspeccion (años)`,
            valor: `${new Date(nodo?.fechaDeInspeccion?.seconds * 1000 + (3600 * 24 * 365 * informacionActivo?.anhosEntreInspecciones?.valor * 1000)).toLocaleString().split(',')[0]} (${informacionActivo?.anhosEntreInspecciones?.valor}) `,
            minWidth: 150
          },
        });
      }
      catch (error) {
        console.log(error)
      }
    });
  }

  return listaDeActivosAsmeb313
};

export default armarDataparaProceso;