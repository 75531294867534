export const glosarioDeTitulos = [
    { 'key': 'titulo1', 'nombre': 'Panoramica de la Inspeccion', 'value': 'Panoramica de la Inspección',},
    { 'key': 'titulo2', 'nombre': 'Excavación ', 'value': 'Excavación ',},
    { 'key': 'titulo3', 'nombre': 'Georreferenciación', 'value': 'Georreferenciación',},
    { 'key': 'titulo4', 'nombre': 'Camisa tipo B', 'value': 'Camisa tipo B',},
    { 'key': 'titulo5', 'nombre': 'Camisa sobre anillos', 'value': 'Camisa sobre anillos',},
    { 'key': 'titulo6', 'nombre': 'Junta circunferencial a tope ', 'value': 'Junta circunferencial a tope ',},
    { 'key': 'titulo7', 'nombre': 'Anomalía por corrosión', 'value': 'Anomalía por corrosión',},
    { 'key': 'titulo8', 'nombre': 'Anomalía por ovalidad', 'value': 'Anomalía por ovalidad',},
    { 'key': 'titulo9', 'nombre': 'Cascota', 'value': 'Cascota',},
    { 'key': 'titulo10', 'nombre': 'Anomalía por abolladura ', 'value': 'Anomalía por abolladura ',},
    { 'key': 'titulo11', 'nombre': 'Anomalía por daño mecánico ', 'value': 'Anomalía por daño mecánico ',},
    { 'key': 'titulo12', 'nombre': 'Líquidos Penetrantes PT', 'value': 'Líquidos Penetrantes PT',},
    { 'key': 'titulo13', 'nombre': 'Partículas Magnéticas MT', 'value': 'Partículas Magnéticas MT',},
    { 'key': 'titulo14', 'nombre': 'Espesores UTW', 'value': 'Espesores UTW',},
    { 'key': 'titulo15', 'nombre': 'Ultrasonido convencional UT', 'value': 'Ultrasonido convencional UT',},
    { 'key': 'titulo16', 'nombre': 'Phased Array PAUT', 'value': 'Phased Array PAUT',},
    { 'key': 'titulo17', 'nombre': 'Inspección Visual VT', 'value': 'Inspección Visual VT',},
    { 'key': 'titulo18', 'nombre': 'Toma de Potencial', 'value': 'Toma de Potencial',},
    { 'key': 'titulo19', 'nombre': 'Resultados de inspección', 'value': 'Resultados de inspección',},
    { 'key': 'titulo20', 'nombre': 'Estado del recubrimiento ', 'value': 'Estado del recubrimiento ',},
    
    ]
