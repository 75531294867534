import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection'

function BotonCargueMasivo({ onClick, ...props }) {
    return (<BotonParaSection key="botonDeCargaEnApi581PRD"
        onClick={onClick}
        icono={<FaCloudUploadAlt />} title="Cargar datos" />
    )
}

export default BotonCargueMasivo
