import React from 'react';
import { Card, ListGroup, ListGroupItem, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const ModalDetalleDeTraza = ({ colorDeFondo, ordenDeTrabajo, estadoFormateado }) => {
  return (
    <Card className="profile-responsive p-0">
      <div className="dropdown-menu-header">
        <div
          className="dropdown-menu-header-inner"
          style={{
            backgroundColor: colorDeFondo + 'CC',
          }}
        >
          <Row className='mx-0 px-2 justify-content-between'>
            <div>
              <h4 className="font-weight-bold">{ ordenDeTrabajo.id }</h4>
              <h6 className="menu-header-subtitle">Órden de Trabajo</h6>
              <h6 
                className="menu-header-subtitle"
                style={{
                  textTransform: 'capitalize'
                }}
              > 
                Estado: <span className='font-weight-bold'>{ estadoFormateado }</span> 
              </h6>
            </div>
            <div className="menu-header-btn-pane">
              <Link to={'/app/ordenDeTrabajo/' + ordenDeTrabajo.id}>
                <Button
                  className="btn-lg btn-light pusheable-button"
                  style={{
                    color: colorDeFondo,
                    backgroundColor: 'white'
                  }}
                >
                  Ver detalle
                </Button>
              </Link>
            </div>
          </Row>
        </div>
      </div>
      <ListGroup flush>
        <ListGroupItem>
          <Row>
            <Col>
              <h6 className="text-dark font-weight-bold">Responsable:</h6>
              <p className='my-0'>{ordenDeTrabajo.responsable || '---'}</p>
              <small>{ordenDeTrabajo.correo || '---'}</small>
            </Col>
            <Col>
              <h6 className="text-dark font-weight-bold">Fecha de actualización:</h6>
              <p className='my-0'>{ordenDeTrabajo.fechaDeActualizacion || '---'}</p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h6 className="text-dark font-weight-bold">Descripción:</h6>
              <p className='my-0 text-justify'>{ordenDeTrabajo.descripcionODT || '---'}</p>
              <small>Lat: {ordenDeTrabajo.puntosGeoreferencia[0]?.lat || '---'}, Lng: {ordenDeTrabajo.puntosGeoreferencia[0]?.lng || '---'}</small>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
}

export default ModalDetalleDeTraza
