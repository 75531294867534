import React from 'react';
import { connect } from 'react-redux';

import { FaListOl } from 'react-icons/fa';

// Componentes:
import CustomSection from '../../../../../utilidades/componentes/plantillaBase/CustomSection';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import VisualizadorLrecLh5ALh1002 from '../lrecLh5ALh1002/VisualizadorLrecLh5ALh1002';
import VisualizadorLineaCrudoTk30001 from '../lineaCrudoTk30-001/VisualizadorLineaCrudoTk30001';


//Utilidad de base de datos:
import { firestoreDB } from '../../../../../firebase';
//toast:
import { ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//Scripts auxiliares:
import { obtenerNodoConSubcoleccion } from '../../../../../utilidades/scripts/paraConsultaDeNodos';

//Scripts locales: 
import armarDataGwt from '../scripts/armarDataGwt';
import armarDataUtScanA from '../scripts/armarDataUtScanA';
import armarDataUtScanC from '../scripts/armarDataUtScanC';
import armarDataPerfilDeEspesores from '../scripts/armarDataPerfilDeEspesores';
import { get } from 'lodash';

//Utilidades:

//cloud Function
// const calcularActivo = firebaseFunctions.httpsCallable('calcularActivo');

//Estilos base:
const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaParrafosDeInfo = { ...estiloDeFuentesBase, textAlign: "right", marginBottom: 2 };
const estiloFoco = { marginLeft: 5, marginRight: 5, textDecoration: "underline" };

class VisualizadorDeSegmentosIntegridad extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cargaCompleta: false,
            cargando: false,
            nodo: undefined,
            activeTab: '1',
            botonActivo:'LINEA',
            tipoDeComponente: undefined,
            tablaScanA: undefined,
            nodoCml: []
        }
    }
    render(){
        if (!this.state.error) {
            const nodo = this.state.nodo;
            if (nodo !== undefined && this.state.cargaCompleta) {
                return (
                    <CustomSection
                        titulo={nodo.informacionGeneral.nombre.valor}
                        subtitulo={"Componente tipo: " + get(nodo,"informacionGeneral.tipoDeComponente.valor","No se pudo obtener este dato")}
                        URLIconoPrincipal="/assets/img/iconos/iconoSegmento.jpg"                        
                        >

                        {this.obtenerInfoDeLosPadres()}
                      
                {/* <CardConEncabezado
                titulo={`Segmento ${this.state.nodo.informacionGeneral.nombre.valor}`}
                icono={FaListOl}
                clase='sombreadoDeHeader'
                > */}

                    {this.renderizarContenidoSegunTipo(this.state.tipoDeComponente)}
                {/* </CardConEncabezado> */}
                        <ToastContainer />
                        <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                    </CustomSection>
                );
            }
            else { return (<Preloader elPreloaderEstaAbierto={true} />); }
        }
        else { return (<Preloader elPreloaderEstaAbierto={true} />); }
    }
    
    componentDidMount = () => {
        this.actualizarNodo();
    }

    
    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })   
        }
    }
    cambiarBoton = (boton) => {
        this.setState({
            botonActivo: boton
        })
    }

    renderizarContenidoSegunTipo = (tipo) => {
        if(this.state.nodoCml?.length > 0){
            switch(tipo){
                case 'Producción':
                    return(
                        <VisualizadorLrecLh5ALh1002
                            key={'Visualizador Lrhc'}
                            dataUtScanA={armarDataUtScanA(this.state.nodoCml)}
                            dataUtScanC={armarDataUtScanC(this.state.nodoCml)}
                            dataGwt={armarDataGwt(this.state.nodoCml)}
                            dataPerfilEspesores={armarDataPerfilDeEspesores(this.state.nodoCml)}
                            nodosCml={this.state.nodoCml}
                            bgColor={'grisCumbre'}
                            />
                    )
                case 'Proceso':
                    return(
                        <VisualizadorLrecLh5ALh1002
                            key={'Visualizador proceso'}
                            dataUtScanA={armarDataUtScanA(this.state.nodoCml)}
                            dataUtScanC={armarDataUtScanC(this.state.nodoCml)}
                            dataGwt={armarDataGwt(this.state.nodoCml)}
                            dataPerfilEspesores={armarDataPerfilDeEspesores(this.state.nodoCml)}
                            nodosCml={this.state.nodoCml}
                            bgColor={'grisCumbre'}
                            />
                        // <VisualizadorLineaCrudoTk30001 
                        //     key={'Visualizador Linea crudo'}
                        //     dataPerfilEspesores={armarDataPerfilDeEspesores(this.state.nodoCml)}
                        //     dataUtScanA={armarDataUtScanA(this.state.nodoCml)}
                        //     dataUtScanC={armarDataUtScanC(this.state.nodoCml)}
                        //     dataGwt={armarDataGwt(this.state.nodoCml)}
                        //     nodosCml={this.state.nodoCml}
                        //     />
                    )
                default:
                    return(<React.Fragment></React.Fragment>)
            }
        }
    }


    //Métodos operativos:
    actualizarNodo = async() => {
        const id = this.props.match.params.id;
        const coleccionActiva = "segmentos";
        this.setState({
            cargando: true,
        });

        try {
            const algo = await firestoreDB.collection(coleccionActiva).doc(id).get();
            console.log('algo.data()', algo.exists)
        }catch(error) {console.log(error)}
        

        firestoreDB.doc(`bdPrueba/9AKrQUDKh3UGtqDD2Rpk/${coleccionActiva}/${id}`)
        .get()
        .then(async snapshot => {
            const moduloTarget = this.props.match.params.modulo;
            let nodoTarget = { id: snapshot.id, ...snapshot.data(), referencia: snapshot.ref };
            
            obtenerNodoConSubcoleccion(nodoTarget, moduloTarget)
            .then(nodo => {
                        this.setState({
                            id: snapshot.id,
                            coleccionActiva: coleccionActiva,
                            nodo: nodo,
                            cargaCompleta: true,
                            cargando: false,
                            tipoDeComponente: nodo.informacionGeneral.tipoDeActividad
                        }, () => {
                            this.obtenerDataCml()
                        });
                    })
                    .catch(error => { console.log(error);this.setState({cargando: false}) });
            })
            .catch(error => { console.log(error);this.setState({cargando: false}) });
    }


    obtenerDataCml = () => {
        this.setState({cargando: true})
        const norma = this.props.match.params.norma
        try{
            let dataDelCml = [];
            if(norma === 'ASMEB31_3'){
                firestoreDB.collection(`bdPrueba/9AKrQUDKh3UGtqDD2Rpk/cml`)
                .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
                .where('informacionDeLosPadres.segmentoAsociado.nombre', '==', this.state.nodo.informacionGeneral.nombre.valor)
                .where('informacionDeLosPadres.segmentoAsociado.referencia', '==', this.state.nodo.referencia)
                .where('informacionGeneral.tipoDeActividad', '==', 'Proceso')
                .get()
                .then(snapShots => {
                    snapShots.forEach(snapshot => {
                        dataDelCml.push({...snapshot.data()})
                    })
                })
                .then( () => {
                    this.setState({nodoCml: dataDelCml, cargando:false})
                    this.renderizarContenidoSegunTipo(this.state.tipoDeComponente)
                })
                .catch(error => {console.log(error); this.setState({cargando:false})})               
            }else{
                firestoreDB.collection(`bdPrueba/9AKrQUDKh3UGtqDD2Rpk/cml`)
                .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
                .where('informacionDeLosPadres.segmentoAsociado.nombre', '==', this.state.nodo.informacionGeneral.nombre.valor)
                .where('informacionDeLosPadres.segmentoAsociado.referencia', '==', this.state.nodo.referencia)
                .where('informacionGeneral.tipoDeActividad', '==', 'Producción')
                .get()
                .then(snapShots => {
                    snapShots.forEach(snapshot => {
                        dataDelCml.push({...snapshot.data()})
                    })
                })
                .then( () => {
                    this.setState({nodoCml: dataDelCml, cargando:false})
                    this.renderizarContenidoSegunTipo(this.state.tipoDeComponente)
                })
                .catch(error => {console.log(error); this.setState({cargando:false})})  
            }
                
        }
        catch(error){console.log(error); this.setState({cargando: false})}   
    }


    //Métodos para renderizado:
    obtenerInfoDeLosPadres = () => {
        if (this.state.cargaCompleta) {
            let listaDeContenido = [];
            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo }} key={"infoDePadres." + this.state.nodo.id + ".nombre"}
                    dangerouslySetInnerHTML={{
                        __html:
                            "Estoy viendo los detalles del " + this.obtenerNombreDeColeccionEnSingular(this.state.coleccionActiva)
                            + " <b>" + this.state.nodo.informacionGeneral.nombre.valor + "</b>"
                    }} />
            );

            listaDeContenido.push(
                <p style={{ ...estiloParaParrafosDeInfo, marginBottom: 20 }}
                    key={"infoDePadres." + this.state.nodo.id + ".paraSegmento"}>
                    Pertenece al activo
                    <b style={{ ...estiloFoco }}>
                        {this.state.nodo.informacionDeLosPadres.activoAsociado.nombre}
                    </b>
                    del sistema
                    <b style={{ ...estiloFoco }}>
                        {this.state.nodo.informacionDeLosPadres.sistemaAsociado.nombre}
                    </b>
                    del campo
                    <b style={{ ...estiloFoco }}>
                        {this.state.nodo.informacionDeLosPadres.campoAsociado.nombre}
                    </b>
                </p>
            );


            return listaDeContenido;
        }
        else {
            return "Cargando...";
        }
    }

    obtenerNombreDeColeccionEnSingular = (coleccion) => {
        switch (coleccion) {
            case "campos":
                return "campo";
            case "sistemas":
                return "sistema";
            case "activos":
                return "activo";
            case "segmentos":
                return "segmento";
            default:
                return coleccion;
        }
    }
    //Métodos para redirección:
    navegarHaciaPath = (path) => {
        this.props.history.push(path);
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }

    navegarHaciaActivoPadre = () => {
        const moduloTarget = this.props.match.params.modulo;
        const normaTerger = this.props.match.params.norma;
        const pathTarget = "/app/analisis/detalle/lineas/activo" + 
        this.state.nodo.informacionDeLosPadres.activoAsociado.referencia.id + 
        "/" + 
        moduloTarget + 
        "/" + normaTerger;
        this.navegarHaciaPath(pathTarget);
    }
}

const mapStateToProps = state => ({
    dataDe570EnRedux: state.reducerParaData["/app/analisis/integridad/api570"],
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorDeSegmentosIntegridad)