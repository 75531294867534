
import React from 'react'
import { Grid } from "@material-ui/core";
import LinkConHistory from '../../../componentes/LinkConHistory';
import NumberFormat from 'react-number-format';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import  values  from 'lodash/values';
import { ESTADO_PRD, TDP, INMEDIATA, MODELO_CONSECUENCIA , SIN_VALORAR} from '../../../scripts/constantes';
import { REQUIERE } from './constantes';
import { parse , isAfter, addMonths} from 'date-fns'
import customBodyRender from '../../../componentes/CustomMUIDataTable/cellTablaDefault';



const columns = ({ moduloTarget }) =>{
    const hoyEnTresMeses = addMonths(new Date(), 3)
    return [
    {
        name: "parametrosDeEntrada.modeloDeConsecuencia",
        label: "Modelo de Consecuencia",
        options: {
            display: false,
            viewColumns: false,
            filterOptions: {
                names: values(MODELO_CONSECUENCIA),
            }
        }
    },
    {
        name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.TAG",
        label: "TAG",
        options: {
            filterType: 'textField',
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Grid container >
                        <LinkConHistory to={`/app/detalleInventario/prd/${tableMeta.rowData[11]}/${moduloTarget}`} style={{ width: "100%", textAlign: "center" }}>
                            {value}
                        </LinkConHistory>
                    </Grid>
                );
            },
        }
    },
    {
        name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.codActivo",
        label: "Código de activo",
        options: {
            filterType: 'textField',
        }
    },
    
    {
        name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.id",
        label: "Id (Placa)",
        options: {
            filterType: 'textField',
        }
    },
    {
        name: `parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.fechaUltimaInspeccion`,
        label: "Fecha de última inspección",
        options:{
            filter: false,
            customBodyRender
        }
    },
    {
        name: `parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.fechaUlMant`,
        label: "Fecha de último Mantenimiento",
        options:{
            filter: false,
            customBodyRender
        },
    },
    {
        name: `resultados.${moduloTarget}.riesgo.total.financiera`,
        label: "Riesgo Semicuantiativo",
        options: {
            filterType: 'textField',
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Grid container style={{ justifyContent: "flex-end" }} >
                        {isNumber(value) ? <NumberFormat
                            displayType={"text"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={value}
                            prefix={"$"}
                            thousandSeparator={true}
                        /> : customBodyRender(value)}
                    </Grid>
                );
            },
        }
    },
    {
        name: `resultados.${moduloTarget}.riesgo.total.nivelDeRiesgo`,
        label: "Riesgo Cualitativo",
        options:{
            customBodyRender
        }
    },
    {
        name: `resultados.${moduloTarget}.planesDeAccion.inspeccion.fechaProx`,
        label: "Fecha proxima de inspección",
        options:{
            filter: false,
            customBodyRender
        }
    },
    {
        name: `resultados.${moduloTarget}.planesDeAccion.mantenimiento.fechaProx`,
        label: "Fecha proxima de mantenimiento",
        options:{
            filter: false,
            customBodyRender
        }
    },
    {
        name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.estado",
        label: "Estado",
        options: {
          filterType: 'multiselect',
          filterOptions: { names: values(ESTADO_PRD) }
        }
      },
    {
        name: "id",
        label: " ",
        options: {
            display: false,
            viewColumns: false,
            filterType: 'checkbox',
            filterOptions: {
                names: [REQUIERE.INSPECCION, REQUIERE.MANTENIMIENTO, SIN_VALORAR],
                logic: (id, filters, row) => {
                    let filtrable = false;
                    if (filters.includes(REQUIERE.INSPECCION)) {
                        const tipoDePrueba = row[12]
                        const proxInsp = row[8]
                        if (!(isEmpty(tipoDePrueba) || isEmpty(proxInsp) || tipoDePrueba === TDP.SIN_PRUEBA || proxInsp === INMEDIATA)){
                            const proxInspTime = parse(proxInsp, 'MM/dd/yyyy', new Date())
                            filtrable = filtrable || isAfter(proxInspTime, hoyEnTresMeses)
                        }
                    }
                    if (filters.includes(REQUIERE.MANTENIMIENTO)) {
                        const tipoDeMantenimiento = row[13]
                        const proxMant = row[9]
                        if (!(isEmpty(tipoDeMantenimiento) || isEmpty(proxMant) /* || tipoDeMantenimiento === TDM.SIN_MANT  */|| proxMant === INMEDIATA)){
                            const proxMantTime = parse(proxMant, 'MM/dd/yyyy', new Date())
                            filtrable = filtrable || isAfter(proxMantTime, hoyEnTresMeses)
                        }
                    }
                    if (filters.includes(SIN_VALORAR)) {
                        const valorada = row[14]
                        filtrable = filtrable || !!valorada
                    }
                    return filtrable
                }
            }
        }
    },
    {
        name: "parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.tipoDePrueba",
        label: "Tipo de prueba",
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        }
      },
      {
        name: "parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.mantenimiento",
        label: "Mantenimiento",
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        }
      },
      {
        name: "estados.valorada",
        label: "Valoración",
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        }
      },
];
};

export default columns;


/*


TAG
# De Activo / Código
# DE INVENTARIO	Ultima inspección
Ultimo mantenimiento
Valoración RBI
Fecha próxima inspección
Fecha próximo mantenimiento

*/