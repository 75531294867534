import React from 'react';

//Componentes auxiliares:
import Mapa from '../auxiliares/Mapa';
import PuntoActivoIntegridad from './PuntoActivoIntegridad';

class MapaActivosIntegridad extends React.Component {

    constructor() {
        super();

        this.state = {
        };
    }

    render() {
        if (this.props.coordenadas !== undefined ) {
            if (this.props.coordenadas.length > 0) {
                return (
                    <div >
                        <Mapa
                            defaultZoom={1}
                            key={this.props.keyDeRenderizado}
                            containerStyles={{height: 400}}
                            >
                            {this.obtenerPuntos()}
                        </Mapa>
                    </div>
                );
            }
            else {
                return (
                    <p style={{ textAlign: "center" }}>
                        No hay información para mostrar
                    </p>
                );
            }
        } else {
            return (
                <p style={{ textAlign: "center" }}>
                    No hay información para mostrar
                </p>
            );
        }
    }

    // componentDidMount() {
    //     this.obtenerPuntos();
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.keyDeRenderizado !== this.props.keyDeRenderizado) {
            this.obtenerPuntos();
        }
    }

    //Métodos para renderizado:
    obtenerPuntos = () => {
        try{
            return this.props.coordenadas.map((coordenada, i) => {
            if ( coordenada[0].lng && coordenada[0].lat ) {
                return (
                    <PuntoActivoIntegridad
                        key={'punto de activo' + i + coordenada[0].lng }
                        activoIntegridad={ coordenada }
                    />
                )
            } 
        })
        }   
        catch(error){ console.log(error)}
        
    }

}

export default MapaActivosIntegridad;