import React from 'react';
import { FaCalculator } from 'react-icons/fa';
import PrefectScrollbar from 'react-perfect-scrollbar';

//Componentes auxiliares:
import Timeline from '../../../../../../utilidades/componentes/plantillaBase/Timeline.jsx';
import CardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/CardConEncabezado.jsx';

//Estilos base:
const estiloDePerfectScrollbarDeCalculos = { maxHeight: 350 };

const LogDeCalculos = (props) => {
    let logDeCalculos = [];

    try {
        logDeCalculos = props.nodo.logsDeCalculos||props.nodo.logDeCalculos[0].data;
        logDeCalculos = logDeCalculos[props.scopeTarget];
    }
    catch (error) { };

    if (logDeCalculos) {
        return (
            <CardConEncabezado titulo={"Cálculo de " + props.scopeTarget} icono={FaCalculator}>
                <PrefectScrollbar style={{ ...estiloDePerfectScrollbarDeCalculos }}>
                    <Timeline mostrarFechas={false} ordenarAscendente={true}
                        itemsDelTimeline={obtenerLogDeCalculosFormateado(logDeCalculos)} />
                </PrefectScrollbar>
            </CardConEncabezado>
        );
    }
    else{
        return(
            <React.Fragment/>
        );
    }
}

const obtenerLogDeCalculosFormateado = (logOriginal) => {
    let logFormateado = [];

    logFormateado.push({
        status: "info",
        contenido: <b>{"Inicia el proceso de cálculos"}</b>,
    });

    try {
        logFormateado.push(...logOriginal.map((registro, i) => {
            let statusTarget = "warning";
            if (registro.status === "done") { statusTarget = "success" };
            if (registro.status === "error") { statusTarget = "danger" };

            return { status: statusTarget, contenido: registro.detalle };
        }));
    }
    catch (error) {
        logFormateado.push({
            status: "warning",
            contenido: "No podemos mostrar el log de cálculos por el momento..."
        });
    }

    // logFormateado.push({
    //     status: "info",
    //     contenido: <b>{"Terminó el proceso de cálculos"}</b>,
    // });

    return logFormateado;
}

export default LogDeCalculos;