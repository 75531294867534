import React from 'react';
import { connect } from 'react-redux';
import Hamburger from 'react-hamburgers';

import { setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall } from '../../../storage/reducerParaTema.js';

//Componenes auxiliares:
import MobileMenu from './ToggleParaMenuResponsive.jsx';

class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false
        };

    }

    toggleEnableClosedSidebar = () => {
        let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    }

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        let { enableClosedSidebar } = this.props;

        return (
            <React.Fragment>
                <div className="app-header__logo">
                    <div className="logo-src" />
                    <div className="header__pane ml-auto">
                        <div onClick={this.toggleEnableClosedSidebar}>
                            <Hamburger active={enableClosedSidebar} type="elastic" onClick={() => this.setState({ active: !this.state.active })} />
                        </div>
                    </div>
                </div>
                <MobileMenu />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    enableClosedSidebar: state.reducerParaTema.enableClosedSidebar,
    enableMobileMenu: state.reducerParaTema.enableMobileMenu,
    enableMobileMenuSmall: state.reducerParaTema.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({
    setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logo);