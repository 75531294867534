import React from 'react';
import { Progress } from 'react-sweet-progress';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardTitle, CardBody, } from 'reactstrap';

//Scripts auxiliares:
import Timeline from '../plantillaBase/Timeline.jsx';

class TimelineDeProceso extends React.Component {

    render() {
        let porcentajeDeProgreso = (this.props.cantidadDeNodosPorCargar) ?
            Math.round(100 * this.props.cantidadDeNodosProcesados / this.props.cantidadDeNodosPorCargar) : 0;

        return (
            <Card className="widget-chart border mb-3">
                <CardTitle style={{ textAlign: "left" }}>Log de proceso:</CardTitle>

                <CardBody>
                    <PerfectScrollbar style={{ maxHeight: 350 }}>
                        <Timeline cantidadMaximaDeItems={150} itemsDelTimeline={this.props.logDeProceso} />
                    </PerfectScrollbar>

                    <div hidden={(porcentajeDeProgreso === 0) ? true : false}>
                        <Progress className="" percent={porcentajeDeProgreso} style={{ paddingTop: 25 }} />
                        <div style={{ opacity: 0.6, fontSize: 10 }}>
                            {this.props.cantidadDeNodosProcesados + " / " + this.props.cantidadDeNodosPorCargar}
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default TimelineDeProceso;