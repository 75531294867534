import Swal from 'sweetalert2'
import { confirmacionDeActualizacionDeNodos } from '../../../../../utilidades/almacen/alertasParaSwal'

function SwalDeObtenerDatos() {
    return  Swal.fire(confirmacionDeActualizacionDeNodos)
}

export default SwalDeObtenerDatos

export function SwalAlert(titulo,subtitulo,icon){
    return Swal.fire({
        title: titulo,
        html: '<span style="font-size:14px;">' + subtitulo + '</span>',
        icon: icon,
        scrollbarPadding:false
    });
}