import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';

//Componentes auxiliares:
import Header from './componentes/Header.jsx';
import Sidebar from './componentes/Sidebar.jsx';
import SelectorDeRutas from './componentes/SelectorDeRutas.jsx';

let CapaDeAplicacion = (props) => {
    let { colorScheme, enableFixedHeader, enableFixedSidebar, enableFixedFooter, enableClosedSidebar, closedSmallerSidebar, enableMobileMenu, enablePageTabsAlt } = props;

    return (
        <ResizeDetector handleWidth render={({ width }) => (
            <div className={cx("app-container app-theme-" + colorScheme, { 'fixed-header': enableFixedHeader }, { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                { 'fixed-footer': enableFixedFooter }, { 'closed-sidebar': enableClosedSidebar || width < 1250 }, { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                { 'sidebar-mobile-open': enableMobileMenu }, { 'body-tabs-shadow-btn': enablePageTabsAlt })}>

                <Header />
                <div className="app-main">
                    <Sidebar />
                    <div className="app-main__outer" style={{ paddingBottom: 0 }}>
                        <div className="app-main__inner">
                            <SelectorDeRutas />
                        </div>
                        {/* <AppFooter /> */}
                    </div>
                </div>
            </div>
        )} />
    );
}

const mapStateToProp = state => ({
    colorScheme: state.reducerParaTema.colorScheme,
    enableFixedHeader: state.reducerParaTema.enableFixedHeader,
    enableMobileMenu: state.reducerParaTema.enableMobileMenu,
    enableFixedFooter: state.reducerParaTema.enableFixedFooter,
    enableFixedSidebar: state.reducerParaTema.enableFixedSidebar,
    enableClosedSidebar: state.reducerParaTema.enableClosedSidebar,
    enablePageTabsAlt: state.reducerParaTema.enablePageTabsAlt,

    permitirEdicionDeInterfaz: state.reducerGeneral.permitirEdicionDeInterfaz,
});


export default withRouter(connect(mapStateToProp)(CapaDeAplicacion));