import { firebaseFunctions } from "../../../../../../firebase"
import { errorToast } from "../../../../../../utilidades/Toastify/Toastify"

const exportarConsultas = firebaseFunctions.httpsCallable('exportarConsultas');

export default async ({ dominioCorporativo, campo, where = [] }) => {
    return exportarConsultas({
        option: "obtenerInventario",
        where: [
            ...where,
            {
                key: "parametrosDeEntrada.dominioCorporativo",
                condicion: "==",
                value: dominioCorporativo
            },
            {
                key: "parametrosDeEntrada.informacionDePRD.informacionGeneral.campo",
                condicion: "==",
                value: campo
            }
        ]
    }).then((res) => res.data).catch(()=>{
        errorToast("Error obteniendo Inventario")
        throw new Error("Error obteniendo inventario")
    })
}
