import React from 'react'
import { Row, Col } from 'reactstrap';
import { AiOutlinePicture } from 'react-icons/ai';

//componentes:
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';

//Componentes auxiliares:
import RegistroFotografico from './RegistroFotografico';

const GaleriaEnsayosNoDestructivos = (props) => {
    let listadoEnsayos = Object.keys(props.galeriaDeEnsayosNoDestructivos)
    if (props.galeriaDeEnsayosNoDestructivos) {
        return (
            <Row>
            {listadoEnsayos.map(ensayo=>{
                if (props.galeriaDeEnsayosNoDestructivos[ensayo] !== undefined)
                {
                    return (
                        <Col key={ensayo} xs='12' md='12' lg='6'>
                            <CardConEncabezado key={ensayo} titulo={tituloCard(ensayo)} icono={AiOutlinePicture} bgColor={'grisCumbre'}>
                                <Row>
                                    {props.galeriaDeEnsayosNoDestructivos[ensayo][`${ensayo}1`] ? <Col xs='12' md='6' lg='6'>
                                        <RegistroFotografico
                                            key={`${ensayo}1`}
                                            path={props.galeriaDeEnsayosNoDestructivos ? props.galeriaDeEnsayosNoDestructivos[ensayo][`${ensayo}1`]['path'] : null}
                                            ensayo={true}
                                            idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                                            tipo={ensayo}
                                            imagen={`${ensayo}1`}
                                            name={tituloCard(ensayo)} />
                                    </Col> : null}
                                    {props.galeriaDeEnsayosNoDestructivos[ensayo][`${ensayo}2`] ? <Col xs='12' md='6' lg='6'>
                                        <RegistroFotografico
                                            key={`${ensayo}2`}
                                            path={props.galeriaDeEnsayosNoDestructivos ? props.galeriaDeEnsayosNoDestructivos[ensayo][`${ensayo}2`]['path']: null}
                                            ensayo={true}
                                            idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                                            tipo={ensayo}
                                            imagen={`${ensayo}2`}
                                            name={tituloCard(ensayo)} />
                                    </Col> : null}
                                </Row>
                            </CardConEncabezado>
                        </Col>
                    );
                }
                else {
                    return (
                        <React.Fragment>

                        </React.Fragment>
                    );
                }
            })}
            </Row>

        );
    }

    return (<React.Fragment />);
}

const tituloCard = (ensayo) =>{
    switch (ensayo) {
        case 'ondasGuiadas':
            return 'Ondas Guiadas'
        case 'phaseArray':
            return 'Phase Array'
        case 'tintasPenetrantes':
            return 'Tintas Penetrantes'
        case 'particulasMagneticas':
            return 'Partículas Magnéticas'
        case 'radiografia':
            return 'Radiografía'
        case 'visual':
            return 'Inspección Visual'
        case 'liquidosPenetrantes':
            return 'Líquidos Penetrantes'
        case 'ultrasonidoConvencional':
            return 'Ultrasonido Convencional'
        case 'ultrasonidoUTW':
            return 'Ultrasonido UTW'
        case 'ultrasonidoSCANC':
            return 'Ultrasonido SCAN-C'
        case 'inspeccionTOFD':
            return 'Inspección TOFD'
        case 'potencial':
            return 'Potencial'
        default:
            return 'Sin titulo'
    }
}
export default GaleriaEnsayosNoDestructivos;