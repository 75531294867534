import React from 'react';
import { Backdrop, Fade, Grid, makeStyles, Modal } from '@material-ui/core';
import Loader from 'react-loaders';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
let Preloader = (props) => {
    const classes = useStyles()
    return (
        <Modal open={props.elPreloaderEstaAbierto}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={classes.modal}
        >
            <Fade in={props.elPreloaderEstaAbierto}>
                <Grid container style={{width:"auto"}}>
                    <Loader type="line-scale-party" color="#ffffff" />
                </Grid>
            </Fade>
        </Modal>
    );
}

export default React.memo(Preloader);