import React from 'react'

//utilidades
import CardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';
import { glosarioDeVariables } from '../../../../../../utilidades/scripts/glosarioDeVariables';
import { glosarioDeVariablesPRD } from '../../../../../../utilidades/scripts/glosarioDeVariablesPRD';
import { obtenerValidacionDeVariable } from '../../../../../../utilidades/scripts/paraValidarVariables';
import CeldaActualizable from './CeldaActualizable';
//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
//Conexion con base de datos:
import { firestoreDB } from '../../../../../../firebase';
import Tooltip from '@material-ui/core/Tooltip';
import { actualizarPRD } from '../../api581Valvulas/scripts/consultasDB';

const clonedeepPRDenTabla = require('lodash/cloneDeep');

//estilos:
const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, ...estiloDeFuentesBase };

class TablaEditable extends React.Component {

    constructor(props) {
        super(props);
        this.state = { listaDeDatos: [] }
        this.datoEditable = this.datoEditable.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.ajustarListaDeDatos();
    }

    componentDidUpdate(prevenProps, prevenState) {
        if (prevenProps !== this.props) {
            this.ajustarListaDeDatos();
        }
    }

    agregarCambiosACampos = () => {
        if (this.props.listaDeCambios && this.props.listaDeCambios.length > 0) {
            let listaDeCambios = this.props.listaDeCambios;
            let lista = this.state.listaDeDatos;
            if (lista.length > 0) {
                lista.forEach((item, i) => {
                    let cambio = listaDeCambios.find(element => element.key === item.key);                    
                    if (cambio) lista[i].valor = cambio.valor;
                })
            }
            this.setState({
                listaDeDatos: lista
            })
        }
    }

    ajustarListaDeDatos = () => {
        if (this.props.isPRD) {
            let nuevaLista = [...this.props.listaDeDatos];
            nuevaLista.map((item, i) => {
                item.indice = i;
                return item;
            })
            this.setState({
                listaDeDatos: nuevaLista
            }, () => { this.agregarCambiosACampos() })
        } else {
            this.setState({
                listaDeDatos: this.props.listaDeDatos
            })
        }
    }

    renderView() {
        if (this.props.isPRD) {
            return (
                <CustomTable criterioDeOrdenamiento={{ id: "nombre", desc: false }}
                    isPRD={this.props.isPRD}
                    listaDeDatos={this.state.listaDeDatos}
                    columnas={[
                        {
                            accessor: 'nombre',
                            minWidth: 250,
                            Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                            Cell: row => (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}title={row.original.tooltip}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", ...estiloDeFuentesBase, fontSize: 14, textAlign: "left", }}>
                                        {row.original.nombre}
                                    </p>
                                </div>
                            ),
                        },
                        {
                            accessor: 'valor',
                            Header: () => (<p style={{ ...estiloParaHeader }}>Valor</p>),
                            Cell: row => (
                                !!row.original.valueTooltip
                                    ? <Tooltip arrow placement="top-end" title={row.original.valueTooltip}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", textAlign: "right" }} title={!!row.original.valueTooltip ? undefined : row.original.valor}>
                                            {this.datoEditable(row)}
                                        </div>
                                    </Tooltip> :
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", textAlign: "right" }} title={!!row.original.valueTooltip ? undefined : row.original.valor}>
                                        {this.datoEditable(row)}
                                    </div>
                            )
                        },
                        {
                            accessor: 'unidades',
                            minWidth: 60,
                            Header: () => (<p style={{ ...estiloParaHeader }}>Unidades</p>),
                            Cell: row => (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                        {(row.original.unidades === null) ? "-" : row.original.unidades}
                                    </p>
                                </div>
                            )
                        }
                    ]} />
            )
        } else {
            return (
                <CardConEncabezado titulo={this.props.titulo} icono={this.props.icono}>
                    <CustomTable criterioDeOrdenamiento={{ id: "nombre", desc: false }}
                        listaDeDatos={this.state.listaDeDatos.filter(item => item.valor !== undefined && item.key !== 'trazaDelSegmento')}
                        columnas={[
                            {
                                accessor: 'nombre',
                                minWidth: 250,
                                Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                                Cell: row => (
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                        <p style={{ marginBottom: 0, cursor: "pointer", ...estiloDeFuentesBase, fontSize: 14, textAlign: "left", }}>
                                            {row.original.nombre}
                                        </p>
                                    </div>
                                ),
                            },
                            {
                                accessor: 'valor',
                                Header: () => (<p style={{ ...estiloParaHeader }}>Valor</p>),
                                Cell: row => (
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", textAlign: "right" }} title={row.original.valor}>
                                        {this.datoEditable(row)}
                                    </div>
                                )
                            },
                            {
                                accessor: 'unidades',
                                minWidth: 60,
                                Header: () => (<p style={{ ...estiloParaHeader }}>Unidades</p>),
                                Cell: row => (
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                        <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                            {(row.original.unidades === null) ? "-" : row.original.unidades}
                                        </p>
                                    </div>
                                )
                            }
                        ]} />
                    <ToastContainer />
                </CardConEncabezado>
            )
        }
    }

    avisoDeGuardadoSatisfactorio = (palabra,type) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: type||'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    actualizarCelda(key, value, indiceDeLaVariable) {
        if (this.props.isPRD) {
            if(value==="")value=null
            if (this.props.obtenerCambios) this.props.obtenerCambios({ "key": key, "valor": value });
            let nivel1 = "";
            let nivel2 = "";
            let variable = "";
            const glosarioEnTabla = clonedeepPRDenTabla(glosarioDeVariablesPRD);
            Object.keys(glosarioEnTabla).forEach(item => {
                Object.keys(glosarioEnTabla[item]).forEach(item2 => {
                    Object.keys(glosarioEnTabla[item][item2]).forEach(item3 => {
                        if (key === item3) {
                            nivel1 = item;
                            nivel2 = item2;
                            variable = item3;
                        }
                    })
                })
            })

            let objetoDeActualizacion = {};
            if (nivel1 && nivel2 && variable) {
                const pathDeActualizacion= `parametrosDeEntrada.${nivel1}.${nivel2}.${variable}`
                objetoDeActualizacion[pathDeActualizacion] = value
                objetoDeActualizacion["estados.parametrosDeEntradaModificados"] = true;
                actualizarPRD({
                    coleccionActiva: this.props.coleccionActiva,
                    id: this.props.idNodo,
                    objetoDeActualizacion
                })
                    .then((result) => {
                        this.avisoDeGuardadoSatisfactorio('Actualización exitosa');
                        this.avisoDeGuardadoSatisfactorio('Se recomienda Recalcular','warning');
                        this.props.onActualizacionExitosa && this.props.onActualizacionExitosa(value,key,pathDeActualizacion);

                        let listaDeDatosActualizada = this.state.listaDeDatos;
                        listaDeDatosActualizada[indiceDeLaVariable].valor = value;
                        this.setState({ listaDeDatos: listaDeDatosActualizada });
                    })
                    .catch(error => {
                        console.log(error);
                        this.avisoDeGuardadoFallido('El proceso de actualización falló. Intenta de nuevo');
                    })
            }

        } else {
            let objetoDeActualizacion = {};
            objetoDeActualizacion[`variablesDeLaInspeccion.${key}.valor`] = value
            firestoreDB.collection(this.props.coleccionActiva).doc(this.props.idNodo).update(objetoDeActualizacion)
                .then((result) => {
                    this.avisoDeGuardadoSatisfactorio('Actualización exitosa');

                    let listaDeDatosActualizada = this.state.listaDeDatos;
                    listaDeDatosActualizada[indiceDeLaVariable].valor = value;
                    this.setState({ listaDeDatos: listaDeDatosActualizada });
                })
                .catch(error => {
                    console.log(error);
                    this.avisoDeGuardadoFallido('El proceso de actualización falló. Intenta de nuevo');
                })
        }

    }

    datoEditable = (infoDeCelda) => {
        if (this.props.isPRD) {
            for (let key in this.props.objetoPRD) {
                const variable = this.props.objetoPRD[key];
                const validacion = obtenerValidacionDeVariable(key, this.props.objetoPRD);
                if (infoDeCelda.original.key === key && typeof(infoDeCelda.original.valor) !== "object") {
                    return (
                        <CeldaActualizable
                            tipoActivo={this.props.tipoActivo}
                            valor={infoDeCelda.original.valor||"-"}
                            variable={variable}
                            infoDeCelda={infoDeCelda}
                            funcionDeValidacion={validacion}
                            estadoInicial={infoDeCelda.original.valor}
                            valorMensaje={` ${infoDeCelda.original.valor}`}
                            keyDelNodo={infoDeCelda.original.key}
                            vacioComoNulo
                            actualizarCelda={(valor) => { this.actualizarCelda(infoDeCelda.original.key, valor, infoDeCelda.original.indice); }}
                        />
                    )
                }
            }
        } else {
            for (let key in glosarioDeVariables) {
                const variable = glosarioDeVariables[key];
                const validacion = obtenerValidacionDeVariable(key);
                if (infoDeCelda.original.key === key) {
                    return (
                        <CeldaActualizable
                            tipoActivo={this.props.tipoActivo}
                            valor={infoDeCelda.original.valor}
                            variable={variable}
                            infoDeCelda={infoDeCelda}
                            funcionDeValidacion={validacion}
                            estadoInicial={infoDeCelda.original.valor}
                            valorMensaje={` ${infoDeCelda.original.valor}`}
                            keyDelNodo={infoDeCelda.original.key}
                            actualizarCelda={(valor) => { this.actualizarCelda(infoDeCelda.original.key, valor, infoDeCelda.original.indice); }}
                        />
                    )
                }
            }
        }

    }

}

export default TablaEditable;