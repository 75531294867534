import React, { useState, useEffect } from 'react';

//Componentes auxiliares:
import Mapa from '../../../../../utilidades/componentes/auxiliares/Mapa';
import PuntoDeInspeccion from './PuntoDeInspeccion';

const MapaDeFormatoDeInspeccion = (props) => {
  const [zoom, setZoom] = useState(1)
  const [height, setHeight] = useState(1050)

  /* ---------------------------- Render Functions ---------------------------- */
  const obtenerPuntos = (ordenesDeTrabajo) => {
    try {
      return ordenesDeTrabajo.map((ordenDeTrabajo, i) => {

        /* ------------ Transformar data de georeferenciación a 4 puntos ------------ */
        const [puntoInicio, puntoFinal] = ordenDeTrabajo.coordenadas.split(';')

        let [lngPuntoInicio, latPuntoInicio] = puntoInicio.split(':')
        let [lngPuntoFinal, latPuntoFinal] = puntoFinal.split(':')

        lngPuntoInicio = parseFloat(lngPuntoInicio.replace(",", "."));
        latPuntoInicio = parseFloat(latPuntoInicio.replace(",", "."));
        lngPuntoFinal = parseFloat(lngPuntoFinal.replace(",", "."));
        latPuntoFinal = parseFloat(latPuntoFinal.replace(",", "."));

        /* ----------- Si todos los datos son numéricos genera componente ----------- */
        if (!isNaN(lngPuntoInicio) && !isNaN(latPuntoInicio) && !isNaN(lngPuntoFinal) && !isNaN(latPuntoFinal)) {
          const trazo = [{ 'lng': lngPuntoInicio, 'lat': latPuntoInicio }, { 'lng': lngPuntoFinal, 'lat': latPuntoFinal }]
          return (
            <PuntoDeInspeccion
              key={`ordenDeTrabajo_${ordenDeTrabajo.id || ordenDeTrabajo.estado}_${Date.now()}`}
              renderKey={`ordenDeTrabajo_${ordenDeTrabajo.id || ordenDeTrabajo.estado}_${Date.now()}`}
              id={ordenDeTrabajo.id || ordenDeTrabajo.numeroDeFormato}
              estado={ordenDeTrabajo.estado}
              ordenDeTrabajo={{
                ...ordenDeTrabajo,
                puntosGeoreferencia: trazo
              }}
              tipoMapa={props.tipoMapa}
            />
          );
        } else {
          return (<></>)
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    switch (props.tipoMapa) {
      case 'satelite':
        setZoom(80)
        setHeight(400)
        break;
      case 'mapa':
      default:
        setZoom(1)
        setHeight(1050)
        break;
    }
  }, [props.tipoMapa])

  if (props.ordenesDeTrabajo.length === 0) {
    return (
      <p style={{ textAlign: "center" }}>
        No hay información para mostrar
      </p>
    )
  }

  return (
    <div >
      <Mapa
        defaultZoom={zoom}
        key={props.keyDeRenderizado}
        containerStyles={{ height: height }}
        tipoMapa={props.tipoMapa}
      >
        {obtenerPuntos(props.ordenesDeTrabajo)}
      </Mapa>
    </div>
  )
}

export default MapaDeFormatoDeInspeccion;