import React from 'react'
import { ResponsiveContainer, ComposedChart, Bar, XAxis, CartesianGrid, YAxis, Legend, Tooltip, LabelList } from 'recharts';
import PropTypes from 'prop-types';

const BarrasDobleAgrupadas = (props) => {
        const parametrosBarra = obtenerParametrosDeBarras(props.data);
        return (
            <>
                <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                    <ComposedChart data={props.data} >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="nombre" hide={false} />
                        <YAxis />
                        <Legend align="right" />
                        <Tooltip />
                        {obtenerBarras(parametrosBarra, props)}
                    </ComposedChart>
                </ResponsiveContainer>
            </>
        )
    
}

function obtenerParametrosDeBarras(data){
    let lista = [];
    if(data.length > 0){
        data.forEach(element =>{
            Object.keys(element).forEach(item=>{
                if(item !== "nombre" && !lista.find(index=> index === item)) lista.push(item);
            })                  
        })
    }
    return lista;
}

const obtenerBarras = (data, props) => {
    const {barSize, colores, mostrarValores} = props;
    let listaDeBarras = [];
    if (data.length > 0) {
        let listaDeKeys = data;
        for (let k = 0; k < listaDeKeys.length; k++) {
            if (listaDeKeys[k] !== 'nombre' && listaDeKeys[k] !== 'grupo') {
                listaDeBarras.push(
                    <Bar
                        height={900}
                        stackId="a"
                        barSize={barSize ? barSize : 75}
                        key={listaDeKeys[k]}
                        nombre={listaDeKeys[k]}
                        dataKey={listaDeKeys[k]}
                        fill={obtenerColor(listaDeKeys[k], colores)}
                    >   
                        {mostrarValores ?<LabelList dataKey={listaDeKeys[k]} /> : <></>}
                    </Bar>
                );
            }
        }
    }
    return listaDeBarras;
}

const obtenerColor = (etiqueta, colores) => {
    let listaDeColores = Object.keys(colores)
    for (let i = 0; i < listaDeColores.length; i++) {
        if (colores[listaDeColores[i]]) {
            return colores[etiqueta];
        }
    }
    return "#D6DCD9"
}

BarrasDobleAgrupadas.propType = {
    data: PropTypes.arrayOf(PropTypes.object),
    keyAgrupamiento1: PropTypes.string,
    keyAgrupamiento2: PropTypes.string,
    keyVariable: PropTypes.string,
    parametrizacion: PropTypes.object
}

export default BarrasDobleAgrupadas;