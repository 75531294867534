import React, { useState } from 'react';
//reacstrap:
import { Card, Col, Row, ListGroup, ListGroupItem, Modal, ModalBody } from 'reactstrap';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import Swal from 'sweetalert2';

//Icons
import { RiDeleteBin6Line } from 'react-icons/ri';

//Toastify
import { successToast, errorToast } from '../../../../utilidades/Toastify/Toastify'

//services
import { servicioEliminarUsuario } from '../services/paraGestionDeUsuarios'


//Imports auxiliares
import rolesDeUsuarios from '../scripts/parametrizacionRoles';

//imagenes de fondo Cards:
import { bgHorizontales } from '../../../../utilidades/almacen/bancoDeImagenes';
const backgroundImageUrl = bgHorizontales[1];
const defaultAvatar = "/assets/img/iconos/avatar.webp";


const ModalDetalleUsuario = (props) => {
    const [ deletingUser, setDeletingUser] = useState(false)

    const handleUserDelete = async () => {
        try {
            setDeletingUser(true)

            const resultado = await Swal.fire({
                title: '¿Estás seguro de eliminar el usuario?',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: `Aceptar`,
                denyButtonText: `Cancelar`,
              })

            if(resultado.isDismissed) return
              
            await servicioEliminarUsuario(props.usuario.id)

            successToast('Usuario eliminado correctamente')

            props.toggleDelModal()
        } catch (error) {
            errorToast(error.message || 'Error al eliminar el usuario')
        } finally {
            setDeletingUser(false)
        }
    }

    return (
        <Modal isOpen={props.elModalEstaAbierto} toggle={props.toggleDelModal}>
            <ModalBody className='p-0'>
            <Col sm="12" md='12' lg="12" xl="12" className='p-0' >
                <Card className="profile-responsive">
                    <div className="dropdown-menu-header" >
                        <div className="dropdown-menu-header-inner bg-dark">
                            <div className="menu-header-image opacity-2"
                                style={{
                                    backgroundImage: "url('" + backgroundImageUrl + "')",
                                    backgroundPositionX: "center", backgroundPositionY: "top", backgroundSize: "cover"
                                }}
                            />
                            <div className="menu-header-content btn-pane-right">
                                <Col className='align-items-center mb-sm-3 mb-md'>
                                    <Row className='justify-content-center'>
                                        <div className="avatar-icon rounded" style={{width: 200, height: 200}}>
                                            <img src={props.usuario.fotoDePerfil? props.usuario.fotoDePerfil.url : defaultAvatar} alt="Avatar 5"/>
                                        </div>
                                    </Row>
                                </Col>
                                <Col className='align-items-start'>
                                    <h5 className="menu-header-title">{props.usuario.nombre}</h5>
                                    <h6 className="widget-subheading mt-2" style={{fontSize: 15}}>{rolesDeUsuarios[props.usuario.rol] ? rolesDeUsuarios[props.usuario.rol].titulo : ''}</h6>
                                    <h6 className="widget-subheading mt-1" style={{fontSize: 15}}>{props.usuario.correo}</h6>
                                    <Row className="justify-content-end mt-4">
                                        <LaddaButton 
                                            className={"mb-2 mr-2 btn btn-danger align-self-end"}
                                            loading={deletingUser}
                                            onClick={handleUserDelete}
                                            data-style={EXPAND_RIGHT}
                                            disabled={deletingUser}
                                        >
                                            <RiDeleteBin6Line/>
                                        </LaddaButton>
                                    </Row>
                                </Col>
                            </div>
                        </div>
                    </div>
                    <ListGroup flush>
                        <ListGroupItem>
                            <div className="widget-content pt-1 pl-0 pr-0 text-align-start" style={{textAlign: 'start'}}>
                                {/* <h5 className="widget-heading opacity-5 mb-0">
                                    Firma:
                                </h5>
                                <h6 className="mt-3 mb-3">
                                   Mi firma
                                </h6> */}
                                <p className="opacity-8 mb-0">
                                    Creado por:
                                </p>
                                <p className="opacity-5 mb-0 font-weight-bold">
                                    { props.usuario.creador || 'Sin información' }
                                </p>
                            </div>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            </ModalBody>
        </Modal>
    );
}

export default ModalDetalleUsuario;