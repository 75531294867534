import { CircularProgress } from '@material-ui/core';
import React from 'react'


function BodyLoadingComponent({ component, ...props }) {
    switch (component) {
        case "cargandoLabel":
            return <div {...props} children={"Cargando..."} />
        case "circularProgress":
        default:
            return <CircularProgress {...props} />
    }
}

export default BodyLoadingComponent
