import React from 'react';

//Componentes auxiliares:
import TablaDeNodos from './TablaDeNodos.jsx';
import CustomModal from '../plantillaBase/CustomModal.jsx';

class CeldaConModal extends React.Component {

    constructor() {
        super();

        this.state = {
            elModalEstaAbierto: false
        }
    }

    render() {
        const fila = this.props.fila;
        const celda = this.props.celda;
        const estilosBase = this.props.estilosBase;

        return (
            <React.Fragment>
                <div key={"filaDeMatriz." + fila.valorEnY + "." + celda.valorEnX + "." + this.props.contadorDeRenders}
                    style={{
                        maxWidth: estilosBase.anchoDeCeldaMaximo, borderRadius: 5,
                        backgroundColor: celda.color, height: estilosBase.altoDeFila,
                        marginLeft: estilosBase.margenEntreCeldas / 4, marginRight: estilosBase.margenEntreCeldas / 4,
                        flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                    }} onClick={this.toggleDelModal}>
                    <span style={{ fontSize: 14, fontWeight: 'bold', color: 'white' }}>
                        {celda.nodosAsociados.length}
                    </span>
                </div>

                <CustomModal
                    toggleDelModal={this.toggleDelModal}
                    titulo={this.obtenerTituloDelModal()}
                    elModalEstaAbierto={this.state.elModalEstaAbierto}>

                    <div style={{ padding: 15 }}>
                        <TablaDeNodos
                            tipoDeFalla={this.props.tipoDeFalla}
                            isPRD={this.props.isPRD||false}
                            coleccionActiva={this.props.coleccionActiva}
                            listaDeNodos={this.props.celda.nodosAsociados}
                            criterioDeOrdenamiento={{ id: "riesgo", desc: true }}
                            moduloTarget={this.props.moduloTarget}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa} />
                    </div>

                </CustomModal>

            </React.Fragment>
        );
    }

    //Métodos operativos:
    toggleDelModal = () => {
        if (this.props.celda.nodosAsociados.length > 0) {
            this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto });
        }
    }

    //Métodos para renderizado:
    obtenerTituloDelModal = () => {
        const nivelDeRiesgo = this.props.celda.nivelDeRiesgo;
        const probabilidadCualitativa = this.props.celda.valorEnX;
        const consecuenciaCualitativa = this.props.fila.valorEnY;

        return (
            <span>
                {"Nodos con CoF " + probabilidadCualitativa + " y PoF " + consecuenciaCualitativa + ". "}
                <b>{"Nivel de riesgo " + nivelDeRiesgo}</b>
            </span>
        );
    }
}

export default CeldaConModal;