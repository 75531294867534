import React from 'react';

//Componentes auxiliares:
import Mapa from '../../../../../../utilidades/componentes/auxiliares/Mapa';
import TrazaDeSegmento from '../../../../../../utilidades/componentes/visualizadores/TrazaDeSegmento';

//Scripts auxiliares:
import { obtenerColorSegunValorCualitativo } from '../../../../../../utilidades/scripts/paraVisualizacionDeRangos';

class MapaDeActivo extends React.Component {

    constructor() {
        super();

        this.state = {

            segmentosProcesados: []
        };
    }

    render() {
        if (this.state.segmentosProcesados.length > 0) {
            return (
                <div style={{ height: 440, ...this.props.style }}>
                    <Mapa
                        defaultZoom={10}
                        key={this.props.keyDeRenderizado}>

                        {this.obtenerTrazas()}

                    </Mapa>
                </div>
            );
        }
        else {
            return (
                <p style={{ textAlign: "center" }}>
                    Este activo no tiene información suficiente a nivel de georeferenciación
                </p>
            );
        }
    }

    componentDidMount() {
        this.procesarSegmentos();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.keyDeRenderizado !== this.props.keyDeRenderizado) {
            this.procesarSegmentos();
        }
    }

    //Métodos operativos:
    procesarSegmentos = () => {
        let segmentosProcesados = [];
        
        this.props.segmentos.forEach((segmento, i) => {
            let trazaComoString = segmento.variablesDeLaInspeccion.trazaDelSegmento.valor;

            if (trazaComoString) {
                trazaComoString = trazaComoString.replace("[", "");
                trazaComoString = trazaComoString.replace(";]", "");

                let trazasDelSegmento = [];
                trazaComoString.split(";").forEach(puntoComoString => {

                    try {
                        let componentesDelPunto = puntoComoString;
                        componentesDelPunto = componentesDelPunto.replace("{", "");
                        componentesDelPunto = componentesDelPunto.replace("}", "");

                        componentesDelPunto = componentesDelPunto.split(":");
                        componentesDelPunto[0] = componentesDelPunto[0].replace(",", ".");
                        componentesDelPunto[1] = componentesDelPunto[1].replace(",", ".");

                        if (!isNaN(componentesDelPunto[0]) && !isNaN(componentesDelPunto[1])) {
                            trazasDelSegmento.push({ "lng": parseFloat(componentesDelPunto[0]), "lat": parseFloat(componentesDelPunto[1]) });
                        }
                    }
                    catch (error) { console.log(error); };

                });

                let segmentoProcesado = { ...segmento };
                segmentoProcesado["trazaGeoreferenciada"] = {
                    color: obtenerColorSegunValorCualitativo(segmento.resultadosDeLaValoracion.riesgo.riesgoCualitativo, this.props.parametrizacionCorporativa.rangosDeRiesgo),
                    puntos: trazasDelSegmento,
                };
                segmentosProcesados.push(segmentoProcesado);
            }

        });

        this.setState({ segmentosProcesados: segmentosProcesados });
    }

    //Métodos para renderizado:
    obtenerTrazas = () => {
        return this.state.segmentosProcesados.map((segmento, i) => {
            return (
                <TrazaDeSegmento
                    key={"trazaDelSegmento." + segmento.id}
                    path={segmento.trazaGeoreferenciada.puntos}
                    moduloTarget={this.props.moduloTarget}
                    parametrizacionCorporativa={this.props.parametrizacionCorporativa}
                    segmento={segmento}
                />
            );
        })
    }

}

export default MapaDeActivo;