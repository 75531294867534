
export const crearListaTablaInfoActivo = ( nodo ) => {

    let listaInfoActivo = [];
    const data = nodo?.informacionGeneral;
    listaInfoActivo.push(
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Contrato vigente'
            },
            valor: {
                label: 'Valor', 
                valor: data?.contratoVigente?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'TAG'
            },
            valor: {
                label: 'Valor', 
                valor: data?.nombre?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: data?.diametroActivo?.nombre || 'Diámetro del activo'
            },
            valor: {
                label: 'Valor', 
                valor: data?.diametroActivo?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: data?.diametroActivo?.unidades ||'in'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Relación de los diámetros'
            },
            valor: {
                label: 'Valor',
                valor: data?.relacionDiametros?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades',
                valor: 'psi'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Fecha de activación'
            },
            valor: {
                label: 'Valor', 
                valor: data?.fechaDeActivacion?.valor.seconds ? new Date(data?.fechaDeActivacion?.valor.seconds).toLocaleDateString() : 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Presión de diseño'
            },
            valor: {
                label: 'Valor',
                valor: data?.presionDeDisenho?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades',
                valor: 'psi'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Presión de operación'
            },
            valor: {
                label: 'Valor',
                valor: data?.presionDeOperacion?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades',
                valor: 'psi'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Temperatura de diseño'
            },
            valor: {
                label: 'Valor', 
                valor: data?.temperaturaDeDisenho?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: '°F'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Temperatura de operación'
            },
            valor: {
                label: 'Valor', 
                valor: data?.temperaturaDeOperacion?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: '°F'
            },
        },
        {
            nombre: {
                label: 'Nombre', 
                valor: 'Fluido'
            },
            valor: {
                label: 'Valor', 
                valor: nodo?.sistemaAsociado?.nombre || 'Aún sin calcular'
            },
            unidades: {
                label: 'unidades', 
                valor: '-'
            },
        },
        {
            nombre: {
                label: 'Nombre, ', 
                valor:  'PWHT',
            },
            valor: {
                label: 'Valor', 
                valor:  'No'
            },
            unidades: {
                label: 'Unidades',
                valor: ' - '
            }
        },
        {
            nombre: {
                label: 'Nombre, ', 
                valor:  'Corrosión Allowance',
            },
            valor: {
                label: 'Valor', 
                valor:  data?.corrosionAllowance?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'Unidades',
                valor: ' in '
            }
        },
        {
            nombre: {
                label: 'Nombre, ', 
                valor:  'Eficiencia de la junta',
            },
            valor: {
                label: 'Valor', 
                valor:  data?.eficienciaDeLaJunta?.valor || 'Aún sin calcular'
            },
            unidades: {
                label: 'Unidades',
                valor: ' - '
            }
        },
    )

    return listaInfoActivo;
}
