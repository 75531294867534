import React from "react";
import { Route, Redirect } from "react-router-dom";

let LayoutProtegida = (componentProps) => {
    return (
        <Route {...componentProps}
            render={(props) => {
                if (componentProps.elUsuarioEstaLogeado) { return (<componentProps.Componente {...props} />); }
                else {
                    return (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
                }
            }}
        />
    );
};

export default LayoutProtegida;