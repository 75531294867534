import { get } from 'lodash';
import { useStore } from 'react-redux'
import { actualizarDataApi581PRD } from '../../../../../storage/reducerParaData';

/**
 * //FIXME OPTIMIZAR- NO SE ESTÁ HACIENDO DE LA MEJOR MANERA?
 */
function useStoreApi581PRD() {
    const store = useStore();
    function getStoreState(){
        return store.getState()
    }
    function getStateApi581PRD(fromStoreData) {
        return ( get(fromStoreData || getStoreState(), ["reducerParaData", "/app/analisis/riesgo/api581PRD"], {}))
    }
    function getDominioCorporativo(fromStoreData) {
        return get(fromStoreData || getStoreState(), "reducerParaUsuario.usuarioActivo.dominioCorporativo", "");
    }
    function dispatch(data) {
        store.dispatch(actualizarDataApi581PRD(data))
    }
    return ({ 
        getStoreState,
        getDominioCorporativo,
        getStateApi581PRD, 
        dispatch, 
        store 
    })
}

export default useStoreApi581PRD
