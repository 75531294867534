import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

/** Componente para paginar la tabla */
import FetchPaginator from './FetchPaginator';

/** Scripts */
import { crearFormatoParaTabla } from '../../scripts/crearFormatoParaTabla';

//Constantes auxiliares:
const largoDePaginacionDefault = 5;


/**
 * Tabla que hace la consulta paginada, cada vez que se le dé click en una nueva página se van a consultar los
 * Datos página por página.
 * @param {any} keyDeTabla Key que llevará la tabla.
 * @param {number} cantidadDeDatos Cantidad de datos para poder determinar cuantas páginas va a tener la tabla.
 * @param {any} listaDeDatos Datos en formato de tabla.
 * @param {function} callback Función que se vá a ejecutar para consultar la data en la db.
 * @param {boolean} cargando Variable que dice si la tabla va a estar cargando o no.
 * @param {boolean} mostrarPaginacionProp Variable para mostrar o no la paginación.
 * @param {number} largoDePaginacionProp Qué tantos datos se van a mostrar por página.
 * @param {Map} criterioDeOrdenamiento Objeto que dice cómo van a estar organizados los datos.
 * @param {Map} style Estilos para la tabla.
 * @param {Map} posicionUltimoDato Posición del ultimo dato para partir en la paginación.
 * @param {Map} limiteDeDescargas Limite de data que se va a traer al ejecutar la función de petición.
 */

const TablaFetchPaginada = (
    { 
        keyDeTabla, 
        cantidadDeDatos, 
        listaDeDatos, 
        callback, 
        cargando,
        mostrarPaginacionProp,
        largoDePaginacionProp,
        criterioDeOrdenamiento,
        style,
        posicionUltimoDato,
        limiteDeDescargas
    }
) => {

    const {crearColumnasParaTabla, crearListaDatosParaTabla} = crearFormatoParaTabla();

    let mostrarPaginacion = true;
    if (mostrarPaginacionProp !== undefined) {
         mostrarPaginacion = mostrarPaginacionProp
    };

    if (mostrarPaginacion && listaDeDatos && listaDeDatos.length < largoDePaginacionDefault) { 
        mostrarPaginacion = false; 
    };

    let largoDePaginacion = largoDePaginacionDefault;
    if (largoDePaginacionProp) { 
        largoDePaginacion = largoDePaginacionProp; 
    };

    if (listaDeDatos && listaDeDatos.length < largoDePaginacion) { 
        largoDePaginacion = listaDeDatos.length; 
    };

    return (
        <ReactTable
            key={ keyDeTabla }

            /** Props para paginación */
            PaginationComponent={ FetchPaginator }
            cantidadDeDatos={ cantidadDeDatos }
            largoDePaginacion={ largoDePaginacion }
            callback={ callback }
            showPagination={ mostrarPaginacion }
            defaultPageSize={ largoDePaginacion }
            posicionUltimoDato={posicionUltimoDato}
            limiteDeDescargas={limiteDeDescargas}

            /** Apariencia */
            className="-striped -highlight"
            style={style}
            pageText={'Página'}
            ofText={'de'}
            previousText={'Anterior'}
            nextText={'Siguiente'}
            rowsText={'filas'}
            
            /** Datos */ 
            data={crearListaDatosParaTabla(listaDeDatos)}
            columns={crearColumnasParaTabla(listaDeDatos)}


            defaultSorted={[criterioDeOrdenamiento || { id: "numero", desc: false }]}

            loading={cargando}
            
        />
    );
}
FetchPaginator.propTypes = {
    keyDeTabla : PropTypes.any, 
    cantidadDeDatos : PropTypes.number, 
    listaDeDatos : PropTypes.array, 
    callback : PropTypes.func, 
    cargando : PropTypes.bool,
    mostrarPaginacionProp : PropTypes.bool,
    largoDePaginacionProp : PropTypes.number,
    criterioDeOrdenamiento : PropTypes.object,
    style : PropTypes.any,
    posicionUltimoDato : PropTypes.number,
    limiteDeDescargas : PropTypes.number
}

export default TablaFetchPaginada

