import React from 'react'
import Col from 'reactstrap/lib/Col'
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado'
import CrearTablaDinamicamente from '../../../../../utilidades/componentes/visualizadores/CrearTablaDinamicamente'


const TablaReporteOndasGuiadas = (props) => {
    return(
        <React.Fragment>
            <Col md='12'>
                <CardConEncabezado
                    titulo={'Resultados ondas guiadas'}
                    icono={props.icono}
                    bgColor={props.bgColor}>
                    <CrearTablaDinamicamente
                        datosDeTabla={props.datosDeTabla}/>
                </CardConEncabezado>
            </Col>
        </React.Fragment>
    )
}

export default TablaReporteOndasGuiadas