import React from 'react';
import { Polyline } from 'react-google-maps';

//Componentes auxiliares:
import CustomModal from '../plantillaBase/CustomModal.jsx';
import TablaDeNodos from '../resumenDeModulo/TablaDeNodos.jsx';

class TrazaDeSegmento extends React.Component {

    constructor() {
        super();

        this.state = {
            elModalEstaAbierto: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Polyline
                    defaultOptions={{ strokeColor: this.props.segmento.trazaGeoreferenciada.color, strokeWeight: 5 }}
                    onClick={this.toggleDelModal}
                    path={this.props.path}
                />

                <CustomModal
                    elModalEstaAbierto={this.state.elModalEstaAbierto}
                    size="lg"
                    titulo={this.obtenerTituloDelModal()}
                    toggleDelModal={this.toggleDelModal}
                >
                    <div style={{ padding: 15 }}>
                        <TablaDeNodos
                            coleccionActiva={"segmentos"}
                            criterioDeOrdenamiento={{ id: "riesgo", desc: true }}
                            listaDeNodos={[this.props.segmento]}
                            moduloTarget={this.props.moduloTarget}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa} />
                    </div>

                </CustomModal>

            </React.Fragment>
        );
    }

    //Métodos operativos:
    toggleDelModal = () => {
        this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto });
    }

    //Métodos para renderizado:
    obtenerTituloDelModal = () => {
        return (
            <span>
                <b>{this.props.segmento.informacionGeneral.nombre.valor}</b>
            </span>
        );
    }
}

export default TrazaDeSegmento;