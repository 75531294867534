
import forEach from 'lodash/forEach';
import { SIN_VALORAR } from '../../../scripts/constantes';


/**
 * recibe objeto {semicuantitativo:[{id:api...,data:...}]}
 * se planea no utilizar estas funciones
 * (modificar en analisis de riesgo la forma de obtener los resultados (dejar de usar el array y trabajarlo directamente en objeto))
 */
export const resultadosDeStoreAInventario = (infoCampo) => {
    const toReturn = {}
    forEach(infoCampo, (prds, keyDeInfo) => {
        if (keyDeInfo === SIN_VALORAR) {
            toReturn[keyDeInfo] = prds
        }
        else {
            const nuevasPRDS = prds.map((prd) => {
                const nuevosResultados = {}
                prd.resultados.forEach((obj) => (nuevosResultados[obj.id] = obj.data))
                return ({ ...prd, resultados: nuevosResultados })
            })
            toReturn[keyDeInfo] = nuevasPRDS
        }
    })
    return toReturn;
}
/**
 * se planea no utilizar estas funciones
 * (modificar en analisis de riesgo la forma de obtener los resultados (dejar de usar el array y trabajarlo directamente en objeto))
 */
export const resultadosDeInvetarioAStore = (infoCampo) => {
    const toReturn = {}
    forEach(infoCampo, (prds, keyDeInfo) => {
        if (keyDeInfo === SIN_VALORAR) {
            toReturn[keyDeInfo] = prds
        }
        else {
            const nuevasPRDS = prds.map((prd) => {
                const nuevosResultados = Object.keys(prd.resultados).map((modulo) => ({ id: modulo, data: prd.resultados[modulo] }))
                return { ...prd, resultados: nuevosResultados }
            })
            toReturn[keyDeInfo] = nuevasPRDS
        }
    })
    return toReturn;
}