import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Row, Col, Label, BreadcrumbItem, Input, Card } from 'reactstrap';
import { Select, MenuItem } from '@material-ui/core';
import { firebaseFunctions } from '../../../firebase';

//Componentes Generales
import Preloader from '../../../utilidades/componentes/plantillaBase/Preloader';

//Scripts Generales
import {
    glosarioEdicionAnomaliaPM1,
    glosarioEdicionAnomaliaPM2,
    glosarioEdicionAnomaliaAB1,
    glosarioEdicionAnomaliaAB2,
    glosarioEdicionCamisa,
    glosarioEdicionCamisaSA,
    glosarioEdicionParche,
    glosarioEdicionComentariosCamisas,
    glosarioEdicionSubanomalias,
    glosarioEdicionGrietas,
    glosarioEdicionGrietasCorridaUTCD,
    glosarioEdicionEnsayosGrietas,
} from './scripts/glosarioVariablesDeInput';

import { obtenerValidacionDeVariable } from './scripts/paraValidadVariablesFormulario';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//const cargarOrdenDeTrabajo = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajo');
const editarAnomalias = firebaseFunctions.httpsCallable('odt_editarAnomalias ')
const editarSubAnomalias = firebaseFunctions.httpsCallable('odt_editarSubAnomalias')

class VisualizadorFormularioEdicionAnomalias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            glosario1: undefined,
            glosario2: undefined,
            //Validación formulario
            estadoDeValidacion: {},
            error: "",
        }
    }

    componentDidMount(){
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        this.setState({
            estadoDeValidacion: estadoDeValidacionInicial,
        });
    }
    componentWillMount(){
        this.obtenerGlosario(this.props.seccion)
    }
    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.submitDelFormulario}>
                    <Card className="col col-12 my-2 px-0">
                        <Col key={'titulo_seccion1'} lg='12' className='p-0'>
                            <Breadcrumb>
                                <BreadcrumbItem className='BreadcrumbItem-color'>{this.obtenerTitulosCardsSeccion1()}</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                        <Row className="mb-3 px-3">
                            {this.obtenerPrimeraSeccionDeFormulario()}
                        </Row>
                    </Card>
                    {!(this.state.glosario2 === undefined) ? <Card className="col col-12 mt-3 px-0">
                        <Col key={'titulo_seccion2'} lg='12' className='p-0'>
                            <Breadcrumb>
                                <BreadcrumbItem className='BreadcrumbItem-color'>{this.obtenerTitulosCardsSeccion2()}</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                        <Row className="mb-3 px-3">
                            {this.obtenerSegundaSeccionDeFormulario()}
                        </Row>
                    </Card> : null}
                    <br />
                    <Input type='submit' value="Actualizar" style={{ textAlign: 'center', color: '#ffffff', width: '25%', float: 'right', opacity: this.obtenerEstadoDeFormulario()? 1 : 0.8 }} className={'bg-royal pusheable-button mb-3'} disabled={!this.obtenerEstadoDeFormulario()}/>
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    obtenerGlosario = (seccion) => {
        if (this.props.subAnomalia)
        {
            switch (seccion) {
                case 'CAMISA':
                case 'CAMISA_SOBRE_ANILLOS':
                case 'PARCHE':
                    this.setState({
                        glosario1: glosarioEdicionSubanomalias,
                    })
                    break;
                default:
                    this.setState({
                        glosario1: glosarioEdicionSubanomalias,
                    })
                    break;
            }

        }
        else {
            switch (seccion) {
                case 'PERDIDA_DE_METAL':
                    this.setState({
                        glosario1: glosarioEdicionAnomaliaPM1,
                        glosario2: glosarioEdicionAnomaliaPM2,
                    })
                    break;
                case 'ABOLLADURA':
                    this.setState({
                        glosario1: glosarioEdicionAnomaliaAB1,
                        glosario2: glosarioEdicionAnomaliaAB2
                    })
                    break;
                case 'GRIETA':
                    this.setState({
                        glosario1: glosarioEdicionGrietas,
                        glosario2: glosarioEdicionGrietasCorridaUTCD
                    })
                    break;
                case 'ENSAYO_GRIETA':
                    this.setState({
                        glosario1: glosarioEdicionEnsayosGrietas,
                    })
                    break;
                case 'CAMISA':
                    this.setState({
                        glosario1: glosarioEdicionCamisa,
                        glosario2: glosarioEdicionComentariosCamisas
                    })
                    break;
                case 'CAMISA_SOBRE_ANILLOS':
                    this.setState({
                        glosario1: glosarioEdicionCamisaSA,
                        glosario2: glosarioEdicionComentariosCamisas
                    })
                    break;
                case 'PARCHE':
                    this.setState({
                        glosario1: glosarioEdicionParche,
                        glosario2: glosarioEdicionComentariosCamisas
                    })
                    break;
                default:
                    this.setState({
                        glosario1: glosarioEdicionAnomaliaPM1,
                    })
                    break;
            }
        }
    }

    obtenerTitulosCardsSeccion1 = ()=>{
        if (this.props.subAnomalia) {
            switch (this.props.seccion) {
                case 'CAMISA':
                case 'CAMISA_SOBRE_ANILLOS':
                case 'PARCHE':
                    return 'IDENTIFICACIÓN DE ANOMALÍAS'
                default:
                    return 'IDENTIFICACIÓN DE ANOMALÍAS'
            }
        }
        else {
            switch (this.props.seccion) {
                case 'PERDIDA_DE_METAL':
                    return 'DATOS RECOGIDOS EN LA INSPECCIÓN'
                case 'ABOLLADURA':
                    return 'CARACTERIZACIÓN, VALORACIÓN Y EVALUACIÓN DE ABOLLADURAS'
                case 'GRIETA':
                    return 'DATOS SUMINISTRADOS POR MEDICIÓN EN CAMPO'
                case 'ENSAYO_GRIETA':
                    return 'ENSAYOS POR ULTRASONIDO'
                case 'CAMISA':
                    return 'CONDICIÓN DEL RECUBRIMIENTO'
                case 'CAMISA_SOBRE_ANILLOS':
                    return 'CONDICIÓN DEL RECUBRIMIENTO'
                case 'PARCHE':
                    return 'CONDICIÓN DEL RECUBRIMIENTO'
                case 'SUBANOMALIA':
                    return 'CONDICIÓN DEL RECUBRIMIENTO'
                default:
                    return 'PRIMERA SECCIÓN'
            }
        }
    }

    obtenerTitulosCardsSeccion2 = ()=>{
        if (!this.props.subAnomalia) {
            switch (this.props.seccion) {
                case 'PERDIDA_DE_METAL':
                    return 'DATOS RECOGIDOS DE ILI'
                case 'ABOLLADURA':
                    return 'INTERACCIÓN CON PERDIDA DE METAL'
                case 'GRIETA':
                    return 'DATOS SUMINISTRADOS CORRIDA UTCD'
                case 'CAMISA':
                    return 'COMENTARIOS DE LOS ENSAYOS'
                case 'CAMISA_SOBRE_ANILLOS':
                    return 'COMENTARIOS DE LOS ENSAYOS'
                case 'PARCHE':
                    return 'COMENTARIOS DE LOS ENSAYOS'
                default:
                    return 'SEGUNDA SECCIÓN'
            }
        }
    }

    //Funciones Render
    obtenerInputSegunTipo = (input, seccion) => {
        switch (input.type) {
            case 'text':
                return (
                    <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                        <Input
                            readOnly={input.disabled ? input.disabled : false}
                            type={input.type}
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                            onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'email':
                return (
                    <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                        <Input
                            type={input.type}
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                            onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'fecha':
                return (
                    <Col key={`date ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label className={"mb-0 mt-2"}>{input.contenido}</Label>
                        <Input
                            key={input.name}
                            type="date"
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor? this.state.estadoDeValidacion[input.name].valor.toISOString().split("T")[0] : new Date().toISOString().split("T")[0] : new Date().toISOString().split("T")[0]}
                            onChange={(e) => this.cambiosEnInput(e, input, seccion, 'date')}
                            style={{ width: '100%', boxShadow: 'none' }}
                            required
                        />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'select':
                const estadoDelPadre = this.state.estadoDeValidacion[input.padre];
                return(
                    <Col key={`select ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                        <Label className={"mb-0 mt-2"} style={{color: estadoDelPadre? estadoDelPadre.valor === ''? '#c9c9c9' : 'black' : 'black'}}>{input.contenido}</Label>
                        <Select
                            name={input.name}
                            value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                            onChange={this.cambiosEnInput}
                            style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                            variant="outlined"
                        >
                            {this.obtenerListadoDeOpciones(input.name, input)}
                        </Select>
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                    </Col>
                );
            case 'textarea':
                return(
                <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                    <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                    <Input
                        type={input.type}
                        name={input.name}
                        value={this.state.estadoDeValidacion[input.name]? this.state.estadoDeValidacion[input.name].valor : ''}
                        onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                        required
                    />
                        <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                </Col>
                )
            default:
                return (
                    <Col key={`titulo ${input.name}`} lg='12' className='pt-4'>
                        <Breadcrumb>
                            <BreadcrumbItem className='BreadcrumbItem-color'>{input.name}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                )
        }
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        const estadoDeValidacion =  this.state.estadoDeValidacion;

        const listadoDeValores = {};
        Object.keys(estadoDeValidacion).forEach(key => {
            listadoDeValores[key] = estadoDeValidacion[key].valor
        });

        if(nodo.obtenerOpciones !== undefined){
            let opciones = nodo.obtenerOpciones(listadoDeValores)
            opciones.forEach((opcion, i)=> {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }else{
            nodo.opciones.forEach((opcion, i) =>{
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }
    }

    obtenerPrimeraSeccionDeFormulario = () => {
        return Object.keys(this.state.glosario1).map(key =>this.obtenerInputSegunTipo(this.state.glosario1[key], this.props.seccion))
    }

    obtenerSegundaSeccionDeFormulario = () => {
        return Object.keys(this.state.glosario2).map(key =>this.obtenerInputSegunTipo(this.state.glosario2[key], this.props.seccion))
    }

    //Funciones Lógicas 
    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    cambiosEnInput = (event, key, seccion, type) => {
        let value =  undefined
        if (type === 'date') {
            let myDate = new Date(((new Date(event.target.value).getTime()/1000)+18000)*1000)
            value = myDate
        }
        else {
            value = event.target.value
        }
        const name = event.target.name;
        const funcionValidacion = obtenerValidacionDeVariable(name, seccion)
        const validacion = funcionValidacion['validation'];

        const estadoDeValidacionActual = this.state.estadoDeValidacion;


        if (validacion && name !== 'estado') {
            const respuesta = validacion(key, value);
            if (!respuesta) {
                estadoDeValidacionActual[name].estado = false;
                estadoDeValidacionActual[name].valor = value;
                estadoDeValidacionActual[name].mensaje = true;
                this.setState({
                    error: "El valor no es válido!",
                    estadoDeValidacion: estadoDeValidacionActual,
                })
                return;
            } 
        } 
        estadoDeValidacionActual[name].estado = true;
        estadoDeValidacionActual[name].valor = value;
        estadoDeValidacionActual[name].mensaje = false;
        this.setState({
            error: "",
            estadoValidacion: estadoDeValidacionActual,
        })
    }

    estructuraParaGuardado = () =>{
        const estadoDeValidacion = this.state.estadoDeValidacion;
        if (this.props.subAnomalia){
            switch (this.props.seccion) {
                case 'CAMISA':
                case 'PARCHE':
                case 'CAMISA_SOBRE_ANILLOS':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "idSubAnomalia": this.props.idSubAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "largoAnomaliaCamisa": estadoDeValidacion.largoAnomaliaCamisa.valor ? estadoDeValidacion.largoAnomaliaCamisa.valor : null,
                            "anchoAnomaliaCamisa": estadoDeValidacion.anchoAnomaliaCamisa.valor ? estadoDeValidacion.anchoAnomaliaCamisa.valor : null,
                            "posicionHorariaAnomalia": estadoDeValidacion.posicionHorariaAnomalia.valor ? estadoDeValidacion.posicionHorariaAnomalia.valor : null,
                            "ubicacionEnLaReparacion": estadoDeValidacion.ubicacionEnLaReparacion.valor ? estadoDeValidacion.ubicacionEnLaReparacion.valor : null,
                            "conceptoAnomalia": estadoDeValidacion.conceptoAnomalia.valor ? estadoDeValidacion.conceptoAnomalia.valor : null,
                            "ensayoReparacion": estadoDeValidacion.ensayoReparacion.valor ? estadoDeValidacion.ensayoReparacion.valor : null,
                        }
                    }
                default:
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "idSubAnomalia": this.props.idSubAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "largoAnomaliaCamisa": estadoDeValidacion.largoAnomaliaCamisa.valor ? estadoDeValidacion.largoAnomaliaCamisa.valor : null,
                            "anchoAnomaliaCamisa": estadoDeValidacion.anchoAnomaliaCamisa.valor ? estadoDeValidacion.anchoAnomaliaCamisa.valor : null,
                            "posicionHorariaAnomalia": estadoDeValidacion.posicionHorariaAnomalia.valor ? estadoDeValidacion.posicionHorariaAnomalia.valor : null,
                            "ubicacionEnLaReparacion": estadoDeValidacion.ubicacionEnLaReparacion.valor ? estadoDeValidacion.ubicacionEnLaReparacion.valor : null,
                            "conceptoAnomalia": estadoDeValidacion.conceptoAnomalia.valor ? estadoDeValidacion.conceptoAnomalia.valor : null,
                            "ensayoReparacion": estadoDeValidacion.ensayoReparacion.valor ? estadoDeValidacion.ensayoReparacion.valor : null,
                        }
                    }
            }
        }
        else {
            switch (this.props.seccion) {
                case 'PERDIDA_DE_METAL':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": estadoDeValidacion.tipoDeAnomalia.valor ? estadoDeValidacion.tipoDeAnomalia.valor : null,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "evaluacionOComparacion": estadoDeValidacion.evaluacionOComparacion.valor ? estadoDeValidacion.evaluacionOComparacion.valor : null,
                            "programadaOAdiccional": estadoDeValidacion.programadaOAdiccional.valor ? estadoDeValidacion.programadaOAdiccional.valor : null,
                            "evento": estadoDeValidacion.evento.valor ? estadoDeValidacion.evento.valor : null,
                            "distanciaAlRegistroMedicion": estadoDeValidacion.distanciaAlRegistroMedicion.valor ? estadoDeValidacion.distanciaAlRegistroMedicion.valor : null,
                            "distanciaJuntaAnterior": estadoDeValidacion.distanciaJuntaAnterior.valor ? estadoDeValidacion.distanciaJuntaAnterior.valor : null,
                            "distanciaJuntaPosterior": estadoDeValidacion.distanciaJuntaPosterior.valor ? estadoDeValidacion.distanciaJuntaPosterior.valor : null,
                            "posicionDelDuctoILIDistanciaMedicion": estadoDeValidacion.posicionDelDuctoILIDistanciaMedicion.valor ? estadoDeValidacion.posicionDelDuctoILIDistanciaMedicion.valor : null,
                            "longitudAnomaliaMedicion": estadoDeValidacion.longitudAnomaliaMedicion.valor ? estadoDeValidacion.longitudAnomaliaMedicion.valor : null,
                            "anchoAnomaliaMedicion": estadoDeValidacion.anchoAnomaliaMedicion.valor ? estadoDeValidacion.anchoAnomaliaMedicion.valor : null,
                            "profundidad": estadoDeValidacion.profundidad.valor ? estadoDeValidacion.profundidad.valor : null,
                            "interactuaConJunta": estadoDeValidacion.interactuaConJunta.valor ? estadoDeValidacion.interactuaConJunta.valor : null,
                            "tipoAnomalia": estadoDeValidacion.tipoAnomalia.valor ? estadoDeValidacion.tipoAnomalia.valor : null,
                            "paredInternaMedicion": estadoDeValidacion.paredInternaMedicion.valor ? estadoDeValidacion.paredInternaMedicion.valor : null,
                            "idAnomaliaILI": estadoDeValidacion.idAnomaliaILI.valor ? estadoDeValidacion.idAnomaliaILI.valor : null,
                            "distanciaAlRegistro": estadoDeValidacion.distanciaAlRegistro.valor ? estadoDeValidacion.distanciaAlRegistro.valor : null,
                            "posicionDelDuctoILI": estadoDeValidacion.posicionDelDuctoILI.valor ? estadoDeValidacion.posicionDelDuctoILI.valor : null,
                            "longitudAnomialia": estadoDeValidacion.longitudAnomialia.valor ? estadoDeValidacion.longitudAnomialia.valor : null,
                            "anchoAnomialia": estadoDeValidacion.anchoAnomialia.valor ? estadoDeValidacion.anchoAnomialia.valor : null,
                            "porcentajeReduccionEspesor": estadoDeValidacion.porcentajeReduccionEspesor.valor ? estadoDeValidacion.porcentajeReduccionEspesor.valor : null,
                            "paredInterna": estadoDeValidacion.paredInterna.valor ? estadoDeValidacion.paredInterna.valor : null,
                        }
                    }
                case 'ABOLLADURA':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "idAnomaliaILI": estadoDeValidacion.idAnomaliaILI.valor ? estadoDeValidacion.idAnomaliaILI.valor : null,
                            "distanciaRegistro": estadoDeValidacion.distanciaRegistro.valor ? estadoDeValidacion.distanciaRegistro.valor : null,
                            "longitudAbolladura": estadoDeValidacion.longitudAbolladura.valor ? estadoDeValidacion.longitudAbolladura.valor : null,
                            "anchoAbolladura": estadoDeValidacion.anchoAbolladura.valor ? estadoDeValidacion.anchoAbolladura.valor : null,
                            "profundidad": estadoDeValidacion.profundidad.valor ? estadoDeValidacion.profundidad.valor : null,
                            "distanciaH3JuntaAnterior": estadoDeValidacion.distanciaH3JuntaAnterior.valor ? estadoDeValidacion.distanciaH3JuntaAnterior.valor : null,
                            "distanciaH4JuntaPosterior": estadoDeValidacion.distanciaH4JuntaPosterior.valor ? estadoDeValidacion.distanciaH4JuntaPosterior.valor : null,
                            "distanciaJuntaLongitudinal": estadoDeValidacion.distanciaJuntaLongitudinal.valor ? estadoDeValidacion.distanciaJuntaLongitudinal.valor : null,
                            "interaccionConArrugasOConcentradorDeEsfuerzos": estadoDeValidacion.interaccionConArrugasOConcentradorDeEsfuerzos.valor ? estadoDeValidacion.interaccionConArrugasOConcentradorDeEsfuerzos.valor : null,
                            "interSoldadura": estadoDeValidacion.interSoldadura.valor ? estadoDeValidacion.interSoldadura.valor : null,
                            "posicionHoraria": estadoDeValidacion.posicionHoraria.valor ? estadoDeValidacion.posicionHoraria.valor : null,
                            "distanciaCAALosHombrosH1": estadoDeValidacion.distanciaCAALosHombrosH1.valor ? estadoDeValidacion.distanciaCAALosHombrosH1.valor : null,
                            "distanciaCAALosHombrosH2": estadoDeValidacion.distanciaCAALosHombrosH2.valor ? estadoDeValidacion.distanciaCAALosHombrosH2.valor : null,
                            "distanciaCAALosHombrosH3": estadoDeValidacion.distanciaCAALosHombrosH3.valor ? estadoDeValidacion.distanciaCAALosHombrosH3.valor : null,
                            "distanciaCAALosHombrosH4": estadoDeValidacion.distanciaCAALosHombrosH4.valor ? estadoDeValidacion.distanciaCAALosHombrosH4.valor : null,
                            "localizaciónDeHombrosH1Distancia": estadoDeValidacion.localizaciónDeHombrosH1Distancia.valor ? estadoDeValidacion.localizaciónDeHombrosH1Distancia.valor : null,
                            "localizaciónDeHombrosH2Distancia": estadoDeValidacion.localizaciónDeHombrosH2Distancia.valor ? estadoDeValidacion.localizaciónDeHombrosH2Distancia.valor : null,
                            "localizaciónDeHombrosH3Distancia": estadoDeValidacion.localizaciónDeHombrosH3Distancia.valor ? estadoDeValidacion.localizaciónDeHombrosH3Distancia.valor : null,
                            "localizaciónDeHombrosH4Distancia": estadoDeValidacion.localizaciónDeHombrosH4Distancia.valor ? estadoDeValidacion.localizaciónDeHombrosH4Distancia.valor : null,
                            "localizaciónDeHombrosCADistancia": estadoDeValidacion.localizaciónDeHombrosCADistancia.valor ? estadoDeValidacion.localizaciónDeHombrosCADistancia.valor : null,
                            "tipoDeAnomaliaInteraccion": estadoDeValidacion.tipoDeAnomaliaInteraccion.valor ? estadoDeValidacion.tipoDeAnomaliaInteraccion.valor : null,
                            "distanciaRegistroInteraccion": estadoDeValidacion.distanciaRegistroInteraccion.valor ? estadoDeValidacion.distanciaRegistroInteraccion.valor : null,
                            "distaciaALas12": estadoDeValidacion.distaciaALas12.valor ? estadoDeValidacion.distaciaALas12.valor : null,
                            "longitudInterracion": estadoDeValidacion.longitudInterracion.valor ? estadoDeValidacion.longitudInterracion.valor : null,
                            "anchoInterracion": estadoDeValidacion.anchoInterracion.valor ? estadoDeValidacion.anchoInterracion.valor : null,
                            "profundidadInterracion": estadoDeValidacion.profundidadInterracion.valor ? estadoDeValidacion.profundidadInterracion.valor : null,
                        }
                    }
                case 'GRIETA':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "distanciaRegistroGrietaMed": estadoDeValidacion.distanciaRegistroGrietaMed.valor ? estadoDeValidacion.distanciaRegistroGrietaMed.valor : null,
                            "distanciaJuntaAnteriorGrietaMed": estadoDeValidacion.distanciaJuntaAnteriorGrietaMed.valor ? estadoDeValidacion.distanciaJuntaAnteriorGrietaMed.valor : null,
                            "distanciaJuntaPosteriorGrietaMed": estadoDeValidacion.distanciaJuntaPosteriorGrietaMed.valor ? estadoDeValidacion.distanciaJuntaPosteriorGrietaMed.valor : null,
                            "distanciaJuntaLongitudinalGrietaMed": estadoDeValidacion.distanciaJuntaLongitudinalGrietaMed.valor ? estadoDeValidacion.distanciaJuntaLongitudinalGrietaMed.valor : null,
                            "distanciaALas12GrietaMed": estadoDeValidacion.distanciaALas12GrietaMed.valor ? estadoDeValidacion.distanciaALas12GrietaMed.valor : null,
                            "longitud2CGrietaMed": estadoDeValidacion.longitud2CGrietaMed.valor ? estadoDeValidacion.longitud2CGrietaMed.valor : null,
                            "longitud2AGrietaMed": estadoDeValidacion.longitud2AGrietaMed.valor ? estadoDeValidacion.longitud2AGrietaMed.valor : null,
                            "profundidadDGrietaMed": estadoDeValidacion.profundidadDGrietaMed.valor ? estadoDeValidacion.profundidadDGrietaMed.valor : null,
                            "anguloDeInclinacionGrietaMed": estadoDeValidacion.anguloDeInclinacionGrietaMed.valor ? estadoDeValidacion.anguloDeInclinacionGrietaMed.valor : null,
                            "espesorTuboGrietaMed": estadoDeValidacion.espesorTuboGrietaMed.valor ? estadoDeValidacion.espesorTuboGrietaMed.valor : null,
                            "tipoDeGrietaMed": estadoDeValidacion.tipoDeGrietaMed.valor ? estadoDeValidacion.tipoDeGrietaMed.valor : null,
                            "distanciaRegistroGrietaUTCD": estadoDeValidacion.distanciaRegistroGrietaUTCD.valor ? estadoDeValidacion.distanciaRegistroGrietaUTCD.valor : null,
                            "distanciaJuntaAnteriorGrietaUTCD": estadoDeValidacion.distanciaJuntaAnteriorGrietaUTCD.valor ? estadoDeValidacion.distanciaJuntaAnteriorGrietaUTCD.valor : null,
                            "distanciaJuntaPosteriorGrietaUTCD": estadoDeValidacion.distanciaJuntaPosteriorGrietaUTCD.valor ? estadoDeValidacion.distanciaJuntaPosteriorGrietaUTCD.valor : null,
                            "distanciaJuntaLongitudinalGrietaUTCD": estadoDeValidacion.distanciaJuntaLongitudinalGrietaUTCD.valor ? estadoDeValidacion.distanciaJuntaLongitudinalGrietaUTCD.valor : null,
                            "posicionHorariaGrietaUTCD": estadoDeValidacion.posicionHorariaGrietaUTCD.valor ? estadoDeValidacion.posicionHorariaGrietaUTCD.valor : null,
                            "longitudGrietaUTCD": estadoDeValidacion.longitudGrietaUTCD.valor ? estadoDeValidacion.longitudGrietaUTCD.valor : null,
                            "profundidadDGrietaUTCD": estadoDeValidacion.profundidadDGrietaUTCD.valor ? estadoDeValidacion.profundidadDGrietaUTCD.valor : null,
                            "anguloDeInclinacionGrietaUTCD": estadoDeValidacion.anguloDeInclinacionGrietaUTCD.valor ? estadoDeValidacion.anguloDeInclinacionGrietaUTCD.valor : null,
                            "espesorTuboGrietaUTCD": estadoDeValidacion.espesorTuboGrietaUTCD.valor ? estadoDeValidacion.espesorTuboGrietaUTCD.valor : null,
                            "tipoDeGrietaUTCD": estadoDeValidacion.tipoDeGrietaUTCD.valor ? estadoDeValidacion.tipoDeGrietaUTCD.valor : null,
                        }
                    }
                case 'ENSAYO_GRIETA':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "distanciaRegistroEnsayoGrieta": estadoDeValidacion.distanciaRegistroEnsayoGrieta.valor ? estadoDeValidacion.distanciaRegistroEnsayoGrieta.valor : null,
                            "distanciaALas12EnsayoGrieta": estadoDeValidacion.distanciaALas12EnsayoGrieta.valor ? estadoDeValidacion.distanciaALas12EnsayoGrieta.valor : null,
                            "longitudEnsayoGrieta": estadoDeValidacion.longitudEnsayoGrieta.valor ? estadoDeValidacion.longitudEnsayoGrieta.valor : null,
                            "profundidadInicialEnsayoGrieta": estadoDeValidacion.profundidadInicialEnsayoGrieta.valor ? estadoDeValidacion.profundidadInicialEnsayoGrieta.valor : null,
                            "profundidadFinalEnsayoGrieta": estadoDeValidacion.profundidadFinalEnsayoGrieta.valor ? estadoDeValidacion.profundidadFinalEnsayoGrieta.valor : null,
                            "naturalezaIndicacionEnsayoGrieta": estadoDeValidacion.naturalezaIndicacionEnsayoGrieta.valor ? estadoDeValidacion.naturalezaIndicacionEnsayoGrieta.valor : null,
                            "estadoEnsayoGrieta": estadoDeValidacion.estadoEnsayoGrieta.valor ? estadoDeValidacion.estadoEnsayoGrieta.valor : null,
                            "comentariosEnsayoGrieta": estadoDeValidacion.comentariosEnsayoGrieta.valor ? estadoDeValidacion.comentariosEnsayoGrieta.valor : null,
                        }
                    }
                case 'CAMISA':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "espesorTuberia": estadoDeValidacion.espesorTuberia.valor ? estadoDeValidacion.espesorTuberia.valor : null,
                            "espesorCamisa": estadoDeValidacion.espesorCamisa.valor ? estadoDeValidacion.espesorCamisa.valor : null,
                            "longitudCamisa": estadoDeValidacion.longitudCamisa.valor ? estadoDeValidacion.longitudCamisa.valor : null,
                            "condicionVT": estadoDeValidacion.condicionVT.valor ? estadoDeValidacion.condicionVT.valor : null,
                            "recomendacionVT": estadoDeValidacion.recomendacionVT.valor ? estadoDeValidacion.recomendacionVT.valor : null,
                            "condicionMT": estadoDeValidacion.condicionMT.valor ? estadoDeValidacion.condicionMT.valor : null,
                            "recomendacionMT": estadoDeValidacion.recomendacionMT.valor ? estadoDeValidacion.recomendacionMT.valor : null,
                            "condicionPAUT": estadoDeValidacion.condicionPAUT.valor ? estadoDeValidacion.condicionPAUT.valor : null,
                            "recomendacionPAUT": estadoDeValidacion.recomendacionPAUT.valor ? estadoDeValidacion.recomendacionPAUT.valor : null,
                            "condicionEspesores": estadoDeValidacion.condicionEspesores.valor ? estadoDeValidacion.condicionEspesores.valor : null,
                            "recomendacionEspesores": estadoDeValidacion.recomendacionEspesores.valor ? estadoDeValidacion.recomendacionEspesores.valor : null,
                            "condicionOtros": estadoDeValidacion.condicionOtros.valor ? estadoDeValidacion.condicionOtros.valor : null,
                            "recomendacionOtros": estadoDeValidacion.recomendacionOtros.valor ? estadoDeValidacion.recomendacionOtros.valor : null,


                        }
                    }
                case 'CAMISA_SOBRE_ANILLOS':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "espesorTuberia": estadoDeValidacion.espesorTuberia.valor ? estadoDeValidacion.espesorTuberia.valor : null,
                            "espesorAnillo1": estadoDeValidacion.espesorAnillo1.valor ? estadoDeValidacion.espesorAnillo1.valor : null,
                            "espesorAnillo2": estadoDeValidacion.espesorAnillo2.valor ? estadoDeValidacion.espesorAnillo2.valor : null,
                            "longitudEntreAnillos": estadoDeValidacion.longitudEntreAnillos.valor ? estadoDeValidacion.longitudEntreAnillos.valor : null,
                            "condicionVT": estadoDeValidacion.condicionVT.valor ? estadoDeValidacion.condicionVT.valor : null,
                            "recomendacionVT": estadoDeValidacion.recomendacionVT.valor ? estadoDeValidacion.recomendacionVT.valor : null,
                            "condicionMT": estadoDeValidacion.condicionMT.valor ? estadoDeValidacion.condicionMT.valor : null,
                            "recomendacionMT": estadoDeValidacion.recomendacionMT.valor ? estadoDeValidacion.recomendacionMT.valor : null,
                            "condicionPAUT": estadoDeValidacion.condicionPAUT.valor ? estadoDeValidacion.condicionPAUT.valor : null,
                            "recomendacionPAUT": estadoDeValidacion.recomendacionPAUT.valor ? estadoDeValidacion.recomendacionPAUT.valor : null,
                            "condicionEspesores": estadoDeValidacion.condicionEspesores.valor ? estadoDeValidacion.condicionEspesores.valor : null,
                            "recomendacionEspesores": estadoDeValidacion.recomendacionEspesores.valor ? estadoDeValidacion.recomendacionEspesores.valor : null,
                            "condicionOtros": estadoDeValidacion.condicionOtros.valor ? estadoDeValidacion.condicionOtros.valor : null,
                            "recomendacionOtros": estadoDeValidacion.recomendacionOtros.valor ? estadoDeValidacion.recomendacionOtros.valor : null,


                        }
                    }
                case 'PARCHE':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "espesorTuberia": estadoDeValidacion.espesorTuberia.valor ? estadoDeValidacion.espesorTuberia.valor : null,
                            "espesorParche": estadoDeValidacion.espesorParche.valor ? estadoDeValidacion.espesorParche.valor : null,
                            "diametroParche": estadoDeValidacion.diametroParche.valor ? estadoDeValidacion.diametroParche.valor : null,
                            "condicionVT": estadoDeValidacion.condicionVT.valor ? estadoDeValidacion.condicionVT.valor : null,
                            "recomendacionVT": estadoDeValidacion.recomendacionVT.valor ? estadoDeValidacion.recomendacionVT.valor : null,
                            "condicionMT": estadoDeValidacion.condicionMT.valor ? estadoDeValidacion.condicionMT.valor : null,
                            "recomendacionMT": estadoDeValidacion.recomendacionMT.valor ? estadoDeValidacion.recomendacionMT.valor : null,
                            "condicionPAUT": estadoDeValidacion.condicionPAUT.valor ? estadoDeValidacion.condicionPAUT.valor : null,
                            "recomendacionPAUT": estadoDeValidacion.recomendacionPAUT.valor ? estadoDeValidacion.recomendacionPAUT.valor : null,
                            "condicionEspesores": estadoDeValidacion.condicionEspesores.valor ? estadoDeValidacion.condicionEspesores.valor : null,
                            "recomendacionEspesores": estadoDeValidacion.recomendacionEspesores.valor ? estadoDeValidacion.recomendacionEspesores.valor : null,
                            "condicionOtros": estadoDeValidacion.condicionOtros.valor ? estadoDeValidacion.condicionOtros.valor : null,
                            "recomendacionOtros": estadoDeValidacion.recomendacionOtros.valor ? estadoDeValidacion.recomendacionOtros.valor : null,

                        }
                    }
                case 'SUBANOMALIA':
                    return {
                        "anomalia": {
                            "id": this.props.idAnomalia,
                            "idSubAnomalia": this.props.idSubAnomalia,
                            "tipoDeAnomalia": this.props.seccion,
                            "numeroDeFormato": this.props.ordenDeTrabajo,
                            "largoAnomaliaCamisa": estadoDeValidacion.largoAnomaliaCamisa.valor ? estadoDeValidacion.largoAnomaliaCamisa.valor : null,
                            "anchoAnomaliaCamisa": estadoDeValidacion.anchoAnomaliaCamisa.valor ? estadoDeValidacion.anchoAnomaliaCamisa.valor : null,
                            "posicionHorariaAnomalia": estadoDeValidacion.posicionHorariaAnomalia.valor ? estadoDeValidacion.posicionHorariaAnomalia.valor : null,
                            "ubicacionEnLaReparacion": estadoDeValidacion.ubicacionEnLaReparacion.valor ? estadoDeValidacion.ubicacionEnLaReparacion.valor : null,
                            "conceptoAnomalia": estadoDeValidacion.conceptoAnomalia.valor ? estadoDeValidacion.conceptoAnomalia.valor : null,
                            "ensayoReparacion": estadoDeValidacion.ensayoReparacion.valor ? estadoDeValidacion.ensayoReparacion.valor : null,
                        }
                    }
                default:
                    break;
            }
        }
        
        
    }

    generarEstadoDeValidacioninicial = () =>{
        let estadoDeValidacionInicial = {};
        let listadoDeVariables = []
        //const listadoDeVariables = [...Object.keys(glosarioEdicionAnomaliaPM1), ...Object.keys(glosarioEdicionAnomaliaPM2)];
        if (this.state.glosario2){
            listadoDeVariables = [...Object.keys(this.state.glosario1), ...Object.keys(this.state.glosario2)];    
        }
        else {
            listadoDeVariables = [...Object.keys(this.state.glosario1)];
        }
        //const listadoDeVariables = [...Object.keys(this.state.glosario1), ...(this.state.glosario2 ? Object.keys(this.state.glosario2) : null)];
        
        listadoDeVariables.forEach(variable =>{
            this.returnValorInicial(variable)
            estadoDeValidacionInicial[variable] = {}
            //estadoDeValidacionInicial[variable]['estado'] = false;
            estadoDeValidacionInicial[variable]['mensaje'] = false;
            //estadoDeValidacionInicial[variable]['valor'] = '';
            estadoDeValidacionInicial[variable]['estado'] = this.returnValorInicial(variable) ? true : false;
            estadoDeValidacionInicial[variable]['valor'] = this.returnValorInicial(variable);
            
            //Fecha ya posee un valor inicial válido
            if(variable === 'fechaEstimadaDeProgramacion'){
                estadoDeValidacionInicial[variable]['estado'] = true;
                estadoDeValidacionInicial[variable]['valor'] = new Date();
            }
        })
       
        
        return estadoDeValidacionInicial;
    }

    obtenerEstadoDeFormulario = () =>{
        //const estadoDeValidacion = this.state.estadoDeValidacion;
        //return Object.keys(estadoDeValidacion).every(key => estadoDeValidacion[key].estado);
        return true
    }

    returnValorInicial = (input) => {
        let anomaliaSelected = undefined
        if (this.props.subAnomalia)
        {
            switch (this.props.seccion) {
                case 'CAMISA':
                case 'CAMISA_SOBRE_ANILLOS':
                case 'PARCHE':
                    if (this.props.anomaliasCamisas.length) {
                        anomaliaSelected = this.props.anomaliasCamisas.filter(anomalia => (anomalia.idDeAnomalia === this.props.idAnomalia))
                        let subAnomalia = anomaliaSelected[0]['listadoDeSubAnomalias'].filter(anomalia => anomalia.idDeSubAnomalia === this.props.idSubAnomalia)
                        if (input === 'idDeSubAnomalia') {
                            return subAnomalia[0][input]
                        }
                        else {
                            return subAnomalia[0]['detalle'][input]
                        }
                    }
                    else return ''

                default:
                    break;
            }
        }
        else {
            switch (this.props.seccion) {
                case 'PERDIDA_DE_METAL':
                    let listaAnomalia = ['evaluacionOComparacion', 'id', 'programadaOAdiccional', 'tipoDeAnomalia']
                    let listaDatosIli = ['anchoAnomialia', 'distanciaAlRegistro', 'evento', 'idAnomaliaILI', 'longitudAnomialia', 'paredInterna', 'porcentajeReduccionEspesor', 'posicionDelDuctoILI',]
                    let listaDatosInspeccion = ['distanciaAlRegistroMedicion', 'anchoAnomaliaMedicion', 'clasificacionAnomalia', 'distanciaJuntaAnterior', 'distanciaJuntaPosterior', 'interactuaConJunta', 'longitudAnomaliaMedicion', 'paredInternaMedicion', 'porcentajeReduccionEspesorMedicion', 'posicionDelDuctoILIHoraMedicion', 'profundidad', 'remanente', 'tipoAnomalia', 'posicionDelDuctoILIDistanciaMedicion']
                    let listaEvalASME = ['ERFs', 'evaluacionDeLaAnomalia', 'presionSegura']
                    if (this.props.informacionOT.length) {
                        anomaliaSelected = this.props.informacionOT.filter(anomalia => anomalia.anomalia.id === this.props.idAnomalia)
                        if (listaAnomalia.includes(input)) {
                            return anomaliaSelected[0]['anomalia'][input]
                        }
                        else if (listaDatosIli.includes(input)) {
                            return anomaliaSelected[0]['datosSuministradosCorridaILI'][input]
                        }
                        else if (listaDatosInspeccion.includes(input)) {
                            return anomaliaSelected[0]['datosSuministradosPorMedicion'][input]
                        }
                        else if (listaEvalASME.includes(input)) {
                            return anomaliaSelected[0]['evaluacionASME'][input]
                        }
                        else return ''
                    }
                    else return ''
                case 'ABOLLADURA':
                    if (this.props.informacionOT.length) {
                        anomaliaSelected = this.props.anomaliasAbolladuras.filter(anomalia => anomalia.idDeAnomalia === this.props.idAnomalia)
                        return anomaliaSelected[0][input]
                    }
                    else return ''
                case 'GRIETA':
                    if (this.props.anomaliasGrietas.length) {
                        anomaliaSelected = this.props.anomaliasGrietas.filter(anomalia => anomalia.idDeAnomalia === this.props.idAnomalia)
                        if (input === 'idDeAnomalia') {
                            return anomaliaSelected[0][input]
                        }
                        else { return anomaliaSelected[0]['detalle'][input] }
                    }
                    else return ''
                case 'ENSAYO_GRIETA':
                    if (this.props.ensayosGrietas.length) {
                        anomaliaSelected = this.props.ensayosGrietas.filter(anomalia => anomalia.idDeEnsayo === this.props.idAnomalia)
                        if (input === 'idDeEnsayo') {
                            return anomaliaSelected[0][input]
                        }
                        else { return anomaliaSelected[0]['detalle'][input] }
                    }
                    else return ''
                case 'CAMISA':
                case 'CAMISA_SOBRE_ANILLOS':
                case 'PARCHE':
                    if (this.props.anomaliasCamisas.length) {
                        anomaliaSelected = this.props.anomaliasCamisas.filter(anomalia => (anomalia.idDeAnomalia === this.props.idAnomalia && anomalia.tipoDeAnomalia === this.props.seccion))
                        if (input === 'idDeAnomalia') {
                            return anomaliaSelected[0][input]
                        }
                        else { return anomaliaSelected[0]['detalle'][input] }
                    }
                    else return ''
                default:
                    return '';
            }
        }
    }

    returnCloudFunction = (nuevaOrdenDeTrabajo) => {
        if (this.props.subAnomalia) {
            switch (this.props.seccion) {
                case 'CAMISA':
                case 'PARCHE':
                case 'CAMISA_SOBRE_ANILLOS':
                    return editarSubAnomalias(nuevaOrdenDeTrabajo)
                default:
                    return editarSubAnomalias(nuevaOrdenDeTrabajo)
            }
        }
        else {
            switch (this.props.seccion) {
                case 'PERDIDA_DE_METAL':
                case 'ABOLLADURA':
                case 'CAMISA':
                case 'GRIETA':
                case 'PARCHE':
                case 'CAMISA_SOBRE_ANILLOS':
                    return editarAnomalias(nuevaOrdenDeTrabajo)
                default:
                    return editarAnomalias(nuevaOrdenDeTrabajo)
            }
        }
    }

    submitDelFormulario = (evento) => {
        evento.preventDefault();
        this.setState({
            cargando: true
        })
        
        const nuevaOrdenDeTrabajo = this.estructuraParaGuardado();
        this.returnCloudFunction(nuevaOrdenDeTrabajo)
        .then(result =>{
            switch(result.data.status){
                case "ANOMALIA_ACTUALIZADA":
                    this.setState({ cargando: false },()=>{
                        this.avisoDeGuardadoSatisfactorio('¡La anomalía se actualizó satisfactoriamente!');
                        setTimeout(()=>{ this.props.cerrarModal() }, 100);
                    })
                break;
                case "ANOMALIA_NO_EXISTE":
                    this.setState({ cargando: false }, ()=>{
                        this.avisoDeGuardadoFallido('La anomalía no existe');
                    })
                break;
                default:
                    console.log('ocurrio un error al crear la orden de trabajo');
                break;
            }
        })
        .catch(e =>{
            console.log('errores al realizar guardado: ', e);
        })
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorFormularioEdicionAnomalias);