import { connect } from 'react-redux'
import { firestoreDB, firebaseFunctions } from "../../../../firebase";
import React, { Suspense } from "react";

//Iconos
import { AiOutlineFilePdf } from "react-icons/ai";
import { BsFillChatQuoteFill } from "react-icons/bs";
import { FaCloudDownloadAlt, FaPhotoVideo, FaList } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";

//Componentes Genéricos
import BotonDeCardConEncabezado from "../../../../utilidades/componentes/plantillaBase/BotonDeCardConEncabezado";
import BotonParaSection from "../../../../utilidades/componentes/plantillaBase/BotonParaSection";
import CardConEncabezado from "../../../../utilidades/componentes/plantillaBase/CardConEncabezado";
import CustomModal from '../../../../utilidades/componentes/plantillaBase/CustomModal';
import CustomSection from "../../../../utilidades/componentes/plantillaBase/CustomSection";
import Preloader from "../../../../utilidades/componentes/plantillaBase/Preloader";

//Componentes Específicos
import CaracterizacionDeAbolladuras from "./components/perdidaDeMetal/CaracterizacionDeAbolladuras";
import CaracterizacionDeGrietas from "./components/grietas/CaracterizacionDeGrietas";
import CaracterizacionDeBS from "./components/bendingStrain/CaracterizacionBS";
import CaracterizacionDeCamisas from "./components/camisas/CaractrerizacionDeCamisas";
import ComparacionDeDatos from "./components/perdidaDeMetal/ComparacionDeDatos";
import Grid from "./components/perdidaDeMetal/Grid";
import ConsolaDeDescarga from "./components/ConsolaDeDescarga";
import Observaciones from "./components/ili/Observaciones";
import DatosDeCampo from "./components/ili/DatosDeCampo";
import DatosDeLaExcavacion from "./components/ili/DatosDeLaExcavacion";
import DetalleDeAtenciones from "./components/reparaciones/DetalleDeAtenciones";
import DetalleDeReparaciones from "./components/reparaciones/DetalleDeReparaciones";
import EstadoDeLaInspeccion from "./components/ili/EstadoDeLaInspeccion";
import GaleriaEnsayosNoDestructivos from "./components/GaleriaEnsayosNoDestructivos";
/* import GaleriaFotografica from "./components/GaleriaFotografica"; */
import GaleriaLocalizacionILI from "./components/ili/GaleriaLocalizacionILI";
import IdentificacionAnomaliaILI from "./components/ili/IdentificacionAnomaliaILI";
import IdentificacionDelDucto from "./components/ili/IdentificacionDelDucto";
import ModalDeChat from "./components/chat/ModalDeChat";
import UbicacionGeoGrafica from "./components/ili/UbicacionGeografica";

//Scripts Específicos
import { botonesSegunRol } from './scripts/botonesSegunRol'
import obtenerDatosDeAnomalias from './scripts/obtenerDatosDeAnomalias';

//Vistas
import Error404 from "../../../404";
import VisualizadorFormularioDeILI from "../VisualizadorFormularioDeILI";
import VisualizadorFormularioDeAnomalias from "../VisualizadorFormularioDeAnomalias";


let exportarInspeccion = firebaseFunctions.httpsCallable('exportarInspeccion');
//let exportarInspeccion = firebaseFunctions('us-central1').httpsCallable('exportarInspeccion');
const GaleriaFotografica = React.lazy(() => import('./components/GaleriaFotografica'));

class VisualizadorDeFormatoDeInspeccion extends React.Component {

    constructor() {
        super();
        this.unsuscribeAnomalias = undefined;
        this.unsuscribeOdt = undefined;
        this.unsuscribeAnexos = undefined;
        this.unsuscribeInspeccion = undefined;
        this.state = {
            anomaliaParaEdicion: undefined,
            subAnomaliaParaEdicion: undefined,
            cargando: false,
            error: false,
            infoError: undefined,
            estadoDelRegistro: undefined,
            keysDelRegistro: undefined,
            identificacionILI: undefined,
            anomalias: undefined,
            espesores: undefined,
            distanciaCaALosHombros: undefined,
            localizacionDeHombros: undefined,
            anomaliasAbolladuras: undefined,
            galeriaDeEnsayosNoDestructivos: undefined,
            fotosGaleria2: undefined,
            detalleILI: undefined,
            subAnomalia:false,

            detalleDeReparacionesMecanicas: undefined,
            reparacionPorCamisas: undefined,
            reparacionPorCorte: undefined,

            elChatEstaAbierto: false,
            laConsolaDeDescartaEstaAbierta: false,

            anomaliasCamisas: undefined,
            anomaliasBS: undefined,

            estadoDelNodo: false,
            contatorDeRender: 0,

            verificarSubAnomalias: true,

            modalInformacionILI: false,
            modalLocalizacionILI: false,
            modalUbicacionILI: false,
            modalExcavacionILI: false,
            modalGeoILI: false,
            modalEdicionAnomalias: false,
            nmodalGrid: false,
        };
    }

    render() {
        if (!this.state.error) {
            return (
                <React.Fragment>
                    <CustomSection
                        botonesAdicionales={[this.obtenerBotonExportacion(), this.obtenerBotonDeChat()]}
                        subtitulo={this.props.match.params.id}
                        titulo={"Detalle de Órden de Trabajo"}
                        URLIconoPrincipal="/assets/img/logos/giaCore-logo.png">
                        <CardConEncabezado
                            icono={FaList}
                            titulo={"Información general de la orden de trabajo"}
                            botonesAuxiliares={this.generarBotonesSegunRol()}
                        >
                            {this.pintarEstadoDeLaInspección()}
                        </CardConEncabezado>

                        {this.renderCardDeDatosDeInspeccion()}

                        {this.renderCardAnomalias()}

                        {this.pintarReparacionesMecanicas()}
                        {this.state.fotosGaleria2 ? this.pintarGaleria() : <React.Fragment />}
                        {
                            this.state.galeriaDeEnsayosNoDestructivos !== undefined && Object.keys(this.state.galeriaDeEnsayosNoDestructivos).length >= 1 ?
                                <CardConEncabezado
                                    icono={FaPhotoVideo}
                                    titulo={"Galería de ensayos no destructivos"}>
                                    <GaleriaEnsayosNoDestructivos
                                        idOrdenDeTrabajo={this.props.match.params.id}
                                        galeriaDeEnsayosNoDestructivos={this.state.galeriaDeEnsayosNoDestructivos} />
                                </CardConEncabezado>
                                : <React.Fragment />
                        }
                    </CustomSection>

                    <ConsolaDeDescarga
                        alertExitoso={this.dispararAlertExitoso}
                        alertFallido={this.dispararAlertFallido}
                        elModalEstaAbierto={this.state.laConsolaDeDescartaEstaAbierta}
                        toggleDelModal={this.toggleDeConsolaDeDescarga}
                        ordenDeInspeccion={this.props.match.params.id}
                    />

                    <ModalDeChat
                        elModalEstaAbierto={this.state.elChatEstaAbierto}
                        idOrdenDeTrabajo={this.props.match.params.id}
                        toggleDelModal={this.toggleDelChat}
                        usuario={this.state.estadoDelRegistro ? this.state.estadoDelRegistro.responsable : null}
                    />
                    <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                </React.Fragment>
            );
        }
        else {
            return (
                <Error404
                    error={this.props.location.pathname} />
            );
        }

    }


    componentDidCatch(error, info) {
        this.setState({ error: true, infoError: info });
    }

    componentDidMount() {
        this.traerNodo();
    }

    componentWillUnmount() {
        this.unsuscribeAnexos();
        this.unsuscribeAnomalias();
        this.unsuscribeInspeccion();
        this.unsuscribeOdt();
    }

    componentDidUpdate(prevenProps, prevenState) {
        if (prevenProps !== this.props) {
            this.traerNodo();
        }
    }

    //Métodos operativos:
    traerNodo = () => {
        const id = this.props.match.params.id;
        this.unsuscribeInspeccion = firestoreDB.collection('ordenesDeTrabajo').doc(id).collection('registrosDeInspeccion').onSnapshot(
            (snapShots) => {
                if (!snapShots.empty) {
                    let registros = {};
                    snapShots.forEach(document => {
                        registros[document.id] = document.data();
                    });
                    this.traerDatosDeRegistro(registros)
                }
                else {
                    this.setState({
                        existeOT: false,
                        error: true,
                    })
                }
            }, (e) => {
                console.log('error: ', e);
            });

        let anomalias = {};
        this.unsuscribeAnomalias = firestoreDB.collection('ordenesDeTrabajo').doc(id).collection('anomalias').onSnapshot(
            (snapShots) => {
                if (!snapShots.empty) {

                    snapShots.forEach(document => {
                        const data = document.data();
                        anomalias[document.id] = data;
                        this.setState({
                            anomalias: anomalias,
                            contatorDeRender: this.contatorDeRender + 1
                        })
                    });
                    this.traerDatosDeAnomalias(anomalias, id);
                }
            });

        this.unsuscribeOdt = firestoreDB.doc(`ordenesDeTrabajo/${id}`).onSnapshot((snapshot) => {
            if (snapshot.exists) {
                this.setState({
                    estadoDelRegistro: { ...snapshot.data() },
                    contatorDeRender: this.contatorDeRender + 1
                });
            }

        });
        this.unsuscribeAnexos = firestoreDB.doc(`ordenesDeTrabajo/${id}/registrosDeInspeccion/anexos`).onSnapshot(
            snapShot => {
                if (snapShot.exists) {
                    this.setState({
                        galeriaDeEnsayosNoDestructivos: snapShot.data().ensayosNoDestructivos,
                        contatorDeRender: this.contatorDeRender + 1
                    })
                }
            }
        );
    }

    toggleCargando = () => {this.setState({cargando:!this.state.cargando})}

    toggleDelChat = () => {
        this.setState({ elChatEstaAbierto: !this.state.elChatEstaAbierto });
    }

    toggleDeConsolaDeDescarga = () => {
        this.setState({ laConsolaDeDescartaEstaAbierta: !this.state.laConsolaDeDescartaEstaAbierta });
    }

    disableBotonEditar = () => { 
        const rolesPermitidos = ["admin", "supervisor", "control_calidad", "programador_contrato", "tecnico"]
        return rolesPermitidos.includes(this.props.usuarioActivo.rol)
    }

    generarBotonesSegunRol = () => {
        let botones = [];
        let roles = Object.keys(botonesSegunRol);
        const id = this.props.match.params.id;
        for (let i = 0; i < roles.length; i++) {
            let formato = Object.keys(botonesSegunRol[roles[i]])
            for (let j = 0; j < formato.length; j++) {
                let contenidoDelBoton = botonesSegunRol[roles[i]][formato[j]]
                if (this.props.usuarioActivo.administradores.includes(this.props.usuarioActivo.email)) {
                    if (contenidoDelBoton.rol === 'administrador') {
                        switch (contenidoDelBoton.parametroDeLaFuncion) {
                            case 'PDF':
                                botones.push(
                                    <BotonDeCardConEncabezado key={contenidoDelBoton.key}
                                        icono={AiOutlineFilePdf}
                                        title={contenidoDelBoton.titulo}
                                        onClick={() => {
                                            this.setState({ cargando: true })
                                            exportarInspeccion({
                                                'parametroDeLaFuncion': contenidoDelBoton.parametroDeLaFuncion,
                                                'ordenDeInspeccion': id,
                                            })
                                                .then(response => {
                                                    this.setState({ cargando: false })
                                                    window.open(response.data.download_url);
                                                })
                                                .catch(error => console.log(error))
                                        }} />
                                )

                                break;
                            case 'EXCEL':
                                botones.push(
                                    <BotonDeCardConEncabezado key={contenidoDelBoton.key}
                                        icono={RiFileExcel2Line}
                                        title={contenidoDelBoton.titulo}
                                        onClick={() => {
                                            this.setState({ cargando: true })
                                            exportarInspeccion({
                                                'parametroDeLaFuncion': contenidoDelBoton.parametroDeLaFuncion,
                                                'ordenDeInspeccion': id,
                                                'registroDeInspeccion': 'ili'
                                            })
                                                .then(response => {
                                                    this.setState({ cargando: false })
                                                    window.open(response.data.download_url);
                                                })
                                                .catch(error => console.log(error))
                                        }} />
                                )
                                break;
                            default:
                                console.log('error')
                        }
                    }
                }
                else if (this.props.usuarioActivo.clientes.includes(this.props.usuarioActivo.email)) {
                    if (contenidoDelBoton.rol === 'cliente') {
                        botones.push(
                            <BotonDeCardConEncabezado key={contenidoDelBoton.key}
                                icono={AiOutlineFilePdf}
                                title={contenidoDelBoton.titulo}
                                onClick={() => {
                                    this.setState({ cargando: true })
                                    exportarInspeccion({
                                        'parametroDeLaFuncion': contenidoDelBoton.parametroDeLaFuncion,
                                        'ordenDeInspeccion': id,
                                        'registroDeInspeccion': 'ili'
                                    })
                                        .then(response => {
                                            this.setState({ cargando: false })
                                            window.open(response.data.download_url);
                                        })
                                        .catch(error => console.log(error))
                                }} />
                        )
                    }
                }
            }
        }
        return botones
    }
    //Métodos para renderizado de componentes hijos:
    traerDatosDeRegistro = (registros) => {
        let keysDelRegistro = {};
        let identificacionILI = {};
        let nuevoEstado = {};
        let espesores = [];
        let fotosGaleria2 = [];

        const datosDelNodo = registros['ili'];
        const datosDeReparaciones = registros['reparacionesMecanicas'];
        const datosRegistroFotografico = registros['registroFotografico'];
        if (datosRegistroFotografico && datosRegistroFotografico.detalle) {
            try {
                datosRegistroFotografico.detalle.map((img, index) => {
                    return fotosGaleria2.push(img)
                })
            } catch (error) { console.log(error) }
        }

        if (datosDelNodo.registro) {
            keysDelRegistro = { ...datosDelNodo.registro };
            identificacionILI = { ...datosDelNodo.registro };
            espesores.push({
                MM: "3:00",
                Z1: datosDelNodo.registro["espesorZ1_3"],
                Z2: datosDelNodo.registro["espesorZ2_3"],
                Z3: datosDelNodo.registro["espesorZ3_3"],
                Z4: datosDelNodo.registro["espesorZ4_3"],
                Z5: datosDelNodo.registro["espesorZ5_3"],
            },
                {
                    MM: "6:00",
                    Z1: datosDelNodo.registro["espesorZ1_6"],
                    Z2: datosDelNodo.registro["espesorZ2_6"],
                    Z3: datosDelNodo.registro["espesorZ3_6"],
                    Z4: datosDelNodo.registro["espesorZ4_6"],
                    Z5: datosDelNodo.registro["espesorZ5_6"],

                },
                {
                    MM: "9:00",
                    Z1: datosDelNodo.registro["espesorZ1_9"],
                    Z2: datosDelNodo.registro["espesorZ2_9"],
                    Z3: datosDelNodo.registro["espesorZ3_9"],
                    Z4: datosDelNodo.registro["espesorZ4_9"],
                    Z5: datosDelNodo.registro["espesorZ5_9"],
                },
                {
                    MM: "12:00",
                    Z1: datosDelNodo.registro["espesorZ1_12"],
                    Z2: datosDelNodo.registro["espesorZ2_12"],
                    Z3: datosDelNodo.registro["espesorZ3_12"],
                    Z4: datosDelNodo.registro["espesorZ4_12"],
                    Z5: datosDelNodo.registro["espesorZ5_12"],
                });

            nuevoEstado["identificacionILI"] = identificacionILI
            nuevoEstado["espesores"] = espesores;
            nuevoEstado["keysDelRegistro"] = keysDelRegistro;

            // if (!this.state.keysDelRegistro) {
            //     nuevoEstado.keysDelRegistro = keysDelRegistro
            // }
        }

        if (datosDeReparaciones !== undefined && datosDeReparaciones['listaDeReparaciones']) {

            const reparacionPorCamisas = datosDeReparaciones['listaDeReparaciones'].find(reparacion => reparacion.accion === 'camisas');
            const reparacionPorCorte = datosDeReparaciones['listaDeReparaciones'].find(reparacion => reparacion.accion === 'corte');

            nuevoEstado = {
                ...nuevoEstado,
                detalleDeReparacionesMecanicas: datosDeReparaciones['detalle'],
                reparacionPorCamisas: reparacionPorCamisas,
                reparacionPorCorte: reparacionPorCorte,
                fotosGaleria2: fotosGaleria2,
                contatorDeRender: this.contatorDeRender + 1
            }
        }
        this.setState(nuevoEstado);
        this.setState({ detalleILI: datosDelNodo });
    }

    traerDatosDeAnomalias = (anomalias, id) => {
        obtenerDatosDeAnomalias(anomalias, id)
            .then(nuevoEstado => {
                let datos = {
                    ...this.state,
                    ...nuevoEstado,
                    contatorDeRender: this.contatorDeRender + 1
                }
                this.setState(datos);
            })
            .catch(e => {

            })
    }

    pintarReparacionesMecanicas = () => {
        if (this.state.detalleDeReparacionesMecanicas !== undefined) {
            if (Object.keys(this.state.detalleDeReparacionesMecanicas).length > 1
                || this.state.reparacionPorCamisas !== undefined
                || this.state.reparacionPorCorte !== undefined
            ) {
                return (
                    <CardConEncabezado
                        icono={FaList}
                        key={'card-reparaciones_' + (new Date()).getTime()}
                        titulo={"Reparaciones Mecánicas"}
                    >
                        {this.pintarDetalleDeReparaciones()}
                        {this.pintarAccionesDeReparacion('Camisas')}
                        {this.pintarAccionesDeReparacion('Corte')}
                    </CardConEncabezado>
                )
            } else {
                return (
                    <React.Fragment />
                )
            }
        } else {
            return (
                <React.Fragment />
            )
        }
    }

    pintarGaleria = () => {
        if (this.state.fotosGaleria2) {
            return (
                <CardConEncabezado
                    icono={FaPhotoVideo}
                    titulo={"Galería fotográfica"}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <GaleriaFotografica
                            idOrdenDeTrabajo={this.props.match.params.id}
                            keysDelRegistro={this.state.fotosGaleria2} />
                    </Suspense>
                </CardConEncabezado>
            )
        } else {
            return (
                <React.Fragment />
            )
        }
    }

    pintarEstadoDeLaInspección = () => {
        try {
            if (this.state.estadoDelRegistro !== undefined) {
                return (
                    <EstadoDeLaInspeccion
                        estadoDelRegistro={this.state.estadoDelRegistro}
                        id={this.props.match.params.id}
                        usuarioActivo={this.props.usuarioActivo}
                    />
                )
            } else {
                return (<React.Fragment></React.Fragment>);
            }
        }
        catch (error) {
            this.setState({ error: true })
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarIdentificacionDucto = () => {
        try {
            if (this.state.keysDelRegistro !== undefined) {
                return (
                    <IdentificacionDelDucto 
                    keysDelRegistro={this.state.keysDelRegistro} />
                )
            } else {
                return (<React.Fragment></React.Fragment>);
            }
        }
        catch (error) {
            this.setState({ error: true })
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarLocalizacionILI = () => {
        try {
            if (this.state.identificacionILI !== undefined) {
                return (
                    <GaleriaLocalizacionILI
                        usuarioActivo={this.props.usuarioActivo}
                        idOrdenDeTrabajo={this.props.match.params.id}
                        detalleILI={this.props.detalleILI}
                        authRol={this.disableBotonEditar()}
                    />
                );
            } else {
                return (<React.Fragment></React.Fragment>);
            }
        }
        catch (error) {
            this.setState({ error: true })
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarIdentificacionAnomaliaILI = () => {
        try {
            return (
                <React.Fragment>
                    {this.renderModalInformacionILI('id_anomalia_ili')}
                    <IdentificacionAnomaliaILI 
                        usuarioActivo={this.props.usuarioActivo}
                        keysDelRegistro={this.state.identificacionILI}
                        toggleModalLI={this.toggleModalLI}
                        estado={this.existeInformacionILI()}
                        authRol={this.disableBotonEditar()}
                    />
                </React.Fragment>
            )
        }
        catch (error) {
            this.setState({ error: true })
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarUbicacionGeografica = () => {
        try {
            return (
                <React.Fragment>
                    {this.renderModalUbicacionILI('ub_geografica_ili')}
                    <UbicacionGeoGrafica 
                        usuarioActivo={this.props.usuarioActivo}
                        keysDelRegistro={this.state.keysDelRegistro} 
                        toogleModal={this.toggleModaUbicacionlLI}
                        estado={this.existeUbicacionILI()}
                        authRol={this.disableBotonEditar()}
                    />
                </React.Fragment>
            );
        }
        catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarDatosExcavacion = () => {
        try {
            return (
                <React.Fragment>
                    {this.renderModalExcavacionILI('datos_excavacion')}
                    <DatosDeLaExcavacion 
                        usuarioActivo={this.props.usuarioActivo}
                        keysDelRegistro={this.state.keysDelRegistro}
                        toogleModal={this.toggleModaExcavacionlLI}
                        estado={this.existeExcavacionILI()}
                        authRol={this.disableBotonEditar()}
                    />
                </React.Fragment>
            );
        }
        catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarUbicacionYGeoreferenciacion = () => {
        try {
            return (
                <React.Fragment>
                    {this.renderModalGeoILI('ub_anomalia_campo')}
                    <DatosDeCampo 
                        usuarioActivo={this.props.usuarioActivo}
                        keysDelRegistro={this.state.keysDelRegistro} 
                        espesores={this.state.espesores} 
                        toogleModal={this.toggleModalGeoILI}
                        estado={this.existeGeoILI()}
                        authRol={this.disableBotonEditar()}
                    />
                </React.Fragment>
            );
        }
        catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarObservaciones = () => {
        try {
            return (
                <React.Fragment>
                    <Observaciones 
                        usuarioActivo={this.props.usuarioActivo}
                        keysDelRegistro={this.state.estadoDelRegistro}
                        loading={() => this.toggleCargando()}
                        estado={this.existeObservacion()}
                        authRol={this.disableBotonEditar()}
                    />
                </React.Fragment>
            );
        }
        catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    renderCardDeDatosDeInspeccion = () => {
        if (
            (
                this.state.keysDelRegistro ?
                    (
                        (this.existeUbicacionILI() ? true : undefined) ||
                        (this.existeExcavacionILI() ? true : undefined) ||
                        (this.existeGeoILI() ? true : undefined) ||
                        (this.state.keysDelRegistro.departamentoOYMDeDucto ? true : undefined) ||
                        (this.existeInformacionILI() ? true : undefined) ||
                        (this.existeObservacion() ? true : undefined)
                    )
                    : undefined
            ) ||
            (
                this.state.identificacionILI ?
                    this.state.identificacionILI.distanciaRegistroDucto ?
                        true
                        : undefined
                    : undefined
            )
        ) {
            return (
                <CardConEncabezado
                    icono={FaList}
                    key={(new Date()).getTime()}
                    titulo={"Datos de la inspección"}
                >
                    {this.pintarIdentificacionDucto()}
                    {this.pintarLocalizacionILI()}
                    {this.pintarIdentificacionAnomaliaILI()}
                    {this.pintarUbicacionGeografica()}
                    {this.pintarDatosExcavacion()}
                    {this.pintarUbicacionYGeoreferenciacion()}
                    {this.pintarObservaciones()}
                    {this.renderModalInformacionAnomalias(this.state.tipoDeAnomaliaEdicion)}
                    {this.renderModalGridTable()}
                </CardConEncabezado>
            )
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }

    }

    pintarComparacionDatos = () => {
        if (this.state.keysDelRegistro !== undefined) {
            for (let i = 0; i < this.state.anomalias.length; i++) {
                if (this.state.anomalias[i].anomalia.id !== undefined) {
                    return (
                        <React.Fragment>
                            <ComparacionDeDatos
                                anomalias={this.state.anomalias}
                                toggleModalEdicionAnomalias={() => this.toggleModalEdicionAnomalias()}
                                toggleModalGrid={() => this.toggleModalGrid()}
                                setAnomaliaEdicion={(e) => this.setAnomaliaEdicion(e)}
                                setTipoAnomaliaEdicion={(e) => this.setTipoAnomaliaEdicion(e)}
                                authRol={this.disableBotonEditar()}
                            />
                        </React.Fragment>
                    )
                }
            }
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }

    pintarAnomaliasAbolladuras = () => {
        if (this.state.keysDelRegistro && this.state.anomaliasAbolladuras && this.state.anomaliasAbolladuras.length > 0) {
            if (this.state.anomaliasAbolladuras[0].distanciaRegistro !== undefined) {
                return (
                    <CaracterizacionDeAbolladuras
                        usuarioActivo={this.props.usuarioActivo}
                        anomaliasAbolladuras={this.state.anomaliasAbolladuras}
                        distanciaCaALosHombros={this.state.distanciaCaALosHombros}
                        localizacionDeHombros={this.state.localizacionDeHombros}
                        keysDelRegistro={this.state.keysDelRegistro}
                        elModalDeAbolladurasEstaAbierto={this.state.elModalEstaAbierto}
                        toggleDelModal={this.toggleDelModalAbolladuras}
                        toggleModalEdicionAnomalias={() => this.toggleModalEdicionAnomalias()}
                        setAnomaliaEdicion={(e) => this.setAnomaliaEdicion(e)}
                        setTipoAnomaliaEdicion={(e) => this.setTipoAnomaliaEdicion(e)}
                        authRol={this.disableBotonEditar()}
                    />
                )
            }
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }

    pintarAnomaliasGrietas = () => {
        if ( this.state.anomaliasGrietas && this.state.anomaliasGrietas.length > 0) {
            return (
                <CaracterizacionDeGrietas
                    informacionGeneral={this.state.generalGrietas}
                    idOrdenDeTrabajo={this.props.match.params.id}
                    anomalias={this.state.anomaliasGrietas}
                    ensayos={this.state.ensayosGrietas}
                    toggleModalEdicionAnomalias={() => this.toggleModalEdicionAnomalias()}
                    setAnomaliaEdicion={(e) => this.setAnomaliaEdicion(e)}
                    setSubAnomaliaEdicion={(e) => this.setSubAnomaliaEdicion(e)}
                    setTipoAnomaliaEdicion={(e) => this.setTipoAnomaliaEdicion(e)}
                    authRol={this.disableBotonEditar()}
                />
            )
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }

    pintarAnomaliasCamisas = () => {
        if (this.state.anomaliasCamisas && this.state.anomaliasCamisas.length > 0) {
            return (
                <CaracterizacionDeCamisas
                    key={"caracterizacion" + this.state.contatorDeRender}
                    datosDeCamisas={this.state.anomaliasCamisas}
                    idOrdenDeTrabajo={this.props.match.params.id}
                    toggleModalEdicionAnomalias={() => this.toggleModalEdicionAnomalias()}
                    setAnomaliaEdicion={(e) => this.setAnomaliaEdicion(e)}
                    setSubAnomaliaEdicion={(e) => this.setSubAnomaliaEdicion(e)}
                    setTipoAnomaliaEdicion={(e) => this.setTipoAnomaliaEdicion(e)}
                    authRol={this.disableBotonEditar()}
                />
            )
        } else {
            return (
                <React.Fragment />
            )
        }
    }

    pintarAnomaliaBending = () => {
        if (this.state.anomaliasBending && this.state.anomaliasBending.length > 0) {
            return (
                <CaracterizacionDeBS
                    key={"caracterizacion" + this.state.contatorDeRender}
                    datosDeBending={this.state.anomaliasBending}
                    idOrdenDeTrabajo={this.props.match.params.id}
                />
            )
        } else {
            return (
                <React.Fragment />
            )
        }
    }
    // Esta función se encarga de pintar toda la informacion correspondiente a las anomalias
    renderCardAnomalias = () => {
        if (
            (this.state.anomaliasAbolladuras ? this.state.anomaliasAbolladuras.length > 0 ? true : undefined : undefined) ||
            (this.state.anomaliasGrietas ? this.state.anomaliasGrietas.length > 0 ? true : undefined : undefined) ||
            (this.state.anomaliasCamisas ? this.state.anomaliasCamisas.length > 0 ? true : undefined : undefined) ||
            (this.state.anomalias ? this.state.anomalias.length > 0 ? true : undefined : undefined)
        ) {
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <CardConEncabezado
                        icono={FaList}
                        titulo={"Anomalías"}
                    >
                        {this.pintarComparacionDatos()}
                        {this.pintarAnomaliasAbolladuras()}
                        {this.pintarAnomaliasGrietas()}
                        {this.pintarAnomaliasCamisas()}
                        {this.pintarAnomaliaBending()}
                    </CardConEncabezado>
                </Suspense>
            )
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }



    }

    pintarDetalleDeReparaciones = () => {
        try {
            if (this.state.detalleDeReparacionesMecanicas !== undefined && this.state.detalleDeReparacionesMecanicas.estado !== 'NUEVO') {
                return (
                    <DetalleDeReparaciones keysDeReparacion={this.state.detalleDeReparacionesMecanicas} />
                )
            } else {
                return (<React.Fragment></React.Fragment>);
            }
        }
        catch (error) {
            this.setState({ error: true })
            return (<React.Fragment></React.Fragment>);
        }
    }

    pintarAccionesDeReparacion = (tipoDeReparacion) => {
        if (this.state.reparacionPorCamisas !== undefined && tipoDeReparacion === 'Camisas') {
            return (
                <DetalleDeAtenciones tipo={tipoDeReparacion} acciones={this.state.reparacionPorCamisas.listaDeAcciones} />
            )
        } else if (this.state.reparacionPorCorte !== undefined && tipoDeReparacion === 'Corte') {
            return (
                <DetalleDeAtenciones tipo={tipoDeReparacion} acciones={this.state.reparacionPorCorte.listaDeAcciones} />
            )
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }

    obtenerBotonDeChat = () => {
        return (
            <BotonParaSection
                key="botonChatOrdenesInspeccion"
                icono={<BsFillChatQuoteFill />}
                onClick={this.toggleDelChat}
                title="Chat"
            />
        );
    }

    obtenerBotonExportacion = () => {
        return (
            <BotonParaSection
                key="botonExportarOrdenesInspeccion"
                icono={<FaCloudDownloadAlt />}
                onClick={this.toggleDeConsolaDeDescarga}
                title="Exportar"
            />
        );
    }

    toggleModalLI = () => {
        document.body.classList.remove('modal-open');
        this.setState({ modalInformacionILI: !this.state.modalInformacionILI })
    }

    toggleModaUbicacionlLI = () => {
        document.body.classList.remove('modal-open');
        this.setState({ modalUbicacionILI: !this.state.modalUbicacionILI })
    }

    toggleModalLocalizacionlLI = () => {
        document.body.classList.remove('modal-open');
        this.setState({ modalLocalizacionILI: !this.state.modalLocalizacionILI })
    }

    toggleModaExcavacionlLI = () => {
        document.body.classList.remove('modal-open');
        this.setState({ modalExcavacionILI: !this.state.modalExcavacionILI })
    }

    toggleModalEdicionAnomalias = () => {
        document.body.classList.remove('modal-open');
        if (this.state.subAnomalia) { this.setState({ subAnomalia: false }) }
        this.setState({
            modalEdicionAnomalias: !this.state.modalEdicionAnomalias,
        })
    }

    toggleModalGrid = () => {
        console.log('aca')
        document.body.classList.remove('modal-open');
        this.setState({
            modalGrid: !this.state.modalGrid,
        })
    }

    setAnomaliaEdicion = (idAnomalia) => {
        this.setState({
            anomaliaParaEdicion: idAnomalia,
        })
    }

    setSubAnomaliaEdicion = (idAnomalia) => {
        this.setState({
            subAnomaliaParaEdicion: idAnomalia,
            subAnomalia: true,
        })
    }

    toggleModalGeoILI = () => {
        document.body.classList.remove('modal-open');
        this.setState({ modalGeoILI: !this.state.modalGeoILI })
    }

    setTipoAnomaliaEdicion =(tipoDeAnomalia)=>{
        this.setState({
            tipoDeAnomaliaEdicion: tipoDeAnomalia
        })
    }

    renderModalGridTable = () =>{
        return (
            <CustomModal
                size='xl'
                titulo={`Grilla Anomalía ${this.state.anomaliaParaEdicion}`}
                elModalEstaAbierto={this.state.modalGrid}
                toggleDelModal={() => this.toggleModalGrid()}
                hideFooter={true}
                anomaliaParaEdicion={this.state.anomaliaParaEdicion}
                children={
                    <Grid
                        ordenDeTrabajo={this.props.match.params.id}
                        idAnomalia={this.state.anomaliaParaEdicion}
                        anomalias={this.state.anomalias}
                    />
                }
            >
            </CustomModal>
        );
    }
    renderModalInformacionAnomalias = (seccion) => {
        return (
            <CustomModal
                titulo={this.obtenerTituloModal(seccion)}
                elModalEstaAbierto={this.state.modalEdicionAnomalias}
                toggleDelModal={() => { this.toggleModalEdicionAnomalias() }}
                hideFooter={true}
                children={
                    <VisualizadorFormularioDeAnomalias
                        subAnomalia={this.state.subAnomalia}
                        cerrarModal={() => this.toggleModalEdicionAnomalias()}
                        anomaliasCamisas={this.state.anomaliasCamisas}
                        anomaliasAbolladuras={this.state.anomaliasAbolladuras}
                        anomaliasGrietas={this.state.anomaliasGrietas}
                        ensayosGrietas={this.state.ensayosGrietas}
                        ordenDeTrabajo={this.props.match.params.id}
                        idAnomalia={this.state.anomaliaParaEdicion}
                        idSubAnomalia={this.state.subAnomaliaParaEdicion}
                        informacionOT={this.state.anomalias}
                        seccion={seccion}
                    />}
            >
            </CustomModal>
        );
    }

    renderModalInformacionILI = (seccion) => {
        return (
            <CustomModal
                titulo={this.obtenerTituloModal(seccion)}
                elModalEstaAbierto={this.state.modalInformacionILI}
                toggleDelModal={() => { this.toggleModalLI() }}
                hideFooter={true}
                children={
                    <VisualizadorFormularioDeILI
                        cerrarModal={() => { this.toggleModalLI() }}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.detalleILI}
                        seccion={seccion}
                    />}
            >
            </CustomModal>
        );
    }

    renderModalLocalizacionILI = (seccion) => {
        let titulo = this.obtenerTituloModal(seccion)
        return (
            <CustomModal
                titulo={titulo}
                elModalEstaAbierto={this.state.modalLocalizacionILI}
                toggleDelModal={() => { this.toggleModalLocalizacionlLI() }}
                children={
                    <VisualizadorFormularioDeILI
                        cerrarModal={() => { this.toggleModalLocalizacionlLI() }}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.detalleILI}
                        seccion={seccion}
                    />}
            >
            </CustomModal>
        );
    }

    renderModalUbicacionILI = (seccion) => {
        return (
            <CustomModal
                titulo={this.obtenerTituloModal(seccion)}
                elModalEstaAbierto={this.state.modalUbicacionILI}
                toggleDelModal={() => { this.toggleModaUbicacionlLI() }}
                hideFooter={true}
                children={
                    <VisualizadorFormularioDeILI
                        cerrarModal={() => { this.toggleModaUbicacionlLI() }}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.detalleILI}
                        seccion={seccion}
                    />}
            >
            </CustomModal>
        );
    }
    renderModalExcavacionILI = (seccion) => {
        return (
            <CustomModal
                titulo={this.obtenerTituloModal(seccion)}
                elModalEstaAbierto={this.state.modalExcavacionILI}
                toggleDelModal={() => { this.toggleModaExcavacionlLI() }}
                hideFooter={true}
                children={
                    <VisualizadorFormularioDeILI
                        cerrarModal={() => { this.toggleModaExcavacionlLI() }}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.detalleILI}
                        seccion={seccion}
                    />}
            >
            </CustomModal>
        );
    }
    renderModalGeoILI = (seccion) => {
        return (
            <CustomModal
                titulo={this.obtenerTituloModal(seccion)}
                elModalEstaAbierto={this.state.modalGeoILI}
                toggleDelModal={() => { this.toggleModalGeoILI() }}
                hideFooter={true}
                children={
                    <VisualizadorFormularioDeILI
                        cerrarModal={() => { this.toggleModalGeoILI() }}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.detalleILI}
                        seccion={seccion}
                    />}
            >
            </CustomModal>
        );
    }


    obtenerTituloModal = (seccion) => {
        switch (seccion) {
            case 'id_anomalia_ili':
                return 'IDENTIFICACION ANOMALIA ILI'
            case 'localizacion_anomalia_ili':
                return 'Agregar imagenes de LOCALIZACIÓN DE ANOMALÍA ILI'
            case 'ub_geografica_ili':
                return 'UBICACIÓN GEOGRÁFICA'
            case 'datos_excavacion':
                return 'DATOS DE LA EXCAVACIÓN'
            case 'ub_anomalia_campo':
                return 'UBICACIÓN Y GEOREFERENCICIÓN DE ANOMALIA EN CAMPO'
            case 'PERDIDA_DE_METAL':
                return 'ANOMALÍA PÉRDIDA DE METAL'
            case 'ABOLLADURA':
                return 'ANOMALÍA ABOLLADURA'
            case 'GRIETA':
                return 'ANOMALÍA GRIETA'
            case 'ENSAYO_GRIETA':
                return 'ANOMALÍA GRIETA'
            case 'CAMISA':
                return 'ENSAYO GRIETA'
            case 'PARCHE':
                return 'ANOMALÍA PARCHE'
            case 'CAMISA_SOBRE_ANILLOS':
                return 'ANOMALÍA CAMISA SOBRE ANILLOS'
            case 'SUBANOMALIA':
                return 'SUBANOMALÍA DE CAMISA'
            case 'observaciones':
                return 'OBSERVACIONES'
            default:
                return 'NO EXISTE TITULO'
        }
    }

    existeInformacionILI = () => {
        return ((this.state.keysDelRegistro.distanciaRegistroDucto) ||
            (this.state.keysDelRegistro.longitudDeDuctoDecimal) ||
            (this.state.keysDelRegistro.latitudDeDuctoDecimal) ||
            (this.state.keysDelRegistro.lTubo) ||
            (this.state.keysDelRegistro.distanciaAlRegistroDelDucto) ||
            (this.state.keysDelRegistro.longitudAnomaliaDelDucto) ||
            (this.state.keysDelRegistro.anchoAnomaliaDelDucto) ||
            (this.state.keysDelRegistro.distanciaJuntaAnteriorDelDucto) ||
            (this.state.keysDelRegistro.distanciaJuntaPosteriorDelDucto) ||
            (this.state.keysDelRegistro.posicionHorariaDelDucto) ||
            (this.state.keysDelRegistro.anhoDeLaCorridaDelDucto) ||
            (this.state.keysDelRegistro.porcentajeDeMetalOReduccionDelIdDelDucto) ||
            (this.state.keysDelRegistro.tipoDeAnomaliaDelDucto) ||
            (this.state.keysDelRegistro.programadaOAdicionalDelDucto) ||
            (this.state.keysDelRegistro.clasificacionDelDucto) ||
            (this.state.keysDelRegistro.enParedDelDucto)
        )
    }
    existeUbicacionILI = () => {
        return (
            (this.state.keysDelRegistro.departamentoUbicacionGeografica) ||
            (this.state.keysDelRegistro.municipioUbicacionGeografica) ||
            (this.state.keysDelRegistro.sectorOVeredaUbicacionGeografica)
        )
    }
    existeExcavacionILI = () => {
        return (
            (this.state.keysDelRegistro.distanciaRegistroExcavacionInicial) ||
            (this.state.keysDelRegistro.distanciaRegistroExcavacionFinal) ||
            (this.state.keysDelRegistro.longitudDeExcavacion) ||
            (this.state.keysDelRegistro.anchoDeExcavacion) ||
            (this.state.keysDelRegistro.profundidadDeExcavacion) ||
            (this.state.keysDelRegistro.tipoDeTerreno)
        )
    }
    existeGeoILI = () => {
        return (
            (this.state.keysDelRegistro.longitudDeAnomaliaDecimal) ||
            (this.state.keysDelRegistro.latitudDeAnomaliaDecimal) ||
            (this.state.keysDelRegistro.ubicacionTuberiaAnomalia) ||
            (this.state.keysDelRegistro.longitudTubo) ||
            (this.state.keysDelRegistro.precisionDelGPS) ||
            (this.state.keysDelRegistro.espesorZ1_12) ||
            (this.state.keysDelRegistro.espesorZ1_3) ||
            (this.state.keysDelRegistro.espesorZ1_6) ||
            (this.state.keysDelRegistro.espesorZ1_9) ||
            (this.state.keysDelRegistro.espesorZ2_12) ||
            (this.state.keysDelRegistro.espesorZ2_3) ||
            (this.state.keysDelRegistro.espesorZ2_6) ||
            (this.state.keysDelRegistro.espesorZ2_9) ||
            (this.state.keysDelRegistro.espesorZ3_12) ||
            (this.state.keysDelRegistro.espesorZ3_3) ||
            (this.state.keysDelRegistro.espesorZ3_6) ||
            (this.state.keysDelRegistro.espesorZ3_9) ||
            (this.state.keysDelRegistro.espesorZ4_12) ||
            (this.state.keysDelRegistro.espesorZ4_3) ||
            (this.state.keysDelRegistro.espesorZ4_6) ||
            (this.state.keysDelRegistro.espesorZ4_9) ||
            (this.state.keysDelRegistro.espesorZ5_12) ||
            (this.state.keysDelRegistro.spesorZ5_) ||
            (this.state.keysDelRegistro.spesorZ5_) ||
            (this.state.keysDelRegistro.spesorZ5_) ||
            (this.state.keysDelRegistro.espesorMinimo) ||
            (this.state.keysDelRegistro.espesorMaximo) ||
            (this.state.keysDelRegistro.espesorPromedio)
        )
    }
    existeObservacion = () => {
        return (
            (this.state.estadoDelRegistro.observaciones.observacionEND!==undefined) ||
            (this.state.estadoDelRegistro.observaciones.observacionGeneral!==undefined) ||
            (this.state.estadoDelRegistro.observaciones.observacionReparacion!==undefined) ||
            (this.state.estadoDelRegistro.observaciones.observacionAdicional!==undefined)
        )
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

export default connect(mapStateToProps)(VisualizadorDeFormatoDeInspeccion);