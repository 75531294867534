import React from 'react';
import NumberFormat from 'react-number-format';
import { withRouter, Link } from 'react-router-dom';

//Componentes auxiliares:
import CustomTable from '../visualizadores/CustomTable';

//Scripts auxiliares:
import { obtenerColorSegunValorCualitativo } from '../../../utilidades/scripts/paraVisualizacionDeRangos';
import BarraNivelCualitativo from './BarraNivelCualitativo';

//Constantes auxiliares:
const estiloDeFuentesBase = { fontSize: 13 };
const estiloParaCeldaBasica = { marginBottom: 0 };
const estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, ...estiloDeFuentesBase, cursor: "pointer" };
const estiloParaHeaderFuga = { color: "#504C4C", marginBottom: 0, ...estiloDeFuentesBase, cursor: "pointer" };
const estiloParaHeaderApertura = { color: "rgba(99, 10, 80,0.8)", marginBottom: 0, ...estiloDeFuentesBase, cursor: "pointer" };

//Parametrización de la tabla de nodos:
const parametrizacionAdicional = {
    "riesgo": {
        "decimalScale": 2,
        "keyCualitativo": "riesgoCualitativo",
        "keyDeRangos": "rangosDeRiesgo",
        "thousandSeparator": true,
        "prefix": ""
    },
    "consecuencia": {
        "decimalScale": 0,
        "keyCualitativo": "consecuenciaCualitativa",
        "keyDeRangos": "rangosDeConsecuencia",
        "thousandSeparator": true,
        "prefix": "$"
    },
    "probabilidad": {
        "decimalScale": 6,
        "keyCualitativo": "probabilidadCualitativa",
        "keyDeRangos": "rangosDeProbabilidad",
        "thousandSeparator": true,
        "prefix": ""
    },
    "riesgoFuga": {
        "decimalScale": 2,
        "keyCualitativo": "riesgoCualitativoFuga",
        "keyDeRangos": "rangosDeRiesgo",
        "thousandSeparator": true,
        "prefix": "$"
    },
    "consecuenciaFuga": {
        "decimalScale": 0,
        "keyCualitativo": "consecuenciaCualitativaFuga",
        "keyDeRangos": "rangosDeConsecuencia",
        "thousandSeparator": true,
        "prefix": "$"
    },
    "probabilidadFuga": {
        "decimalScale": 6,
        "keyCualitativo": "probabilidadCualitativaFuga",
        "keyDeRangos": "rangosDeProbabilidad",
        "thousandSeparator": true,
        "prefix": ""
    },
    "riesgoApertura": {
        "decimalScale": 2,
        "keyCualitativo": "riesgoCualitativoApertura",
        "keyDeRangos": "rangosDeRiesgo",
        "thousandSeparator": true,
        "prefix": "$"
    },
    "consecuenciaApertura": {
        "decimalScale": 0,
        "keyCualitativo": "consecuenciaCualitativaApertura",
        "keyDeRangos": "rangosDeConsecuencia",
        "thousandSeparator": true,
        "prefix": "$"
    },
    "probabilidadApertura": {
        "decimalScale": 6,
        "keyCualitativo": "probabilidadCualitativaApertura",
        "keyDeRangos": "rangosDeProbabilidad",
        "thousandSeparator": false,
        "prefix": ""
    },
    "riesgoTotal": {
        "decimalScale":2,
        "keyCualitativo": "nivelDeRiesgo",
        "keyDeRangos": "rangosDeRiesgo",
        "thousandSeparator": false,
        "prefix": '$',
    },
}

const obtenerColumnasDeLaTabla = (props) => {
    let columnasBase = [];
    if (props.isPRD) {
        if(props.tipoDeFalla && props.tipoDeFalla === "fuga"){
            columnasBase = [
                {
                    show: true,
                    accessor: 'nombre',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                    Cell: row => {
                        const rutaDeRedireccion = '/app/detalleDePRD/prd/' + row.original.id + '/' + props.moduloTarget;
    
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <Link to={rutaDeRedireccion}
                                    onContextMenu={() => {
                                        guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                    }}
                                    onClick={() => {
                                        guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                    }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombre}>
                                        {row.original.nombre}
                                    </p>
                                </Link>
                            </div>
                        );
                    },
                },
                {
                    show: true,
                    accessor: 'consecuenciaFuga',
                    Header: () => (<p style={{ ...estiloParaHeader }}>CoF fuga</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "consecuenciaFuga", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'probabilidadFuga',
                    Header: () => (<p style={{ ...estiloParaHeader }}>PoF fuga</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "probabilidadFuga", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'riesgoFuga',
                    Header: () => (<p style={{ ...estiloParaHeader }}>Riesgo fuga</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "riesgoFuga", props.parametrizacionCorporativa)
                },
                {
                    show: props.coleccionActiva === 'activos',
                    accessor: 'nombreDelSegmentoCritico',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Segmento crítico</p>),
                    Cell: row => {
                        if (row.original.idDelSegmentoCritico) {
                            const rutaDeRedireccion = '/app/detalleDeNodo/segmentos/' + row.original.idDelSegmentoCritico + '/' + props.moduloTarget;
                            return (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <Link to={rutaDeRedireccion}
                                        onContextMenu={() => {
                                            guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                        }}
                                        onClick={() => {
                                            guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                        }}>
                                        <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                            {row.original.nombreDelSegmentoCritico}
                                        </p>
                                    </Link>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "center", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                        {row.original.nombreDelSegmentoCritico}
                                    </p>
                                </div>
                            );
                        }
                    },
                },
            ];
        }else if(props.tipoDeFalla && props.tipoDeFalla === "apertura"){
            columnasBase = [
                {
                    show: true,
                    accessor: 'nombre',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                    Cell: row => {
                        const rutaDeRedireccion = '/app/detalleDePRD/prd/' + row.original.id + '/' + props.moduloTarget;
    
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <Link to={rutaDeRedireccion}
                                    onContextMenu={() => {
                                        guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                    }}
                                    onClick={() => {
                                        guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                    }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombre}>
                                        {row.original.nombre}
                                    </p>
                                </Link>
                            </div>
                        );
                    },
                },
                {
                    show: true,
                    accessor: 'consecuenciaApertura',
                    Header: () => (<p style={{ ...estiloParaHeader }}>CoF apertura</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "consecuenciaApertura", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'probabilidadApertura',
                    Header: () => (<p style={{ ...estiloParaHeader }}>PoF apertura</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "probabilidadApertura", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'riesgoApertura',
                    Header: () => (<p style={{ ...estiloParaHeader }}>Riesgo apertura</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "riesgoApertura", props.parametrizacionCorporativa)
                },
                {
                    show: props.coleccionActiva === 'activos',
                    accessor: 'nombreDelSegmentoCritico',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Segmento crítico</p>),
                    Cell: row => {
                        if (row.original.idDelSegmentoCritico) {
                            const rutaDeRedireccion = '/app/detalleDeNodo/segmentos/' + row.original.idDelSegmentoCritico + '/' + props.moduloTarget;
                            return (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <Link to={rutaDeRedireccion}
                                        onContextMenu={() => {
                                            guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                        }}
                                        onClick={() => {
                                            guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                        }}>
                                        <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                            {row.original.nombreDelSegmentoCritico}
                                        </p>
                                    </Link>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "center", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                        {row.original.nombreDelSegmentoCritico}
                                    </p>
                                </div>
                            );
                        }
                    },
                },
            ];
        }else{
            columnasBase = [
                {
                    show: true,
                    accessor: 'nombre',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                    Cell: row => {
                        const rutaDeRedireccion = '/app/detalleDePRD/prd/' + row.original.id + '/' + props.moduloTarget;
    
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <Link to={rutaDeRedireccion}
                                    onContextMenu={() => {
                                        guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                    }}
                                    onClick={() => {
                                        guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                    }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombre}>
                                        {row.original.nombre}
                                    </p>
                                </Link>
                            </div>
                        );
                    },
                },
                {
                    show: true,
                    accessor: 'consecuenciaFuga',
                    Header: () => (<p style={{ ...estiloParaHeaderFuga }}>CoF fuga</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "consecuenciaFuga", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'probabilidadFuga',
                    Header: () => (<p style={{ ...estiloParaHeaderFuga }}>PoF fuga</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "probabilidadFuga", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'riesgoFuga',
                    Header: () => (<p style={{ ...estiloParaHeaderFuga }}>Riesgo fuga</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "riesgoFuga", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'consecuenciaApertura',
                    Header: () => (<p style={{ ...estiloParaHeaderApertura }}>CoF apertura</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "consecuenciaApertura", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'probabilidadApertura',
                    Header: () => (<p style={{ ...estiloParaHeaderApertura }}>PoF apertura</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "probabilidadApertura", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'riesgoApertura',
                    Header: () => (<p style={{ ...estiloParaHeaderApertura }}>Riesgo apertura</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "riesgoApertura", props.parametrizacionCorporativa)
                },
                {
                    show: true,
                    accessor: 'riesgoTotal',
                    Header: () => (<p style={{ ...estiloParaHeader }}>{props.filtroModeloConsecuencia === "Semicuantitativo" ? "Riesgo total" : "Riesgo máximo"}</p>),
                    Cell: row => obtenerVariableConBarra(row.original, "riesgoTotal", props.parametrizacionCorporativa)
                },
                {
                    show: props.coleccionActiva === 'activos',
                    accessor: 'nombreDelSegmentoCritico',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Segmento crítico</p>),
                    Cell: row => {
                        if (row.original.idDelSegmentoCritico) {
                            const rutaDeRedireccion = '/app/detalleDeNodo/segmentos/' + row.original.idDelSegmentoCritico + '/' + props.moduloTarget;
                            return (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <Link to={rutaDeRedireccion}
                                        onContextMenu={() => {
                                            guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                        }}
                                        onClick={() => {
                                            guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                        }}>
                                        <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                            {row.original.nombreDelSegmentoCritico}
                                        </p>
                                    </Link>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                    <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "center", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                        {row.original.nombreDelSegmentoCritico}
                                    </p>
                                </div>
                            );
                        }
                    },
                },
            ]; 
        }
    } else {
        columnasBase = [{
            show: true,
            accessor: 'nombre',
            minWidth: 200,
            Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
            Cell: row => {
                const rutaDeRedireccion = '/app/detalleDeNodo/' + props.coleccionActiva + '/' + row.original.id + '/' + props.moduloTarget;

                return (
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                        <Link to={rutaDeRedireccion}
                            onContextMenu={() => {
                                guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                            }}
                            onClick={() => {
                                guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                            }}>
                            <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombre}>
                                {row.original.nombre}
                            </p>
                        </Link>
                    </div>
                );
            },
        },
        {
            show: true,
            accessor: 'consecuencia',
            Header: () => (<p style={{ ...estiloParaHeader }}>CoF</p>),
            Cell: row => obtenerVariableConBarra(row.original, "consecuencia", props.parametrizacionCorporativa)
        },
        {
            show: true,
            accessor: 'probabilidad',
            Header: () => (<p style={{ ...estiloParaHeader }}>PoF</p>),
            Cell: row => obtenerVariableConBarra(row.original, "probabilidad", props.parametrizacionCorporativa)
        },
        {
            show: true,
            accessor: 'riesgo',
            Header: () => (<p style={{ ...estiloParaHeader }}>Riesgo</p>),
            Cell: row => obtenerVariableConBarra(row.original, "riesgo", props.parametrizacionCorporativa)
        },
        {
            show: props.coleccionActiva === 'activos',
            accessor: 'nombreDelSegmentoCritico',
            minWidth: 200,
            Header: () => (<p style={{ ...estiloParaHeader }}>Segmento crítico</p>),
            Cell: row => {
                if (row.original.idDelSegmentoCritico) {
                    const rutaDeRedireccion = '/app/detalleDeNodo/segmentos/' + row.original.idDelSegmentoCritico + '/' + props.moduloTarget;
                    return (
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                            <Link to={rutaDeRedireccion}
                                onContextMenu={() => {
                                    guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                }}
                                onClick={() => {
                                    guardarEnlaceEnLocalStorage(props, rutaDeRedireccion);
                                }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                    {row.original.nombreDelSegmentoCritico}
                                </p>
                            </Link>
                        </div>
                    );
                }
                else {
                    return (
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                            <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "center", ...estiloDeFuentesBase, fontSize: 14 }} title={row.original.nombreDelSegmentoCritico}>
                                {row.original.nombreDelSegmentoCritico}
                            </p>
                        </div>
                    );
                }
            },
        },
        ];
    }


    return columnasBase.filter(item => item.show);
};

const TablaDeNodos = (props) => {
    let listaDeNodos = props.listaDeNodos || [];
    if (props.isPRD) {
        return (
            <CustomTable
                isPRD={true}
                criterioDeOrdenamiento={props.criterioDeOrdenamiento}
                listaDeDatos={[...obtenerListaDeNodosFormateadaPRD(listaDeNodos)]}
                columnas={obtenerColumnasDeLaTabla(props)} 
                tableProps = {props.tableProps}
                />
        );
    } else {
        return (
            <CustomTable
                criterioDeOrdenamiento={props.criterioDeOrdenamiento}
                listaDeDatos={[...obtenerListaDeNodosFormateada(listaDeNodos)]}
                columnas={obtenerColumnasDeLaTabla(props)} />
        );
    }

}

//Funciones para renderizado:
const obtenerVariableConBarra = (nodo, keyTarget, parametrizacionCorporativa) => {
    const parametrizacionDelRango = parametrizacionCorporativa[(parametrizacionAdicional[keyTarget]["keyDeRangos"])];

    let contenidoNumerico = (<p style={estiloParaCeldaBasica} title="Datos insuficientes">-</p>);
    if (nodo.hasOwnProperty(keyTarget)) {        
        contenidoNumerico = (nodo[keyTarget]< 10**(-5) && nodo[keyTarget] !== 0 ) ? nodo[keyTarget].toExponential(3): (
            <NumberFormat
                displayType={'text'}
                value={nodo[keyTarget]}
                decimalScale={parametrizacionAdicional[keyTarget]["decimalScale"]}
                thousandSeparator={parametrizacionAdicional[keyTarget]["thousandSeparator"]}
                prefix={nodo.BoolMaximaCOF ? undefined : parametrizacionAdicional[keyTarget]["prefix"]}
                fixedDecimalScale={true}
                style={{ ...estiloDeFuentesBase }} />
        );
    }
    
    let valorCualitativo = nodo[(parametrizacionAdicional[keyTarget]["keyCualitativo"])];
    let valorCualitativoMaximo = obtenerValorCualitativoMaximo(parametrizacionDelRango);
    if(keyTarget === "riesgoTotal" && typeof(nodo[keyTarget]) === 'boolean'){
        valorCualitativo = nodo.riesgoTotalValorCualitativo;
        contenidoNumerico = nodo.riesgoTotalNivelDeRiesgo;
    }

    const colorAsociado = obtenerColorSegunValorCualitativo(valorCualitativo, parametrizacionDelRango)
    let tituloDeLaBarra = nodo[(parametrizacionAdicional[keyTarget]["keyCualitativo"])];

    // En esta seción se valida el riesgo para fuga y apertura para las prd evaluadas con el modelo de consecuencia cualitativo
    if(keyTarget === "riesgoFuga" && !nodo[keyTarget] && nodo[keyTarget]!==0) contenidoNumerico = obtenerEtiqueta(valorCualitativo, parametrizacionDelRango);
    if(keyTarget === "riesgoApertura" && !nodo[keyTarget] && nodo[keyTarget]!==0) contenidoNumerico = obtenerEtiqueta(valorCualitativo, parametrizacionDelRango);
     
    if (keyTarget === "riesgo") {
        try {
            let keysDeParametrizacionDeRiesgo = Object.keys(parametrizacionCorporativa['rangosDeRiesgo']);
            let itemsDeParametrizacionDeRiesgo = keysDeParametrizacionDeRiesgo.map((key, i) => {
                return parametrizacionCorporativa['rangosDeRiesgo'][key];
            });

            let itemDeParametrizacion = itemsDeParametrizacionDeRiesgo.find(item => item.color === colorAsociado);
            tituloDeLaBarra = itemDeParametrizacion.etiqueta;
        }
        catch (error) { };
    }

    return (
        <div style={{ textAlign: "center", width: "100%" }}>
            <div className="d-flex justify-content-center">
                {contenidoNumerico}
            </div>

            <BarraNivelCualitativo  {...{ tituloDeLaBarra, valorCualitativo, valorCualitativoMaximo, colorAsociado }} />
        </div>
    );
}

//Funciones operativas:

const obtenerEtiqueta = (valorMax, parametrizacion)=>{
    const etiqueta = Object.keys(parametrizacion).filter(item=>parametrizacion[item].valorCualitativo === valorMax)
    .map(element=>{return parametrizacion[element]})
    return etiqueta[0].etiqueta;
}

const obtenerListaDeNodosFormateada = (listaOriginal) => {
    return listaOriginal.map((item, i) => {
       
        let nombre = undefined;

        let consecuencia = undefined;
        let consecuenciaCualitativa = undefined;

        let probabilidad = undefined;
        let probabilidadCualitativa = undefined;

        let riesgo = undefined;
        let riesgoCualitativo = undefined;

        let idDelSegmentoCritico = undefined;
        let nombreDelSegmentoCritico = undefined;

        try { nombre = item.informacionGeneral.nombre.valor; } catch (error) { };
        try { consecuencia = item.resultadosDeLaValoracion.consecuencia.consecuenciaFinanciera; } catch (error) { };
        try { consecuenciaCualitativa = item.resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa; } catch (error) { };
        try { probabilidad = item.resultadosDeLaValoracion.probabilidad.probabilidad; } catch (error) { };
        try { probabilidadCualitativa = item.resultadosDeLaValoracion.probabilidad.probabilidadCualitativa; } catch (error) { };
        try { riesgo = item.resultadosDeLaValoracion.riesgo.riesgoFinanciero; } catch (error) { };
        try { riesgoCualitativo = item.resultadosDeLaValoracion.riesgo.riesgoCualitativo; } catch (error) { };

        if (item.resultadosDeLaValoracion.segmentoCritico) {
            try {
                idDelSegmentoCritico = item.resultadosDeLaValoracion.segmentoCritico.id;
                nombreDelSegmentoCritico = item.resultadosDeLaValoracion.segmentoCritico.nombre;
            }
            catch (error) {
                nombreDelSegmentoCritico = "-";
            }
        }
        else {
            nombreDelSegmentoCritico = "-";
        }
        return {
            "id": item.id,
            "nombre": nombre,
            "riesgo": riesgo,
            "consecuencia": consecuencia,
            "probabilidad": probabilidad,
            "riesgoCualitativo": riesgoCualitativo,
            "consecuenciaCualitativa": consecuenciaCualitativa,
            "probabilidadCualitativa": probabilidadCualitativa,
            "idDelSegmentoCritico": idDelSegmentoCritico,
            "nombreDelSegmentoCritico": nombreDelSegmentoCritico,
        };
    });
}

const obtenerListaDeNodosFormateadaPRD = (listaOriginal) => {
    let listaDeNodos = [];
    
    listaOriginal.forEach((item, i) => {
        item.resultados.forEach(analisis => {
            let BoolMaximaCOF = analisis.data.consecuencia.fuga.maximaCOF ?
                true
            : analisis.data.consecuencia.apertura.maximaCOF ?
                true
            :false;
            let nombre = undefined;

            let consecuenciaApertura = undefined;
            let consecuenciaFuga = undefined;
            let consecuenciaCualitativaApertura = undefined;
            let consecuenciaCualitativaFuga = undefined;

            let probabilidadApertura = undefined;
            let probabilidadFuga = undefined;
            let probabilidadCualitativaFuga = undefined;
            let probabilidadCualitativaApertura = undefined;

            let riesgoApertura = undefined;
            let riesgoFuga = undefined;
            let riesgoCualitativoFuga = undefined;
            let riesgoCualitativoApertura = undefined;

            let idDelSegmentoCritico = undefined;
            let nombreDelSegmentoCritico = undefined;

            let riesgoTotalFinanciero = undefined;
            let riesgoTotalNivelDeRiesgo = undefined;
            let riesgoTotalValorCualitativo = undefined;

            nombre = item.nombre;
            //Analisis para fuga 
            try { consecuenciaFuga = analisis.data.consecuencia.fuga.maximaCOF ?
                    analisis.data.consecuencia.fuga.maximaCOF
                : analisis.data.consecuencia.fuga.financiera } catch (error) { };
            try { consecuenciaCualitativaFuga = analisis.data.consecuencia.fuga.maximaCOF ?
                    analisis.data.consecuencia.fuga.maximaCOF
                :analisis.data.consecuencia.fuga.nivelDeConsecuenciaFinanciera; } catch (error) { };
            try { probabilidadFuga = analisis.data.probabilidad.fuga.total; } catch (error) { };
            try { probabilidadCualitativaFuga = analisis.data.probabilidad.fuga.nivelDeProbabilidad; } catch (error) { };
            try { riesgoFuga = analisis.data.riesgo.fuga.riesgoFinanciero; } catch (error) { };
            try { riesgoCualitativoFuga = analisis.data.riesgo.fuga.valorCualitativo; } catch (error) { };

            //Analisis para apertura
            try { consecuenciaApertura = analisis.data.consecuencia.apertura.maximaCOF ?
                    analisis.data.consecuencia.apertura.maximaCOF
                : analisis.data.consecuencia.apertura.financiera; } catch (error) { };
            try { consecuenciaCualitativaApertura = analisis.data.consecuencia.apertura.maximaCOF ?
                    analisis.data.consecuencia.apertura.maximaCOF
                :analisis.data.consecuencia.apertura.nivelDeConsecuenciaFinanciera; } catch (error) { };
            try { probabilidadApertura = analisis.data.probabilidad.apertura.total; } catch (error) { };
            try { probabilidadCualitativaApertura = analisis.data.probabilidad.apertura.nivelDeProbabilidad; } catch (error) { };
            try { riesgoApertura = analisis.data.riesgo.apertura.riesgoFinanciero; } catch (error) { };
            try { riesgoCualitativoApertura = analisis.data.riesgo.apertura.valorCualitativo; } catch (error) { };
            
            // Riesgo total
            try { riesgoTotalFinanciero = analisis.data.riesgo.total.financiera ?  analisis.data.riesgo.total.financiera : undefined; } catch (error) { };
            try { 
                if(!analisis.data.riesgo.total.financiera){
                    riesgoTotalNivelDeRiesgo = analisis.data.riesgo.total.nivelDeRiesgo;
                    riesgoTotalValorCualitativo = analisis.data.riesgo.total.valorCualitativo;
                } 
            } catch (error) { };
            const data  = {
                "id": item.id,
                "nombre": nombre || "None",
                "riesgoFuga": riesgoFuga,
                "consecuenciaFuga": consecuenciaFuga,
                "probabilidadFuga": probabilidadFuga,
                "riesgoCualitativoFuga": riesgoCualitativoFuga,
                "consecuenciaCualitativaFuga": consecuenciaCualitativaFuga,
                "probabilidadCualitativaFuga": probabilidadCualitativaFuga,
                "riesgoApertura": riesgoApertura,
                "consecuenciaApertura": consecuenciaApertura,
                "probabilidadApertura": probabilidadApertura,
                "riesgoCualitativoApertura": riesgoCualitativoApertura,
                "consecuenciaCualitativaApertura": consecuenciaCualitativaApertura,
                "probabilidadCualitativaApertura": probabilidadCualitativaApertura,
                "idDelSegmentoCritico": idDelSegmentoCritico,
                "nombreDelSegmentoCritico": nombreDelSegmentoCritico,
                "riesgoTotal": riesgoTotalFinanciero || true,
                "riesgoTotalNivelDeRiesgo":riesgoTotalNivelDeRiesgo,
                "riesgoTotalValorCualitativo":riesgoTotalValorCualitativo,
                "BoolMaximaCOF":BoolMaximaCOF,
            };

            listaDeNodos.push(data)
        });
    });
    return listaDeNodos;
}

const obtenerValorCualitativoMaximo = (rangosDeParametrizacion) => {
    let rangosComoLista = Object.keys(rangosDeParametrizacion).map((key, i) => { return { ...rangosDeParametrizacion[key] }; });

    let valorMaximo = rangosComoLista[0].valorCualitativo;
    rangosComoLista.forEach((rango, i) => { if (rango.valorCualitativo > valorMaximo) { valorMaximo = rango.valorCualitativo } });

    return valorMaximo;
}

// const obtenerValorMaximoDeIntervalos = (rangosDeParametrizacion) => {
//     let intervalos = Object.keys(rangosDeParametrizacion);
//     let unionDeLosIntervalos = MathInterval.union(...intervalos).toString();

//     let valorMaximo = 0;
//     for (let i = 0; i < unionDeLosIntervalos.length; i++) {
//         if (!isNaN(unionDeLosIntervalos[i]) && unionDeLosIntervalos[i] !== " ") {
//             if (parseFloat(unionDeLosIntervalos[i]) > valorMaximo) {
//                 valorMaximo = parseFloat(unionDeLosIntervalos[i]);
//             }
//         }
//     }

//     return valorMaximo;
// }

const guardarEnlaceEnLocalStorage = (props, path) => {
    localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    localStorage.setItem('regresarA', ("/app" + window.location.href.split("/app")[1]));
}

export default withRouter(TablaDeNodos);