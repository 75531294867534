import React, { useState } from 'react'
import Collapse from '@material-ui/core/Collapse';

function ItemExpandibleConsola({ message, expanded, ...props }) {
    const [state, setstate] = useState(false)
    return (
        <div style={{ textAlign: "left", cursor: "pointer" }} >
            <div onClick={() => {
                setstate((prevState) => (!prevState))
            }}>
                {message}
            </div>
            {expanded && <Collapse in={state} style={{ cursor: "text", fontSize: "smaller", opacity: 0.6,fontStyle:"oblique" }}>
                {expanded}
            </Collapse>}
        </div>
    )
}

export default ItemExpandibleConsola
