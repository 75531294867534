
import React, { Fragment } from "react";
import ReactTimeAgo from 'react-time-ago'
import { Row } from 'reactstrap';

let MensajeDeChat = (props) => {
    const obtenerAvatar = (orientacion) => {
        const nombre = props.nombre.length > 0 ? props.nombre[0].toUpperCase() : 'R';
        if (props.esDelUsuarioLogeado === orientacion) {
            return (
                <div>
                    <div className={"avatar-icon-wrapper " + (props.esDelUsuarioLogeado ? 'ml-1 ' : 'mr-1')}>
                        <div className={"avatar-icon avatar-icon-lg round text-center"} style={{ backgroundColor: (props.esDelUsuarioLogeado ? '#2E86C1' : '#148F77') }}>
                            <p style={{ fontSize: 22 }} className="text-white font-weight-bold">{nombre}</p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <Fragment>
            <Row>
                <div className={(props.esDelUsuarioLogeado ? 'float-right ml-auto' : '')}>
                    <div className={"chat-box-wrapper " + (props.esDelUsuarioLogeado ? 'chat-box-wrapper-right' : '')} >
                        {obtenerAvatar(false)}
                        <div>
                            <div className="chat-box overflow-auto" style={{ backgroundColor: (props.esDelUsuarioLogeado ? '#AED6F1' : '#ABEBC6') }}>
                                {props.mensaje}
                            </div>
                            <small className="opacity-6">
                                <ReactTimeAgo date={props.timestamp} locale="es" />
                            </small>
                        </div>
                        {obtenerAvatar(true)}
                    </div>
                </div>
            </Row>
        </Fragment>
    )
}

export default MensajeDeChat;
