export const opcionesParaGraficas = [
    {
        value: "dobleAgrupamiento",
        label: "Doble agrupamiento",
        opciones: [
            {
                value: "riesgo;tipoDeActivo",
                label: "Riesgo total por tipo de activo",
                keyAgrupamiento1: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
                keyAgrupamiento2: null,
                keyVariable: "resultadosDeLaValoracion.riesgo.riesgoCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "riesgo;campo",
                label: "Riesgo total por campo",
                keyAgrupamiento1: "informacionDeLosPadres.campoAsociado.nombre",
                keyAgrupamiento2: null,
                keyVariable: "resultadosDeLaValoracion.riesgo.riesgoCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "riesgo;tipoDeActivo;campo",
                label: "Riesgo total por tipo de activo y campo",
                keyAgrupamiento1: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
                keyAgrupamiento2: "informacionDeLosPadres.campoAsociado.nombre",
                keyVariable: "resultadosDeLaValoracion.riesgo.riesgoCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "probabilidad;tipoDeActivo",
                label: "Probabilidad total por tipo de activo",
                keyAgrupamiento1: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
                keyAgrupamiento2: null,
                keyVariable: "resultadosDeLaValoracion.probabilidad.probabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
            {
                value: "probabilidad;campo",
                label: "Probabilidad total por campo",
                keyAgrupamiento1: "informacionDeLosPadres.campoAsociado.nombre",
                keyAgrupamiento2: null,
                keyVariable: "resultadosDeLaValoracion.probabilidad.probabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
            {
                value: "probabilidad;tipoDeActivo;capo",
                label: "Probabilidad total por tipo de activo y campo",
                keyAgrupamiento1: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
                keyAgrupamiento2: "informacionDeLosPadres.campoAsociado.nombre",
                keyVariable: "resultadosDeLaValoracion.probabilidad.probabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
            {
                value: "consecuencia;tipoDeActivo",
                label: "Consecuencia total por tipo de activo",
                keyAgrupamiento1: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
                keyAgrupamiento2: null,
                keyVariable: "resultadosDeLaValoracion.consecuencia.consecuenciaFinanciera",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "consecuencia;campo",
                label: "Consecuencia total por campo",
                keyAgrupamiento1: "informacionDeLosPadres.campoAsociado.nombre",
                keyAgrupamiento2: null,
                keyVariable: "resultadosDeLaValoracion.consecuencia.consecuenciaFinanciera",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "consecuencia;tipoDeActivo;campo",
                label: "Consecuencia total por tipo de activo y campo",
                keyAgrupamiento1: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
                keyAgrupamiento2: "informacionDeLosPadres.campoAsociado.nombre",
                keyVariable: "resultadosDeLaValoracion.consecuencia.consecuenciaFinanciera",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
        ]
    },
    {
        value: "torta",
        label: "Torta",
        opciones: [
            {
                value: "riesgo",
                label: "Riesgo total",
                keyVariable: "resultadosDeLaValoracion.riesgo.riesgoCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "consecuencia",
                label: "Consecuencia total",
                keyVariable: "resultadosDeLaValoracion.consecuencia.consecuenciaFinanciera",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "probabilidad",
                label: "Probabilidad total",
                keyVariable: "resultadosDeLaValoracion.probabilidad.probabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
        ]
    }
]