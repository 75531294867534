import React from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { withRouter } from 'react-router-dom';

//Mockup del menu de navegación:
import LinkParaMetisMenu from './HipervinculoDeMenu.jsx';
import { MainNav, /*AdminNav*/ } from '../estaticos/menuDefault.js';

//Utilidades de redux:
import { setEnableMobileMenu } from '../../../storage/reducerParaTema.js';

class ContenedorDeLinks extends React.Component {

    state = {
        cliente: 'geopark',
        rol: 'admin',
        MainNav: MainNav,
    };

    toggleMobileSidebar = (e) => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    }

    render() {
        return (
            <React.Fragment>
                <h5 className="app-sidebar__heading">GIAcore</h5>
                <MetisMenu
                    content={this.state.MainNav}
                    onSelected={(e) => { this.toggleMobileSidebar(e) }}
                    activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down" LinkComponent={LinkParaMetisMenu} />

                {/* <h5 className="app-sidebar__heading">Administración</h5>
                <MetisMenu
                    content={AdminNav}
                    onSelected={this.toggleMobileSidebar}
                    activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down" LinkComponent={LinkParaMetisMenu} /> */}

            </React.Fragment>
        );
    }

    componentDidMount() {
        this.setState({ MainNav: this.props.parametrizacionCorporativa["menuPrincipal"]["menu"] });
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

const mapStateToProps = state => ({
    enableMobileMenu: state.reducerParaTema.enableMobileMenu,
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContenedorDeLinks));