import React from 'react'
import { Popover, Nav, Col, Row, NavItem, Button, Label, InputGroup, Input, Card, CardBody } from 'reactstrap'
import { TiFilter } from 'react-icons/ti';

//Componentes
import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

//iconos:
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class FiltrosPorFecha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtroVisible: false,
            fechaFiltroPrevia: undefined,
            fechaFiltroCustomInicio: new Date(new Date(new Date(Date.now())).getFullYear(), new Date(new Date(Date.now())).getMonth(), 1, 0, 0, 0, 0),
            fechaFiltroCustomFin: new Date(new Date(new Date(Date.now())).getFullYear(), new Date(new Date(Date.now())).getMonth() + 1, 1, 0, 0, 0, 0),
            dateInputDisable: true,
            customFilterMes: true,
            customFilterAnho: false,
            filtroSeleccionado: 'quincenal', //semanal, quincenal, mensual, anual, custom
            tipoDeFiltroCustom: 'customMensual' //customMensual
        }
    }

    componentDidMount() {
        this.setState({
            filtroSeleccionado: this.props.filtroActivo,
        });
    }

    render() {
        return (
            <>
                <BotonParaSection
                    key="btnMostrarFiltros"
                    onClick={this.cambiarEstadoDeFiltro}
                    icono={<TiFilter />} title="Filtrar" id="PopoverMegaMenu" />
                <Popover style={{ backgroundColor: 'white' }} className="rm-max-width" placement="bottom-end" fade={false} trigger="legacy" isOpen={this.state.filtroVisible} target="PopoverMegaMenu"
                    toggle={this.cambiarEstadoDeFiltro}>
                    <div className="dropdown-mega-menu w-100">
                        <Row>
                            <Col lg="12" sm="12">
                                <Nav vertical>
                                    <NavItem className="nav-item-header">
                                        Filtrar Ordenes de Trabajo
                                        </NavItem>
                                </Nav>
                                <Row>
                                    <Button
                                        outline
                                        className="btn-shadows btn my-1 mx-5 w-100"
                                        color={this.obtenerColorDeBoton('semanal')}
                                        onClick={() => this.cambiarFiltroActivo('semanal')}
                                        active={this.state.filtroSeleccionado === 'semanal'}
                                    >
                                        Última semana
                                    </Button>
                                </Row>
                                <Row>
                                    <Button
                                        outline
                                        className="btn-shadows btn my-1 mx-5 w-100"
                                        color={this.obtenerColorDeBoton('quincenal')}
                                        onClick={() => this.cambiarFiltroActivo('quincenal')}
                                        active={this.state.filtroSeleccionado === 'quincenal'}
                                    >
                                        Última quincena
                                    </Button>
                                </Row>
                                <Row>
                                    <Button
                                        outline
                                        className="btn-shadows btn my-1 mx-5 w-100"
                                        color={this.obtenerColorDeBoton('mensual')}
                                        onClick={() => this.cambiarFiltroActivo('mensual')}
                                        active={this.state.filtroSeleccionado === 'mensual'}
                                    >
                                        Último mes
                                    </Button>
                                </Row>
                                <Row>
                                    <Button
                                        outline
                                        className="btn-shadows btn my-1 mx-5 w-100"
                                        color={this.obtenerColorDeBoton('anual')}
                                        onClick={() => this.cambiarFiltroActivo('anual')}
                                        active={this.state.filtroSeleccionado === 'anual'}
                                    >
                                        Último año
                                    </Button>
                                </Row>
                                <Row>
                                    <Button
                                        className="btn-shadows btn my-1 mx-5 w-100"
                                        color={this.obtenerColorDeBoton('custom')}
                                        onClick={() => {
                                            this.setState({ dateInputDisable: false })
                                            this.cambiarFiltroActivo('custom')
                                        }}
                                        active={this.state.filtroSeleccionado === 'custom'}
                                    >
                                        <p className="m-0">Filtro Personalizado</p>
                                    </Button>
                                </Row>

                                {this.obtenerRenderDeCustomFilterPicker()}

                                <Row className="mt-4 mx-4 justify-content-around">
                                    <Button
                                        className=""
                                        style={{ width: 100 }}
                                        color={this.aplicarFiltroDisable() ? "light" : "primary"}
                                        disabled={this.aplicarFiltroDisable()}
                                        onClick={this.aplicarFiltroActivo}
                                    >
                                        Aplicar filtro
                                    </Button>
                                    <Button
                                        className=""
                                        style={{ width: 100 }}
                                        color={"secondary"}
                                        onClick={this.cambiarEstadoDeFiltro}
                                    >
                                        Cerrar
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Popover>
            </>
        );
    }

    //Funciones render
    obtenerDatePickerPorFiltro = () => {
        switch (this.state.tipoDeFiltroCustom) {
            case 'customAnual':
                return (
                    <DatePicker
                        selected={this.state.fechaFiltroCustomInicio}
                        onChange={(value, e) => { this.actualizarFechasFiltroCustom(value, e) }}
                        showYearPicker
                        maxDate={new Date()}
                        filterDate={this.filtrarFechasPermitidas}
                        className="form-control"
                        dateFormat="yyyy"
                        yearItemNumber={6}
                    />

                );
            case 'customMensual':
                return (
                    <DatePicker
                        selected={this.state.fechaFiltroCustomInicio}
                        onChange={(value, e) => { this.actualizarFechasFiltroCustom(value, e) }}
                        showMonthYearPicker
                        maxDate={new Date()}
                        className="form-control"
                        dateFormat="MM/yyyy"
                    />
                );
            default:
                return null;
        }
    }

    obtenerRenderDeCustomFilterPicker = () => {
        return (!this.state.dateInputDisable) ?
            (
                <Card className="mx-1 mt-3">
                    <CardBody>
                        <Row className="mb-3 justify-content-around">
                            <Label check>
                                <Input
                                    checked={this.state.customFilterMes}
                                    onChange={() => { 
                                        this.setState({ 
                                            customFilterAnho: false, 
                                            customFilterMes: !this.state.customFilterMes, 
                                            tipoDeFiltroCustom: 'customMensual',
                                            fechaFiltroCustomInicio: new Date(new Date(new Date(Date.now())).getFullYear(), new Date(new Date(Date.now())).getMonth(), 1, 0, 0, 0, 0),
                                            fechaFiltroCustomFin: new Date(new Date(new Date(Date.now())).getFullYear(), new Date(new Date(Date.now())).getMonth() + 1, 1, 0, 0, 0, 0),
                                        }) 
                                    }}
                                    type="checkbox" />{' '}
                                Mensual
                            </Label>
                            <Label check>
                                <Input
                                    checked={this.state.customFilterAnho}
                                    onChange={() => { 
                                        this.setState({ 
                                            customFilterAnho: !this.state.customFilterAnho,
                                            customFilterMes: false,
                                            tipoDeFiltroCustom: 'customAnual',
                                            fechaFiltroCustomInicio: new Date(new Date(new Date(Date.now())).getFullYear(), 0, 1, 0, 0, 0, 0),
                                            fechaFiltroCustomFin: new Date(new Date(new Date(Date.now())).getFullYear() + 1, 0, 1, 0, 0, 0, 0),
                                            }) 
                                        }}
                                    type="checkbox" />{' '}
                                Anual
                            </Label>
                        </Row>
                        <Row className="mx-2">
                            <Col className="px-0" xs="12">
                                <Label for="exampleDate">Selecciona una fecha:</Label>
                                <InputGroup className="mb-3 self-align-center">
                                    {this.obtenerDatePickerPorFiltro()}
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                </InputGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ) : null;
    }

    //Funciones operativas
    actualizarFechasFiltroCustom = (value, e) => {
        const nuevaFechaDeInicio = new Date(value).getTime();
        let nuevaFechaFinal = new Date(nuevaFechaDeInicio);

        if (this.state.tipoDeFiltroCustom === 'customAnual') {
            nuevaFechaFinal = nuevaFechaFinal.setFullYear(nuevaFechaFinal.getFullYear() + 1, 0, 1);

        } else if (this.state.tipoDeFiltroCustom === 'customMensual') {
            nuevaFechaFinal = nuevaFechaFinal.setMonth(nuevaFechaFinal.getMonth() + 1, 1);
        }

        this.setState({
            fechaFiltroPrevia: this.state.fechaFiltroCustomInicio,
            fechaFiltroCustomInicio: nuevaFechaDeInicio,
            fechaFiltroCustomFin: nuevaFechaFinal
        })
    }

    aplicarFiltroActivo = () => {
        if (this.state.filtroSeleccionado !== this.props.filtroActivo || (this.state.filtroSeleccionado === 'custom' && this.state.fechaFiltroCustomInicio !== this.state.fechaFiltroPrevia)) {
            this.props.aplicarFiltro(this.state.filtroSeleccionado, this.state.fechaFiltroCustomInicio, this.state.fechaFiltroCustomFin);
            this.setState({
                filtroVisible: !this.state.filtroVisible
            })
        }
    }

    aplicarFiltroDisable = () => {
        return !(this.state.filtroSeleccionado !== this.props.filtroActivo || (this.state.filtroSeleccionado === 'custom' && this.state.fechaFiltroCustomInicio !== this.state.fechaFiltroPrevia))
    }

    cambiarEstadoDeFiltro = () => {

        this.setState({
            filtroSeleccionado: this.props.filtroActivo,
            filtroVisible: !this.state.filtroVisible
        });
    }

    cambiarFiltroActivo = (nuevoFiltro) => {
        let dateInputState = true
        if (nuevoFiltro === 'custom') {
            dateInputState = false
        }
        this.setState({
            dateInputDisable: dateInputState,
            filtroSeleccionado: nuevoFiltro,
        });
    }

    filtrarFechasPermitidas = (fecha) => {
        return fecha < Date.now()
    }

    obtenerColorDeBoton = (tipoDeFiltro) => {
        if (this.state.filtroSeleccionado === tipoDeFiltro) {
            return 'success';
        } else {
            return 'light';
        }
    }
}

export default FiltrosPorFecha;