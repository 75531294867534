import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table';
import { Input, Button } from 'reactstrap';
import { MdSync } from "react-icons/md";
import { firestoreDB } from "../../../../../../firebase";

let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, };
let estiloSegmentos = {
	display: "flex",
	width: "100%",
	justifyContent: 'center',
	cursor: 'pointer',
	// textDecoration: 'underline', 
	overflowX: 'auto',
	// justifyContent: "none", 
	textDecoration: 'none',
	fontWeight: 'bold',
	color: 'rgba(32, 39, 140, 0.5)'
}

//Funcion para pasar de array a json
const formatGrid = (row) => {
	let newRow = {}
	for (let n = 0; n < row.length; n++) {
		newRow[n] = row[n]
	}
	return newRow
}

const getAnomalia = (props) => {
	let arrayData = props.anomalias.filter(anomalia => anomalia.anomalia.id === props.idAnomalia)
	return arrayData
}
//Obtiene la informacion de la grilla del documento de anomalia
const getDataGrid = (props) => {
	let array = []
	let dataGrid = []
	let arrayData = getAnomalia(props)
	array.push(arrayData[0].detalleGrilla.dataGrilla)
	Object.keys(array[0]).forEach((key) => {
		dataGrid.push(formatGrid(array[0][key]))
	})
	return dataGrid
}

function Grid(props) {
	const [data, setData] = useState(getDataGrid(props))
	const [anomalia, setAnomalia] = useState(getAnomalia(props))
	const [upToDate, setUpToDate] = useState(true)
	const [stateText, setStateText] = useState('Actualizado') //TODO revisar: si no se cambia el estado no deberia ser un parametro del state (setStateText)

	useEffect(() => {
		getColumns()
	}, [data])

	const actualizarDocumentoDeAnomalia = () => {
		const idOrdenDeTrabajo = props.ordenDeTrabajo;
		const idAnomalia = props.idAnomalia;
		let nuevaAnomalia = anomalia
		let dataGrilla = formatGridDB(data)
		setUpToDate(true)
		nuevaAnomalia[0].detalleGrilla['dataGrilla'] = dataGrilla
		setAnomalia(nuevaAnomalia)
		firestoreDB.collection('ordenesDeTrabajo').doc(idOrdenDeTrabajo)
			.collection('anomalias').doc('perdidaDeMetal').collection('anomalias').doc(idAnomalia)
			.set({ "detalleGrilla": nuevaAnomalia[0].detalleGrilla }, { merge: true })
			.then(() => {
			})
			.catch(e => {
				console.log(e);
			});
	}

	const actualizarGrid = (valor, row, column) => {
		let newData = [...data]
		newData[row][column] = valor
		setUpToDate(false)
		setData(newData)
	}

	const formatGridDB = (row) => {
		let jsonData = {}
		Object.keys(data).forEach((key) => {
			let arrayTemp = []
			Object.keys(data[key]).forEach((keys) => {
				arrayTemp.push(data[key][keys])
			})
			jsonData[key] = arrayTemp
		})
		return jsonData
	}

	const getCell = (row, key) => {
		if (key === '0')
		{
			return (
				<div key={`cell_container_${row._index}${key}`} style={{ ...estiloSegmentos, backgroundColor: (key === '0' ? '#FFFFF' : getColor(row._original[`${key}`])) }}>
					<p style={{ margin: '0 auto', color: "black", fontWeight: 300 }}
					> {row._original[`${key}`] || ""}</p>
				</div>
			);
		}
		else {
			return (
				(
					<div key={`cell_container_${row._index}${key}`} style={{ ...estiloSegmentos, backgroundColor: (key === '0' ? '#FFFFF' : getColor(row._original[`${key}`])) }}>
						<Input
							style={{ backgroundColor: getColor(row._original[`${key}`]), justifyContent: 'center', alignItems: 'center' }}
							type="text" name={`${key}`}
							id={`${row._index}${key}`}
							key={`cell_${row._index}${key}`}
							onChange={(e) => { actualizarGrid(e.target.value, row._index, parseInt(key)) }}
							value={row._original[`${key}`] || ""}
						/>
					</div>
				)
			)
		}
	}

	//Añade columnas al array de columns para la tabla
	const getColumns = () => {
		let columns = []
		let columnsHeaders = obtenerColumnas(Object.keys(data[0]).length - 1)
		Object.keys(data[0]).forEach((key) => {
			let column = {
				Header: () => (<p style={estiloParaHeader}>{columnsHeaders[parseInt(key)]}</p>),
				Cell: ({ row }) => getCell(row, key),
				minWidth: 80,
				maxWidth: (key === '0' ? 80 : 800)
			}
			columns.push(column)
		})
		return columns
	}
	//Devuelve el color segun la escala
	const getColor = (valor) => {
		let [max, min] = getLimites(data)
		let perc = max === min ? 100 : (valor - min) * 100 / (max - min)
		let rgb = perc2color((100 - perc))
		return rgb
	}

	//Obtiene valor max y min de la tabla
	const getLimites = () => {
		let arrayMax = []
		let arrayMin = []
		for (let n = 0; n < Object.keys(data).length; n++) {
			let array = []
			Object.keys(data[n]).forEach((key) => {
				array.push(data[n][key])
			})
			arrayMax.push(Math.max(...array.slice(1)))
			arrayMin.push(Math.min(...array.slice(1)))
		}
		return [Math.max(...arrayMax), Math.min(...arrayMin)]
	}

	//Devuelve un array con los headers de las columnas
	const obtenerColumnas = (columns) => {
		let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
		let array = ['']
		let m = 0
		let x = 0
		for (let n = 0; n < columns; n++) {
			if (x > 25) {
				x = 0
				m++
			}
			if (m > 0) {
				array.push(`${alphabet[m - 1]}${alphabet[x]}`)
			}
			else {
				array.push(`${alphabet[x]}`)
			} x++
		}
		return array
	}

	//Obtiene el codigo del color
	const perc2color = (perc) => {
		let r, g, b = 0;
		if (perc < 50) {
			r = 255;
			g = Math.round(5.1 * perc);
		}
		else {
			g = 255;
			r = Math.round(510 - 5.10 * perc);
		}
		var h = r * 0x10000 + g * 0x100 + b * 0x1;
		return '#' + ('000000' + h.toString(16)).slice(-6);
	}

	return (
		<React.Fragment>
			<div key={'grid_table_contairner'}>
				<br />
				<ReactTable
					key={'grid_table'}
					rowsText={'filas'}
					data={data}
					showPagination={false}
					defaultPageSize={data.length}
					className="-striped -highlight"
					columns={
						[
							{
								Header: () => (
									<div className={'modal-grid-state'}>
										<div></div>
										<div><p style={{ ...estiloParaHeader, }}>Grilla</p></div>
										<div>{!upToDate ? <Button
											color="link"
											style={{ justifyContent: 'center', alignItems: 'center' }}
											onClick={() => { actualizarDocumentoDeAnomalia() }}
										>
											<MdSync size={16} />
										</Button> : <p style={{ ...estiloParaHeader, color: '#28a745' }}>{stateText}</p>}</div>
									</div>),
								columns: getColumns(data)
							}
						]
					}
					style={props.style}
					{...props.tableProps}
				/>
				<br />
			</div>
		</React.Fragment>

	);
}

export default Grid;