import React from 'react';
import { Button } from 'reactstrap';
import { IoMdAdd } from "react-icons/io";

let BotonCallToAction = (props) => {
    if(props.usuarioActivo){
        if(props.usuarioActivo.rol !== 'cliente'){
            return(
                <Button className={"text-white " + props.className? props.className : ''}
                    disabled={props.disabled || false}
                    color={ props.color || "primary" } style={props.style}
                    onClick={props.onClick}>
                    {obtenerIcono(props)}
                    {props.titulo? props.titulo : ''}
                </Button>
            );
        }
    }
    return (
        <Button className={props.estilos || "text-white"}
            disabled={habilitarUsuario(props)}
            color={ props.color || "primary" } style={props.style}
            onClick={props.onClick}>
            {obtenerIcono(props)}
            {props.titulo? props.titulo : ''}
        </Button> 
    );
}

const habilitarUsuario = (props) => {
    let rolesPermitidos = ['admin', 'supervisor', 'tecnico']
    if (props.usuarioActivo) { return rolesPermitidos.includes(props.usuarioActivo.rol) }
    else return false
}

let obtenerIcono = (props) => {
    let Componente = props.icono;
    if (props.icono) {
        return (<Componente size={15} style={{ strokeWidth: 0.75, marginRight: 12, color: props.colorDeIcono || 'white'}} />);
    }
    else {
        return (<IoMdAdd size={15} style={{ strokeWidth: 0.75, marginRight: 12, color: 'white'}} />);
    }
}

export default BotonCallToAction;