import React from 'react';
import { Button } from 'reactstrap';
import { FaCalculator } from 'react-icons/fa';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';


let BotonParaSection = (props) => {
    if (props.buttonTableHeader === true) {
        return (
            <Tooltip title={props.title}>
            <IconButton
                aria-label="download"
                onClick={props.onClick}
                id={props.id}
            >
                <FaCloudDownloadAlt />
            </IconButton>
            </Tooltip>
        )
    }
    else {
        return (
            <Button size="sm" color={props.color || "light"} title={props.title}
                style={{ marginLeft: 10 }} onClick={props.onClick} id={props.id}>
                {props.icono || <FaCalculator />}
            </Button>
        );
    }
    
}

export default BotonParaSection;