import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataGwt = (nodoCml) => {
    const dataGwt = [];
    try {
        nodoCml.forEach((item, i) => {
            const resVarA = item?.variablesDeLaInspeccion?.utScanA;
            const resInf = item?.informacionGeneral;
            const resVarOnd = item?.variablesDeLaInspeccion?.ondasGuiadas;
            const coordenadas = armarCoordenadas(nodoCml);
            dataGwt.push(
                {
                    item: { 
                        label:'Item', 
                        valor: i+1, 
                        minWidth: 50
                    },
                    locacion: {
                        label:resVarA?.locacion?.nombre,
                        valor: resVarA?.locacion?.valor
                    },
                    diametro: {
                        label: 'Diámetro (in)',
                        valor: formatearvalorsegunestado(resVarA?.diametro?.valor, 0)
                    },
                    tipoDeAccesorio: {
                        label:'Tipo de accesorio',
                        valor:resInf?.tipoDeElemento || 0
                    },
                    abscisa: {
                        label:'Abscisa',
                        valor: resVarA?.ubicacion?.valor || ' - '
                    },
                    latitudGps: {
                        label:'Latitud GPS',
                        valor: coordenadas?.lat || ' - '
                    },
                    longitudGps: {
                        label:'Longitud GPS',
                        valor: coordenadas?.lng || ' - '
                    },
                    longitudInspeccion: {
                        label:'Longitud de inspección (m)', 
                        valor:formatearvalorsegunestado(resVarOnd?.longitudInspeccion?.valor, 1)
                    },
                    numeroIndicaciones: {
                        label:'Número de indicaciones (#)',
                        valor:formatearvalorsegunestado(resVarOnd?.indicaciones?.valor, 0)
                    },
                    nivelDeRiesgo: {
                        label:'Nivel de riesgo',
                        valor: resVarOnd?.nivelDeRiesgo?.valor || ' - '
                    },
                    // tiempoDeSeguimiento:{
                    //     label: 'Tiempo de seguimiento (años)',
                    //     valor:resVarOnd?.tiempoDeSeguimiento?.valor, 
                    //     minWidth: 110 || 0
                    // },
                    segmento: {
                        label:resVarOnd?.condicionGwt?.nombre, 
                        valor: resVarOnd?.condicionGwt?.valor || ' - ', 
                        minWidth: 280
                    },
                },
            )
        })
    }
    catch(error) {console.log(error)}
    return dataGwt
}

export default armarDataGwt

const armarCoordenadas = (nodoCml) => {
    try{
        let coordenadas = {};
        nodoCml.forEach(item => {
            if(item.variablesDeLaInspeccion.coordenadas !== undefined) {
                let lng
                let lat
                if ( item?.variablesDeLaInspeccion?.ondasGuiadas?.coordenadaGwt?.valor !== null && 
                    item?.variablesDsiseLaInspeccion?.ondasGuiadas?.coordenadaGwt?.valor !== undefined &&
                    item?.variablesDeLaInspeccion?.ondasGuiadas?.coordenadaGwt?.valor !== 0){
                    lng = item?.variablesDeLaInspeccion?.ondasGuiadas?.coordenadaGwt?.valor.split(',')[1];
                    lat = item?.variablesDeLaInspeccion?.ondasGuiadas?.coordenadaGwt?.valor.split(',')[0];
                }
                coordenadas['lng'] = lng;
                coordenadas['lat'] = lat;
            }
        })
        return coordenadas;
    }
    catch(error){console.log(error)}
}
