import React from 'react';
import { Card, ListGroup, ListGroupItem, CardHeader, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

//Imports auxiliares
import PerfectScrollbar from 'react-perfect-scrollbar';

let defaultAvatar = "/assets/img/iconos/avatar.webp";

const ListaEmpresas = (props) => {
    return (
        <Card className="main-card mb-0 shadow-sm border border-light">
            <CardHeader style={{fontSize: 18, backgroundColor: '#F8F9F9'}}>Empresas existentes</CardHeader>
            <div className="scroll-area">
                <PerfectScrollbar>
                    <ListGroup className="todo-list-wrapper" flush>
                        {obtenerRenderDeTarjetasUsuario(props)}
                    </ListGroup>
                </PerfectScrollbar>
            </div>

        </Card>
    );
}

const guardarEnlaceEnLocalStorage = (path) => {
    localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
}

const obtenerRenderDeTarjetasUsuario = (props) =>{
    if(props.usuarios){
        return props.usuarios.map((empresa, index) =>{
            return(
                <ListGroupItem className='py-1' key={'permitido'+ empresa.correo + index}>
                    <div className="widget-content p-0">
                    <div className="todo-indicator d-flex align-items-center" style={{height: 50, width: 5, backgroundColor: '--'}}/>
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                                <div className="widget-content-left">
                                    <img width={60} className="rounded" src={defaultAvatar} alt="" />
                                </div>
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading" style={{fontSize: 17}}>
                                    {empresa.nombreEmpresa}
                                </div>
                                <div className="widget-subheading" style={{color: '#273746', fontWeight: 'bold'}}>
                                    Dominio: {empresa.dominioCorporativo}
                                </div>
                                <div className="widget-subheading" style={{color: '#273746', fontWeight: 'bold'}}>
                                    Cantidad de Usuarios Permitidos: {empresa.usuariosPermitidos}
                                </div>
                            </div>
                            <div className="widget-content-right">
                                    <Link 
                                    to={`/app/administrador/empresas/${empresa.id}`}
                                    onContextMenu={() => guardarEnlaceEnLocalStorage(`/app/administrador/empresas/${empresa.id}`)}
                                    onClick={() => guardarEnlaceEnLocalStorage(`/app/administrador/empresas/${empresa.id}`)} 
                                    style={{textDecoration: 'none'}}>
                                        <Button size="lg" color="success">Ver</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            );
        })
    }
}

export default ListaEmpresas;