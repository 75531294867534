import Swal from 'sweetalert2';

const dispararAlertExitoso = (titulo, subtitulo) => {
    Swal.fire({
      title: titulo,
      html: '<span style="font-size:14px;">' + subtitulo + '</span>',
      icon: 'success',
    });
}

const dispararAlertFallido = (titulo, subtitulo) => {
    Swal.fire({
        title: titulo,
        html: '<span style="font-size:14px;">' + subtitulo + '</span>',
        icon: 'error',
    });
}

export { dispararAlertExitoso, dispararAlertFallido }