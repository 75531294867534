const armarNombre = (data, id) => {
    let codLinea = getCodLinea(data)
    let pk = data.distanciaRegistroDucto?data.distanciaRegistroDucto.toString().split(".")[0]:'0'
    let codigoSAP = id
    let actividad = getActividad(data)
    let consecutivo = data.consecutivo ? data.consecutivo : 0
    return `${codLinea}(${pk})-4457-${codigoSAP}-BC-IE_${actividad}-${consecutivo}`

}

const getCodLinea = (data) => { 
    switch (data.lineaDeDucto.toUpperCase()) {

        case "AYACUCHO - COVEÑAS 24”": return 'D095'
        case "AYACUCHO - GALÁN 8”": return 'D050'
        case "AYACUCHO - RETIRO 16”": return 'D030'
        case "AYACUCHO-GALÁN 14”": return 'D010'
        case "BANADIA - SAMORE": return 'D070'
        case "CAÑO LIMÓN - BANADIA": return 'D065'
        case "CARTAGENA - BARANOA 12”": return 'D020'
        case "CAUCASIA - COVEÑAS 24”": return 'D505'
        case "COVEÑAS - CARTAGENA 18”": return 'D025'
        case "EL COPEY - AYACUCHO 14”": return 'D005'
        case "GALÁN - AYACUCHO 14”": return 'D060'
        case "GALÁN - AYACUCHO 18”": return 'D055'
        case "GALÁN-LIZAMA": return 'D100'
        case "GALÁN-SEBASTOPOL 12”": return 'D125'
        case "GALÁN-SEBASTOPOL 16”": return 'D120'
        case "GALÁN-SEBASTOPOL 8”": return 'D130'
        case "LIZAMA-TRAMPA TIENDA NUEVA": return 'D105'
        case "LOOP SINCÉ - COVEÑAS 16”": return 'D045'
        case "ORU - AYACUCHO": return 'D090'
        case "POZOS COLORADOS - EL COPEY 14”": return 'D001'
        case "RIO ZULIA - ORU": return 'D085'
        case "SAMORE - TOLEDO": return 'D075'
        case "SEBASTOPOL - SALGAR  8”": return 'D145'
        case "SEBASTOPOL - SALGAR 12 ”": return 'D140'
        case "SEBASTOPOL - SALGAR 16 ”": return 'D135'
        case "SEBASTOPOL - SAN JOSE DEL NUS": return 'D150'
        case "SEBASTOPOL - SANTAROSA": return 'D170'
        case "SINCÉ - COVEÑAS 12”": return 'D040'
        case "TOLEDO - RÍO ZULIA": return 'D080'
        case "TRAMPA GUAYACAN - CHIMITA": return 'D115'
        case "TRAMPA TIENDA NUEVA - TRAMPA GUAYACAN": return 'D110'
        case "VASCONIA - CAUCASIA ": return 'D500'
        case "VASCONIA - CIB 20”": return 'D355'
        case "VASCONIA - VELÁSQUEZ 12”": return 'D380'
        default: return 'n/a' 
    }
}

const getActividad=(data)=>{
    if (data.actividad !== undefined) {
        if (data.actividad.includes('ANM')) { return 'ANM' }
        else if (data.actividad.includes('AND')) { return 'AND' }
        else if (data.actividad.includes('ANC')) { return 'ANC' }
        else if (data.actividad.includes('ARP')) { return 'ARP' }
    }
    else { return 'n/a' }
}
export default armarNombre