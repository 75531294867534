import React from 'react';
import { withRouter, Link } from 'react-router-dom';

//Componentes auxiliares:
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';

//Constantes auxiliares:
const estiloDeFuentesBase = { fontSize: 13 };
const estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, ...estiloDeFuentesBase, cursor: "pointer" };

let TablaDeNodosSistemas = (props) => {
    let listaDeNodos = props.listaDeNodos || [];
    return (
        <CustomTable criterioDeOrdenamiento={props.criterioDeOrdenamiento}
            listaDeDatos={[...obtenerListaDeNodosFormateada(listaDeNodos)]}
            columnas={[
                {
                    accessor: 'nombre',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                    Cell: row => (
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                            <Link to={'/app/detalleDeNodo/sistemas/' + row.original.id+ '/' + props.moduloTarget}
                                onContextMenu={() => {
                                    onClickConRedireccion(props, '/app/detalleDeNodo/' 
                                        + props.coleccionActiva + '/' + row.original.id + '/' + props.moduloTarget);
                                }}
                                onClick={() => {
                                    onClickConRedireccion(props, '/app/detalleDeNodo/'
                                        + props.coleccionActiva + '/' + row.original.id + '/' + props.moduloTarget);
                                }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14, }} title={row.original.nombre}>
                                    {row.original.nombre}
                                </p>
                            </Link>
                        </div>
                    ),
                },
            ]} />
    );
}




//Funciones operativas:
const obtenerListaDeNodosFormateada = (listaOriginal) => {
    return listaOriginal.map((item, i) => {

        let nombre = undefined;

        try { nombre = item.informacionGeneral.nombre; } catch (error) { };

        return {
            "id": item.id,
            "nombre": nombre,
        };
    });
}


const onClickConRedireccion = (props, path) => {
    // props.history.push(path);
    localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
}

export default withRouter(TablaDeNodosSistemas);