import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

function ApexChart(props) {
    const [state, setState] = useState(parseData());

    useEffect(() => {
        if (props.opciones) {
            setState(parseData(props));
        }
        return () => { }
    }, [props]);

    function parseData(props) {
        const defaultOptions = {
            series: [44, 55, 13, 43, 22],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
            },
        };

        if(props && props.opciones) return {...defaultOptions, ...props.opciones}
        return defaultOptions;
    }

    return (
        <div id="chart">
            <ReactApexChart
                options={state.options} 
                series={state.series}
                type={state.options.chart.type}
                width={state.options.chart.width}
            />
        </div>
    );
}

export default ApexChart;