import { errorToast } from "../../../../../../utilidades/Toastify/Toastify"

const { firebaseFunctions } = require('../../../../../../firebase')
const exportarConsultas = firebaseFunctions.httpsCallable('exportarConsultas');

async function obtenerResumen(dominioCorporativo) {
    return exportarConsultas({ option: "obtenerResumen", dominioCorporativo })
        .then((response) => response.data)
        .catch(() => {
            errorToast("Error obteniendo resumen", "error")
            throw new Error("Error obteniendo resumen")
        })
}

export default obtenerResumen;