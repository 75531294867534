import isDate from 'lodash/isDate';
import React from 'react';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable'

const estiloDeFuentesBase = { fontSize: 13, textAlign: "center" };
const estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, ...estiloDeFuentesBase, cursor: "pointer" };
const estiloParaHeaderInput = { color: "rgba(0, 0, 0,0.5)", marginBottom: 0, ...estiloDeFuentesBase, cursor: "pointer" };

const VisualizadorDePlanes = (props) => {
    return (
        <React.Fragment>
            <CustomTable
                listaDeDatos={[...obtenerListaFormateada(props.nodo)]}
                columnas={obtenerColumnas(props)}
            />
        </React.Fragment>
    )
}

const obtenerListaFormateada = (nodo) => {
    let resultado = [];
    if (nodo.resultados) {
        const datos = nodo.resultados[0].data.planesDeAccion;
        resultado = Object.keys(datos).map(item => {
            return {
                "idPlan": item,
                "descripcion": datos[item].descripcion,
                "fechaProx": datos[item].fechaProx,
                "tipo": datos[item].tipo,
                "fechaUltimaInspeccion": nodo.parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.fechaUltimaInspeccion,
                "rsgTrgtCuant": nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCuant,
                "rsgTrgtCual": nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCual,
                "tipoDePrueba": nodo.parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.tipoDePrueba,
                "tInsp": nodo.parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.tInsp,
                "tipoDeValvula": nodo.parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.tipoDeValvula,
                "fechaDeValoracionRBI": nodo.parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.fechaDeValoracionRBI,
                "fechaDeInstalacion": nodo.parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.fechaDeInstalacion,
                "servicio": nodo.parametrosDeEntrada.informacionDePRD.informacionDeFluidoSeveridadTipo.servicio,
            }
        });
    }

    return resultado
}

const obtenerColumnas = (props) => {
    let columnasBase = [];
    columnasBase = [
        {
            Header: () => (<p style={{ ...estiloParaHeader }}>Resultados</p>),
            columns: [
                {
                    show: true,
                    accessor: 'tipo',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Tipo de inspección requerida</p>),
                    Cell: row => (
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                            <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                {row.original.tipo}
                            </p>
                        </div>
                    )
                },
                {
                    show: true,
                    accessor: 'fechaProx',
                    alignItems: 'center',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Fecha proxima de inspección</p>),
                    Cell: row => {
                        let valueCell = row.original.fechaProx || "-";
                        if (isDate(valueCell)) valueCell = new Date(valueCell).toLocaleDateString();
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {valueCell}
                                </p>
                            </div>
                        )
                    },
                },
                {
                    show: true,
                    accessor: 'descripcion',
                    minWidth: 700,
                    Header: () => (<p style={{ ...estiloParaHeader }}>Descripción de la inspección</p>),
                    Cell: row => {
                        const descripcion = row.original.descripcion.split('\n');
                        return (
                            <div style={{ overflow: "hidden", width: "auto" }}>
                                {descripcion.map((item, i) => { return (<p key={i}>{item}</p>) })}
                            </div>
                        )
                    }

                },
            ]
        },
        {
            Header: "Parámetros de Entrada",
            columns: [
                {
                    show: true,
                    accessor: 'riesgo',
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>{obtenerPropiedadesReisgo(props).title}</p>),
                    Cell: row => {
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {obtenerPropiedadesReisgo(props).valor}
                                </p>
                            </div>
                        )
                    }
                },
                {
                    show: true,
                    accessor: 'fechaUltimaInspeccion',
                    minWidth: 185,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Fecha de ultima inspección</p>),
                    Cell: row => {
                        let valueCell = row.original.fechaUltimaInspeccion || "-";
                        if (isDate(valueCell)) valueCell = new Date(valueCell).toLocaleDateString();
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {valueCell}
                                </p>
                            </div>
                        )
                    }
                },
                {
                    show: true,
                    accessor: 'fechaDeValoracionRBI',
                    minWidth: 180,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Fecha de valoración RBI</p>),
                    Cell: row => {
                        let valueCell = row.original.fechaDeValoracionRBI || "-";
                        if (isDate(valueCell)) valueCell = new Date(valueCell).toLocaleDateString();
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {valueCell}
                                </p>
                            </div>
                        )
                    },
                },
                {
                    show: true,
                    accessor: 'fechaDeInstalacion',
                    minWidth: 145,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Fecha de instalación</p>),
                    Cell: row => {
                        let valueCell = row.original.fechaDeInstalacion || "-";
                        if (isDate(valueCell)) valueCell = new Date(valueCell).toLocaleDateString();
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {valueCell}
                                </p>
                            </div>
                        )
                    },
                },
                {
                    show: true,
                    accessor: 'tipoDePrueba',
                    minWidth: 120,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Tipo de prueba</p>),
                    Cell: row => {
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {row.original.tipoDePrueba}
                                </p>
                            </div>
                        )
                    }
                },
                {
                    show: true,
                    accessor: 'tInsp',
                    minWidth: 140,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Tiempo de inspección</p>),
                    Cell: row => {
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {typeof (row.original.tInsp) === "number" ? row.original.tInsp.toFixed(2) : row.original.tInsp} años
                                </p>
                            </div>
                        )
                    }
                },
                {
                    show: true,
                    accessor: 'tipoDeValvula',
                    minWidth: 120,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Tipo de válvula</p>),
                    Cell: row => {
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {row.original.tipoDeValvula}
                                </p>
                            </div>
                        )
                    }
                },
                {
                    show: true,
                    accessor: 'servicio',
                    minWidth: 200,
                    Header: () => (<p style={{ ...estiloParaHeaderInput }}>Servicio</p>),
                    Cell: row => {
                        return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
                                <p style={{ marginBottom: 0, cursor: "pointer", textAlign: "left", ...estiloDeFuentesBase, fontSize: 14 }}>
                                    {row.original.servicio}
                                </p>
                            </div>
                        )
                    }
                },
            ]
        }

    ];

    return columnasBase;
}


const obtenerPropiedadesReisgo = (props) => {
    switch (props.nodo.parametrosDeEntrada.modeloDeConsecuencia) {
        case "Semicuantitativo":
            return { 'title': "Riesgo total", "valor": props.nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCuant }
        case "Cualitativo":
            return { 'title': "Riesgo máximo", "valor": props.nodo.parametrosDeEntrada.informacionDePRD.informacionGeneral.rsgTrgtCual }
        default:
            return "Riesgo total/máximo";
    }
}
export default VisualizadorDePlanes;