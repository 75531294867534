import React from 'react'
import { Row, Col, Button } from 'reactstrap'

import { RiFileExcel2Line } from 'react-icons/ri'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { FaCloudDownloadAlt } from 'react-icons/fa';


import BotonParaSection from '../../../../../utilidades/componentes/plantillaBase/BotonParaSection'
import CustomModal from '../../../../../utilidades/componentes/plantillaBase/CustomModal'
import CustomLoadingOverlay from '../../../../../utilidades/componentes/plantillaBase/CustomLoadingOverlay'
import { dispararAlertExitoso, dispararAlertFallido } from '../../../../../utilidades/Swal/Swal'

import { servicioGenerarControlDeInformes } from '../../services/paraGenerarInformes'
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
class DescargaTablaInspeccion extends React.Component{


    state = {
        estaAbierto: false,
        loading: false,
        consolaAbierta:false,
    }
    
    abrirModal = () => this.setState({ estaAbierto:true})
    cerrarModal = () => this.setState({ estaAbierto:false})
    toggleModal = () => this.setState({consolaAbierta:!this.state.consolaAbierta})
  
    descargaTabla = async (formato) => {
        this.setState({ loading: true })

        const data = {
            formato: formato
        }
        try {
            const downloadUrl = await servicioGenerarControlDeInformes(data)

            if (this.state.estaAbierto) {
                this.toggleModal();
                dispararAlertExitoso('Descarga exitosa', 'Revisa las ventanas emergentes');
            }

            window.open(downloadUrl);
        }
        catch (error) {
            if (this.state.estaAbierto) {
                this.toggleModal();
            }

            dispararAlertFallido(
                'Descarga fallida',
                error.message || 'El proceso de exportación no pudo finalizar correctamente'
            );
        } finally {
            this.setState({ loading: false })
        }
    }

    botonExcel = () => {
        if(!['admin','supervisor', 'tecnico'].includes(this.props.usuarioActivo.rol)) return
        return(
            <Col sm={12} lg={6}>
                <Button 
                className="btn-wide pusheable-button w-100"
                style={{marginBottom:20,backgroundColor:'#138D75'}}
                onClick={() => this.descargaTabla("EXCEL")}
                > 
                    <RiFileExcel2Line className='mr-3' size={18} />
                    Excel
                </Button>
            </Col>
        )
    }

    botonPDF = () => {
        if(!['admin','supervisor', 'tecnico', 'cliente'].includes(this.props.usuarioActivo.rol)) return

        return(
            <Col sm={12} lg={6}>
                <Button 
                className="btn-wide pusheable-button w-100"
                style={{marginBottom:20,backgroundColor:'#CB4335'}}
                onClick={() => this.descargaTabla("PDF")}
                > 
                    <AiOutlineFilePdf className='mr-3' size={18} />
                    PDF
                </Button>
            </Col>
        )
    }


    render(){
        return(
            <React.Fragment>

                <BotonParaSection
                    buttonTableHeader={true}
                    key="botonExportarTablaInspeccion"
                    onClick={this.abrirModal}
                    icono={<FaCloudDownloadAlt/>}
                    title="Exportar"
                />
               

                <CustomModal
                    elModalEstaAbierto={this.state.estaAbierto}
                    hideFooter={true}
                    size="md"
                    titulo={
                        <span className="h5 font-weight-bold">Consola de descarga</span>
                    }
                    toggleDelModal={this.cerrarModal}>

                    <Row
                        style={{
                            padding: 15,
                            fontSize: 16
                        }}>

                        <Col xs="12" md="12" lg="12" xl="12">
                            <div className="widget-heading">
                                <p>Tabla de inspeccion</p>
                            </div>
                            <div className="widget-subheading text-justify"
                                style={{ opacity: 1 }}>
                                Selecciona una opcion para iniciar el proceso. Esto puede tardar algunos segundos
                            </div>
                            <hr />
                            <CustomLoadingOverlay active={this.state.loading}>
                                <p><b>Descargar informe</b></p>
                                <Row>
                                    {this.botonExcel()}
                                    {this.botonPDF()}
                                </Row>
                            </CustomLoadingOverlay>
                        </Col>
                    </Row>

                </CustomModal>
            </React.Fragment>
        )
    }
}    

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
})

export default connect(mapStateToProps)(DescargaTablaInspeccion)