import React from 'react';
import { Link } from 'react-router-dom';

import datosDePrueba from '../../scripts/datosDePrueba';
import CustomTableDinamica from './CustomTableDinamica';

import TablaConServerPaginator  from '../../../vistas/aplicacion/integridad/API570/componentes/TablaConServerPaginator';

const estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0, alignItems: 'center'};
const estiloCeldas = { display: "flex", width: "100%", justifyContent: 'center', textDecoration: 'none' }
/**
 * Este componente crea la tabla de react Table dinámicamente, se necesita unicamente pasarle los datos de la tabla organizados
 * EJ: const tabla = [
 *  {
 *      dato1: {label: nombre del encabezado, valor: valor del dato}
 *  }
 * ]
 */
const CrearTablaDinamicamente = (props) => {

    const crearColumnasParaTabla = (mensajeTablaVacia) => {
        const datosTablaInternos = [];
        if(props.datosDeTabla === undefined){
            datosTablaInternos.push({
                accesor: mensajeTablaVacia || 'No hay datos para mostrar',
                Header: () => (<p style={estiloParaHeader}>{mensajeTablaVacia || 'No hay datos para mostrar'}</p>),
                minWidth: 100,
                resizable: false,
                Cell: ({ row }) => (
                    <div style={{ ...estiloCeldas }}>
                        Sin datos
                    </div>
                ),
            })
            return datosTablaInternos
        }
        if (props.datosDeTabla[0] !== undefined) {

        Object.keys(props.datosDeTabla[0]).forEach(dato => {
            if(props.datosDeTabla[0][dato].condicion === undefined){
                datosTablaInternos.push({
                    accesor: `${dato}`,
                    Header: () => (<p style={estiloParaHeader}>{props.datosDeTabla[0][dato].label}</p>),
                    minWidth: props.datosDeTabla[0][dato].minWidth || 100,
                    minHeigth: props.datosDeTabla[0][dato].minHeigth || 50,
                    resizable: props.datosDeTabla[0][dato].resizable || false,
                    Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }}>
                            <span style={{textAlign: 'center'}}>{`${row._original[dato]}`}</span>
                        </div>
                    ),
                    
                });
            } else if(props.datosDeTabla[0][dato].condicion === 'link'){
                datosTablaInternos.push({
                    accesor: `${dato}`,
                    Header: () => (<p style={estiloParaHeader}>{props.datosDeTabla[0][dato].label}</p>),
                    minWidth: props.datosDeTabla[0][dato].minWidth || 100,
                    resizable: props.datosDeTabla[0][dato].resizable || false,
                    Cell: ({ row }) => {
                        const ruta = props.datosDeTabla[0][dato].ruta + '/' + row._original[dato].split("/")[1] + '/' + row._original[dato].split("/")[2] + '/' + row._original[dato].split("/")[3]
                        return (<div style={{ ...estiloCeldas }}>
                            <Link to={ruta} 
                            onClick={() => {guardarEnElLocalStorage(ruta);}}
                            >
                                <p style={{ margin: '0 auto', color: '#545cd8', fontWeight: 300, textDecoration: 'underline', overflowX: 'auto', textAlign: 'center'  }}
                                >{row._original[dato].split("/")[0]}</p>
                            </Link>
                        </div>
                    )},
                })
            } else if(props.datosDeTabla[0][dato].condicion === 'fecha'){
                datosTablaInternos.push({
                    accesor: `${dato}`,
                    Header: () => (<p style={estiloParaHeader}>{props.datosDeTabla[0][dato].label}</p>),
                    minWidth: props.datosDeTabla[0][dato].minWidth || 100,
                    resizable: props.datosDeTabla[0][dato].resizable || false,
    
                    Cell: ({ row }) => {
                        return (<div style={{ ...estiloCeldas }}>
                            <Link to={props.datosDeTabla[0][dato].ruta} 
                            onClick={() => {guardarEnElLocalStorage(props.datosDeTabla[0][dato].ruta)}}
                            >
                                <p style={{ margin: '0 auto', color: '#545cd8', fontWeight: 300, textDecoration: 'underline', marginLeft: 100, marginRight:100, overflowX: 'auto'  }}
                                >{row._original[dato]}</p>
                            </Link>
                        </div>
                    )},
                })
            } else if (props.datosDeTabla[0][dato].condicion === 'dataEnriquecida'){
                datosTablaInternos.push({
                    accesor: `${dato}`,
                    Header: () => (<p style={estiloParaHeader} dangerouslySetInnerHTML={{ __html: props.datosDeTabla[0][dato].label}}></p>),
                    minWidth: props.datosDeTabla[0][dato].minWidth || 100,
                    resizable: props.datosDeTabla[0][dato].resizable || false,
                    Cell: ({ row }) => (
                        <div style={{ ...estiloCeldas }} >
                            {`${row._original[dato]}`}
                        </div>
                    ),
                    
                });
            } else if (props.datosDeTabla[0][dato].condicion === 'hover'){
                try{
                    datosTablaInternos.push({
                        accesor: `${dato}`,
                        Header: () => (<p style={estiloParaHeader}>{props.datosDeTabla[0][dato].label}</p>),
                        minWidth: props.datosDeTabla[0][dato].minWidth || 100,
                        resizable: props.datosDeTabla[0][dato].resizable || false,
                        Cell: ({ row }) => (
                            <div style={{ ...estiloCeldas, cursor: 'pointer' }} onMouseEnter={() =>{try{ return props.hover('', row._original[dato])}catch(error){}}}>
                                {row._original[dato]}
                            </div>
                        ),
                    })    
                }
                catch(error){

                }
            } else if (props.datosDeTabla[0][dato].condicion === 'graficaRadar'){
                try{
                    datosTablaInternos.push({
                        accesor: `${dato}`,
                        Header: () => (<p style={estiloParaHeader}>{props.datosDeTabla[0][dato].label}</p>),
                        minWidth: props.datosDeTabla[0][dato].minWidth || 100,
                        resizable: props.datosDeTabla[0][dato].resizable || false,
                        Cell: ({ row }) => (
                            <div style={{ ...estiloCeldas, cursor: 'pointer' }} onMouseEnter={() =>{try{ return props.hover('', row._original[dato])}catch(error){}}}>
                                {row._original[dato]}
                            </div>
                        ),
                    })    
                }
                catch(error){

                }
            }
           
            
        })   
        return datosTablaInternos;
    } else {
        datosTablaInternos.push({
            accesor: 'error',
            Header: () => (<p style={estiloParaHeader}>Error</p>),
            minWidth: 100,
            resizable: false,
            Cell: ({ row }) => (
                <div style={{ ...estiloCeldas }}>
                    No hay datos para mostrar
                </div>
            ),
        })
    }
    }
    

    /**
     * función para crear los encabezados de la tabla, sólo necesita la lista de los datos 
     */

    const crearListaDatosParaTabla = () =>{
        const listaDeDatosParaTabla = [];
        if(props.datosDeTabla !== undefined){
            props.datosDeTabla.forEach(datos => {
                listaDeDatosParaTabla.push(armarObjeto(datos))
            });
        } else {
            listaDeDatosParaTabla.push({
                error: 'error',
            },)
        }
        return listaDeDatosParaTabla;
    }
    
    const armarObjeto = (datos) => {
        let objeto = {};
        Object.keys(datos).forEach(dato => {
            if(typeof(datos[dato].valor) === 'object'){
                objeto[dato] = new Date(datos[dato].valor.seconds * 1000).toLocaleString()
            }else{
                objeto[dato] = datos[dato].valor
            }
        });
        return objeto;
    }
    //new Date(row._original[dato].seconds * 1000).toLocaleString()
    let guardarEnElLocalStorage = ( path) => {
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
    }  

    const columnasDePrueba = [
        {
            accesor: 'error',
            Header: () => (<p style={estiloParaHeader}>Error</p>),
            minWidth: 100,
            resizable: false,
            Cell: ({ row }) => (
                <div style={{ ...estiloCeldas }}>
                   No hay datos para mostrar
                </div>
            ),
        },
    ]

    const renderizarTabla = () => {
        try{
            if(props.paginator){
                return(
                    <TablaConServerPaginator
                        keyDeTabla={props.key}
                        norma={props.norma}
                        style={props.style}
                        listaDeDatos={crearListaDatosParaTabla()}
                        criterioDeOrdenamiento={{ id: "numero", desc: false }}
                        columnas={crearColumnasParaTabla()}
                        getDataPaginada={props.getDataPaginada}
                        cargando={props.cargando}
                    />
                )
            } else {
                return(
                    <CustomTableDinamica
                        key={props.keyDeTabla}
                        keyDeTabla={props.keyDeTabla}
                        style={props.style}
                        listaDeDatos={crearListaDatosParaTabla()}
                        criterioDeOrdenamiento={{ id: "numero", desc: false }}
                        columnas={crearColumnasParaTabla()}
                    />
                )
            }
        }
        catch(error){
            console.log(error)
            return (
                <CustomTableDinamica
                    // keyTabla={(new Date()).getTime()}
                    listaDeDatos={datosDePrueba}
                    criterioDeOrdenamiento={{ id: "numero", desc: false }}
                    columnas={columnasDePrueba}
                />
            )
        }
    }

       
    return(
        <React.Fragment>
            {renderizarTabla()}
        </React.Fragment>
    )

}

export default CrearTablaDinamicamente;

