import React from 'react'
import { firestoreDB } from "../../../../../../firebase";
import { firebaseApp } from "../../../../../../firebase";
import { connect } from 'react-redux'
import { Label, Input, Col, Row, Breadcrumb, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup } from 'reactstrap';

//Scripts
import { parametrosDeEstados } from '../../../scripts/parametrosDeEstados';

//Iconos
import { MdClose } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { MdSave } from "react-icons/md";

//Componentes Genéricos
import BotonCallToAction from "../../../../../../utilidades/componentes/plantillaBase/BotonCallToAction";

//Images
let defaultAvatar = "/assets/img/iconos/avatar.webp";

class EstadoDeLaInspeccion extends React.Component {
    constructor(props) {
        super(props);
        this.isMonted = false;
        this.state = {
            dropdownEstadosOpen: false,
            dropdownTecnicosOpen: false,
            dropdownActividadOpen: false,
            listaDeTecnicos: [],
            fechaRealDeProgramacion: undefined,
            idDeLaOrdenDeInspeccion: undefined,
            estadoDelRegistro: {},

            //Edicion
            edicionDeshabilitada: true,
            estadoDelRegistroEnEdicion: {},
            estadoModificado: false,

            //Render
            contadorKeyRender: 0
        }
    }

    componentDidMount() {
        this.setState({
            idDeLaOrdenDeInspeccion: this.props.id,
            estadoDelRegistro: this.props.estadoDelRegistro,
            estadoDelRegistroEnEdicion: { ...this.props.estadoDelRegistro }
        })

        this.isMonted = true;
        let getTecnicos = this.traerTecnicos();

        getTecnicos.then(() => {
            if (this.props.estadoDelRegistro.fechaRealDeProgramacion !== undefined) {
                this.setState({ fechaRealDeProgramacion: new Date(this.props.estadoDelRegistro.fechaRealDeProgramacion.seconds * 1000) })
            }
        })
    }

    componentWillUnmount() {
        this.isMonted = false;
    }

    render() {
        if (this.props.estadoDelRegistro !== undefined) {
            return (
                <React.Fragment>
                    <FormGroup>
                        <Row>
                            <Col className='ml-xs-0 ' xs='12' md='12' lg='4'>
                                <Row>
                                    <Col xs='12' md='6' lg='6'>
                                        <Label for="supervisor" className='mb-0 mt-2'>Supervisor:</Label>
                                        <Input type="text" name="responsable" id="responsable" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.responsable.nombre} disabled />
                                    </Col>
                                    <Col xs='12' md='6' lg='6'>
                                        {this.obtenerRenderDeDropdownEstados()}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='12' md='6' lg='12'>
                                        {this.obtenerRenderDeDropdownTecnicos()}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    {this.obtenerRenderFechaEstimadaDeProgramacion()}
                                    {this.obtenerRenderFechaRealDeInicio()}
                                    {this.obtenerRenderFechaRealDeFinalizacion()}
                                </Row>
                                <Row>
                                    {this.obtenerRenderFechaDeActualizacion()}
                                    {this.obtenerRenderFechaRealDeProgramacion()}
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                    {/* <Col className="d-flex flex-row px-0" style={{backgroundColor:'green'}}> */}
                    <FormGroup>
                        <Row>
                            <Col xs='12' md='12' lg='12'>
                                <Row>
                                    <Col xs='12' md='6' lg='3'>
                                        <Label for="descripcionLinea" className='mb-0 mt-2'>Línea de ducto:</Label>
                                        <Input type="text" className='text-center' name="descripcionLinea" id="descripcionLinea" placeholder="Sin asignar" defaultValue={this.props.estadoDelRegistro.lineaDeDucto ? this.props.estadoDelRegistro.lineaDeDucto : '-'} disabled />
                                    </Col>
                                    <Col xs='12' md='12' lg='3'>
                                        <Label for="distanciaDeInicio" className='mb-0 mt-2'>Distancia de inicio de la anomalía:</Label>
                                        <Input type="text" name="distanciaDeInicio" id="distanciaDeInicio" placeholder="Distancia de inicio de la anomalía" defaultValue={this.props.estadoDelRegistro.distanciaDeInicio} disabled />
                                    </Col>

                                    <Col xs='12' md='12' lg='3' className=''>
                                        <Label for="distanciaDeFin" className='mb-0 mt-2 '>Distancia de fin de la anomalía:</Label>
                                        <Input type="text" name="distanciaDeFin" id="distanciaDeFin" placeholder="Distancia de fin de la anomalía" defaultValue={this.props.estadoDelRegistro.distanciaDeFin} disabled />
                                    </Col>
                                    <Col xs='12' md='12' lg='3' className=''>
                                        <Label for="longitudAnomalia" className='mb-0 mt-2 '>Longitud de la anomalía:</Label>
                                        <Input type="text" name="longitudAnomalia" id="longitudAnomaliaGeneral" placeholder="Longitud de la anomalía" defaultValue={(this.props.estadoDelRegistro.distanciaDeFin && this.props.estadoDelRegistro.distanciaDeInicio)?this.props.estadoDelRegistro.distanciaDeFin - this.props.estadoDelRegistro.distanciaDeInicio:''} disabled />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs='12' md='12' lg='12'>
                                <Label for="descripcionODT" className='mb-0 mt-2'>Descripción de la orden de trabajo:</Label>
                                <Input type="textarea" name="descripcionODT" id="descripcionODT" placeholder="Descripción de la orden de trabajo" defaultValue={this.props.estadoDelRegistro.descripcionODT} disabled />
                            </Col>
                            <Col xs='12' md='12' lg='12'>
                                <Label for="infoControlInforme" className='mb-0 mt-2'>Información Control de Informes:</Label>
                            </Col>
                            <Col xs='12' md='12' lg='3'>
                                {this.obtenerRenderDeActividad()}
                            </Col>
                            <Col xs='12' md='12' lg='3'>
                                {this.obtenerRenderFechaCargueHorus()}
                            </Col>
                            <Col xs='12' md='12' lg='3'>
                                {this.obtenerRenderTipoActividad()}
                            </Col>
                            <Col xs='12' md='12' lg='12' className="d-flex flex-row justify-content-end mt-3">
                                {this.obtenerRenderDeBottonesDeAccion()}
                            </Col>
                        </Row>
                    </FormGroup>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Breadcrumb>
                        <BreadcrumbItem className='BreadcrumbItem-color'>Este documento ya no existe</BreadcrumbItem>
                    </Breadcrumb>
                </React.Fragment>
            )
        }
    }

    //Funciones Render
    generarListaDeTecnicosParaDropdown = () => {
        if (this.state.listaDeTecnicos !== undefined) {
            return this.state.listaDeTecnicos.map(tecnico => {
                return (
                    <DropdownItem
                        key={`tecnico ${tecnico.nombre}`}
                        onClick={() => this.setDatosModificados('tecnico', { nombre: tecnico.nombre, correo: tecnico.correo })}>
                        <div className="widget-content-left mr-4">
                            <img width={30} className="rounded" style={{ borderRadius: "50%" }} src={tecnico.fotoDePerfil ? tecnico.fotoDePerfil.url : defaultAvatar} alt="" />
                        </div>
                        {tecnico.nombre}
                    </DropdownItem>
                );
            });
        }
    }

    obtenerRenderDeBottonesDeAccion = () => {
        const rolesPermitidos = ["admin", "supervisor", "control_calidad", "programador_contrato"]
        if (rolesPermitidos.includes(this.props.usuarioActivo.rol)) {
            return (
                <>
                    <BotonCallToAction
                        className="mx-2"
                        style={{ width: 150 }}
                        key={'btn_estado_edicion'}
                        usuarioActivo={this.props.usuarioActivo}
                        color={this.state.edicionDeshabilitada ? 'primary' : 'success'}
                        icono={this.state.edicionDeshabilitada ? MdEdit : MdSave}
                        titulo={this.state.edicionDeshabilitada ? 'Editar' : 'Guardar'}
                        onClick={() => this.accionEditar()}
                        disabled={!this.state.edicionDeshabilitada && !this.state.estadoModificado}
                    />

                    <BotonCallToAction
                        className={this.state.edicionDeshabilitada ? "d-none" : ""}
                        style={{ width: 150 }}
                        key={'btn_estado_cancelar'}
                        usuarioActivo={this.props.usuarioActivo}
                        color={'light'}
                        icono={MdClose}
                        colorDeIcono={'dark'}
                        titulo={'Cancelar'}
                        onClick={() => this.accionCancelar()}
                    />
                </>
            );
        }
    }

    obtenerRenderDeDropdownEstados = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.estado;
        return (
            <>
                <Label for="estado" className='mb-0 mt-2'>Estado:</Label>
                <Dropdown key={'dropdown_estado_' + this.state.contadorKeyRender} isOpen={this.state.dropdownEstadosOpen} toggle={this.toggleEstados} disabled={this.state.edicionDeshabilitada} >
                    <DropdownToggle className='w-100' style={{ backgroundColor: this.state.edicionDeshabilitada ? '#e9ecef' : 'white', color: 'black', fontSize: 16, fontWeight: 'normal', borderColor: '#ced4da' }}>
                        {tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("estado") || "Sin asignar" : "Sin asignar"}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Estado</DropdownItem>
                        {this.obtenerRenderListaDeEstadosSegunRol()}
                    </DropdownMenu>
                </Dropdown>
            </>
        );
    }

    obtenerRenderDeActividad = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.actividad;
        return (
            <>
                <Label for="estado" className='mb-0 mt-2'>Actividad:</Label>
                <Dropdown key={'dropdown_actividad_' + this.state.contadorKeyRender} isOpen={this.state.dropdownActividadOpen} toggle={this.toggleActividad} disabled={this.state.edicionDeshabilitada} >
                    <DropdownToggle className='w-100' style={{ backgroundColor: this.state.edicionDeshabilitada ? '#e9ecef' : 'white', color: 'black', fontSize: 16, fontWeight: 'normal', borderColor: '#ced4da' }}>
                        {tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("actividad") || "Sin asignar" : "Sin asignar"}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Actividad</DropdownItem>
                        <DropdownItem key={'key_ANM'} onClick={() => this.setDatosModificados("actividad", 'ANM')}>{'ANM - Atención Anomalías ILI Programadas por pérdida de metal'}</DropdownItem>
                        <DropdownItem key={'key_AND'} onClick={() => this.setDatosModificados("actividad", 'AND')}>{'AND - Atención Anomalías ILI Programadas por deformación de diámetro'}</DropdownItem>
                        <DropdownItem key={'key_ANC'} onClick={() => this.setDatosModificados("actividad", 'ANC')}>{'ANC - Atención Anomalías ILI Programadas por deformación de curvatura'}</DropdownItem>
                        <DropdownItem key={'key_ARP'} onClick={() => this.setDatosModificados("actividad", 'ARP')}>{'ARP - Aseguramiento a reparaciones mecánicas'}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </>
        );
    }

    obtenerRenderDeDropdownTecnicos = () => {
        const valorEnEdicion = this.state.edicionDeshabilitada ? this.state.estadoDelRegistroEnEdicion : this.state.estadoDelRegistro;
        const tieneValorAsignado = !!(valorEnEdicion.tecnico && valorEnEdicion.tecnico.nombre !== "" && valorEnEdicion.tecnico.correo !== "");
        return (
            <React.Fragment>
                <Label for="tecnico" className='mb-0 mt-2'>Inspector :</Label>
                <Dropdown key={'dropdown_tecnico_' + this.state.contadorKeyRender} isOpen={this.state.dropdownTecnicosOpen} toggle={this.toggleTecnicos} disabled={this.state.edicionDeshabilitada}>
                    <DropdownToggle caret={false} className='w-100' style={{ backgroundColor: this.state.edicionDeshabilitada ? '#e9ecef' : 'white', color: 'black', fontSize: 16, fontWeight: 'normal', borderColor: '#ced4da' }}>
                        {tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("tecnico") || "Sin asignar" : "Sin asignar"}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Técnico</DropdownItem>
                        {this.generarListaDeTecnicosParaDropdown()}
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )
        //}
    }

    obtenerRenderListaDeEstadosSegunRol = () => {
        // const estadosPorRol = estadosSegunRolyEstadoActual[this.props.usuarioActivo.rol];
        // if(estadosPorRol){
        //     if(estadosPorRol[this.props.estadoDelRegistro.estado]){
        //         return estadosPorRol[this.props.estadoDelRegistro.estado].map(estado =>{
        //             return (
        //                 <DropdownItem key={'key_'  + estado} onClick={() => this.setDatosModificados("estado", estado)}>{parametrosDeEstados[estado]['tituloDelModal']}</DropdownItem>
        //             );
        //         })
        //     }
        // }
        // return (<></>);

        return Object.keys(parametrosDeEstados).map(key => {
            return (
                <DropdownItem key={'key_' + parametrosDeEstados[key].estado} onClick={() => this.setDatosModificados("estado", parametrosDeEstados[key].estado)}>{parametrosDeEstados[key]['tituloDelModal']}</DropdownItem>
            );
        });
    }

    obtenerRenderFechaDeActualizacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaDeActualizacion;
        return (
            <Col key={'fecha_actualizacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                <Label for="fechaDeActualizacion" className='mb-0 mt-2'>Fecha de Actualización:</Label>
                <Input
                    type={tieneValorAsignado ? "date" : "text"}
                    name="fechaDeActualizacion"
                    id="fechaDeActualizacion"
                    placeholder="Sin asignar"
                    value={tieneValorAsignado ? new Date(this.state.estadoDelRegistro.fechaDeActualizacion.toDate()).toISOString().split('T')[0] : "Fecha aún no definida"}
                    disabled
                />
            </Col>
        )
    }

    obtenerRenderFechaCargueHorus = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaDeCargueHorus;
        return (
            <Col key={'fecha_cargue_' + this.state.contadorKeyRender} xs='12' md='12' lg='12'>
                <Label for="fechaDeCargueHorus" className='mb-0 mt-2'>Fecha de Cargue en Horus:</Label>
                <Input
                    type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                    name="fechaDeCargueHorus"
                    id="fechaDeCargueHorus"
                    placeholder="Sin asignar"
                    value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaDeCargueHorus") : "Fecha aún no definida"}
                    onChange={(e) => this.setDatosModificados("fechaDeCargueHorus", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                    disabled={this.state.edicionDeshabilitada}
                />
            </Col>
        )
    }

    obtenerRenderTipoActividad = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.tipoActividad;
        return (
            <Col key={'tipo_actividad_' + this.state.contadorKeyRender} xs='12' md='12' lg='12'>
                <Label for="tipoActividad" className='mb-0 mt-2'>Tipo de Actividad:</Label>
                <Input
                    type='text'
                    name='tipoActividad'
                    id='tipoActividad'
                    placeholder='Sin asignar'
                    value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("tipoActividad") : "Actividad no definida"}
                    onChange={(e) => this.setDatosModificados("tipoActividad", e.target.value)}
                    disabled={this.state.edicionDeshabilitada}
                />
            </Col>
        )
    }

    obtenerRenderFechaEstimadaDeProgramacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaEstimadaDeProgramacion;
        return (
            <Col key={'fecha_est_programacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                <Label for="fechaEstimadaDeProgramacion" className='mb-0 mt-2'>Fecha Estimada de Programación:</Label>
                <Input
                    type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                    name="fechaEstimadaDeProgramacion"
                    id="fechaEstimadaDeProgramacion"
                    placeholder="Sin asignar"
                    value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaEstimadaDeProgramacion") : "Fecha aún no definida"}
                    onChange={(e) => this.setDatosModificados("fechaEstimadaDeProgramacion", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                    disabled={this.state.edicionDeshabilitada}
                />
            </Col>
        )
    }

    obtenerRenderFechaRealDeFinalizacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaRealDeFinalizacion;
        const estadosPermitidos = ["EJECUTADA", "REVISADA", "CONTROL_CALIDAD", "FACTURADA", "CANCELADA"];
        const poseeEstadoPermitido = estadosPermitidos.includes(this.state.estadoDelRegistro.estado);

        const poseeEstadoPermitidoEnEdicion = estadosPermitidos.includes(this.state.estadoDelRegistroEnEdicion.estado);
        const mostrarEnEdicion = (poseeEstadoPermitidoEnEdicion && !this.state.edicionDeshabilitada);

        if (poseeEstadoPermitido || mostrarEnEdicion) {
            return (
                <Col key={'fecha_real_finalizacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                    <Label for="fechaRealDeFinalizacion" className='mb-0 mt-2'>Fecha Real de Finalización:</Label>
                    <Input type='text' placeholder="Sin asignar"
                        defaultValue={tieneValorAsignado ? this.obtenerValorActualDeInput("fechaRealDeInicio") : "Fecha aún no definida"}
                        disabled />
                </Col>
            )
        } else {
            return (<React.Fragment></React.Fragment>)
        }
    }

    obtenerRenderFechaRealDeInicio = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaRealDeInicio;
        const mostrarEnEdicion = (this.state.estadoDelRegistroEnEdicion.estado !== 'PLANEADA' && this.state.estadoDelRegistroEnEdicion.estado !== 'PROGRAMADA' && !this.state.edicionDeshabilitada);

        if ((this.state.estadoDelRegistro.estado !== 'PLANEADA' && this.state.estadoDelRegistro.estado !== 'PROGRAMADA') || mostrarEnEdicion) {
            return (
                <Col key={'fecha_real_inicio_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                    <Label for="fechaRealDeInicio" className='mb-0 mt-2'>Fecha Real de Inicio:</Label>
                    <Input type='text' placeholder="Sin asignar"
                        defaultValue={tieneValorAsignado ? this.obtenerValorActualDeInput("fechaRealDeInicio") : "Fecha aún no definida"}
                        disabled />
                </Col>
            )
        } else {
            return (<React.Fragment></React.Fragment>)
        }
    }

    obtenerRenderFechaRealDeProgramacion = () => {
        const tieneValorAsignado = !!this.state.estadoDelRegistro.fechaRealDeProgramacion;
        const mostrarEnEdicion = (this.state.estadoDelRegistroEnEdicion.estado !== 'PLANEADA' && !this.state.edicionDeshabilitada);

        if (this.state.estadoDelRegistro.estado !== 'PLANEADA' || mostrarEnEdicion) {
            return (
                <Col key={'fecha_real_programacion_' + this.state.contadorKeyRender} xs='12' md='12' lg='4'>
                    <Label for="fechaRealDeProgramacion" className='mb-0 mt-2'>Fecha Real de Programación:</Label>
                    <Input
                        type={tieneValorAsignado || !this.state.edicionDeshabilitada ? "date" : "text"}
                        name="fechaRealDeProgramacion"
                        id="fechaRealDeProgramacion"
                        placeholder="Sin asignar"
                        value={tieneValorAsignado || !this.state.edicionDeshabilitada ? this.obtenerValorActualDeInput("fechaRealDeProgramacion") : "Fecha aún no definida"}
                        onChange={(e) => this.setDatosModificados("fechaRealDeProgramacion", firebaseApp.firestore.Timestamp.fromDate(new Date(e.target.value)))}
                        disabled={this.state.edicionDeshabilitada}
                    />
                </Col>
            )
        } else {
            return (<></>)
        }
    }

    //Funciones Lógicas
    accionCancelar = () => {
        this.setState({
            estadoModificado: false,
            edicionDeshabilitada: true,
            estadoDelRegistroEnEdicion: { ...this.state.estadoDelRegistro },
            contadorKeyRender: this.state.contadorKeyRender + 1
        });
    }

    accionEditar = () => {
        if (this.state.edicionDeshabilitada) {
            this.setState({
                edicionDeshabilitada: !this.state.edicionDeshabilitada
            });
        } else {
            this.actualizarDocumentoEnBd();
        }
    }

    actualizarDocumentoEnBd = () => {
        const datosActualizados = this.state.estadoDelRegistroEnEdicion;
        firestoreDB.doc(`ordenesDeTrabajo/${this.state.idDeLaOrdenDeInspeccion}`).update(datosActualizados)
            .then(() => {
                this.setState({
                    edicionDeshabilitada: !this.state.edicionDeshabilitada,
                    estadoDelRegistro: datosActualizados,
                    estadoModificado: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    obtenerValorActualDeInput = (parametro) => {
        let valorParametro = this.state.edicionDeshabilitada
            ? this.state.estadoDelRegistro[parametro]
            : this.state.estadoDelRegistroEnEdicion[parametro] || this.state.estadoDelRegistro[parametro];

        switch (parametro) {
            case "tecnico":
                const tecnico = this.state.listaDeTecnicos.find(tecnico => valorParametro.correo === tecnico.correo);
                if (valorParametro.correo) {
                    return (
                        <Row className={"mx-2 my-0 justify-content-left"}>
                            {tecnico ? <img width={25} className="rounded mr-4" style={{ borderRadius: "50%" }} src={tecnico ? tecnico.fotoDePerfil ? tecnico.fotoDePerfil.url || defaultAvatar : defaultAvatar : defaultAvatar} alt="" /> : null}
                            {valorParametro.nombre || 'Sin asignar'}
                        </Row>
                    );
                } else {
                    return null
                }
            case "estado":
                return parametrosDeEstados[valorParametro].estado;
            case "fechaDeActualizacion":
            case "fechaRealDeInicio":
            case "fechaEstimadaDeProgramacion":
            case "fechaDeCargueHorus":
            case "fechaRealDeProgramacion":
                valorParametro = valorParametro ? valorParametro.toDate() : new Date();
                return new Date(valorParametro).toISOString().split('T')[0]
            default:
                return valorParametro;
        }
    }

    setDatosModificados = (parametro, valor) => {
        let estadoDelRegistroEnEdicion = this.state.estadoDelRegistroEnEdicion;

        estadoDelRegistroEnEdicion[parametro] = valor;

        if (parametro) {
            estadoDelRegistroEnEdicion["fechaDeActualizacion"] = firebaseApp.firestore.Timestamp.fromDate(new Date());
        }

        this.setState({
            estadoDelRegistroEnEdicion: estadoDelRegistroEnEdicion,
            estadoModificado: true
        })
    }
    
    toggleActividad = () => this.setState({ dropdownActividadOpen: !this.state.dropdownActividadOpen });

    toggleEstados = () => this.setState({ dropdownEstadosOpen: !this.state.dropdownEstadosOpen });

    toggleTecnicos = () => this.setState({ dropdownTecnicosOpen: !this.state.dropdownTecnicosOpen });

    traerTecnicos = () => {
        return new Promise((resolve) => {
            let tecnicos = []
            firestoreDB.collection('usuarios')
                .where('dominioAlQuePertenece', '==', this.props.usuarioActivo.dominioCorporativo)
                .where('rol', '==', 'tecnico')
                .get()
                .then(snapShots => {
                    snapShots.forEach(snapShot => {
                        tecnicos.push({
                            'nombre': snapShot.data().nombre,
                            'correo': snapShot.data().correo,
                            'fotoDePerfil': snapShot.data().fotoDePerfil
                        });
                        if (this.isMonted) this.setState({ listaDeTecnicos: tecnicos });
                    })
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    resolve()
                })
        })
    }
}


const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

export default connect(mapStateToProps)(EstadoDeLaInspeccion);

