import { IconButton } from '@material-ui/core';
import { FiArrowRight } from 'react-icons/fi'

import { MdEdit } from "react-icons/md";
import { FiArrowLeft } from 'react-icons/fi'
import React, { useState } from 'react'
import {
    Breadcrumb, BreadcrumbItem, CardBody, Card, CardHeader, Carousel, CarouselItem, CardFooter, Row, Col, Button
} from 'reactstrap';

import DetalleDeCamisas from './DetalleDeCamisas';

//Scripts Generales
import detallesDeAnomalias from '../../../scripts/detallesDeAnomalias'

const CaracterizacionDeCamisas = (props) => {
        return (
            <React.Fragment >
                <br />
                <br />
                <Breadcrumb
                    style={{ marginBottom: 1 }}
                >
                    <BreadcrumbItem className='BreadcrumbItem-color'>CARACTERIZACIÓN, VALORACIÓN Y EVALUACIÓN DE CAMISAS</BreadcrumbItem>
                </Breadcrumb>
                <RenderInformacionCamisas
                    idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                    {...props} />
            </React.Fragment>
        )
    // }else{
    //     return <React.Fragment></React.Fragment>
    // }
}



const RenderInformacionCamisas = (props) => {
    let listaDeCamisas = props.datosDeCamisas;
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === listaDeCamisas.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? listaDeCamisas.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
    if (listaDeCamisas) {
        const slides = listaDeCamisas.map((item, index) => {
                return (
                    <CarouselItem
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                        key={`${item.idDeAnomalia}_${item.tipoDeAnomalia}`}
                    >

                        <CardHeader style={{ flexDirection:'row', alignContent: "center", justifyContent: "center", paddingTop: 0, paddingBottom: 0 }}>
                            <div >
                                {detallesDeAnomalias[item.tipoDeAnomalia] ? detallesDeAnomalias[item.tipoDeAnomalia].titulo : 'Título no encontrado'}
                            </div>
                            <div className="btn-actions-pane-right actions-icon-btn" style={{ display: "flex", alignItems: "bottom" }}>
                                {props.authRol ? <Button
                                    color="primary"
                                    onClick={() => {
                                        props.toggleModalEdicionAnomalias()
                                        props.setTipoAnomaliaEdicion(item.tipoDeAnomalia)
                                        props.setAnomaliaEdicion(item.idDeAnomalia)
                                    }}
                                >
                                    <MdEdit
                                        size={15} style={{ strokeWidth: 0.75, marginRight: 12, color: 'white' }}
                                    />
                                    Editar Datos
                                </Button> : null}
                            </div>
                        </CardHeader>
                        <CardBody style={{ paddingTop: 1, paddingBottom: 1 }}>
                            <DetalleDeCamisas
                                authRol={props.authRol}
                                tipoDeAnomalia={item.tipoDeAnomalia}
                                idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                                toggleModalEdicionAnomalias={() => props.toggleModalEdicionAnomalias()}
                                setTipoAnomaliaEdicion={(e) => props.setTipoAnomaliaEdicion(e)}
                                setAnomaliaEdicion={(e) => props.setAnomaliaEdicion(e)}
                                setSubAnomaliaEdicion={(e) => props.setSubAnomaliaEdicion(e)}
                                anomaliaCamisa={item}
                                existeSubanomalia={verificarSubAnomalias(props)}
                            />
                        </CardBody>
                    </CarouselItem>
                );
        });
        
        return (
            <Card>
                <CardBody style={{ padding: 1 }}>
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        enableTouch={false}
                        interval={false}
                    >
                        {slides}
                    </Carousel>
                </CardBody>
                <CardFooter style={{ paddingBottom: 1, paddingTop: 1 }}>
                    <Row style={{ justifyItems: "center", alignItems: "center" }}>
                        {activeIndex > 0 && <IconButton onClick={previous}><FiArrowLeft /></IconButton>}
                        <Col>{`Página ${activeIndex + 1} de ${listaDeCamisas.length}`}</Col>
                        {activeIndex < listaDeCamisas.length - 1 && <IconButton onClick={next} ><FiArrowRight /></IconButton>}
                    </Row>
                </CardFooter>
            </Card>
        )
    } else {
        return (
            <React.Fragment></React.Fragment>
        )
    }

}

const verificarSubAnomalias = (props) => {
    let existen = false;
    props.datosDeCamisas.forEach(item=>{
        if(item.listadoDeSubAnomalias.length > 0) existen = true;
    })
    return existen;
}

export default CaracterizacionDeCamisas;