/**
 * COPIA DE CONSTANTES DE BACK-END EL 23/02/2021
 */

// PROBABILIDAD DE FALLA
const POF = {
  APERTURA: 'apertura',
  FUGA: 'fuga',
};
// SOBREPRESION
const SP = {
  ESCENARIO: 'escenario',
  VALOR: 'valor',
  NOMBRE: 'nombre',
  LABEL: {
    FIRE: 'Fire',
    LOSS_OF_COOL_WAT: 'LossOf cooling water utility',
    ELECTRICAL_POWER_SUPPLY_FAILURE: 'Electrical power supply failure',
    BLCK_DSCHG_WITH_ADMN_CTRLS: 'Blocked discharge with administrative controls in place',
    BLCK_DSCHG_WITHOUT_ADMN_CTRLS: 'Blocked discharge without administrative controls',
    CTRL_VALVE_FAIL_INIT_EVNT_IS_OP_DIR_AS__CV_NORM_FAIL_POS: 'Control valve failure, initiating event is opposite direction as CV normal fail position',
    CTRL_VALVE_FAIL_INIT_EVNT_IS_SAME_DIR_AS_CV_NORM_FAIL_POS: 'Control valve failure, initiating event is same direction as CV normal fail position',
    RUNAWAY_CHEMICAL_REACT: 'Runaway chemical reaction',
    HEAT_EXCHNGR_TUBE_RPTR: 'Heat exchanger tube rupture',
    TWR_P_A_OR_RFLX_PMP_FAIL: 'Tower P/A or reflux pump failures',
    THRML_RLF_WITH_ADMN_CTRLS: 'Thermal relief with administrative controls in place',
    THRML_RLF_WITHOUT_ADMN_CTRLS: 'Thermal relief without administrative controls',
    LQD_OVRFILL_WITH_ADM_CTRLS: 'Liquid overfilling with administrative controls in place',
    LQD_OVRFILL_WITHOUT_ADMN_CTRLS: 'Liquidoverfilling without administrative controls',
  },
};

// RBI VALORES
const RBI = {
  CON_RBI: 'SI EL EQUIPO TIENE RBI',
  SIN_RBI: 'SI EL EQUIPO NO TIENE RBI',
  DF: 'DF',
  DESCRIPCION: 'Descripcion',
  CLASEDF: 'DFClass',
};

const TIPO_COMPONENTE = 'Tipo de componente';

// SEVERIDAD DE FLUIDO
const SEVFLU = {
  LEVE: 'Leve',
  MODERARA: 'Moderada',
  SEVERA: 'Severa',
};

// GLOBALES

// FALLA O APRUEBA
const FOA = {
  FALLO: 'Falló',
  APROBO: 'Aprobó',
};
// TIPOS DE VALVULA
const TV = {
  CONVEN_RES: 'Convencional resortada',
  CONVEN_BAL: 'Convencional balanceada',
  PILOTADA: 'Pilotada',
  DISC_ROT: 'Disco de rotura',
  PRES_VAC: 'Presión y vacío',
};

// TIPOS DE DESCARGA
const TD = {
  TEA: 'Tea',
  ATMOSFERICA: 'Atmosférica',
  PROC_CERRADO: 'Proceso cerrado(Drenaje,recirculación)',
  TEA_SISTEMA_REC: 'Tea con sistema de Recuperación',
};
// EFECTIVIDAD INSPECCION
const EFI = {
  INEFECTIVA_SIN_INSP: 'Inefectiva o sin inspección',
  POBRE: 'Pobremente efectiva',
  USUAL: 'Usualmente efectiva',
  ALTA: 'Altamente efectiva',
};

// TIPO DE OPERACION
const TO = {
  INTERMITENTE: 'Intermitente',
  CONTINUA: 'Contínua',
};

// SI O NO
const SN = {
  SI: 'SI',
  No: 'NO',
};

// SI O NO SIN INFORMACION HISTORICOS DE ACTUACION
const HIST = {
  SI: 'SI',
  NO: 'NO',
  SIN_INFO: 'Sin información',
};

// FASE DEL FLUIDO ALMACENADO
const FFA = {
  LIQUIDO: 'Líquido',
  GAS: 'Gas',
};

// EVALUAR COMO
const EVC = {
  LIQUIDO: 'Evaluar como Liquido',
  GAS: 'Evaluar como Gas',
};
// SITEMA DE MITIGACION
const SISTEMA_MITIGACION = {
  INV_BLOW: 'Inventory blowdown, coupled with isolation system classification B or higher',
  FIRE_WATER_DELUGE_AND_MONITORS: 'Fire water deluge system and monitors',
  FIRE_WATER_MONITORS_ONLY: 'Fire water monitors only',
  FOAM_SPRAY_SYSTEM: 'Foam spray system',
};
/**
 * TIPO DE FUGA
 */
const RELEASE_TYPE = {
  CONTINUA: 'Continua',
  INSTANTANEA: 'Instantanea',
};

const SENS_AMBIENTAL = {
  ALTA: 'Alta',
  MEDIA: 'Media',
  BAJA: 'Baja',
};

const MODELO_CONSECUENCIA = {
  SEMICUANT: 'Semicuantitativo',
  CUALIT: 'Cualitativo',
  SyC: 'S&C'
};

const SIN_VALORAR = "Sin Valorar";

//Variables para eventos de sobrepresión.
const VSP = 'Eventos de sobrepresión';

// KEYS PARA PARÁMETROS DE DOMINIO CORPORATIVO
const PDC = {
  NIVEL_CONSECUENCIA: 'rangosDeConsecuencia',
  NIVEL_PROBABILIDAD: 'rangosDeProbabilidad',
  MATRIZ_RIESGO: 'rangosMatriz',
  NIVEL_RIESGO: 'rangosDeRiesgoFinanciero',
  NIVEL_CONSECUENCIA_AREA: 'rangosDeConsecuenciaArea',
};

// SERVICIO
const SRV = {
  TIPICOS: 'Servicios típicos, fluidos corrosivos',
  LIMPIOS: 'Servicios limpios (no presentan fouling), fluidos no corrosivos',
};

const TDP = {
  BANCO: 'En Banco',
  SITU: 'In situ',
  SIN_PRUEBA: 'Sin inspeccion / Sin prueba desde su instalación',
};

const TDM = {
/*   BANCO: 'En Banco',
  SITU: 'In situ', */
  SIN_MANT: 'Sin mantenimiento',
};

const RSG = { // WARNING EN PLANTILLA DE CARGUE TAMBIÉN ESTAN ESTATICAS
  L: 'L',
  M: 'M',
  H: 'H',
  E: 'E',
};

const ESTADO_PRD = {
  DISPONIBLE: 'Disponible',
  OPERATIVA: 'Operativa',
  FUERA_DE_SERVICIO: 'Fuera de servicio',
};
// fecha proxima inspeccion
const INMEDIATA = 'Inmediata';
const MAX_ITERAC_PLANES = 1000;


const TE = {
  COMPRESSOR: 'Compressor',
  HEAT_EXCHNGR: 'Heat exchanger',
  PIPE: 'Pipe',
  PUMP: 'Pump',
  TANK: 'Tank',
  VESSEL_FINFAN: 'Vessel/FinFan'
};

const MODO_DES = {
  DETALLE: "detalle",
  INVENT: "inventario",
  REPORTES: "reportes",
  RESULT: "resultados"
};

module.exports = {
  POF,
  SP,
  TIPO_COMPONENTE,
  RBI,
  TV,
  SEVFLU,
  FOA,
  EFI,
  SN,
  TO,
  FFA,
  EVC,
  TD,
  SISTEMA_MITIGACION,
  RELEASE_TYPE,
  SENS_AMBIENTAL,
  MODELO_CONSECUENCIA,
  PDC,
  SRV,
  TDP,
  RSG,
  INMEDIATA,
  HIST,
  MAX_ITERAC_PLANES,
  ESTADO_PRD,
  TE,
  TDM,
  SIN_VALORAR,
  MODO_DES,
  VSP,
};
