import { useState } from "react";

/** Datos en grueso */
import { crearListaTablaResumenActivo } from '../scripts/crearListaTablaResumenActivo';

/**
 * @Return dataTablaInfo, cargando
 */
export const useFetchTablaResumen = () => {

    const [dataTablaResumen, setDataTablaResumen] = useState( [] );
    const [cargandoResumen, setCargandoResumen] = useState( false );

    const armarDatosTablaResumen = ( variablesDeLaInspeccion ) => {
        setCargandoResumen( true )
        setDataTablaResumen( crearListaTablaResumenActivo( variablesDeLaInspeccion ) )
        setCargandoResumen( false )
    };

    return {dataTablaResumen, cargandoResumen, armarDatosTablaResumen}
}
