export const plantillasDeCargue = {
    "lineas": {
        urlDelIcono: "/assets/img/iconos/excelLineas.png",
        urlDeDescarga: "https://drive.google.com/uc?id=1_ePnu6bYtdAqNLS4VkR7GzyRuVMKVKNT&export=download",
    },
    "tanques": {
        urlDelIcono: "/assets/img/iconos/excelTanques.png",
        urlDeDescarga: "https://drive.google.com/uc?id=12pSsUjx8dQGc8hBUYBP2MfIVAcmnqqR7&export=download",
    },
    "vasijas": {
        urlDelIcono: "/assets/img/iconos/excelVasijas.png",
        urlDeDescarga: "https://drive.google.com/uc?id=1g3tZ6eWtCxjRQuOcvIbsWhJ-6UjiYlLu&export=download",
    },
    "ductos": {
        urlDelIcono: "/assets/img/iconos/excelDuctosGas.png",
        urlDeDescarga: "https://drive.google.com/uc?id=12pSsUjx8dQGc8hBUYBP2MfIVAcmnqqR7&export=download",
    },
    "valvulas": {
        urlDelIcono: "/assets/img/iconos/excelValvulas.png",
        urlDeDescarga: "https://drive.google.com/uc?id=12pSsUjx8dQGc8hBUYBP2MfIVAcmnqqR7&export=download",
    },
    "pozos": {
        urlDelIcono: "/assets/img/iconos/excelPozos.png",
        urlDeDescarga: "https://drive.google.com/uc?id=12pSsUjx8dQGc8hBUYBP2MfIVAcmnqqR7&export=download",
    }
}