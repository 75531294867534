import { MODELO_CONSECUENCIA, SIN_VALORAR } from '../../../scripts/constantes';

export const RUTAS = {
    TAG:"parametrosDeEntrada.informacionDePRD.informacionGeneral.TAG",
    SERVOSISTEM:"parametrosDeEntrada.informacionDePRD.informacionGeneral.servOsistem",
    CAMPO:"parametrosDeEntrada.informacionDePRD.informacionGeneral.campo",
    TIPO_DE_VALVULA:"parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.tipoDeValvula",
    TIPO_DE_EQUIPO: "parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral.tipoDeEquipo",
    DIAMETRO_DE_ACTIVO: "parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral.diametroDeActivo",
    RATING_ENTRADA: "parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.ratingEntrada",
    RATING_SALIDA: "parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.diametroSalida",
    SERIAL: "parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.serial",
    FABRICANTE: "parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.fabricante",
    COD_ACTIVO:"parametrosDeEntrada.informacionDePRD.informacionGeneral.codActivo",
    ESTADO:"parametrosDeEntrada.informacionDePRD.informacionGeneral.estado",
    DOMINIO_CORPORATIVO:'parametrosDeEntrada.dominioCorporativo',
    PLANTAOESTACION: 'parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion'
}

export const GRUPOS = {
    LOCALIZACION:"lozalización",
    INFORMACION_FABRICANTE:"Información del fabricante",
    INFORMACION_PRD:"Información del prd",
}


export const REPORTES_STATUS = {
    CARGANDO: "cargando",
    CARGADO: "cargado",
    NO_CARGADO: "noCargado"
}

export const MODELOS_CONSECUENCIA = {
    SEMICUANTITATIVO:"Semicuantitativo",
    CUALITATIVO:"Cualitativo"
}
export const REQUIERE = {
    INSPECCION: "Por Inspeccionar",
    MANTENIMIENTO: "Por Mantenimiento"
}

export const KEYS = {
    VALORACION: [MODELO_CONSECUENCIA.CUALIT, MODELO_CONSECUENCIA.SEMICUANT],
    INFOCAMPO: [MODELO_CONSECUENCIA.CUALIT, MODELO_CONSECUENCIA.SEMICUANT, SIN_VALORAR]
}