import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataparaProduccion = (activosProduccion) => {
  let listaDeActivosAsmeb314 = [];
  if (activosProduccion !== undefined) {
    activosProduccion.forEach((nodo, i) => {
      let informacionActivo = nodo.variablesDeLaInspeccion
      const cmlRemMin = informacionActivo?.cmlRemMin ? informacionActivo?.cmlRemMin : 'No hay datos';
      const maximaPresionTrabajoAdmisible = informacionActivo?.maximaPresionTrabajoAdmisible ? informacionActivo?.maximaPresionTrabajoAdmisible : 'No hay datos';
      // const vidCmlRemMin = informacionActivo?.vidCmlRemMin? informacionActivo?.vidCmlRemMin : 'No hay datos';
      const porcentajeMaxPerdidaMaterial = informacionActivo?.porcentajeMaxPerdidaMaterial ? informacionActivo?.porcentajeMaxPerdidaMaterial : 'No hay datos';
      const velocidadMaximaDeCorrosion = informacionActivo?.velocidadMaximaDeCorrosion ? informacionActivo?.velocidadMaximaDeCorrosion : 'No hay datos';
      const vidaRemanente = informacionActivo?.vidaRemanente ? informacionActivo?.vidaRemanente : 'No hay datos';
      const tReq = informacionActivo?.tReq
      const tNom = informacionActivo?.tNom
      const tMin = informacionActivo?.tMin
      const corrosionAllowance = informacionActivo?.car

      try {
        listaDeActivosAsmeb314.push({
          'Item': {
            label: 'Item',
            valor: i + 1,
            maxWidth: 80,
          },
          'Nombre': {
            label: 'Nombre',
            valor: `${nodo.informacionGeneral.nombre.valor}/${nodo.id}/API570/ASMEB31_4`,
            maxWidth: 130,
            spanStyle: {
              width: '100px'
            },
            condicion: 'link',
            ruta: '/app/analisis/detalle/lineas/activo'
          },
          'Diámetro' : {
            label: 'Diámetro del activo (in)',
            valor: formatearvalorsegunestado(nodo.informacionGeneral.diametro.valor),
            minWidth: 150
          },
          'Cml remanente mínimo': {
            label:  'Cml vida remanente mínima',
            valor: cmlRemMin?.valor || 'Aún sin calcular',
            minWidth: 150
          },
          'Espesor de retiro (in)': {
            label:  'Espesor de retiro (in)',
            valor: parseFloat(tReq?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(tReq?.valor).toFixed(3),
            minWidth: 150
          },
          'Espesor nominal (in)': {
            label: 'Espesor nominal (in)',
            valor: parseFloat(tNom?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(tNom?.valor).toFixed(3),
            minWidth: 150
          },
          'Mínimo espesor medido (in)': {
            label: 'Mínimo espesor medido (in)',
            valor: parseFloat(tMin?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(tMin?.valor).toFixed(3),
            minWidth: 150
          },
          'CAR (in)': {
            label: 'CAR (in)',
            valor: parseFloat(corrosionAllowance?.valor).toFixed(2) === 'NaN' ? 'Aún no calculado' : parseFloat(corrosionAllowance?.valor).toFixed(3),
            minWidth: 150
          },
          'Porcentaje máximo de perdida de espesor (%)': {
            label: 'Porcentaje máximo de perdida de espesor (%)',
            valor: parseFloat(porcentajeMaxPerdidaMaterial?.valor).toFixed(2) === 'NaN' ? 'Aún sin calcular' : parseFloat(porcentajeMaxPerdidaMaterial?.valor).toFixed(1),
            minWidth: 150
          },
          'Velocidad máxima de corrosión (mpy)': {
            label: 'Velocidad máxima de corrosión (mpy)',
            valor: parseFloat(velocidadMaximaDeCorrosion?.valor).toFixed(2) === 'NaN' ? 'Aún sin calcular' : parseFloat(velocidadMaximaDeCorrosion?.valor).toFixed(1),
            minWidth: 150
          },
          'Vida remanente (años)': {
            label: `Vida remanente (años)`,
            valor: vidaRemanente.valor ? parseFloat(vidaRemanente.valor).toFixed(1) : 'Aún no calculado',
            minWidth: 150
          },
          
          'Maxima presión de trabajo admisible (psi)': {
            label: 'Maxima presión de trabajo admisible (psi)',
            valor: parseFloat(maximaPresionTrabajoAdmisible?.valor).toFixed(2) === 'NaN' ? 'Aún sin calcular' : parseFloat(maximaPresionTrabajoAdmisible?.valor).toFixed(1),
            minWidth: 150
          },
          'Fecha proxima inspeccion': {
            label: `Fecha próxima inspeccion (años)`,
            valor: `${new Date(nodo?.fechaDeInspeccion?.seconds * 1000 + (3600 * 24 * 365 * informacionActivo?.anhosEntreInspecciones?.valor * 1000)).toLocaleString().split(',')[0]} (${informacionActivo?.anhosEntreInspecciones?.valor}) `,
            minWidth: 150
          },
        })
      }
      catch (error) {
        console.log(error)
      }
    });
  }
  return listaDeActivosAsmeb314
};

export default armarDataparaProduccion;