import React from "react";
import { Router } from 'react-router';
import { connect } from 'react-redux';
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';


//Componentes auxiliares:
import CapaDeAplicacion from './capas/aplicacion/CapaDeAplicacion.jsx';
import CapaDeAutenticacion from './capas/autenticacion/CapaDeAutenticacion.jsx';
import LayoutProtegida from './capas/autenticacion/componentes/LayoutProtegida.jsx';

//Variables para el router:
const historialDelRouter = createBrowserHistory();

let App = (props) => {
  return (
    <Router history={historialDelRouter}>
      <Switch>
        <Route path="/login" render={props => <CapaDeAutenticacion {...props} enableClosedSidebar={true} />} />
        <LayoutProtegida path="/app" elUsuarioEstaLogeado={props.elUsuarioEstaLogeado} Componente={CapaDeAplicacion}/>

        <Redirect to="/login" />
      </Switch>
      <ToastContainer />
    </Router>
  );
}

const mapStateToProps = state => ({ elUsuarioEstaLogeado: state.reducerParaUsuario.elUsuarioEstaLogeado });
export default connect(mapStateToProps)(App);