import React from 'react';
import { Button, Row, Col } from "reactstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    totalContainer: {
        marginLeft: "1rem",
        height: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center"
        },
    }
}))

function CustomFooterMUIDatatable({
    changePage,
    changeRowsPerPage,
    options,
    page,
    rowCount,
    rowsPerPage, ...props
}) {
    const classes = useStyles()
    const pages = Math.ceil(rowCount / rowsPerPage);
    const activePage = page + 1;
    return (
        <Row>
            <Col xs="12" md="12" lg="6" xl="6" >
                <div className={classes.totalContainer}>
                    {`Total: ${rowCount}`}
                </div>     
            </Col>
            <Col xs="12" md="12" lg="6" xl="6" style={{ display: "flex", padding: 10 }}>
                <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button className="pagination-button" disabled={activePage === 1}
                        onClick={() => { if (activePage === 1) return; changePage(page - 1); }}>
                        <FaAngleLeft />
                    </Button>
                </div>

                <div className="pagination-button"
                    style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5, }}>
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {"Página " + activePage + " de " + pages}
                    </span>
                </div>

                <div style={{ flex: 1, display: "flex", justifyContent: "center", }}>
                    <Button className="pagination-button" disabled={activePage === pages}
                        onClick={() => { if (activePage >= pages) return; changePage(page + 1); }}>
                        <FaAngleRight />
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default CustomFooterMUIDatatable
