import React from 'react';
import { Button } from 'reactstrap';

//Componentes auxiliares:
import FiltroDinamico from './FiltroDinamico.jsx';
import CustomRightModal from '../plantillaBase/CustomRightModal.jsx';

class ManagerDeFiltros extends React.Component {

    constructor() {
        super();

        this.state = {}
    }

    render() {
        return (
            <CustomRightModal size="md" elModalEstaAbierto={this.props.elModalEstaAbierto} bodyStyle={{ padding: 0 }}
                toggleDelModal={this.props.toggleDelModal} titulo={<span>Herramienta de <b>filtrado</b></span>}
                botonesParaElFooter={[this.obtenerBotonParaAgregarFiltro(this.props)]}>

                <div style={{ marginTop: 20 }}>
                    {this.obtenerFiltros(this.props)}
                    {this.obtenerMensajeDeNingunFiltro(this.props)}
                </div>
            </CustomRightModal>
        );
    }

    componentDidMount() {
    }

    //Métodos para renderizado:
    obtenerBotonParaAgregarFiltro = (props) => {
        return (
            <Button
                color="info"
                onClick={props.agregarFiltro}
                key="botonParaAgregarFiltro">
                Agregar otro filtro
            </Button>
        );
    }

    obtenerFiltros = (props) => {
        let filtrosActivos = props.filtrosActivos;
        let keysDeFiltros = Object.keys(filtrosActivos);
        
        return keysDeFiltros.map((key, i) => {
            if (filtrosActivos[key]['activo']) {
                return (
                    <div key={"filtroVisualizador." + key} style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <FiltroDinamico
                            keyAsociado={key}
                            tipo={filtrosActivos[key]['tipo']}
                            otrosFiltrosActivos={filtrosActivos}
                            desactivarFiltro={props.desactivarFiltro}
                            filtroAsociadoActual={filtrosActivos[key]}
                            mostrarContenido={filtrosActivos[key]['desplegado']}
                            actualizarValorDeFiltro={props.actualizarValorDeFiltro}
                            categorias={props.categorias}
                        />
                    </div>
                );
            }
            else {
                return (
                    <React.Fragment key={"filtroVisualizador." + key} />
                );
            }
        });
    }

    obtenerMensajeDeNingunFiltro = (props) => {
        let hayAlmenosUnFiltroActivo = false;
        let keysDeFiltros = Object.keys(props.filtrosActivos);

        keysDeFiltros.forEach((key, i) => {
            try {
                if (props.filtrosActivos[key]["activo"]) {
                    hayAlmenosUnFiltroActivo = true;
                }
            }
            catch (error) {

            }
        });

        if (!hayAlmenosUnFiltroActivo) {
            return (
                <div style={{ paddingTop: 15, paddingLeft: 20, paddingRight: 20 }}>
                    <p style={{ opacity: 0.6, textAlign: "justify" }}>
                        No has seteado ningún filtro. Haz clic en el botón de abajo para agregar uno y comenzar...
                    </p>
                </div>
            );
        }
    }
}

export default ManagerDeFiltros;