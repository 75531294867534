import { connect } from 'react-redux'
import { firestoreDB, firebaseFunctions } from "../../../../firebase";
import React from "react";

//Iconos
import { AiOutlineFilePdf } from "react-icons/ai";
import { BsFillChatQuoteFill } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

//Componentes Genéricos
import BotonDeCardConEncabezado from "../../../../utilidades/componentes/plantillaBase/BotonDeCardConEncabezado";
import BotonParaSection from "../../../../utilidades/componentes/plantillaBase/BotonParaSection";
import CustomModal from '../../../../utilidades/componentes/plantillaBase/CustomModal';
import CustomSection from "../../../../utilidades/componentes/plantillaBase/CustomSection";
import Preloader from "../../../../utilidades/componentes/plantillaBase/Preloader";

//Componentes Específicos

import ListGroupOrdenesHijas from '../../formatoAnomalias/GestorOrdenesDeTrabajo/components/listGroupOrdenesHijas';
import InformacionGeneralODTMadre from "./components/ili/InformacionGeneralODTMadre";
import ModalDeChat from "./components/chat/ModalDeChat";
import VisualizadorFormularioOrdenHija from "../VisualizadorFormularioOrdenHija";
import VisualizadorFormularioEdicionODTMadre from "../VisualizadorFormularioEdicionODTMadre";

//Scripts Específicos
import { botonesSegunRol } from './scripts/botonesSegunRol'


//Vistas
import Error404 from "../../../404";

let exportarInspeccion = firebaseFunctions.httpsCallable('exportarInspeccion');
//let exportarInspeccion = firebaseFunctions('us-central1').httpsCallable('exportarInspeccion');

class VisualizadorDeFormatoDeInspeccionMadre extends React.Component {

    constructor() {
        super();
        this.unsuscribeAnomalias = undefined;
        this.unsuscribeOdt = undefined;
        this.unsuscribeAnexos = undefined;
        this.unsuscribeInspeccion = undefined;
        this.state = {
            ordenesDeTrabajoHijas: undefined,
            cargando: false,
            error: false,
            infoError: undefined,
            estadoDelRegistro: undefined,
            keysDelRegistro: undefined,
            elChatEstaAbierto: false,
            laConsolaDeDescartaEstaAbierta: false,
            contatorDeRender: 0,
            modalAdicionarFormato:false,
            modalEditarInformacion: false,
            listadoOrdenesHijas: [],
        };
    }

    render() {
        if (!this.state.error) {
            return (
                <React.Fragment>
                    <CustomSection
                        botonesAdicionales={[this.obtenerBotonDeChat()]}
                        subtitulo={this.props.match.params.id}
                        titulo={"Detalle de Órden de Trabajo"}
                        URLIconoPrincipal="/assets/img/logos/giaCore-logo.png">
                        {/* <CardConEncabezado
                            icono={FaList}
                            titulo={"Información general de la orden de trabajo"}
                            botonesAuxiliares={this.generarBotonesSegunRol()}
                        > */}
                            {this.pintarEstadoDeLaInspección()}
                        {/* </CardConEncabezado> */}
                        {this.renderModalAdicionarFormato()}
                        {this.renderModalEditarInformacion()}
                        {this.renderTablaOrdenesHijas()}
                    </CustomSection>

                    <ModalDeChat
                        elModalEstaAbierto={this.state.elChatEstaAbierto}
                        idOrdenDeTrabajo={this.props.match.params.id}
                        toggleDelModal={this.toggleDelChat}
                        usuario={this.state.estadoDelRegistro ? this.state.estadoDelRegistro.responsable : null}
                    />
                    <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                </React.Fragment>
            );
        }
        else {
            return (
                <Error404
                    error={this.props.location.pathname} />
            );
        }

    }

    componentDidCatch(error, info) {
        this.setState({ error: true, infoError: info });
    }

    componentDidMount() {
        this.traerNodo();
    }

    componentWillUnmount() {
        this.unsuscribeOdt();
        this.unsubscribe()
    }

    componentDidUpdate(prevenProps, prevenState) {
        if (prevenProps !== this.props) {
            this.traerNodo();
        }
    }

    //Métodos operativos:
    traerNodo = () => {
        const id = this.props.match.params.id;
        this.unsubscribe = firestoreDB.collection('ordenesDeTrabajo')
            .where('dominioCorporativo', '==', this.props.usuarioActivo.dominioCorporativo)
            .where('ordenDeTrabajoMadre.identificador', '==', id)
            .onSnapshot(
                (snapShots) => {
                    let ordenesDeTrabajo = [];
                    snapShots.forEach((snapshot) => {
                        const data = snapshot.data();
                        try {
                            ordenesDeTrabajo.push({
                                id: snapshot.id,
                                fechaEstimadaDeProgramacion: data.fechaEstimadaDeProgramacion ? new Date(data.fechaEstimadaDeProgramacion.seconds * 1000).toLocaleDateString() : "Sin registrar",
                                fechaDeActualizacionComparador: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000) : "Sin registro",
                                fechaDeActualizacion: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000).toLocaleDateString() : "Sin registro",
                                responsable: data.responsable ? data.responsable.nombre ? data.responsable.nombre : "Sin registro" : "Sin registro",
                                correo: data.responsable ? data.responsable.correo ? data.responsable.correo : "Sin registro" : "Sin registro",
                                lineaDeDucto: data.lineaDeDucto ? data.lineaDeDucto : "Sin registro",
                                descripcionODT: data.descripcionODT ? data.descripcionODT : "Sin registro",
                                coordenadas: data.coordenadas ? data.coordenadas : "Sin registro",
                                valorFacturado: data.valorFacturado ? data.valorFacturado : 0,
                                estado: data.estado ? data.estado : "Sin registro",
                                tecnico: data.tecnico ? data.tecnico.nombre ? data.tecnico.nombre : "Sin registro" : "Sin registro",
                            });
                        }
                        catch (error) {
                            console.log('error.... ',error);
                            this.setState({
                                error: true,
                            })
                        }
                    })
                    let listadoOrdenesHijas = []
                    ordenesDeTrabajo.forEach(orden => {
                        listadoOrdenesHijas.push(orden.id)
                    });
                    this.setState({
                        ordenesDeTrabajoHijas: ordenesDeTrabajo,
                        cargandoDatos: false,
                        listadoOrdenesHijas:listadoOrdenesHijas
                    })
                });

        this.unsuscribeOdt = firestoreDB.doc(`ordenesDeTrabajoMadre/${id}`).onSnapshot((snapshot) => {
            if (snapshot.exists) {
                this.setState({
                    estadoDelRegistro: { ...snapshot.data() },
                    contatorDeRender: this.contatorDeRender + 1
                });
            }

        });
    }

    toggleDelChat = () => {
        this.setState({ elChatEstaAbierto: !this.state.elChatEstaAbierto });
    }

    generarBotonesSegunRol = () => {
        let botones = [];
        let roles = Object.keys(botonesSegunRol);
        const id = this.props.match.params.id;
        for (let i = 0; i < roles.length; i++) {
            let formato = Object.keys(botonesSegunRol[roles[i]])
            for (let j = 0; j < formato.length; j++) {
                let contenidoDelBoton = botonesSegunRol[roles[i]][formato[j]]
                if (this.props.usuarioActivo.administradores.includes(this.props.usuarioActivo.email)) {
                    if (contenidoDelBoton.rol === 'administrador') {
                        switch (contenidoDelBoton.parametroDeLaFuncion) {
                            case 'PDF':
                                botones.push(
                                    <BotonDeCardConEncabezado key={contenidoDelBoton.key}
                                        icono={AiOutlineFilePdf}
                                        title={contenidoDelBoton.titulo}
                                        onClick={() => {
                                            this.setState({ cargando: true })
                                            exportarInspeccion({
                                                'parametroDeLaFuncion': contenidoDelBoton.parametroDeLaFuncion,
                                                'ordenDeInspeccion': id,
                                                'registroDeInspeccion': 'ili'
                                            })
                                                .then(response => {
                                                    this.setState({ cargando: false })
                                                    window.open(response.data.download_url);
                                                })
                                                .catch(error => console.log(error))
                                        }} />
                                )

                                break;
                            case 'EXCEL':
                                botones.push(
                                    <BotonDeCardConEncabezado key={contenidoDelBoton.key}
                                        icono={RiFileExcel2Line}
                                        title={contenidoDelBoton.titulo}
                                        onClick={() => {
                                            this.setState({ cargando: true })
                                            exportarInspeccion({
                                                'parametroDeLaFuncion': contenidoDelBoton.parametroDeLaFuncion,
                                                'ordenDeInspeccion': id,
                                                'registroDeInspeccion': 'ili'
                                            })
                                                .then(response => {
                                                    this.setState({ cargando: false })
                                                    window.open(response.data.download_url);
                                                })
                                                .catch(error => console.log(error))
                                        }} />
                                )
                                break;
                            default:
                                console.log('error')
                        }
                    }
                }
                else if (this.props.usuarioActivo.clientes.includes(this.props.usuarioActivo.email)) {
                    if (contenidoDelBoton.rol === 'cliente') {
                        botones.push(
                            <BotonDeCardConEncabezado key={contenidoDelBoton.key}
                                icono={AiOutlineFilePdf}
                                title={contenidoDelBoton.titulo}
                                onClick={() => {
                                    this.setState({ cargando: true })
                                    exportarInspeccion({
                                        'parametroDeLaFuncion': contenidoDelBoton.parametroDeLaFuncion,
                                        'ordenDeInspeccion': id,
                                        'registroDeInspeccion': 'ili'
                                    })
                                        .then(response => {
                                            this.setState({ cargando: false })
                                            window.open(response.data.download_url);
                                        })
                                        .catch(error => console.log(error))
                                }} />
                        )
                    }
                }
            }
        }
        return botones
    }

    obtenerBotonDeChat = () => {
        return (
            <BotonParaSection
                key="botonChatOrdenesInspeccion"
                icono={<BsFillChatQuoteFill />}
                onClick={this.toggleDelChat}
                title="Chat"
            />
        );
    }

    pintarEstadoDeLaInspección = () => {
        try {
            if (this.state.estadoDelRegistro !== undefined) {
                return (
                    <InformacionGeneralODTMadre
                        estadoDelRegistro={this.state.estadoDelRegistro}
                        id={this.props.match.params.id}
                        usuarioActivo={this.props.usuarioActivo}
                        toggleModal={() => this.toggleModaEditarInformacion()}
                    />
                )
            } else {
                return (<React.Fragment></React.Fragment>);
            }
        }
        catch (error) {
            this.setState({ error: true })
            return (<React.Fragment></React.Fragment>);
        }
    }

    renderModalAdicionarFormato = (seccion) => {
        return (
            <CustomModal
                titulo={'Añadir Formato'}
                elModalEstaAbierto={this.state.modalAdicionarFormato}
                toggleDelModal={this.toggleModaAdicionarFormato}
                hideFooter={true}
                children={
                    <VisualizadorFormularioOrdenHija
                        cerrarModal={this.toggleModaAdicionarFormato}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.detalleILI}
                        ordenMadre = {this.props.match.params.id}
                        dominioCorporativo = {this.props.usuarioActivo.dominioCorporativo}
                        listaDeFormatos={this.state.ordenesDeTrabajoHijas}
                        seccion={'nuevoFormato'}
                    />}
            >
            </CustomModal>
        );
    }

    renderModalEditarInformacion = (seccion) => {
        return (
            <CustomModal
                titulo={'Editar Orden'}
                elModalEstaAbierto={this.state.modalEditarInformacion}
                toggleDelModal={this.toggleModaEditarInformacion}
                hideFooter={true}
                children={
                    <VisualizadorFormularioEdicionODTMadre
                        cerrarModal={this.toggleModaEditarInformacion}
                        ordenDeTrabajo={this.props.match.params.id}
                        informacionOT={this.state.estadoDelRegistro}
                        ordenMadre = {this.props.match.params.id}
                        dominioCorporativo = {this.props.usuarioActivo.dominioCorporativo}
                        seccion={'nuevoFormato'}
                        listadoOrdenesHijas={this.state.listadoOrdenesHijas}
                    />}
            >
            </CustomModal>
        );
    }

    renderTablaOrdenesHijas = () => {
        console.log(this.state.ordenesDeTrabajoHijas)
        return (
            <ListGroupOrdenesHijas
                listaDeFormatos={this.state.ordenesDeTrabajoHijas}
                ordenMadre = {this.props.match.params.id}
                usuarioActivo={this.props.usuarioActivo}
                toggleModal={() => this.toggleModaAdicionarFormato()}
            />
        )
    }

    toggleModaAdicionarFormato = () => {
        this.setState({ modalAdicionarFormato: !this.state.modalAdicionarFormato })
    }
    toggleModaEditarInformacion = () => {
        this.setState({ modalEditarInformacion: !this.state.modalEditarInformacion })
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

export default connect(mapStateToProps)(VisualizadorDeFormatoDeInspeccionMadre);