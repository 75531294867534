const listarValoresSegunRango = (data, menor, medio, mayor) => {
    const listasDeDatos = {
        menor: [],
        medio: [],
        mayor: []
    };
    
    if(data <= menor){
        listasDeDatos['menor'].push(data)
    }else if(data <= medio){
        listasDeDatos['medio'].push(data)
    }else if(data > mayor){
        listasDeDatos['mayor'].push(data)
    }

    return listasDeDatos
}

export default listarValoresSegunRango
