import {useState} from 'react'

/** Datos en grueso */
import { crearListaTablaInfoActivo } from '../scripts/crearListaTablaInfoActivo';

/**
 * @Return dataTablaInfo, cargando
 */
export const useFetchTablaInfo = () => {

    const [dataTablaInfo, setDataTablaInfo] = useState( [] );
    const [cargandoInfo, setCargandoInfo] = useState( false );

    const armarDatosTablaInfo = ( informacionGeneral ) => {
        setCargandoInfo( true )
        setDataTablaInfo( crearListaTablaInfoActivo( informacionGeneral ) )
        setCargandoInfo( false )
    };

    return {dataTablaInfo, cargandoInfo, armarDatosTablaInfo}
}
