import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import {
    Card, CardBody,
    CardTitle
} from 'reactstrap';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            // se agrega unicamente para centrar la gráficas de los contadores 
            // de ser necesario borrar junto con style linea 28
            style={{ height: "100%" }}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ height: "100%" }}>
                   {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: "white",
//         color: "black"
//     },
// }));


export default function TabsGraficas(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = async(event, newValue) => {
        let changeTab = true
        if(!!props.onChangeTab )changeTab = await props.onChangeTab({index:newValue})
        !!changeTab && setValue(newValue);
    };

    return (
        <Card style={{ height: "100%" }}  >
            <CardTitle>
                    <Tabs 
                        indicatorColor="primary" 
                        value={value} 
                        onChange={handleChange} /*aria-label="simple tabs example"*/
                        variant="scrollable"
                        textColor="primary"
                        scrollButtons="on"
                        aria-label="full width tabs example"
                        >
                        {props.contentHeader.map((item,i)=>(<Tab key={item+i} wrapped label={item} {...a11yProps(i)} />))}
                    </Tabs>
            </CardTitle>
            <CardBody style={{ padding:"0.2rem",...props.styleBody}}>
                {props.contentBody.map((item, i)=>(<TabPanel key={item+i} value={value} index={i} children={item()} />))}
            </CardBody>
        </Card>
    );
}