import { useEffect, useState } from 'react'

/** Firebase */
import { firestoreDB } from '../../../../../firebase';

/** Inicio Datos de prueba */
import { dataTabla3Columnas } from '../datosDePrueba/dataTabla3Columnas';
/** Fin Datos de prueba */

export const useFetchTablaActivos = ( 
    ) => {

    const [datos, setDatos] = useState([])
    const [last, setLast] = useState(null);
    const [primerCargando, setPrimerCargando] = useState(false)
    const [cargando, setCargando] = useState(false);
    //     {
    //         activos: [],
    //         last: null,
    //         PrimerCargando: false,
    //         cargando: false
    //     }
    // );


    useEffect(() => {
    }, [datos])

    // useEffect(() => {
    //     consultarActivos()
    // }, [])

    /**
     * Retorna los datos consultados y la función que ejecuta el llamado a base de datos paginado.
     * @param {string} dominioCorporativo Dominio al cual pertenece el usuario.
     * @param {string} campoTarget Campo al cual se van a consultar los activos.
     * @param {number} pagina Página en la que se encuentra la tabla.
     * @param {number} posicionUltimoDato Posición del ultimo dato consultado.
     * @param {number} limite Límite de datos a consultar.
     * @returns datos de la tabla sin formato, callback, posición del último dato. 
     */

    const consultarActivos = async(
        dominioCorporativo, 
        campoTarget, 
        pagina, 
        posicionUltimoDato,
        limite,
        primeraCarga
    ) => {

        /** Inicio Proceso de consulta a db */

        setPrimerCargando( primeraCarga? true : false )
        setCargando( primeraCarga? false : true )

        let promiseActivos = firestoreDB.collection("activos");
        promiseActivos = promiseActivos.where("dominioCorporativo", "==", dominioCorporativo);
        promiseActivos = promiseActivos.where("informacionDeLosPadres.campoAsociado.nombre", "==", campoTarget);
        promiseActivos = promiseActivos.where("modulos.API510", "==", true);
        (pagina !== 1 && (promiseActivos = promiseActivos.startAfter(posicionUltimoDato)));
        promiseActivos = promiseActivos.limit(limite);
        promiseActivos.get()
                .then(snapShots => {
                    setLast(snapShots.docs[snapShots.docs.length - 1]);
                    snapShots.forEach(snapShot => {
                        setDatos([...datos, {
                            id: snapShot.id,
                            ...snapShot.data(),
                            referencia: snapShot.ref
                        }]);
                    })
                    setCargando( false ); 
                    setPrimerCargando( false );
                })
                .catch(error => {console.log(error); setCargando( false ); setPrimerCargando( false )})
                
        /** Fin Proceso de consulta a db */

    }

    return { datos, cargando, primerCargando, last, consultarActivos };

}
