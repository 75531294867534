export const botonesSegunRol = {
    'administrador': {
        'PDF': {
            'rol': 'administrador',
            'key': 'boton del administrador PDF',
            'icono': 'AiOutlineFilePdf',
            'titulo': 'Descargar PDF',
            'parametroDeLaFuncion': 'PDF'
        },
        'EXCEL': {
            'rol': 'administrador',
            'key'   : 'boton del administrador EXCEL',
            'icono': 'RiFileExcel2Fill',
            'titulo': 'Descargar EXCEL',
            'parametroDeLaFuncion': 'EXCEL'
        }
    },
    'cliente': {
        'PDF': {
            'rol': 'cliente',
            'key': 'boton del administrador',
            'icono': 'AiOutlineFilePdf',
            'titulo': 'Descargar PDF',
            'parametroDeLaFuncion': 'PDF'
        }
    }
}