import { firestoreDB, firebaseApp } from '../../../../../firebase';

const obtenerOrdenesDeTrabajoConFiltro = (terminarSubscripcionPrevia, dominioCorporativo, filtro, fechaCustom,fechaCustomEnd) => {
    return new Promise((resolve, reject) => {
        let ordenesDeTrabajo = [];
        let estados = [];
        const fechaDeFiltro = obtenerFechaDeFiltro(filtro, fechaCustom);
        const queryDeOrdenesFiltradas = obtenerQueryDeOrdenesFiltradas(filtro, dominioCorporativo, fechaDeFiltro, fechaCustomEnd);
        const unsubscribe = queryDeOrdenesFiltradas.onSnapshot((snapShots) => {
            if (terminarSubscripcionPrevia) terminarSubscripcionPrevia();
            snapShots.forEach((snapshot) => {
                const data = snapshot.data();
                try {
                    ordenesDeTrabajo.push({
                        id: snapshot.id,
                        fechaEstimadaDeProgramacion: data.fechaEstimadaDeProgramacion ? new Date(data.fechaEstimadaDeProgramacion.seconds * 1000).toLocaleDateString() : "Sin registrar",
                        fechaDeActualizacion: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000).toLocaleDateString() : "Sin registro",
                        fechaDeActualizacionComparador: data.fechaDeActualizacion ? new Date(data.fechaDeActualizacion.seconds * 1000) : "Sin registro",
                        responsable: data.responsable ? data.responsable.nombre ? data.responsable.nombre : "Sin registro" : "Sin registro",
                        correo: data.responsable ? data.responsable.correo ? data.responsable.correo : "Sin registro" : "Sin registro",
                        lineaDeDucto: data.lineaDeDucto ? data.lineaDeDucto : "Sin registro",
                        descripcionODT: data.descripcionODT ? data.descripcionODT : "Sin registro",
                        coordenadas: data.coordenadas ? data.coordenadas : "Sin registro",
                        estado: data.estado ? data.estado : "Sin registro",
                        tecnico: data.tecnico ? data.tecnico.nombre ? data.tecnico.nombre : "Sin registro" : "Sin registro",
                        valorFacturado: data.valorFacturado ? data.valorFacturado : 0,
                    });
                    if ((!data.fechaDeActualizacion || new Date(data.fechaDeActualizacion.seconds * 1000) >= fechaDeFiltro)) {
                        estados.push(
                            data.estado,
                        );
                    }
                }
                catch (error) {
                    console.log('error.... ', error);
                    reject(error);
                }
            })
            resolve([unsubscribe, ordenesDeTrabajo, estados]);
        });
    });
}

//Funciones lógicas
const obtenerFechaDeFiltro = (tipoDeFiltro, fechaCustom) =>{
    switch(tipoDeFiltro){
        case 'semanal':
            return new Date( Date.now() - 60*60*24*7*1000).setHours(0,0,0,0);
        case 'quincenal':
            return new Date( Date.now() - 60*60*24*15*1000).setHours(0,0,0,0);
        case 'mensual':
            return new Date( Date.now() - 60*60*24*30*1000).setHours(0,0,0,0);
        case 'anual':
            return new Date( Date.now() - 60*60*24*365*1000).setHours(0,0,0,0);
        case 'custom':
            return fechaCustom
        default:
            return new Date( Date.now() - 60*60*24*15*1000).setHours(0,0,0,0);
    }
}

const obtenerQueryDeOrdenesFiltradas = (tipoDeFiltro, dominioCorporativo, fechaDeFiltro, fechaCustomEnd ) =>{
    if(tipoDeFiltro === 'custom'){
        return firestoreDB.collection('ordenesDeTrabajoMadre')
            .orderBy('fechaDeActualizacion')
            .where('dominioCorporativo', '==', dominioCorporativo)
            .startAt(firebaseApp.firestore.Timestamp.fromDate(new Date(fechaDeFiltro)))
            .endBefore(firebaseApp.firestore.Timestamp.fromDate(new Date(fechaCustomEnd)));
    }else{
        return firestoreDB.collection('ordenesDeTrabajoMadre')
            .orderBy('fechaDeActualizacion')
            .where('dominioCorporativo', '==', dominioCorporativo)
            .startAt(firebaseApp.firestore.Timestamp.fromDate(new Date(fechaDeFiltro)))
            .endBefore(firebaseApp.firestore.Timestamp.fromDate(new Date(Date.now())));
    }
}

export default obtenerOrdenesDeTrabajoConFiltro;