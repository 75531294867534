//Utilidades de firebase:
import { firebaseFunctions } from '../../../../firebase';
import assert from 'assert'

//Microservicios firebase:
const generarInformeFinal = firebaseFunctions.httpsCallable('odt_generarInformeFinal');
const generarUrlDeImagenes = firebaseFunctions.httpsCallable('odt_generarUrlDeImagenes');
const generarControlDeInformes = firebaseFunctions.httpsCallable('odt_generarControlDeInformes');
// const generarControlDeInformes = firebaseFunctions.httpsCallable('odt_generarControlDeInformes');
/**
 * **Servicio**: Descargar Imagenes
 * 
 * @param {String} ordenDeTrabajoHija Id del informe (orden de trabajo hija).
 * @returns Zip file
 */
const servicioDescargarImagenes = async (ordenDeTrabajoHija) => {
  try {
    /* ----------------- Obtener listado de imagenes en storage ----------------- */
    const response = await generarUrlDeImagenes({ordenDeTrabajoHija})

    const listadoDeImagenes = response.data?.data?.images

    assert(listadoDeImagenes && listadoDeImagenes.length > 0, "No hay imagenes para descargar")

    return listadoDeImagenes

  } catch (error) {
    throw error
  }
}

/**
 * **Servicio**: Generar Informe Final
 * 
 * @param {String} ordenDeTrabajoHija Id del informe (orden de trabajo hija).
 * @param {String} formato Formato del documento: **"EXCEL"**, **"PDF"** 
 * @returns {Promise<String>} URL to download requested document
 */
const servicioGenerarInformeFinal = async ({ ordenDeTrabajoHija, formato }) => {
  try {
    const response = await generarInformeFinal(
      {
        ordenDeTrabajoHija,
        formato
      }
    )

    /* ------------------ Verifica que la respuesta fue exitosa ----------------- */
    assert(response.data.code === 'ok')

    return response.data.download_url

  } catch (error) {
    throw error
  }
}

const servicioGenerarControlDeInformes = async ({formato}) => {
  try {
    const response = await generarControlDeInformes(
      {
        formato
      }
    )

    /* ------------------ Verifica que la respuesta fue exitosa ----------------- */
    assert(response.data.code === 'ok')

    return response.data.download_url

  } catch (error) {
    throw error
  }
}

// /**
//  * **Servicio**: Generar Control De Informes
//  * 
//  * @param {String} ordenDeTrabajoHija Id del informe (orden de trabajo hija).
//  * @param {String} formato Formato del documento: **"EXCEL"**, **"PDF"** 
//  * @returns {Promise<String>} URL to download requested document
//  */
//  const servicioGenerarControlDeInformes = async ({ ordenDeTrabajoHija, formato }) => {
//   try {
//     const response = await generarControlDeInformes(
//       {
//         ordenDeTrabajoHija,
//         formato
//       }
//     )

//     /* ------------------ Verifica que la respuesta fue exitosa ----------------- */
//     assert(response.data.code === 'ok')

//     return response.data.download_url

//   } catch (error) {
//     throw error
//   }
// }


export { servicioGenerarInformeFinal, servicioDescargarImagenes, servicioGenerarControlDeInformes };