import React from 'react';
import { connect } from 'react-redux';

//Componentes auxiliares:
import ResumenDeModulo from '../../../../../utilidades/componentes/resumenDeModulo/ResumenDeModulo';

//Scripts auxiliares:
import { categorias } from '../asmeB318s/estaticos/opcionesDeFiltros';
import { opcionesParaGraficas } from '../api580/estaticos/opcionesParaGraficas';

//Funciones para manipular state redux:
import { actualizarDataAsmeB318s, actualizarModuloActivo } from '../../../../../storage/reducerParaData';
import Error404 from "../../../../404";

const activosDePrueba = require('../api580/estaticos/ejemploDeActivos.json');
const segmentosDePrueba = require('../api580/estaticos/ejemploDeSegmentos.json');

const itemsColumnaIzquierda = {
    1: {
        activo: true,
        tipo: "MATRIZ_DE_RIESGO",
        props: {
            criterioDeOrdenamiento: {
                id: "riesgo", desc: true
            }
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[0]
        }
    },
    3: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[1]
        }
    },
    4: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[1].opciones[2]
        }
    }
};

const itemsColumnaDerecha = {
    1: {
        activo: true,
        tipo: "TABLA_DE_NODOS",
        props: {
            criterioDeOrdenamiento: {
                id: "riesgo", desc: true
            }
        }
    },
    2: {
        activo: true,
        tipo: "MAPA_DEL_ACTIVO",
        props: {}
    },
    3: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[1]
        }
    },
    4: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[6]
        }
    },
    5: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[3]
        }
    },
};

class VisualizadorAsmeB318S extends React.Component {

    constructor() {
        super();

        this.state = {
            error: false,
        };
    }

    render() {
        if (!this.state.error) {
            return (
                <React.Fragment>
                    <ResumenDeModulo
                        categoriasDeFiltrado={categorias}
                        itemsColumnaDerecha={itemsColumnaDerecha}
                        itemsColumnaIzquierda={itemsColumnaIzquierda}
                        metodoParaActualizarDataEnRedux={this.props.actualizarDataAsmeB318s}
                        moduloTarget="ASME_B31_8S"
                        nodosEnRedux={this.props.dataDeB318sEnRedux}
                        nodosParaDesarrollo={{ "todosLosSegmentos": segmentosDePrueba, "todosLosActivos": activosDePrueba }}
                        opcionesParaGraficas={opcionesParaGraficas}
                        permitirConsultas={true}
                        titulo="ASME B31.8S"
                        subtitulo="Análisis de riesgo para gasoductos"
                    />
                </React.Fragment>
            );
        }
        else {
            return (
                <Error404
                    error={this.props.location.pathname} />
            );
        }
    }
    static getDerivedStateFromError(error) {
        this.setState({ error: true })
    }

    componentDidMount(){
        this.props.actualizarModuloActivo('ASMEB31_8S')
    }
}

const mapStateToProps = state => ({
    dataDeB318sEnRedux: state.reducerParaData["/app/analisis/riesgo/b318s"],
});

const mapDispatchToProps = dispatch => ({
    actualizarDataAsmeB318s: nuevaData => { dispatch(actualizarDataAsmeB318s(nuevaData)); },
    actualizarModuloActivo: nuevoModulo => { dispatch(actualizarModuloActivo(nuevoModulo)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizadorAsmeB318S);