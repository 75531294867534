import React from 'react';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import { FaChartPie } from 'react-icons/fa';

//Componentes auxiliares:
import GraficoDeTorta from '../visualizadores/GraficoDeTorta.jsx';
import CardConEncabezado from '../plantillaBase/CardConEncabezado.jsx';
import GraficoDeBarrasDoblementeAgrupadas from '../visualizadores/GraficoDeBarrasDoblementeAgrupadas.jsx';

//Constantes auxiliares:
import { opcionesParaGraficas } from '../../../vistas/aplicacion/analisis/riesgo/api580/estaticos/opcionesParaGraficas';
import { opcionesParaGraficasSemicuantitativas as opcionesParaValvulas } from '../../../vistas/aplicacion/analisis/riesgo/api581Valvulas/estaticos/opcionesParaGraficasSemicuantitativas'
import { opcionesParaGraficasCualitativas as opcionesParaValvulasCualitativas } from '../../../vistas/aplicacion/analisis/riesgo/api581Valvulas/estaticos/opcionesParaGraficasCualitativas'
class CardConGrafica extends React.Component {

    constructor() {
        super();

        this.state = {
            graficaActiva: opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficas[0].opciones[0]
        }
    }  

    render() {
        return (
            <CardConEncabezado titulo={this.state.opcionesDeLaGraficaActiva.label} icono={FaChartPie}>
                {/* <div> */}
                <Row>
                    <Col xs="12" md="4" lg="4" xl="5" style={{ paddingTop: 5, paddingBottom: 5 }}>
                        {this.SelectorTipoDeGrafica()}
                    </Col>

                    <Col xs="12" md="8" lg="8" xl="7" style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Select
                            placeholder="Variables"
                            options={this.state.graficaActiva.opciones}
                            value={this.state.opcionesDeLaGraficaActiva}
                            onChange={(e) => { this.setState({ opcionesDeLaGraficaActiva: e }); }} />
                    </Col>
                </Row>

                <div style={{ marginTop: 25, }}>
                    {this.obtenerGraficaDeTorta()}
                    {this.obtenerGraficaDeBarrasDoblementeAgrupadas()}
                </div>
                {/* </div> */}
            </CardConEncabezado>
        );
    }

    componentDidMount() {
        this.setState({
            graficaActiva: this.props.graficaActiva || opcionesParaGraficas[0],
            opcionesDeLaGraficaActiva: this.props.opcionesDeLaGraficaActiva || opcionesParaGraficas[0].opciones[0]
        });
    }

    componentDidUpdate(prevenProps, prevenState){
        if(prevenProps !== this.props){
            this.setState({
                graficaActiva: this.props.graficaActiva || opcionesParaGraficas[0],
                opcionesDeLaGraficaActiva: this.props.opcionesDeLaGraficaActiva || opcionesParaGraficas[0].opciones[0]
            });
        }
    }

     
    SelectorTipoDeGrafica = () =>{
        if(this.props.modeloDeConsecuencia === "Cualitativo"){
            if(this.props.data[0] && this.props.data[0].resultados){
                const opcionesParaValvulasApertura =  [opcionesParaValvulasCualitativas[0],opcionesParaValvulasCualitativas[2]];
                const opcionesParaValvulasFuga = [opcionesParaValvulasCualitativas[0],opcionesParaValvulasCualitativas[1]];
                return(
                    <React.Fragment>
                        <Select placeholder="Tipo de gráfica" options={this.props.columna ==="itemsColumnaIzquierda" ? opcionesParaValvulasApertura : opcionesParaValvulasFuga}
                        value={this.state.graficaActiva} 
                        onChange={(e) => {
                            this.setState({
                                graficaActiva: e,
                                opcionesDeLaGraficaActiva: e.opciones[0]
                            });
                        }} />
                    </React.Fragment>                
                )
            }
        }else{
            if(this.props.data[0] && this.props.data[0].resultados){
                const opcionesParaValvulasApertura =  [opcionesParaValvulas[0],opcionesParaValvulas[2]];
                const opcionesParaValvulasFuga = [opcionesParaValvulas[0],opcionesParaValvulas[1]];
                return(
                    <React.Fragment>
                        <Select placeholder="Tipo de gráfica" options={this.props.columna ==="itemsColumnaIzquierda" ? opcionesParaValvulasApertura : opcionesParaValvulasFuga}
                        value={this.state.graficaActiva} 
                        onChange={(e) => {
                            this.setState({
                                graficaActiva: e,
                                opcionesDeLaGraficaActiva: e.opciones[0]
                            });
                        }} />
                    </React.Fragment>                
                )
            }else{
                return(
                    <React.Fragment>
                        <Select placeholder="Tipo de gráfica" options={opcionesParaGraficas}
                        value={this.state.graficaActiva} onChange={(e) => {
                            this.setState({
                                graficaActiva: e,
                                opcionesDeLaGraficaActiva: e.opciones[0]
                            });
                        }} />
                    </React.Fragment>  
                )
            }
        }
       
    }

    //Métodos para renderizado:
    obtenerGraficaDeBarrasDoblementeAgrupadas = () => {
        if (this.state.graficaActiva.value === "dobleAgrupamiento") {
            return (
                <GraficoDeBarrasDoblementeAgrupadas
                    modeloDeConsecuencia={this.props.modeloDeConsecuencia}
                    data={this.props.data}
                    keyVariable={this.state.opcionesDeLaGraficaActiva.keyVariable}
                    keyAgrupamiento1={this.state.opcionesDeLaGraficaActiva.keyAgrupamiento1}
                    keyAgrupamiento2={this.state.opcionesDeLaGraficaActiva.keyAgrupamiento2}
                    estado={this.state.opcionesDeLaGraficaActiva.estado}
                    parametrizacion={this.state.opcionesDeLaGraficaActiva.comParametrizacion || this.props.parametrizacionCorporativa[this.state.opcionesDeLaGraficaActiva.keyDeParametrizacion]}
                    coleccionActiva={this.props.coleccionActiva}
                />
            );
        }
    }

    obtenerGraficaDeTorta = () => {
        if (
            this.state.graficaActiva.value === "torta"
            || this.state.graficaActiva.value === "tortaFuga"
            || this.state.graficaActiva.value === "tortaApertura"
        ) {
            return (
                <GraficoDeTorta
                    modeloDeConsecuencia={this.props.modeloDeConsecuencia}
                    data={this.props.data}
                    keyVariable={this.state.opcionesDeLaGraficaActiva.keyVariable}
                    parametrizacion={this.props.parametrizacionCorporativa[this.state.opcionesDeLaGraficaActiva.keyDeParametrizacion]}
                />
            );
        }
    }
}

export default CardConGrafica;