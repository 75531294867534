
const agruparPorcentajes = (condicionSegmento) => {
    const agrupador = [0];
    let porcentajeSumado = undefined;
    if(condicionSegmento !== []){
        const reducer = (acumulador, valorActual) => acumulador + valorActual;
        condicionSegmento.forEach(item => {
            // console.log('estos son los items que le llegan ', item)
            agrupador.push(parseInt(item.valor));
        })
        porcentajeSumado = agrupador.reduce(reducer)
    }else {
        porcentajeSumado = [0]
    }
    return porcentajeSumado
}

export default agruparPorcentajes
