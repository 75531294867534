import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Este componente crea la tabla de react Table dinámicamente, se necesita unicamente pasarle los datos de la tabla organizados
 * EJ: const tabla = [
 *  {
 *      dato1: {label: nombre del encabezado, valor: valor del dato}
 *  }
 * ]
 */
let estiloParaHeader = { color: "rgba(32,39,140,0.5)", marginBottom: 0};
let estiloCeldas = { display: "flex", width: "100%", justifyContent: 'center', textDecoration: 'none' }

export const crearColumnasParaTabla = (datosDeTabla, mensajeTablaVacia) => {
    const datosTablaInternos = [];
    if(datosDeTabla === undefined){
        datosTablaInternos.push({
            accesor: mensajeTablaVacia || 'No hay datos para mostrar',
            Header: () => (<p style={estiloParaHeader}>{mensajeTablaVacia || 'No hay datos para mostrar'}</p>),
            minWidth: 100,
            resizable: false,
            Cell: ({ row }) => (
                <div style={{ ...estiloCeldas }}>
                    Sin datos
                </div>
            ),
        })
        return datosTablaInternos
    }
    if (datosDeTabla[0] !== undefined) {
        Object.keys(datosDeTabla[0]).forEach(dato => {
            switch (datosDeTabla[0][dato].condicion) {
                case undefined:
                    datosTablaInternos.push({
                        accesor: `${dato}`,
                        Header: () => (<p style={estiloParaHeader}>{datosDeTabla[0][dato].label}</p>),
                        minWidth: datosDeTabla[0][dato].minWidth || 100,
                        maxWidth: datosDeTabla[0][dato].maxWidth,
                        resizable: datosDeTabla[0][dato].resizable || false,
                        Cell: ({ row }) => (
                            <div style={{ ...estiloCeldas }}>
                                <span style={{textAlign: 'center'}}>{row._original[dato]}</span>
                            </div>
                        ),
                        
                    });
                    break;
                case 'link':
                    datosTablaInternos.push({
                        accesor: `${dato}`,
                        Header: () => (<p style={estiloParaHeader}>{datosDeTabla[0][dato].label}</p>),
                        minWidth: datosDeTabla[0][dato].minWidth || 100,
                        maxWidth: datosDeTabla[0][dato].maxWidth || 130,
                        resizable: datosDeTabla[0][dato].resizable || false,
                        Cell: ({ row }) => {
                            const ruta = datosDeTabla[0][dato].ruta + '/' + row._original[dato].split("/")[1] + '/' + row._original[dato].split("/")[2] + '/' + row._original[dato].split("/")[3]
                            return (<div style={{ ...estiloCeldas }}>
                                <Link to={ruta} 
                                onClick={() => {guardarEnElLocalStorage(ruta);}}
                                >
                                    <p style={{ margin: '0 auto', color: '#545cd8', fontWeight: 300, textDecoration: 'underline', overflowX: 'auto', textAlign: 'center', width: datosDeTabla[0][dato].maxWidth - 10 || 100 }}
                                    >{row._original[dato].split("/")[0]}</p>
                                </Link>
                            </div>
                        )},
                    })
                    break;
                case 'fecha':
                    datosTablaInternos.push({
                        accesor: `${dato}`,
                        Header: () => (<p style={estiloParaHeader}>{datosDeTabla[0][dato].label}</p>),
                        minWidth: datosDeTabla[0][dato].minWidth || 100,
                        maxWidth: datosDeTabla[0][dato].maxWidth,
                        resizable: datosDeTabla[0][dato].resizable || false,
        
                        Cell: ({ row }) => {
                            return (<div style={{ ...estiloCeldas }}>
                                <Link to={datosDeTabla[0][dato].ruta} 
                                onClick={() => {guardarEnElLocalStorage(datosDeTabla[0][dato].ruta)}}
                                >
                                    <p style={{ margin: '0 auto', color: '#545cd8', fontWeight: 300, textDecoration: 'underline', marginLeft: 100, marginRight:100, overflowX: 'auto'  }}
                                    >{row._original[dato]}</p>
                                </Link>
                            </div>
                        )},
                    })
                    break;
                case 'dataEnriquecida':
                    datosTablaInternos.push({
                        accesor: `${dato}`,
                        Header: () => (<p style={estiloParaHeader} dangerouslySetInnerHTML={{ __html: datosDeTabla[0][dato].label}}></p>),
                        minWidth: datosDeTabla[0][dato].minWidth || 100,
                        maxWidth: datosDeTabla[0][dato].maxWidth ,
                        resizable: datosDeTabla[0][dato].resizable || false,
                        Cell: ({ row }) => (
                            <div style={{ ...estiloCeldas }} >
                                {`${row._original[dato] + 1}`}
                            </div>
                        ),
                        
                    });
                    break;
                default:
                    return;
            }        
        }) 
    } else {
        datosTablaInternos.push({
            accesor: 'error',
            Header: () => (<p style={estiloParaHeader}>Error</p>),
            minWidth: 100,
            resizable: false,
            Cell: ({ row }) => (
                <div style={{ ...estiloCeldas }}>
                    No hay datos para mostrar
                </div>
            ),
        })
    }
    return datosTablaInternos;
}

    
const guardarEnElLocalStorage = ( path) => {
    localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path));
}  
/**
 * función para crear los encabezados de la tabla, sólo necesita la lista de los datos 
 */

export const crearListaDatosParaTabla = (datosDeTabla) =>{
    const listaDeDatosParaTabla = [];
    if(datosDeTabla !== undefined){
        datosDeTabla.forEach(datos => {
            listaDeDatosParaTabla.push(armarObjeto(datos))
        });
        return listaDeDatosParaTabla;
    } 
    else {
        listaDeDatosParaTabla.push({
            error: 'error',
        },)
        // return listaDeDatosParaTabla;
    }
}

const armarObjeto = (datos) => {
    let objeto = {};
    Object.keys(datos).forEach(dato => {
        if(typeof(datos[dato].valor) === 'object'){
            objeto[dato] = new Date(datos[dato].valor.seconds * 1000).toLocaleString()
        }else{
            objeto[dato] = datos[dato].valor
        }
    });
    return objeto;
}



