import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

//Scripts auxiliares:
import { obtenerHoraLarga } from '../../scripts/paraFechas';

//Constantes de operación:
const cantidadMaximaDeItemsDelTimeline = 150;

const Timeline = (props) => {
    let cantidadMaxima = cantidadMaximaDeItemsDelTimeline;
    if (props.cantidadMaximaDeItems) { cantidadMaxima = props.cantidadMaximaDeItems };

    let mostrarFechas = true;
    if (props.mostrarFechas !== undefined) { mostrarFechas = props.mostrarFechas };

    let ordenarAscendente = false;
    if (props.ordenarAscendente !== undefined) { ordenarAscendente = props.ordenarAscendente };

    return (
        <VerticalTimeline layout="1-column" className={(!mostrarFechas) ? "vertical-without-time" : ""}>
            {obtenerItemsDelTimeline(props.itemsDelTimeline, cantidadMaxima, mostrarFechas, ordenarAscendente)}
        </VerticalTimeline>
    );
}

const obtenerItemsDelTimeline = (itemsDelTimeline, cantidadMaxima, mostrarFechas, ordenarAscendente = false) => {
    let listaDeItemsDelTimeline = [];
    let logDeProceso = itemsDelTimeline || [];

    if (!ordenarAscendente) {
        for (let i = logDeProceso.length - 1; i >= 0 && i >= (logDeProceso.length - cantidadMaxima); i--) {

            let objetoDeConfiguracionDelTimeline = {};
            if (mostrarFechas) { objetoDeConfiguracionDelTimeline = { date: obtenerHoraLarga(logDeProceso[i].fecha) } };

            listaDeItemsDelTimeline.push(
                <VerticalTimelineElement
                    {...objetoDeConfiguracionDelTimeline}
                    key={"itemDelTimeline." + i} className="vertical-timeline-item"
                    icon={<i className={"badge badge-dot badge-dot-xl badge-" + logDeProceso[i].status}> </i>}>
                    {logDeProceso[i].contenido}
                </VerticalTimelineElement>
            );
        }
    }
    else {
        for (let i = 0; i < logDeProceso.length && i < cantidadMaxima; i++) {

            let objetoDeConfiguracionDelTimeline = {};
            if (mostrarFechas) { objetoDeConfiguracionDelTimeline = { date: obtenerHoraLarga(logDeProceso[i].fecha) } };

            listaDeItemsDelTimeline.push(
                <VerticalTimelineElement
                    {...objetoDeConfiguracionDelTimeline}
                    key={"itemDelTimeline." + i} className="vertical-timeline-item"
                    icon={<i className={"badge badge-dot badge-dot-xl badge-" + logDeProceso[i].status}> </i>}>
                        
                    {logDeProceso[i].contenido}
                </VerticalTimelineElement>
            );
        }
    }


    return listaDeItemsDelTimeline;
}

export default Timeline;