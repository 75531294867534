import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';

import CardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import Preloader from '../../../../../../utilidades/componentes/plantillaBase/Preloader';

//import useStyles from './styles'


import ValoresAgrupados from '../../componentes/ValoresAgrupados';
import useResumen from '../../hooks/useResumen';
import {ESTADO_PRD}from "../../scripts/constantes"
export default (props) => {
    const [state, cargando] = useResumen()

    return (
        <React.Fragment>
            <Preloader elPreloaderEstaAbierto={cargando} />
            <CardConEncabezado titulo={"Gestión de Válvulas"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} >
                        <ValoresAgrupados
                            containerProps={{ style: { height: "100%" } }}
                            titulo="Inventario"
                            bodyProps={{ style: { height: "100%" } }}
                            body={{
                                containerProps: { xs: "12", sm: "12", md: "12", xl: "12" },
                                tituloSecundario: "Total Válvulas",
                                valor: state.total,
                                iconProps: { className: "lnr-laptop-phone text-dark opacity-8" },
                                containerIconProps: { className: "icon-wrapper-bg opacity-10 bg-secondary" },
                                subtitulos: [{
                                    label: "Inspeccionadas:",
                                    valor: state.inspeccionadas,
                                    valorProps: { className: "d-inline text-success pr-1" }
                                },
                                {
                                    label: "Sin Inspeccionar:",
                                    valor: state.sinInspeccion,
                                    valorProps: { className: "d-inline text-danger pr-1" }
                                },
                                {
                                    label: "Fuera de servicio:",
                                    valor: get(state, `estado[${ESTADO_PRD.FUERA_DE_SERVICIO}]`,0),
                                    valorProps: { className: "d-inline text-warning pr-1" }
                                }
                            ]
                            }
                            }
                            footer={{
                                button: {
                                    label: "Ver Reporte Completo",
                                    conLink: {
                                        toPath: "/app/gestion/api581Valvulas/inventario",
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <ValoresAgrupados
                            containerProps={{ style: { height: "100%" } }}
                            titulo="Mantenimiento"
                            bodyProps={{ style: { height: "100%" } }}
                            body={{
                                containerProps: { xs: "12", sm: "12", md: "12", xl: "12" },
                                tituloSecundario: "Mantenimiento",
                                valor: "---",
                                iconProps: { className: "lnr-cog text-dark opacity-8" },
                                containerIconProps: { className: "icon-wrapper-bg opacity-10 bg-danger" },
                                subtitulos: [{ label: "Mantenimiento" }, { label: "Mantenimiento" }]
                            }
                            }
                            footer={{
                                button: {
                                    label: "Ver",
                                    conLink: {
                                        toPath: "/app/gestion/api581Valvulas/mantenimiento",
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <ValoresAgrupados
                            containerProps={{ style: { height: "100%" } }}
                            titulo="Analisis de Riesgo"
                            info={`${state.totalValoradaRiesgo || "-"} Valoradas`}
                            bodyProps={{ style: { height: "100%" } }}
                            body={[{
                                containerProps: { xs: "12", sm: "6", md: "6", xl: "6" },
                                tituloSecundario: "Valoradas",
                                valor: state.totalCualitativo,
                                iconProps: { className: "lnr-pie-chart text-dark opacity-8" },
                                subtitulos: { label: "Modelo de consecuencia Cualitativo" }
                            }, {
                                containerProps: { xs: "12", sm: "6", md: "6", xl: "6" }, //xs sm md lg xl
                                tituloSecundario: "Valoradas",
                                valor: state.totalSemicuantitativo,
                                iconProps: { className: "lnr-chart-bars text-dark" },
                                containerIconProps: { className: "icon-wrapper-bg opacity-10 bg-success" },
                                subtitulos: { label: "Modelo de consecuencia Semicuantitativo" }
                            }]
                            }
                            footer={{
                                button: {
                                    label: "Ver Reporte Completo",
                                    conLink: {
                                        toPath: "/app/analisis/riesgo/api581Valvulas",
                                    }
                                }
                            }}
                        />
                    </Grid>

                </Grid>
            </CardConEncabezado>
        </React.Fragment>
    )
}