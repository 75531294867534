const objectPath = require('object-path');

export const obtenerElementosQueCumplanConLasCondiciones = (nodos, condiciones) => {
  // console.log("todos los nodos: ", nodos);
  let keysDeCondiciones = Object.keys(condiciones);
  let condicionesComoArray = keysDeCondiciones.map((key, i) => { return condiciones[key]; });
  condicionesComoArray = condicionesComoArray.filter(condicion => condicion.activo);
  let nodosQueCumplenConLasCondiciones = [];

  if (nodos[0].resultados) {
    for (let i = 0; i < nodos.length; i++) {
      let cumpleTodasLasCondiciones = true;
      for (let k = 0; k < condicionesComoArray.length; k++) {
        if (!validarCondicion(nodos[i], condicionesComoArray[k])) {
          cumpleTodasLasCondiciones = false;
          break;
        }
      }
      if (cumpleTodasLasCondiciones) { nodosQueCumplenConLasCondiciones.push(nodos[i]) };
    }
  } else {
    for (let i = 0; i < nodos.length; i++) {
      let cumpleTodasLasCondiciones = true;
      for (let k = 0; k < condicionesComoArray.length; k++) {
        if (!validarCondicion(nodos[i], condicionesComoArray[k])) {
          cumpleTodasLasCondiciones = false;
          break;
        }
      }
      if (cumpleTodasLasCondiciones) { nodosQueCumplenConLasCondiciones.push(nodos[i]) };
    }
  }
  return nodosQueCumplenConLasCondiciones;
}

const validarCondicion = (nodo, condicion) => {
  console.log("nodo: ", nodo)
  console.log("condicion: ", condicion);
  if (nodo.resultados) {
    switch (condicion.comparador) {
      case "==":
        return (
          objectPath.get(nodo.resultados[0].data, condicion.key) === condicion.valorTarget
          ||
          objectPath.get(nodo.parametrosDeEntrada, condicion.key) === condicion.valorTarget
        );
      case "<=":
        return (
          objectPath.get(nodo.resultados[0].data, condicion.key) <= condicion.valorTarget
          ||
          objectPath.get(nodo.parametrosDeEntrada, condicion.key) <= condicion.valorTarget
        );
      case ">=":
        return (
          objectPath.get(nodo.resultados[0].data, condicion.key) >= condicion.valorTarget
          ||
          objectPath.get(nodo.parametrosDeEntrada, condicion.key) >= condicion.valorTarget
        );

      case "<":
        return (
          objectPath.get(nodo.resultados[0].data, condicion.key) < condicion.valorTarget
          ||
          objectPath.get(nodo.parametrosDeEntrada, condicion.key) < condicion.valorTarget
        );
      case ">":
        return (
          objectPath.get(nodo.resultados[0].data, condicion.key) > condicion.valorTarget
          ||
          objectPath.get(nodo.parametrosDeEntrada, condicion.key) > condicion.valorTarget
        );
      case "!=":
        return (
          objectPath.get(nodo.resultados[0].data, condicion.key) !== condicion.valorTarget
          ||
          objectPath.get(nodo.parametrosDeEntrada, condicion.key) !== condicion.valorTarget
        );
      default:
        return false;
    }
  } else {
    switch (condicion.comparador) {
      case "==":
        return (objectPath.get(nodo, condicion.key) === condicion.valorTarget);

      case "<=":
        return (objectPath.get(nodo, condicion.key) <= condicion.valorTarget);

      case ">=":
        return (objectPath.get(nodo, condicion.key) >= condicion.valorTarget);

      case "<":
        return (objectPath.get(nodo, condicion.key) < condicion.valorTarget);

      case ">":
        return (objectPath.get(nodo, condicion.key) > condicion.valorTarget);

      case "!=":
        return (objectPath.get(nodo, condicion.key) !== condicion.valorTarget);

      default:
        return false;
    }
  }


}