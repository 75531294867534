export const armarDataParaTablaActivos = ( nodos ) => {
    let activosApi510 = [];
    nodos.forEach(nodo => {
        try{
            activosApi510.push(
                {
                    'Nombre': {
                        label: 'Nombre', 
                        valor: `${nodo?.informacionGeneral?.nombre?.valor}/API510/${nodo?.id}`, 
                        maxWidth: 130, 
                        spanStyle: {
                            width: '100px'
                        },
                        condicion: 'link',
                        ruta: '/app/analisis/integridad/activo/api510' 
                    },
                    'Vida remanente Cap': {
                        label: 'Vida remanente mínima Cap (años)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'Vida remanente Cuerpo': {
                        label: 'Vida remanente mínima Cuerpo (años)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'Vida remanente Boquilla': {
                        label: 'Vida remanente mínima Boquilla (años)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'Velocidad de corrosion Cap': {
                        label: 'Velocidad de corrosión Cap (mpy)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'Velocidad de corrosion Cuerpo': {
                        label: 'Velocidad de corrosión Cuerpo (mpy)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'Velocidad de corrosion Boquilla': {
                        label: 'Velocidad de corrosión Boquilla (mpy)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'MAWP Cap': {
                        label: 'MAWP Cap (psi)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'MAWP Cuerpo': {
                        label: 'MAWP Cuerpo (psi)', 
                        valor: undefined || "aún sin calcular", 
                    },
                    'MAWP Boquilla': {
                        label: 'MAWP Boquilla (psi)', 
                        valor: undefined || "aún sin calcular", 
                    },
                }
            );
        }
        catch(error){
            console.log(error)
        }
    });

    return activosApi510
}
