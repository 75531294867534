//Utilidades de firebase:
import { firebaseFunctions } from '../../../../firebase';

//Microservicios firebase:
const crearUsuario = firebaseFunctions.httpsCallable('crearUsuario');
const crearUsuarioExterno = firebaseFunctions.httpsCallable('crearUsuarioExterno');
const eliminarUsuario = firebaseFunctions.httpsCallable('bu_eliminarUsuario');


/**
 * **Servicio**: Crear usuario
 * 
 * @param {Object} usuario - Nuevo usuario
 * @param {String} usuario.nombre - El nombre del usuario
 * @param {String} usuario.correo- El correo del usuario (debe ser único)
 * @param {String} usuario.dominioCorporativo - El dominio corporativo
 * @param {String} usuario.companyId - Identificador de la empresa
 * @param {String} usuario.rol - El rol del usuario Ej: supervisor, técnico...
 * 
 * @returns id del usuario registrado
 */
const servicioCrearUsuario = async (usuario, userValidation) => {
    try {
      /* ----------------- Obtener listado de imagenes en storage ----------------- */
      let response
      if (userValidation) response = await crearUsuarioExterno(usuario)
      else response = await crearUsuario(usuario)
  
      const idUsuario = response.data?.data?.idDocumento
      return idUsuario
  
    } catch (error) {
      throw error
    }
}

/**
 * **Servicio**: Crear usuario
 * 
 * @param {String} idUsuario - Identificador del usuario a eliminar
 * 
 * @returns id del usuario eliminado
 */
const servicioEliminarUsuario = async (idUsuario) => {
  try {
    /* ----------------- Obtener listado de imagenes en storage ----------------- */
    const response = await eliminarUsuario({idUsuario})

    const idDocumento = response.data?.data?.idDocumento
  
    return idDocumento

  } catch (error) {
    throw error
  }
}

export { servicioCrearUsuario, servicioEliminarUsuario };