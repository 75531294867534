import React from 'react';
import { Card, CardImg, CardTitle, Col, Button } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';


import { RiCloseCircleLine } from "react-icons/ri";

import 'react-toastify/scss/main.scss';

//Utilidades de firebase:
import { firebaseStorage, firestoreDB } from "../../../../../firebase";
import CustomModalImage from '../../../../../utilidades/componentes/plantillaBase/CustomModalImage';

import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';


const addImg = require('../../../../../assets/utils/images/utils/addImg.png');

const firebase = require('firebase/app');
require('firebase/firestore');
const pathReference = firebaseStorage.ref();

class RegistroFotografico extends React.Component {

    constructor(props) {
        super(props);
        this.isMonted = false;
        this.state = {
            elModalDeDetalleEstaAbierto: false,
            url: undefined,
            imagenEnElModal: undefined,
            name: undefined,
            cargando: false,
        }
    }
    
    componentDidMount() {
        this.isMonted = true;
        if (this.props.url) {
            this.setState({ url: this.props.url })
        }
        else if (this.props.path) {
            this.obtenerImagen(this.props.path);
        }
    }

    componentWillUnmount() { 
        this.isMonted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.path !== prevProps.path) {
            this.obtenerImagen(this.props.path);
        }
    }

    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    cargandoTrue = () => {
        this.setState({ cargando: true })
    }
    cargandoFalse = () => {
        this.setState({ cargando: false })
    }

    elimiminarImage = (props) => {
        let array = this.props.array ? this.props.array : []
        let imgRef = firebaseStorage.ref(this.props.path);
        if (array.length > 0) { array.splice(this.props.index, 1) }
        this.referenciaFirebase(props, array)
            .then(() => {
                return imgRef.delete()
            }
            )
            .then(() => { 
                this.avisoDeGuardadoSatisfactorio(`Se eliminó con exito la imagen ${this.props.name}`)
            })
            .catch()
    }

    referenciaFirebase = (props, array) => {
        if (props.imagen.includes('Galeria')) {
            return firestoreDB.collection('ordenesDeTrabajo')
                .doc(props.idOrdenDeTrabajo)
                .collection('registrosDeInspeccion')
                .doc('registroFotografico')
                .set({detalle: array})
        }
        else if (props.imagen.includes('Camisas')) {
            return firestoreDB.collection('ordenesDeTrabajo')
                .doc(props.idOrdenDeTrabajo)
                .collection('anomalias')
                .doc(props.idDeAnomalia)
                .update(`detalle.${props.imagen}`, firebase.firestore.FieldValue.delete())
        }
        else if (props.ensayo) {
            return firestoreDB.collection('ordenesDeTrabajo')
                .doc(props.idOrdenDeTrabajo)
                .collection('registrosDeInspeccion')
                .doc('anexos')
                .update(`ensayosNoDestructivos.${props.tipo}.${props.imagen}`, firebase.firestore.FieldValue.delete())
        }
    }


    actualizarURL=(urlNueva, name)=>{
        if (urlNueva) {
            this.setState({
                url: urlNueva,
                name: name,
            })
        }
    }
    
    render() {
        if (this.state.url && !this.props.addImg) {
            if(this.props.ensayo){
                return(
                    <React.Fragment>
                        <CustomModalImage
                            elModalEstaAbierto={this.state.elModalDeDetalleEstaAbierto}
                            children={this.state.imagenEnElModal}
                            toggleDelModal={this.toggleDelVisualizadorDeDetalle}
                            name={this.props.name}
                            authRol={this.props.authRol}
                            ensayo={this.props.ensayo}
                            imagen={this.props.imagen}
                            avisoDeGuardadoSatisfactorio={(e) => this.avisoDeGuardadoSatisfactorio(e)}
                            avisoDeGuardadoFallido={(e) => this.avisoDeGuardadoFallido(e)}
                            actualizarURL={(e) => this.actualizarURL(e)}
                        />
                            <div style={{ position: 'relative',}}>
                            <div
                                className='div-delete-img'
                                style={{ position: 'absolute', zIndex: 10, width: '100%', }}>
                                {this.props.authRol ? <Button
                                    color="link"
                                    outline
                                    style={{ color: '#b2b2b2' }}
                                    size="lg"
                                    onClick={() => { this.elimiminarImage(this.props) }}>
                                    <RiCloseCircleLine
                                        size={24}
                                    />
                                </Button> : null}
                            </div>
                                <div>
                                    <Card className={"bg-royal"} style={{ margin: 5, cursor: 'pointer', height: 280 }} onClick={this.toggleDelVisualizadorDeDetalle}>
                                        <CardImg top width="100%" style={{ height: 280 }} src={this.state.url} alt={this.state.url} />
                                    </Card>
                                </div>
                            </div>
                            
                    </React.Fragment>
                )
            }
            else{
                return (
                    <React.Fragment>
                        <CustomModalImage
                            array={this.props.array}
                            index={this.props.index}
                            elModalEstaAbierto={this.state.elModalDeDetalleEstaAbierto}
                            children={this.state.imagenEnElModal}
                            toggleDelModal={this.toggleDelVisualizadorDeDetalle}
                            idOrdenDeTrabajo={this.props.idOrdenDeTrabajo}
                            idDeAnomalia={this.props.idDeAnomalia}
                            authRol={this.props.authRol}
                            name={this.props.name}
                            path={this.props.path}
                            imagen={this.props.imagen}
                            cargandoTrue={() => this.cargandoTrue()}
                            cargandoFalse={() => this.cargandoFalse()}
                            avisoDeGuardadoSatisfactorio={(e) => this.avisoDeGuardadoSatisfactorio(e)}
                            avisoDeGuardadoFallido={(e) => this.avisoDeGuardadoFallido(e)}
                            actualizarURL={(e) => this.actualizarURL(e)}
                        />
                        <Col xs='12' md='6' lg='4'>
                            <div style={{ position: 'relative', }}>
                                <div
                                    className='div-delete-img'
                                    style={{ position: 'absolute', zIndex: 10, width: '100%', }}>
                                    {this.props.authRol ? <Button
                                        color="link"
                                        outline
                                        style={{ color: '#b2b2b2' }}
                                        size="lg"
                                        onClick={() => { this.elimiminarImage(this.props) }}>
                                        <RiCloseCircleLine
                                            size={24}
                                        />
                                    </Button> : null}
                                </div>
                                <div>
                                    <Card className={"bg-royal"} style={{ margin: 5, cursor: 'pointer', height: 320 }} onClick={this.toggleDelVisualizadorDeDetalle}>
                                        <CardImg top width="100%" style={{ height: 280 }} src={this.state.url} alt={this.state.url} />

                                        <CardTitle className={"text-white pt-2 text-center"} style={{ textTransform: "none" }}>
                                            {this.state.name ? this.state.name : this.props.name}
                                        </CardTitle>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        <ToastContainer />
                        <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                    </React.Fragment>
                );
            }
        }
        else if (this.props.addImg) {
            return (
                <React.Fragment>
                    <CustomModalImage
                        array={this.props.array}
                        index={this.props.index}
                        elModalEstaAbierto={this.state.elModalDeDetalleEstaAbierto}
                        children={this.state.imagenEnElModal}
                        toggleDelModal={this.toggleDelVisualizadorDeDetalle}
                        idOrdenDeTrabajo={this.props.idOrdenDeTrabajo}
                        idDeAnomalia={this.props.idDeAnomalia}
                        addImg={this.props.addImg}
                        name={this.props.name}
                        path={this.props.path}
                        imagen={this.props.imagen}
                        cargandoTrue={() => this.cargandoTrue()}
                        cargandoFalse={() => this.cargandoFalse()}
                        avisoDeGuardadoSatisfactorio={(e) => this.avisoDeGuardadoSatisfactorio(e)}
                        avisoDeGuardadoFallido={(e) => this.avisoDeGuardadoFallido(e)}
                        actualizarURL={(e) => this.actualizarURL(e)}
                    />
                    <Col xs='12' md='6' lg='4' style={{alignContent:'center'}}>

                        <Card className={"bg-transparent"} style={{ margin: 5, cursor: 'pointer', height: 320, justifyContent:'center', alignItems:'center' }} onClick={this.toggleDelVisualizadorDeDetalle}>
                            <CardImg top style={{ height: 80, width:80, resizeMode: 'contain' }} src={addImg} alt={addImg} />
                            <CardTitle className={"text-black-50 pt-2 text-center"} style={{ textTransform: "none" }}>
                                Agregar nueva imagen a la galeria
                            </CardTitle>
                        </Card>
                    </Col>
                    <ToastContainer />
                    <Preloader elPreloaderEstaAbierto={this.state.cargando} />
                </React.Fragment>
            );
        }
        else {
            return (<React.Fragment />);
        }
    }

    obtenerImagen = (path) => {
        pathReference.child(path).getDownloadURL()
            .then((url) => {
                if (this.isMonted) this.setState({ url: url })
            })
            .catch(error => {
                this.setState({ url: undefined })
                console.log('Error', error);
            });
    }

    toggleDelVisualizadorDeDetalle = (url) => {
        this.setState({ elModalDeDetalleEstaAbierto: !this.state.elModalDeDetalleEstaAbierto, imagenEnElModal: this.state.url  });
    }
}

export default RegistroFotografico;