const rolesDeUsuarios = {
    'admin': {
        'titulo': 'Administrador',
        'color': '#E74C3C'
    },
    'tecnico':{
        'titulo': 'Técnico',
        'color': '#1ABC9C'
    },
    'supervisor': {
        'titulo': 'Supervisor',
        'color': '#1ABC9C'
    },
    'cliente': {
        'titulo': 'Usuario',
        'color': '#1ABC9C'
    }
}

export default rolesDeUsuarios;