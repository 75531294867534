export const tablaIdentificacion = [
    {
        nombre: {
            label: 'Nombre, ', 
            valor:  'TAG',
        },
        valor: {
            label: 'Valor', 
            valor:  'D3681'
        },
    },
    {
        nombre: {
            label: 'Nombre, ', 
            valor:  'Servicio'
        },
        valor: {
            label: 'Valor', 
            valor:  'TAMBOR DE TEA'
        },
    },
    {
        nombre: {
            label: 'Nombre, ', 
            valor:  'Fecha'
        },
        valor: {
            label: 'Valor', 
            valor:  new Date()
        },
    },
    {
        nombre: {
            label: 'Nombre, ', 
            valor:  'Elaboró',
        },
        valor: {
            label: 'Valor', 
            valor:  'Jhon Doe'
        },
    },
    {
        nombre: {
            label: 'Nombre, ', 
            valor:  'Referencia',
        },
        valor: {
            label: 'Valor', 
            valor:  '123456789'
        },
    },
    
]