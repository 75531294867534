import React from 'react';

import { Row, Col } from 'reactstrap';
import { FaExpand } from 'react-icons/fa';
import { GiSoundWaves } from "react-icons/gi";

//Gráficas
import GraficoCartesianoCompuestoIntegridad from '../../../../../utilidades/componentes/visualizadores/GraficoCartesianoCompuestoIntegridad';

//Componentes:
import TablaReporteOndasGuiadas from '../componentes/TablaReporteOndasGuiadas';
import GraficaTablasUtScan from '../componentes/GraficaTablasUtScan';

const VisualizadorLrecLh5ALh1002 = (props) => {
   
    return (
        <React.Fragment>
            <Row>
                {   
                    props.dataGwt && props.dataGwt.length > 0 &&
                    <Col xs='12' md='12' lg='12'>
                        <TablaReporteOndasGuiadas
                            icono={GiSoundWaves}
                            bgColor={props.bgColor}
                            datosDeTabla={props.dataGwt}
                        />
                    </Col>
                }
                {
                    props.dataUtScanA && props.dataUtScanA.length > 0 &&
                    <Col xs='12' md='12' lg='12'>
                        <GraficaTablasUtScan
                            keyTabla={'utScanA'}
                            titulo={'UT SCAN A'}
                            icono={FaExpand}
                            bgColor={props.bgColor}
                            datosDeTabla={props.dataUtScanA}
                            nodosCml={props.nodosCml}    
                        />
                    </Col>
                }
                <br/>
                <br/>
                <br/>
                {
                    props.dataUtScanC && props.dataUtScanC.length > 0 &&
                    <Col xs='12' md='12' lg='12'>
                        <GraficaTablasUtScan
                            keyTabla={'utScanC'}
                            titulo={'UT SCAN C'}
                            icono={FaExpand}
                            bgColor={props.bgColor}
                            datosDeTabla={props.dataUtScanC}
                            nodosCml={props.nodosCml}    
                        />
                    </Col>
                }
                {
                    props.dataPerfilEspesores && props.dataPerfilEspesores.length > 0 &&
                    <Col xs='12' md='12' lg='12'>
                        <GraficoCartesianoCompuestoIntegridad
                        titulo='Perfil de espesores' 
                        nombreColumnaIzquierda='Presión (psi)'
                        nombreColumnaDerecha='Espesor (in)'
                        datosParaGrafica={props.dataPerfilEspesores}
                        />
                    </Col>
                }
                <br/>
            </Row>                
        </React.Fragment>
    )
}

export default (VisualizadorLrecLh5ALh1002);
