const geolib = require('geolib');

const armarCoordenadasParaMapaDeActivos = (todosLosSegmentos) => {
    try{
        let coordenadas = [];
        todosLosSegmentos.forEach(item => {
        let coorInicio = item?.variablesDeLaInspeccion?.ubicacion?.inicio?.coordenada? item?.variablesDeLaInspeccion?.ubicacion?.inicio?.coordenada.toString().trim() : undefined;
        let coorFinal = item?.variablesDeLaInspeccion?.ubicacion?.fin?.coordenada? item?.variablesDeLaInspeccion?.ubicacion?.fin?.coordenada.toString().trim() : undefined;
        if (coorInicio !== undefined && coorFinal !== undefined) {
            coordenadas.push([
                {'lng': -geolib.toDecimal(coorInicio.split(',')[1]), 'lat': geolib.toDecimal(coorInicio.split(',')[0]) },
                {'lng': -geolib.toDecimal(coorFinal.split(',')[1]), 'lat': geolib.toDecimal(coorFinal.split(',')[0]) }
            ]);
        } 
        })
        return coordenadas;
    }
    catch(error){console.log(error)}
}

export default armarCoordenadasParaMapaDeActivos