import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Utilidades de firebase:
import * as firebase from 'firebase/app';
import 'firebase/auth';

//Componentes auxiliares
import PanelDeUsuario from './PanelDeUsuario';

//Utilidades de redux:
import { deslogearUsuario } from '../../../storage/reducerParaUsuario.js';

import { firebaseFunctions } from '../../../firebase';

const validarUsuario = firebaseFunctions.httpsCallable('validarUsuario');

let MenuDeUsuario = (props) => {
    return (
        <React.Fragment>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        {/* <div className="widget-content-left mr-3 header-user-info"
                            style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div className="widget-heading" style={{ flex: "75%" }}>
                                {props.usuarioActivo.displayName}
                            </div>

                            <div className="widget-heading" style={{ flex: "25%", opacity: "0.5", fontSize: "0.5rem" }}>
                                {props.usuarioActivo.email.split("@")[1]}
                            </div>
                        </div> */}
                        <PanelDeUsuario/>
                        {/* <div className="widget-content-left">
                            <img className="rounded-circle" alt="userProfilePicture"
                                width={42} src={props.usuarioActivo.photoURL || defaultAvatar} />
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="header-btn-lg pr-0"
                style={{ display: "flex", justifyContent: "center", minWidth: 30 }}>
                <FiLogOut className="widget-heading pusheable-button"
                    style={{ strokeWidth: 3.5 }} onClick={(e) => { cerrarSesionActiva(props); }} />
            </div>
        </React.Fragment>
    )
}


const cerrarSesionActiva = (props) => {
    validarUsuario({
        "email": props.usuarioActivo.email,
        "token": props.usuarioActivo.token,
        "isLogin": false
    })
    .then(response =>{
        firebase.auth().signOut()
            .then(() => {
                props.deslogearUsuario();
                props.history.push("/login");
                localStorage.removeItem('ultimaRutaVisitada');
            })
            .catch(err => { console.log(err) });
    })

        .catch(error =>{console.log(error)})
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

const mapDispatchToProps = dispatch => ({
    deslogearUsuario: () => dispatch(deslogearUsuario()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuDeUsuario));
