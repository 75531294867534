import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Vistas de la capa de autenticación:
import Login from './componentes/Login.jsx';

let CapaDeAutenticacion = (props) => {
    return (
        <div className="app-container">
            <Switch>
                <Route path={"/login"} key={"/login"} component={Login} />
            </Switch>
        </div>
    );
}

export default CapaDeAutenticacion;