export const validarDatosDeInspeccion = (matrizDeDatos, dominioCorporativo, logDelProceso) => {
    let nuevaMatriz = [];
    for (let i = 6; i < matrizDeDatos.length; i++) {
        // let existen = true;
        let datosGenerales = {};
        let indicesNovedad = {};
        let nombreResponsable = "";
        let correoResponsable = "";
        let idOdt = "";
        datosGenerales["dominioCorporativo"] = dominioCorporativo;
        matrizDeDatos[0].forEach((nombreVariable, index) => {
            if (index >= 1 && index <= 15 && nombreVariable) {
                idOdt = matrizDeDatos[i][1];

                // if (!matrizDeDatos[i][index]) existen = false;
                if (nombreVariable === "correo") {
                    // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;                       
                    correoResponsable = matrizDeDatos[i][index];

                } else if (nombreVariable === "nombre") {
                    // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;
                    nombreResponsable = matrizDeDatos[i][index];
                } else if (nombreVariable === "fechaEstimadaDeProgramacion") {
                    try {
                        return datosGenerales[nombreVariable] = matrizDeDatos[i][index] ? new Date(new Date(matrizDeDatos[i][index]).getTime() + new Date(matrizDeDatos[i][index]).getTimezoneOffset() * 60 * 1000).toString() : null;
                    } catch (e) {
                        // existen = false;
                    }
                }
                else if (nombreVariable === "fechaDeActualizacion") {
                    try {
                        return datosGenerales[nombreVariable] = matrizDeDatos[i][index] ? new Date(new Date(matrizDeDatos[i][index]).getTime() + new Date(matrizDeDatos[i][index]).getTimezoneOffset() * 60 * 1000).toString() : null;
                    } catch (e) {
                        // existen = false;
                    }
                }
                else {
                    // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;
                    return datosGenerales[nombreVariable] = matrizDeDatos[i][index];
                }
            }
        });

        datosGenerales["responsable"] = { "nombre": nombreResponsable, "correo": correoResponsable }
        matrizDeDatos[1].forEach((nombreVariable, index) => {
            if (index >= 16 && index <= 25 && nombreVariable) {
                // if (!matrizDeDatos[i][index]) existen = false;
                // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;

                return indicesNovedad[nombreVariable] = matrizDeDatos[i][index];
            }
        });
        // if (existen) {
        nuevaMatriz.push({ ...datosGenerales, novedad: indicesNovedad })
        logDelProceso("La ODT <b>" + idOdt + "</b> está lista para cargarse ", "success");
        // } else {
        //     logDelProceso("La ODT <b>" + idOdt + "</b> no cumple las condiciones para cargarse", "danger");
        // }
    }
    return nuevaMatriz;
}

export const validarDatosDeInspeccionODTHija = (matrizDeDatos, dominioCorporativo, logDelProceso) => {
    let nuevaMatriz = [];
    for (let i = 6; i < matrizDeDatos.length; i++) {
        // let existen = true;
        let datosGenerales = {};
        let nombreTecnico = "";
        let correoTecnico = "";
        let idOdt = "";
        datosGenerales["dominioCorporativo"] = dominioCorporativo;
        matrizDeDatos[0].forEach((nombreVariable, index) => {
            if (index >= 1 && index <= 10 && nombreVariable) {
                idOdt = matrizDeDatos[i][2];

                // if (!matrizDeDatos[i][index]) existen = false;
                if (nombreVariable === "correo") {
                    // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;                       
                    correoTecnico = matrizDeDatos[i][index];

                } else if (nombreVariable === "nombre") {
                    // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;
                    nombreTecnico = matrizDeDatos[i][index];
                } else if (nombreVariable === "fechaEstimadaDeProgramacion") {
                    try {
                        return datosGenerales[nombreVariable] = matrizDeDatos[i][index] ? new Date(new Date(matrizDeDatos[i][index]).getTime() + new Date(matrizDeDatos[i][index]).getTimezoneOffset() * 60 * 1000).toString() : null;
                    } catch (e) {
                        // existen = false;
                    }
                }
                else if (nombreVariable === "fechaDeActualizacion") {
                    try {
                        return datosGenerales[nombreVariable] = matrizDeDatos[i][index] ? new Date(new Date(matrizDeDatos[i][index]).getTime() + new Date(matrizDeDatos[i][index]).getTimezoneOffset() * 60 * 1000).toString() : null;
                    } catch (e) {
                        // existen = false;
                    }
                }
                else {
                    // if(tipoDeDatos[nombreVariable] && tipoDeDatos[nombreVariable]  !== typeof(matrizDeDatos[i][index])) existen= false;
                    return datosGenerales[nombreVariable] = matrizDeDatos[i][index];
                }
            }
        });

        datosGenerales["tecnico"] = { "nombre": nombreTecnico, "correo": correoTecnico }
        // if (existen) {
        nuevaMatriz.push({ ...datosGenerales})
        logDelProceso("La ODT Hija <b>" + idOdt + "</b> está lista para cargarse ", "success");
        // } else {
        //     logDelProceso("La ODT <b>" + idOdt + "</b> no cumple las condiciones para cargarse", "danger");
        // }
    }
    return nuevaMatriz;
}
