import {MODELO_CONSECUENCIA, VSP} from './constantes';

function validarEventosSobrePresion(lista){
    const validacion = lista.find(item=>item.valor !== '-');
    if(validacion) return true;
    return false;
}

export default function validarModelos(lista, modelo){
    let nuevaLista = [];
    let evsp = [];
    let result = {};

    if(lista && modelo){
        nuevaLista.push(...lista.filter(item=> item.modelo === modelo));
        nuevaLista.push(...lista.filter(item=> item.modelo === MODELO_CONSECUENCIA.SyC ));
        evsp.push(...lista.filter(item => item.evento === VSP));
        nuevaLista.push(...evsp);
        result = {
            'EVSP': validarEventosSobrePresion(evsp),
            'lista': nuevaLista
        }
    }

    return result;
}