import { firebaseFunctions } from '../../../../../../firebase.js';
const obtenerListaDeOpciones = firebaseFunctions.httpsCallable('obtenerListaDeOpciones');

//Funciones auxiliares:
const obtenerFiltrosComoLista = (objeto) => {
    let listaTarget = [];
    let keys = Object.keys(objeto);

    keys.forEach((key, i) => { listaTarget.push({ ...objeto[key], key: key }); });
    return listaTarget;
}

export const categorias = [
    {
        tipo: "CONSECUENCIA",
        keyDelNodo: "resultadosDeLaValoracion.consecuencia.consecuenciaFinanciera",
        value: "consecuencia",
        label: "Consecuencia",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos con <b>consencuencia</b> igual a <b>" + valorTarget + " $</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>consencuencia</b> mayor o igual a <b>" + valorTarget + " $</b>";
                case "Mayor":
                    return "Todos los elementos con <b>consencuencia</b> mayor a <b>" + valorTarget + " $</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>consencuencia</b> menor o igual a <b>" + valorTarget + " $</b>";
                case "Menor":
                    return "Todos los elementos con <b>consencuencia</b> menor a <b>" + valorTarget + " $</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "CONSECUENCIA_CUALITATIVA",
        keyDelNodo: "resultadosDeLaValoracion.consecuencia.consecuenciaFinancieraCualitativa",
        value: "consecuenciaCualitativa",
        label: "Consecuencia cualitativa",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos con <b>consencuencia cualitativa</b> igual a <b>" + valorTarget + "</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>consencuencia cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                case "Mayor":
                    return "Todos los elementos con <b>consencuencia cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>consencuencia cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                case "Menor":
                    return "Todos los elementos con <b>consencuencia cualitativa</b> menor a <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "RIESGO",
        keyDelNodo: "resultadosDeLaValoracion.riesgo.riesgoFinanciero",
        value: "riesgo",
        label: "Riesgo",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "!=", label: "Diferente" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos con <b>riesgo</b> igual a <b>" + valorTarget + "</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>riesgo</b> mayor o igual a <b>" + valorTarget + "</b>";
                case "Mayor":
                    return "Todos los elementos con <b>riesgo</b> mayor a <b>" + valorTarget + "</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>riesgo</b> menor o igual a <b>" + valorTarget + "</b>";
                case "Menor":
                    return "Todos los elementos con <b>riesgo</b> menor a <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "NIVEL_DE_RIESGO",
        keyDelNodo: "resultadosDeLaValoracion.riesgo.nivelDeRiesgo",
        value: "nivelDeRiesgo",
        label: "Nivel de riesgo",
        valorDefault: { value: 'L' },
        tipoDeCategoria: "valoresContinuos",
        esTexto: true,
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos con <b>riesgo cualitativo</b> igual a <b>" + valorTarget + "</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>riesgo cualitativo</b> mayor o igual a <b>" + valorTarget + "</b>";
                case "Mayor":
                    return "Todos los elementos con <b>riesgo cualitativo</b> mayor a <b>" + valorTarget + "</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>riesgo cualitativo</b> menor o igual a <b>" + valorTarget + "</b>";
                case "Menor":
                    return "Todos los elementos con <b>riesgo cualitativo</b> menor a <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "PROBABILIDAD",
        keyDelNodo: "resultadosDeLaValoracion.probabilidad.probabilidad",
        value: "probabilidad",
        label: "Probabilidad",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos con <b>probabilidad</b> igual a " + valorTarget + "</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>probabilidad</b> mayor o igual a " + valorTarget + "</b>";
                case "Mayor":
                    return "Todos los elementos con <b>probabilidad</b> mayor a " + valorTarget + "</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>probabilidad</b> menor o igual a " + valorTarget + "</b>";
                case "Menor":
                    return "Todos los elementos con <b>probabilidad</b> menor a " + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "PROBABILIDAD_CUALITATIVA",
        keyDelNodo: "resultadosDeLaValoracion.probabilidad.probabilidadCualitativa",
        value: "probabilidadCualitativa",
        label: "Probabilidad Cualitativa",
        valorDefault: { value: 0 },
        tipoDeCategoria: "valoresContinuos",
        comparadoresPermitidos: [
            { value: ">", label: "Mayor" },
            { value: ">=", label: "Mayor o igual" },
            { value: "==", label: "Igual" },
            { value: "<=", label: "Menor o igual" },
            { value: "<", label: "Menor" }
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos con <b>probabilidad cualitativa</b> igual a <b>" + valorTarget + "</b>";
                case "Mayor o igual":
                    return "Todos los elementos con <b>probabilidad cualitativa</b> mayor o igual a <b>" + valorTarget + "</b>";
                case "Mayor":
                    return "Todos los elementos con <b>probabilidad cualitativa</b> mayor a <b>" + valorTarget + "</b>";
                case "Menor o igual":
                    return "Todos los elementos con <b>probabilidad cualitativa</b> menor o igual a <b>" + valorTarget + "</b>";
                case "Menor":
                    return "Todos los elementos con <b>probabilidad cualitativa</b> menor a <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "TIPO_DE_ACTIVO",
        keyDelNodo: "resultadosDeLaValoracion.planesDeAccion.informacionAdicional.tipoDeActivo",
        value: "tipoDeActivo",
        label: "Tipo de activo",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "Gasoducto",
                label: "Gasoducto"
            },
            {
                value: "Líneas de producción",
                label: "Líneas de producción"
            }
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los <b>activos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
    {
        tipo: "SISTEMA",
        keyDelNodo: "informacionDeLosPadres.sistemaAsociado.nombre",
        valorDefault: { value: "" },
        value: "sistema",
        label: "Sistema al que pertenece",
        placeholder: "Cargando sistemas",
        tipoDeCategoria: "opcionesAsincronas",
        comparadoresPermitidos: [{ value: "==", label: "Igual" },],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los elementos <b>que pertenecen</b> al sistema <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        },
        obtenerOpcionesDesdeCF: (props, otrosFiltrosActivos) => {
            let listaDePrecondiciones = [];
            let filtrosComoLista = obtenerFiltrosComoLista(otrosFiltrosActivos);
            let filtroDelCampoPadre = filtrosComoLista.find(item => item.tipo === "CAMPO" && item.activo);

            if (filtroDelCampoPadre !== undefined) {
                listaDePrecondiciones.push({
                    "key": "informacionDeLosPadres.campoAsociado.nombre",
                    "comparador": "==",
                    "valorTarget": filtroDelCampoPadre.valorTarget
                })
            }

            return obtenerListaDeOpciones({
                "coleccion": "sistemas",
                "dominioCorporativo": props.dominioCorporativo,
                "keyDeAgrupamiento": "informacionGeneral.nombre",

                "precondicionesDeFiltrado": listaDePrecondiciones
            });
        }
    },
    {
        tipo: "TIPO_DE_COMPONENTE",
        keyDelNodo: "informacionGeneral.tipoDeComponente.valor",
        value: "tipoDeComponente",
        label: "Tipo de componente",
        valorDefault: { value: "" },
        tipoDeCategoria: "opcionesDiscretas",
        opcionesDeLaCategoria: [
            {
                value: "PIPE-1",
                label: "PIPE-1"
            },
            {
                value: "PIPE-2",
                label: "PIPE-2"
            },
            {
                value: "PIPE-4",
                label: "PIPE-4"
            },
            {
                value: "PIPE-6",
                label: "PIPE-6"
            },
            {
                value: "PIPE-8",
                label: "PIPE-8"
            },
            {
                value: "PIPE-10",
                label: "PIPE-10"
            },
            {
                value: "PIPE-12",
                label: "PIPE-12"
            },
            {
                value: "PIPE-16",
                label: "PIPE-16"
            },
            {
                value: "PIPEGT-16",
                label: "PIPEGT-16"
            }
        ],
        comparadoresPermitidos: [
            { value: "==", label: "Igual" },
        ],
        descripcion: (comparadorActivo, valorTarget) => {
            switch (comparadorActivo) {
                case "Igual":
                    return "Todos los <b>segmentos</b> de tipo <b>" + valorTarget + "</b>";
                default:
                    return "Al parecer, estas condiciones no se pueden interpretar...";
            }
        }
    },
]