import { get } from "lodash-es"

export const encapsularObjetoEnArray = (objeto) => {
    if (Array.isArray(objeto)) {
        return objeto
    }
    let objetoEnArray = [objeto]
    return objetoEnArray
}

export const obtenerTablaDePlanesDeAccion = (listaDeSegmentos) => {
    let listaDeFilas = [];
    for (let k = 0; k < listaDeSegmentos.length; k++) {
        let listaDeCategorias = [];
        listaDeCategorias = Object.keys(get(listaDeSegmentos,`[${k}].resultadosDeLaValoracion.planesDeAccion`,{}));
        for (let j = 0; j < listaDeCategorias.length; j++) {
            if (listaDeCategorias[j] !== 'informacionAdicional') {
                let listaDeAccionesPorCategoria = Object.keys(listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion[listaDeCategorias[j]]);
                for (let i = 0; i < listaDeAccionesPorCategoria.length; i++) {
                    listaDeFilas.push({
                        id: listaDeSegmentos[k].id,
                        nombreDelCampo: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].nombreDelCampo,
                        nombreDelSistema: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].nombreDelSistema,
                        nombreDelActivo: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].nombreDelActivo,
                        tipoDeActivo: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].tipoDeActivo,
                        nombreDelComponente: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].nombreDelComponente,
                        tipoDeComponente: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].tipoDeComponente,
                        categoria: listaDeCategorias[j],
                        tipoEvaluacion: listaDeSegmentos[k].modulos,
                        accion: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion[listaDeCategorias[j]][listaDeAccionesPorCategoria[i]].accion,
                        alcance: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion[listaDeCategorias[j]][listaDeAccionesPorCategoria[i]].alcance,
                        frecuencia: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion[listaDeCategorias[j]][listaDeAccionesPorCategoria[i]].frecuencia,
                        estado: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion[listaDeCategorias[j]][listaDeAccionesPorCategoria[i]].estado,
                        tieneInspeccion: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].tieneInspeccion,
                        dfPred: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].dfPred,
                        nivelDeRiesgo: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].nivelDeRiesgo,
                        probabilidadCualitativa: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].probabilidad,
                        consecuenciaCualitativa: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].consecuencia,
                        ubicacionDeCMLscanA: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].ubicacionDeCMLscanA,
                        ubicacionDeCMLscanBC: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].ubicacionDeCMLscanBC,
                        amenazaPred: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].amenazaPred,
                        consecuencia: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].consecuencia,
                        probabilidad: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].probabilidad,
                        condicionEspecifica: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].condicionEspecifica,
                        idDelComponente: listaDeSegmentos[k].resultadosDeLaValoracion.planesDeAccion['informacionAdicional'].idDelComponente,
                        keyDeLaAccion: i,
                    });
                }
            }
        }
    }

    return listaDeFilas;
}

export const limpiarNodos = (listaDeNodos) => {
    return listaDeNodos.map((nodo, i) => {
        try {
            return {
                "id": nodo["id"],
                "resultadosDeLaValoracion": {
                    "planesDeAccion": nodo["resultadosDeLaValoracion"]["planesDeAccion"],
                    "nivelDeRiesgo": nodo["resultadosDeLaValoracion"]["riesgo"]["nivelDeRiesgo"]
                }
            }
        }
        catch (error) {
            return {
                "id": nodo["id"],
                "resultadosDeLaValoracion": {
                    "planesDeAccion": [],
                }
            }
        }
    });
}

export const organizarDatosParaActualizarTabla = (tablaConFiltro) => {
    let tabla = tablaConFiltro;
    let cambios = {};

    for (let k = 0; k < tabla.length; k++) {
        if (!cambios[tabla[k]['idDelComponente']]) {
            cambios[tabla[k]['idDelComponente']] = {}
        }
        if (!cambios[tabla[k]['idDelComponente']][tabla[k]['categoria']]) {
            cambios[tabla[k]['idDelComponente']][tabla[k]['categoria']] = {}
        }
        if (!cambios[tabla[k]['idDelComponente']][tabla[k]['categoria']][tabla[k]['keyDeLaAccion']]) {
            cambios[tabla[k]['idDelComponente']][tabla[k]['categoria']][tabla[k]['keyDeLaAccion']] = tabla[k].estado;
        }
    }
    return cambios;
}