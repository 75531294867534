// El activador del modal de este componente está en el state del padre...
import React from 'react';
import { connect } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import { Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//Componentes auxiliares:
import CardDeResumen from './CardDeResumen.jsx';
import CustomModal from '../plantillaBase/CustomModal.jsx';
import TimelineDeProceso from './TimelineDeProceso.jsx';

//Utilidades de almacén y scripts:
import { plantillasDeCargue } from '../../almacen/plantillasDeCargue.js';
import { reemplazarTildes } from '../../scripts/paraTexto.js';
import { recorrerMatrizDeDatosIntegridad } from '../../scripts/recorrerMatrizDeDatosIntegridad.js';

//Utilidades para cargar datos de 580 - 581:
import { cargarMatrizDeDatosEnFirestore } from '../../scripts/paraCargueMasivo';
import { cargarDatosDePRD } from '../../scripts/paraCargueMasivoPRD';
import { cargarDatosDeIntegridad } from '../../scripts/paraCargueMasivoIntegridad';

// import {ejemploDeMatrizDeCargue} from '../estaticos/ejemploDeMatrizDeCargue.js';
import isDate from 'lodash/isDate';
import isNaN from "lodash/isNaN"

const { glosarioDeVariablesPRD: glosario } = require('../../scripts/glosarioDeVariablesPRD');

// Copy with lodash in cargue
const clonedeepPRDenCargue = require('lodash/cloneDeep');

//Constantes auxiliares:
const timeoutDeLecturaDelFichero = 2000;
const itemDeInicioDelLog = {
    status: "info",
    fecha: new Date(),
    contenido: <div className="widget-subheading" style={{ textAlign: "left", margin: 0 }}>Esperando a que comience el proceso...</div>
}
const obtenerItemDeFinalizacionExitosa = (segundos) => {
    return (
        <div className="widget-subheading" style={{ textAlign: "left", margin: 0 }}>{"El proceso terminó con éxito. Tardó " + segundos + " segundos"}</div>
    );
}
const defaultSubtitulo= "<span style='opacity:0.6;'>Selecciona un archivo para comenzar</span>"
class ConsolaDeCargue extends React.Component {

    constructor() {
        super();

        this.state = {
            puedoCerrarElModal: true,

            tipoDeActivos: "", //líneas, vasijas, válvulas, etc.
            estadoDelProceso: "inicio", //inicio; leyendoFichero; errorLeyendoElFichero; ficheroListo; cargandoData; procesoFinalizado
            normaTecnicaAsociada: "580",
            ficheroSeleccionado: { path: "miExcel.xlsx" },

            subtitulo: defaultSubtitulo,

            matrizDeDatos: [],
            datosPRD: [],
            
            matrizDeDatosIntegridad: [],
            logDeProceso: [itemDeInicioDelLog],

            tamanhoBatchIntegridad: 2000,
            calculoPorBatch: false,
        }
    }

    render() {
        return (
            <CustomModal size="lg" elModalEstaAbierto={this.props.elModalEstaAbierto}
                toggleDelModal={this.toggleDelModal} titulo={<span>Herramienta para <b>cargue masivo</b></span>}>

                <Row style={{ padding: 15 }}>
                    <Col xs="12" md="6" lg="6" xl="4">
                        <CardDeResumen
                            subtitulo={this.state.subtitulo}
                            // icono={this.obtenerIconoSegunActivo()}
                            titulo={this.obtenerTituloDeResumenSegunState()}>

                            <div style={{ justifyContent: "center" }}>
                                {this.obtenerBotonParaIniciarProcesoEnBackend()}
                                {this.obtenerBotonParaSeleccionarArchivo()}
                                {this.obtenerBotonParaResetearArchivo()}
                            </div>
                        </CardDeResumen>
                        <CardDeResumen subtitulo={"<span style='opacity:0.6;'>Aquí encuentras templates de cargue en excel</span>"}>
                            <div>
                                <ButtonDropdown className="d-sm-block mt-1" direction="right" isOpen={this.state.btnDropright}
                                    toggle={() => { this.setState({ btnDropright: !this.state.btnDropright }) }}>
                                    <DropdownToggle className="drop-down-button" caret>
                                        <span style={{ marginRight: 2 }}>Descargar plantillas</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="drop-down-descargas">
                                        {/* API 581 */}
                                        <DropdownItem style={{ display: (this.props.match.path.includes("api580")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://drive.google.com/file/d/1DxNnuJf0IE3qXrSHshI60PMmZokdrV6k/view?usp=sharing"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para líneas</a>
                                        </DropdownItem>
                                        <DropdownItem style={{ display: (this.props.match.path.includes("api580")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://drive.google.com/file/d/1QWQ0rtqKiF4t7CEFr-O55sRpn7k8FLV9/view?usp=sharing"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para tanques</a>
                                        </DropdownItem>
                                        <DropdownItem style={{ display: (this.props.match.path.includes("api580")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://drive.google.com/file/d/1kPSRy3GhFNZ0h4MC5fxq5tuWb3AcOrq2/view?usp=sharing"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para vasijas</a>
                                        </DropdownItem>

                                        {/* ASME B31.8S */}
                                        <DropdownItem style={{ display: (this.props.match.path.includes("asmeb318s")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://drive.google.com/uc?id=1LuHIyhC_EuoksfjRRhdnVDMEYJTa9CmJ&export=download&authuser=0"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para ASME B31.8S</a>
                                        </DropdownItem>

                                        {/* API 1160 */}
                                        <DropdownItem style={{ display: (this.props.match.path.includes("api1160")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://drive.google.com/uc?id=1Dn2hnzB4ZQRx8Fg5Gl0jzRkwLXpQ_L7x&export=download&authuser=0"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para API 1160</a>
                                        </DropdownItem>

                                        {/* API 570 */}
                                        <DropdownItem style={{ display: (this.props.match.path.includes("integridad/lineas")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://docs.google.com/spreadsheets/d/1QxFfqZYbbQCD1hkqjCtMLid1JJz2lnvs/edit?rtpof=true"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para API 570</a>
                                        </DropdownItem>

                                        {/* API 510 */}
                                        <DropdownItem style={{ display: (this.props.match.path.includes("integridad/api510")) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://docs.google.com/spreadsheets/d/1QxFfqZYbbQCD1hkqjCtMLid1JJz2lnvs/edit?rtpof=true"
                                                target='_blank' rel="noopener noreferrer" download>Descargar template para API 510</a>
                                        </DropdownItem>

                                        <DropdownItem style={{ display: (this.props.isPRD) ? "unset" : "none" }}>
                                            <a className="ancla-plantilla" href="https://drive.google.com/uc?id=1vLPxbeShX3Pt6DHvxFLTTf_-DCFkKsnf&export=download&authuser=0"
                                                target='_blank' rel="noopener noreferrer" download>Descargar plantilla</a>
                                        </DropdownItem>
                                        
                                    </DropdownMenu>
                                </ButtonDropdown>
                                {/* {this.renderPlantillasDeCargue()}    */}
                            </div>
                        </CardDeResumen>
                        <input type="file" id="miInputDeFichero" ref="miInputDeFichero" style={{ display: "none" }}
                            accept=".xlsm,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => { this.alistarFicheroDeExcel(e.target.files[0]);
                            if(this.props.isPRD)document.getElementById("miInputDeFichero").value="";
                            }} />
                    </Col>

                    <Col xs="12" md="6" lg="6" xl="8">
                        <TimelineDeProceso logDeProceso={this.state.logDeProceso} />
                    </Col>

                </Row>

            </CustomModal>
        );
    }

    componentDidMount() {
    }



    //Métodos para renderizado:
    obtenerBotonParaIniciarProcesoEnBackend = () => {
        let estadoDelProceso = this.state.estadoDelProceso;

        if (estadoDelProceso === "ficheroListo") {
            return (
                <Button className="btn-wide pusheable-button"
                    color="success" style={{ marginBottom: 20 }}
                    onClick={() => {
                        // window.document.getElementById("miInputParaPruebas").value = JSON.stringify(this.state.matrizDeDatos);
                        this.cargarElementosDesdeMatrizDeDatos();
                    }}>
                    Iniciar proceso
                </Button>
            );
        }
    }

    //Renders plantillas de cargue
    renderPlantillasDeCargue = () => {
        if(this.props.isPRD){
            return(
                <React.Fragment>
                    {/* <Button className="btn btn-success" direction="right">Descargar plantilla */}
                    <a className="ancla-plantilla btn btn-success" href="https://drive.google.com/uc?id=1vLPxbeShX3Pt6DHvxFLTTf_-DCFkKsnf&export=download&authuser=0"
                                    target='_blank' rel="noopener noreferrer" download>Descargar plantilla</a>
                    {/* </Button> */}
                </React.Fragment>  
                )
        }else{
            return (
                <React.Fragment>
                    <ButtonDropdown className="d-sm-block mt-1" direction="right" isOpen={this.state.btnDropright}
                        toggle={() => { this.setState({ btnDropright: !this.state.btnDropright }) }}>
                        <DropdownToggle className="drop-down-button" caret>
                            <span style={{ marginRight: 2 }}>Descargar plantillas</span>
                        </DropdownToggle>
                        <DropdownMenu className="drop-down-descargas">
                            {/* API 581 */}
                            <DropdownItem style={{ display: (this.props.match.path.includes("api580")) ? "unset" : "none" }}>
                                <a className="ancla-plantilla" href="https://drive.google.com/file/d/1DxNnuJf0IE3qXrSHshI60PMmZokdrV6k/view?usp=sharing"
                                    target='_blank' rel="noopener noreferrer" download>Descargar template para líneas</a>
                            </DropdownItem>
                            <DropdownItem style={{ display: (this.props.match.path.includes("api580")) ? "unset" : "none" }}>
                                <a className="ancla-plantilla" href="https://drive.google.com/file/d/1QWQ0rtqKiF4t7CEFr-O55sRpn7k8FLV9/view?usp=sharing"
                                    target='_blank' rel="noopener noreferrer" download>Descargar template para tanques</a>
                            </DropdownItem>
                            <DropdownItem style={{ display: (this.props.match.path.includes("api580")) ? "unset" : "none" }}>
                                <a className="ancla-plantilla" href="https://drive.google.com/file/d/1kPSRy3GhFNZ0h4MC5fxq5tuWb3AcOrq2/view?usp=sharing"
                                    target='_blank' rel="noopener noreferrer" download>Descargar template para vasijas</a>
                            </DropdownItem>
    
                            {/* ASME B31.8S */}
                            <DropdownItem style={{ display: (this.props.match.path.includes("asmeb318s")) ? "unset" : "none" }}>
                                <a className="ancla-plantilla" href="https://drive.google.com/file/d/1LuHIyhC_EuoksfjRRhdnVDMEYJTa9CmJ/view"
                                    target='_blank' rel="noopener noreferrer" download>Descargar template para ASME B31.8S</a>
                            </DropdownItem>
    
                            {/* API 1160 */}
                            <DropdownItem style={{ display: (this.props.match.path.includes("api1160")) ? "unset" : "none" }}>
                                <a className="ancla-plantilla" href="https://drive.google.com/file/d/1Dn2hnzB4ZQRx8Fg5Gl0jzRkwLXpQ_L7x/view?usp=sharing"
                                    target='_blank' rel="noopener noreferrer" download>Descargar template para API 1160</a>
                            </DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </React.Fragment>
            )
        }
       
    }

    obtenerBotonParaResetearArchivo = () => {
        let estadoDelProceso = this.state.estadoDelProceso;

        if (estadoDelProceso === "ficheroListo" || estadoDelProceso === "errorLeyendoElFichero" || estadoDelProceso === "procesoFinalizado") {
            return (
                <Button className="btn-wide pusheable-button"
                    color="info" style={{ marginBottom: 0 }} outline
                    onClick={() => { this.refs.miInputDeFichero.click(); }}>
                    Seleccionar otro archivo
                </Button>
            );
        }
    }

    obtenerBotonParaSeleccionarArchivo = () => {
        let estadoDelProceso = this.state.estadoDelProceso;

        if (estadoDelProceso === "inicio") {
            return (
                <Button className="btn-wide pusheable-button"
                    color="primary" style={{ marginBottom: 20 }}
                    onClick={() => { this.refs.miInputDeFichero.click(); }}>
                    Seleccionar archivo
                </Button>
            );
        }
    }

    obtenerIconoSegunActivo = () => {
        if (this.state.estadoDelProceso === "ficheroListo" || this.state.estadoDelProceso === "cargandoData") {
            if (this.props.isPRD) {
                return plantillasDeCargue["ductos"].urlDelIcono;
            } 
            // else if (this.props.isIntegridad) {
            //     return 
            // }
             else {
                return plantillasDeCargue[this.state.tipoDeActivos].urlDelIcono;
            }
        }
    }

    obtenerTituloDeResumenSegunState = () => {
        switch (this.state.estadoDelProceso) {
            case "ficheroListo":
                return "Voy a cargar " + this.state.tipoDeActivos + " desde:";
            case "cargandoData":
                return "Estoy cargando " + this.state.tipoDeActivos + " desde:";
            case "procesoFinalizado":
                return "El proceso de carga ha finalizado";
            default:
                return "";
        }
    }

    //Métodos operativos:
    agregarElementoAlLogDeProceso = (contenido, status, usarTemplateBasico = true, limpiarLog = false) => {
        let nuevoLogDeProceso = (!limpiarLog) ? this.state.logDeProceso : [itemDeInicioDelLog];

        let nuevoItemDeProceso = { status: status, fecha: new Date(), contenido: contenido };
        if (usarTemplateBasico) {
            nuevoItemDeProceso.contenido =
                <div className="" style={{ textAlign: "left" }}
                    dangerouslySetInnerHTML={{ __html: contenido }} />
        }
        nuevoLogDeProceso.push(nuevoItemDeProceso);

        this.setState({ logDeProceso: nuevoLogDeProceso });
    }

    alistarFicheroDeExcel = (ficheroTarget) => {
        this.setState({ estadoDelProceso: "leyendoFichero", subtitulo: "Leyendo el archivo. Espera un poco...", });
        this.agregarElementoAlLogDeProceso("Inició la lectura del archivo <b>" + ficheroTarget.name + "</b>", "info", true, true);
        readXlsxFile(ficheroTarget)
            .then(matrizDeDatos => {
                if (this.props.isPRD) {
                    const { matriz , proceso } = this.prepararDatosDePRD(matrizDeDatos);
                    const procesoValido = ["RBI","Inventario"].includes(proceso); //NEGADO POR PRUEBAS
                    this.agregarElementoAlLogDeProceso(`La <b>lectura preliminar</b> del archivo finalizó ${ procesoValido ?"correctamente":"con errores!"}`, procesoValido?"success":"danger", true);

                    if(procesoValido){
                    this.agregarElementoAlLogDeProceso(`Se realizará cargue para: <b>${proceso}</b>`, "success", true);
                    if(proceso === "Inventario" && !this.props.fromInventario)this.agregarElementoAlLogDeProceso(`<b>¡¡SE REALIZARÁ CARGUE PARA INVENTARIO!!</b>`, "warning", true);
                    this.setState({
                        subtitulo: ficheroTarget.name,
                        datosPRD: { matriz , proceso },
                        estadoDelProceso: "ficheroListo",
                        ficheroSeleccionado: { ...this.state.ficheroSeleccionado, nombre: ficheroTarget.name }
                    })}
                    else{
                        this.agregarElementoAlLogDeProceso(`No se realizará cargue para: <b>${proceso}</b>`, "danger", true);
                        this.agregarElementoAlLogDeProceso(`<b>En plantilla de cargue</b> Valide la casilla <b>"Cargue para"</b> e intente cargar nuevamente`, "warning", true);
                        this.setState({ estadoDelProceso: "inicio", subtitulo:defaultSubtitulo, });
                    }
                } else if(this.props.isIntegridad){
                    this.prepararDatosParaIntegridad(matrizDeDatos);
                    this.agregarElementoAlLogDeProceso("La <b>lectura preliminar</b> del archivo finalizó correctamente", "success", true);
                    this.setState({
                        subtitulo: ficheroTarget.name,
                        estadoDelProceso: "ficheroListo",
                        ficheroSeleccionado: { ...this.state.ficheroSeleccionado, nombre: ficheroTarget.name }
                    })
                }
                else {
                    try {
                        let normaTecnicaAsociada = matrizDeDatos[2][0];
                        try {
                            let tipoDeActivos = reemplazarTildes(matrizDeDatos[1][0]);

                            setTimeout(() => {
                                this.agregarElementoAlLogDeProceso("La <b>lectura preliminar</b> del archivo finalizó correctamente", "success", true);

                                this.setState({
                                    tipoDeActivos: tipoDeActivos,
                                    matrizDeDatos: matrizDeDatos,
                                    subtitulo: ficheroTarget.name,
                                    estadoDelProceso: "ficheroListo",
                                    normaTecnicaAsociada: normaTecnicaAsociada,
                                    ficheroSeleccionado: { ...this.state.ficheroSeleccionado, nombre: ficheroTarget.name }
                                });

                            }, timeoutDeLecturaDelFichero);
                        }
                        catch (error) {
                            let mensajeDeError = "Se presentó el siguiente error interpretando al archivo: <br/><br/>";
                            mensajeDeError += "<span style='color: var(--danger);'>" + error.toString() + "</span>";

                            this.setState({ subtitulo: mensajeDeError, estadoDelProceso: "errorLeyendoElFichero", });
                            this.agregarElementoAlLogDeProceso("No se pudo identificar el tipo de activos a cargar", "danger", true);
                        }
                    }
                    catch (error) {
                        let mensajeDeError = "Se presentó el siguiente error leyendo el archivo: <br/><br/>";
                        mensajeDeError += "<span style='color: var(--danger);'>" + error.toString() + "</span>";

                        this.setState({ subtitulo: mensajeDeError, estadoDelProceso: "errorLeyendoElFichero", });
                        this.agregarElementoAlLogDeProceso("Se presentó un error leyendo el archivo", "danger", true);
                    }
                }

            });
    }

    prepararDatosDePRD = (matrizDeDatos) => {

        const datos = clonedeepPRDenCargue(glosario);
        let nuevaLista = [];
        for (let j = 9; j < matrizDeDatos.length; j++) {
            for (let i = 0; i < matrizDeDatos[0].length; i++) {
                Object.keys(datos).forEach(item1 => {
                    Object.keys(datos[item1]).forEach(item2 => {
                        Object.keys(datos[item1][item2]).forEach(item3 => {
                            if (matrizDeDatos[2][i] === item3) {
                                try {
                                    const datoCelda = matrizDeDatos[j][i]
                                    datos[item1][item2][item3] = isDate(datoCelda) ? datoCelda.toLocaleDateString('en-US') :
                                        isNaN(datoCelda) ? null : datoCelda;
                                } catch (e) {
                                    console.log("error: ", e);
                                }
                            }
                        })
                    })
                })
            }
            //AHORA ES [5][1] Y NO [4][1] DEBIDO A QUE SE MOVIÓ LA POSICION DE MODELO DE CONSECUENCAI PARA AÑADIR EL INVENTARIO
            const  datoTemporal = { ...clonedeepPRDenCargue(datos), "modeloDeConsecuencia": matrizDeDatos[5][1] , "dominioCorporativo" : this.props.usuarioActivo.dominioCorporativo }
            nuevaLista.push(datoTemporal);
        }
        const proceso = matrizDeDatos[4][1]
        return { matriz: nuevaLista, proceso };
    }

    prepararDatosParaIntegridad = (matrizDeDatos) => {
        if(matrizDeDatos.length > this.state.tamanhoBatchIntegridad) {
            this.procesarDatosEnBatches(matrizDeDatos, [], 4, 4 + this.state.tamanhoBatchIntegridad)
            this.setState({calculoPorBatch: true})
        } else {
            this.procesarDatosEnUnBatch(matrizDeDatos);
        }
    }
    
    procesarDatosEnBatches = (matrizDeDatos, bloqueDeDatos, filaInicial, filaFinal ) => {
        const listaDeBatch = bloqueDeDatos;
        const batch = [];
        if (filaInicial !== 4 && (filaFinal - filaInicial) !== this.state.tamanhoBatchIntegridad) {
            for(let i = filaInicial; i < matrizDeDatos.length; i++){
                batch.push(...recorrerMatrizDeDatosIntegridad(matrizDeDatos, i));
            }
            listaDeBatch.push(batch);
            this.setState({matrizDeDatosIntegridad: listaDeBatch});
        } else {
            for(let i = filaInicial; i < filaFinal; i++){
                batch.push(...recorrerMatrizDeDatosIntegridad(matrizDeDatos, i));
                if ( i === filaFinal - 1){
                    listaDeBatch.push(batch)
                    let nuevaFilaFinal;
                    const nuevaFilaInicial = filaInicial + this.state.tamanhoBatchIntegridad;
                    if ( filaFinal + this.state.tamanhoBatchIntegridad > matrizDeDatos.length ){
                        nuevaFilaFinal = matrizDeDatos.length - filaFinal
                    } else {
                        nuevaFilaFinal = filaFinal + this.state.tamanhoBatchIntegridad;
                    }
                    this.procesarDatosEnBatches(
                        matrizDeDatos, 
                        listaDeBatch, 
                        nuevaFilaInicial, 
                        nuevaFilaFinal 
                        );
                    }
                }
                
            }
        }

    procesarDatosEnUnBatch = (matrizDeDatos) => {
        const datosEnUnSoloBatch = [];
        for(let i = 4; i < matrizDeDatos.length; i++){
            let objetoConDataIntegridad = {};
            matrizDeDatos[0].forEach((key, indiceKey) => {
                if(key === 'fechaDeActivacion' || key === 'fechaDeInspeccion') {
                    objetoConDataIntegridad[key] =  (matrizDeDatos[i][indiceKey].getTime() / 1000) 
                    // / (3600 * 24)) + (365*70) + 19
                } else {
                    objetoConDataIntegridad[key] =  matrizDeDatos[i][indiceKey]
                }
            })
            datosEnUnSoloBatch.push(objetoConDataIntegridad)
        }
        this.setState({matrizDeDatosIntegridad: datosEnUnSoloBatch})
    }

    cargarElementosDesdeMatrizDeDatos = () => {
        let propsParaCargueDesdeCloudFunctions = { usuarioActivo: this.props.usuarioActivo, };
        this.agregarElementoAlLogDeProceso("<b>Comenzó</b> el proceso de <b>carga masiva</b>", "info", true, true);
        this.setState({
            puedoCerrarElModal: false, estadoDelProceso: "cargandoData",
            subtitulo: "<span>" + this.state.ficheroSeleccionado.nombre +
                "</span><br/></br><span style='opacity:0.6;'>Por favor espera, esto puede tardar algunos minutos</span>"
        });

        // DANGER ZONE || DANGER ZONE
        // propsParaCargueDesdeCloudFunctions.usuarioActivo.dominioCorporativo = "geo-park.com";
        // DANGER ZONE || DANGER ZONE
        if (this.props.isPRD) {
            cargarDatosDePRD(this.state.datosPRD, (contenido, status, usarTemplateBasico) => {
                this.agregarElementoAlLogDeProceso(contenido, status, usarTemplateBasico, false);
            })
                .then(() => {
                    let tiempoTardado = "...";
                    try { tiempoTardado = new Date() - this.state.logDeProceso[1].fecha; tiempoTardado = Math.ceil(tiempoTardado / 1000); }
                    catch (error) { };

                    let contenidoDeFinExitoso = obtenerItemDeFinalizacionExitosa(tiempoTardado);
                    this.agregarElementoAlLogDeProceso(contenidoDeFinExitoso, "success", false);
                    // Con esta funcion, se vuelve a consultar la base de datos, para traer los nuevos elementos.
                   this.props.consultarDatosPRD && this.props.consultarDatosPRD();
                    this.setState({
                        puedoCerrarElModal: true,
                        estadoDelProceso: "procesoFinalizado",
                        subtitulo: "<span style='color: var(--success);'><b>Con éxito</b></span>"
                    });
                })
                .catch(() => {
                    this.setState({
                        puedoCerrarElModal: true,
                        estadoDelProceso: "procesoFinalizado",
                        subtitulo: "<span style='color: var(--danger);'><b>Con errores</b></span>"
                    });
                })
        } else if (this.props.isIntegridad) {
            cargarDatosDeIntegridad(this.state.matrizDeDatosIntegridad, this.state.calculoPorBatch, (contenido, status) => {
                this.agregarElementoAlLogDeProceso(contenido, status, true, false);
            }, this.props.modulo)
                .then(() => {
                    let tiempoTardado = "...";
                    try { tiempoTardado = new Date() - this.state.logDeProceso[1].fecha; tiempoTardado = Math.ceil(tiempoTardado / 1000); }
                    catch (error) { };

                    let contenidoDeFinExitoso = obtenerItemDeFinalizacionExitosa(tiempoTardado);
                    this.agregarElementoAlLogDeProceso(contenidoDeFinExitoso, "success", false);
                    this.setState({
                        puedoCerrarElModal: true,
                        estadoDelProceso: "procesoFinalizado",
                        subtitulo: "<span style='color: var(--success);'><b>Con éxito</b></span>"
                    });
                    this.props.callbackFunction();
                })
                .catch(() => {
                    this.setState({
                        puedoCerrarElModal: true,
                        estadoDelProceso: "procesoFinalizado",
                        subtitulo: "<span style='color: var(--danger);'><b>Con errores</b></span>"
                    });
                })

            } else {
            cargarMatrizDeDatosEnFirestore(propsParaCargueDesdeCloudFunctions, this.state.matrizDeDatos, (contenido, status) => {
                this.agregarElementoAlLogDeProceso(contenido, status, true, false);
            })
                .then((nombreCampo) => {
                    let tiempoTardado = "...";
                    try { tiempoTardado = new Date() - this.state.logDeProceso[1].fecha; tiempoTardado = Math.ceil(tiempoTardado / 1000); }
                    catch (error) { };

                    let contenidoDeFinExitoso = obtenerItemDeFinalizacionExitosa(tiempoTardado);
                    this.agregarElementoAlLogDeProceso(contenidoDeFinExitoso, "success", false);

                    this.setState({
                        puedoCerrarElModal: true,
                        estadoDelProceso: "procesoFinalizado",
                        subtitulo: "<span style='color: var(--success);'><b>Con éxito</b></span>"
                    });

                    this.props.callbackFunction(nombreCampo);
                })
                .catch(() => {
                    this.setState({
                        puedoCerrarElModal: true,
                        estadoDelProceso: "procesoFinalizado",
                        subtitulo: "<span style='color: var(--danger);'><b>Con errores</b></span>"
                    });
                });
        }

    }

    toggleDelModal = () => {
        if (this.state.puedoCerrarElModal && this.props.toggleDelModal) {
            this.props.toggleDelModal();
        }
    }
}

const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    endpointParaCargarCampo: state.reducerParaFunctions.endpointParaCargarCampo,
    endpointParaCargarSistema: state.reducerParaFunctions.endpointParaCargarSistema,
    endpointParaCargarActivo: state.reducerParaFunctions.endpointParaCargarActivo,
    endpointParaCargarSegmento: state.reducerParaFunctions.endpointParaCargarSegmento,
});

export default withRouter(connect(mapStateToProps)(ConsolaDeCargue));