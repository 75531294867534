import React from 'react'
import { Row, CardHeader } from 'reactstrap';

//Componentes auxiliares:
import RegistroFotografico from '../RegistroFotografico';

const GaleriaCamisas = (props) => {
    if (props.keysDelRegistro) {
        return (
            <React.Fragment>
                <br/>
                { (props.keysDelRegistro.primeraFotoAnomalia !== undefined || props.keysDelRegistro.segundaFotoAnomalia !== undefined)?
                    <CardHeader style={{ alignContent: "flex-start", justifyContent: "center" }}>
                        <div >
                            REGISTRO FOTOGRÁFICO {props.titulo}
                        </div>
                    </CardHeader>
                    : <React.Fragment></React.Fragment>
                }
               
                <Row>
                    {props.keysDelRegistro.primeraFotoAnomalia !== undefined ? <RegistroFotografico
                        key={props.keysDelRegistro ? props.keysDelRegistro.primeraFotoAnomalia.path : null}
                        path={props.keysDelRegistro ? props.keysDelRegistro.primeraFotoAnomalia.path : null}
                        idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                        idDeAnomalia={props.idDeAnomalia}
                        name={props.keysDelRegistro.primeraFotoAnomalia.titulo}
                        imagen={imagenCamisas(props.tipoDeAnomalia, true)} 
                        /* name={props.keysDelRegistro.primeraFotoAnomalia.titulo} *//> : <React.Fragment />}

                    {props.keysDelRegistro.segundaFotoAnomalia !== undefined ? <RegistroFotografico
                        key={props.keysDelRegistro ? props.keysDelRegistro.segundaFotoAnomalia.path : null}
                        path={props.keysDelRegistro ? props.keysDelRegistro.segundaFotoAnomalia.path : null}
                        idOrdenDeTrabajo={props.idOrdenDeTrabajo}
                        idDeAnomalia={props.idDeAnomalia}
                        name={props.keysDelRegistro.segundaFotoAnomalia.titulo}
                        imagen={imagenCamisas(props.tipoDeAnomalia, false)} 
                        /* name={props.keysDelRegistro.segundaFotoAnomalia.titulo} */ /> : <React.Fragment />}

                </Row>
            </React.Fragment>
        );
    }

    return (<React.Fragment />);
}

const imagenCamisas =(tipo, primera)=>{
    switch (tipo) {
        case 'CAMISA':
            if (primera) { return 'primeraImagenCamisas' }
            else { return 'segundaImagenCamisas' }
        case 'CAMISA_SOBRE_ANILLOS':
            if (primera) { return 'primeraImagenCamisasSA' }
            else { return 'segundaImagenCamisasSA' }
        case 'PARCHE':
            if (primera) { return 'primeraImagenParches' }
            else { return 'segundaImagenParches' }
        default:
            return 'primeraImagenCamisas';
    }
}

export default GaleriaCamisas;