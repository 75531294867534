
var currencyFormatter = require('currency-formatter');

export const obtenerVariableDeTablaFormateada = (variable, valor) => {
    let unidadDeVariable = variable.unidades;
    let tipoDeVariable = variable.tipo;
    if (unidadDeVariable === null) {
        if (variable.tipo === 'texto') {
            return valor
        } else if (variable.tipo === 'fecha') {
            try {
                let fechaSinISO = valor.split("T")[0];
                // let time = dateFormat(fechaSinISO, "d/mmmm/yyyy ")
                let time = fechaSinISO.toLocaleString().replace(/-/gi,'/')
                return `${time}`
            }
            catch (error) {
                return valor;
            }
        } else return valor
    }
    else if (tipoDeVariable === 'númerico') {
        if (unidadDeVariable.includes('$')) {
            return (
                `${currencyFormatter.format(valor, { code: 'COP' }).replace(',', '.')}`
            )
        } else if (toString(valor).length > 6 && valor > 1 && valor < 1000 && valor % 1 !== 0) {
            return parseFloat(valor).toFixed(2);
        }
        else if (valor > 0 && valor < 1 && valor.toString().length > 5) {

            let valorDecimal = valor.toString().split('.')[1];
            if (valorDecimal[0] === '0' && valorDecimal[1] === '0' && valorDecimal[2] === '0') {
                return parseFloat(valor).toExponential(2)
            } else {
                return parseFloat(valor).toFixed(3)
            }

        }else {
            if(variable.funcionDeTransformacion === 'paraPorcentajes'){
                let valorEnPorcentaje = valor * 100
                return valorEnPorcentaje
            }else {
                return valor
            }
        }
    }
    else {
        return valor
    }
}