import React from 'react';
import { Card, Col } from 'reactstrap';

//Componentes auxiliares:
import CustomModal from '../../../../../../utilidades/componentes/plantillaBase/CustomModal';
import CustomTable from '../../../../../../utilidades/componentes/visualizadores/CustomTable';

//Scripts auxiliares:
import { validarQueNoExistaEnLista } from '../scripts/managerDeListas';
import { obtenerNombreDePropiedad } from '../scripts/managerDeNombres';
import { obtenerCantidadEnNotacionCorta } from '../../../../../../utilidades/scripts/paraMoneda';
import { obtenerValorEnNotacionCientifica } from '../../../../../../utilidades/scripts/paraCantidadesNumericas';
import BarraNivelCualitativo from '../../../../../../utilidades/componentes/resumenDeModulo/BarraNivelCualitativo';
import { obtenerColorSegunValorCualitativo } from '../../../../../../utilidades/scripts/paraVisualizacionDeRangos';

import Typography from '@material-ui/core/Typography';



//Constantes auxiliares:
const estiloDeFuentesBase = { fontSize: 13 };
const estiloParaHeader = { color: 'rgba(32,39,140,0.5)', marginBottom: 0, ...estiloDeFuentesBase, cursor: 'pointer' };

class CardDeResultados extends React.Component {

    constructor() {
        super();

        this.state = {
            elModalEstaAbierto: false
        }
    }

    render() {
        let propsCard = {}
        const clickableCard = { onClick: this.toggleDelModal, style: { cursor: 'pointer' } }
        if (!this.props.notClickable) propsCard = clickableCard;
        return (
            <Col /* sm='12' md='12' lg='6' xl='6' */>   {/* //WARNING  */}
                <Card className='widget-chart border mb-3'>
                    <div className='card no-shadow rm-border bg-transparent widget-chart text-left'
                        {...propsCard}>
                        <div className='icon-wrapper rounded-circle'>
                            {this.obtenerIconoDelCard()}
                        </div>
                        <div className='widget-chart-content'>
                            <div className='widget-numbers' style={{ textAlign: 'center', margin: 0, fontSize: this.props.isPRD ? "x-large" : "xx-large" }}>
                                {this.obtenerValorPrincipalDelCard()}
                            </div>
                            <div className='widget-description opacity-8 text-focus' style={{ marginTop: 15, alignSelf: 'center' }}>
                                {this.obtenerTituloDelCard()}
                            </div>
                        </div>
                    </div>

                    {this.props.conBarraDeNivel && (!!this.props.parametrizacionCorporativa) ? <BarraNivelCualitativo  {...this.obtenerValoresBarraDeNivel()} /> : <hr />}
                    {!!this.props.conFoot && <Typography variant="caption" style={{ marginTop: 4 }} >{this.props.conFoot}</Typography>}
                    {!!this.props.conRiesgoTarget && <Typography variant="caption" >{`Riesgo Target: ${this.props.modeloDeConsecuencia === "Semicuantitativo" ? obtenerCantidadEnNotacionCorta(this.props.conRiesgoTarget) : this.props.conRiesgoTarget}`}</Typography>}
                </Card>

                <CustomModal
                    size='md'
                    toggleDelModal={this.toggleDelModal}
                    titulo={this.obtenerTituloDelModal()}
                    elModalEstaAbierto={this.state.elModalEstaAbierto}>

                    <div style={{ padding: 15 }}>
                        <CustomTable
                            criterioDeOrdenamiento={{ id: 'valor', desc: true }}
                            listaDeDatos={[...this.obtenerListaDeResultados()]}
                            columnas={[
                                {
                                    accessor: 'nombre',
                                    Header: () => (<p style={{ ...estiloParaHeader }}>Nombre</p>),
                                },
                                {
                                    accessor: 'valor',
                                    width: 125,
                                    Header: () => (<p style={{ ...estiloParaHeader }}>Valor</p>),
                                    Cell: row => {
                                        return (
                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
                                                {this.props.probabilidad ? obtenerValorEnNotacionCientifica(row.original.valor, this.props.isPRD ? -3 : -5, this.props.isPRD ? 3 : 1) : typeof row.original.valor === 'string' ? row.original.valor : this.props.modeloDeConsecuencia === "Semicuantitativo" ? obtenerCantidadEnNotacionCorta(row.original.valor) : row.original.valor}
                                            </div>
                                        );
                                    },
                                },
                            ]} />
                    </div>

                </CustomModal>
            </Col>
        );
    }

    //Métodos para renderizado de información del card:
    obtenerIconoDelCard = () => {
        if (this.props.consecuencia !== undefined) {
            return (
                <React.Fragment>
                    <div className='icon-wrapper-bg opacity-10 bg-success' />
                    <i className='pe-7s-cash text-white opacity-8' />
                </React.Fragment>
            );
        }

        if (this.props.probabilidad !== undefined) {
            return (
                <React.Fragment>
                    <div className='icon-wrapper-bg opacity-10 bg-warning' />
                    <i className='lnr-chart-bars text-white opacity-8' />
                </React.Fragment>
            );
        }
        if (this.props.riesgo !== undefined) {
            return (
                <React.Fragment>
                    <div className='icon-wrapper-bg opacity-10 bg-danger' />
                    <i className='lnr-chart-bars text-white opacity-8' />
                </React.Fragment>
            );
        }
        if (this.props.riesgoTotal !== undefined) {
            return (
                <React.Fragment>
                    <div className='icon-wrapper-bg opacity-10 bg-danger' />
                    <i className='lnr-bullhorn text-white opacity-8' />
                </React.Fragment>
            );
        }
    }

    obtenerTituloDelCard = () => {
        if (this.props.consecuencia !== undefined) {
            return 'Consecuencia';
        }

        if (this.props.probabilidad !== undefined) {
            return 'Probabilidad';
        }
        if (this.props.riesgo !== undefined || this.props.riesgoTotal) {
            return 'Riesgo';
        }
    }

    obtenerTituloDelModal = () => {
        let titulo = '';

        if (this.props.consecuencia !== undefined) {
            titulo = 'consecuencia';
        }

        if (this.props.probabilidad !== undefined) {
            titulo = 'probabilidad';
        }
        if (this.props.riesgo !== undefined || this.props.riesgoTotal !== undefined) {
            titulo = 'riesgo';
        }

        return (<span>{'Detalle de '}<b>{titulo}</b></span>);
    }

    obtenerValorPrincipalDelCard = () => {
        if (this.props.consecuencia !== undefined) {
            return ( this.props.isPRD ?
                        (this.props.modeloDeConsecuencia === "Semicuantitativo" ?
                            obtenerCantidadEnNotacionCorta(this.props.consecuencia.financiera)
                        : this.props.consecuencia.maximaCOF) || '-'
                    : obtenerCantidadEnNotacionCorta(this.props.consecuencia.consecuenciaFinanciera) || '-'
                );
        }

        if (this.props.probabilidad !== undefined) {
            return (obtenerValorEnNotacionCientifica(this.props.isPRD ? this.props.probabilidad.total : this.props.probabilidad.probabilidad, this.props.isPRD ? -3 : -5, this.props.isPRD ? 1 : 1) || '-');
        }
        if (this.props.riesgo !== undefined) {
            return (obtenerCantidadEnNotacionCorta(this.props.riesgo.riesgoFinanciero) || this.props.riesgo.nivelDeRiesgo || '-');
        }
        if (this.props.riesgoTotal !== undefined) {
            return (obtenerCantidadEnNotacionCorta(this.props.riesgoTotal.financiera) || this.props.riesgoTotal.nivelDeRiesgo || '-');
        }
    }

    obtenerValorCualitativoDelCard = () => {
        if (this.props.consecuencia !== undefined) {
            switch (this.props.modeloDeConsecuencia) {
                case "Cualitativo":
                    return (this.props.consecuencia.maximaCOF);
                case "Semicuantitativo":
                default:
                    return (this.props.consecuencia.nivelDeConsecuenciaFinanciera);
            }
        }
        if (this.props.probabilidad !== undefined) {
            return (this.props.probabilidad.nivelDeProbabilidad);
        }
        if (this.props.riesgo !== undefined) {
            return (this.props.riesgo.valorCualitativo);
        }
        if (this.props.riesgoTotal !== undefined) {
            return (this.props.riesgoTotal.valorCualitativo);
        }
    }
    obtenerValoresBarraDeNivel = () => {
        const valorCualitativo = this.obtenerValorCualitativoDelCard();
        const colorAsociado = obtenerColorSegunValorCualitativo(valorCualitativo, this.props.parametrizacionCorporativa);
        const valorCualitativoMaximo = this.obtenerValorCualitativoMaximo();
        const tituloDeLaBarra = valorCualitativo;
        const valoresBarraDeNivel = { valorCualitativo, colorAsociado, valorCualitativoMaximo, tituloDeLaBarra }
        return valoresBarraDeNivel;
    }
    obtenerValorCualitativoMaximo() {
        let rangosComoLista = Object.keys(this.props.parametrizacionCorporativa).map((key, i) => { return { ...this.props.parametrizacionCorporativa[key] }; });
        let valorMaximo = rangosComoLista[0].valorCualitativo;
        rangosComoLista.forEach((rango, i) => { if (rango.valorCualitativo > valorMaximo) { valorMaximo = rango.valorCualitativo } });
        return valorMaximo;
    }
    //Métodos operativos:
    obtenerListaDeResultados = () => {
        let itemsDelArray = [];

        const objetoTarget = this.props[this.props.scope]||{};
        let blackList = ['pof', 'cof', 'probabilidad', 'probabilidadCualitativa', 'consecuenciaFinanciera', 'consecuenciaFinancieraCualitativa', 'consecuenciaEnAreaCualitativa', 'nivelDeProbabilidad', 'total', 'calculadoSatisfactoriamente', 'riesgoFinanciero', 'valorCualitativo'];
        if (this.props.modeloDeConsecuencia === "Semicuantitativo") {
            blackList = blackList.concat(['nivelDeConsecuenciaFinanciera'])
        } else {
            blackList = blackList.concat(['financiera'])
        }
        Object.keys(objetoTarget).forEach((key, i) => {
            if (validarQueNoExistaEnLista(blackList, key)) {
                itemsDelArray.push({
                    nombre: obtenerNombreDePropiedad(key, this.props.scope),
                    valor: (this.props.scope === 'probabilidad' && !this.props.isPRD) ? objetoTarget[key].pof : this.middlewarePRD(objetoTarget[key], key)
                });
            }
        });

        return itemsDelArray;
    }

    toggleDelModal = () => {
        this.setState({ elModalEstaAbierto: !this.state.elModalEstaAbierto });
    }
    middlewarePRD = (value, key) => {
        if (this.props.isPRD) {
            if (key === "consecuenciaEnArea") {
                if (typeof value === "string") return value
                if (value === 0) return '0 Ft2'
                return `${value >= (10 ** 4) ? value.toFixed(1) : value.toFixed(3)} Ft2`
            }
            else if (key === "nivelDeConsecuenciaArea") {
                if (typeof value === "string") return value
                return `${value}`
            }
        }
        return value;
    }
}

export default CardDeResultados;