import React, { useState } from 'react'

import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar } from 'recharts'
import { MenuItem, Select } from '@material-ui/core'

import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado'
import CrearTablaDinamicamente from '../../../../../utilidades/componentes/visualizadores/CrearTablaDinamicamente'
import GraficoDeTortaIntegridad from '../../../../../utilidades/componentes/visualizadores/GraficoDeTortaIntegridad'
import armarDataParaGraficoDeTortaUtScanA from '../lrecLh5ALh1002/scripts/armarDataParaGraficoDeTortaUtScanA';
import armarDataParaGraficoDeTortaUtScanC from '../lrecLh5ALh1002/scripts/armarDataParaGraficoDeTortaUtScanC';
import armarDataParaGraficoDeBarrasScanA from '../lrecLh5ALh1002/scripts/armarDataParaGraficoDeBarrasScanA';
import armarDataParaGraficoDeBarrasUtScanC from '../lrecLh5ALh1002/scripts/armarDataParaGraficoDeBarrasUtScanC';
import crearTablaLeyendaGraficaBarrasUtScanA from '../lrecLh5ALh1002/scripts/crearTablaLeyendaGraficaBarrasUtScanA';
import crearTablaLeyendaGraficaBarrasUtScanC from '../lrecLh5ALh1002/scripts/crearTablaLeyendaGraficaBarrasUtScanC';


const GraficaTablasUtScan = (props) => {


    const [dataGraficoTortaScanA, setDataGraficoTortaScanA] = useState('Vida remanente')
    const [dataGraficoTortaScanC, setDataGraficoTortaScanC] = useState('porcentaje de pérdida')

    const [dataGraficoBarrasScanA, setDataGraficoBarrasScanA] = useState('Vida remanente')
    const [dataGraficoBarrasScanC, setDataGraficoBarrasScanC] = useState('porcentaje de pérdida')


    const cambiarEstadoBotonFiltro = (e, tipo) => {
        if(tipo === 'graficaTortaScanA'){
            setDataGraficoTortaScanA(e.target.value);
            // this.setState({contador: 1});
            renderizarGraficoTortaScan()
        } else if(tipo === 'graficaTortaScanC'){
            setDataGraficoTortaScanC(e.target.value);
            renderizarGraficoTortaScan()
        } else if(tipo === 'graficaBarrasScanA'){
            setDataGraficoBarrasScanA(e.target.value);
            rederizarGraficaDeBarrasUtScanA()
        } else if(tipo === 'graficaBarrasScanC'){
            setDataGraficoBarrasScanC(e.target.value);
            rederizarGraficaDeBarrasUtScanC()
        }
    }


    const obtenerListadoDeOpciones = (tipo) => {
        let menu = []
        let opcionesGraficaTortaScanA = [
            'Vida remanente',
            // 'Porcentaje de pérdida',
            // 'MAWP',
        ]
        let opcionesGraficaTortaScanC = [
            'porcentaje de pérdida'
        ]
        let opcionesGraficaBarrasScanA = [
            'Vida remanente',
            // 'Porcentaje de pérdida',
            // 'MAWP',
        ]
        let opcionesGraficaBarrasScanC = [
            'porcentaje de pérdida'
        ]
        if(tipo === 'graficaTortaScanA'){
            opcionesGraficaTortaScanA.forEach((opcion, i)=> {
                menu.push(<MenuItem key={opcion} value={opcion}>{opcion}</MenuItem>)
            })
            return menu
        }else if(tipo === 'graficaTortaScanC'){
            opcionesGraficaTortaScanC.forEach((opcion, i)=> {
                menu.push(<MenuItem key={opcion} value={opcion}>{opcion}</MenuItem>)
            })
            return menu
        }else if(tipo === 'graficaBarrasScanA'){
            opcionesGraficaBarrasScanA.forEach((opcion, i)=> {
                menu.push(<MenuItem key={opcion} value={opcion}>{opcion}</MenuItem>)
            })
            return menu
        }else if(tipo === 'graficaBarrasScanC'){
            opcionesGraficaBarrasScanC.forEach((opcion, i)=> {
                menu.push(<MenuItem key={opcion} value={opcion}>{opcion}</MenuItem>)
            })
            return menu
        }
    }


    const generarBoton = (tipoDeBoton) => {
        switch(tipoDeBoton){
            case 'utScanATorta':
                return(
                    <Select
                    key={'boton de graficaTortaScanA'}
                    name={'Botón graficaTortaScanA'}
                    value={dataGraficoTortaScanA}
                    onChange={(e) => {cambiarEstadoBotonFiltro(e, 'graficaTortaScanA')}}
                    className={'select-filtro-tabla'}
                    variant="outlined"
                    >
                        {obtenerListadoDeOpciones('graficaTortaScanA')}
                    </Select>
                )
            case 'utScanCTorta':
                return(
                    <Select
                    key={'boton de graficaTortaScanC'}
                    name={'Botón graficaTortaScanC'}
                    value={dataGraficoTortaScanC}
                    onChange={(e) => {cambiarEstadoBotonFiltro(e, 'graficaTortaScanC')}}
                    className={'select-filtro-tabla'}                    
                    variant="outlined"
                    >
                        {obtenerListadoDeOpciones('graficaTortaScanC')}
                    </Select>
                )
            case 'utScanABarra':
                return(
                    <Select
                    key={'boton de graficaBarrasScanA'}
                    name={'Botón graficaBarrasScanA'}
                    value={dataGraficoBarrasScanA}
                    onChange={(e) => {cambiarEstadoBotonFiltro(e, 'graficaBarrasScanA')}}
                    className={'select-filtro-tabla'}                    
                    variant="outlined"
                    >
                        {obtenerListadoDeOpciones('graficaBarrasScanA')}
                    </Select>
                )
            case 'utScanCBarra':
                return(
                    <Select
                    key={'boton de graficaBarrasScanC'}
                    name={'Botón graficaBarrasScanC'}
                    value={dataGraficoBarrasScanC}
                    onChange={(e) => {cambiarEstadoBotonFiltro(e, 'graficaBarrasScanC')}}
                    className={'select-filtro-tabla'}                    
                    variant="outlined"
                    >
                        {obtenerListadoDeOpciones('graficaBarrasScanC')}
                    </Select>
                )
            default:
                return(<React.Fragment></React.Fragment>)
        }
    }


    const renderizarGraficoTortaScan = (graficaEstado, tipo) => {
        switch(tipo){
            case 'utScanA':
                return(
                    <GraficoDeTortaIntegridad
                        key={graficaEstado}
                        parametrizacion={armarDataParaGraficoDeTortaUtScanA(graficaEstado, props.nodosCml)}
                    />
                )
            case 'utScanC':
                return(
                    <GraficoDeTortaIntegridad
                        parametrizacion={armarDataParaGraficoDeTortaUtScanC(graficaEstado, props.nodosCml)}
                    />
                )
            default:
                return(
                    <React.Fragment></React.Fragment>
                )
        }
    }


    const rederizarGraficaDeBarrasUtScanA = () => {
        return(
            <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                <BarChart data={armarDataParaGraficoDeBarrasScanA(dataGraficoBarrasScanA, props.nodosCml)} barSize={30} barGap={4}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#333"/>
                    <Legend />
                    <Tooltip cursor={{ stroke: '#333', strokeWidth: 1 }} />
                    <Bar yAxisId="left" name="Cantidad Cmls" dataKey="cantidad" fill="#0779e4"  />
                    {/* <Bar yAxisId="right" name="Porcentaje (%)" dataKey="porcentaje" fill="#333" /> */}
                    <br/>
                </BarChart>
            </ResponsiveContainer>
        )     
    }


    const rederizarGraficaDeBarrasUtScanC = () => {
        return(
            <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                <BarChart data={armarDataParaGraficoDeBarrasUtScanC( props.nodosCml )} barSize={30} barGap={4}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#333"/>
                    <Legend />
                    <Tooltip cursor={{ stroke: '#333', strokeWidth: 1 }} />
                    <Bar yAxisId="left" name="Cantidad Cmls" dataKey="cantidad" fill="#0779e4" />
                    {/* <Bar yAxisId="right" name="Porcentaje (%)" dataKey="porcentaje" fill="#333" /> */}
                    <br/>
                </BarChart>
            </ResponsiveContainer>
        )     
    }


    const renderizarTablaLeyendaBarras = (tabla) => {
        if(tabla === 'utScanA'){
            return (
                <CrearTablaDinamicamente
                    style={{margin: '15px 0 0 0'}}
                    datosDeTabla={crearTablaLeyendaGraficaBarrasUtScanA(dataGraficoBarrasScanA)}/>
            )
        }else if(tabla === 'utScanC'){
            return (
                <CrearTablaDinamicamente
                    style={{margin: '15px 0 0 0'}}
                    datosDeTabla={crearTablaLeyendaGraficaBarrasUtScanC()}/>
            )
        }
    }


    return(
        <React.Fragment>
            <Col md='12'>
                <CardConEncabezado
                    titulo={props.titulo}
                    icono={props.icono}
                    bgColor={props.bgColor}>
                    <CrearTablaDinamicamente
                        datosDeTabla={props.datosDeTabla}/>
                </CardConEncabezado>
            </Col>
        
            {<br/>}

            <Col md='12'>
                <Row>
                    <Col xs='12' md='6' lg='6' xl='6'>
                        <CardConEncabezado
                                titulo={'Grafica de torta '+props.titulo}
                                icono={props.icono}
                                bgColor={props.bgColor}
                                botonesAuxiliares={[generarBoton(props.keyTabla+'Torta')]}>
                                    {
                                        props.keyTabla === 'utScanA' &&
                                            renderizarGraficoTortaScan(dataGraficoTortaScanA, 'utScanA')
                                    }
                                    {
                                        props.keyTabla === 'utScanC' &&
                                            renderizarGraficoTortaScan(dataGraficoTortaScanC, 'utScanC')                            
                                    }
                        </CardConEncabezado>
                    </Col>
                
                    <Col xs='12' md='6' lg='6' xl='6'>  
                        <CardConEncabezado
                                titulo={'Grafica de barra '+ props.titulo}
                                icono={props.icono}
                                bgColor={props.bgColor}
                                botonesAuxiliares={[generarBoton(props.keyTabla+'Barra')]}>
                                    <Row>
                                        <Col xs='12' md='12' lg='12' xl='12'>
                                            {
                                                props.keyTabla === 'utScanA' &&
                                                    rederizarGraficaDeBarrasUtScanA(dataGraficoBarrasScanA, 'utScanA')
                                            } 
                                            {
                                                props.keyTabla === 'utScanC' &&
                                                    rederizarGraficaDeBarrasUtScanC(dataGraficoBarrasScanC, 'utScanC')
                                            }     
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12'>
                                            {
                                                props.keyTabla === 'utScanA' &&
                                                renderizarTablaLeyendaBarras('utScanA')
                                            } 
                                            {
                                                props.keyTabla === 'utScanC' &&
                                                renderizarTablaLeyendaBarras('utScanC')
                                            }  
                                        </Col>
                                    </Row>
                        </CardConEncabezado>
                    </Col>
                </Row>
            </Col>
        </React.Fragment>
    )
}

export default  GraficaTablasUtScan