export const listaDeRutasSegunRutaActual = {
    'ordenDeTrabajo': '/app/identificacion/ordenesDeTrabajo',
    'formatoDeInspeccion/': '/app/ordenDeTrabajo/',
    'api580': '/app/dashboard',
    'asmeb318s': '/app/dashboard',
    'api1160': '/app/dashboard',
    'ordenesDeTrabajo': '/app/dashboard',
    'API581': '/app/analisis/riesgo/api580',
    'ASME_B31_8S': '/app/analisis/riesgo/asmeb318s',
    'API1160': '/app/analisis/riesgo/api1160',
    'administrador/usuarios': '/app/dashboard',
    'lineas/activo': '/app/analisis/integridad/lineas',
    'lineas/segmento': '/app/analisis/integridad/lineas',
    'detalleDePRD':'/app/analisis/riesgo/api581Valvulas',
    'api581Valvulas':'/app/gestion/dashboard/api581Valvulas',
    'detalleInventario':'/app/gestion/api581Valvulas/inventario',
    'empresas/':'/app/administrador/empresas',
}