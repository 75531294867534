import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { BsFiles } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { FaPlusCircle, FaRegFilePdf, FaRegFileExcel, FaRegFileImage,FaRegFileAlt } from 'react-icons/fa';

//Componentes auxiliares:
import ConsolaDeCargueDeFicheros from './ConsolaDeCargueDeFicheros';
import CardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import BotonDeCardConEncabezado from '../../../../../../utilidades/componentes/plantillaBase/BotonDeCardConEncabezado';

//Scripts auxiliares para Firebase Storage:
import {confirmacionDeBorradoDeFichero} from '../estaticos/alertasParaSwal';
import { descargarArchivo, obtenerTodosLosArchivos, eliminarArchivo } from '../../../../../../utilidades/scripts/managerDeFirebaseStorage';

class CardDeFicheros extends React.Component {

    constructor() {
        super();

        this.state = {
            ficherosAsociados: [],
            elModalDeCargueEstaAbierto: false
        }
    }

    render() {
        return (
            <React.Fragment>

                <CardConEncabezado titulo="Ficheros asociados" icono={BsFiles}
                    botonesAuxiliares={[<BotonDeCardConEncabezado key="BotonDeCarga"
                        icono={FaPlusCircle} title="Agregar" onClick={this.toggleDelModalDeCargue} />]}>

                    {this.obtenerEnlacesDeFicheros()}

                </CardConEncabezado>

                <ConsolaDeCargueDeFicheros
                    nodo={this.props.nodo}
                    coleccionTarget={this.props.coleccionTarget}
                    toggleDelModal={this.toggleDelModalDeCargue}
                    actualizarLista={this.obtenerListaDeFicheros}
                    elModalEstaAbierto={this.state.elModalDeCargueEstaAbierto} />

            </React.Fragment>
        );
    }

    componentDidMount(){
        this.obtenerListaDeFicheros();
    }

    //Métodos para renderizado:
    obtenerEnlacesDeFicheros = () => {
        if (this.state.ficherosAsociados.length > 0) {
            return this.state.ficherosAsociados.map((fichero, i) => {
                return (
                    <React.Fragment key={"fichero." + i + ".delNodo." + this.props.nodo.id}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ flex: 6 }}>
                                <span style={{ marginBottom: 5 }}>
                                    {this.obtenerIconoSegunExtensionDeFichero(fichero.name.split(".")[1])}
                                </span>
                                <span style={{ marginLeft: 7.5, marginTop: 2.5 }} className="file-hyperlink"
                                    onClick={() => {
                                        descargarArchivo(fichero.fullPath)
                                            .then(response => {
                                                if (response.status === "OK") {
                                                    window.open(response.data, "_blank");
                                                }
                                            })
                                            .catch(error => { console.log(error); });
                                    }}>
                                    {fichero.name}
                                </span>
                            </div>

                            <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                <MdDelete className="pusheable-button"
                                    onClick={() => {
                                        let miConfirmacionDeBorradoDeFichero = { ...confirmacionDeBorradoDeFichero };
                                        miConfirmacionDeBorradoDeFichero.html = miConfirmacionDeBorradoDeFichero.html.replace("@nombreDelFichero", fichero.name);

                                        Swal.fire(miConfirmacionDeBorradoDeFichero)
                                            .then(result => {
                                                if (result.isConfirmed) {
                                                    eliminarArchivo(fichero.fullPath)
                                                        .then(() => {
                                                            Swal.fire("Proceso exitoso", "", 'success');
                                                            this.obtenerListaDeFicheros();
                                                        })
                                                        .catch(error => { console.log(error); });
                                                }
                                            });
                                    }} />
                            </div>
                        </div>

                        {(i < this.state.ficherosAsociados.length - 1) ? <hr /> : <React.Fragment />}
                    </React.Fragment>
                );
            })

        }
        else {
            return (<p style={{ textAlign: "center" }}>No hay ficheros asociados a este nodo</p>);
        }
    }

    obtenerIconoSegunExtensionDeFichero = (extension) => {
        switch (extension) {
            case "pdf":
                return <FaRegFilePdf/>;
            case "xlsm":
            case "xlsx":
                return <FaRegFileExcel/>;
            case "jpg":
            case "png":
            case "jpeg":
            case "gif":
                return <FaRegFileImage/>;
            default:
                return <FaRegFileAlt/>;
        }
    }

    //Métodos operativos:
    obtenerListaDeFicheros = () => {
        obtenerTodosLosArchivos(this.props.usuarioActivo.companyId, this.props.coleccionTarget, this.props.nodo.id)
            .then(response => { if (response.status === "OK") { this.setState({ ficherosAsociados: response.data }); } })
            .catch(error => { console.log(error); });
    }

    toggleDelModalDeCargue = () => {
        this.setState({ elModalDeCargueEstaAbierto: !this.state.elModalDeCargueEstaAbierto });
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo
});

export default connect(mapStateToProps)(CardDeFicheros);