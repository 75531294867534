export const glosarioDeInputsAgregarEmpresa = {
    nombre: {
        key: 'nombre',
        type: 'text',
        mensaje: 'mensajeNombre',
        label: 'Nombre :',
        categoria: "valorAbierto",
        'col-sm': 12,
        'col-md': 12,
        'col-lg': 12,
        'col-xl': 12
    },
    dominio: {
        key: 'dominio',
        type: 'text',
        tipo: 'dominio',
        mensaje: 'mensajeDominio',
        label: 'Dominio Corporativo :',
        categoria: "valorUnico",
        'col-sm': 12,
        'col-md': 12,
        'col-lg': 12,
        'col-xl': 12
    },
    usuariosPermitidos: {
        key: 'usuariosPermitidos',
        type: 'text',
        tipo: 'usuariosPermitidos',
        mensaje: 'mensajeUsuariosPermitidos',
        label: 'Cantidad de Usuarios Permitidos :',
        categoria: "valorUnico",
        'col-sm': 12,
        'col-md': 12,
        'col-lg': 12,
        'col-xl': 12
    },
    
}

export const glosarioDeInputsParametrizaciones = [
    { padre:undefined, key: 'menuPrincipal', label: 'Menu Principal' },
    { padre:'API581', key: 'rangosAPI581', label: 'Rangos API 581' },
    { padre:'API581', key: 'tabla_4_15_API581', label: 'Tabla 4.15 API 581' },
    { padre:'API581', key: 'tabla_4_17_API581', label: 'Tabla 4.17 API 581' },
    { padre:'API581', key: 'tabla_6_6_API581', label: 'Tabla 6.6 API 581' },
    { padre:'API1160', key: 'rangosAPI1160', label: 'Rangos API 1160' },
    { padre:'API1160', key: 'tabla_4_15_API1160', label: 'Tabla 4.15 API 1160' },
    { padre:'API1160', key: 'tabla_4_17_API1160', label: 'Tabla 4.17 API 1160' },
    { padre:'API1160', key: 'tabla_6_6_API1160', label: 'Tabla 6.6 API 1160' },
    { padre:'API318S', key: 'rangosASME_B31_8S', label: 'Rangos ASME B31.8S' },
    { padre:'API318S', key: 'tabla_4_15_ASME_B31_8S', label: 'Tabla 4.15 ASME B31.8S' },
    { padre:'API318S', key: 'tabla_4_17_ASME_B31_8S', label: 'Tabla 4.17 ASME B31.8S' },
    { padre:'API318S', key: 'tabla_6_6_ASME_B31_8S', label: 'Tabla 6.6 ASME B31.8S' },
]
