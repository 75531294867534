import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import React from 'react';

// Styles:
const baseOverlayStyle = {
  overlay: (base) => ({
    ...base,
    background: '#fff',
    opacity: 0.4,
    borderRadius: 7.5,
  }),
}

const CustomLoadingOverlay = (props) => {
  return (
    <LoadingOverlay
      active={props.active}
      spinner={getSpinner(props)}
      styles={baseOverlayStyle}
      tag={props.tag || 'div'}
    >
      {props.children}
    </LoadingOverlay>
  );
}

const getSpinner = (props) => {
  return (
    <Loader
      active={props.active}
      color='var(--primary)'
      style={{ transform: 'scale(0.8)', ...props.style }}
      type={props.loaderType || 'line-scale-party'}
    />
  );
}

export default CustomLoadingOverlay;