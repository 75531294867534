import React from 'react';

import cx from 'classnames';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { setEnableMobileMenu } from '../../../storage/reducerParaTema.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Componentes auxiliares:
import Logo from './LogoPrincipal.jsx';
import ContenedorDeLinks from './MenuPrincipal.jsx';

class Sidebar extends React.Component {

    state = {};

    toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    }

    render() {
        let { backgroundColor, enableBackgroundImage, enableSidebarShadow, backgroundImage, backgroundImageOpacity } = this.props;

        return (
            <React.Fragment>
                <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-sidebar", backgroundColor, { 'sidebar-shadow': enableSidebarShadow })} transitionName="SidebarAnimation"
                    transitionAppear={true} transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>

                    <Logo />

                    <PerfectScrollbar>
                        <div className="app-sidebar__inner">
                            <ContenedorDeLinks />
                        </div>
                    </PerfectScrollbar>

                    <div
                        className={cx("app-sidebar-bg", backgroundImageOpacity)}
                        style={{ backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null }}>
                    </div>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.reducerParaTema.enableBackgroundImage,
    enableSidebarShadow: state.reducerParaTema.enableSidebarShadow,
    enableMobileMenu: state.reducerParaTema.enableMobileMenu,
    backgroundColor: state.reducerParaTema.backgroundColor,
    backgroundImage: state.reducerParaTema.backgroundImage,
    backgroundImageOpacity: state.reducerParaTema.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({

    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);