import { formatearValorACeroCuandoEsNan } from "./formatearValorACeroCuandoEsNan";
import { formatearvalorsegunestado } from "../../scripts/formatearValorSegunEstado";

const armarDataTablaGrafica = (norma, todosLosSegmentos) => {
    let dataArmada = [];
    let dataOrganizada = [];
    todosLosSegmentos.forEach( item => {
        dataOrganizada.push({
            nombreSegmento: `${item?.informacionGeneral?.nombre?.valor}/${item?.id}/API570/${norma}`, 
            // nombreSegmento: `${item?.informacionGeneral?.nombre.valor}/9AKrQUDKh3UGtqDD2Rpk/API570/${norma}`, 
            pkInicio: formatearvalorsegunestado(item?.variablesDeLaInspeccion?.ubicacion?.inicio?.pk),
            pkFin: formatearvalorsegunestado(item?.variablesDeLaInspeccion?.ubicacion?.fin?.pk),
            etiqueta: item?.variablesDeLaInspeccion?.condicionDelSegmento,
            long: formatearvalorsegunestado(item?.informacionGeneral?.longitud?.valor, 3),
            valor: formatearValorACeroCuandoEsNan(item?.informacionGeneral?.porcentaje?.valor, 2),
            piernasMuertas: item?.variablesDeLaInspeccion?.piernasMuertas?.valor || '-',
        })
    })
    dataOrganizada.sort((a,b) => ((a.pkInicio) > (b.pkInicio)) ? 1 : (((b.pkInicio) > (a.pkInicio))? -1 : 0))
    
    dataOrganizada.forEach(item => {
        dataArmada.push(
            {
                color: "#1E96FC",
                nombreSegmento: item.nombreSegmento, 
                pk: `${item.pkInicio} - ${item.pkFin}`, 
                etiqueta: item.etiqueta,
                long: item.long, 
                valor: item.valor, 
                piernasMuertas: item.piernasMuertas
            }
        )
    })
    return dataArmada;
}

export default armarDataTablaGrafica;