import React from 'react'
import { ResponsiveContainer, ComposedChart, Bar, XAxis, CartesianGrid, YAxis, Legend, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

const objectPath = require("object-path")
var Interval = require('math.interval')

const GraficoDeBarrasDobleAgrupadas = (props) => {
    if (props.keyAgrupamiento2 !== undefined && props.keyAgrupamiento2 !== null) {
        return (
            <div>
                <p style={{ marginBottom: 0, marginLeft: 5, fontSize: '0.75rem' }}>{'Cantidad de ' + props.coleccionActiva}</p>
                <ResponsiveContainer width='100%' aspect={10.0 / 4.0} >
                    <ComposedChart data={ajustarDataDoble(props.data, props.keyAgrupamiento1, props.keyAgrupamiento2, props.keyVariable, props.parametrizacion)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="nombre" />
                        <XAxis dataKey="nombre" axisLine={false} tickLine={false} interval={0}
                            tick={(propsTick) => renderizarAgrupacion(propsTick, obtenerGrupo(props.data, props.keyAgrupamiento1).length,
                                obtenerGrupo(props.data, props.keyAgrupamiento2))} height={1} scale="band" xAxisId="quarter" />
                        <YAxis />
                        <Legend align="right" />
                        <Tooltip content={(info) => TooltipDeDobleAgrupadas(info)} />
                        {obtenerBarras(ajustarDataDoble(props.data, props.keyAgrupamiento1, props.keyAgrupamiento2, props.keyVariable, props.parametrizacion), props.parametrizacion)}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        )
    } else {
        return (
            <div>
                <p style={{ marginBottom: 0, marginLeft: 5, fontSize: '0.75rem' }}>{'Cantidad de ' + props.coleccionActiva}</p>
                <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                    <ComposedChart data={ajustarDataSencillo(props)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="nombre" />
                        <YAxis />
                        <Legend align="right" />
                        <Tooltip />
                        {obtenerBarras(ajustarDataSencillo(props), props.parametrizacion)}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

const obtenerBarras = (data, parametros) => {
    let listaDeBarras = [];
    if (data.length > 0) {
        let listaDeKeys = Object.keys(data[0]);
        for (let k = 0; k < listaDeKeys.length; k++) {
            if (listaDeKeys[k] !== 'nombre' && listaDeKeys[k] !== 'grupo') {
                listaDeBarras.push(
                    <Bar
                        stackId="a"
                        barSize={75}
                        key={listaDeKeys[k]}
                        nombre={listaDeKeys[k]}
                        dataKey={listaDeKeys[k]}
                        fill={obtenerColor(listaDeKeys[k], parametros)}
                    />
                );
            }
        }
    }
    return listaDeBarras;
}

const obtenerColor = (etiqueta, parametros) => {
    let listaDeRangos = Object.keys(parametros)
    for (let i = 0; i < listaDeRangos.length; i++) {
        if (etiqueta === parametros[listaDeRangos[i]].etiqueta) {
            return parametros[listaDeRangos[i]].color;
        }
    }
}

const ajustarDataDoble = (lista, keyDeGrupo1, keyDeGrupo2, keyDeVariable, parametros) => {
    let dataAjustada = [];
    let listaDeEtiquetas = Object.keys(parametros);

    let grupoHorizontal1 = obtenerGrupo(lista, keyDeGrupo1);
    let grupoHorizontal2 = obtenerGrupo(lista, keyDeGrupo2);

    dataAjustada = [];
    for (let j = 0; j < grupoHorizontal2.length; j++) {
        for (let k = 0; k < grupoHorizontal1.length; k++) {
            let objetoDeNivel = {};
            objetoDeNivel['nombre'] = grupoHorizontal1[k];
            objetoDeNivel['grupo'] = grupoHorizontal2[j];
            for (let i = 0; i < listaDeEtiquetas.length; i++) {
                objetoDeNivel[parametros[listaDeEtiquetas[i]].etiqueta] = 0;
            }
            dataAjustada.push(objetoDeNivel)
        }
    }

    for (let i = 0; i < lista.length; i++) {
        for (let k = 0; k < grupoHorizontal1.length; k++) {
            for (let h = 0; h < grupoHorizontal2.length; h++) {
                for (let j = 0; j < listaDeEtiquetas.length; j++) {
                    try {
                        if (objectPath.get(lista[i], keyDeGrupo1) === grupoHorizontal1[k] &&
                            new Interval(listaDeEtiquetas[j]).contains(objectPath.get(lista[i], keyDeVariable)) &&
                            objectPath.get(lista[i], keyDeGrupo2) === grupoHorizontal2[h]) {
                            dataAjustada[k + (h * grupoHorizontal1.length)][parametros[listaDeEtiquetas[j]].etiqueta]++
                            break;
                        }
                    }
                    catch (error) { };
                }
            }
        }
    }

    return dataAjustada
}

const ajustarDataSencillo = (props) => {
    
    const {
        data:lista, 
        keyAgrupamiento1:keyDeGrupo, 
        keyVariable:keyDeVariable,
        parametrizacion:parametros, 
        estado,
        modeloDeConsecuencia
    } = props;
    let dataAjustada = [];
    let listaDeEtiquetas = Object.keys(parametros);

    let grupoHorizontal = obtenerGrupo(lista, keyDeGrupo);
    dataAjustada = [];
    for (let k = 0; k < grupoHorizontal.length; k++) {
        let objetoDeNivel = {};
        objetoDeNivel['nombre'] = grupoHorizontal[k];
        for (let i = 0; i < listaDeEtiquetas.length; i++) {
            objetoDeNivel[parametros[listaDeEtiquetas[i]].etiqueta] = 0;
        }
        dataAjustada.push(objetoDeNivel)
    }
    
    for (let i = 0; i < lista.length; i++) {
        for (let k = 0; k < grupoHorizontal.length; k++) {
            for (let j = 0; j < listaDeEtiquetas.length; j++) {
                //Si el objeto tiene la variable resultados se identifica como datos de valvulas de lo contrario es otra información -> no ingrese aqui.
                if (lista[i].resultados) {
                    lista[i].resultados.forEach(item => {
                        try {
                            if (!estado) {
                                if (objectPath.get(item, (keyDeGrupo + ".apertura"))
                                    && grupoHorizontal[k] === "apertura"
                                    && valoresConsecuencia(listaDeEtiquetas[j], item, keyDeVariable, modeloDeConsecuencia, parametros, "apertura")
                                ) {
                                    dataAjustada[k][parametros[listaDeEtiquetas[j]].etiqueta]++
                                }

                                if (objectPath.get(item, (keyDeGrupo + ".fuga"))
                                    && grupoHorizontal[k] === "fuga"
                                    && valoresConsecuencia(listaDeEtiquetas[j], item, keyDeVariable, modeloDeConsecuencia, parametros, "fuga")
                                ) {
                                    dataAjustada[k][parametros[listaDeEtiquetas[j]].etiqueta]++
                                }
                            }

                            const estados = ['fuga', 'apertura'];
                            if (estados.includes(estado)) {
                                if (objectPath.get(lista[i], keyDeGrupo) === grupoHorizontal[k] &&
                                    valoresConsecuencia(listaDeEtiquetas[j], item, keyDeVariable, modeloDeConsecuencia, parametros, estado)
                                    // new Interval(listaDeEtiquetas[j]).contains(objectPath.get(item, keyDeVariable.replace("changeNombre", estado)))

                                ) {
                                    dataAjustada[k][parametros[listaDeEtiquetas[j]].etiqueta]++
                                }
                            }

                        } catch (e) { console.log(e) }
                    })

                    if (keyDeGrupo.split('.')[0] === "parametrosDeEntrada") {
                        try {
                            if (
                                objectPath.get(lista[i], keyDeGrupo) === grupoHorizontal[k]
                                && objectPath.get(lista[i], keyDeVariable) === listaDeEtiquetas[j]
                            ) {
                                dataAjustada[k][parametros[listaDeEtiquetas[j]].etiqueta]++
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                } else {
                    try {
                        if (objectPath.get(lista[i], keyDeGrupo) === grupoHorizontal[k] &&
                            new Interval(listaDeEtiquetas[j]).contains(objectPath.get(lista[i], keyDeVariable))) {
                            dataAjustada[k][parametros[listaDeEtiquetas[j]].etiqueta]++
                            break;
                        }
                    }
                    catch (error) { };
                }

            }
        }
    }
    if (estado) setTimeout(() => { }, 1000)
    return dataAjustada
}

const valoresConsecuencia = (listaDeEtiquetas, item, keyDeVariable, modeloDeConsecuencia, parametros, tipoDeFalla) =>{
    if(modeloDeConsecuencia === "Cualitativo"){ 
        return parametros[listaDeEtiquetas].valorCualitativo === objectPath.get(item, keyDeVariable.replace("changeNombre", tipoDeFalla));
    }else{
        return new Interval(listaDeEtiquetas).contains(objectPath.get(item, keyDeVariable.replace("changeNombre", tipoDeFalla)))
    }
    
}

const obtenerGrupo = (data, key) => {
    let lista = [];
    let resultado = false;
    data.forEach(item => {
        if (item.resultados) {
            resultado = true;
            if (objectPath.get(item, key)) lista.push(objectPath.get(item, key));
        } else {
            lista.push(objectPath.get(item, key))
        }

    });
    if (resultado && lista.length === 0) return lista = ['fuga', 'apertura']
    lista = [...new Set(lista)];
    return lista;
}

const renderizarAgrupacion = (tickProps, cantidadDeGrupo1, listaGrupo2) => {
    const { y, width, payload } = tickProps;
    const grupo = Math.floor(payload.index / cantidadDeGrupo1);
    const x = payload.index * (width / (cantidadDeGrupo1 * listaGrupo2.length))

    if (payload.index % cantidadDeGrupo1 === 1) {
        return <text x={x + (1.05 * payload.offset)} y={y - 5} textAnchor="left">{listaGrupo2[grupo]}</text>
    }
    return null;
}

const TooltipDeDobleAgrupadas = ({ active, payload, label }) => {
    if (active) {
        try {
            if (payload.length > 0) {
                return (
                    <Card className="card-border">
                        <div style={{ padding: 3, marginBottom: 2, textAlign: 'center', borderBottom: '1px solid rgba(32, 39, 140, 0.125)' }}>
                            <p className="text-capitalize" style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{label}</p>
                            <p style={{ padding: 0, margin: 0, fontSize: '0.75rem' }}>{'(' + payload[0].payload.grupo + ')'}</p>
                        </div>
                        <div style={{ padding: 3, fontSize: '0.75rem' }}>
                            {renderizarTooltipDobleAgrupado(payload)}
                        </div>
                    </Card>
                );
            }
        }
        catch (error) {
            return (
                <React.Fragment />
            );
        }
    }

    return null;
};

const renderizarTooltipDobleAgrupado = (payload) => {
    let listaDeItems = [];
    payload.forEach(item => {
        listaDeItems.push(
            <div key={'item' + item.name} style={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 3 }}>
                <span className='badge badge-pill' style={{ marginRight: 2, backgroundColor: item.color }}> </span>
                <span>{item.name + ' : ' + item.value}</span>
            </div>
        )
    });

    return listaDeItems
}
GraficoDeBarrasDobleAgrupadas.propType = {
    data: PropTypes.arrayOf(PropTypes.object),
    keyAgrupamiento1: PropTypes.string,
    keyAgrupamiento2: PropTypes.string,
    keyVariable: PropTypes.string,
    parametrizacion: PropTypes.object
}

export default GraficoDeBarrasDobleAgrupadas;