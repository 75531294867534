//Conexión con herramientas de firebase:
import * as firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import cloudFunctionLocal from './utilidades/scripts/cloudFunctionLocal';

//Variables ajustables para operación:
let permitirPersistencia = true;
let permitirSalidasPorConsola = false;
let trabajarEnModoDesconexion = false;

// firebase.initializeApp({
//     apiKey: "AIzaSyCgAOKUK9kw6h7NZAWX5Dxiitacq-SmWlA",
//     authDomain: "giacore-dev.firebaseapp.com",
//     databaseURL: "https://giacore-dev.firebaseio.com",
//     projectId: "giacore-dev",
//     storageBucket: "giacore-dev.appspot.com",
//     messagingSenderId: "892856216323",
//     appId: "1:892856216323:web:f013c744064f17fc785736",
//     measurementId: "G-ZQQF2613YX"
// });

firebase.initializeApp({
   apiKey: "AIzaSyDjj5XTMKkJQoQHCaHEj15YVF4Y50oGHtU",
   authDomain: "giacore-25a91.firebaseapp.com",
   databaseURL: "https://giacore-25a91.firebaseio.com",
   projectId: "giacore-25a91",
   storageBucket: "giacore-25a91.appspot.com",
   messagingSenderId: "395268916319",
   appId: "1:395268916319:web:f9b2d175318a790070c7ea",
   measurementId: "G-BESCTQWHTG"
});

// firebase.initializeApp({
//     apiKey: "AIzaSyBXQGS2NXP0hdv35F7EOhk_vbFqNfaxskc",
//     authDomain: "giacore-comm.firebaseapp.com",
//     databaseURL: "https://giacore-comm.firebaseio.com",
//     projectId: "giacore-comm",
//     storageBucket: "giacore-comm.appspot.com",
//     messagingSenderId: "161218663653",
//     appId: "1:161218663653:web:a8e0bba2cfdb6a065b700c",
//     measurementId: "G-DWCMPS8K28"
// });

// firebase.initializeApp({
//     apiKey: "AIzaSyCNUYiUG6zIL0I7iiG3sfITDn7VB5kedpY",
//     authDomain: "giacore-app.firebaseapp.com",
//     databaseURL: "https://giacore-app.firebaseio.com",
//     projectId: "giacore-app",
//     storageBucket: "giacore-app.appspot.com",
//     messagingSenderId: "974830481418",
//     appId: "1:974830481418:web:8ec2604e2ce375b5886ff3",
//     measurementId: "G-RFRT9CWD5H"
// });

//Se configura la persistencia de los datos en firestore:
if (permitirPersistencia) {
    firebase.firestore().enablePersistence()
    // firebase.firestore().enablePersistence({synchronizeTabs:true})
        .then(() => { if (permitirSalidasPorConsola) { console.log("Persistencia activada con éxito") } })
        .catch(error => console.log(error));
}

// Firestore offline mode only:
if (trabajarEnModoDesconexion) {
    firebase.firestore().disableNetwork()
        .then(() => { if (permitirSalidasPorConsola) { console.log("Modo offline activado") } })
        .catch(error => console.log(error));
}

export const firebaseApp = firebase;
export const firestoreAuth = firebase.auth();
export const firestoreDB = firebase.firestore();
export const firebaseFunctions = firebase.functions();
export const firebaseStorage = firebase.storage();

/**
 * Funcion que remplazaría "firebaseFunctions"
 * para poder cambiar entre funcion local y desplegada facimente
 * ejm:
 *  en lugar de hacer:
 *      const funcionDePruebaCallable = firebaseFunctions.httpsCallable("FuncionDePrueba")
 *  usar:
 *      const funcionDePruebaCallable = firebaseFunctionsWithLocalOption("FuncionDePrueba")
 * Segundo parámetro es opcional
 *
 * @param {*} name
 * @param {*} isLocal (opcional) si no se agrega o si es "false" se usaría la cloud desplegada
 * de usar true se usa cloud local tomando como server el declarado en archivo
 * src\constants\serverCloudLocal.js
 * de el servidor ser diferente, es posible cambiarse
 * @returns
 */
 export const firebaseFunctionsWithLocalOption = (name, isLocal) => (dataQwery = {}) => (isLocal ? cloudFunctionLocal({ link: `${process.env.REACT_APP_SERVER_LOCAL_CLOUD}${name}`, dataQwery }) : firebaseFunctions.httpsCallable(name)(dataQwery));

export const googleMapsCredentials = {
    apiKey: "AIzaSyDjj5XTMKkJQoQHCaHEj15YVF4Y50oGHtU",
    googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDjj5XTMKkJQoQHCaHEj15YVF4Y50oGHtU"
}