import { useState } from 'react'
import { firestoreDB } from '../../../../../firebase'


export const useFetchNodo = () => {

    const [datosNodo, setDatosNodo] = useState({})
    const [cargandoNodo, setCargandoNodo] = useState(false)

    /**
     * Hook para consultar un documento de la DB de firebase
     * @param {string} coleccion Colección al cual pertenece el documento
     * @param {string} doc Documento el cual se quiere consultar
     * @return Datos del documento
     */
    const consultarNodo = (coleccion, doc) => {
        try {
            setCargandoNodo(true)
            let promiseConsulta = firestoreDB.doc(`${coleccion}/${doc}`);
            promiseConsulta.get().then(snapShot => {
                setDatosNodo({id: snapShot.id, ...snapShot.data(), referencia: snapShot.ref})
                setCargandoNodo(false)
            });
        }
        catch(error) {console.log(error); setCargandoNodo(false)}   
    }

    return { datosNodo, cargandoNodo, consultarNodo }
}
