import listarValoresSegunRango from './listarValoresSegunRango';

const armarDataParaGraficoDeBarrasScanA = (tipo, nodosCml) => {
    const datosParaGrafica = [];

    const vidaRemanenteMenor = [];
    const vidaRemanenteMedia = [];
    const vidaRemanenteMayor = [];  

    const velocidadCorrosionMenor = [];
    const velocidadCorrosionMedio = [];
    const velocidadCorrosionMayor = [];

    const mawpMenor = [];
    const mawpMedio = [];
    const mawpMayor = [];

    nodosCml.forEach((data, i) => {
        try{
            const resInt = data?.resultadosIntegridad?.vidaRemanente?.valor;
            const dataResInt = listarValoresSegunRango(resInt, 10, 20, 20);

            vidaRemanenteMenor.push(...dataResInt['menor']);
            vidaRemanenteMedia.push(...dataResInt['medio']);
            vidaRemanenteMayor.push(...dataResInt['mayor']);

            const dataVelCor = listarValoresSegunRango(resInt, 2, 4, 4);

            velocidadCorrosionMenor.push(...dataVelCor['menor']);
            velocidadCorrosionMedio.push(...dataVelCor['medio']);
            velocidadCorrosionMayor.push(...dataVelCor['mayor']);

            const dataResMawp = listarValoresSegunRango(resInt, 2, 4, 4);

            mawpMenor.push(...dataResMawp['menor']);
            mawpMedio.push(...dataResMawp['medio']);
            mawpMayor.push(...dataResMawp['mayor']);
        }
        catch(e){console.log(e)} 
    })
    switch(tipo){
        case 'Vida remanente':
            datosParaGrafica.push(
                
                {color: "#66be33", name: "A", cantidad: vidaRemanenteMenor.length|| 0  },
                {color: "#e7961e", name: "B", cantidad: vidaRemanenteMedia.length|| 0  },
                {color: "#d22928", name: "C", cantidad: vidaRemanenteMayor.length || 0 },
                )
            return datosParaGrafica;
        case 'Porcentaje de pérdida':
            datosParaGrafica.push(
                {color: "#66be33", name: "A", cantidad: velocidadCorrosionMenor.length|| 0  },
                {color: "#e7961e", name: "B", cantidad: velocidadCorrosionMedio.length|| 0  },
                {color: "#d22928", name: "C", cantidad: velocidadCorrosionMayor.length || 0 },
                )
            return datosParaGrafica;
        case 'MAWP':
            datosParaGrafica.push(
                {color: "#66be33", name: "A", cantidad: mawpMenor.length|| 0  },
                {color: "#e7961e", name: "B", cantidad: mawpMedio.length|| 0  },
                {color: "#d22928", name: "C", cantidad: mawpMayor.length || 0 },
                )
            return datosParaGrafica;
        default:
        datosParaGrafica.push(
            {color: "#333", name: "No hay datos para mostrar ", cantidad: 0 },
            )
        return datosParaGrafica;
    }
}

export default armarDataParaGraficoDeBarrasScanA