
//Utilidad de functions:
import { firebaseFunctions } from '../../firebase';
const calcularActivo = firebaseFunctions.httpsCallable('calcularActivo');
const cargarCampoEnFirestore = firebaseFunctions.httpsCallable('cargarCampo');
const cargarActivoEnFirestore = firebaseFunctions.httpsCallable('cargarActivo');
const cargarSistemaEnFirestore = firebaseFunctions.httpsCallable('cargarSistema');
const cargarSegmentoEnFirestore = firebaseFunctions.httpsCallable('cargarSegmento');

//Constantes de localización de templates de excel:
const columnaDeCampos = 4;
const columnaDeActivos = 2;
const columnaDeSistemas = 3;
const columnaDeSegmentos = 1;

const primeraFilaConDatos = 9;
const primeraColumnaConVariables = 5;

const filaConElKeyDeLaVariable = 2;
const filaConElScopeDeLaVariable = 1;
const filaConElNombreDeLaVariable = 3;
const filaConElParentDeLaVariable = 0;
const filaConLasUnidadesDeLaVariable = 4;

const filaConNormaTecnicaTarget = 2;
const columnaConNormaTecnicaTarget = 0;

//Funciones auxiliares en el proceso:
const obtenerArrayConDatosEnJSON = (arrayConStringify) => {
  return arrayConStringify.map((item, i) => { return JSON.parse(item); });
}

//Función principal:
export const cargarMatrizDeDatosEnFirestore = (props, matrizDeDatos, callbackParaLog) => {

  return new Promise((resolve, reject) => {
    const permitirCargue = true;
    const listaDeCamposPorCargar = obtenerListaDeCampos(props, matrizDeDatos);
    const listaDeSistemasPorCargar = obtenerListaDeSistemas(props, matrizDeDatos);
    const listaDeActivosPorCargar = obtenerListaDeActivos(props, matrizDeDatos);
    const listaDeSegmentosPorCargar = obtenerListaDeSegmentos(props, matrizDeDatos);

    if (permitirCargue) {
      // Promise de carga de campos:
      cargarCamposDesdeBackend(listaDeCamposPorCargar, callbackParaLog)
        .then((camposCargados) => {
          callbackParaLog("El proceso de carga de los <b>campos terminó con éxito</b> ", "success");
          // Promise de carga de sistemas:
          cargarSistemasDesdeBackend(listaDeSistemasPorCargar, callbackParaLog)
            .then(() => {
              callbackParaLog("El proceso de carga de los <b>campos terminó con éxito</b> ", "success");
              //Promise de carga de activos:
              cargarActivosDesdeBackend(listaDeActivosPorCargar, callbackParaLog)
                .then((activosCargados) => {
                  callbackParaLog("El proceso de carga de los <b>activos terminó con éxito</b> ", "success");
                  // Promise de carga de segmentos:
                  cargarSegmentosDesdeBackend(listaDeSegmentosPorCargar, callbackParaLog)
                    .then(() => {
                      callbackParaLog("El proceso de carga de los <b>segmentos terminó con éxito</b> ", "success");
                      // Cálculo de los activos:
                      calcularActivosDesdeBackend(activosCargados, callbackParaLog)
                        .then(() => {
                          callbackParaLog("El proceso de <b>valoración</b> de los activos terminó con éxito ", "success");
                          resolve(camposCargados[0].informacionGeneral.nombre);
                        })
                        .catch(error => {
                          callbackParaLog("El proceso de <b>valoración</b> de los activos no terminó ", "danger");
                          reject();
                        });

                    })
                    .catch(error => {
                      callbackParaLog("El proceso de carga de los <b>segmentos no terminó</b> ", "danger");
                      reject();
                    });
                })
                .catch(error => {
                  callbackParaLog("El proceso de carga de los <b>activos no terminó</b> ", "danger");
                  reject();
                });
            })
            .catch(error => {
              callbackParaLog("El proceso de carga de los <b>sistemas no terminó</b> ", "danger");
              reject();
            });
        })
        .catch(error => {
          console.log(error);
          callbackParaLog("El proceso de carga de los <b>campos no terminó</b> ", "danger");
          reject();
        });
    }
    else {
      // console.log(listaDeSegmentosPorCargar);
    }

  });
}

//Funciones de carga en Firestore desde Backend, según lista de nodos:
const cargarCamposDesdeBackend = (listaDeCampos, callbackParaLog) => {
  return new Promise((resolve, reject) => {
    callbackParaLog("<b>Comenzó</b> el proceso de carga de los <b>campos</b>", "info");

    let promisesDeCarga = [];
    listaDeCampos.forEach(nodo => {
      promisesDeCarga.push(promiseCustomDeCargaDeNodos("campo " + nodo.nombre, cargarCampoEnFirestore(nodo), callbackParaLog))
    });

    Promise.all(promisesDeCarga)
      .then(nodosCargados => { resolve(nodosCargados); })
      .catch(error => { reject(error); });
  });
}

const cargarSistemasDesdeBackend = (listaDeSistemas, callbackParaLog) => {
  return new Promise((resolve, reject) => {
    callbackParaLog("<b>Comenzó</b> el proceso de carga de los <b>sistemas</b>", "info");

    let promisesDeCarga = [];
    listaDeSistemas.forEach(nodo => {
      promisesDeCarga.push(
        promiseCustomDeCargaDeNodos("sistema: " + nodo.nombre
          + " del campo: " + nodo.cuerpoDelNodo.informacionDeLosPadres.campoAsociado.nombre,
          cargarSistemaEnFirestore(nodo), callbackParaLog))
    });

    Promise.all(promisesDeCarga)
      .then(nodosCargados => { resolve(nodosCargados); })
      .catch(error => { reject(error); });
  });
}

const cargarActivosDesdeBackend = (listaDeActivos, callbackParaLog) => {
  return new Promise((resolve, reject) => {
    callbackParaLog("<b>Comenzó</b> el proceso de carga de los <b>activos</b>", "info");

    let promisesDeCarga = [];
    listaDeActivos.forEach(nodo => {
      promisesDeCarga.push(
        promiseCustomDeCargaDeNodos("activo: " + nodo.nombre
          + " del sistema: " + nodo.cuerpoDelNodo.informacionDeLosPadres.sistemaAsociado.nombre,
          cargarActivoEnFirestore(nodo), callbackParaLog))
    });

    Promise.all(promisesDeCarga)
      .then(nodosCargados => { resolve(nodosCargados); })
      .catch(error => { reject(error); });
  });
}

const cargarSegmentosDesdeBackend = (listaDeSegmentos, callbackParaLog) => {
  return new Promise((resolve, reject) => {
    callbackParaLog("<b>Comenzó</b> el proceso de carga de los <b>segmentos</b>", "info");

    let promisesDeCarga = [];
    listaDeSegmentos.forEach(nodo => {
      promisesDeCarga.push(
        promiseCustomDeCargaDeNodos("segmento: " + nodo.nombre
          + " del activo: " + nodo.cuerpoDelNodo.informacionDeLosPadres.activoAsociado.nombre,
          cargarSegmentoEnFirestore(nodo), callbackParaLog))
    });

    Promise.all(promisesDeCarga)
      .then(nodosCargados => { resolve(nodosCargados); })
      .catch(error => { reject(error); });
  });
}

const calcularActivosDesdeBackend = (listaDeActivos, callbackParaLog) => {
  return new Promise((resolve, reject) => {
    callbackParaLog("<b>Comenzó</b> el proceso de valoración de los <b>activos</b>", "info");

    let promisesDeCarga = [];
    listaDeActivos.forEach(nodo => {
      promisesDeCarga.push(
        promiseCustomDeCalculoDeActivos("activo: " + nodo.informacionGeneral.nombre.valor,
          calcularActivo({ "id": nodo.id, "dominioCorporativo": nodo.dominioCorporativo }), callbackParaLog))
    });

    Promise.all(promisesDeCarga)
      .then(nodosCargados => { resolve(nodosCargados); })
      .catch(error => { reject(error); });
  });
}

//Funciones para lectura y procesamiento de matriz de datos:
const obtenerListaDeCampos = (props, matrizDeDatos, imprimirListaBase = false) => {
  let listaTemporal = [];
  for (let i = primeraFilaConDatos; i < matrizDeDatos.length; i++) {
    listaTemporal.push(JSON.stringify({
      nombre: matrizDeDatos[i][columnaDeCampos],
      precondicionesDeCarga: {
        "dominioCorporativo": props.usuarioActivo.dominioCorporativo
      },
      cuerpoDelNodo: {
        "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
        "informacionGeneral": { "nombre": matrizDeDatos[i][columnaDeCampos] }
      }
    }));
  }

  if (imprimirListaBase) {
    console.log(listaTemporal);
  }
  let arraySinRepetidos = [...new Set(listaTemporal)];
  return obtenerArrayConDatosEnJSON(arraySinRepetidos);
}

const obtenerListaDeSistemas = (props, matrizDeDatos, imprimirListaBase = false) => {
  let listaTemporal = [];
  for (let i = primeraFilaConDatos; i < matrizDeDatos.length; i++) {
    listaTemporal.push(JSON.stringify({
      nombre: matrizDeDatos[i][columnaDeSistemas],
      precondicionesDelPadre: {
        "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
        "informacionGeneral.nombre": matrizDeDatos[i][columnaDeCampos]
      },
      cuerpoDelNodo: {
        "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
        "informacionGeneral": { "nombre": matrizDeDatos[i][columnaDeSistemas] },
        "informacionDeLosPadres": { "campoAsociado": { "nombre": matrizDeDatos[i][columnaDeCampos] } }
      }
    }));
  }

  if (imprimirListaBase) { console.log(listaTemporal); }
  let arraySinRepetidos = [...new Set(listaTemporal)];
  return obtenerArrayConDatosEnJSON(arraySinRepetidos);
}

const obtenerListaDeActivos = (props, matrizDeDatos, imprimirListaBase = false) => {
  let listaTemporal = [];
  for (let i = primeraFilaConDatos; i < matrizDeDatos.length; i++) {
    const keyDelModuloTarget = matrizDeDatos[filaConNormaTecnicaTarget][columnaConNormaTecnicaTarget];

    let cuerpoDelNodoTemporal = {
      ...obtenerObjetoDePropiedades(matrizDeDatos, matrizDeDatos[i], "activos"),
      "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
      "informacionDeLosPadres": {
        "campoAsociado": { "nombre": matrizDeDatos[i][columnaDeCampos] },
        "sistemaAsociado": { "nombre": matrizDeDatos[i][columnaDeSistemas] }
      },
      "modulos": {
      }
    };

    cuerpoDelNodoTemporal.modulos[keyDelModuloTarget] = true;

    listaTemporal.push(JSON.stringify({
      nombre: matrizDeDatos[i][columnaDeActivos],
      precondicionesDelPadre: {
        "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
        "informacionGeneral.nombre": matrizDeDatos[i][columnaDeSistemas],
        "informacionDeLosPadres.campoAsociado.nombre": matrizDeDatos[i][columnaDeCampos]
      },
      cuerpoDelNodo: cuerpoDelNodoTemporal
    }));
  }

  if (imprimirListaBase) {
    console.log(listaTemporal);
  }
  // console.log(listaTemporal)

  let arraySinRepetidos = [...new Set(listaTemporal)];
  return obtenerArrayConDatosEnJSON(arraySinRepetidos);
}

const obtenerListaDeSegmentos = (props, matrizDeDatos, imprimirListaBase = false) => {
  let listaTemporal = [];
  for (let i = primeraFilaConDatos; i < matrizDeDatos.length; i++) {
    const keyDelModuloTarget = matrizDeDatos[filaConNormaTecnicaTarget][columnaConNormaTecnicaTarget];

    let cuerpoDelNodoTemporal = {
      ...obtenerObjetoDePropiedades(matrizDeDatos, matrizDeDatos[i], "segmentos"),
      "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
      "informacionDeLosPadres": {
        "campoAsociado": { "nombre": matrizDeDatos[i][columnaDeCampos] },
        "sistemaAsociado": { "nombre": matrizDeDatos[i][columnaDeSistemas] },
        "activoAsociado": { "nombre": matrizDeDatos[i][columnaDeActivos] },
      },
      "modulos": {
      }
    }

    cuerpoDelNodoTemporal.modulos[keyDelModuloTarget] = true;

    listaTemporal.push(JSON.stringify({
      nombre: matrizDeDatos[i][columnaDeSegmentos],
      precondicionesDelPadre: {
        "dominioCorporativo": props.usuarioActivo.dominioCorporativo,
        "informacionDeLosPadres.campoAsociado.nombre": matrizDeDatos[i][columnaDeCampos],
        "informacionDeLosPadres.sistemaAsociado.nombre": matrizDeDatos[i][columnaDeSistemas],
        "informacionGeneral.nombre.valor": matrizDeDatos[i][columnaDeActivos]
      },
      cuerpoDelNodo: cuerpoDelNodoTemporal,
    }));
  }

  if (imprimirListaBase) { console.log(listaTemporal); }
  let arraySinRepetidos = [...new Set(listaTemporal)];
  return obtenerArrayConDatosEnJSON(arraySinRepetidos);
}

//Función para iterar la fila de cada segmento/activo y setear las propiedades en el objeto resultante:
const obtenerObjetoDePropiedades = (matrizDeDatos, filaTarget, scope) => {
  let objetoResultante = {
    "informacionGeneral": {},
    "variablesDeLaInspeccion": {}
  };

  if (scope === "segmentos") {
    objetoResultante["informacionGeneral"]["nombre"] = { "valor": filaTarget[columnaDeSegmentos] };
    objetoResultante["variablesDeLaInspeccion"]["mecanismosPorEvaluar"] = ["corrosionInterna", "corrosionExterna"];
  }

  if (scope === "activos") {
    objetoResultante["informacionGeneral"]["nombre"] = { "valor": filaTarget[columnaDeActivos] };
  }

  for (let k = primeraColumnaConVariables; k < filaTarget.length; k++) {
    try {
      if (matrizDeDatos[filaConElScopeDeLaVariable][k].includes(scope)) {
        // if (matrizDeDatos[filaConElKeyDeLaVariable][k] === 'densidadDePoblacion') console.log('densidadDePoblacion', filaTarget[k])
        objetoResultante[matrizDeDatos[filaConElParentDeLaVariable][k]][matrizDeDatos[filaConElKeyDeLaVariable][k]] = {
          "valor": filaTarget[k],
          "nombre": matrizDeDatos[filaConElNombreDeLaVariable][k],
          "unidades": matrizDeDatos[filaConLasUnidadesDeLaVariable][k]
        };
      }
    }
    catch (error) { };
  }
  return objetoResultante;
}

//Función para obtenerPromise cuyo resolve ejecute también un callback:
const promiseCustomDeCargaDeNodos = (descripcionDelNodo, promiseOriginal, callbackFunction) => {
  // Posibles status:
  // NODO_CREADO_CON_EXITO
  // NODO_EXISTENTE
  // ERROR_DE_PRECONDICIONES

  return new Promise((resolve, reject) => {
    promiseOriginal
      .then(serverResponse => {
        try {
          let responseData = serverResponse.data;
          switch (responseData.status) {
            case "NODO_CREADO_CON_EXITO":
              callbackFunction("La carga del <b>" + descripcionDelNodo + "</b> se completó exitosamente", "success");
              resolve(responseData.nodo);
              break;

            case "NODO_EXISTENTE":
            case "NODO_ACTUALIZADO_CON_EXITO":
              callbackFunction("El <b>" + descripcionDelNodo + "</b> ya existía en los datos, y se reemplazó", "warning");
              resolve(responseData.nodo);
              break;

            case "ERROR_ACTUALIZANDO_NODO":
              callbackFunction("El <b>" + descripcionDelNodo + "</b> ya existía en los datos, y no pudo reemplazarse", "danger");
              resolve(responseData.nodo);
              break;

            case "ERROR_DE_PRECONDICIONES":
              callbackFunction("Las precondiciones de carga del <b>" + descripcionDelNodo + "</b> no se cumplieron, luego no se cargó.", "danger");
              reject(responseData.error);
              break;

            case "UNAUTHENTICATED":
              callbackFunction("La carga del <b>" + descripcionDelNodo + "</b> falló por un error de permisos", "danger");
              reject(responseData.error);
              break;

            default:
              reject(responseData.error);
              break;
          }
        }
        catch (error) {
          callbackFunction("La carga del <b>" + descripcionDelNodo + "</b> falló por un error inesperado en la interpretación del error del response", "danger");
          reject(error);
        }
      })
      .catch(error => {
        callbackFunction("La carga del <b>" + descripcionDelNodo + "</b> falló por un error inesperado en el servidor", "danger");
        reject(error);
      });
  });
}

const promiseCustomDeCalculoDeActivos = (descripcionDelNodo, promiseOriginal, callbackFunction) => {
  return new Promise((resolve, reject) => {
    promiseOriginal
      .then(serverResponse => {
        try {
          let responseData = serverResponse.data;

          switch (responseData.status) {
            case "ACTIVO_VALORADO_&_ACTUALIZADO":
            case "ACTIVO_VALORADO_&_NO_ACTUALIZADO":
              callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> se completó exitosamente", "success");
              resolve(responseData.nodo);
              break;

            case "ACTIVO_VALORADO_&_ACTUALIZADO_PARCIALMENTE":
              callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> se completó exitosamente en algunos módulos. Otros fallaron", "warning");
              resolve(responseData.nodo);
              break;

            case "ERROR_ACTIVO_NO_VALORADO":
              callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> falló, pues uno de sus segmentos no pudo ser valorado", "danger");
              resolve(responseData.nodo);
              break;

            case "ERROR_AL_BUSCAR_SEGMENTOS":
              callbackFunction("Las precondiciones de valoración del <b>" + descripcionDelNodo + "</b> no se cumplieron, luego no se calculó", "danger");
              reject();
              break;

            case "UNAUTHENTICATED":
              callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> falló por un error de permisos", "danger");
              reject();
              break;

            default:
              callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> falló por un error desconocido", "danger");
              reject();
              break;
          }
        }
        catch (error) {
          console.log(error);
          callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> falló por un error inesperado en la interpretación del error del response", "danger");
          reject();
        }
      })
      .catch(error => {
        if (error.toString().includes('deadline-exceeded')) {
          callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> está tardando más de lo esperado. Puedes seguir navegando mientras el proceso termina", "warning");
          resolve();
        } else {
          console.log(error);
          callbackFunction("La valoración del <b>" + descripcionDelNodo + "</b> falló por un error inesperado en el servidor", "danger");
          reject();
        }
      });
  });
}
