import React, { Component } from 'react'
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Legend } from 'recharts'
import PropTypes from 'prop-types'

class GraficoDeTortaFormatos extends Component {
    constructor() {
        super();
        this.state = {
            activeIndex: 0,
            data: []
        }
    }

    componentDidMount() {
        this.setState({ data: 
            this.props.parametrizacion
         })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.parametrizacion !== this.props.parametrizacion) {
            this.setState({ data: 
                this.props.parametrizacion
             });
        }
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    renderColorfulLegendText = (value) => {
        return <span style={{ fontWeight: '100' }}>{value}</span>;
    }
    uppeCasetoLowerCase = (texto) => {
        let textSize = texto.length
        let nuevoTexto = texto[0]
        for (let n = 1; n < textSize; n++) {
            nuevoTexto = nuevoTexto.concat(texto[n].toLowerCase())
        }
        return nuevoTexto
    } 

    render() {
            return (
                <ResponsiveContainer height='30%' aspect={10.0 / 7} >
                    <PieChart style={{ backgroundColor: "rgb(252,252,252)", borderRadius: 5 }}>
                        <Legend
                            formatter={this.renderColorfulLegendText}
                            iconSize={14}
                            onMouseEnter={this.onPieEnter}
                            verticalAlign={'bottom'}
                            payload={
                                this.state.data.map(
                                    item => ({
                                        id: item.etiqueta,
                                        type: "circle",
                                        color: item.color,
                                        value: `${this.uppeCasetoLowerCase(item.etiqueta)}`,
                                    })
                                )
                                
                            }
                        />
                        <Pie
                            activeIndex={this.state.activeIndex}
                            activeShape={(activeProps) => renderizarMouseOver(activeProps, this.state.data)}
                            data={this.state.data}
                            cx={"50%"}
                            cy={"50%"}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#b0b0b0"
                            dataKey="valor"
                            onMouseEnter={this.onPieEnter}
                        >
                            {this.state.data.map((data) => <Cell key={'estado' + data.etiqueta} fill={data.color} />)}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            )
        
    }
}

const renderizarMouseOver = (props, info) => {
    const radianes = Math.PI / 180;
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        payload, percent, valor, fill
    } = props;
    const sin = Math.sin(-radianes * midAngle);
    const cos = Math.cos(-radianes * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
        <g>
            <text x={cx}
                y={cy}
                dy={8}
                textAnchor="middle"
                fill='#525252'
                style={{ fontWeight: "normal" }}>
                {payload.etiqueta}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={obtenerColor(info, payload.etiqueta)}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={obtenerColor(info, payload.etiqueta, true)}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#525252">{valor}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={obtenerColor(info, payload.nombre)}>
                {'(' + (percent * 100).toFixed(1) + ' %)'}
            </text>
        </g>
    );
}

const obtenerColor = (info, nombre, ponerOpacidad) => {
    ponerOpacidad = ponerOpacidad === undefined ? false : true;
    for (let k = 0; k < info.length; k++) {
        if (info[k].etiqueta === nombre) {
            return ponerOpacidad ? info[k].color + 'B3' : info[k].color
        }
    }
}


GraficoDeTortaFormatos.propTypes = {
    parametrizacion: PropTypes.array
}

export default GraficoDeTortaFormatos;