import React from 'react'
import LinkConHistory from '../../../componentes/LinkConHistory';
import { Grid } from '@material-ui/core';
import { isEmpty, values } from 'lodash';
import { ESTADO_PRD, TE, TV ,TDP,TDM, SIN_VALORAR } from '../../../scripts/constantes';
// MODIFICAR LABELS : https://github.com/gregnb/mui-datatables#localization

const columns = ({ moduloTarget })=> [
  {
    //name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.TAG",
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.dependencia",
    label: "Dependencia",
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.campo",
    label: "Campo",
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
    label: "Planta / Estación",
  },
  
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.servOsistem",
    label: "Servicio / Sistema",
  },
  {
    name: "parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral.tipoDeEquipo",
    label: "Tipo de equipo protegido",
    options: {
      filterType: 'multiselect',
      filterOptions:{names:values(TE)}
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.TAG",
    label: "TAG",
    options: {
      filterType: 'textField',
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Grid container justify="center" >
            <LinkConHistory to={`/app/detalleInventario/prd/${tableMeta.rowData[11]}/${moduloTarget}`} >
              {value}
            </LinkConHistory>
          </Grid>
        );
      },
    }
  },
  {
    name: "parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral.TAGEquipoProtegido",
    label: "TAG equipo protegido",
    options: {
      filterType: 'textField',
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.codActivo",
    label: "Código de activo",
    options: {
      filterType: 'textField',
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.id",
    label: "Id (Placa)",
    options: {
      filterType: 'textField',
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.tipoDeValvula",
    label: "Tipo de válvula",
    options: {
      filterType: 'multiselect',
      filterOptions: { names: values(TV) }
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionGeneral.estado",
    label: "Estado",
    options: {
      filterType: 'multiselect',
      filterOptions: { names: values(ESTADO_PRD) }
    }
  },
  {
    name: "id",
    label: " ",
    options: {
      display: false,
      viewColumns: false,
      filterType: 'checkbox',
      filterOptions: {
        names: [TDP.SIN_PRUEBA, TDM.SIN_MANT, SIN_VALORAR],
        logic: (id, filters, row) => {
          let filtrable = false;
          if (filters.includes(TDP.SIN_PRUEBA)) {
            const tipoDePrueba = row[12]
            if(!(isEmpty(tipoDePrueba) || tipoDePrueba === TDP.SIN_PRUEBA))filtrable = true;
          }
          if (filters.includes(TDM.SIN_MANT)) {
            const tipoDeMantenimiento = row[13]
            if(!(isEmpty(tipoDeMantenimiento) || tipoDeMantenimiento === TDM.SIN_MANT))filtrable = true 
          }
          if (filters.includes(SIN_VALORAR)) {
            const valorada = row[14]
            filtrable = filtrable || !!valorada
        }
          return filtrable
        }
      }
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.tipoDePrueba",
    label: "Tipo de prueba",
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    }
  },
  {
    name: "parametrosDeEntrada.informacionDePRD.informacionDeLaInspeccion.mantenimiento",
    label: "Mantenimiento",
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    }
  },
  {
    name: "estados.valorada",
    label: "Valoración",
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    }
  },

];

export default columns;
/*
Dependencia
Planta / Estación
Sistema	SUBSISTEMA/
Equipo	- TIPO DE EQUIPO?
TAG
# De Activo / Código
# DE INVENTARIO
TIPO
*/