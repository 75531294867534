export const opcionesParaGraficasCualitativas = [
    {
        value: "dobleAgrupamiento",
        label: "Doble agrupamiento",
        opciones: [
            {
                value: "riesgo;tipoDeActivo",
                label: "Riesgo por tipo de falla",
                keyAgrupamiento1: "data.riesgo",
                keyAgrupamiento2: null,
                keyVariable: "data.riesgo.changeNombre.valorCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "probabilidad;tipoDeActivo",
                label: "Probabilidad por tipo de falla",
                keyAgrupamiento1: "data.probabilidad",
                keyAgrupamiento2: null,
                keyVariable: "data.probabilidad.changeNombre.nivelDeProbabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
            {
                value: "consecuencia;tipoDeActivo",
                label: "Consecuencia por tipo de falla",
                keyAgrupamiento1: "data.consecuencia",
                keyAgrupamiento2: null,
                keyVariable: "data.consecuencia.changeNombre.maximaCOF",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "inventario;valvula",
                label: "Inventario por tipo de válvula",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "parametrosDeEntrada.informacionDePRD.informacionDeDiseñoyConstruccion.tipoDeValvula",
                comParametrizacion:{
                    "Convencional balanceada":{
                        "color":"#66be33",
                        "etiqueta":"Convencional balanceada",
                    },
                    "Convencional resortada":{
                        "color":"#6690FF",
                        "etiqueta":"Convencional resortada",
                    },
                    "Pilotada":{
                        "color":"#f1ca1c",
                        "etiqueta":"Pilotada",
                    },
                    "Disco de rotura":{
                        "color":"#e7961c",
                        "etiqueta":"Disco de rotura",
                    }, 
                    "Presión y vacío":{
                        "color":"#d21e1e",
                        "etiqueta":"Presión y vacío",
                    },           
                },
            },
            {
                value: "inventario;equipoProtegido",
                label: "Inventario de equipos protegidos",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "parametrosDeEntrada.informacionEquipoProtegido.informacionGeneral.tipoDeEquipo",
                comParametrizacion:{
                    "Compressor":{
                        "color":"#66be33",
                        "etiqueta":"Compressor",
                    },
                    "Heat exchanger":{
                        "color":"#f1ca1c",
                        "etiqueta":"Heat",
                    },
                    "Pipe":{
                        "color":"#e7961c",
                        "etiqueta":"Pipe",
                    },
                    "Pump":{
                        "color":"#d21e1e",
                        "etiqueta":"Pump",
                    },
                    "Tank":{
                        "color":"#6690FF",
                        "etiqueta":"Tank",
                    },
                    "Vessel/FinFan":{
                        "color":"#A266FF",
                        "etiqueta":"Vessel/FinFan",
                    }                  
                },
            },
            {
                value: "probabilidad;plantaOestacionFuga",
                label: "Probabilidad por planta/estación fuga",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "data.probabilidad.changeNombre.nivelDeProbabilidad",
                estado:"fuga",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
            {
                value: "probabilidad;plantaOestacionApertura",
                label: "Probabilidad por planta/estación apertura",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "data.probabilidad.changeNombre.nivelDeProbabilidad",
                estado:"apertura",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
            {
                value: "consecuencia;plantaOestacionFuga",
                label: "Consecuencia por planta/estación fuga",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "data.consecuencia.changeNombre.maximaCOF",
                estado:"fuga",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "consecuencia;plantaOestacionApertura",
                label: "Consecuencia por planta/estación apertura",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "data.consecuencia.changeNombre.maximaCOF",
                estado:"apertura",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "riesgo;plantaOestacionApertura",
                label: "Riesgo por planta/estación apertura",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "data.riesgo.changeNombre.valorCualitativo",
                estado:"apertura",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "riesgo;plantaOestacionFuga",
                label: "Riesgo por planta/estación fuga",
                keyAgrupamiento1: "parametrosDeEntrada.informacionDePRD.informacionGeneral.plantaOestacion",
                keyAgrupamiento2: null,
                keyVariable: "data.riesgo.changeNombre.valorCualitativo",
                estado:"fuga",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
        ]
    },
    {
        value: "tortaFuga", // Fuga
        label: "Torta",
        opciones: [
            {
                value: "riesgo",
                label: "Riesgo fuga",
                keyVariable: "data.riesgo.fuga.valorCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "consecuencia",
                label: "Consecuencia fuga",
                keyVariable: "data.consecuencia.fuga.maximaCOF",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "probabilidad",
                label: "Probabilidad fuga",
                keyVariable: "data.probabilidad.fuga.nivelDeProbabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
        ]
    },
    {
        value: "tortaApertura", // Apertura
        label: "Torta",
        opciones: [
            {
                value: "riesgo",
                label: "Riesgo apertura",
                keyVariable: "data.riesgo.apertura.valorCualitativo",
                keyDeParametrizacion: "rangosDeRiesgo",
            },
            {
                value: "consecuencia",
                label: "Consecuencia apertura",
                keyVariable: "data.consecuencia.apertura.maximaCOF",
                keyDeParametrizacion: "rangosDeConsecuencia",
            },
            {
                value: "probabilidad",
                label: "Probabilidad apertura",
                keyVariable: "data.probabilidad.apertura.nivelDeProbabilidad",
                keyDeParametrizacion: "rangosDeProbabilidad",
            },
        ]
    },
]