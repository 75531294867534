import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

//Icons:
import { BsTable } from 'react-icons/bs';
import { FaRedoAlt, FaCloudUploadAlt, FaCloudDownloadAlt,FaMapPin } from 'react-icons/fa';
import { GiJigsawPiece, GiFuelTank } from 'react-icons/gi';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TiFilter } from 'react-icons/ti';

//Componentes auxiliares:
import BotonParaSection from '../plantillaBase/BotonParaSection.jsx';
import CardConEncabezado from '../plantillaBase/CardConEncabezado.jsx';
import CardConGrafica from './CardConGrafica.jsx';
import ConsolaDeCargue from './ConsolaDeCargue.jsx';
import ConsolaDeDescarga from './ConsolaDeDescarga.jsx';
import ConsolaDeDescargaPRD from './ConsolaDeDescargaPRDFC.jsx';
import CustomSection from '../plantillaBase/CustomSection.jsx';
import ManagerDeFiltros from './ManagerDeFiltros.jsx';
import MatrizDeRiesgo from './MatrizDeRiesgo.jsx';
import MapaDeActivo from '../../../vistas/aplicacion/analisis/riesgo/visualizadorDeNodos/componentes/MapaDeActivo.jsx';
import Preloader from '../plantillaBase/Preloader.jsx';
import TablaDeNodos from './TablaDeNodos.jsx';
import SpanDeCampo from './SpanDeCampo.jsx';

//Utilidad de base de datos:
import { firestoreDB } from '../../../firebase';

//Scripts auxiliares:
import { confirmacionDeActualizacionDeNodos } from '../../almacen/alertasParaSwal';
import { obtenerElementosQueCumplanConLasCondiciones } from '../../scripts/paraCondicionalesMultiples';
import { obtenerSegmentosFiltrados } from '../../scripts/paraFiltrarSegmentos';
import { obtenerNodosConSubcolecciones } from '../../scripts/paraConsultaDeNodos.js';
import { actualizarDataApi581PRD } from '../../../storage/reducerParaData.js';
import { MODO_DES } from '../../../vistas/aplicacion/gestion/api581Valvulas/scripts/constantes';

//Estilos base:
const estilosParaResumenesEnTop = { marginBottom: 2.5 };

class ResumenDeModulo extends React.Component {

    constructor() {
        super();

        this.state = {
            cargando: false,
            nodosDescargadosLaPrimeraVez: false,

            campoTarget: undefined,
            coleccionActiva: "activos",
            filtrosActivos: {},
            nodosFiltrados: [],
            segmentosFiltrados: [],
            todosLosActivos: [],
            todosLosSegmentos: [],

            laConsolaDeCargueEstaAbierta: false,
            laConsolaDeDescartaEstaAbierta: false,
            laConsolaDeFiltrosEstaAbierta: false,

            mostrarLabelDeDescargaFallida: false,
            mostrarLabelDeDescargaEnProceso: false,

            keyDeRenderizado: 0
        }
    }

    render() {
        return (
            <React.Fragment>
                <CustomSection
                    titulo={this.props.titulo}
                    subtitulo={this.props.subtitulo}
                    URLIconoPrincipal="/assets/img/logos/giaCore-logo.png"
                    botonesAdicionales={[this.obtenerBotonDeActualizacion(), this.obtenerBotonDeFiltros(),
                    this.obtenerBotonDeDescargaDeData(), this.obtenerBotonDeCargueMasivo(), this.obtenerBotonDeCambioDeColeccion()]}>

                    {!this.props.isChildren && <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                        <p style={{ ...estilosParaResumenesEnTop, display: ((this.state.campoTarget) ? "unset" : "none") }}>
                            {this.state.cargando
                                ? "Descargando "
                                : "Estoy viendo los "
                            }
                            <b>{this.state.coleccionActiva}</b>
                            {" del campo "}
                            <b>
                                <SpanDeCampo
                                    campoTarget={this.state.campoTarget}
                                    usuarioActivo={this.props.usuarioActivo}
                                    onChange={(nuevoCampoTarget) => { this.actualizarCampoTarget(nuevoCampoTarget); }}
                                    // permitirConsultaInicial={!this.props.nodosEnRedux.nodosDescargados}
                                    permitirConsultaInicial={true}
                                />
                            </b>
                        </p>

                        <p style={{ ...estilosParaResumenesEnTop, display: ((!this.state.campoTarget) ? "unset" : "none") }}>
                            Obteniendo los <b>posibles campos</b>...
                        </p>

                        {this.obtenerMensajeDeDescargaFallida()}
                        {this.obtenerMensajeDeLimiteDeDescargas()}
                    </div>}

                    <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                        {this.obtenerMensajeDeDescargaEnProceso()}
                    </div>

                   
                    {this.props.isChildren && this.props.children()}
                    {this.props.parametrizacionParaPRD ? this.props.parametrizacionParaPRD(this.props.parametrizacionCorporativa,this.props.usuarioActivo.dominioCorporativo,this.props.storeStatePRD,this.props.actualizarDataApi581PRD) : <React.Fragment></React.Fragment>}
                    {this.obtenerContenidoPrincipal()}
                </CustomSection>

                {/* {this.renderConsolaDeCargue()} */}
                <ConsolaDeCargue
                    toggleDelModal={this.toggleDeLaConsolaDeCargue}
                    elModalEstaAbierto={this.state.laConsolaDeCargueEstaAbierta}
                    callbackFunction={(e) => { this.actualizarCampoTarget(e) }} 
                    isPRD={this.props.datosDePRD ? true: false}
                    consultarDatosPRD={this.props.consultarDatosPRD}
                    />
                    
                <ManagerDeFiltros
                    agregarFiltro={this.agregarFiltro}
                    desactivarFiltro={this.desactivarFiltro}
                    filtrosActivos={this.state.filtrosActivos}
                    toggleDelModal={this.toggleDeLaConsolaDeFiltros}
                    actualizarValorDeFiltro={this.actualizarValorDeFiltro}
                    elModalEstaAbierto={this.state.laConsolaDeFiltrosEstaAbierta}
                    categorias={this.props.categoriasDeFiltrado} />

                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (this.props.permitirConsultas) {
            const nodosDeRedux = this.props.nodosEnRedux;
            if (!nodosDeRedux.nodosDescargados) {
                return this.setState({
                    limiteDeDescargas: nodosDeRedux['limiteDeDescargas'],
                });
            }
            this.setState({
                nodosDescargadosLaPrimeraVez: true,

                todosLosActivos: nodosDeRedux["activos"],
                todosLosSegmentos: nodosDeRedux["segmentos"],
                segmentosFiltrados: nodosDeRedux["segmentos"],

                todosLosNodos: nodosDeRedux[nodosDeRedux['coleccionActiva']],
                nodosFiltrados: nodosDeRedux[nodosDeRedux['coleccionActiva']],

                campoTarget: nodosDeRedux['campoActivo'],
                coleccionActiva: nodosDeRedux['coleccionActiva'],
                limiteDeDescargas: nodosDeRedux['limiteDeDescargas'],
            });
        }
        if(this.props.isChildren){
            this.setState({
                nodosDescargadosLaPrimeraVez:true,
                todosLosNodos: this.props.todosLosNodosPRD,
            })
        }

    }

    componentDidUpdate(prevenProps, prevenState){
        if(prevenProps !== this.props){
            if(this.props.isChildren){
                this.setState({
                    todosLosNodos:this.props.todosLosNodosPRD,
                })
            }
        }
    }


    //Métodos para descarga de datos:
    actualizarNodos = async () => {
        if(this.props.datosDePRD){
            if(this.props.datosDePRD[0]){
                try{
                    this.props.onActualizarDatosPRD(this.props.datosDePRD[0].parametrosDeEntrada.modeloDeConsecuencia,undefined,true);
                }catch(e){
                    window.location.reload();
                }
            }else{
                window.location.reload();
            }
            
        }else{
            if (this.props.permitirConsultas) {
                this.setState({
                    cargando: true
                });
                let promiseDeConsultaDeActivos = firestoreDB.collection("activos");
                promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
                promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("informacionDeLosPadres.campoAsociado.nombre", "==", this.state.campoTarget);
                promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.where("modulos." + this.props.moduloTarget, "==", true);
                if (this.state.limiteDeDescargas !== undefined) { promiseDeConsultaDeActivos = promiseDeConsultaDeActivos.limit(this.state.limiteDeDescargas); }
    
                let promiseDeConsultaDeSegmentos = firestoreDB.collection("segmentos");
                promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("dominioCorporativo", "==", this.props.usuarioActivo.dominioCorporativo);
                promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("informacionDeLosPadres.campoAsociado.nombre", "==", this.state.campoTarget);
                promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.where("modulos." + this.props.moduloTarget, "==", true);
                if (this.state.limiteDeDescargas !== undefined) { promiseDeConsultaDeSegmentos = promiseDeConsultaDeSegmentos.limit(this.state.limiteDeDescargas); }
    
                try {
                    let todosLosActivos = await obtenerNodosConSubcolecciones(promiseDeConsultaDeActivos, this.props.moduloTarget, true, true);
                    let todosLosSegmentos = await obtenerNodosConSubcolecciones(promiseDeConsultaDeSegmentos, this.props.moduloTarget, true, true);
                    
                    todosLosActivos = this.limpiarVariablesInnecesarias(todosLosActivos);
                    todosLosSegmentos = this.limpiarVariablesInnecesarias(todosLosSegmentos);
    
                    let nodosDeLaColeccionActiva = (this.state.coleccionActiva === "activos") ? todosLosActivos : todosLosSegmentos;
                    this.setState({
                        todosLosActivos: todosLosActivos,
                        todosLosSegmentos: todosLosSegmentos,
                        segmentosFiltrados: todosLosSegmentos,
    
                        todosLosNodos: nodosDeLaColeccionActiva,
                        nodosFiltrados: nodosDeLaColeccionActiva,
    
                        nodosDescargadosLaPrimeraVez: true
                    }, () => {
                        this.actualizarDataEnReduxStorage();
                    });
    
                    setTimeout(() => { this.setState({ cargando: false, keyDeRenderizado: this.state.keyDeRenderizado + 1 }); }, 1000);
                }
                catch (error) {
                    console.log(error);
                    this.setState({ cargando: false });
                    window.document.body.style.cursor = "default";
                }
            }
            else{
                if(this.props.nodosParaDesarrollo && Object.keys(this.props.nodosParaDesarrollo).length > 0){
                    this.setState({
                        todosLosActivos: this.props.nodosParaDesarrollo["todosLosActivos"],
                        todosLosSegmentos: this.props.nodosParaDesarrollo["todosLosSegmentos"],
                        segmentosFiltrados: this.props.nodosParaDesarrollo["todosLosSegmentos"],
        
                        todosLosNodos: this.props.nodosParaDesarrollo["todosLosActivos"],
                        nodosFiltrados: this.props.nodosParaDesarrollo["todosLosActivos"],
        
                        nodosDescargadosLaPrimeraVez: true,
                    });
                }
                
            }
        }
        
    }

    actualizarCampoTarget = (nuevoCampoTarget) => {
        try { this.setState({ campoTarget: nuevoCampoTarget }, () => { this.actualizarNodos(); }); }
        catch (error) { };
    }

    actualizarDataEnReduxStorage = () => {
        this.props.metodoParaActualizarDataEnRedux({
            "nodosDescargados": true,
            "campoActivo": this.state.campoTarget,
            "activos": this.state.todosLosActivos,
            "segmentos": this.state.todosLosSegmentos,
            "coleccionActiva": this.state.coleccionActiva,
            "limiteDeDescargas": this.state.limiteDeDescargas
        });
    }

    limpiarVariablesInnecesarias = (listaDeNodos) => {
        return listaDeNodos.map((nodo, i) => {
            let objetoConProbabilidad = {};
            let objetoConConsecuencia = {};
            let objetoConSegmentoCritico = {};

            let consecuenciaOriginal = nodo["resultadosDeLaValoracion"]["consecuencia"]??{};
            let probabilidadOriginal = nodo["resultadosDeLaValoracion"]["probabilidad"]??{};           

            try {
                Object.keys(probabilidadOriginal).forEach((key, i) => {
                    if (key !== "logDeCalculos") {
                        objetoConProbabilidad[key] = probabilidadOriginal[key];
                    }
                });
            }
            catch (error) { console.log(error); };

            try{
                Object.keys(consecuenciaOriginal).forEach((key, i) => {
                    if (key !== "logDeCalculos") {
                        objetoConConsecuencia[key] = consecuenciaOriginal[key];
                    }
                });
            }
            catch (error) {console.log(error); };

            if (nodo["resultadosDeLaValoracion"]["segmentoCritico"]) {
                objetoConSegmentoCritico = nodo["resultadosDeLaValoracion"]["segmentoCritico"];
            }
            else {
                objetoConSegmentoCritico = { nombre: "-", id: undefined };
            }

            let activoAsociado = nodo["informacionDeLosPadres"]["activoAsociado"];

            if(activoAsociado){
                activoAsociado = activoAsociado.nombre;
            }

            return ({
                "id": nodo["id"],
                "dominioCorporativo": nodo["dominioCorporativo"],
                "informacionGeneral": nodo["informacionGeneral"],
                "informacionDeLosPadres": {
                    activoAsociado: {
                        nombre: activoAsociado
                    },
                    campoAsociado: nodo["informacionDeLosPadres"]["campoAsociado"],
                    sistemaAsociado: nodo["informacionDeLosPadres"]["sistemaAsociado"],
                },
                "resultadosDeLaValoracion": {
                    "consecuencia": objetoConConsecuencia,
                    "planesDeAccion": { "informacionAdicional": nodo["resultadosDeLaValoracion"]["planesDeAccion"]?nodo["resultadosDeLaValoracion"]["planesDeAccion"]["informacionAdicional"]:'Sin información adicional' },
                    "probabilidad": objetoConProbabilidad,
                    "riesgo": nodo["resultadosDeLaValoracion"]["riesgo"],
                    "segmentoCritico": objetoConSegmentoCritico,
                },
                "variablesDeLaInspeccion": {
                    "trazaDelSegmento": nodo["variablesDeLaInspeccion"]["trazaDelSegmento"],
                }
            });
        });
    }

    obtenerMensajeDeDescargaEnProceso = () => {
        if (this.state.mostrarLabelDeDescargaEnProceso) {
            return (
                <p style={{ ...estilosParaResumenesEnTop }}
                    dangerouslySetInnerHTML={{ __html: "La descarga de exportación a excel está <b>en proceso</b>" }} />
            );
        }
    }

    obtenerMensajeDeDescargaFallida = () => {
        if (this.state.mostrarLabelDeDescargaFallida) {
            return (
                <p style={{ ...estilosParaResumenesEnTop }}
                    dangerouslySetInnerHTML={{ __html: "La descarga de exportación a excel <b>falló</b>" }} />
            );
        }
    }

    obtenerMensajeDeLimiteDeDescargas = () => {
        if (!this.state.cargando && this.state.campoTarget) {
            if (this.state.limiteDeDescargas !== undefined) {
                return (
                    <p style={{ ...estilosParaResumenesEnTop }}>
                        {"Se descargaron los "}
                        <b>{this.state.limiteDeDescargas + " " + this.state.coleccionActiva}</b>
                        {" con mayor riesgo. "}
                        <span style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
                            onClick={() => {
                                Swal.fire(confirmacionDeActualizacionDeNodos)
                                    .then(result => {
                                        if (result.value) {
                                            this.setState({ limiteDeDescargas: undefined });
                                            setTimeout(() => { this.actualizarNodos(); }, 50);
                                        }
                                    });
                            }}>
                            Ver todos
                        </span>
                    </p>
                );
            }
            else {
                return (
                    <p style={{ ...estilosParaResumenesEnTop }}
                        dangerouslySetInnerHTML={{
                            __html: "Se descargaron <b>todos los " + this.state.coleccionActiva
                                + "</b> del campo ("
                                + (this.state.coleccionActiva === "segmentos"
                                    ? this.state.todosLosSegmentos.length
                                    : this.state.todosLosActivos.length) + ")"
                        }} />
                );
            }
        }
        else {
            return (
                <p style={{ ...estilosParaResumenesEnTop }}
                    dangerouslySetInnerHTML={{ __html: "..." }} />
            );
        }
    }

    //Métodos para el manager de filtros:
    actualizarValorDeFiltro = (keyTarget, nuevoValorDelFiltro, ejecutarConsulta) => {
        let filtrosActualizados = this.state.filtrosActivos;
        filtrosActualizados[keyTarget] = nuevoValorDelFiltro;
        this.props.onChangeFiltros && this.props.onChangeFiltros(filtrosActualizados)
        const nodosFiltrados = obtenerElementosQueCumplanConLasCondiciones(this.state.todosLosNodos, filtrosActualizados);
        
        const segmentosFiltrados = this.state.coleccionActiva === 'segmentos' ? this.state.todosLosSegmentos : obtenerSegmentosFiltrados(nodosFiltrados, this.state.todosLosSegmentos);
        if(this.props.isChildren){
            this.props.datosFiltradosPRD && this.props.datosFiltradosPRD(nodosFiltrados);
            this.setState({
                filtrosActivos: filtrosActualizados,
                nodosFiltrados: nodosFiltrados,
                keyDeRenderizado: this.state.keyDeRenderizado + 1
            });
        }else{
            this.setState({
                filtrosActivos: filtrosActualizados,
                nodosFiltrados: nodosFiltrados,
                segmentosFiltrados: segmentosFiltrados,
                keyDeRenderizado: this.state.keyDeRenderizado + 1
            });
        }
       
    }

    agregarFiltro = () => {
        let filtrosActualizados = this.state.filtrosActivos;
        let keysActuales = Object.keys(filtrosActualizados);

        let keyTarget = 0;

        if (keysActuales.length > 0) {
            keysActuales.forEach((key, i) => { filtrosActualizados[key]['desplegado'] = false; });
            keyTarget = Math.max(...keysActuales) + 1
        }

        filtrosActualizados[keyTarget] = { tipo: "RIESGO", activo: true, desplegado: true };
        this.setState({ filtrosActivos: filtrosActualizados, });
    }

    desactivarFiltro = (keyTarget) => {
        let filtrosActualizados = this.state.filtrosActivos;
        filtrosActualizados[keyTarget].activo = false;
        this.props.onChangeFiltros && this.props.onChangeFiltros(filtrosActualizados)
        const nodosFiltrados = obtenerElementosQueCumplanConLasCondiciones(this.state.todosLosNodos, filtrosActualizados);
        const segmentosFiltrados = this.state.coleccionActiva === 'segmentos' ? this.state.todosLosSegmentos : obtenerSegmentosFiltrados(nodosFiltrados, this.state.todosLosSegmentos);
        if(this.props.isChildren){
            this.props.datosFiltradosPRD && this.props.datosFiltradosPRD(nodosFiltrados)
        }else{
            this.setState({
                filtrosActivos: filtrosActualizados,
                nodosFiltrados: nodosFiltrados,
                segmentosFiltrados: segmentosFiltrados,
                keyDeRenderizado: this.state.keyDeRenderizado + 1
            });
        }
        
    }

    //Métodos para el renderizado programático de columnas:
    obtenerContenidoBasadoEnTipo = (parametrizacionDelContenido,keyEnState) => {
        switch (parametrizacionDelContenido.tipo) {
            case "TABLA_DE_NODOS":
                return (
                    <CardConEncabezado titulo="Lista de datos" icono={BsTable}>
                        <TablaDeNodos
                            listaDeNodos={this.state.nodosFiltrados}
                            coleccionActiva={this.state.coleccionActiva}
                            moduloTarget={this.props.moduloTarget}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.props.moduloTarget]}
                            {...parametrizacionDelContenido.props}
                        />
                    </CardConEncabezado>
                );

            case "GRAFICA":
                if(this.props.isChildren){
                    return (
                        <CardConGrafica
                            modeloDeConsecuencia={"Cualitativo"}
                            columna={keyEnState}
                            data={this.props.datosDePRD}
                            graficaActiva={this.props.opcionesParaGraficas[0]}
                            opcionesDeLaGraficaActiva={this.props.opcionesParaGraficas[0].opciones[1]}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.props.moduloTarget]}
                            coleccionActiva={this.state.coleccionActiva}
                            {...parametrizacionDelContenido.props} />
                    );
                }else{
                    return (
                        <CardConGrafica
                            data={this.state.nodosFiltrados}
                            graficaActiva={this.props.opcionesParaGraficas[0]}
                            opcionesDeLaGraficaActiva={this.props.opcionesParaGraficas[0].opciones[1]}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.props.moduloTarget]}
                            coleccionActiva={this.state.coleccionActiva}
                            {...parametrizacionDelContenido.props} />
                    );
                }
               

            case "MATRIZ_DE_RIESGO":
                return (
                    <MatrizDeRiesgo
                        coleccionActiva={this.state.coleccionActiva}
                        listaDeNodos={this.state.nodosFiltrados}
                        moduloTarget={this.props.moduloTarget}
                        parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.props.moduloTarget]} />
                );

            case "MAPA_DEL_ACTIVO":
                const segmentos =  this.state.coleccionActiva === 'segmentos' ? this.state.nodosFiltrados : this.state.segmentosFiltrados;
                return (
                    <CardConEncabezado titulo={'Georeferencia de los activos'} icono={FaMapPin}>
                        <MapaDeActivo
                            keyDeRenderizado={"mapa." + this.props.moduloTarget + "." + this.state.keyDeRenderizado}
                            moduloTarget={this.props.moduloTarget}
                            parametrizacionCorporativa={this.props.parametrizacionCorporativa["rangos" + this.props.moduloTarget]}
                            segmentos={segmentos} />
                    </CardConEncabezado>
                );

            default:
                return (<React.Fragment />);
        }
    }

    obtenerContenidoDeColumna = (keyEnState) => {
        let columnaTarget = this.props[keyEnState];
        let keys = Object.keys(columnaTarget);
        let items = keys.map((key, i) => { return columnaTarget[key]; });
        items = items.filter(paramsDeComponente => paramsDeComponente.activo);
        return items.map((item, i) => {
            return (
                <React.Fragment key={keyEnState + "." + i}>
                    {this.obtenerContenidoBasadoEnTipo(item,keyEnState)}
                </React.Fragment>
            );
        });
    }

    obtenerContenidoPrincipal = () => {
        if (this.state.nodosDescargadosLaPrimeraVez) {
            return (
                <Row>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 12, order: 1 }} lg={{ size: 6, order: 1 }} xl={{ size: 6, order: 1 }}>
                        {this.obtenerContenidoDeColumna("itemsColumnaIzquierda")}
                    </Col>

                    <Col xs={{ size: 12, order: 2 }} md={{ size: 12, order: 2 }} lg={{ size: 6, order: 2 }} xl={{ size: 6, order: 2 }}>
                        {this.obtenerContenidoDeColumna("itemsColumnaDerecha")}
                    </Col>
                </Row>
            );
        }
    }

    //Métodos para renderizado:
    obtenerBotonDeActualizacion = () => {
        return (
            <BotonParaSection key="botonDeActualizacionEnApi580"
                onClick={() => { this.actualizarNodos() }}
                icono={<FaRedoAlt />} title="Refrescar datos" />
        );
    }

    obtenerBotonDeCambioDeColeccion = () => {
        let icono = (this.state.coleccionActiva === "activos") ? <GiJigsawPiece /> : <GiFuelTank />;
        if(this.props.isChildren){
            return(<React.Fragment key={"sfdfkjfsd"}></React.Fragment>)
        }else{
            return (
                <BotonParaSection key={"botonDeCambioDeColeccionEnApi580." + this.state.coleccionActiva}
                    icono={icono} title="Cambiar scope"
                    onClick={() => {
                        if (this.state.coleccionActiva === "activos") {
                            const nodosFiltrados = obtenerElementosQueCumplanConLasCondiciones(this.state.todosLosSegmentos, this.state.filtrosActivos);
                            this.setState({
                                coleccionActiva: "segmentos",
                                todosLosNodos: this.state.todosLosSegmentos,
                                segmentosFiltrados: this.state.todosLosSegmentos,
                                nodosFiltrados: nodosFiltrados,
                                keyDeRenderizado: this.state.keyDeRenderizado + 1
                            }, () => { this.actualizarDataEnReduxStorage() });
                        }
                        else {
                            const nodosFiltrados = obtenerElementosQueCumplanConLasCondiciones(this.state.todosLosActivos, this.state.filtrosActivos);
                            const segmentosFiltrados = obtenerSegmentosFiltrados(nodosFiltrados, this.state.todosLosSegmentos);
                            this.setState({
                                coleccionActiva: "activos",
                                todosLosNodos: this.state.todosLosActivos,
                                segmentosFiltrados: segmentosFiltrados,
                                nodosFiltrados: nodosFiltrados,
                                keyDeRenderizado: this.state.keyDeRenderizado + 1
                            }, () => { this.actualizarDataEnReduxStorage() });
                        }
                    }} />
            );
        }
       
    }

    obtenerBotonDeCargueMasivo = () => {
        return (
            <BotonParaSection key="botonDeCargaEnApi580"
                onClick={this.toggleDeLaConsolaDeCargue}
                icono={<FaCloudUploadAlt />} title="Cargar datos" />
        );
    }

    obtenerSheetExportacion = () => {
        switch (this.props.moduloTarget) {
            case 'API581':
                return 'rangosAPI581'
            case 'API58':
                return 'rangosAPI581'
            case 'API5':
                return 'rangosAPI581'
            case 'ASME_B31_8S':
                return 'rangosASME_B31_8S'
            case 'API1160':
                return 'rangosAPI1160'
            default:
                break;
        }
    }
	

    obtenerBotonDeDescargaDeData = () => {
        if(this.props.datosDePRD){
            return (
                <React.Fragment key="botonDeDescargaResultadosPRD">
                    <BotonParaSection key="botonDeDescargaResultadosPRD"
                        onClick={() => this.toggleDeConsolaDeDescarga()}
                        icono={<FaCloudDownloadAlt />} title="Descargar datos" />
    
                    <ConsolaDeDescargaPRD
                        modeloDeConsecuencia={this.props.modeloDeConsecuencia}
                        prd={this.props.datosDePRD.map(prd => { return prd.id})}
                        activos={this.state.todosLosActivos.map((segmento, i) => { return segmento.id; })}
                        alertExitoso={this.dispararAlertExitoso}
                        alertFallido={this.dispararAlertFallido}
                        campoTarget={this.state.campoTarget}
                        elModalEstaAbierto={this.state.laConsolaDeDescartaEstaAbierta}
                        coleccionActiva={this.state.coleccionActiva}
                        normaTarget={this.props.moduloTarget}
                        segmentos={this.state.todosLosSegmentos.map((segmento, i) => { return segmento.id; })}
                        toggleDelModal={this.toggleDeConsolaDeDescarga}
                        sheetId={this.props.parametrizacionCorporativa[this.obtenerSheetExportacion()].sheetId}
                        modoDescarga={MODO_DES.RESULT}
                        />
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment key="botonDeDescargaEnApi580">
                    <BotonParaSection key="botonDeDescargaEnApi580"
                        onClick={() => this.toggleDeConsolaDeDescarga()}
                        icono={<FaCloudDownloadAlt />} title="Descargar datos" />
    
                    <ConsolaDeDescarga
                        activos={this.state.todosLosActivos.map((segmento, i) => { return segmento.id; })}
                        alertExitoso={this.dispararAlertExitoso}
                        alertFallido={this.dispararAlertFallido}
                        campoTarget={this.state.campoTarget}
                        elModalEstaAbierto={this.state.laConsolaDeDescartaEstaAbierta}
                        coleccionActiva={this.state.coleccionActiva}
                        normaTarget={this.props.moduloTarget}
                        segmentos={this.state.todosLosSegmentos.map((segmento, i) => { return segmento.id; })}
                        sheetId={this.props.parametrizacionCorporativa[this.obtenerSheetExportacion()].sheetId}
                        toggleDelModal={this.toggleDeConsolaDeDescarga} />
    
                </React.Fragment>
            );
        }       
    }

    obtenerBotonDeFiltros = () => {
        return (
            <BotonParaSection key="botonDeFiltrosEnApi580"
                onClick={this.toggleDeLaConsolaDeFiltros}
                icono={<TiFilter size={15} />} title="Filtrar datos" />
        );
    }

    obtenerBotonDePlantillas = () => {
        return (
            <BotonParaSection key="botonDePlantillas"
                onClick={this.toggleDeLaConsolaDePlantillas}
                icono={<RiFileExcel2Line size={15} />} title="Descargar plantillas" />
        );
    }

    //Swal alerts:
    dispararAlertExitoso = (titulo, subtitulo) => {
        Swal.fire({
            title: titulo,
            html: '<span style="font-size:14px;">' + subtitulo + '</span>',
            icon: 'success',
            scrollbarPadding:false,
        });
    }

    dispararAlertFallido = (titulo, subtitulo) => {
        Swal.fire({
            title: titulo,
            html: '<span style="font-size:14px;">' + subtitulo + '</span>',
            icon: 'error',
            scrollbarPadding:false,
        });
    }

    //Toggles de los modales:
    toggleDeLaConsolaDeCargue = () => {
        this.setState({ laConsolaDeCargueEstaAbierta: !this.state.laConsolaDeCargueEstaAbierta });
    }

    toggleDeConsolaDeDescarga = () => {
        this.setState({ laConsolaDeDescartaEstaAbierta: !this.state.laConsolaDeDescartaEstaAbierta });
    }

    toggleDeLaConsolaDeFiltros = () => {
        this.setState({ laConsolaDeFiltrosEstaAbierta: !this.state.laConsolaDeFiltrosEstaAbierta });
    }
}

ResumenDeModulo.propTypes = {
    categoriasDeFiltrado: PropTypes.arrayOf(PropTypes.object),
    itemsColumnaDerecha: PropTypes.object,
    itemsColumnaIzquierda: PropTypes.object,
    metodoParaActualizarDataEnRedux: PropTypes.func,
    nodosEnRedux: PropTypes.object,
    nodosParaDesarrollo: PropTypes.object,
    opcionesParaGraficas: PropTypes.arrayOf(PropTypes.object),
    permitirConsultas: PropTypes.bool,
}

const mapStateToProps = state => ({
    storeStatePRD: state.reducerParaData["/app/analisis/riesgo/api581PRD"],
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
    parametrizacionCorporativa: state.reducerParaUsuario.parametrizacionCorporativa,
});
const mapDispatchToProps = dispatch => ({
    actualizarDataApi581PRD: nuevaData => { dispatch(actualizarDataApi581PRD(nuevaData)); }
});

export default connect(mapStateToProps , mapDispatchToProps)(ResumenDeModulo);