export const mecanismosParaAPI581 = [
    {
        'key': 'variablesDeLaInspeccion.evaluarCorrosionInterna.valor',
        'nombre': 'Corrosión interna',
        'editable': false
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarCorrosionExterna.valor',
        'nombre': 'Corrosión externa',
        'editable': false
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarCUI.valor',
        'nombre': 'Corrosion under insolation',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarFatiga.valor',
        'nombre': 'Fatiga',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarLining.valor',
        'nombre': 'Lining',
        'editable': true
    },
]

export const amenazasPara1160YB318s = [
    {
        'key': 'variablesDeLaInspeccion.evaluarCE.valor',
        'nombre': 'Corrosión Externa',
        'editable': false
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarCI.valor',
        'nombre': 'Corrosión Interna',
        'editable': false
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarFT.valor',
        'nombre': 'Agrietamiento por fatiga',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarSCC.valor',
        'nombre': 'Agrietamiento SCC',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarCL.valor',
        'nombre': 'Clima y fuerzas externas',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarMAN.valor',
        'nombre': 'Defectos de manufactura',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarTER.valor',
        'nombre': 'Daño por terceros',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarCONS.valor',
        'nombre': 'Defectos de construcción',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarEQ.valor',
        'nombre': 'Falla de equipos',
        'editable': true
    },
    {
        'key': 'variablesDeLaInspeccion.evaluarOI.valor',
        'nombre': 'Operaciones incorrectas',
        'editable': true
    },
]