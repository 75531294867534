import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Row, Col, Label, BreadcrumbItem, Input, Card } from 'reactstrap';
import { Select, MenuItem } from '@material-ui/core';
import { firebaseFunctions } from '../../../firebase';

//Componentes Generales
import Preloader from '../../../utilidades/componentes/plantillaBase/Preloader';

//Scripts Generales
import { glosarioEdicionODTMadre } from './scripts/glosarioVariablesDeInput';
import { obtenerValidacionDeVariable } from './scripts/paraValidadVariablesFormulario';

//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

//const cargarOrdenDeTrabajo = firebaseFunctions.httpsCallable('cargarOrdenDeTrabajo');
const editarOrdenDeTrabajoMadre = firebaseFunctions.httpsCallable('editarOrdenDeTrabajoMadre')

class VisualizadorFormularioEdicionODTMadre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando: false,

            //Validación formulario
            estadoDeValidacion: {},
            error: "",
        }
    }

    componentDidMount() {
        const estadoDeValidacionInicial = this.generarEstadoDeValidacioninicial();
        this.setState({ estadoDeValidacion: estadoDeValidacionInicial });
    }

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.submitDelFormulario}>
                    <Card className="col col-12 my-2 px-0">
                        <Row className="mb-3 px-3">
                            {this.obtenerPrimeraSeccionDeFormulario()}
                        </Row>
                    </Card>
                    <br />
                    <Input type='submit' value={'Actualizar'} style={{ textAlign: 'center', color: '#ffffff', width: '25%', float: 'right', opacity: this.obtenerEstadoDeFormulario() ? 1 : 0.8 }} className={'bg-royal pusheable-button mb-3'} disabled={!this.obtenerEstadoDeFormulario()} />
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    validacionPintarCampos = (input) => {
        if (this.state.estadoDeValidacion.estado) {
            switch (input.name) {
                case 'fechaEstimadaDeProgramacion':
                    if (this.state.estadoDeValidacion.estado.valor === 'PLANEADA') {
                        return true
                    }
                    else {
                        return false
                    }
                case 'fechaRealDeInicio':
                    if (this.state.estadoDeValidacion.estado.valor === 'EN_SITIO') {
                        return true
                    }
                    else {
                        return false
                    }
                case 'fechaRealDeProgramacion':
                    if (this.state.estadoDeValidacion.estado.valor === 'PROGRAMADA') {
                        return true
                    }
                    else {
                        return false
                    }
                case 'fechaRealDeFinalizacion':
                case 'valorFacturado':
                case 'actaDeFinalizacion':
                    if (this.state.estadoDeValidacion.estado.valor === 'FACTURADA') {
                        return true
                    }
                    else {
                        return false
                    }
                default:
                    return true
            }
        }
    }
    //Funciones Render
    obtenerInputSegunTipo = (input, seccion) => {
        let pintar = true
        pintar = this.validacionPintarCampos(input)
        if (pintar) {
            switch (input.type) {
                case 'text':
                    return (
                        <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                            <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                            <Input
                                type={input.type}
                                name={input.name}
                                value={this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].valor : ''}
                                onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                                required
                            />
                            <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                        </Col>
                    );
                case 'email':
                    return (
                        <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                            <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                            <Input
                                type={input.type}
                                name={input.name}
                                value={this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].valor : ''}
                                onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                                required
                            />
                            <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                        </Col>
                    );
                case 'fecha':
                    return (
                        <Col key={`date ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                            <Label className={"mb-0 mt-2"}>{input.contenido}</Label>
                            <Input
                                key={input.name}
                                type="date"
                                name={input.name}
                                value={this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].valor.toISOString().split('T')[0] : ''}
                                onChange={(e) => this.cambiosEnInput(e, input, seccion, 'date')}
                                style={{ width: '100%', boxShadow: 'none' }}
                                required
                            />
                            <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                        </Col>
                    );
                case 'select':
                    const estadoDelPadre = this.state.estadoDeValidacion[input.padre];
                    return (
                        <Col key={`select ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                            <Label className={"mb-0 mt-2"} style={{ color: estadoDelPadre ? estadoDelPadre.valor === '' ? '#c9c9c9' : 'black' : 'black' }}>{input.contenido}</Label>
                            <Select
                                name={input.name}
                                value={this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].valor : ''}
                                onChange={this.cambiosEnInput}
                                style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                                variant="outlined"
                            >
                                {this.obtenerListadoDeOpciones(input.name, input)}
                            </Select>
                            <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                        </Col>
                    );
                case 'textarea':
                    return (
                        <Col key={`input ${input.name}`} md={input['col-md']} lg={input['col-lg']}>
                            <Label for={input.name} className={"mb-0 mt-2"}>{input.contenido}</Label>
                            <Input
                                type={input.type}
                                name={input.name}
                                value={this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].valor : ''}
                                onChange={(e) => { this.cambiosEnInput(e, input, seccion) }}
                                required
                            />
                            <small style={{ color: 'red' }} >{(this.state.estadoDeValidacion[input.name] ? this.state.estadoDeValidacion[input.name].mensaje ? 'El valor del campo es inválido' : '' : '')}</small>
                        </Col>
                    )
                default:
                    return (
                        <Col key={`titulo ${input.name}`} lg='12' className='pt-4'>
                            <Breadcrumb>
                                <BreadcrumbItem className='BreadcrumbItem-color'>{input.name}</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    )
            }
        }
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        const estadoDeValidacion = this.state.estadoDeValidacion;

        const listadoDeValores = {};
        Object.keys(estadoDeValidacion).forEach(key => {
            listadoDeValores[key] = estadoDeValidacion[key].valor
        });

        if (nodo.obtenerOpciones !== undefined) {
            let opciones = nodo.obtenerOpciones(listadoDeValores)
            opciones.forEach((opcion, i) => {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        } else {
            nodo.opciones.forEach((opcion, i) => {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }
    }

    obtenerPrimeraSeccionDeFormulario = () => {
        return Object.keys(glosarioEdicionODTMadre).map(key => this.obtenerInputSegunTipo(glosarioEdicionODTMadre[key], 'orden_trabajo_1'))
    }

    //Funciones Lógicas
    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    cambiosEnInput = (event, key, seccion, type) => {
        let value = undefined
        if (type === 'date') {
            let myDate = new Date(((new Date(event.target.value).getTime() / 1000) + 18000) * 1000)
            value = myDate
        }
        else {
            value = event.target.value
        }
        const name = event.target.name;
        const funcionValidacion = obtenerValidacionDeVariable(name, seccion)
        const validacion = funcionValidacion['validation'];

        const estadoDeValidacionActual = this.state.estadoDeValidacion;


        if (validacion && name !== 'estado') {
            const respuesta = validacion(key, value);
            if (!respuesta) {
                estadoDeValidacionActual[name].estado = false;
                estadoDeValidacionActual[name].valor = value;
                estadoDeValidacionActual[name].mensaje = true;
                this.setState({
                    error: "El valor no es válido!",
                    estadoDeValidacion: estadoDeValidacionActual,
                })
                return;
            }
        }
        estadoDeValidacionActual[name].estado = true;
        estadoDeValidacionActual[name].valor = value;
        estadoDeValidacionActual[name].mensaje = false;
        this.setState({
            error: "",
            estadoValidacion: estadoDeValidacionActual,
        })
    }

    estructuraParaGuardado = () => {
        const estadoDeValidacion = this.state.estadoDeValidacion;
        let [latitudes, longitudes] = this.obtenerCoordenadas()
        return {
            "ordenDeTrabajo": {
                "estado": estadoDeValidacion.estado ? estadoDeValidacion.estado.valor : this.props.informacionOT.estado,
                "valorFacturado": estadoDeValidacion.valorFacturado ? estadoDeValidacion.valorFacturado.valor : this.props.informacionOT.valorFacturado,
                "actaDeFinalizacion": estadoDeValidacion.actaDeFinalizacion ? estadoDeValidacion.actaDeFinalizacion.valor : this.props.informacionOT.actaDeFinalizacion,
                "descripcionODT": estadoDeValidacion.descripcionODT ? estadoDeValidacion.descripcionODT.valor : this.props.informacionOT.descripcionODT,
                "numeroDeFormato": this.props.ordenDeTrabajo,
                "lngInicial": estadoDeValidacion.lngInicial.valor ? estadoDeValidacion.lngInicial.valor : longitudes[0],
                "lngFinal": estadoDeValidacion.lngFinal.valor ? estadoDeValidacion.lngFinal.valor : longitudes[1],
                "latInicial": estadoDeValidacion.latInicial.valor ? estadoDeValidacion.latInicial.valor : latitudes[0],
                "latFinal": estadoDeValidacion.latFinal.valor ? estadoDeValidacion.latFinal.valor : latitudes[1],
                "fechaEstimadaDeProgramacion": (estadoDeValidacion.estado ? estadoDeValidacion.estado.valor : this.props.informacionOT.estado) === 'PLANEADA' ? (estadoDeValidacion.fechaEstimadaDeProgramacion.valor ? new Date(estadoDeValidacion.fechaEstimadaDeProgramacion.valor).toString() : (this.props.informacionOT['fechaEstimadaDeProgramacion'] ? new Date(this.props.informacionOT['fechaEstimadaDeProgramacion'].seconds * 1000).toString() : null)) : (this.props.informacionOT['fechaEstimadaDeProgramacion'] ? new Date(this.props.informacionOT['fechaEstimadaDeProgramacion'].seconds * 1000).toString() : null),
                "fechaRealDeInicio": (estadoDeValidacion.estado ? estadoDeValidacion.estado.valor : this.props.informacionOT.estado) === 'EN_SITIO' ? (estadoDeValidacion.fechaRealDeInicio.valor ? new Date(estadoDeValidacion.fechaRealDeInicio.valor).toString() : (this.props.informacionOT['fechaRealDeInicio'] ? new Date(this.props.informacionOT['fechaRealDeInicio'].seconds * 1000).toString() : null)) : (this.props.informacionOT['fechaRealDeInicio'] ? new Date(this.props.informacionOT['fechaRealDeInicio'].seconds * 1000).toString() : null),
                "fechaRealDeProgramacion":(estadoDeValidacion.estado ? estadoDeValidacion.estado.valor : this.props.informacionOT.estado) === 'PROGRAMADA' ? (estadoDeValidacion.fechaRealDeProgramacion.valor ? new Date(estadoDeValidacion.fechaRealDeProgramacion.valor).toString() : (this.props.informacionOT['fechaRealDeProgramacion'] ? new Date(this.props.informacionOT['fechaRealDeProgramacion'].seconds * 1000).toString() : null)) : (this.props.informacionOT['fechaRealDeProgramacion'] ? new Date(this.props.informacionOT['fechaRealDeProgramacion'].seconds * 1000).toString() : null),
                "fechaRealDeFinalizacion":(estadoDeValidacion.estado ? estadoDeValidacion.estado.valor : this.props.informacionOT.estado) === 'FACTURADA' ? (estadoDeValidacion.fechaRealDeFinalizacion.valor ? new Date(estadoDeValidacion.fechaRealDeFinalizacion.valor).toString() : (this.props.informacionOT['fechaRealDeFinalizacion'] ? new Date(this.props.informacionOT['fechaRealDeFinalizacion'].seconds * 1000).toString() : null)) : (this.props.informacionOT['fechaRealDeFinalizacion'] ? new Date(this.props.informacionOT['fechaRealDeFinalizacion'].seconds * 1000).toString() : null),
                "fechaDeActualizacion": new Date().toString(),
                "zonaDeDucto": estadoDeValidacion.zonaDeDucto ? estadoDeValidacion.zonaDeDucto.valor : this.props.informacionOT.zonaDeDucto,
                "regionDeDucto": estadoDeValidacion.regionDeDucto ? estadoDeValidacion.regionDeDucto.valor : this.props.informacionOT.regionDeDucto,
                "departamentoOYMDeDucto": estadoDeValidacion.departamentoOYMDeDucto ? estadoDeValidacion.departamentoOYMDeDucto.valor : this.props.informacionOT.departamentoOYMDeDucto,
                "sistemaDeDucto": estadoDeValidacion.sistemaDeDucto ? estadoDeValidacion.sistemaDeDucto.valor : this.props.informacionOT.sistemaDeDucto,
                "lineaDeDucto": estadoDeValidacion.lineaDeDucto ? estadoDeValidacion.lineaDeDucto.valor : this.props.informacionOT.lineaDeDucto,
                "diametroDeDucto": estadoDeValidacion.diametroDeDucto ? estadoDeValidacion.diametroDeDucto.valor : this.props.informacionOT.diametroDeDucto,
                "espesorNominalDeDucto": estadoDeValidacion.espesorNominalDeDucto ? estadoDeValidacion.espesorNominalDeDucto.valor : this.props.informacionOT.espesorNominalDeDucto,
                "SMYSDeDucto": estadoDeValidacion.SMYSDeDucto ? estadoDeValidacion.SMYSDeDucto.valor : this.props.informacionOT.SMYSDeDucto,
                "MAOPDeDucto": estadoDeValidacion.MAOPDeDucto ? estadoDeValidacion.MAOPDeDucto.valor : this.props.informacionOT.MAOPDeDucto,
                "CEMOPDeDucto": estadoDeValidacion.CEMOPDeDucto ? estadoDeValidacion.CEMOPDeDucto.valor : this.props.informacionOT.CEMOPDeDucto
            },
            "ordenesHijas": this.props.listadoOrdenesHijas
        }
    }

    generarEstadoDeValidacioninicial = () => {
        let estadoDeValidacionInicial = {};
        const listadoDeVariables = [...Object.keys(glosarioEdicionODTMadre)];

        listadoDeVariables.forEach(variable => {
            estadoDeValidacionInicial[variable] = {}
            estadoDeValidacionInicial[variable]['estado'] = this.returnValorInicial(variable) ? true : false;
            estadoDeValidacionInicial[variable]['mensaje'] = false;
            estadoDeValidacionInicial[variable]['valor'] = this.returnValorInicial(variable);
        })


        return estadoDeValidacionInicial;
    }

    obtenerEstadoDeFormulario = () => {
        //const estadoDeValidacion = this.state.estadoDeValidacion;
        //return Object.keys(estadoDeValidacion).every(key => estadoDeValidacion[key].estado);
        return true
    }

    obtenerCoordenadas = () => {
        let coordenadas = this.props.informacionOT.coordenadas.split(';')
        let latitudes = []
        let longitudes = []
        coordenadas.forEach(coordenada => {
            latitudes.push(coordenada.split(':')[1])
            longitudes.push(coordenada.split(':')[0])
        });
        return [latitudes, longitudes]
    }

    returnValorInicial = (input) => {
        if (input === 'latInicial' || input === 'lngInicial' || input === 'latFinal' || input === 'lngFinal') {
            let [latitudes, longitudes] = this.obtenerCoordenadas()
            switch (input) {
                case 'latInicial':
                    return latitudes[0] ? latitudes[0] : ''
                case 'lngInicial':
                    return longitudes[0] ? longitudes[0] : ''
                case 'latFinal':
                    return latitudes[1] ? latitudes[1] : ''
                case 'lngFinal':
                    return longitudes[1] ? longitudes[1] : ''
                default:
                    break;
            }
        }
        else if (input === 'fechaEstimadaDeProgramacion') {
            return this.props.informacionOT[input] ? new Date(this.props.informacionOT[input].seconds * 1000) : new Date()
        }
        else if (input === 'fechaRealDeInicio') {
            return this.props.informacionOT[input] ? new Date(this.props.informacionOT[input].seconds * 1000) : new Date()
        }
        else if (input === 'fechaRealDeProgramacion') {
            return this.props.informacionOT[input] ? new Date(this.props.informacionOT[input].seconds * 1000) : new Date()
        }
        else if (input === 'fechaRealDeFinalizacion') {
            return this.props.informacionOT[input] ? new Date(this.props.informacionOT[input].seconds * 1000) : new Date()
        }
        else if (input === 'fechaCargueHorus') {
            return this.props.informacionOT[input] ? new Date(this.props.informacionOT[input].seconds * 1000) : new Date()
        }
        else {
            return this.props.informacionOT[input] ? this.props.informacionOT[input] : ''
        }
    }

    submitDelFormulario = (evento) => {
        let listaDeRolesPermitidos = ['admin', 'supervisor', 'tecnico']
        if (listaDeRolesPermitidos.includes(this.props.usuarioActivo.rol)) {
            evento.preventDefault();
            this.setState({
                cargando: true
            })
            const nuevaOrdenDeTrabajo = this.estructuraParaGuardado();
            editarOrdenDeTrabajoMadre(nuevaOrdenDeTrabajo)
                .then(result => {
                    console.log(result.data)
                    switch (result.data.status) {
                        case "ORDEN_MADRE_ACTUALIZADA":
                            this.setState({ cargando: false }, () => {
                                this.avisoDeGuardadoSatisfactorio('¡La orden de trabajo se actualizó satisfactoriamente!');
                                setTimeout(() => { this.props.cerrarModal() }, 1000);
                            })
                            break;
                        case "ORDEN_MADRE_NO_ACTUALIZADA":
                            this.setState({ cargando: false }, () => {
                                this.avisoDeGuardadoFallido('No se pudo actualizar la orden de trabajo');
                            })

                            break;
                        case "ORDEN_EXISTENTE":
                            this.setState({ cargando: false }, () => {
                                this.avisoDeGuardadoFallido('La orden de trabajo ya existe');
                            })
                            break;
                        default:
                            console.log('ocurrio un error al actualizar la orden de trabajo');
                            break;
                    }
                })
                .catch(e => {
                    console.log('errores al realizar guardado: ', e);
                })
        }
        else {
            this.avisoDeGuardadoFallido('No tiene permisos para realizar esta acción');
        }
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(VisualizadorFormularioEdicionODTMadre);
