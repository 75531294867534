import React from 'react';
import { MenuItem } from '@material-ui/core';

const obtenerListadoDeOpcionesParaSelect = (listaDeOpciones) => {
    let menu = []
    listaDeOpciones.forEach((opcion, i)=> {
        menu.push(<MenuItem key={opcion} value={opcion}>{opcion}</MenuItem>)
    });
    return menu
}

export default obtenerListadoDeOpcionesParaSelect
