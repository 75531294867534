import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Select } from '@material-ui/core';
//iconos:
import { Col, Input, Label, Row } from 'reactstrap';
//componentes:
import { obtenerValidacionDeVariable } from '../scripts/paraValidarVariablesAgregarUsuarios';
import Preloader from '../../../../utilidades/componentes/plantillaBase/Preloader';
//glosario de inputs:
import { glosarioDeInputsAgregarUsuario } from '../scripts/glosarioDeInputsAgregarUsuario';
//toast:
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
//firebase:
import { firestoreDB } from '../../../../firebase';

const firebase = require('firebase/app');
require('firebase/firestore');

const estiloLabel = 'mb-0 mt-2'

class ContenidoModalEditarUsuarios extends React.Component{
    constructor(){
        super()
        this.state = {
            nombre: '',
            correo: '',
            dominioAlQuePertenece: '',
            rol: '',
            cargando: false,

            mensajeNombre: false,
            mensajeCorreo: false,
            mensajeDominioAlQuePertenece: false,
            mensajeRol: false,

            estadoValidacion: true
        } 
    }
    render(){
        return(
            <React.Fragment>
                <form onSubmit={this.submitDelFormulario}>
                    <Row>
                        {this.generarInputsDelModal()}
                    </Row>
                    <br/>
                    <Input 
                        type='submit' 
                        value="Modificar" 
                        style={{ textAlign: 'center', color: '#ffffff', width: '25%', float: 'right' }} 
                        className={'bg-royal pusheable-button mb-3'} 
                    />
                </form>
                <ToastContainer />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    componentDidMount(){
        this.setState({
            nombre: this.props.nombre,
            correo: this.props.correo,
            dominioAlQuePertenece: this.props.dominio,
            rol: this.props.rol
        })
    }

    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }

    generarInputsDelModal = () => {
        let listaDeInputs = [];
        for (const input of Object.keys(glosarioDeInputsAgregarUsuario)) {
            listaDeInputs.push(this.generarInputSegunGlosario(input))
        }
        return listaDeInputs;
    }

    generarInputSegunGlosario = (input) => {
        for(let key in glosarioDeInputsAgregarUsuario){
            if(key === input){
                switch(glosarioDeInputsAgregarUsuario[key].type){
                    case 'text':
                        return (
                            <Col
                                key={`input:${key}`} 
                                xs={glosarioDeInputsAgregarUsuario[key]['col-sm']} 
                                md={glosarioDeInputsAgregarUsuario[key]['col-md']}
                                lg={glosarioDeInputsAgregarUsuario[key]['col-lg']}
                                xl={glosarioDeInputsAgregarUsuario[key]['col-xl']}
                            >
                                <Label for={key} className={estiloLabel}>{glosarioDeInputsAgregarUsuario[key].label}</Label>
                                <Input
                                    type={glosarioDeInputsAgregarUsuario[key].type}
                                    name={key}
                                    value={this.state[key]}
                                    onChange={(e) => { this.cambiosEnInput(e, glosarioDeInputsAgregarUsuario[key]) }}
                                    required
                                />
                                <small style={{ color: 'red' }} >{(this.state[glosarioDeInputsAgregarUsuario[key].mensaje] ? 'El valor del campo es invalido' : '')}</small>
                            </Col>
                        )
                    case 'select':
                        return (
                            <Col key={`select ${key}`} 
                                xs={glosarioDeInputsAgregarUsuario[key]['col-sm']} 
                                md={glosarioDeInputsAgregarUsuario[key]['col-md']}
                                lg={glosarioDeInputsAgregarUsuario[key]['col-lg']}
                                xl={glosarioDeInputsAgregarUsuario[key]['col-xl']}
                            >
                                <Label className={estiloLabel} >{glosarioDeInputsAgregarUsuario[key].label}</Label>
                                <Select
                                    name={key}
                                    value={this.state[key]}
                                    onChange={this.cambiosEnInput}
                                    style={{ width: '100%', maxHeight: '37px', backgroundColor: '#ffffff', color: 'grey' }}
                                    variant="outlined"
                                >
                                    {this.obtenerListadoDeOpciones(key, glosarioDeInputsAgregarUsuario[key])}
                                </Select>
                            </Col>
                        )
                    default: 
                        return( <React.Fragment></React.Fragment>)
                }
            }
        }
    }

    cambiosEnInput = (event, key, type) => {
        let value =  undefined
        if (type === 'date') {
            let myDate = new Date(((new Date(event.target.value).getTime()/1000)+18000)*1000)
            value = myDate
        }
        else {
            value = event.target.value
        }
        const name = event.target.name;
        let funcionValidacion = obtenerValidacionDeVariable(name)
        let validacion = funcionValidacion['validation']
        if (validacion != null) {
            const respuesta = validacion(key, value);
            if (!respuesta) {
                this.setState({
                    error: "El valor no es válido!",
                    estadoValidacion: false,
                    [key.mensaje]: true
                })
            } else {
                this.setState({
                    [name]: value,
                    error: "",
                    estadoValidacion: true,
                    [key.mensaje]: false
                })
            }
        }
    }

    obtenerListadoDeOpciones(key, nodo) {
        let menu = []
        if(nodo.filtrarOpciones !== undefined){
            let opciones = nodo.filtrarOpciones(this.props.usuarioActivo.dominioCorporativo)
            opciones.forEach((opcion, i)=> {
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }else{
            nodo.opciones.forEach((opcion, i) =>{
                menu.push(<MenuItem key={key + opcion.label + i} value={opcion.valor}>{opcion.label}</MenuItem>)
            })
            return menu
        }
    }

    submitDelFormulario = (evento) => {
        evento.preventDefault();
        this.setState({
            // cargando: true
        })
        firestoreDB.collection('empresas').doc(this.props.usuarioActivo.companyId).get()
            .then(snapshot => {
                    if (!snapshot.data().listaDeUsuariosPermitidos.includes(this.state.correo)) {
                    let usuario = {
                        nombre : this.state.nombre,
                        correo: this.state.correo,
                        dominioAlQuePertenece: this.state.dominioAlQuePertenece,
                        rol: this.state.rol
                    }
                    // let listaDeUsuariosPermitidos = []
                    
                    if (this.state.estadoValidacion) {
                        let promiseUsuario = [];
                        promiseUsuario.push(firestoreDB.doc(`empresas/${this.props.usuarioActivo.companyId}`).update({
                            listaDeUsuariosPermitidos: firebase.firestore.FieldValue.arrayUnion(this.state.correo)
                        }))
                        promiseUsuario.push(
                            firestoreDB.collection('usuarios').doc(this.state.correo).set(usuario)
                            )
                        Promise.all(promiseUsuario)
                            .then( () => {
                                this.setState({ cargando: false })
                                this.avisoDeGuardadoSatisfactorio('¡El usuario se ha creado satisfactoriamente!');
                            })
                            .catch(error => {
                                console.log(error)
                                this.setState({ cargando: false })
                                this.avisoDeGuardadoFallido('El usuario no se ha podido crear correctamente');
                            })
                    } else {
                        this.setState({ cargando: false })
                        this.avisoDeGuardadoFallido('El envío de formulario ha fallado, revisa que los campos estén correctamente diligenciados');
                    }
                } else {
                    this.setState({ cargando: false })
                    this.avisoDeGuardadoFallido('usuario ya existente, comprueba el correo corporativo');
                }
            })
            .catch(() => {
                this.avisoDeGuardadoFallido('El envío de formulario ha fallado');
                this.setState({ cargando: false })
            })
    }
}
const mapStateToProps = state => ({
    usuarioActivo: state.reducerParaUsuario.usuarioActivo,
});

export default connect(mapStateToProps)(ContenidoModalEditarUsuarios);