import React, {Component, Fragment} from 'react';
import {
    Row, Col, ListGroup, ListGroupItem, CardFooter, Button, BreadcrumbItem
} from 'reactstrap';

import { toast } from 'react-toastify';

import { firebaseFunctions } from '../../../../../../src/firebase';

import Swal from 'sweetalert2';

import { Link } from 'react-router-dom';

import { BsTrash } from 'react-icons/bs';

import {confirmacionDeBorradoDeFormatoDeInspeccion} from '../scripts/alertaParaSwal';
//import obtenerColorSegunEstado from '../../../../utilidades/scripts/paraVisualizacionDeRangos';
import obtenerColorSegunEstado from '../../../../../utilidades/scripts/paraVisualizacionDeRangos';

const eliminarOrdenDeTrabajoHija = firebaseFunctions.httpsCallable('eliminarOrdenDeTrabajoHija')

class ListGroupExampleAdvanced extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.state = {
            cSelected: [],
            dropdownOpen: false,
            listaDeFormatos: undefined,
            ordenMadre: undefined,
        };

        this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
    }

    render() {
        return (
            <Fragment>
                <div className={"mb-0 p-3 d-flex border rounded-top"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                    <BreadcrumbItem className='BreadcrumbItem-color align-items-center' >FORMATOS ASOCIADOS</BreadcrumbItem>
                </div>
                <div className="p-3 shadow-none border rounded-bottom">
                    <Row>
                        <Col lg="12">
                            <ListGroup>
                                {this.state.listaDeFormatos ? this.state.listaDeFormatos.map(element => (
                                    <ListGroupItem
                                        key={element.id}>
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-3">
                                                    <i className="pe-7s-note2 text-muted fsize-2" />
                                                </div>
                                                <div className="widget-content-left">
                                                    <div className="widget-heading">
                                                        {element.id}
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {element.descripcionODT}
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {this.crearBadgeSegunEstado(element.estado)}
                                                    </div>
                                                </div>
                                                <div className="widget-content-right widget-content-actions">
                                                    <Link to={this.getRutaDeRedireccion(element.id)}
                                                        onClick={() => { this.guardarEnElLocalStorage(this.props, this.getRutaDeRedireccion(element.id)) }}
                                                    >
                                                        <Button color="primary" outline className="btn-pill" style={{ margin: 5 }}>
                                                            Detalle
                                                                </Button>
                                                    </Link>
                                                    {this.disableBotonEliminar() ? <Button className="btn-pill" outline color="danger"
                                                        onClick={() => {
                                                            let miConfirmacionDeBorradoDeFormato = { ...confirmacionDeBorradoDeFormatoDeInspeccion };
                                                            miConfirmacionDeBorradoDeFormato.html = miConfirmacionDeBorradoDeFormato.html.replace("@nombreDelFichero", element.id);

                                                            Swal.fire(miConfirmacionDeBorradoDeFormato)
                                                                .then(result => {
                                                                    if (result.isConfirmed) {
                                                                        let data = {
                                                                            "ordenDeTrabajoHija": element.id
                                                                        }
                                                                        this.eliminarOTHija(data)
                                                                    }
                                                                });
                                                        }}
                                                    >
                                                        <BsTrash />
                                                    </Button> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                )) : null}
                            </ListGroup>
                            <CardFooter className="d-block text-right">
                                {this.disableBotonEliminar() ? <Button
                                    style={{ width: 150 }} size="lg" color="success" onClick={() => this.props.toggleModal()}>Añadir Formato</Button> : null}
                            </CardFooter>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }

    //---Funciones adicionales---
    //Render Toast Guardado Fallido
    avisoDeGuardadoFallido = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error'
        })
    }
    //Render Toast Guardado Satisfactorio
    avisoDeGuardadoSatisfactorio = (palabra) => {
        toast(palabra, {
            closeButton: true,
            hideProgressBar: false,
            autoClose: 3000,
            position: 'bottom-right',
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success'
        })
    }

    componentDidMount () {
        this.getOrdenesHijas()
    }

    componentDidUpdate(prevenProps, prevenState) {
        if (prevenProps !== this.props) {
            this.getOrdenesHijas();
        }
    }
    //Funcion que crea los badge
    crearBadgeSegunEstado = (estado) => {
        switch (estado) {
            case 'TOTAL':
                return (<div style={{ fontSize: 14, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('TOTAL') }} className="badge badge-pill align-middle">TOTAL ORDENES</div>)
            case 'PLANEADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('PLANEADA') }} className="badge badge-pill align-middle">PLANEADA</div>)
            case 'PROGRAMADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('PROGRAMADA') }} className="badge badge-pill align-middle">PROGRAMADA</div>)
            case 'EN_SITIO':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('EN_SITIO') }} className="badge badge-pill align-middle">EN SITIO</div>)
            case 'INSPECCIONADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('INSPECCIONADA') }} className="badge badge-pill align-middle">INSPECCIONADA</div>)
            case 'EJECUTADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('EJECUTADA') }} className="badge badge-pill align-middle">EJECUTADA</div>)
            case 'REVISADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('REVISADA') }} className="badge badge-pill align-middle">REVISADA</div>)
            case 'FINALIZADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('FINALIZADA') }} className="badge badge-pill align-middle">FINALIZADA</div>)
            case 'CONTROL_CALIDAD':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('CONTROL_CALIDAD') }} className="badge badge-pill align-middle">CONTROL DE CALIDAD</div>)
            case 'FACTURADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('FACTURADA') }} className="badge badge-pill align-middle">FACTURADA</div>)
            case 'CANCELADA':
                return (<div style={{ fontSize: 10, color: '#ffffff', backgroundColor: obtenerColorSegunEstado('CANCELADA') }} className="badge badge-pill align-middle">CANCELADA</div>)  
            default:
                return (<div style={{ fontSize: 10 }} className="badge badge-pill badge-secundary align-middle">NO DEFINIDO</div>)
        }
    }

    //Funcion para habilitar/deshabilitar boton de eliminar odt hija segun rol del usuario activo
    disableBotonEliminar = () => { 
        const rolesPermitidos = ["admin", "supervisor", "control_calidad", "programador_contrato"]
        return rolesPermitidos.includes(this.props.usuarioActivo.rol)
    }

    //Funcion para eliminar odt hija. Hace uso de cloud function
    eliminarOTHija = (ot) => {
        eliminarOrdenDeTrabajoHija(ot)
        .then(result =>{
            switch(result.data.status){
                case "ORDEN_HIJA_ELIMINADA":
                    this.setState({ cargando: false }, () => {
                        this.avisoDeGuardadoSatisfactorio('¡La orden de trabajo se eliminó satisfactoriamente!');
                    })
                    break;
                case "ORDEN_HIJA_NO_ELIMINADA":
                    this.setState({ cargando: false }, () => {
                        this.avisoDeGuardadoFallido('No se pudo eliminar la orden de trabajo');
                    })
                    break;
                default:
                    console.log('ocurrio un error al eliminar la orden de trabajo');
                break;
            }
        })
        .catch(e =>{
            console.log('errores al realizar guardado: ', e);
        })
    }
    //Lee de props la informacion de listado de ordenes hijas y de la orden madre
    getOrdenesHijas = () => { 
        this.setState({
            listaDeFormatos: this.props.listaDeFormatos,
            ordenMadre: this.props.ordenMadre,
        })
    }
    //Ruta redireccion detalle
    getRutaDeRedireccion = (id) =>{
        return '/app/formatoDeInspeccion/' + id
    }
    //Almacenamiento en el localstorage cuando se redirecciona
    guardarEnElLocalStorage = (props,  path) => {
        localStorage.setItem('ultimaRutaVisitada', JSON.stringify(path))
        localStorage.setItem('ultimaOrdenVisitada', this.props.ordenMadre)
    }

    onCheckboxBtnClick(selected) {
        const index = this.state.cSelected.indexOf(selected);
        if (index < 0) {
            this.state.cSelected.push(selected);
        } else {
            this.state.cSelected.splice(index, 1);
        }
        this.setState({cSelected: [...this.state.cSelected]});
    }

    onMouseEnter() {
        this.setState({dropdownOpen: true});
    }

    onMouseLeave() {
        this.setState({dropdownOpen: false});
    }
  
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }  
    
};

export default ListGroupExampleAdvanced;
