import React from 'react';
import { Grid, Divider, Typography, Select, MenuItem, FormControl, InputLabel, Hidden } from '@material-ui/core'
import ResumenDeModulo from '../../../../../utilidades/componentes/resumenDeModulo/ResumenDeModulo';
import MatrizDeRiesgo from '../../../../../utilidades/componentes/resumenDeModulo/MatrizDeRiesgo';
import CardConEncabezado from '../../../../../utilidades/componentes/plantillaBase/CardConEncabezado';
import TablaDeNodos from '../../../../../utilidades/componentes/resumenDeModulo/TablaDeNodos';
import { BsTable } from 'react-icons/bs';
import { Label } from 'reactstrap';
import { obtenerPRD as obtenerDatos } from './scripts/consultasDB';
import Preloader from '../../../../../utilidades/componentes/plantillaBase/Preloader';

import { opcionesParaGraficasSemicuantitativas } from './estaticos/opcionesParaGraficasSemicuantitativas';
import { opcionesParaGraficasCualitativas } from './estaticos/opcionesParaGraficasCualitativas';
import { categorias } from './estaticos/opcionesDeFiltros';
import obtenerResumen from '../../../gestion/api581Valvulas/scripts/consultas/obtenerResumen';
import SpanDeCampo from "../../../../../utilidades/componentes/resumenDeModulo/SpanDeCampo"
import get from 'lodash/get';
import set from 'lodash/set';
import has from 'lodash/has';
import { errorToast } from "../../../../../utilidades/Toastify/Toastify"

const estilosParaResumenesEnTop = { marginBottom: 10, textAlign: "right"};
const itemsColumnaIzquierda = {

    1: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasSemicuantitativas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficasSemicuantitativas[0].opciones[1]
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasSemicuantitativas[2],
            opcionesDeLaGraficaActiva: opcionesParaGraficasSemicuantitativas[2].opciones[0]
        }
    },
};

const itemsColumnaDerecha = {

    1: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasSemicuantitativas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficasSemicuantitativas[0].opciones[0]
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasSemicuantitativas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficasSemicuantitativas[1].opciones[0]
        }
    },
};

const itemsColumnaIzquierdaCualitativa = {

    1: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasCualitativas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficasCualitativas[0].opciones[1]
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasCualitativas[2],
            opcionesDeLaGraficaActiva: opcionesParaGraficasCualitativas[2].opciones[0]
        }
    },
};

const itemsColumnaDerechaCualitativa = {

    1: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasCualitativas[0],
            opcionesDeLaGraficaActiva: opcionesParaGraficasCualitativas[0].opciones[0]
        }
    },
    2: {
        activo: true,
        tipo: "GRAFICA",
        props: {
            graficaActiva: opcionesParaGraficasCualitativas[1],
            opcionesDeLaGraficaActiva: opcionesParaGraficasCualitativas[1].opciones[0]
        }
    },
};
class VisualizadorApi581Valvulas extends React.Component {
    constructor() {
        super();

        this.state = {
            listaDeNodos: [],
            todosLosNodos: [],
            parametrizacionCorporativa: undefined,
            cargando: false,
            filtroModeloConsecuencia: undefined,
            openSelectorConsecuencia: false,
            campoTarget: undefined
        }

        this.unSuscribe = false;
        this.parametrizacionCorporativa = {};
        this.dominioCorporativo = ""
        this.storeStatePRD = {}
    }


    render() {
        return (
            <React.Fragment>
                <ResumenDeModulo
                    categoriasDeFiltrado={categorias[this.state.filtroModeloConsecuencia]}
                    itemsColumnaDerecha={
                        this.state.filtroModeloConsecuencia === "Semicuantitativo" ?
                            itemsColumnaDerecha
                            : itemsColumnaDerechaCualitativa}

                    itemsColumnaIzquierda={this.state.filtroModeloConsecuencia === "Semicuantitativo" ?
                        itemsColumnaIzquierda
                        : itemsColumnaIzquierdaCualitativa}

                    // metodoParaActualizarDataEnRedux={this.props.actualizarDataApi580}
                    moduloTarget="API581"
                    // nodosEnRedux={this.props.dataDe580EnRedux}
                    nodosEnRedux={{}}
                    // nodosParaDesarrollo={{ "todosLosSegmentos": segmentosDePrueba, "todosLosActivos": activosDePrueba }}
                    opcionesParaGraficas={opcionesParaGraficasSemicuantitativas}
                    isChildren={true}
                    children={this.pintarVisualizador}
                    parametrizacionParaPRD={(parametros, dominioCorporativo, storeStatePRD,dispatchPRD) => {
                        if (!this.unSuscribe) {
                            if (parametros) this.parametrizacionCorporativa = parametros;
                            if (dominioCorporativo) this.dominioCorporativo = dominioCorporativo;
                            if (storeStatePRD) this.storeStatePRD = { ...this.storeStatePRD, ...storeStatePRD };
                            if (dispatchPRD) this.dispatchPRD = dispatchPRD;
                        }
                    }}
                    modeloDeConsecuencia={this.state.filtroModeloConsecuencia}
                    onActualizarDatosPRD={(modelo,campo,forzarActualizacion) => { this.datosDeConsulta(modelo,campo,forzarActualizacion) }}
                    datosFiltradosPRD={(datos) => { this.datosFiltrados(datos) }}
                    datosDePRD={this.state.listaDeNodos}
                    todosLosNodosPRD={this.state.todosLosNodos}
                    consultarDatosPRD={() => { this.datosDeConsulta(this.state.filtroModeloConsecuencia) }}
                    permitirConsultas={false}
                    titulo="API 581 PRD"
                    subtitulo="Risk based inspection"
                />
                <Preloader elPreloaderEstaAbierto={this.state.cargando} />
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.unSuscribe = false;
        this.datosDeConsulta(this.state.filtroModeloConsecuencia);
    }

    componentWillUnmount() {
        this.unSuscribe = true;
    }

    componentDidUpdate() {
        this.unSuscribe = false;
    }

    datosFiltrados(nodos) {
        if (!this.unSuscribe) {
            this.setState({
                listaDeNodos: nodos,
            })
        }
    }

    datosDeConsulta = (modeloConsecuencia, campoTarget,forzarActualizacion) => {
        const modeloDeConsecuenciaActual = modeloConsecuencia || this.state.filtroModeloConsecuencia || get(this.storeStatePRD,"target.analisis.modeloDeConsecuencia","Cualitativo" )   
        const consultaModelo = modeloDeConsecuenciaActual === "Cualitativo/Semicuantitativo" ? ["Cualitativo", "Semicuantitativo"] : [modeloDeConsecuenciaActual]
        this.setState({
            cargando: true,
        }, async () => {
                if (!has(this.storeStatePRD,"resumen.campos")) await obtenerResumen(this.dominioCorporativo).then((resumen) => this.storeStatePRD.resumen = resumen)
                const campoActual = campoTarget || this.state.campoTarget || get(this.storeStatePRD, "target.analisis.campo",  get(this.storeStatePRD, "resumen.campos[0]", ""))
                if (has(this.storeStatePRD, `data.analisis.${campoActual}.${modeloDeConsecuenciaActual}`) && !forzarActualizacion) {
                    const result = get(this.storeStatePRD, `data.analisis.${campoActual}.${modeloDeConsecuenciaActual}`);
                    this.setState({
                        cargando: false,
                        listaDeNodos: result,
                        todosLosNodos: result,
                        campoTarget: campoActual,
                        filtroModeloConsecuencia: modeloDeConsecuenciaActual
                    })
                }
                else {
                    obtenerDatos(consultaModelo, this.dominioCorporativo, campoActual).then(result => {
                        if (!this.unSuscribe) {
                            this.setState({
                                cargando: false,
                                listaDeNodos: result,
                                todosLosNodos: result,
                                campoTarget: campoActual,
                                filtroModeloConsecuencia: modeloDeConsecuenciaActual
                            })
                            set(this.storeStatePRD, `data.analisis.${campoActual}.${modeloDeConsecuenciaActual}`, result)
                            this.dispatchPRD(this.storeStatePRD)
                        }
                    })
                        .catch(e => {
                            console.error(e);
                            errorToast("Error obteniendo información de la base de datos")
                            this.setState({
                                cargando: false,
                            })
                        });
            }
        })
    }

    pintarFiltroModeloConsecuenca = () => {
        const handleChange = (event) => {
            const nuevoValor = event.target.value;
            this.setState({ filtroModeloConsecuencia: nuevoValor, })
            this.datosDeConsulta(nuevoValor)
            set(this.storeStatePRD,"target.analisis.modeloDeConsecuencia",nuevoValor)
            this.dispatchPRD(this.storeStatePRD)
        };

        const handleClose = () => {
            this.setState({ openSelectorConsecuencia: false });
        };

        const handleOpen = () => {
            this.setState({ openSelectorConsecuencia: true });
        };
        return (<React.Fragment>
            <Grid container direction="row" style={{ marginBottom: 12, borderRadius: 20 }} >
                <Grid item /* xl*/ xs={12} sm={12} md={4} lg={3} >
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel shrink id="modelo-consecuencia-label">Modelado Implementado:</InputLabel>
                        <Select
                            //labelId="modelo-consecuencia-label"
                            id="modelo-consecuencia"
                            open={this.state.openSelectorConsecuencia}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={this.state.filtroModeloConsecuencia||""}
                            onChange={handleChange}
                            style={{ borderRadius: 20,/*  backgroundColor: '#E9ECEF' */ }}
                            label="Modelado Implementado:"
                            MenuProps={{disableScrollLock: true}}
                        >
                            <MenuItem value={"Cualitativo"}>COF: Cualitativo / POF: Cuantitativo</MenuItem>
                            <MenuItem value={"Semicuantitativo"}>COF: Semicuantitativo / POF: Cuantitativo</MenuItem>
                            {/*  <MenuItem value={"Cualitativo/Semicuantitativo"}>Cualitativo y Semicuantitativo</MenuItem> */}
                        </Select>

                    </FormControl>
                </Grid>
                <Hidden smDown>
                    <Grid item md={8} lg={9}>
                        {this.obtenerMensajeDeCampos()}
                        {/* <VisualizadorModelado sinConsecuencia /> */}
                    </Grid>
                </Hidden>
            </Grid>
        </React.Fragment>)
    }
    pintarVisualizador = () => {
        return (
            <React.Fragment>
                <Hidden mdUp>
                    {this.obtenerMensajeDeCampos()}
                   {/* <VisualizadorModelado sinConsecuencia styleContainerMain={{ marginBottom: 8 }} /> */}
                </Hidden>
                {this.pintarFiltroModeloConsecuenca()}
                {this.pintarTabladeNodos()}
                {this.pintarMatricesDeRiesgo()}
                <div className={"mb-0 p-3 d-flex border rounded"} style={{ marginBottom: 0, backgroundColor: '#E9ECEF', borderColor: '#E9ECEF', listStyle: 'none' }}>
                    <Label style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black' }}>GRAFICAS</Label>
                </div>
                {this.contenedorDeFugaYApertura()}
            </React.Fragment>
        )
    }

    pintarTabladeNodos = () => {
        return (
            <CardConEncabezado titulo="Lista de datos" icono={BsTable}>
                <TablaDeNodos
                    filtroModeloConsecuencia={this.state.filtroModeloConsecuencia}
                    listaDeNodos={this.state.listaDeNodos}
                    coleccionActiva={{}}
                    moduloTarget={"API581"}
                    parametrizacionCorporativa={this.parametrizacionCorporativa["rangosAPI581"]}
                    isPRD={true}
                // {...parametrizacionDelContenido.props}
                />
            </CardConEncabezado>
        )
    }

    pintarMatricesDeRiesgo = () => {
        return (
            !!this.state.listaDeNodos.length && <CardConEncabezado titulo="Matriz de riesgo" icono={BsTable}>
                <Grid container direction="row" style={{ width: "100%" }}>
                    <Grid item xs={12} sm container direction="column" >
                        <Typography variant="subtitle2" style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold", color: "#504C4C", textAlign: "center" }}>
                            APERTURA
                        </Typography>
                        <MatrizDeRiesgo
                            //coleccionActiva={this.state.coleccionActiva}
                            isPRD
                            sinCardEncabezado
                            tipoDeFalla={"apertura"}
                            listaDeNodos={this.state.listaDeNodos}
                            moduloTarget={"API581"}
                            modeloDeConsecuencia={this.state.filtroModeloConsecuencia}
                            parametrizacionCorporativa={{ ...this.parametrizacionCorporativa["rangosAPI581"] }} />
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={1} container direction="column" style={{ alignItems: "center" }}>
                            <Divider orientation="vertical" />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm container direction="column">
                        <Typography variant="subtitle2" style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold", color: "#504C4C", textAlign: "center" }}>
                            FUGA
                        </Typography>
                        <MatrizDeRiesgo
                            //coleccionActiva={this.state.coleccionActiva}
                            isPRD
                            sinCardEncabezado
                            listaDeNodos={this.state.listaDeNodos}
                            tipoDeFalla={"fuga"}
                            moduloTarget={"API581"}
                            modeloDeConsecuencia={this.state.filtroModeloConsecuencia}
                            parametrizacionCorporativa={{ ...this.parametrizacionCorporativa["rangosAPI581"] }} />
                    </Grid>
                </Grid>
            </CardConEncabezado>
        )
    }

    contenedorDeFugaYApertura = () => {
        return (
            <div className="row" style={{ marginTop: 20 }}>
                <div className="col">
                    <div style={{ textAlign: 'center' }}>
                        {/* <Label style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black' }}>GRAFICAS DE APERTURA</Label> */}
                        <Label style={{ marginTop: 'auto', marginBottom: 10, fontWeight: 'bold', color: "#504C4C" }} >GRAFICAS DE APERTURA</Label>
                    </div>
                </div>
                <div className="col">
                    <div style={{ textAlign: 'center' }}>
                        <Label style={{ marginTop: 'auto', marginBottom: 10, fontWeight: 'bold', color: '#504C4C' }}>GRAFICAS DE FUGA</Label>
                    </div>
                </div>
            </div>
        )
    }

    obtenerMensajeDeCampos = () => {
        return (!this.state.campoTarget && this.state.cargando ?
            <p style={estilosParaResumenesEnTop} children="..." /> :
            has(this.storeStatePRD,"resumen.campos") && !!this.storeStatePRD.resumen.campos.length ?
                <p style={estilosParaResumenesEnTop}>
                    {"Estoy viendo los "}<b>PRD</b>{" del campo "}
                    <b>
                        <SpanDeCampo campoTarget={this.state.campoTarget} camposDescargadosLaPrimeraVez campos={this.storeStatePRD.resumen.campos.map((camp) => ({ value: camp, label: camp }))}
                            onChange={(campoTarget) => {
                                this.setState({ campoTarget })
                                set(this.storeStatePRD,"target.analisis.campo",campoTarget)
                                this.dispatchPRD(this.storeStatePRD)
                                this.datosDeConsulta(this.state.filtroModeloConsecuencia, campoTarget)
                            }} // retorna el nuevo campo
                            onChangePreAlert = {(campoTarget)=>(!has(this.storeStatePRD, `data.analisis.${campoTarget}.${this.state.filtroModeloConsecuencia}`))}
                        />
                    </b>
                    {` (${this.state.cargando?"...":this.state.todosLosNodos.length})`}
                </p>
                : <p style={estilosParaResumenesEnTop} children={<b children="¡¡SIN CAMPOS PARA MOSTRAR!!" />}></p>
        )
    }
}


export default VisualizadorApi581Valvulas;